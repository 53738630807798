import { OrderedMap } from "immutable"
import _startsWith from "lodash/startsWith"

export const featuresTitleMapping = OrderedMap({
  "customers/search": "Customers / Search",
  "customers/detail": "Customers / Detail",
  "segments/create": "Custom segments / Create",
  "segments/insights": "Segments / Insights",
  "segments/export": "Segments / Export to all",
  "foreign_segments/list": "Custom segments / List all",
  "foreign_segments/view": "Custom segments / View all",
  "foreign_segments/edit": "Custom segments / Edit all",
  "featured_segments/list": "Featured segments / List all",
  "featured_segments/view": "Featured segments / View all",
  "featured_segments/edit": "Featured segments / Create / edit all",
  "data/dashboard": "Data / Diagnostic Dashboard",
  "data/insights": "Data / Insights",
  "data/attributes": "Data / Attributes",
  "data/events": "Data / Events",
  "data/sources_and_destinations": "Data / Sources & Destinations",
  "data/licence_usage": "Data / Licence Usage",
  "data/tags": "Segments / View Tags",
  "settings/tags": "Segments / Edit Tags",
  "data/user_activity": "Admistration / Users Activity",
  "settings/users": "Administration / Users & Roles",
  "settings/global": "Administration / Settings",
  "prebuilt_segments/edit": "Setup / Smart Segments",
  "settings/insights": "Setup / Insights",
  "settings/cdp_entities": "Setup / Implementation",
  "redirects/logs": "Logs",
  "web_banners/view": "Web banners / View",
  "web_banners/edit": "Web banners / Edit",
  "reports/view": "Reports / View",
  "reports/edit": "Reports / Edit",
})

export const customersFeatures = OrderedMap({
  "customers/search": "Search",
  "customers/detail": "Detail",
})

export const segmentsFeatures = OrderedMap({
  "segments/create": "Create custom segments",
  "segments/insights": "Insights",
  "segments/export": "Export to all",
  "foreign_segments/list": "List all custom segments",
  "foreign_segments/view": "View all custom segments",
  "foreign_segments/edit": "Edit all custom segments",
  "featured_segments/list": "List featured segments",
  "featured_segments/view": "View featured segments",
  "featured_segments/edit": "Create / edit featured segments",
  "data/tags": "View Tags",
  "settings/tags": "Edit Tags",
})

export const dataFeatures = OrderedMap({
  "data/dashboard": "Diagnostic Dashboard",
  "data/insights": "Insights",
  "data/attributes": "Attributes",
  "data/events": "Events",
  "data/sources_and_destinations": "Sources & Destinations",
  "data/licence_usage": "Licence Usage",
})

export const administrationFeatures = OrderedMap({
  "data/user_activity": "Users Activity",
  "settings/users": "Users & Roles",
  "settings/global": "Settings",
  "redirects/logs": "Logs",
})

export const setupFeatures = OrderedMap({
  "settings/cdp_entities": "Implementation",
  "settings/insights": "Insights",
  "prebuilt_segments/edit": "Smart Segments",
})

export const personalizationFeatures = OrderedMap({
  "web_banners/view": "View Web Banners & Promo Codes",
  "web_banners/edit": "Edit Web Banners & Promo Codes",
})

export const reportsFeatures = OrderedMap({
  "reports/view": "View Reports",
  "reports/edit": "Edit Reports",
})

export const featuresDescriptions = {
  "customers/search":
    "This access enables users to access the Customers tab and to search for customers entities.",
  "customers/detail":
    "This access enables users to see the Customer Detail page. Please note that if the access Customers/ Search will not be allowed, then the Customers Detail tab will be possible to see only within segments available according to permissions set.",
  "segments/create": "This access enables creating custom segments in the Custom Segments tab.",
  "segments/insights":
    "This access enables seeing the Insights tab within segments available according to permissions.",
  "segments/export":
    "The access enables to export segment to destination and view the list of available destinations.",
  "foreign_segments/list":
    "This access enables users to see a list of all available custom segments (for all filters: My Segments, Shared With Me, Others) including the ones one was not invited to.",
  "foreign_segments/view":
    "This access enables users to view all available custom segments details (for all filters: My Segments, Shared With Me, Others). User will be able to edit custom segments only with edit access granted, by default user will have view-only access.",
  "foreign_segments/edit":
    'This access enables users to edit all available custom segments (for all filters: My Segments, Shared With Me, Others). For users with this access is not possible to set a "view-only" level of access to the custom segment.',
  "featured_segments/list": "This access enables users to see a list of all featured segments.",
  "featured_segments/view": "This access enables users to view all featured segments details.",
  "featured_segments/edit":
    "This access enables users to create new or edit all featured segments.",
  "data/dashboard": "This access enables users to view the Diagnostic Dashboard tab.",
  "data/insights":
    "This access enables users to view the Insights tab, under the Diagnostic Dashboard tab. Please note that in order to see insights for each segment, access Segments/ Insights must be enabled.",
  "data/attributes": "This access enables users to view the Attributes tab within the Data tab.",
  "data/events": "This access enables users to view the Events tab within the Data tab.",
  "data/sources_and_destinations":
    "This access enables to view the Sources & Destinations tab within the Data tab.",
  "data/licence_usage":
    "This access enables to view information about the number of events processed in the last 30 days and the total number of events stored.",
  "data/tags":
    "This access enables users to view the Tags tab within the Segments tab. Please note that in order to see segments marked with a particular tag, Segments/ List all or View/ Edit All needs to be enabled.",
  "data/user_activity":
    "This access enables users to view the Users Activity tab within the Administration tab.",
  "settings/users":
    "This access enables users to view and edit the Users tab within the Administration tab.",
  "settings/global":
    "This access enables users to view and edit the Settings tab within the Administration tab.",
  "settings/insights":
    "This access enables users to view and edit the Insights tab within the Setup tab.",
  "settings/tags": "This access enables users to edit the Tags tab within the Segments tab.",
  "prebuilt_segments/edit":
    "This access enables users to edit Smart Segments tab within the Setup tab.",
  "settings/cdp_entities":
    "This access enables users to edit Sources, Destinations, Attributes and Events tabs within the Setup tab.",
  "redirects/logs": "This access enables users to view Logs.",
  "web_banners/view": "This access enables users to view the Web Banners and Promo Codes tabs.",
  "web_banners/edit": "This access enables users to edit the Web Banners and Promo Codes tabs.",
  "reports/view": "This access enables users to view the Reporting tab.",
  "reports/edit": "This access enables users to edit Reports.",
}

export const getFeatureTitle = (name, exportDestinations) => {
  if (_startsWith(name, "segments/export/")) {
    const parts = name.split("/")
    const dstId = parts[parts.length - 1]
    return `Segments / Export: ${exportDestinations.getIn([dstId, "name"])}`
  } else {
    return featuresTitleMapping.get(name)
  }
}

export const getFeaturesInitialFormValues = () => {
  return Object.keys(featuresTitleMapping.toJS()).reduce((acc, key) => {
    acc[key] = "off"
    return acc
  }, {})
}
