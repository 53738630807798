import { api } from "api"
import { Dispatch } from "redux"
import { Notification } from "./notificationTypes"
import { Awaited } from "types/util"

export const NOTIFICATIONS = {
  ACTION: {
    FETCH: "NOTIFICATIONS_FETCH",
    ADD_NEW: "NOTIFICATIONS_ADD_NEW",
    MARK_AS_READ: "NOTIFICATIONS_MARK_AS_READ",
    REMOVE_UNREAD_BADGE: "NOTIFICATIONS_REMOVE_UNREAD_BADGE",
  },
} as const

export const fetchNotifications =
  ({ untilId, limit }: { untilId?: string | null; limit?: number } = {}) =>
  async (dispatch: Dispatch) =>
    dispatch({
      type: NOTIFICATIONS.ACTION.FETCH,
      payload: api.notification.list(untilId ?? undefined, limit).then(res => ({
        ...res,
        shouldAppend: Boolean(untilId),
      })),
    })

export const addNotification = (notification: Notification) => ({
  type: NOTIFICATIONS.ACTION.ADD_NEW,
  payload: notification,
})

export const markNotificationsAsRead = () => async (dispatch: Dispatch) =>
  dispatch({
    type: NOTIFICATIONS.ACTION.MARK_AS_READ,
    payload: { promise: api.notification.markAsRead() },
  })

export const removeUnreadBadge = () => ({
  type: NOTIFICATIONS.ACTION.REMOVE_UNREAD_BADGE,
})

export const fetchNotificationsPendingType = `${NOTIFICATIONS.ACTION.FETCH}_PENDING` as const
type FetchNotificationsPendingAction = {
  type: typeof fetchNotificationsPendingType
}

export const fetchNotificationsFulfilledType = `${NOTIFICATIONS.ACTION.FETCH}_FULFILLED` as const
type FetchNotificationsFulfilledAction = {
  type: typeof fetchNotificationsFulfilledType
  payload: Awaited<Awaited<ReturnType<ReturnType<typeof fetchNotifications>>>["payload"]>
}

export const fetchNotificationsRejectedType = `${NOTIFICATIONS.ACTION.FETCH}_REJECTED` as const
type FetchNotificationsRejectedAction = {
  type: typeof fetchNotificationsRejectedType
  error: true
  payload: Record<string, any>
}

type AddNotificationAction = ReturnType<typeof addNotification>

export const markNotificationAsReadPendingType =
  `${NOTIFICATIONS.ACTION.MARK_AS_READ}_PENDING` as const
type MarkNotificationAsReadPendingAction = {
  type: typeof markNotificationAsReadPendingType
}

export const markNotificationAsReadFulfilledType =
  `${NOTIFICATIONS.ACTION.MARK_AS_READ}_FULFILLED` as const
type MarkNotificationAsReadFulfilledAction = {
  type: typeof markNotificationAsReadFulfilledType
}

export const markNotificationAsReadRejectedType =
  `${NOTIFICATIONS.ACTION.MARK_AS_READ}_REJECTED` as const
type MarkNotificationAsReadRejectedAction = {
  type: typeof markNotificationAsReadRejectedType
}

type RemoveUnreadBadgeAction = ReturnType<typeof removeUnreadBadge>

export type NotificationsAction =
  | FetchNotificationsPendingAction
  | FetchNotificationsFulfilledAction
  | FetchNotificationsRejectedAction
  | AddNotificationAction
  | MarkNotificationAsReadPendingAction
  | MarkNotificationAsReadFulfilledAction
  | MarkNotificationAsReadRejectedAction
  | RemoveUnreadBadgeAction
