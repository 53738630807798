import React, { ReactNode } from "react"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import { AttributeUsedIn, Notification } from "resources/notification/notificationTypes"
import { User } from "resources/user/userTypes"
import styles from "./Notification.module.scss"

const joinNodes = (nodes: ReactNode[], joiner: string = ", ") =>
  nodes.reduce((acc, curr) => (
    <>
      {acc}
      {joiner}
      {curr}
    </>
  ))

function attributeUsedInSummary({ insights, segments }: AttributeUsedIn) {
  if (segments.length === 0 && insights.length === 0) {
    return "No segments or insights with this attribute"
  }

  return `Used in ${segments.length} segment${segments.length === 1 ? "" : "s"} and ${
    insights.length
  } insight${insights.length === 1 ? "" : "s"}`
}

function attributeUsedInDetailsHtml(
  { insights, segments }: AttributeUsedIn,
  onLinkClick?: () => void,
) {
  if (segments.length === 0 && insights.length === 0) {
    return null
  }
  return (
    <>
      {segments.length > 0 && (
        <div>
          Segments:{" "}
          {joinNodes(
            segments.map((segment, i) => (
              <Link
                to={getRoutePath("segments.detail", { id: segment.id })}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {segment.name}
              </Link>
            )),
          )}
        </div>
      )}
      {insights.length > 0 && (
        <div>
          Insights:{" "}
          {joinNodes(
            insights.map((insight, i) => (
              <Link
                to={`${getRoutePath("data.insights")}?id=${insight.id}`}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {insight.name}
              </Link>
            )),
          )}
        </div>
      )}
    </>
  )
}

export default function contentHtml(
  details: Notification["details"],
  currentUserId: User["id"],
  isDropdown?: boolean,
  onLinkClick?: () => void,
): { mainHtml: ReactNode; detailsHtml?: ReactNode } {
  switch (details.type) {
    case "AttributeCreated": {
      return {
        mainHtml: (
          <div>
            Attribute created:{" "}
            <Link
              to={getRoutePath("data.attributes")}
              onClick={onLinkClick}
              className={styles.highlightedText}
            >
              {details.attribute.name}
            </Link>
          </div>
        ),
      }
    }
    case "AttributeUpdated": {
      const updatedProps = details.updated_properties.includes("name")
        ? joinNodes([
            <span>
              name (
              <Link
                to={getRoutePath("data.attributes")}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {details.new_attribute.name}
              </Link>
              )
            </span>,
            ...details.updated_properties.filter(prop => prop !== "name"),
          ])
        : details.updated_properties.join(", ")

      return {
        mainHtml: (
          <div>
            <div>
              Attribute updated:{" "}
              <Link
                to={getRoutePath("data.attributes")}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {details.old_attribute.name}
              </Link>
            </div>
            <div className={styles.secondaryText}>Updated {updatedProps}</div>
            <div className={styles.secondaryText}>{attributeUsedInSummary(details.used_in)}</div>
          </div>
        ),
        detailsHtml: attributeUsedInDetailsHtml(details.used_in, onLinkClick),
      }
    }
    case "AttributeShowed": {
      return {
        mainHtml: (
          <div>
            <div>
              Attribute made visible:{" "}
              <Link
                to={getRoutePath("data.attributes")}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {details.attribute.name}
              </Link>
            </div>
            <div className={styles.secondaryText}>{attributeUsedInSummary(details.used_in)}</div>
          </div>
        ),
        detailsHtml: attributeUsedInDetailsHtml(details.used_in, onLinkClick),
      }
    }
    case "AttributeHidden": {
      return {
        mainHtml: (
          <div>
            <div>
              Attribute hidden:{" "}
              <Link
                to={getRoutePath("data.attributes")}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {details.attribute.name}
              </Link>
            </div>
            <div className={styles.secondaryText}>{attributeUsedInSummary(details.used_in)}</div>
          </div>
        ),
        detailsHtml: attributeUsedInDetailsHtml(details.used_in, onLinkClick),
      }
    }
    case "EventCreated": {
      return {
        mainHtml: (
          <div>
            Event created:{" "}
            <Link
              to={getRoutePath("data.events")}
              onClick={onLinkClick}
              className={styles.highlightedText}
            >
              {details.name}
            </Link>
          </div>
        ),
      }
    }
    case "EventUpdated": {
      const updatedProps = details.updated_properties.includes("name")
        ? joinNodes([
            <span>
              name (
              <Link
                to={getRoutePath("data.events")}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {details.new_event.name}
              </Link>
              )
            </span>,
            ...details.updated_properties.filter(prop => prop !== "name"),
          ])
        : details.updated_properties.join(", ")

      return {
        mainHtml: (
          <div>
            <div>
              Event updated:{" "}
              <Link
                to={getRoutePath("data.events")}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {details.old_event.name}
              </Link>
            </div>
            <div className={styles.secondaryText}>Updated {updatedProps}</div>
          </div>
        ),
      }
    }
    case "EventShowed": {
      return {
        mainHtml: (
          <div>
            Event made visible:{" "}
            <Link
              to={getRoutePath("data.events")}
              onClick={onLinkClick}
              className={styles.highlightedText}
            >
              {details.name}
            </Link>
          </div>
        ),
      }
    }
    case "EventHidden": {
      return {
        mainHtml: (
          <div>
            Event hidden:{" "}
            <Link
              to={getRoutePath("data.events")}
              onClick={onLinkClick}
              className={styles.highlightedText}
            >
              {details.name}
            </Link>
          </div>
        ),
      }
    }
    case "InsightCreated": {
      return {
        mainHtml: (
          <div>
            Insight created:{" "}
            <Link
              to={`${getRoutePath("data.insights")}?id=${details.id}`}
              onClick={onLinkClick}
              className={styles.highlightedText}
            >
              {details.name}
            </Link>
          </div>
        ),
      }
    }
    case "InsightUpdated": {
      return {
        mainHtml: (
          <div>
            <div>
              Insight updated:{" "}
              <Link
                to={`${getRoutePath("data.insights")}?id=${details.old_insight.id}`}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {details.old_insight.name}
              </Link>
            </div>
          </div>
        ),
      }
    }
    case "InsightDeleted": {
      return {
        mainHtml: (
          <div>
            Insight deleted:{" "}
            <Link
              to={`${getRoutePath("data.insights")}?id=${details.id}`}
              onClick={onLinkClick}
              className={styles.highlightedText}
            >
              {details.name}
            </Link>
          </div>
        ),
      }
    }
    case "SegmentDeleted": {
      return {
        mainHtml: (
          <div>
            Segment deleted: <span className={styles.highlightedText}>{details.name}</span>
          </div>
        ),
      }
    }
    case "SegmentUpdated": {
      const updatedProps = details.updated_properties.map(p => p.replace("_", " "))
      const updatedPropsText = updatedProps.includes("name")
        ? joinNodes([
            <span>
              name (
              <Link
                to={getRoutePath("segments.detail", { id: details.id })}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {details.new_segment.name}
              </Link>
              )
            </span>,
            ...updatedProps.filter(prop => prop !== "name"),
          ])
        : updatedProps.join(", ")

      return {
        mainHtml: (
          <div>
            <div>
              Segment updated:{" "}
              <Link
                to={getRoutePath("segments.detail", { id: details.id })}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {details.old_segment.name}
              </Link>
            </div>
            <div className={styles.secondaryText}>Updated {updatedPropsText}</div>
          </div>
        ),
      }
    }
    case "SegmentExported": {
      return {
        mainHtml: (
          <div>
            Segment{" "}
            <Link
              to={getRoutePath("segments.detail", { id: details.segment_id })}
              onClick={onLinkClick}
              className={styles.highlightedText}
            >
              {details.segment_name}
            </Link>{" "}
            exported to <strong>{details.export_destination_name}</strong>
          </div>
        ),
      }
    }
    case "SegmentExportFailed": {
      return {
        mainHtml: (
          <div>
            <div>
              Error: Segment{" "}
              <Link
                to={getRoutePath("segments.detail", { id: details.segment_id })}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {details.segment_name}
              </Link>{" "}
              export to <strong>{details.export_destination_name}</strong> failed
            </div>
            <div className={styles.secondaryText}>
              Please contact your administrator to investigate.
            </div>
          </div>
        ),
      }
    }
    case "SegmentInvitation": {
      const isMe = currentUserId === details.invited_user_id
      return {
        mainHtml: (
          <div>
            <strong>{details.inviter_user_name}</strong> invited{" "}
            {isMe ? "you" : <strong>{details.invited_user_name}</strong>} to segment:{" "}
            <Link
              to={getRoutePath("segments.detail", { id: details.segment_id })}
              onClick={onLinkClick}
              className={styles.highlightedText}
            >
              {details.segment_name}
            </Link>
          </div>
        ),
      }
    }
    case "SegmentAccessRemoved": {
      const isMe = currentUserId === details.removed_user_id
      return {
        mainHtml: (
          <div>
            <strong>{details.remover_user_name}</strong> removed{" "}
            {isMe ? (
              "your"
            ) : (
              <>
                <strong>{details.removed_user_name}</strong>'s
              </>
            )}{" "}
            access to segment:{" "}
            {isMe ? (
              <span className={styles.highlightedText}>{details.segment_name}</span>
            ) : (
              <Link
                to={getRoutePath("segments.detail", { id: details.segment_id })}
                onClick={onLinkClick}
                className={styles.highlightedText}
              >
                {details.segment_name}
              </Link>
            )}
          </div>
        ),
      }
    }
    default:
      return { mainHtml: null }
  }
}
