import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { showToast } from "actions/toast.action"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { equals, path, prop, sort } from "ramda"
import { useDispatch } from "react-redux"
import api from "resources/endpoints"
import { OrderDir } from "types/util"
import { ascend, descend } from "utilities/comparators"
import {
  EmbeddedWebBanner,
  EmbeddedWebBannerCreatePayload,
  EmbeddedWebBannerModifyPayload,
  EmbeddedWebBannerSort,
} from "./embeddedWBTypes"

export function useFetchAllEmbeddedWB(opts: {
  orderBy: EmbeddedWebBannerSort
  orderDir: OrderDir
  searchTerm: string
}) {
  const queryClient = useQueryClient()
  return useQuery(
    ["embeddedWebBanner", "all"],
    () =>
      new AllResourceItemsFetcher()
        .setEndpointCall((offset: number, limit: number) =>
          api.embeddedWebBanner.list(offset, limit),
        )
        .setDataPath("web_banners")
        .run() as Promise<EmbeddedWebBanner[]>,
    {
      onSuccess: webBanners => {
        webBanners.forEach(web_banner =>
          queryClient.setQueryData(["embeddedWebBanner", web_banner.id], { web_banner }),
        )
      },
      select: webBanners => {
        const filteredBanners = opts.searchTerm
          ? webBanners.filter(
              ({ name, element_id }) =>
                name.toLowerCase().includes(opts.searchTerm.toLowerCase()) ||
                element_id.toLowerCase().includes(opts.searchTerm.toLowerCase()),
            )
          : webBanners

        const getSortingProperty =
          opts.orderBy === "priority" ? path(["settings", "priority"]) : prop(opts.orderBy)
        const comparator = opts.orderDir === "ASC" ? ascend : descend

        return sort(comparator(getSortingProperty), filteredBanners)
      },
    },
  )
}

export function useFetchEmbeddedWBById(id: EmbeddedWebBanner["id"]) {
  return useQuery(["embeddedWebBanner", id], () => api.embeddedWebBanner.retrieve(id), {
    select: ({ web_banner }) => web_banner,
  })
}

export function useCreateEmbeddedWB() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ data }: { data: EmbeddedWebBannerCreatePayload }) => api.embeddedWebBanner.create(data),
    {
      onSuccess: ({ web_banner }) => {
        queryClient.setQueryData<EmbeddedWebBanner[]>(["embeddedWebBanner", "all"], data => {
          if (!data) {
            return
          }

          data.push(web_banner)
          return data
        })
        queryClient.setQueryData(["embeddedWebBanner", web_banner.id], { web_banner })
        dispatch(showToast("Web banner created."))
      },
    },
  )
}

export function useCopyEmbeddedWB() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(({ id }: { id: EmbeddedWebBanner["id"] }) => api.embeddedWebBanner.copy(id), {
    onSuccess: ({ web_banner }) => {
      queryClient.setQueryData<EmbeddedWebBanner[]>(["embeddedWebBanner", "all"], data => {
        if (!data) {
          return
        }

        data.push(web_banner)
        return data
      })
      queryClient.setQueryData(["embeddedWebBanner", web_banner.id], { web_banner })
      dispatch(showToast("Web banner copied."))
    },
  })
}

export function useModifyEmbeddedWB() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ id, data }: { id: EmbeddedWebBanner["id"]; data: EmbeddedWebBannerModifyPayload }) =>
      api.embeddedWebBanner.modify(id, data),
    {
      onSuccess: ({ web_banner }, { data }) => {
        queryClient.setQueryData<EmbeddedWebBanner[]>(["embeddedWebBanner", "all"], data => {
          if (!data) {
            return
          }

          const index = data.findIndex(({ id }) => id === web_banner.id)
          if (index === undefined) {
            data.push(web_banner)
          } else {
            data[index] = web_banner
          }
          return data
        })
        queryClient.setQueryData(["embeddedWebBanner", web_banner.id], { web_banner })
        dispatch(
          showToast(
            equals(data, { disabled: true })
              ? "Web banner disabled."
              : equals(data, { disabled: false })
              ? "Web banner enabled."
              : "Web banner modified.",
          ),
        )
      },
    },
  )
}

export function useDeleteEmbeddedWB() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ id }: { id: EmbeddedWebBanner["id"] }) => api.embeddedWebBanner.delete(id),
    {
      onSuccess: (_, { id }) => {
        queryClient.setQueryData<EmbeddedWebBanner[]>(["embeddedWebBanner", "all"], data => {
          return data?.filter(el => el.id !== id)
        })
        dispatch(showToast("Web banner deleted."))
      },
    },
  )
}
