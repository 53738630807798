import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Form, Field, reduxForm, formValueSelector } from "redux-form"
import { Map, List } from "immutable"
import _noop from "lodash/noop"
import _forEach from "lodash/forEach"
import _toLower from "lodash/toLower"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SimpleBar from "simplebar-react"

// selectors
import { isDataSourcesFulfilled } from "resources/dataSource/dataSourceSelectors"
import { getEventsSourceIds } from "selectors/event.selector"

// ui components
import Paper from "components/UI/elements/Paper"
import CheckboxField from "components/UI/elements/CheckboxField"
import Button from "components/UI/elements/Button/Button"

// helpers
import { getIconSrc } from "helpers/image.helper"
import { getCustomerSourceIdentification } from "pages/Customers/CustomerDetail/getCustomerSourceIdentification"

import "./TimelineFilterForm.scss"

class TimelineFilterForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      sourcesExpanded: Map(),
    }
  }

  handleSelectAllCheckboxes = () => {
    const { resetSection, eventsFetching } = this.props

    if (!eventsFetching) {
      resetSection("CustomerDetailFilterForm", "sources")
      resetSection("CustomerDetailFilterForm", "eventTypes")
    }
  }

  handleSourceStateChange = sourceId => () => {
    const { resetSection, change, eventTypesBySourceId, sourcesFormState } = this.props

    const sourceState = sourcesFormState[sourceId]
    change(`sources.${sourceId}`, !sourceState)

    if (!sourceState) {
      resetSection("CustomerDetailFilterForm", `eventTypes.${sourceId}`)
    } else {
      const sourceEventTypes = eventTypesBySourceId.get(sourceId)
      if (sourceEventTypes) {
        sourceEventTypes.forEach(eventType => {
          change(`eventTypes.${sourceId}.${eventType.id}`, false)
        })
      }
    }
  }

  handleEventTypeCheckboxChange = evt => {
    const { change, eventTypesFormState } = this.props
    const changedSourceId = evt.currentTarget.name.split(".")[1]
    if (evt.currentTarget.checked) {
      change(`sources.${changedSourceId}`, true)
    } else {
      const eventTypes = eventTypesFormState[changedSourceId]
      let checkedEvents = 0
      _forEach(eventTypes, eT => {
        if (eT) {
          checkedEvents++
        }
      })
      if (checkedEvents === 1 && !evt.currentTarget.checked) {
        change(`sources.${changedSourceId}`, false)
      }
    }
  }

  toggleSourceEventTypes = sourceId => () => {
    this.setState(prevState => ({
      sourcesExpanded: prevState.sourcesExpanded.set(
        sourceId,
        prevState.sourcesExpanded.get(sourceId) ? false : true,
      ),
    }))
  }

  render() {
    const {
      attributesMapBySourceId,
      customerAttributes,
      dataSources,
      isDataSourcesFulfilled,
      eventTypesBySourceId,
      eventsFetching,
      sourcesFormState,
      availableSourceIds,
    } = this.props
    const { sourcesExpanded } = this.state

    const identifiedInSources = getCustomerSourceIdentification(
      attributesMapBySourceId,
      customerAttributes,
      dataSources,
    )

    return (
      <Form className="timeline-filter-form" onSubmit={_noop} autoComplete="off">
        {isDataSourcesFulfilled && (
          <Paper
            hasHeader={true}
            className={`timeline-options ${eventsFetching ? "fetching" : ""}`}
          >
            <SimpleBar className="scrollable">
              <div className="timeline-options-header">
                <h4 className="title">Filter by event:</h4>
                <div className="buttons">
                  <Button
                    color="link"
                    size="small"
                    onClick={this.handleSelectAllCheckboxes}
                    type="button"
                  >
                    Reset
                  </Button>
                </div>
              </div>
              {dataSources
                .map(source => {
                  if (
                    identifiedInSources.get(source.id) === false ||
                    !availableSourceIds.includes(source.id)
                  ) {
                    return null
                  }

                  const sourceEventTypes = eventTypesBySourceId.get(source.id)
                  let color = source.getIn(["frontend_settings", "color"])
                  if (!color) {
                    color = "primary"
                  }
                  const sourceState = sourcesFormState[source.id]

                  return (
                    <div key={source.id} className="form-row">
                      <span
                        className={`sourcename ${!sourceState ? "turned-off" : "turned-on"} ${
                          List.isList(sourceEventTypes) ? "clickable" : ""
                        }`}
                        onClick={
                          List.isList(sourceEventTypes)
                            ? this.toggleSourceEventTypes(source.id)
                            : _noop
                        }
                      >
                        {source.name}
                        {List.isList(sourceEventTypes) && (
                          <span className="caret-button">
                            {sourcesExpanded.get(source.id) && (
                              <FontAwesomeIcon icon={["fas", "caret-up"]} className="caret-icon" />
                            )}
                            {!sourcesExpanded.get(source.id) && (
                              <FontAwesomeIcon
                                icon={["fas", "caret-down"]}
                                className="caret-icon"
                              />
                            )}
                          </span>
                        )}
                      </span>
                      <div
                        className={`source-icon ${color} ${!sourceState ? "disabled" : ""}`}
                        onClick={eventsFetching ? _noop : this.handleSourceStateChange(source.id)}
                      >
                        <img
                          src={getIconSrc(
                            {
                              primary: source.getIn(["frontend_settings", "icon"]),
                              secondary: _toLower(source.type),
                            },
                            source.getIn(["frontend_settings", "alt_icon"]),
                            true,
                          )}
                          alt="icon"
                        />
                      </div>
                      {List.isList(sourceEventTypes) && (
                        <div
                          className={`event-types ${
                            sourcesExpanded.get(source.id) ? "expanded" : "collapsed"
                          }`}
                        >
                          {sourceEventTypes
                            .map(eventType => {
                              return (
                                <Field
                                  key={eventType.id}
                                  name={`eventTypes.${source.id}.${eventType.id}`}
                                  label={eventType.name}
                                  component={CheckboxField}
                                  className="sub"
                                  onChange={this.handleEventTypeCheckboxChange}
                                  disabled={eventsFetching}
                                />
                              )
                            })
                            .toArray()}
                        </div>
                      )}
                    </div>
                  )
                })
                .toArray()}
            </SimpleBar>
          </Paper>
        )}
      </Form>
    )
  }
}

TimelineFilterForm.propTypes = {
  dataSources: PropTypes.instanceOf(Map).isRequired,
  eventTypesBySourceId: PropTypes.instanceOf(Map).isRequired,
  attributesMapBySourceId: PropTypes.object.isRequired,
  customerAttributes: PropTypes.instanceOf(Map).isRequired,
  isDataSourcesFulfilled: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  formChanged: PropTypes.func.isRequired,
  eventsFetching: PropTypes.bool.isRequired,
  availableSourceIds: PropTypes.instanceOf(List).isRequired,
}

const selector = formValueSelector("CustomerDetailFilterForm")
const mapStateToProps = state => ({
  isDataSourcesFulfilled: isDataSourcesFulfilled(state),
  sourcesFormState: selector(state, "sources"),
  eventTypesFormState: selector(state, "eventTypes"),
  availableSourceIds: getEventsSourceIds(state),
})

TimelineFilterForm = connect(mapStateToProps)(TimelineFilterForm)

export default reduxForm({
  form: "CustomerDetailFilterForm",
  touchOnBlur: false,
  enableReinitialize: false,
  destroyOnUnmount: false,
  onChange: (values, dispatch, props, previousValues) => {
    props.formChanged()
  },
})(TimelineFilterForm)
