import classNames from "classnames"
import PropTypes from "prop-types"
import React, { ComponentPropsWithRef, forwardRef, Ref, useEffect } from "react"
import "./Paper.scss"

type PaperProps = {
  // children: ReactNode
  hasHeader?: boolean
  onInit?: () => void
  noPaddingTop?: boolean
} & ComponentPropsWithRef<"div">

const Paper = forwardRef(function Paper(
  { children, className, style, hasHeader, noPaddingTop, onInit }: PaperProps,
  ref: Ref<HTMLDivElement>,
) {
  useEffect(() => {
    if (onInit) onInit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={classNames(
        "paper",
        { "has-header": hasHeader, "no-padding-top": noPaddingTop },
        className,
      )}
      ref={ref}
      style={style ?? {}}
    >
      {children}
    </div>
  )
})

Paper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  hasHeader: PropTypes.bool,
  onInit: PropTypes.func,
  style: PropTypes.object,
  noPaddingTop: PropTypes.bool,
}

export default Paper
