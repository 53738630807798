import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import { hasAccess } from "helpers/authenticatedUser.helper"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import Users from "./Users/UsersRoutes"
import Roles from "./Roles/Roles"
import Settings from "./Settings/Settings"
import UsersActivity from "./UsersActivity/UsersActivity"

function redirectToAllowedAdministrationTab() {
  if (hasAccess.administration.usersActivity()) {
    return <Redirect to={getRoutePath("administration.users-activity")} />
  }

  if (hasAccess.administration.users()) {
    return <Redirect to={getRoutePath("administration.users")} />
  }

  if (hasAccess.administration.globalSettings()) {
    return <Redirect to={getRoutePath("administration.settings")} />
  }
}

export default function Administration() {
  return (
    <Switch>
      <Route
        path={getRoutePath("administration")}
        exact
        render={redirectToAllowedAdministrationTab}
      />

      <AuthorizedRoute
        hasAccess={hasAccess.administration.usersActivity()}
        path={getRoutePath("administration.users-activity")}
        component={UsersActivity}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.administration.users()}
        path={getRoutePath("administration.users")}
        component={Users}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.administration.users()}
        path={getRoutePath("administration.roles")}
        component={Roles}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.administration.globalSettings()}
        path={getRoutePath("administration.settings")}
        component={Settings}
      />
    </Switch>
  )
}
