import React, { PureComponent } from "react"
import { reduxForm, Form, Field } from "redux-form"
import PropTypes from "prop-types"
import { List, Map } from "immutable"
import _forEach from "lodash/forEach"

import Button from "components/UI/elements/Button/Button"
import TextField from "components/UI/elements/TextField"
import SelectField from "components/UI/elements/SelectField"

import { required } from "helpers/validators.helper"

import "./ParametersController.scss"

class ParametersController extends PureComponent {
  getParameterOptions = values => {
    if (Map.isMap(values)) {
      const finalValues = values.map((val, key) => ({
        value: key,
        label: val,
      }))
      return finalValues.toArray()
    }
    return []
  }

  render() {
    const {
      handleSubmit,
      form,
      onSubmit,
      loading,
      fieldsConfig,
      countOfFilledParameters,
      isEditable,
      initialValues,
    } = this.props

    if (fieldsConfig.size === 0) {
      // do not display parameters section if there aren't any fields defined by admin
      return null
    }

    let isSomethingFilled = countOfFilledParameters
    if (!isSomethingFilled) {
      _forEach(initialValues, val => {
        if (val) {
          isSomethingFilled = true
        }
      })
    }

    return (
      <Form name={form} onSubmit={handleSubmit(onSubmit)}>
        <div className="body">
          {fieldsConfig.map(field => {
            let component = TextField
            let options = []
            let bothModes = false
            const inputMode = field.get("input_mode")
            if (List.isList(inputMode)) {
              if (inputMode.size === 1) {
                // change component only if input_mode is list
                if (inputMode.get(0) === "value_from_list") {
                  component = SelectField
                  options = this.getParameterOptions(field.get("values"))
                }
              } else {
                // both are set
                component = SelectField
                options = this.getParameterOptions(field.get("values"))
                bothModes = true
              }
            }
            if (component === TextField) {
              return (
                <div className="form-row" key={field.get("name")}>
                  <Field
                    component={component}
                    placeholder={field.get("title")}
                    name={field.get("name")}
                    label={`${field.get("title")} ${field.get("required") ? "*" : ""}`}
                    validate={field.get("required") ? required : null}
                    fullWidth={true}
                    disabled={!isEditable}
                  />
                </div>
              )
            } else {
              return (
                <div className="form-row" key={field.get("name")}>
                  <Field
                    component={component}
                    placeholder={field.get("title")}
                    name={field.get("name")}
                    label={`${field.get("title")} ${field.get("required") ? "*" : ""}`}
                    validate={field.get("required") ? required : null}
                    fullWidth={true}
                    disabled={!isEditable}
                    options={options}
                    isClearable={true}
                    isCreatable={bothModes}
                    inputId={`react-select-2-input-${field.get("name")}`}
                  />
                </div>
              )
            }
          })}
        </div>
        <div className="action-area">
          <div className="right-part">
            <Button
              color="primary"
              size="small"
              className={loading ? "loading" : ""}
              type="submit"
              disabled={!isEditable}
            >
              Save Parameters
            </Button>
          </div>
        </div>
      </Form>
    )
  }
}

ParametersController.propTypes = {
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fieldsConfig: PropTypes.instanceOf(List).isRequired,
  isEditable: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  countOfFilledParameters: PropTypes.number.isRequired,
  loading: PropTypes.bool,
}

ParametersController = reduxForm({
  touchOnBlur: false,
  enableReinitialize: true,
})(ParametersController)

export default ParametersController
