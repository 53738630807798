import { useCallback, useState } from "react"
import { useHistory, useParams } from "react-router"
import { getRoutePath } from "routes"
import {
  EmbeddedWebBanner,
  EmbeddedWebBannerRequestPayload,
} from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import _isEmpty from "lodash/isEmpty"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { getImgSrcFromHtml } from "resources/webBanner/embeddedWebBanner/getImgSrcFromHtml"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import {
  useFetchEmbeddedWBById,
  useDeleteEmbeddedWB,
  useModifyEmbeddedWB,
} from "resources/webBanner/embeddedWebBanner/embeddedWBQueries"
import EmbeddedWebBannersForm from "../components/EmbeddedWebBannersForm/EmbeddedWebBannersForm"
import { PRIORITY_OPTIONS } from "../dropdownOptions"
import {
  EmbeddedWebBannersFormData,
  EmbeddedWebBannersFormValues,
} from "../embeddedWBComponentTypes"

export default function EmbeddedWebBannersDetail() {
  const history = useHistory()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { id } = useParams<{ id: EmbeddedWebBanner["id"] }>()

  const { data: webBanner, isLoading, isError } = useFetchEmbeddedWBById(id)
  const deleteMutation = useDeleteEmbeddedWB()
  const modifyMutation = useModifyEmbeddedWB()

  const formDataToModifyPayload = useCallback(
    ({
      name,
      element_id,
      condition,
      priority,
      image_local,
      image_name,
      image_remote,
      html,
      destination_url,
      width,
      disabled,
    }: EmbeddedWebBannersFormData) => {
      // destination_url needs to be present in image contents, otherwise it will be nullified :(
      const content: EmbeddedWebBannerRequestPayload["settings"]["content"] = image_local
        ? {
            data: image_local,
            image_name: image_name!,
            type: "local_image",
            destination_url: destination_url === "" ? null : destination_url,
          }
        : image_remote
        ? {
            image_url: image_remote,
            type: "remote_image",
            destination_url: destination_url === "" ? null : destination_url,
          }
        : destination_url !== null
        ? {
            destination_url: destination_url === "" ? null : destination_url,
            type: webBanner?.settings.content.type,
          }
        : html
        ? { html, type: "html" }
        : {}
      const data: EmbeddedWebBannerRequestPayload = {
        name,
        element_id,
        settings: {
          condition,
          priority,
          fe_settings: { width },
        },
        disabled,
      }

      if (content && !_isEmpty(content)) {
        // needs to be added optionally only when something changes, otherwise it removes
        // image from the storage ...
        data.settings.content = content
      }

      return data
    },
    [webBanner],
  )

  const modifyWebBanner = (formData: EmbeddedWebBannersFormData) =>
    modifyMutation.mutateAsync(
      { id, data: formDataToModifyPayload(formData) },
      {
        onSuccess: () => {
          history.push(getRoutePath("personalization.embedded-web-banners"))
        },
      },
    )

  const deleteBanner = () =>
    deleteMutation.mutate(
      { id },
      {
        onSuccess: () => {
          history.push(getRoutePath("personalization.embedded-web-banners"))
        },
      },
    )

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (isError) {
    return null
  }

  const initialValues: EmbeddedWebBannersFormValues | undefined = webBanner && {
    element_id: webBanner.element_id,
    name: webBanner.name,
    condition: webBanner.settings.condition,
    priority: PRIORITY_OPTIONS.find(prio => prio.value === webBanner.settings.priority)!,
    destination_url: webBanner.settings.content.destination_url,
    content_type: webBanner.settings.content.type,
    html: webBanner?.settings.content.type !== "html" ? "" : webBanner?.settings.content.html,
    image_url: getImgSrcFromHtml(webBanner),
    width: webBanner.settings.fe_settings?.width,
    disabled: webBanner.disabled,
  }

  return (
    <>
      <EmbeddedWebBannersForm
        key={id}
        onDelete={() => {
          setDeleteModalOpen(true)
        }}
        initialValues={initialValues}
        onSubmit={modifyWebBanner}
      />
      <ConfirmModal
        title="Are you sure?"
        open={deleteModalOpen}
        type="delete"
        text={`Do you really want to delete web banner?`}
        handleClose={() => {
          setDeleteModalOpen(false)
        }}
        handleConfirm={deleteBanner}
        isLoading={deleteMutation.isLoading}
      />
    </>
  )
}
