import requestFactory from "api/request"
import {
  Report,
  ReportCreatePayload,
  ReportListResponse,
  ReportModifyPayload,
  ReportMovePayload,
} from "./reportTypes"

const report = {
  list(offset = 0, limit = 50): Promise<ReportListResponse> {
    return requestFactory("get", "/reports", { offset, limit })
  },
  retrieve(id: Report["id"]): Promise<{ report: Report }> {
    return requestFactory("get", `/reports/${id}`)
  },
  create(data: ReportCreatePayload): Promise<{ report: Report }> {
    return requestFactory("post", "/reports", data)
  },
  modify(id: Report["id"], data: ReportModifyPayload): Promise<{ report: Report }> {
    return requestFactory("patch", `/reports/${id}`, data)
  },
  delete(id: Report["id"]): Promise<void> {
    return requestFactory("delete", `/reports/${id}`)
  },
  move(id: Report["id"], data: ReportMovePayload): Promise<void> {
    return requestFactory("patch", `/reports/${id}/move`, data)
  },
}

export default report
