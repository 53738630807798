import { formatISO, subDays } from "date-fns"
import { DateString } from "types/util"

export function getISODateDaysAgo(minusDays: number) {
  return formatISO(subDays(new Date(), minusDays), { representation: "date" })
}

export function getISODateToday() {
  return getISODateDaysAgo(0)
}

export function isLastDays(startDate: DateString, endDate: DateString, number: number): boolean {
  return endDate === getISODateToday() && startDate === getISODateDaysAgo(number - 1)
}
