import _includes from "lodash/includes"
import _trim from "lodash/trim"
import removeMd from "remove-markdown"

export const shortenMarkdownText = (text, charactersCount) => {
  const plainText = removeMd(text)
  let shortenedText = plainText.substr(0, charactersCount)
  shortenedText = shortenedText.substr(
    0,
    Math.min(shortenedText.length, shortenedText.lastIndexOf(" ")),
  )
  if (_includes([".", "?", "!"], shortenedText[shortenedText.length - 1])) {
    return shortenedText
  } else {
    return shortenedText + "..."
  }
}

export const shortenText = (text, charactersCount) => {
  if (text.length <= charactersCount || charactersCount < 2) {
    return text
  }
  let shortenedText = text.substr(0, charactersCount - 3)
  if (_includes([".", "?", "!", " "], shortenedText[shortenedText.length - 1])) {
    if (shortenedText.length === text.length) {
      return shortenedText
    } else {
      return `${shortenedText.slice(0, shortenedText.length - 1)}...`
    }
  } else {
    return _trim(shortenedText) + "..."
  }
}

export const shortenTextInMiddle = (text, charactersCount) => {
  if (text.length <= charactersCount || charactersCount < 2) {
    return text
  }
  return `${text.substring(0, 5)}…${text.substring(text.length - charactersCount + 9)}`.trim()
}

export const shortenTextWithoutDots = (text, charactersCount) => {
  if (text.length <= charactersCount) {
    return text
  }
  let shortenedText = _trim(text).substr(0, charactersCount)
  if (_includes([".", "?", "!", " "], shortenedText[shortenedText.length - 1])) {
    if (shortenedText.length === text.length) {
      return _trim(shortenedText.slice(0, shortenedText.length - 1))
    }
  }
  return _trim(shortenedText)
}

export const shortenTextArroundPattern = (pattern, text, maxLength) => {
  if (!text || text.length <= maxLength) {
    return text
  }
  if (!pattern) {
    return `${text.substr(0, maxLength).trim()}...`
  }

  const start = text.indexOf(pattern)
  const end = start + pattern.length
  if (start === 0) {
    return `${text.substr(0, maxLength).trim()}...`
  }
  if (end === text.length) {
    return `...${text.substr(-maxLength, maxLength)}`
  }
  if (pattern.length > maxLength) {
    return `...${pattern.substr(0, maxLength).trim()}...`
  }
  const remainingChars = maxLength - pattern.length
  let subStart = start - Math.ceil(remainingChars / 2)
  let subEnd = end + Math.ceil(remainingChars / 2)
  if (subStart < 0) {
    subEnd = subEnd + -subStart
    subStart = 0
    return `${text.substring(subStart, subEnd)}...`
  } else if (subEnd > text.length) {
    subStart = subStart - (subEnd - text.length - 1)
    subEnd = text.length
    return `...${text.substring(subStart, subEnd)}`
  } else {
    return `...${text.substring(subStart, subEnd)}...`
  }
}

export const copyStringToClipboard = s => {
  const dummy = document.createElement("input")
  document.body.appendChild(dummy)
  dummy.value = s
  dummy.select()
  document.execCommand("copy")
  document.body.removeChild(dummy)
}
