import { Record } from "immutable"

const Event = Record({
  id: 0,
  type: "",
  name: "",
  schema: {},
  source_id: "",
  examples: [],
  created: "",
  version: "",
  is_hidden: 0,
  // 0 works as infinite time to live, we show empty form field in UI
  ttl: 0,
})

export default Event
