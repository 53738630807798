import React, { Component } from "react"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import AttributeForm from "../components/AttributeForm/AttributeForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import { connect } from "react-redux"
import { showToast } from "actions/toast.action"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getInitialAttributeFormValues } from "../components/AttributeForm/attributeInitialValues"
import "./AttributeDetail.scss"
import { getEventsIsFulfilled } from "selectors/event.selector"
import { isDataSourcesFulfilled } from "resources/dataSource/dataSourceSelectors"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import { useFetchAttributeById, useModifyAttribute } from "resources/attribute/attributeQueries"
import { useHistory, useParams } from "react-router-dom"

class AttributeDetail extends Component {
  cloneAttribute = () => {
    const { history, attribute } = this.props
    history.push({
      pathname: getRoutePath("setup.attributes.create"),
      state: { attribute },
    })
  }

  render() {
    const {
      history,
      areAttributesFulfilled,
      attribute,
      areEventsFulfilled,
      areSourcesFulfilled,
      isSaving,
      modifyAttribute,
      toggleHidden,
    } = this.props

    const formInitValues = getInitialAttributeFormValues(attribute)

    return (
      <section className="detail-attribute wrapper">
        <PaperHeader size="small" titleText="Edit attribute" className="detail-attribute-header">
          <div className="header-buttons">
            {attribute && (
              <React.Fragment>
                <Button size="small" color="white-red" onClick={toggleHidden} disabled={isSaving}>
                  {attribute.is_hidden === 1 && (
                    <React.Fragment>
                      <FontAwesomeIcon icon={["far", "eye"]} className="icon" /> Show
                    </React.Fragment>
                  )}
                  {attribute.is_hidden === 0 && (
                    <React.Fragment>
                      <FontAwesomeIcon icon={["far", "eye-slash"]} className="icon" /> Hide
                    </React.Fragment>
                  )}
                </Button>
                <Button size="small" color="white" onClick={this.cloneAttribute}>
                  <FontAwesomeIcon icon={["fas", "clone"]} className="icon" /> Copy
                </Button>
              </React.Fragment>
            )}
            <Button
              size="small"
              color="white"
              onClick={() => {
                history.push(getRoutePath("setup.attributes"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton isLoading={isSaving} formName="AttributeForm" />
          </div>
        </PaperHeader>
        {areAttributesFulfilled && areEventsFulfilled && areSourcesFulfilled ? (
          <Paper hasHeader className="detail-attribute-content">
            <AttributeForm
              onSubmit={modifyAttribute}
              initialValues={formInitValues}
              disabledFields={["id", "source_id", "is_unique", "data_type"]}
            />
          </Paper>
        ) : (
          <LoadingIndicator />
        )}
      </section>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  areEventsFulfilled: getEventsIsFulfilled(state),
  areSourcesFulfilled: isDataSourcesFulfilled(state),
})

AttributeDetail = connect(mapStateToProps, { showToast })(AttributeDetail)

export default props => {
  const { id } = useParams()
  const history = useHistory()
  const { data: attribute, isSuccess } = useFetchAttributeById(id, {
    refetchOnMount: "always",
  })
  const { mutate, isLoading: isSaving } = useModifyAttribute()

  const modifyAttribute = data =>
    mutate({ id, data }, { onSuccess: () => history.push(getRoutePath("setup.attributes")) })
  const toggleHidden = () =>
    attribute && mutate({ id: attribute.id, data: { is_hidden: +!attribute.is_hidden } })

  return (
    <AttributeDetail
      {...props}
      attribute={attribute}
      areAttributesFulfilled={isSuccess}
      modifyAttribute={modifyAttribute}
      toggleHidden={toggleHidden}
      isSaving={isSaving}
    />
  )
}
