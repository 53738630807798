import { STITCHING_ATTRIBUTE } from "sharedConstants"
import { api } from "api"
import _get from "lodash/get"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchStitchingAttributeList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: STITCHING_ATTRIBUTE.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api.stitchingAttribute.list(offset, limit, loadFullStructure),
          )
          .setDataPath("stitching_attribute_configs")
          .setLoadFullStructure(0)
          .run()
        return data
      })(),
    },
  })
}

export const updateStitchingAttribute = (attributeId, data) => async dispatch =>
  dispatch({
    type: STITCHING_ATTRIBUTE.ACTION.UPDATE,
    payload: {
      promise: (async () => {
        const response = await api.stitchingAttribute.modify(attributeId, data)
        return _get(response, "stitching_attribute_configs", [])
      })(),
    },
  })

export const deleteStitchingAttribute = attributeId => async dispatch =>
  dispatch({
    type: STITCHING_ATTRIBUTE.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api.stitchingAttribute.modify(attributeId, { stitching_attribute_configs: [] })
        return attributeId
      })(),
    },
  })
