import _toString from "lodash/toString"

export const SEGMENT = {
  NUMBER: {
    ACTION: {
      INIT: "INIT_SEGMENT_NUMBERS",
      SET: "SET_SEGMENT_NUMBERS",
      ERROR: "ERROR_SEGMENT_NUMBERS",
      CHANGE_CONDITIONS_NUMBER_POSITION: "CHANGE_SEGMENT_CONDITION_NUMBER_POSITION",
      RESET_GLOBAL: "RESET_GLOBAL_SEGMENT_NUMBERS",
      RESET_PARTICULAR: "RESET_PARTICULAR_SEGMENT_NUMBER",
      DELETE_PARTICULAR: "DELETE_PARTICULAR_SEGMENT_NUMBER",
      ADD_LAST: "ADD_LAST_SEGMENT_NUMBER",
    },
  },
}

export const initSegmentNumbers =
  (segmentId, forceInit = false) =>
  dispatch =>
    dispatch({
      type: SEGMENT.NUMBER.ACTION.INIT,
      meta: { segmentId: _toString(segmentId) },
      payload: {
        forceInit,
      },
    })

export const setSegmentNumbers = (segmentId, data) => dispatch =>
  dispatch({
    type: SEGMENT.NUMBER.ACTION.SET,
    meta: { segmentId: _toString(segmentId) },
    payload: data,
  })

export const dispatchSegmentNumbersError = segmentId => dispatch =>
  dispatch({
    type: SEGMENT.NUMBER.ACTION.ERROR,
    meta: { segmentId: _toString(segmentId) },
  })

export const changeSegmentsConditionsNumbersPosition =
  (segmentId, fromIndex, toIndex) => dispatch => {
    dispatch({
      type: SEGMENT.NUMBER.ACTION.CHANGE_CONDITIONS_NUMBER_POSITION,
      meta: { segmentId: _toString(segmentId) },
      payload: {
        fromIndex,
        toIndex,
      },
    })
    dispatch({
      type: SEGMENT.NUMBER.ACTION.RESET_GLOBAL,
      meta: { segmentId: _toString(segmentId) },
      payload: {},
    })
  }

export const resetGlobalSegmentNumbers = segmentId => dispatch =>
  dispatch({
    type: SEGMENT.NUMBER.ACTION.RESET_GLOBAL,
    meta: { segmentId: _toString(segmentId) },
    payload: {},
  })

export const resetParticularSegmentNumber = (segmentId, index) => dispatch =>
  dispatch({
    type: SEGMENT.NUMBER.ACTION.RESET_PARTICULAR,
    meta: { segmentId: _toString(segmentId) },
    payload: {
      index,
    },
  })

export const deleteParticularSegmentNumber = (segmentId, index) => dispatch =>
  dispatch({
    type: SEGMENT.NUMBER.ACTION.DELETE_PARTICULAR,
    meta: { segmentId: _toString(segmentId) },
    payload: {
      index,
    },
  })

export const addEmptySegmentNumber = segmentId => dispatch =>
  dispatch({
    type: SEGMENT.NUMBER.ACTION.ADD_LAST,
    meta: { segmentId: _toString(segmentId) },
  })
