import { Record } from "immutable"

const CustomerEventCount = Record({
  id: 0,
  name: "",
  event_id: "",
  count: 0,
})

export default CustomerEventCount
