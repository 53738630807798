import React from "react"
import styles from "./SegmentUsers.module.scss"
import { User, UserFull } from "resources/user/userTypes"
import { SegmentUser } from "types/segmentUsers"
import Avatar from "components/UI/elements/Avatar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type SegmentUsersProps = {
  acl: SegmentUser[]
  allUsers: Record<string, UserFull>
  openUsersModal: () => void
  authorId: User["id"]
}

export default function SegmentUsers({
  acl,
  allUsers,
  openUsersModal,
  authorId,
}: SegmentUsersProps) {
  const author = allUsers[authorId]
  const aclWithoutAuthor = acl.filter(({ user_id }) => user_id !== authorId)

  return (
    <div className={styles.container}>
      {author && (
        <div className={styles.authorWrapper}>
          <Avatar name={author.name} email={author.email} className={styles.authorAvatar} />
          <div>
            <div className={styles.authorName}>{author.name}</div>
            <div className={styles.authorLabel}>Author</div>
          </div>
        </div>
      )}
      {aclWithoutAuthor && allUsers && (
        <div className={styles.aclWrapper} onClick={openUsersModal}>
          {aclWithoutAuthor.slice(0, 3).map(({ user_id }, index) => {
            const user = allUsers[user_id]

            return (
              <div key={user.id} className={styles.aclEntryWrapper} style={{ zIndex: index + 1 }}>
                <Avatar name={user.name} email={user.email} className={styles.aclAvatar} />
                {index === 2 && aclWithoutAuthor.length > 3 && (
                  <div className={styles.aclEntryOverlay}>+{aclWithoutAuthor.length - 2}</div>
                )}
              </div>
            )
          })}
          <div className={styles.plusButton}>
            <FontAwesomeIcon icon={["far", "plus"]} />
          </div>
        </div>
      )}
    </div>
  )
}
