import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import _round from "lodash/round"
import _isArray from "lodash/isArray"

import { shortEnglishHumanizer } from "helpers/date.helper"

class Duration extends React.PureComponent {
  intervalId = null

  constructor(props) {
    super(props)
    this.state = {
      duration: null,
    }
  }

  componentDidMount() {
    const { datetime, units } = this.props
    const params = {}
    if (_isArray(units) && units.length > 0) {
      params.units = units
    }
    this.setState({
      duration: shortEnglishHumanizer(
        _round(moment.duration(moment.utc().diff(moment.utc(datetime))).asSeconds()) * 1000,
        params,
      ),
    })
    this.intervalId = setInterval(() => {
      this.setState({
        duration: shortEnglishHumanizer(
          _round(moment.duration(moment.utc().diff(moment.utc(datetime))).asSeconds()) * 1000,
          params,
        ),
      })
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  render() {
    let duration = this.state.duration === "0m" ? "<1m" : this.state.duration
    return <span className="duration">{duration}</span>
  }
}

Duration.propTypes = {
  datetime: PropTypes.string.isRequired,
  units: PropTypes.array,
}

export default Duration
