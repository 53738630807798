import { CUSTOMER_ITERATOR } from "sharedConstants"
import { Map } from "immutable"

const initialState = Map({
  index: null,
  segmentId: null,
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CUSTOMER_ITERATOR.ACTION.SET:
      return state.set("index", payload.index).set("segmentId", payload.segmentId)
    case CUSTOMER_ITERATOR.ACTION.SET_INDEX:
      return state.set("index", payload.index)
    case CUSTOMER_ITERATOR.ACTION.RESET:
      return initialState
    default:
      return state
  }
}
