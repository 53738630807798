import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getIconSrc } from "helpers/image.helper"

import "./IconsRadioGroup.scss"
import Tippy from "@tippyjs/react"

class IconsRadioGroup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovering: null,
    }
  }

  handleMouseHover = icon => () => {
    this.setState({
      isHovering: icon,
    })
  }

  render() {
    const {
      input,
      meta: { touched, error },
      icons,
    } = this.props
    const { isHovering } = this.state
    const errorMessage = touched ? error : ""
    return (
      <div className="icons-wrapper">
        <label>Icon</label>
        <div className={`icons-options-group ${isHovering || input.value ? "active" : ""}`}>
          {icons.map(icon => (
            <React.Fragment key={icon.icon}>
              <input
                {...input}
                type="radio"
                id={icon.icon}
                value={icon.icon}
                className="icon-opt"
                checked={icon.icon === input.value}
              />
              <label
                htmlFor={icon.icon}
                onMouseEnter={this.handleMouseHover(icon.icon)}
                onMouseLeave={this.handleMouseHover()}
                className={isHovering === icon.icon || input.value === icon.icon ? "marked" : ""}
              >
                <div className="icon-wrapper">
                  <Tippy content={icon.name} delay={[200, null]} disabled={icon.name === undefined}>
                    <img src={getIconSrc(icon.icon)} alt="" />
                  </Tippy>
                </div>

                {input.value === icon.icon && (
                  <span>
                    <FontAwesomeIcon icon={["far", "check"]} className="check-icon" />
                  </span>
                )}
              </label>
            </React.Fragment>
          ))}
        </div>
        {errorMessage && <p className="error-message">Pick one of the icons please.</p>}
      </div>
    )
  }
}

IconsRadioGroup.propTypes = {
  icons: PropTypes.array.isRequired,
}

export default IconsRadioGroup
