import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { getFormValues } from "redux-form"
import { connect } from "react-redux"
import _toLower from "lodash/toLower"
import _get from "lodash/get"
import _isArray from "lodash/isArray"
import _set from "lodash/set"
import _forEach from "lodash/forEach"
import _isNil from "lodash/isNil"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { List, Map } from "immutable"
import moment from "moment"

// ui components
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import EventsFilterForm from "components/UI/components/EventsFilterForm"
import Table, {
  Thead,
  Th,
  Tbody,
  Td,
  Tr,
  SortButton,
  RowMessage,
} from "components/UI/elements/Table"

// actions
import { fetchCustomerEventCountsList } from "actions/customerEvent.action"

// selectors
import { getEventsIsFulfilled, getEventsData } from "selectors/event.selector"
import { getDataSourcesData } from "resources/dataSource/dataSourceSelectors"
import { getCustomerEventsCounts } from "selectors/customerEventCount.selector"

// helpers
import { getCustomerEventTitle, getCustomerEventPayload } from "helpers/event.helper"
import { getIconSrc } from "helpers/image.helper"

import "./Events.scss"
import Tippy from "@tippyjs/react"

class Events extends PureComponent {
  eventExamples = {}

  constructor(props) {
    super(props)
    this.state = {
      sort: {
        type: "name",
        isAsc: true,
      },
      carouselHeights: {},
      activeExamples: {},
    }
  }

  componentDidMount() {
    this.props.fetchCustomerEventCountsList()
    if (this.props.isEventsFulfilled) {
      this.computeCarouselHeights()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isEventsFulfilled !== prevProps.isEventsFulfilled) {
      this.computeCarouselHeights()
    }
  }

  computeCarouselHeights = () => {
    const carouselHeights = {}
    const activeExamples = {}
    _forEach(this.eventExamples, (examples, key) => {
      let maxHeight = 0
      _forEach(examples, example => {
        if (example.clientHeight > maxHeight) {
          maxHeight = example.clientHeight
        }
      })
      _set(carouselHeights, `${key}`, maxHeight)
      _set(activeExamples, `${key}`, 0)
    })
    this.setState({
      carouselHeights,
      activeExamples,
    })
  }

  getExampleStyle = (eventId, exampleIndex) => {
    const { activeExamples } = this.state
    const activeIndex = _get(activeExamples, eventId)
    if (activeIndex !== undefined) {
      let left = Math.abs(exampleIndex - activeIndex) * 690
      if (exampleIndex < activeIndex) {
        left = -left
      }
      return {
        left: `${left}px`,
        position: "absolute",
      }
    }
    return {}
  }

  moveCarousel = (direction, eventId) => () => {
    if (direction === "left") {
      this.setState(prevState => ({
        activeExamples: {
          ...prevState.activeExamples,
          [eventId]: (prevState.activeExamples[eventId] -= 1),
        },
      }))
    } else if (direction === "right") {
      this.setState(prevState => ({
        activeExamples: {
          ...prevState.activeExamples,
          [eventId]: (prevState.activeExamples[eventId] += 1),
        },
      }))
    }
  }

  getEventList = (searchText, dataSourceId) => {
    const { eventsList, isEventsFulfilled, dataSourcesMap, customerEventsCountsList } = this.props
    const { carouselHeights, activeExamples } = this.state
    let eventsListForRender = eventsList
    if (isEventsFulfilled) {
      if (searchText) {
        eventsListForRender = eventsListForRender.filter(evt =>
          _toLower(evt.name).includes(_toLower(searchText)),
        )
      }
      if (dataSourceId) {
        eventsListForRender = eventsListForRender.filter(evt => evt.source_id === dataSourceId)
      }
    }

    return this.getSortedEventList(eventsListForRender).map(evt => {
      const eventCount = customerEventsCountsList.find(ce => ce.event_id === evt.id)
      const examples = []
      if (_isArray(evt.examples)) {
        evt.examples.forEach(example => {
          examples.push({
            type: evt.type,
            payload: example,
          })
        })
      }
      return (
        <Tr key={evt.id}>
          <Td
            textBigger
            textBlack
            textBold
            withNewBadge={moment().diff(evt.created, "days") < 8}
            className="event-name"
          >
            {evt.name}
          </Td>
          <Td className="event-source">
            <div className="event-source-flex-wrapper">
              <div>
                <img
                  src={getIconSrc(
                    {
                      primary: dataSourcesMap.getIn([evt.source_id, "frontend_settings", "icon"]),
                      secondary: _toLower(dataSourcesMap.getIn([evt.source_id, "type"])),
                    },
                    dataSourcesMap.getIn([evt.source_id, "frontend_settings", "alt_icon"]),
                  )}
                  alt="icon"
                />
              </div>
              <div className="source-n-count">
                <div>{dataSourcesMap.getIn([evt.source_id, "name"], "")}</div>
                <div className="count">
                  {!_isNil(eventCount) ? `${eventCount.count} filled` : ""}
                </div>
              </div>
            </div>
          </Td>
          <Td className="event-examples">
            <div
              className="carousel"
              style={{
                height:
                  _get(carouselHeights, evt.id) === undefined
                    ? "auto"
                    : `${_get(carouselHeights, evt.id)}px`,
              }}
            >
              <div className="carousel-content">
                {examples.map((example, key) => {
                  const title = getCustomerEventTitle(example, evt.schema, evt.name)
                  const payload = getCustomerEventPayload(example, evt.schema, false)
                  return (
                    <div
                      key={key}
                      className="event-example"
                      ref={divElement => _set(this.eventExamples, `${evt.id}.[${key}]`, divElement)}
                      style={this.getExampleStyle(evt.id, key)}
                    >
                      <h4>{title}</h4>
                      {payload}
                    </div>
                  )
                })}
              </div>
              {_get(activeExamples, evt.id) > 0 && (
                <button
                  className="carousel-button carousel-left"
                  onClick={this.moveCarousel("left", evt.id)}
                >
                  <FontAwesomeIcon icon={["far", "chevron-left"]} />
                </button>
              )}
              {_get(activeExamples, evt.id) < examples.length - 1 && (
                <button
                  className="carousel-button carousel-right"
                  onClick={this.moveCarousel("right", evt.id)}
                >
                  <FontAwesomeIcon icon={["far", "chevron-right"]} />
                </button>
              )}
            </div>
          </Td>
        </Tr>
      )
    })
  }

  getSortedEventList = eventList => {
    const { dataSourcesMap } = this.props
    const { sort } = this.state

    if (sort && sort.type) {
      if (sort.type === "name") {
        if (sort.isAsc) {
          return eventList.sort((a, b) => a.name.localeCompare(b.name))
        } else {
          return eventList.sort((a, b) => b.name.localeCompare(a.name))
        }
      } else if (sort.type === "dataSource") {
        if (sort.isAsc) {
          return eventList.sort((a, b) =>
            dataSourcesMap
              .getIn([a.source_id, "name"], "")
              .localeCompare(dataSourcesMap.getIn([b.source_id, "name"], "")),
          )
        } else {
          return eventList.sort((a, b) =>
            dataSourcesMap
              .getIn([b.source_id, "name"], "")
              .localeCompare(dataSourcesMap.getIn([a.source_id, "name"], "")),
          )
        }
      }
    }
  }

  sortEvents = type => {
    const currentSortObj = this.state.sort

    if (currentSortObj.type === type) {
      this.setState({
        sort: {
          type: type,
          isAsc: !currentSortObj.isAsc,
        },
      })
    } else {
      this.setState({
        sort: {
          type: type,
          isAsc: true,
        },
      })
    }
  }

  render() {
    const { filterValues, isEventsFulfilled } = this.props
    const { sort } = this.state

    const events = this.getEventList(
      _get(filterValues, "search", ""),
      _get(filterValues, "select.value", null),
    )

    return (
      <React.Fragment>
        <div className="events-container wrapper">
          <PaperHeader size="small">
            <h3 className="title">
              Events
              <Tippy
                interactive
                content={
                  <>
                    <p>
                      Events are <strong>behaviours of a customer</strong>. They show information
                      about what actions customers have performed and when.{" "}
                      <strong>When a customer’s action happens</strong> (e.g. customer visits a web
                      page, makes a payment transaction, etc.){" "}
                      <strong>it is recorded as an event</strong>. Meiro is able to extract data
                      from the events and create attributes like "Date of the last visit to the web"
                      or "Total revenue from all transactions".
                    </p>
                    <p>
                      To learn more about Events tab, please refer to{" "}
                      <a
                        href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-data"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        this article
                      </a>
                      .
                    </p>
                  </>
                }
              >
                <span className="info-tooltip-icon">
                  <FontAwesomeIcon icon={["fas", "info-circle"]} />
                </span>
              </Tippy>
            </h3>
            <EventsFilterForm />
          </PaperHeader>
          <Paper hasHeader={true} noPaddingTop>
            {events.size > 0 && (
              <Table className="events-table">
                <Thead stickyHeader>
                  <Th className="name">
                    <SortButton
                      column="name"
                      orderBy={sort.type}
                      orderDir={sort.isAsc ? "asc" : "desc"}
                      onClick={() => this.sortEvents("name")}
                      label="Name"
                    />
                  </Th>
                  <Th className="source-col">
                    <SortButton
                      column="dataSource"
                      orderBy={sort.type}
                      orderDir={sort.isAsc ? "asc" : "desc"}
                      onClick={() => this.sortEvents("dataSource")}
                      label="Data source"
                    />
                  </Th>
                  <Th className="examples-col">Examples</Th>
                </Thead>
                <Tbody>{events}</Tbody>
              </Table>
            )}
            {events.size === 0 && isEventsFulfilled && <RowMessage>Nothing found.</RowMessage>}
          </Paper>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    eventsList: getEventsData(state),
    isEventsFulfilled: getEventsIsFulfilled(state),
    filterValues: getFormValues("SearchEventForm")(state),
    dataSourcesMap: getDataSourcesData(state),
    customerEventsCountsList: getCustomerEventsCounts(state),
  }
}

Events.propTypes = {
  eventsList: PropTypes.instanceOf(List).isRequired,
  isEventsFulfilled: PropTypes.bool.isRequired,
  dataSourcesMap: PropTypes.instanceOf(Map).isRequired,
  customerEventsCountsList: PropTypes.instanceOf(List).isRequired,
}

Events = connect(mapStateToProps, { fetchCustomerEventCountsList })(Events)

export default Events
