import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import _toLower from "lodash/toLower"
import _get from "lodash/get"
import { getRoutePath } from "routes"
import { List, Map } from "immutable"
import { fetchEventsList, modifyEvent } from "actions/event.action"
import { getEventsIsFulfilled, getEventsIsFetching, getEventsData } from "selectors/event.selector"
import { fetchDataSourcesList } from "resources/dataSource/dataSourceActions"
import moment from "moment"
import { MOMENT } from "sharedConstants"
import {
  getDataSourcesData,
  areDataSourcesFetching,
} from "resources/dataSource/dataSourceSelectors"
import { getIconSrc } from "helpers/image.helper"
import { getFormValues } from "redux-form"
import { showToast } from "actions/toast.action"
import EventsFilterForm from "components/UI/components/EventsFilterForm"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"

import "./EventsList.scss"

class EventsList extends Component {
  componentDidMount() {
    // refetch events and sources if necessary
    const { areEventsFetching, fetchEventsList, areDataSourcesFetching, fetchDataSourcesList } =
      this.props
    if (!areEventsFetching) {
      fetchEventsList(1)
    }
    if (!areDataSourcesFetching) {
      fetchDataSourcesList(1)
    }
  }

  getFilteredEventList = (searchText, dataSourceId) => {
    const { events, areEventsFulfilled } = this.props
    if (areEventsFulfilled) {
      let result = events
      if (searchText) {
        result = result.filter(event => _toLower(event.name).includes(_toLower(searchText)))
      }
      if (dataSourceId) {
        result = result.filter(event => event.source_id === dataSourceId)
      }
      return result
    }
    return List()
  }

  goToEventDetail = eventId => () => {
    this.props.history.push(getRoutePath("setup.events.detail", { id: eventId }))
  }

  toggleHiddenAttribute = event => async () => {
    const { modifyEvent, showToast } = this.props
    const changedIsHidden = event.is_hidden === 1 ? 0 : 1
    await modifyEvent(event.id, { is_hidden: changedIsHidden })
    showToast(`Event ${changedIsHidden === 1 ? "hidden" : "visible"}.`)
  }

  render() {
    const { history, areEventsFulfilled, dataSourcesMap, filterValues, events } = this.props

    const filteredEvents = this.getFilteredEventList(
      _get(filterValues, "search", ""),
      _get(filterValues, "select.value", null),
    )

    return (
      <section className="wrapper setup-events">
        <PaperHeader size="small" titleText="Events" className="setup-events-header">
          <EventsFilterForm
            destroyOn
            destroyOnUnmount={false}
            formName="SetupSearchEventsForm"
            showHiddenSources={true}
          />
          <Button
            size="small"
            color="primary"
            onClick={() => {
              history.push(getRoutePath("setup.events.create"))
            }}
          >
            + Create event
          </Button>
        </PaperHeader>
        {areEventsFulfilled && filteredEvents.size > 0 && (
          <Paper hasHeader noPaddingTop>
            <Table className="setup-events-table">
              <Thead stickyHeader>
                <Th className="name">Name</Th>
                <Th className="source">Data source</Th>
                <Th className="date-added">Date added</Th>
                <Th className="actions" />
              </Thead>
              <Tbody>
                {filteredEvents
                  .map(event => {
                    const sourceIsHidden =
                      dataSourcesMap.getIn([event.source_id, "is_hidden"], 1) === 1
                    return (
                      <Tr key={event.id} className={event.is_hidden ? "is-hidden" : ""}>
                        <Td
                          textBold
                          textBigger
                          textBlack
                          withNewBadge={moment().diff(event.created, "days") < 8}
                          className="name"
                        >
                          {event.name}
                        </Td>
                        <Td>
                          <div className="source-cell-content">
                            <div className="source-icon">
                              <img
                                src={getIconSrc(
                                  {
                                    primary: dataSourcesMap.getIn([
                                      event.source_id,
                                      "frontend_settings",
                                      "icon",
                                    ]),
                                    secondary: _toLower(
                                      dataSourcesMap.getIn([event.source_id, "type"]),
                                    ),
                                  },
                                  dataSourcesMap.getIn([
                                    event.source_id,
                                    "frontend_settings",
                                    "alt_icon",
                                  ]),
                                )}
                                alt="icon"
                              />
                            </div>
                            <span className="source-name">
                              {dataSourcesMap.getIn([event.source_id, "name"])}
                            </span>
                          </div>
                        </Td>
                        <Td className="created">
                          {moment.utc(event.created).local().format(MOMENT.DATE_FORMAT)}
                        </Td>
                        <Td textAlignRight className="actions">
                          <IconButton
                            color={COLOR.BLACK}
                            size={SIZE.TAG}
                            onClick={this.goToEventDetail(event.id)}
                            withBackground
                            iconName="pencil-alt"
                            tooltip="Edit"
                          />

                          <IconButton
                            color={COLOR.RED}
                            size={SIZE.TAG}
                            onClick={this.toggleHiddenAttribute(event)}
                            className="left-margin"
                            disabled={sourceIsHidden}
                            withBackground
                            iconName={event.is_hidden ? "eye" : "eye-slash"}
                            iconStyle="far"
                            tooltip={
                              sourceIsHidden ? "Hidden source" : event.is_hidden ? "Show" : "Hide"
                            }
                          />
                        </Td>
                      </Tr>
                    )
                  })
                  .toArray()}
              </Tbody>
            </Table>
          </Paper>
        )}
        {areEventsFulfilled && events.size === 0 && (
          <p className="info-message">Click on the "Create Event" to get started.</p>
        )}
        {areEventsFulfilled && events.size > 0 && filteredEvents.size === 0 && (
          <p className="info-message">Nothing found.</p>
        )}
      </section>
    )
  }
}

EventsList.propTypes = {
  areEventsFetching: PropTypes.bool.isRequired,
  areEventsFulfilled: PropTypes.bool.isRequired,
  fetchEventsList: PropTypes.func.isRequired,
  events: PropTypes.instanceOf(List).isRequired,
  dataSourcesMap: PropTypes.instanceOf(Map).isRequired,
  areDataSourcesFetching: PropTypes.bool.isRequired,
  fetchDataSourcesList: PropTypes.func.isRequired,
  modifyEvent: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  areEventsFetching: getEventsIsFetching(state),
  areEventsFulfilled: getEventsIsFulfilled(state),
  events: getEventsData(state, true),
  dataSourcesMap: getDataSourcesData(state, true),
  filterValues: getFormValues("SetupSearchEventsForm")(state),
  areDataSourcesFetching: areDataSourcesFetching(state),
})

export default connect(mapStateToProps, {
  fetchEventsList,
  fetchDataSourcesList,
  modifyEvent,
  showToast,
})(EventsList)
