import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import promiseMiddleware from "redux-promise-middleware"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { createWhitelistFilter } from "redux-persist-transform-filter"

import rootReducer from "reducers"
import { composeWithDevTools } from "redux-devtools-extension"
import { ActionType, PROMISE_TYPE_DELIMITER } from "types/promiseActionTypes"
import { TypedUseSelectorHook, useSelector as originalUseSelector } from "react-redux"

const persistConfig = {
  version: 0,
  key: "root",
  storage,
  transforms: [
    createWhitelistFilter("authenticatedUser", [
      "data",
      "token",
      "firstLogin",
      "loginTimestamp",
      "ui",
      "latestSearch",
    ]),
  ],
  whitelist: ["authenticatedUser"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  undefined,
  composeWithDevTools(
    applyMiddleware(
      promiseMiddleware({
        promiseTypeDelimiter: PROMISE_TYPE_DELIMITER,
        promiseTypeSuffixes: [ActionType.Pending, ActionType.Fulfilled, ActionType.Rejected],
      }),
      thunk,
    ),
  ),
)

export const persistor = persistStore(store)

export default store

export type State = ReturnType<typeof store.getState>
export const useSelector: TypedUseSelectorHook<State> = originalUseSelector
