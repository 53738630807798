import React, { ComponentPropsWithoutRef } from "react"
import styles from "./ComingSoonPage.module.scss"

type ComingSoonPageProps = {
  mainImgSrc: string
} & ComponentPropsWithoutRef<"div">

export default function ComingSoonPage({ mainImgSrc, children }: ComingSoonPageProps) {
  return (
    <div className={styles.container}>
      <img src={mainImgSrc} alt="" className={styles.mainImg} />
      <div className={styles.contentPanel}>
        <div className={styles.contentWrapper}>{children}</div>
      </div>
    </div>
  )
}
