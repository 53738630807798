import React, { PureComponent } from "react"
import _random from "lodash/random"
import { List } from "immutable"

// ui components
import Button from "components/UI/elements/Button/Button"

// helpers
import { getRoutePath } from "routes"

//css
import "./Logout.scss"

import waveSrc from "./images/wave.svg"

// team avatars
import person0 from "./images/team/0.png"
import person1 from "./images/team/1.png"
import person2 from "./images/team/2.png"
import person3 from "./images/team/3.png"
import person4 from "./images/team/4.png"
import person5 from "./images/team/5.png"
import person6 from "./images/team/6.png"
import person7 from "./images/team/7.png"
import person8 from "./images/team/8.png"
import person9 from "./images/team/9.png"
import person10 from "./images/team/10.png"
import person11 from "./images/team/11.png"
import person12 from "./images/team/12.png"
import person13 from "./images/team/13.png"
import person14 from "./images/team/14.png"
import person15 from "./images/team/15.png"
import person16 from "./images/team/16.png"
import person17 from "./images/team/17.png"
import person18 from "./images/team/18.png"
import person19 from "./images/team/19.png"
import person20 from "./images/team/20.png"
import person21 from "./images/team/21.png"
import person22 from "./images/team/22.png"
import person23 from "./images/team/23.png"
import person24 from "./images/team/24.png"
import person25 from "./images/team/25.png"
import person26 from "./images/team/26.png"
import person27 from "./images/team/27.png"

const team = {
  person0,
  person1,
  person2,
  person3,
  person4,
  person5,
  person6,
  person7,
  person8,
  person9,
  person10,
  person11,
  person12,
  person13,
  person14,
  person15,
  person16,
  person17,
  person18,
  person19,
  person20,
  person21,
  person22,
  person23,
  person24,
  person25,
  person26,
  person27,
}

const PERSONS_COUNT = 28
const PERSONS_VISIBLE = 9

class LogoutPage extends PureComponent {
  intervalId = null

  constructor(props) {
    super(props)
    this.state = {
      selectedPersons: this.genArrayOfUniqueNumbers(PERSONS_COUNT - 1, PERSONS_VISIBLE),
      visiblePersons: List(),
      animationIndex: null,
    }
  }

  genArrayOfUniqueNumbers = (maxNumber, count) => {
    const array = []
    while (array.length < count) {
      const num = _random(maxNumber)
      if (!array.includes(num)) {
        array.push(num)
      }
    }
    return List(array)
  }

  getUniqueRandomPersonNumber = () => {
    const { selectedPersons } = this.state
    let num = _random(PERSONS_COUNT - 1)
    while (selectedPersons.includes(num)) {
      num = _random(PERSONS_COUNT - 1)
    }
    return num
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      if (this.state.visiblePersons.size === PERSONS_VISIBLE) {
        clearInterval(this.intervalId)
        this.intervalId = setInterval(() => {
          const index = _random(PERSONS_VISIBLE - 1)
          this.setState(
            prevState => ({
              visiblePersons: prevState.visiblePersons.set(index, false),
              animationIndex: index,
            }),
            () => {
              setTimeout(() => {
                this.setState(prevState => ({
                  selectedPersons: prevState.selectedPersons.set(
                    index,
                    this.getUniqueRandomPersonNumber(),
                  ),
                  visiblePersons: prevState.visiblePersons.set(prevState.animationIndex, true),
                  animationIndex: null,
                }))
              }, 1000)
            },
          )
        }, 2500)
        return
      }
      this.setState(prevState => ({
        visiblePersons: prevState.visiblePersons.push(true),
      }))
    }, 500)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
    this.intervalId = null
  }

  render() {
    const { selectedPersons, visiblePersons } = this.state

    return (
      <section className="logout-page">
        <div className="left-part">
          <div>
            <img src={waveSrc} className="wave" alt="wave" />
            <h1>
              Hey there! Thanks for using Meiro.<strong>We love having you around!</strong>
            </h1>
            <p>
              For exciting updates, check out our{" "}
              <a href="https://meiro.io/blog/" target="_blank" rel="noreferrer noopener">
                blog
              </a>
              .
            </p>
            <Button
              color="primary"
              size="big"
              className="login-button"
              onClick={() => this.props.history.push(getRoutePath("login"))}
            >
              Login
            </Button>
          </div>
        </div>
        <div className="right-part">
          <div className="team-avatars-wrapper">
            {selectedPersons.map((num, idx) => (
              <div
                key={idx}
                className={`member member-${idx + 1} ${
                  visiblePersons.get(idx) === true ? "visible" : "hidden"
                }`}
              >
                <img src={team[`person${num}`]} alt="team-member" />
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

export default LogoutPage
