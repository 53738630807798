import React, { useCallback } from "react"
import EmbeddedWebBannersForm from "../components/EmbeddedWebBannersForm/EmbeddedWebBannersForm"
import { useHistory } from "react-router"
import { getRoutePath } from "routes"
import { useCreateEmbeddedWB } from "resources/webBanner/embeddedWebBanner/embeddedWBQueries"
import { EmbeddedWebBannerCreatePayload } from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import { EmbeddedWebBannersFormData } from "../embeddedWBComponentTypes"

export default function EmbeddedWebBannersCreate() {
  const history = useHistory()

  const createMutation = useCreateEmbeddedWB()

  const createBanner = (formData: EmbeddedWebBannersFormData) =>
    createMutation.mutateAsync(
      { data: formDataToCreatePayload(formData) },
      {
        onSuccess: () => {
          history.push(getRoutePath("personalization.embedded-web-banners"))
        },
      },
    )

  const formDataToCreatePayload = useCallback(
    ({
      name,
      element_id,
      condition,
      priority,
      image_local,
      image_name,
      image_remote,
      html,
      destination_url,
      width,
      disabled,
    }: EmbeddedWebBannersFormData): EmbeddedWebBannerCreatePayload => ({
      name,
      element_id,
      settings: {
        condition,
        priority,
        content: image_local
          ? {
              destination_url: destination_url === "" ? null : destination_url,
              data: image_local,
              image_name: image_name!,
              type: "local_image",
            }
          : image_remote
          ? {
              destination_url: destination_url === "" ? null : destination_url,
              image_url: image_remote,
              type: "remote_image",
            }
          : { html: html!, type: "html" },
        fe_settings: {
          width: width,
        },
      },
      disabled,
    }),
    [],
  )

  return <EmbeddedWebBannersForm onSubmit={createBanner} />
}
