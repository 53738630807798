import { WORKSPACE } from "sharedConstants"
import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchWorkspacesList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: WORKSPACE.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api.mi.workspaces.list(offset, limit, "name", "ASC", loadFullStructure),
          )
          .setDataPath("workspaces")
          .setLoadFullStructure(0)
          .run()
        return data
      })(),
    },
  })
}
