import Button from "components/UI/elements/Button/Button"
import PaperHeader from "components/UI/elements/PaperHeader"
import { hasAccess } from "helpers/authenticatedUser.helper"
import React, { useState } from "react"
import { useHistory } from "react-router"
import { getRoutePath } from "routes"
import styles from "./PopupWebBannersList.module.scss"
import Paper from "components/UI/elements/Paper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import Table, {
  RowMessage,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  SortButton,
} from "components/UI/elements/Table"
import ToggleButton from "components/UI/elements/ToggleButton"
import { PopupWebBanner, PopupWebBannerSort } from "resources/webBanner/popupWebBanner/popupWBTypes"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import PopupWebBannerSettingsForm from "../components/PopupWebBannerSettingsForm/PopupWebBannerSettingsForm"
import { format } from "date-fns"
import { DATEFNS } from "sharedConstants"
import {
  useDeletePopupWB,
  useFetchAllPopupWB,
  useModifyPopupWB,
} from "resources/webBanner/popupWebBanner/popupWBQueries"
import SearchField from "components/UI/elements/SearchField"
import create from "zustand"
import { OrderDir } from "types/util"
import { assoc } from "ramda"
import { User } from "resources/user/userTypes"
import useTypedSelector from "hooks/useTypedSelector"
import { getAllUsersMap } from "resources/user/userSelectors"

type PopupWBSelectionState = {
  orderBy: PopupWebBannerSort
  orderDir: OrderDir
  searchTerm: string
  setSort: (orderBy: PopupWebBannerSort) => void
  setSearchTerm: (searchTerm: string) => void
}

const useStore = create<PopupWBSelectionState>(set => ({
  orderBy: "name",
  orderDir: "ASC",
  searchTerm: "",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
}))

export default function PopupWebBannersList() {
  const history = useHistory()
  const allUsers: Record<User["id"], User> = useTypedSelector(state => getAllUsersMap(state)).toJS()
  const { orderBy, orderDir, searchTerm, setSort, setSearchTerm } = useStore()

  const { data: webBanners, isLoading } = useFetchAllPopupWB({ searchTerm, orderBy, orderDir })
  const deleteMutation = useDeletePopupWB()
  const modifyMutation = useModifyPopupWB()

  const [deleteModal, setDeleteModal] = useState({
    open: false,
    banner: null as PopupWebBanner | null,
  })

  const toggleBannerDisabled = (webBanner: PopupWebBanner) =>
    modifyMutation.mutate({ id: webBanner.id, data: { disabled: !webBanner.disabled } })

  const closeDeleteBannerModal = () => setDeleteModal(assoc("open", false))

  const deleteBanner = () =>
    deleteMutation.mutate(
      { id: deleteModal.banner!.id },
      {
        onSuccess: () => {
          closeDeleteBannerModal()
        },
      },
    )

  return (
    <div className={styles.wrapper}>
      <PaperHeader titleText="Pop up Web Banners" size="small" className={styles.header}>
        <Button
          color="primary"
          type="button"
          size="small"
          onClick={() => {
            history.push(getRoutePath("personalization.popup-web-banners.create"))
          }}
          disabled={!hasAccess.webBanners.edit()}
        >
          + Create Pop up Web Banner
        </Button>
      </PaperHeader>
      <PopupWebBannerSettingsForm />
      <Paper className={styles.bannersBox}>
        <div className={styles.formHeader}>
          <h3>Pop up Web Banners</h3>
          <div>
            <SearchField
              input={{
                value: searchTerm,
                onChange: setSearchTerm,
              }}
              placeholder="Search for name"
              onClear={() => setSearchTerm("")}
              data-testid="pwb-search-field"
            />
          </div>
        </div>
        {isLoading ? (
          <LoadingIndicator />
        ) : webBanners?.length === 0 ? (
          <RowMessage>No banners found.</RowMessage>
        ) : (
          <Table>
            <Thead>
              <Th>
                <SortButton
                  column="name"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("name")}
                  label="Name"
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="priority"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("priority")}
                  label="Priority"
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="disabled"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("disabled")}
                  label="Active"
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="created"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("created")}
                  label="Modified at"
                />
              </Th>
              <Th>&nbsp;</Th>
            </Thead>
            <Tbody>
              {webBanners?.map(webBanner => (
                <React.Fragment key={webBanner.id}>
                  <Tr>
                    <Td
                      textBigger
                      textBlack
                      textBold
                      data-testid="td-pwb-name"
                      className={styles.nameCell}
                    >
                      {webBanner.name}
                    </Td>
                    <Td textAlignRight className={styles.priorityColumn}>
                      {webBanner.settings.priority}
                    </Td>
                    <Td textAlignRight className={styles.toggleColumn}>
                      <ToggleButton
                        value={!webBanner.disabled}
                        handleToggle={() => toggleBannerDisabled(webBanner)}
                        disabled={!hasAccess.webBanners.edit()}
                      />
                    </Td>
                    <Td textAlignRight className={styles.modifiedAtColumn}>
                      <span className={styles.modifiedAt}>
                        {format(new Date(`${webBanner.created}Z`), DATEFNS.DATETIME_FORMAT)}
                      </span>
                      {webBanner.user_id && (
                        <span className={styles.modifiedBy}>
                          by {allUsers[webBanner.user_id]?.name}
                        </span>
                      )}
                    </Td>
                    <Td textAlignRight className={styles.actionsColumn}>
                      {/* @ts-ignore */}
                      <IconButton
                        color={COLOR.BLACK}
                        size={SIZE.TAG}
                        onClick={() => {
                          history.push(
                            getRoutePath("personalization.popup-web-banners.detail", {
                              id: webBanner.id,
                            }),
                          )
                        }}
                        withBackground
                        iconName="pencil-alt"
                        tooltip="Edit"
                      />
                      {/* @ts-ignore */}
                      <IconButton
                        color={COLOR.RED}
                        size={SIZE.TAG}
                        onClick={() => {
                          setDeleteModal({
                            open: true,
                            banner: webBanner,
                          })
                        }}
                        withBackground
                        iconName="trash-alt"
                        tooltip="Delete"
                        className={styles.actionButtonMargin}
                        disabled={!hasAccess.webBanners.edit()}
                        data-testid="cta-delete-pwb"
                      />
                    </Td>
                  </Tr>
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        )}
      </Paper>
      <ConfirmModal
        title="Are you sure?"
        open={deleteModal.open}
        type="delete"
        action="delete"
        what="pop up web banner"
        item={deleteModal.banner?.name}
        handleClose={closeDeleteBannerModal}
        handleConfirm={deleteBanner}
        isLoading={deleteMutation.isLoading}
      />
    </div>
  )
}
