import React, { ComponentPropsWithRef, forwardRef } from "react"
import styles from "./HookFormTextField.module.scss"
import classnames from "classnames"

// TODO: solve ref, different for textarea and input, maybe separate the components
type TextFieldProps = {
  label?: string
  error?: string
  fullWidth?: boolean
  rows?: number
} & ComponentPropsWithRef<"input">
const TextField = forwardRef(function TextField(
  { label, error, type, className, fullWidth, rows, ...props }: TextFieldProps,
  ref: any,
) {
  return (
    <div
      className={classnames(
        styles.textField,
        { [styles.hasError]: error, [styles.fullWidth]: fullWidth },
        className,
      )}
      data-testid={`hook-form-text-field-wrapper-${props.name}`}
    >
      {label && (
        <label className={styles.label} htmlFor={props.name}>
          {label}
        </label>
      )}
      {rows ? (
        <>
          {/* @ts-ignore */}
          <textarea id={props.name} rows={rows} ref={ref} {...props} />
        </>
      ) : (
        <input id={props.name} type={type ?? "text"} ref={ref} {...props} />
      )}
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  )
})

export default TextField
