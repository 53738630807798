import { api } from "api"

export const SEGMENT = {
  ACTION: {
    LIST: "SEGMENT_LIST",
    RETRIEVE: "SEGMENT_RETRIEVE",
    CREATE: "SEGMENT_CREATE",
    MODIFY: "SEGMENT_MODIFY",
    DELETE: "SEGMENT_DELETE",
    CLONE: "SEGMENT_CLONE",
    SET_TABLE_FILTER: "SEGMENT_FILTER_SET_TABLE_FILTER",
  },
}

export const fetchSegments =
  (
    offset,
    limit,
    orderBy,
    orderDir,
    nameFilter,
    tagIds,
    showMy,
    showSharedWithMe,
    showForeign,
    clearListBefore = false,
  ) =>
  async dispatch =>
    dispatch({
      type: SEGMENT.ACTION.LIST,
      meta: { clearListBefore },
      payload: {
        promise: (async () => {
          return await api.segment.list(
            offset,
            limit,
            orderBy,
            orderDir,
            nameFilter,
            tagIds,
            0,
            showMy,
            showSharedWithMe,
            showForeign,
          )
        })(),
      },
    })

export const createSegment = data => async dispatch =>
  dispatch({
    type: SEGMENT.ACTION.CREATE,
    payload: {
      promise: api.segment.create(data),
    },
  })

export const retrieveSegment = id => async dispatch =>
  dispatch({
    type: SEGMENT.ACTION.RETRIEVE,
    payload: {
      promise: api.segment.retrieve(id),
    },
  })

export const modifySegment = (id, data) => async dispatch =>
  dispatch({
    type: SEGMENT.ACTION.MODIFY,
    payload: {
      promise: api.segment.modify(id, data),
    },
  })

export const deleteSegment = id => async dispatch =>
  dispatch({
    type: SEGMENT.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api.segment.delete(id)
        return id
      })(),
    },
  })

export const setSegmentFilter = (filterName, value) => dispatch =>
  dispatch({
    type: `SegmentFilter_${SEGMENT.ACTION.SET_TABLE_FILTER}`,
    payload: {
      filterName,
      value,
    },
  })
