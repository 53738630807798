export const links = {
  homepage: [
    {
      title: "Home page & notification center tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-homepage-notification-center",
    },
    {
      title: "I do not have access to some tabs/ features",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/i-do-not-have-access-to-some-tabs-features",
    },
  ],
  customers: [
    {
      title: "Customers tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-customers",
    },
    {
      title: "What can I understand through the single customer profile",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/what-can-i-understand-through-the-single-customer-profile",
    },
    {
      title: "Identity stitching and how customer identity appears",
      url: "https://docs.meiro.io/books/meiro-knowledge-base/page/identity-stitching-and-how-customer-identity-appears",
    },
    {
      title: "Customer profiles: how can I save customer profiles",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/customer-profiles-how-can-i-save-customer-profiles",
    },
  ],
  segments: [
    {
      title: "Segments tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-segments",
    },
    {
      title: "Segment detail tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-segment-detail",
    },
    {
      title: "How to build segments of customers",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/how-to-build-segments-of-customers",
    },
    {
      title: "Segment builder: tutorials & tips",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/segment-builder-tutorials-tips",
    },
    {
      title: "Insights what are they for and how do they work",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/insights-what-are-they-for-and-how-do-they-work",
    },
  ],
  data: [
    {
      title: "Data tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-data",
    },
    {
      title: "Understand the bigger picture of data available in Meiro",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/understand-the-bigger-picture-of-data-available-in-meiro",
    },
    {
      title: "Insights what are they for and how do they work",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/insights-what-are-they-for-and-how-do-they-work",
    },
  ],
  reporting: [
    {
      title: "Reporting tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-reporting",
    },
    {
      title: "Reporting in Meiro Business Explorer",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/reporting-in-meiro-business-explorer",
    },
    {
      title: "Reporting dashboard templates",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/reporting-dashboard",
    },
  ],
  personalization: [
    {
      title: "Web banners: tutorials & tips",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/web-banners-tutorials-tips",
    },
    {
      title: "Embedded web banners tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-personalization-embedded-web-banners",
    },
    {
      title: "Pop up web banners tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-personalization-pop-up-web-banners",
    },
    {
      title: "Promo code tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-personalization-promo-codes",
    },
  ],
  setup: [
    {
      title: "Setup guide",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/setup-guide",
    },
  ],
  administration: [
    {
      title: "Administration tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-administration",
    },
    {
      title: "Administrator's guide",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/administrators-guide",
    },
  ],
  trash: [
    {
      title: "Trash tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-trash",
    },
    {
      title: "How to restore deleted users, destinations and segments",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/how-to-restore-deleted-users-destinations-and-segments",
    },
  ],
  userSettings: [
    {
      title: "User Settings tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-user-settings",
    },
  ],
}
