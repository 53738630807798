import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { getDataSourcesArrayForSelect } from "resources/dataSource/dataSourceSelectors"
import { SearchFormWithSelectDestroyable } from "components/UI/components/SearchFormWithSelect"

class InsightsFilterForm extends PureComponent {
  render() {
    return (
      <SearchFormWithSelectDestroyable
        form="InsigtsFilter"
        inputPlaceholder="Search for insights"
        selectOptions={this.props.sources}
        selectPlaceholder="All sources"
      />
    )
  }
}

InsightsFilterForm.propTypes = {
  sources: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
  return {
    sources: getDataSourcesArrayForSelect(state),
  }
}

InsightsFilterForm = connect(mapStateToProps)(InsightsFilterForm)

export default InsightsFilterForm
