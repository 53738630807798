import requestFactory from "api/request"
import { Flag, OrderDir } from "types/util"
import { Segment } from "../segment/segmentTypes"
import { SegmentExportLogReturnType, SegmentExportLogListResponse } from "./segmentExportLogTypes"
import {
  SegmentExportReturnType,
  SegmentExportListResponse,
  SegmentExportRunPayload,
  SegmentExportRunResponse,
  SegmentExport,
} from "./segmentExportTypes"

const exportEndpoints = {
  list<T extends Flag = 0>(
    segmentId: Segment["id"],
    offset = 0,
    limit = 50,
    order_by: keyof SegmentExportReturnType<T> = "created",
    order_dir: OrderDir = "DESC",
    load_full_structure: T = 0 as T,
  ): Promise<SegmentExportListResponse<T>> {
    return requestFactory("get", `/segments/${segmentId}/exports`, {
      offset,
      limit,
      order_by,
      order_dir,
      load_full_structure,
    })
  },
  run<T extends Flag = 0>(
    segmentId: Segment["id"],
    data: SegmentExportRunPayload,
    load_full_structure: T = 0 as T,
  ): Promise<SegmentExportRunResponse<T>> {
    return requestFactory(
      "post",
      `/segments/${segmentId}/exports?load_full_structure=${load_full_structure}`,
      data,
    )
  },
  retrieve<T extends Flag = 0>(
    segmentId: Segment["id"],
    exportId: SegmentExport["id"],
    load_full_structure: T = 0 as T,
  ): Promise<{ segment_export: SegmentExportReturnType<T> }> {
    return requestFactory("get", `/segments/${segmentId}/exports/${exportId}`, {
      load_full_structure,
    })
  },
  cancel<T extends Flag = 0>(
    segmentId: Segment["id"],
    exportId: SegmentExport["id"],
    load_full_structure: T = 0 as T,
  ): Promise<{ segment_export: SegmentExportReturnType<T> }> {
    return requestFactory(
      "delete",
      `/segments/${segmentId}/exports/${exportId}?load_full_structure=${load_full_structure}`,
    )
  },
  logs: {
    list<T extends Flag = 0>(
      segmentId: Segment["id"],
      exportId: SegmentExport["id"],
      offset = 0,
      limit = 50,
      load_full_structure: T = 0 as T,
      order_by: keyof SegmentExportLogReturnType<T> = "id",
      order_dir: OrderDir = "DESC",
    ): Promise<SegmentExportLogListResponse<T>> {
      return requestFactory("get", `/segments/${segmentId}/exports/${exportId}/logs`, {
        offset,
        limit,
        load_full_structure,
        order_by,
        order_dir,
      })
    },
  },
}

export default exportEndpoints
