import _get from "lodash/get"

import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const TAG = {
  ACTION: {
    LIST: "TAG_LIST",
    ASSIGN_TO_SEGMENT: "ASSIGN_TAG_TO_SEGMENT",
    DELETE_TAG_FROM_SEGMENT: "DELETE_TAG_FROM_SEGMENT",
    CREATE: "TAG_CREATE",
    MODIFY: "TAG_MODIFY",
    DELETE: "TAG_DELETE",
  },
}

export const fetchTagsList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: TAG.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api.tag.list(offset, limit, "name", "ASC", loadFullStructure),
          )
          .setDataPath("tags")
          .setLoadFullStructure(0)
          .run()
        return data
      })(),
    },
  })
}

export const deleteTag = tagId => async dispatch =>
  dispatch({
    type: TAG.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api.tag.delete(tagId)
        return tagId
      })(),
    },
  })

export const createTag = data => async dispatch =>
  dispatch({
    type: TAG.ACTION.CREATE,
    payload: {
      promise: (async () => {
        const response = await api.tag.create(data)
        return _get(response, "tag", {})
      })(),
    },
  })

export const modifyTag = (tagId, data) => async dispatch =>
  dispatch({
    type: TAG.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api.tag.modify(tagId, data)
        return _get(response, "tag", {})
      })(),
    },
  })
