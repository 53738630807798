import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import _noop from "lodash/noop"
import { connect } from "react-redux"

import { fetchAllSmartSegments } from "resources/segment/segment/segmentActions"
import {
  getSegments,
  getIsFulfilled,
  getSmartSegmentsIsFetching,
} from "resources/segment/segment/segmentSelectors"

import { smartSegmentGroups } from "resources/segment/segment/utilities/smartSegmentUtils"

import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import SmartSegmentTile from "components/UI/elements/SmartSegmentTile"
import Paper from "components/UI/elements/Paper"
import { hasAccess } from "helpers/authenticatedUser.helper"

import { getRoutePath } from "routes"

import "./SmartSegmentsList.scss"
import { TOAST } from "sharedConstants"
import { withRouter } from "react-router"
import { createSegment } from "resources/segment/segment/segmentActions"
import { showToast } from "actions/toast.action"

class SmartSegmentsList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isCreatingSegment: false,
      segmentBuckets: this.assignSegmentsToGroups(),
    }
  }

  assignSegmentsToGroups = () => {
    const { smartSegments } = this.props
    const segmentBuckets = {}

    smartSegments
      .sort((a, b) => {
        if (a.frontend_settings?.smart?.position < b.frontend_settings?.smart?.position) {
          return -1
        }
        if (a.frontend_settings?.smart?.position > b.frontend_settings?.smart?.position) {
          return 1
        }
        return a.name.localeCompare(b.name)
      })
      .forEach(segment => {
        const segmentGroupId = segment.frontend_settings?.smart?.group_id
        if (segmentGroupId) {
          if (segmentBuckets[segmentGroupId]) {
            segmentBuckets[segmentGroupId].push(segment)
          } else {
            segmentBuckets[segmentGroupId] = [segment]
          }
        } else {
          if (segmentBuckets["uncategorized"]) {
            segmentBuckets["uncategorized"].push(segment)
          } else {
            segmentBuckets["uncategorized"] = [segment]
          }
        }
      })

    return segmentBuckets
  }

  componentDidMount() {
    const { fetchAllSmartSegments } = this.props
    fetchAllSmartSegments()
      .then(response => {
        this.setState({
          segmentBuckets: this.assignSegmentsToGroups(),
        })
      })
      .catch(_noop)
  }

  createSegment = async () => {
    const { createSegment, history, showToast } = this.props

    this.setState({ isCreatingSegment: true })

    try {
      const {
        value: { segment },
      } = await createSegment(
        {
          name: "Untitled smart segment",
          prebuilt: 1,
          frontend_settings: { smart: { position: 1 } },
        },
        "smart",
      )

      history.push(getRoutePath("setup.segments.smart.detail", { id: segment.id }))
      showToast(`Smart segment created.`, TOAST.TYPE.SUCCESS)
    } catch (error) {
      this.setState({ isCreatingSegment: false })
    }
  }

  renderSmartSegments = () => {
    const { createAllowed } = this.props
    const { segmentBuckets } = this.state

    let prevColor = null
    const groupsToRender = smartSegmentGroups
      .map(group => {
        if (segmentBuckets[group.id]) {
          const hasBigerMargin = group.color !== prevColor
          prevColor = group.color
          return (
            <Paper
              className={`segments-group ${group.color} ${hasBigerMargin ? "bigger-margin" : ""}`}
              key={group.id}
            >
              <h3>{group.name}</h3>
              <div className="segments">
                {segmentBuckets[group.id].map(segment => (
                  <SmartSegmentTile
                    key={segment.id}
                    name={segment.name}
                    description={segment.frontend_settings?.smart?.description}
                    className="segment-tile"
                    color={group.color}
                    icon={segment.frontend_settings?.smart?.icon}
                    link={getRoutePath(
                      createAllowed ? "setup.segments.smart.detail" : "segments.smart.detail",
                      { id: segment.id },
                    )}
                  />
                ))}
              </div>
            </Paper>
          )
        }
        prevColor = group.color
        return null
      })
      .toArray()

    if (segmentBuckets.uncategorized) {
      groupsToRender.push(
        <Paper className="segments-group gumbo bigger-margin" key="uncategorized">
          <h3>Uncategorized</h3>
          <div className="segments">
            {segmentBuckets["uncategorized"].map(segment => (
              <SmartSegmentTile
                key={segment.id}
                name={segment.name}
                description={segment.frontend_settings?.smart?.description}
                className="segment-tile"
                color="gumbo"
                icon={segment.frontend_settings?.smart?.icon}
                link={getRoutePath(
                  createAllowed ? "setup.segments.smart.detail" : "segments.smart.detail",
                  { id: segment.id },
                )}
              />
            ))}
          </div>
        </Paper>,
      )
    }

    return groupsToRender
  }

  render() {
    const { smartSegments, areSmartSegmentsFetching, areSmartSegmentsFulfilled, createAllowed } =
      this.props
    const { isCreatingSegment } = this.state

    return (
      <section className="wrapper smart-segments-list">
        <PaperHeader titleText="Smart segments" size="small" className="smart-segments-header">
          {createAllowed && (
            <Button
              color="primary"
              size="small"
              className="create-smart-segment-button"
              onClick={this.createSegment}
              isLoading={isCreatingSegment}
            >
              + Create smart segment
            </Button>
          )}
        </PaperHeader>
        {!areSmartSegmentsFulfilled && areSmartSegmentsFetching && (
          <LoadingIndicator className="segments-fetching" />
        )}
        {areSmartSegmentsFulfilled && smartSegments.length === 0 && (
          <p className="info-message">
            {createAllowed ? (
              <span>Click on the "Create Smart Segment" to get started.</span>
            ) : (
              <span>
                Smart segments not set.{" "}
                {hasAccess.setup.smartSegments()
                  ? "Go to Setup / Smart Segments tab to create smart segments."
                  : "Contact admin to set smart segments for your customer's database."}
              </span>
            )}
          </p>
        )}
        {areSmartSegmentsFulfilled && smartSegments.length > 0 && this.renderSmartSegments()}
      </section>
    )
  }
}

SmartSegmentsList.propTypes = {
  fetchAllSmartSegments: PropTypes.func.isRequired,
  smartSegments: PropTypes.array.isRequired,
  areSmartSegmentsFulfilled: PropTypes.bool,
  areSmartSegmentsFetching: PropTypes.bool,
  createAllowed: PropTypes.bool,
}

const mapStateToProps = state => ({
  smartSegments: getSegments(state, true),
  areSmartSegmentsFulfilled: getIsFulfilled(state, true),
  areSmartSegmentsFetching: getSmartSegmentsIsFetching(state),
})

export default connect(mapStateToProps, { fetchAllSmartSegments, showToast, createSegment })(
  withRouter(SmartSegmentsList),
)
