import React, { useCallback, useRef, useState } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import SegmentPickerBar from "./SegmentPickerBar/SegmentPickerBar"
import { Segment } from "resources/segment/segment/segmentTypes"
import { SegmentOption } from "./types"
import useClickOutHandler from "hooks/useClickOutHandler"
import useDebounce from "hooks/useDebounce"
import useKeyListener from "hooks/useKeyListener"
import {
  useFetchFeaturedSegments,
  useFetchRegularSegments,
  useFetchSmartSegments,
} from "resources/segment/segment/segmentQueries"

import styles from "./SegmentPicker.module.scss"
import { prop, sort } from "ramda"
import { ascend } from "utilities/comparators"
import api from "resources/endpoints"
import { useQuery } from "@tanstack/react-query"

type SegmentType = SegmentOption["type"]

type Props = {
  onChange: (newValue: SegmentOption) => void
  value: SegmentOption["value"] | null
  className?: string
  errorMessage?: string
}

const getSegmentOptions = (segments: Array<Segment>, segmentType: SegmentType) =>
  segments.map(({ id, name }) => ({
    label: name,
    value: id,
    type: segmentType,
  }))

const SegmentPicker = ({ className, errorMessage, onChange, value: segmentId }: Props) => {
  const [focused, setFocused] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)

  const onClose = useCallback(() => {
    setSearchTerm("")
    setFocused(false)
    inputRef.current?.blur()
  }, [])

  const { isOpen, open, close, ref, toggle } = useClickOutHandler({
    closeCallback: onClose,
    openCallback: () => setFocused(true),
    preventCloseIf: e =>
      ["SegmentPicker_input", "SegmentPicker_button", "SegmentPickerBar"].some(className =>
        (e.target as HTMLElement)?.className.includes(className),
      ),
  })

  useKeyListener("Escape", close)

  const debouncedSearchTerm = useDebounce(searchTerm)

  const {
    data: regularSegmentResponse,
    fetchNextPage: fetchRegularNextPage,
    hasNextPage: hasNextPageRegular,
    isLoading: isLoadingRegular,
  } = useFetchRegularSegments(debouncedSearchTerm)
  const {
    data: featuredSegmentResponse,
    fetchNextPage: fetchFeaturedNextPage,
    hasNextPage: hasNextPageFeatured,
    isLoading: isLoadingFeatured,
  } = useFetchFeaturedSegments(debouncedSearchTerm)
  const {
    data: smartSegmentResponse,
    fetchNextPage: fetchSmartNextPage,
    hasNextPage: hasNextPageSmart,
    isLoading: isLoadingSmart,
  } = useFetchSmartSegments(searchTerm)

  const fetchNextPage = (category: SegmentType) => {
    switch (category) {
      case "custom":
        fetchRegularNextPage()
        break
      case "featured":
        fetchFeaturedNextPage()
        break
      case "smart":
        fetchSmartNextPage()
        break
    }
  }

  const hasNextPage = (category: SegmentType) => {
    switch (category) {
      case "custom":
        return hasNextPageRegular ?? false
      case "featured":
        return hasNextPageFeatured ?? false
      case "smart":
        return hasNextPageSmart ?? false
      default:
        return false
    }
  }

  const segmentOptions = [
    ...getSegmentOptions(regularSegmentResponse, "custom"),
    ...getSegmentOptions(featuredSegmentResponse, "featured"),
    ...getSegmentOptions(smartSegmentResponse, "smart"),
  ]

  let selectedSegmentOption = segmentOptions.find(item => item.value === segmentId) ?? null

  // TODO: This is a quick fix, create custom hook later
  const { data: segment } = useQuery(
    ["segment", segmentId],
    () => api.segment.retrieve(segmentId!),
    {
      select: ({ segment }) => segment,
      enabled:
        segmentId !== null &&
        // TODO: Find out how to ensure that undefined doesn't sneak in here; should be null
        segmentId !== undefined,
      staleTime: Infinity,
    },
  )

  if (segment && selectedSegmentOption === null) {
    selectedSegmentOption = {
      label: segment.name,
      value: segment.id,
      type: segment.prebuilt ? "smart" : segment.featured ? "featured" : "custom",
    }
    segmentOptions.push(selectedSegmentOption)
  }

  const sortedSegmentOptions = sort(ascend(prop("label")), segmentOptions)

  return (
    <div className={className} data-testid="segment-picker">
      <label className={styles.label}>Segment</label>
      <div className={styles.wrapper}>
        <input
          ref={inputRef}
          autoComplete="off"
          className={classNames(styles.input, {
            [styles.error]: errorMessage,
            [styles.open]: focused || !selectedSegmentOption,
          })}
          data-testid="segment-picker-input"
          placeholder={selectedSegmentOption?.label ?? "Select segment or type to search"}
          type="text"
          value={searchTerm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSearchTerm(event.target.value)
          }
          onFocus={open}
        />
        <button type="button" className={styles.button} onClick={toggle}>
          <FontAwesomeIcon
            icon={["fas", focused ? "caret-up" : "caret-down"]}
            className={styles.icon}
          />
        </button>
        {isOpen && (
          <SegmentPickerBar
            ref={ref}
            loading={isLoadingRegular || isLoadingFeatured || isLoadingSmart}
            searchMode={!!debouncedSearchTerm}
            options={sortedSegmentOptions}
            selectedOption={selectedSegmentOption}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            onSelect={newValue => {
              onChange(newValue)
              close()
            }}
          />
        )}
      </div>
      {errorMessage && (
        <p className={styles.errorMessage} data-test-id="segment-picker-errorMessage">
          {errorMessage}
        </p>
      )}
    </div>
  )
}

export default SegmentPicker
