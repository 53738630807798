import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import Trash from "pages/Trash/Trash"
import UserSettings from "pages/UserSettings/UserSettings"
import NotAuthorizedPage from "pages/NotAuthorized/NotAuthorized"
import NotFoundPage from "pages/NotFound/NotFound"
import HomePage from "pages/HomePage/HomePage"
import { hasAccess } from "helpers/authenticatedUser.helper"
import Segments from "pages/Segments/SegmentsRoutes"
import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import Setup from "pages/Setup/SetupRoutes"
import Data from "pages/Data/DataRoutes"
import Reporting from "pages/Reporting/ReportingRoutes"
import Administration from "pages/Administration/AdministrationRoutes"
import Customers from "pages/Customers/CustomersRoutes"
import Personalization from "pages/Personalization/PersonalizationRoutes"

export default function AuthenticatedRoutesSwitch() {
  return (
    <Switch>
      <Route path={getRoutePath("not-authorized")} component={NotAuthorizedPage} />
      <Route path={getRoutePath("not-found")} component={NotFoundPage} />

      <Route path={getRoutePath("home")} exact component={HomePage} />
      <Route path={getRoutePath("customers")} component={Customers} />
      <Route path={getRoutePath("segments")} component={Segments} />
      <AuthorizedRoute
        hasAccess={hasAccess.data.general()}
        path={getRoutePath("data")}
        component={Data}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.reports()}
        path={getRoutePath("reporting")}
        component={Reporting}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.webBanners.view()}
        path={getRoutePath("personalization")}
        component={Personalization}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.general()}
        path={getRoutePath("setup")}
        component={Setup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.administration.general()}
        path={getRoutePath("administration")}
        component={Administration}
      />
      <Route path={getRoutePath("trash")} component={Trash} />
      <Route path={getRoutePath("user-settings")} component={UserSettings} />

      <Route component={NotFoundPage} />
    </Switch>
  )
}
