import { Record } from "immutable"

const Acl = Record({
  permission: "",
  created: "",
  user_id: null,
  segment_id: null,
})

export default Acl
