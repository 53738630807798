import _get from "lodash/get"

import { ACL } from "sharedConstants"
import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchSegmentsAclList = segmentId => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: ACL.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit) => api.acl.segment.list(segmentId, offset, limit))
          .setDataPath("segment_users")
          .run()
        return {
          segmentId,
          data,
        }
      })(),
    },
  })
}

export const fetchUsersAclList = userId => async dispatch => {
  return dispatch({
    type: ACL.USER.ACTION.LIST,
    payload: {
      promise: (async () => {
        const response = await api.acl.user.list(userId)
        return _get(response, "user_segments", {})
      })(),
    },
  })
}

export const createAcl = (segmentId, data) => async dispatch =>
  dispatch({
    type: ACL.ACTION.CREATE,
    payload: {
      promise: (async () => {
        const response = await api.acl.segment.create(segmentId, data)
        return _get(response, "segment_user", {})
      })(),
    },
  })

export const modifyAcl = (segmentId, userId, data) => async dispatch =>
  dispatch({
    type: ACL.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api.acl.segment.user.modify(segmentId, userId, data)
        return _get(response, "segment_user", {})
      })(),
    },
  })

export const deleteAcl = acl => async dispatch =>
  dispatch({
    type: ACL.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api.acl.segment.user.delete(acl.segment_id, acl.user_id)
        return {
          segmentId: acl.segment_id,
          userId: acl.user_id,
        }
      })(),
    },
  })
