import { LABEL } from "sharedConstants"
import _mapKeys from "lodash/mapKeys"

const initialState = {
  data: {},
  isFetching: false,
  isFulfilled: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${LABEL.ACTION.LIST}_PENDING`:
      return {
        ...state,
        isFetching: true,
      }
    case `${LABEL.ACTION.LIST}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        isFulfilled: true,
        data: _mapKeys(payload, "id"),
      }
    case `${LABEL.ACTION.CREATE}_FULFILLED`:
    case `${LABEL.ACTION.MODIFY}_FULFILLED`:
      return {
        ...state,
        data: { ...state.data, [payload.id]: payload },
      }
    case `${LABEL.ACTION.DELETE}_FULFILLED`:
      const { [payload]: _, ...newData } = state.data
      return {
        ...state,
        data: newData,
      }
    default:
      return state
  }
}
