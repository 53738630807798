import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TrashItemType } from "pages/Trash/trashTypes"
import getReadableItemType from "pages/Trash/utilities/getReadableItemType"
import styles from "./TrashItemTypeChip.module.scss"

type ItemTypeChipProps = {
  type: TrashItemType
}

export default function ItemTypeChip({ type }: ItemTypeChipProps) {
  let icon: IconProp = "database"
  switch (type) {
    case "embedded_web_banners":
    case "popup_web_banners":
      icon = "globe"
      break
    case "segment_export_destinations":
      icon = "database"
      break
    case "segments":
      icon = "filter"
      break
    case "users":
      icon = "users"
      break
    case "user_roles":
      icon = "user-edit"
      break
    default:
      break
  }
  return (
    <span className={styles.itemTypeChip}>
      <FontAwesomeIcon icon={icon} className={styles.icon} />
      {getReadableItemType(type)}
    </span>
  )
}
