import { MouseEvent, useCallback, useRef, useState } from "react"

type ClickOutHandlerProps = {
  openCallback?: () => void
  closeCallback?: () => void
  preventCloseIf?: (e: MouseEvent) => boolean
  initState?: boolean
}

export default function useClickOutHandler({
  openCallback,
  closeCallback,
  preventCloseIf,
  initState = false,
}: ClickOutHandlerProps = {}) {
  const [isOpen, setIsOpen] = useState(initState)
  const ref = useRef<HTMLDivElement>(null)

  const close = useCallback(() => {
    closeCallback?.()
    setIsOpen(false)
    document.removeEventListener("click", clickHandlerRef.current, false)
  }, [closeCallback])

  const handleClickOut = useCallback(
    e => {
      if (ref.current && !ref.current.contains(e.target) && !preventCloseIf?.(e)) {
        close()
      }
    },
    [close, preventCloseIf],
  )

  const clickHandlerRef = useRef(handleClickOut)

  const open = useCallback(() => {
    openCallback?.()
    setIsOpen(true)
    clickHandlerRef.current = handleClickOut
    setTimeout(() => document.addEventListener("click", clickHandlerRef.current, false), 0)
  }, [handleClickOut, openCallback])

  const toggle = useCallback(() => {
    if (isOpen) {
      close()
    } else {
      open()
    }
  }, [close, isOpen, open])

  return { isOpen, open, close, toggle, ref }
}
