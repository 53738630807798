import { isAndOrCondition } from "components/ConditionBuilder/utils"
import { ConditionTree } from "types/conditionTree"
import { SegmentCondition } from "../segmentConditionsTypes"
import { Segment } from "../segmentTypes"
import { migrateMultiValueCondition } from "./segmentConditionsMigration"

// Incoming transformations

function segmentConditionFromAPIFormat(conditionTree: any): ConditionTree<SegmentCondition> {
  if (conditionTree === null) return conditionTree

  if (conditionTree.attribute_id !== undefined) {
    // A leaf condition
    return migrateMultiValueCondition(conditionTree)
  }

  return {
    // Rename 'operation' to 'operator'
    operator: conditionTree.operation,
    operands: conditionTree.operands.map(segmentConditionFromAPIFormat),
  }
}

export function segmentFromAPIFormat(segment: any): Segment {
  if (!segment.settings?.conditions_operation) return segment

  return {
    ...segment,
    settings: {
      ...segment.settings,
      conditions_operation: segmentConditionFromAPIFormat(segment.settings.conditions_operation),
    },
  }
}

// Outgoing transformations

function segmentConditionToAPIFormat(conditionTree: ConditionTree<SegmentCondition>): any {
  if (isAndOrCondition(conditionTree)) {
    return {
      operation: conditionTree.operator,
      operands: conditionTree.operands.map(segmentConditionToAPIFormat),
    }
  }

  return conditionTree
}

export function segmentToAPIFormat(segment: Partial<Segment>) {
  if (!segment.settings?.conditions_operation) return segment

  return {
    ...segment,
    settings: {
      ...segment.settings,
      conditions_operation: segmentConditionToAPIFormat(segment.settings.conditions_operation),
    },
  }
}
