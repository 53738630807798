import React, { useState } from "react"
import Modal from "components/UI/elements/Modal"
import ConfirmModal from "../ConfirmModal"
import TemplateGalleryItem, { Template } from "./TemplateGalleryItem/TemplateGalleryItem"
import Button from "components/UI/elements/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./TemplateGallery.module.scss"
import { prop, sort } from "ramda"
import { ascend } from "utilities/comparators"

type TemplateGalleryProps = {
  templates: Template[]
  isTextAreaEmpty: boolean
  insertTemplate: (template: Template) => void
}

const TemplateGallery = ({ insertTemplate, templates, isTextAreaEmpty }: TemplateGalleryProps) => {
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null)

  const openGalleryModal = () => {
    setIsGalleryModalOpen(true)
  }

  const closeGalleryModal = () => {
    setIsGalleryModalOpen(false)
    setSelectedTemplate(null)
  }

  const confirmSelection = () => {
    if (selectedTemplate === null) {
      return
    }

    if (isTextAreaEmpty) {
      insertTemplate(selectedTemplate)
      closeGalleryModal()
    } else {
      setIsGalleryModalOpen(false)
      setIsConfirmModalOpen(true)
    }
  }

  const confirmOverwrite = () => {
    if (selectedTemplate === null) {
      return
    }

    insertTemplate(selectedTemplate)
    setIsConfirmModalOpen(false)
  }

  const cancelOverwrite = () => {
    setIsConfirmModalOpen(false)
    openGalleryModal()
  }

  return (
    <>
      <Button onClick={openGalleryModal} color="primary" size="small" className={styles.addButton}>
        <FontAwesomeIcon icon={["fas", "folder"]} /> template gallery
      </Button>
      <ConfirmModal
        open={isConfirmModalOpen}
        type="success"
        handleClose={cancelOverwrite}
        handleConfirm={confirmOverwrite}
        title="Replace banner HTML"
        text="Do you want to replace banner HTML?"
      />
      <Modal
        className={styles.galleryModal}
        title="HTML banners template gallery"
        open={isGalleryModalOpen}
        handleClose={closeGalleryModal}
      >
        <>
          <div className={styles.galleryModalContent}>
            {sort(ascend(prop("name")), templates).map(template => {
              return (
                <TemplateGalleryItem
                  key={template.id}
                  isSelected={selectedTemplate?.id === template.id}
                  onClick={() => setSelectedTemplate(template)}
                  template={template}
                />
              )
            })}
          </div>
          <div className={styles.actionButtons}>
            <Button
              color="transparent-grey"
              className={styles.cancelButton}
              size="big"
              onClick={closeGalleryModal}
            >
              Cancel
            </Button>
            <Button
              disabled={selectedTemplate === null}
              color={"primary"}
              size="big"
              onClick={confirmSelection}
            >
              Select
            </Button>
          </div>
        </>
      </Modal>
    </>
  )
}

export default TemplateGallery
