import { createReport } from "resources/report/reportActions"
import { showToast } from "actions/toast.action"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { getRoutePath } from "routes"
import ReportForm, { ReportFormValues } from "../components/ReportForm/ReportForm"

export default function () {
  const dispatch = useDispatch()
  const history = useHistory()

  const onCreateReport = useCallback(
    async ({ name, dashboard_id, disabled }: ReportFormValues) => {
      await dispatch(
        createReport({
          name,
          dashboard_id,
          disabled,
        }),
      )
      history.push(getRoutePath("setup.reports"))
      dispatch(showToast("Report created."))
    },
    [dispatch, history],
  )

  return <ReportForm onSubmit={onCreateReport} />
}
