import React, { ComponentProps } from "react"
import PropTypes from "prop-types"
import "./PaperHeader.scss"
import classNames from "classnames"

type PaperHeaderProps = {
  size?: "small"
  titleText?: string
  subTitleText?: string
} & ComponentProps<"div">

const PaperHeader = ({ className, children, size, titleText, subTitleText }: PaperHeaderProps) => (
  <div className={classNames("paper-header", size, className)}>
    {titleText && (
      <div>
        <h3 className="title">{titleText}</h3>
        {subTitleText && <h4 className="subtitle">{subTitleText}</h4>}
      </div>
    )}
    {children}
  </div>
)

PaperHeader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  titleText: PropTypes.string,
  subTitleText: PropTypes.string,
}

export default PaperHeader
