import React, { Component } from "react"
import PropTypes from "prop-types"
import { Form, reduxForm, Field } from "redux-form"
import _get from "lodash/get"

import SearchField from "../../elements/SearchField"
import SelectField from "../../elements/SelectField"

import "./SearchFormWithSelect.scss"

class SearchFormWithSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      typingTimeout: 0,
    }
  }

  onSubmit = values => {
    const { onSubmit } = this.props
    if (onSubmit) {
      // submit field only when onSubmit is passed to props
      const filterTerm = _get(values, "search", "")
      onSubmit(filterTerm)
    }
  }

  onSearchFieldChange = () => {
    if (this.props.onSubmit) {
      const { typingTimeout } = this.state
      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }

      this.setState({
        typingTimeout: setTimeout(this.props.handleSubmit(this.onSubmit), 400),
      })
    }
  }

  render() {
    const { inputPlaceholder, className, handleSubmit, selectOptions, selectPlaceholder } =
      this.props
    return (
      <Form
        name="search-form"
        className={`search-form-with-select ${className ? className : ""}`}
        autoComplete="off"
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <Field
          name="select"
          component={SelectField}
          options={selectOptions}
          className="select-picker"
          isSearchable={true}
          isClearable={true}
          placeholder={selectPlaceholder}
          size="big"
          focusedBorderColor="#C4C4C4"
        />
        <Field
          name="search"
          component={SearchField}
          placeholder={inputPlaceholder}
          onChange={this.onSearchFieldChange}
        />
      </Form>
    )
  }
}

SearchFormWithSelect.propTypes = {
  inputPlaceholder: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  initialValues: PropTypes.object,
  form: PropTypes.string.isRequired,
  selectOptions: PropTypes.array,
  selectPlaceholder: PropTypes.string.isRequired,
}

export default reduxForm({
  destroyOnUnmount: false,
})(SearchFormWithSelect)

export const SearchFormWithSelectDestroyable = reduxForm({
  destroyOnUnmount: true,
})(SearchFormWithSelect)
