export const selectStyles = (
  size = "big",
  border = "all",
  maxMenuListHeight = "300px",
  focusedBorderColor = "#FE7F66",
) => {
  let height = "44px"
  if (size === "small") {
    height = "34px"
  } else if (size === "large") {
    height = "52px"
  } else if (size === "tag") {
    height = "24px"
  }
  return {
    control: (base, state) => ({
      ...base,
      ":hover": {
        borderColor: "#999999",
      },
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: state.isFocused ? `${focusedBorderColor} !important` : "#C4C4C4",
      boxShadow: state.isFocused ? "0 1px 3px 0px rgba(254, 127, 102, 0.25)" : "none",
      borderRadius: "4px",
      borderTopLeftRadius: border === "no-left" ? "0" : "4px",
      borderBottomLeftRadius: border === "no-left" ? "0" : "4px",
      borderLeftWidth: border === "no-left" ? "0" : "1px",
      minHeight: height,
      outline: "none",
      backgroundColor: "#ffffff",
      fontSize: size === "tag" ? "9px" : "15px",
      textTransform: size === "tag" ? "uppercase" : "none",
      letterSpacing: size === "tag" ? "0.15em" : "normal",
      cursor: "pointer",
    }),
    valueContainer: (base, state) => ({
      ...base,
      color: "#222222",
      paddingLeft: "12px",
    }),
    menu: (base, state) => ({
      ...base,
      borderRadius: "4px",
      marginTop: "2px",
    }),
    menuList: (base, state) => ({
      ...base,
      maxHeight: maxMenuListHeight,
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#FE7F66" : state.isFocused ? "#fff2f0" : null,
      color: state.isSelected ? "#FFFFFF" : null,
      cursor: "pointer",
      ":active": {
        backgroundColor: "#EB6C52",
        color: "#ffffff",
      },
      fontSize: size === "tag" ? "9px" : "15px",
      textTransform: size === "tag" ? "uppercase" : "none",
      letterSpacing: size === "tag" ? "0.15em" : "normal",
    }),
    placeholder: base => ({
      ...base,
      color: "#929292",
    }),
    noOptionsMessage: base => ({
      ...base,
      color: "#999999",
      fontSize: size === "tag" ? "9px" : "15px",
      textTransform: size === "tag" ? "uppercase" : "none",
      letterSpacing: size === "tag" ? "0.15em" : "normal",
      fontStyle: "italic",
      textAlign: "left",
    }),
  }
}

//edit valueContainer for font style
