import { Template } from "components/UI/components/TemplateGallery/TemplateGalleryItem/TemplateGalleryItem"
import imgSrc from "./formWithPromoCode.jpg"

const formWithPromoCode: Template = {
  id: "formWithPromoCode",
  name: "Form with promo code",
  description:
    "Collect information about your customers to improve targeting and provide promo codes as a thank you.",
  imgSrc,
  width: 400,
  highlight: null,
  docLink:
    "https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-use-cases-library-html-for-data-collection-thank-you-page-with-promo-code",
  html: `<!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="stylesheet" href="https://use.typekit.net/txy5jpc.css" />
      <style>
        body {
          margin: 0px;
          font-family: sans-serif;
          font-size: 14px;
          color: #222222;
        }

        .banner {
          box-sizing: border-box;
          width: 100%;
          height: 300px;
          border-radius: 8px;
          padding: 15px;
          border: 1px solid #777777;
          background-color: white;
          position: relative;
        }

        h1 {
          margin: 0 0 10px 0;
          font-size: 18px;
          font-weight: 600;
        }

        .label {
          font-size: 13px;
          font-weight: 600;
        }

        .pet-type-container,
        .name-bday-container,
        .checkbox-container {
          margin-top: 3px;
          margin-bottom: 10px;
        }

        .pet-name-input {
          width: 110px;
        }

        .pet-bday-input {
          width: 110px;
        }

        .pet-name-input,
        .pet-bday-input {
          height: 30px;
          padding: 0 6px;
          font-size: 14px;
          border: 1px solid #777777;
          border-radius: 4px;
        }

        .checkbox-container {
          font-size: 13px;
        }

        .submit-button {
          width: 105px;
          height: 40px;
          background: #222222;
          border: 0;
          border-radius: 20px;
          font-size: 14px;
          font-weight: 600;
          color: white;
          cursor: pointer;
          transition: all 0.15s ease;
        }

        .submit-button:hover {
          background-color: black;
        }

        #page-2 p {
          margin: 10px 0;
        }

        .promo-code-container {
          display: flex;
          align-items: center;
          border: 1px solid #999999;
          margin: 10px 20px;
          padding: 5px;
          padding-left: 10px;
          border-radius: 6px;
        }

        #promo-code {
          font-size: 16px;
          font-weight: 600;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        #copy-button {
          margin-left: 10px;
          background-color: white;
          border: 1px solid #777777;
          border-radius: 4px;
          padding: 5px 10px;
          font-size: 14px;
          color: #222222;
          cursor: pointer;
          transition: all 0.15s ease;
        }

        #copy-button:hover {
          color: black;
          border-color: #222222;
        }

        .validity {
          color: #777777;
          font-size: 13px;
          font-style: italic;
        }

        .img-container {
          display: flex;
          align-items: flex-end;
          width: 80%;
          position: absolute;
          left: 10%;
          bottom: 0;
        }

        .animal-img {
          flex: 1;
          width: 50%;
          height: auto;
        }
      </style>
    </head>
    <body>
      <div class="banner">
        <div id="page-1">
          <h1>
            We would like to know more about your pets, so that we can offer you the best products.
          </h1>

          <form id="pet-form" onsubmit="submitForm(event)">
            <div class="label">Pet type</div>
            <div class="pet-type-container">
              <label>
                <input type="radio" name="petType" value="dog" required />
                Dog</label
              >
              <label>
                <input type="radio" name="petType" value="cat" required />
                Cat</label
              >
              <label>
                <input type="radio" name="petType" value="other" required />
                Other</label
              >
            </div>

            <div class="label">Pet name and date of birth</div>
            <div class="name-bday-container">
              <input class="pet-name-input" type="text" name="petName" placeholder="Name" required />
              <input class="pet-bday-input" type="date" name="petBirthday" required />
            </div>

            <div class="checkbox-container">
              <label>
                <input type="checkbox" name="consent" required />

                I agree with the
                <a target="_blank" href="https://www.example.com">Terms and conditions</a>.
              </label>
            </div>

            <button class="submit-button">Submit</button>
          </form>
        </div>

        <div id="page-2" style="display: none">
          <h1>The form was submitted successfully.</h1>

          <p>As a thank you, here is a discount code for you:</p>
          <div class="promo-code-container">
            <div id="promo-code"></div>
            <button id="copy-button" onclick="copyText()">Copy</button>
          </div>

          <p class="description">
            Use this code to get a <strong>5 %</strong> discount on any animal care products.
          </p>
          <p class="validity">This code is valid until December 31 2022.</p>

          <div class="img-container">
            <img class="animal-img" src="https://www.meiro.io/banner/Dog_1.png" alt="dog" />
            <img class="animal-img" src="https://www.meiro.io/banner/cat_1.png" alt="cat" />
          </div>
        </div>
      </div>

      <script>
        function copyText() {
          var promoCodeEl = document.getElementById("promo-code")
          document.getSelection().selectAllChildren(promoCodeEl)
          document.execCommand("copy")
          document.getSelection().removeAllRanges()
          document.getElementById("copy-button").textContent = "Copied ✅"
        }

        function submitForm(event) {
          event.preventDefault()

          document.getElementById("page-1").style = "display: none"
          document.getElementById("page-2").style = ""

          var form = event.target
          var data = {
            name: form.petName.value,
            birthday: form.petBirthday.value,
            type: form.petType.value,
          }

          if (window.parent && window.parent.MeiroEvents) {
            var bannerID = window.parent.MeiroEvents.getWebBannerId()
            var responses = window.parent.MeiroEvents.getWebBannerHttpResponses(bannerID)
            var promoCode = responses[0].data.promo_code

            document.getElementById("promo-code").textContent = promoCode

            var payload = {
              action: "web_banner_submit",
              banner_id: window.frameElement.getAttribute("data-bannerid"),
              data: data,
            }

            window.parent.MeiroEvents.track("custom_event", payload)
          }
        }
      </script>
    </body>
  </html>
  `,
}

export default formWithPromoCode
