import React, { useCallback, useEffect, useState } from "react"
import styles from "./SegmentUsersModal.module.scss"
import Modal from "components/UI/elements/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SegmentUser } from "types/segmentUsers"
import { UserFull } from "resources/user/userTypes"
import { Segment, SegmentType } from "resources/segment/segment/segmentTypes"
import Button from "components/UI/elements/Button/Button"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import Avatar from "components/UI/elements/Avatar"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import { PERMISSION, SYSTEM_USER_ID } from "sharedConstants"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import { Permission } from "types/util"
import { adjust, append, equals, remove, without } from "ramda"
import SelectField from "components/UI/elements/SelectField"
import { createAcl, deleteAcl, modifyAcl } from "actions/acl.action"
import { showToast } from "actions/toast.action"
import Tippy from "@tippyjs/react"

type SegmentUsersModalProps = {
  isOpen: boolean
  onClose: () => void
  segmentId: Segment["id"]
  authorId: Segment["author_id"]
  acl: SegmentUser[]
  allUsers: Record<UserFull["id"], UserFull>
  isEditable: boolean
  segmentType: SegmentType
}

const getOppositePermission = (permission: Permission) =>
  permission === PERMISSION.READ ? PERMISSION.WRITE : PERMISSION.READ

const canEditAllSegments = (user: UserFull, segmentType: SegmentType) =>
  segmentType === "featured"
    ? user.role.features.includes("featured_segments/edit")
    : user.role.features.includes("foreign_segments/edit")

const canViewAllSegments = (user: UserFull, segmentType: SegmentType) =>
  segmentType === "featured"
    ? user.role.features.includes("featured_segments/view")
    : user.role.features.includes("foreign_segments/view")

export default function SegmentUsersModal({
  isOpen,
  onClose,
  segmentId,
  authorId,
  acl,
  allUsers,
  isEditable,
  segmentType,
}: SegmentUsersModalProps) {
  const dispatch = useDispatch()

  const [isSaving, setIsSaving] = useState(false)
  const [isAddingUsers, setIsAddingUsers] = useState(false)

  // creating manually ACL object as user may be deleted and then he's not included in acl[]
  const authorAccess: SegmentUser = {
    user_id: authorId,
    segment_id: segmentId,
    permission: PERMISSION.WRITE,
    created: "",
  }
  const sortedAcl = [authorAccess, ...acl.filter(({ user_id }) => user_id !== authorId)]

  const [pendingRemoves, setPendingRemoves] = useState<UserFull["id"][]>([])
  const [pendingChanges, setPendingChanges] = useState<
    { userId: UserFull["id"]; newPermission: Permission }[]
  >([])
  const [pendingInvites, setPendingInvites] = useState<
    { inviteeId?: UserFull["id"]; permission?: Permission }[]
  >([{}])

  useEffect(() => {
    setPendingRemoves([])
    setPendingChanges([])
    setPendingInvites([{}])
    setIsAddingUsers(false)
  }, [isOpen])

  // existing users

  const toggleUserPermission = useCallback(
    toggledId => {
      setPendingChanges(changes => {
        const existingChange = changes.find(({ userId }) => userId === toggledId)
        const existingPermission = acl.find(({ user_id }) => user_id === toggledId)!

        return existingChange
          ? without([existingChange], changes)
          : changes.concat({
              userId: toggledId,
              newPermission: getOppositePermission(existingPermission.permission),
            })
      })
    },
    [acl],
  )

  const removeUser = useCallback(userId => {
    setPendingChanges(without([userId]))
    setPendingRemoves(append(userId))
  }, [])

  // invites

  const remainingUsersToInvite = Object.values(allUsers).filter(
    user =>
      !user.disabled &&
      user.id !== SYSTEM_USER_ID &&
      !acl.find(({ user_id }) => user_id === user.id) && // user is not already in segment ACL
      !pendingInvites.find(({ inviteeId }) => inviteeId === user.id), // user is not already selected among invites
  )

  const getInviteeDropdownOptions = useCallback(
    (index: number) => {
      const selectedUserId = pendingInvites[index].inviteeId

      // if a user is selected in the dropdown they must be included in the dropdown options
      return (
        selectedUserId
          ? remainingUsersToInvite.concat(allUsers[selectedUserId])
          : remainingUsersToInvite
      )
        .map(({ id, name, email }) => ({ value: id, name, email, label: name }))
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    },
    [allUsers, pendingInvites, remainingUsersToInvite],
  )

  const areThereUnusedOptions = getInviteeDropdownOptions(0).length > 1
  const areAllRowsFilledOut = pendingInvites.every(({ inviteeId }) => inviteeId)

  const toggleInviteePermission = useCallback(index => {
    setPendingInvites(invites =>
      adjust(
        index,
        ({ inviteeId, permission }) => ({
          inviteeId,
          permission: getOppositePermission(permission!),
        }),
        invites,
      ),
    )
  }, [])

  const removeInvitee = useCallback(
    index => {
      setPendingInvites(pendingInvites.length > 1 ? remove(index, 1) : [{}])
    },
    [pendingInvites.length],
  )

  const addRow = useCallback(() => {
    setPendingInvites(append({}))
  }, [])

  const setInvitee = useCallback(
    (userId, index) => {
      setPendingInvites(invites =>
        adjust(
          index,
          ({ permission }) => ({
            inviteeId: userId,
            permission: canEditAllSegments(allUsers[userId], segmentType)
              ? PERMISSION.WRITE
              : permission ?? PERMISSION.READ,
          }),
          invites,
        ),
      )
    },
    [allUsers, segmentType],
  )

  const inviteAllUsers = useCallback(() => {
    setPendingInvites(invites =>
      without([{}], invites).concat(
        remainingUsersToInvite.map(user => ({
          inviteeId: user.id,
          permission: canEditAllSegments(user, segmentType) ? PERMISSION.WRITE : PERMISSION.READ,
        })),
      ),
    )
    setIsAddingUsers(true)
  }, [remainingUsersToInvite, segmentType])

  // general

  const saveChanges = useCallback(async () => {
    setIsSaving(true)

    const changePromises = Promise.all(
      pendingChanges.map(({ userId, newPermission }) =>
        dispatch(modifyAcl(segmentId, userId, { permission: newPermission })),
      ),
    )
    const removePromises = Promise.all(
      pendingRemoves.map(userId =>
        dispatch(deleteAcl(acl.find(({ user_id }) => user_id === userId))),
      ),
    )
    const invitePromises = Promise.all(
      without([{}], pendingInvites).map(({ inviteeId, permission }) =>
        dispatch(createAcl(segmentId, { user_id: inviteeId, permission })),
      ),
    )

    try {
      await changePromises
      await removePromises
      await invitePromises

      dispatch(showToast("Segment access list updated."))
      onClose()
    } catch {
    } finally {
      setIsSaving(false)
    }
  }, [acl, dispatch, onClose, pendingChanges, pendingInvites, pendingRemoves, segmentId])

  return (
    <Modal open={isOpen} handleClose={onClose} className={styles.container} hideHeader>
      <div className={styles.header}>
        <div className={styles.title}>
          {isAddingUsers ? "invite users to segment" : "segment users"}

          <Tippy
            content="Collaborate with other users on the segment. Invited users will receive notification for this segment."
            placement="top"
          >
            <div className={styles.infoIcon}>
              <FontAwesomeIcon icon={["fas", "info-circle"]} />
            </div>
          </Tippy>
        </div>
        <div className={styles.stretch} />
        <div className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "times"]} />
        </div>
      </div>
      <div className={styles.body}>
        {!isAddingUsers && (
          <>
            {sortedAcl
              .filter(({ user_id }) => !pendingRemoves.includes(user_id))
              .map(({ user_id, permission }) => {
                const user = allUsers[user_id]
                const isAuthor = user_id === authorId

                return (
                  <div key={user_id} className={styles.userRow}>
                    <Avatar name={user.name} email={user.email} />
                    <div className={styles.userName}>{user.name}</div>
                    <div className={styles.stretch} />
                    {isAuthor ? (
                      <Tippy
                        content="Author's rights cannot be edited. Author has full access to the segment."
                        placement="top"
                      >
                        <div className={styles.authorTogglePlaceholder}>author</div>
                      </Tippy>
                    ) : (
                      <Tippy
                        content="This user has the permission to edit all segments."
                        disabled={!canEditAllSegments(user, segmentType)}
                        placement="top"
                      >
                        <div>
                          <ToggleSwitch
                            name={`permission-${user_id}`}
                            checked={
                              pendingChanges.find(({ userId }) => userId === user_id)
                                ? getOppositePermission(permission)
                                : permission
                            }
                            leftLabel="View"
                            leftValue={PERMISSION.READ}
                            rightLabel="Edit"
                            rightValue={PERMISSION.WRITE}
                            width="112px"
                            handleToggle={() => toggleUserPermission(user_id)}
                            disabled={canEditAllSegments(user, segmentType) || !isEditable}
                          />
                        </div>
                      </Tippy>
                    )}
                    {isEditable && (
                      <Tippy
                        content={
                          canEditAllSegments(user, segmentType)
                            ? "User will still have permission to edit the segment, but will not get notifications about segment changes."
                            : canViewAllSegments(user, segmentType)
                            ? "User will still have permission to view the segment, but will not get notifications about segment changes."
                            : "User will lose access to the segment."
                        }
                        placement="top"
                      >
                        <div className={styles.removeButtonWrapper}>
                          {/* @ts-ignore */}
                          <IconButton
                            color={COLOR.RED}
                            size={SIZE.TAG}
                            withBackground
                            onClick={() => removeUser(user_id)}
                            iconName="trash-alt"
                            className={classNames(styles.removeButton, {
                              [styles.hidden]: isAuthor,
                            })}
                          />
                        </div>
                      </Tippy>
                    )}
                  </div>
                )
              })}
            {isEditable && (
              <div className={styles.inviteButtonsWrapper}>
                <button className={styles.addButton} onClick={() => setIsAddingUsers(true)}>
                  <FontAwesomeIcon icon={["far", "user-plus"]} className={styles.icon} />
                  invite users
                </button>
                <button
                  className={classNames(styles.addButton, styles.secondary)}
                  onClick={inviteAllUsers}
                >
                  <FontAwesomeIcon icon={["far", "users"]} className={styles.icon} />
                  invite all users
                </button>
              </div>
            )}
          </>
        )}
        {isAddingUsers && (
          <>
            {pendingInvites.map(({ inviteeId, permission }, index) => {
              const user = inviteeId && allUsers[inviteeId]
              const options = getInviteeDropdownOptions(index)

              return (
                <div key={inviteeId ?? "empty"} className={styles.userRow}>
                  <SelectField
                    input={{
                      value: options.find(({ value }) => value === inviteeId),
                      onChange: ({ value }: { value: UserFull["id"] }) => setInvitee(value, index),
                    }}
                    options={options}
                    isSearchable
                    className={styles.inviteeSelect}
                  />
                  <div className={styles.stretch} />
                  {user && permission && (
                    <Tippy
                      content="This user has the permission to edit all segments."
                      disabled={!canEditAllSegments(user, segmentType)}
                      placement="top"
                    >
                      <div>
                        <ToggleSwitch
                          name={`permission-invite-${user.id}`}
                          checked={permission}
                          leftLabel="View"
                          leftValue={PERMISSION.READ}
                          rightLabel="Edit"
                          rightValue={PERMISSION.WRITE}
                          width="112px"
                          handleToggle={() => toggleInviteePermission(index)}
                          disabled={canEditAllSegments(user, segmentType)}
                        />
                      </div>
                    </Tippy>
                  )}
                  <Tippy content="Remove user from invites." placement="top">
                    <div className={styles.removeButtonWrapper}>
                      {/* @ts-ignore */}
                      <IconButton
                        color={COLOR.RED}
                        size={SIZE.TAG}
                        withBackground
                        onClick={() => removeInvitee(index)}
                        iconName="trash-alt"
                        className={classNames(styles.removeButton, {
                          [styles.hidden]: equals(pendingInvites, [{}]),
                        })}
                      />
                    </div>
                  </Tippy>
                </div>
              )
            })}
            {areThereUnusedOptions && areAllRowsFilledOut && (
              <div className={styles.inviteButtonsWrapper}>
                <button className={styles.addButton} onClick={addRow}>
                  <FontAwesomeIcon icon={["far", "plus-circle"]} className={styles.icon} />
                  add another
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {isEditable && (
        <div className={styles.footer}>
          {!isAddingUsers && (
            <Button color="white" onClick={onClose} size="medium">
              cancel
            </Button>
          )}
          {isAddingUsers && (
            <Button color="white" onClick={() => setIsAddingUsers(false)} size="medium">
              back
            </Button>
          )}
          <div className={styles.stretch} />
          <Button
            className={styles.saveButton}
            color="primary"
            onClick={saveChanges}
            disabled={isSaving}
            isLoading={isSaving}
            size="medium"
          >
            save changes
          </Button>
        </div>
      )}
    </Modal>
  )
}
