import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Record } from "immutable"
import { NavLink } from "react-router-dom"
import _noop from "lodash/noop"
import SegmentNameLink from "./SegmentNameLink/SegmentNameLink"

// selector
import { getSegmentExportData } from "resources/segment/segmentExport/segmentExportSelectors"
import { getSegmentById } from "resources/segment/segment/segmentSelectors"

// actions
import { retrieveSegment } from "resources/segment/segment/segmentActions.deprecated"

// helpers
import { shortenText } from "helpers/string.helper"
import { getRoutePath } from "routes"

const SMART_PATH = "/segments/smart/:id/exports/:eid/detail"
const FEATURED_PATH = "/segments/featured/:id/exports/:eid/detail"

class SegmentExportLogSubmenu extends PureComponent {
  componentDidMount() {
    const {
      segment,
      retrieveSegment,
      match: {
        params: { id },
      },
    } = this.props
    const isSmartSegment = this.props.match.path === SMART_PATH
    if (!segment) {
      retrieveSegment(id, isSmartSegment).catch(_noop)
    }
  }

  render() {
    const { segmentExport, segment, match } = this.props

    if (!segmentExport || !segment) {
      return null
    }

    const isSmartSegment = match.path === SMART_PATH

    return (
      <nav className="secondary-nav">
        {isSmartSegment && <NavLink to={getRoutePath("segments.smart")}>Smart segments</NavLink>}
        {!isSmartSegment && <NavLink to={getRoutePath("segments")}>Custom segments</NavLink>}
        <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
        <SegmentNameLink
          name={segment.name}
          routeTo={getRoutePath(isSmartSegment ? "segments.smart.detail" : "segments.detail", {
            id: segment.id,
          })}
        />
        <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
        <span className="link-like is-active export-name">
          {shortenText(segmentExport.name, 25)}
        </span>
        <span className="link-like is-active">/ Export Detail</span>
      </nav>
    )
  }
}

SegmentExportLogSubmenu.propTypes = {
  segmentExport: PropTypes.instanceOf(Record),
  retrieveSegment: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const idProps = ownProps.match.params
  const segmentType =
    ownProps.match.path === SMART_PATH
      ? "smart"
      : ownProps.match.path === FEATURED_PATH
      ? "featured"
      : "regular"
  return {
    segmentExport: getSegmentExportData(state, idProps.id, idProps.eid),
    segment: getSegmentById(state, idProps.id, segmentType),
  }
}

export default connect(mapStateToProps, { retrieveSegment })(SegmentExportLogSubmenu)
