import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Form, Field, getFormValues } from "redux-form"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { List, OrderedMap } from "immutable"
import _forEach from "lodash/forEach"
import _startsWith from "lodash/startsWith"
import _noop from "lodash/noop"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import TextField from "components/UI/elements/TextField"
import ToggleSwitchField from "components/UI/elements/ToggleSwitch/ToggleSwitchField"
import {
  customersFeatures,
  segmentsFeatures,
  dataFeatures,
  administrationFeatures,
  featuresDescriptions,
  setupFeatures,
  personalizationFeatures,
  reportsFeatures,
} from "helpers/roles.helper"
import { required } from "helpers/validators.helper"
import Tippy from "@tippyjs/react"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import { areLabelsFulfilled, getLabelsArray } from "selectors/label.selector"
import Tag from "components/UI/elements/Tag"
import TagPicker from "components/UI/components/TagPicker"
import { fetchLabelsList } from "actions/label.action"

class RoleForm extends Component {
  componentDidMount() {
    const { areLabelsFulfilled, fetchLabelsList } = this.props
    if (!areLabelsFulfilled) {
      fetchLabelsList().catch(_noop)
    }
  }

  onFeatureChange = evt => {
    const { exportDestinations, formValues, change } = this.props
    const { name, value } = evt.target
    if (name === "segments/export") {
      exportDestinations.forEach(destination => {
        change(`segments/export/${destination.id}`, value)
      })
    }
    // toggle view tags with their editing feature
    if (name === "settings/tags" && value === "on" && formValues["data/tags"] === "off") {
      change("data/tags", "on")
    }
    if (name === "data/tags" && value === "off" && formValues["settings/tags"] === "on") {
      change("settings/tags", "off")
    }
    if (name === "web_banners/edit" && value === "on" && formValues["web_banners/view"] === "off") {
      change("web_banners/view", "on")
    }
    if (name === "web_banners/view" && value === "off" && formValues["web_banners/edit"] === "on") {
      change("web_banners/edit", "off")
    }
    if (name === "reports/edit" && value === "on" && formValues["reports/view"] === "off") {
      change("reports/view", "on")
    }
    if (name === "reports/view" && value === "off" && formValues["reports/edit"] === "on") {
      change("reports/edit", "off")
    }
    if (name === "featured_segments/list" && value === "off") {
      change("featured_segments/view", "off")
      change("featured_segments/edit", "off")
    }
    if (name === "featured_segments/view") {
      if (value === "on") {
        change("featured_segments/list", "on")
      } else {
        change("featured_segments/edit", "off")
      }
    }
    if (name === "featured_segments/edit") {
      if (value === "on") {
        change("featured_segments/list", "on")
        change("featured_segments/view", "on")
      } else {
        change("featured_segments/list", "off")
        change("featured_segments/view", "off")
      }
    }
  }

  onSubFeatureChange = evt => {
    const { name, value } = evt.target
    const { formValues, change } = this.props

    if (value === "off" && formValues["segments/export"] === "on") {
      change("segments/export", value)
    } else if (value === "on" && formValues["segments/export"] === "off") {
      let allSubValuesOn = true
      _forEach(formValues, (val, key) => {
        if (_startsWith(key, "segments/export/") && val === "off") {
          if (key !== name) {
            allSubValuesOn = false
          }
        }
      })
      if (allSubValuesOn) {
        change("segments/export", value)
      }
    }
  }

  renderFormRow = (name, title) => {
    const { exportDestinations } = this.props
    return (
      <div className="row" key={name}>
        <div className="main">
          <label>
            {title}{" "}
            <Tippy placement="right" content={featuresDescriptions[name]}>
              <span>
                <FontAwesomeIcon icon={["fas", "info-circle"]} className="info-icon" />
              </span>
            </Tippy>
          </label>
          <ToggleSwitchField
            name={name}
            leftValue="off"
            rightValue="on"
            onChange={this.onFeatureChange}
            onOffType
            size="sm"
          />
        </div>

        {name === "segments/export" && exportDestinations.size > 0 && (
          <div className="secondary">
            {exportDestinations
              .map(destination => {
                return (
                  <div className="secondary-row" key={destination.id}>
                    <label>– {destination.name}</label>
                    <ToggleSwitchField
                      name={`${name}/${destination.id}`}
                      leftValue="off"
                      rightValue="on"
                      onChange={this.onSubFeatureChange}
                      onOffType
                      size="sm"
                    />
                  </div>
                )
              })
              .toArray()}
          </div>
        )}
      </div>
    )
  }

  toggleAllFeatures = (value, featuresMap) => () => {
    const { exportDestinations } = this.props
    featuresMap.forEach((title, name) => {
      this.props.change(name, value)
      if (name === "segments/export") {
        exportDestinations.forEach(destination => {
          this.props.change(`${name}/${destination.id}`, value)
        })
      }
    })
  }

  renderFeaturesSection = (sectionTitle, featuresMap) => {
    return (
      <Paper className="features-section">
        <div className="header-part">
          <h4>{sectionTitle}</h4>
          <div className="all-features-actions">
            <Button color="link-primary" onClick={this.toggleAllFeatures("off", featuresMap)}>
              Disable all
            </Button>
            <div className="buttons-delimiter" />
            <Button color="link-primary" onClick={this.toggleAllFeatures("on", featuresMap)}>
              Enable all
            </Button>
          </div>
        </div>
        <div className="features-toggles">
          {featuresMap.map((title, name) => this.renderFormRow(name, title)).toArray()}
        </div>
      </Paper>
    )
  }

  selectAttributeVisibilityLabel = labelId => {
    const { formValues, change } = this.props
    const currentLlabelIds = formValues?.attribute_visibility_label_ids ?? []
    change("attribute_visibility_label_ids", [...currentLlabelIds, labelId])
  }

  deselectAttributeVisibilityLabel = labelId => {
    const { formValues, change } = this.props
    change(
      "attribute_visibility_label_ids",
      formValues.attribute_visibility_label_ids.filter(lId => lId !== labelId),
    )
  }

  render() {
    const { closeForm, handleSubmit, onSubmit, isSaving, areLabelsFulfilled, formValues, labels } =
      this.props
    return (
      <Form className="role-form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <PaperHeader className="admin-roles-header" size="small">
          <div className="left-part">
            <Field
              component={TextField}
              placeholder="Name"
              name="name"
              label="Name role"
              validate={required}
              maxLength={50}
            />
          </div>
          <div className="right-part">
            <Button type="button" color="white" size="small" onClick={closeForm}>
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              size="small"
              className={isSaving ? "loading" : ""}
            >
              Save
            </Button>
          </div>
        </PaperHeader>
        <div className="admin-roles-content form-content">
          <div className="left-column">
            {this.renderFeaturesSection("Customers", customersFeatures)}
            {this.renderFeaturesSection("Segments", segmentsFeatures)}
            {this.renderFeaturesSection("Reporting", reportsFeatures)}
          </div>
          <div className="right-column">
            {this.renderFeaturesSection("Data", dataFeatures)}
            {this.renderFeaturesSection("Personalization", personalizationFeatures)}
            {this.renderFeaturesSection("Administration", administrationFeatures)}
            {this.renderFeaturesSection("Setup", setupFeatures)}
          </div>
        </div>
        <div className="attribute-visibility-settings">
          <Paper className="attribute-visibility-box">
            <h4>
              Attribute visibility protection{" "}
              <InfoTooltip placement="top" className="attribute-visibility-tooltip">
                Specify attributes' labels for which this user role won't see values.
              </InfoTooltip>
            </h4>
            <div className="attribute-visibility-labels">
              {areLabelsFulfilled && (
                <div className="attribute-visibility-labels-list">
                  {Array.isArray(formValues?.attribute_visibility_label_ids) &&
                    formValues.attribute_visibility_label_ids.map(labelId => {
                      const label = labels.find(l => l.id === labelId)
                      const labelName = label?.name ?? labelId
                      return (
                        <Tag
                          key={labelId}
                          color="primary"
                          clickable
                          onClick={() => this.deselectAttributeVisibilityLabel(labelId)}
                        >
                          {labelName}
                        </Tag>
                      )
                    })}
                  <TagPicker
                    className="attribute-visibility-tag-picker"
                    selectedTagIds={formValues?.attribute_visibility_label_ids ?? []}
                    allTags={List(labels)}
                    onTagSelect={this.selectAttributeVisibilityLabel}
                    dropdownAlign="left"
                    type="label"
                  />
                </div>
              )}
            </div>
          </Paper>
        </div>
      </Form>
    )
  }
}

RoleForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  exportDestinations: PropTypes.instanceOf(OrderedMap).isRequired,
  formValues: PropTypes.object,
  fetchLabelsList: PropTypes.func.isRequired,
  areLabelsFulfilled: PropTypes.bool.isRequired,
  labels: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  formValues: getFormValues("RoleForm")(state),
  areLabelsFulfilled: areLabelsFulfilled(state),
  labels: getLabelsArray(state),
})

RoleForm = reduxForm({
  form: "RoleForm",
  touchOnBlur: false,
  enableReinitialize: true,
})(RoleForm)

export default connect(mapStateToProps, { fetchLabelsList })(RoleForm)
