import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./CheckboxField.scss"

const CheckboxField = ({
  input,
  label,
  className,
  meta: { touched, error },
  onChange = () => {},
  disabled = false,
  ...fieldAttrs
}) => {
  const errorMessage = touched ? error : ""
  return (
    <div className={`checkbox-field ${errorMessage ? "error" : ""} ${className ? className : ""}`}>
      <label className={`${disabled ? "disabled" : ""} ${input.value ? "label-checked" : ""}`}>
        {label}
        <input
          {...input}
          checked={input.value}
          type="checkbox"
          className="checkbox"
          onChange={evt => {
            input.onChange(evt)
            onChange(evt)
          }}
          disabled={disabled}
          {...fieldAttrs}
        />
        <span className="checkmark">
          <FontAwesomeIcon className="icon-check" icon={["fas", "check"]} />
        </span>
      </label>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  )
}

CheckboxField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

export default CheckboxField
