import { ACL } from "sharedConstants"
import { Map } from "immutable"
import _map from "lodash/map"
import _mapKeys from "lodash/mapKeys"
import Acl from "models/acl.model"

/*
 * { segmentId: acl }
 */
const initialState = Map()

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${ACL.USER.ACTION.LIST}_FULFILLED`:
    case ACL.USER.ACTION.LIST:
      return Map(
        _mapKeys(
          _map(payload, acl => new Acl(acl)),
          "segment_id",
        ),
      )
    default:
      return state
  }
}
