import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Record } from "immutable"
import moment from "moment"
import _toLower from "lodash/toLower"

// ui components
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"

// constants, helpers
import { MOMENT } from "sharedConstants"
import { getCustomerEventTitle, getCustomerEventPayload } from "helpers/event.helper"
import { getIconSrc } from "helpers/image.helper"

import "./CustomerEvent.scss"

const TOGGLE_HEIGHT_TRESHOLD = 100

class CustomerEvent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      expand: false,
      height: null,
      headingHeight: null,
    }
  }

  componentDidMount() {
    const height = this.customerEventHeight.clientHeight
    const headingHeight = this.headingHeight.clientHeight
    const expand = height <= TOGGLE_HEIGHT_TRESHOLD
    this.setState({ height, headingHeight, expand })
  }

  toggleShowMore = () => () => {
    let { expand } = this.state

    expand = !expand
    this.setState({ expand })
  }

  render() {
    const { customerEvent, event, dataSource } = this.props
    const { expand, height, headingHeight } = this.state

    const title = getCustomerEventTitle(customerEvent, event.schema, event.name)
    const payload = getCustomerEventPayload(
      customerEvent,
      event.schema,
      true,
      expand,
      headingHeight,
    )
    const color = dataSource.getIn(["frontend_settings", "color"], "primary")

    return (
      <Paper
        className={`event-box ${color} ${height <= TOGGLE_HEIGHT_TRESHOLD ? "bottom-pad" : ""}`}
      >
        <div className="event-source-icon">
          <img
            src={getIconSrc(
              {
                primary: dataSource.getIn(["frontend_settings", "icon"]),
                secondary: _toLower(dataSource.type),
              },
              dataSource.getIn(["frontend_settings", "alt_icon"]),
              true,
            )}
            alt="icon"
          />
        </div>
        <div
          className={`event-content ${expand ? "" : "collapse"}`}
          ref={customerEventHeight => (this.customerEventHeight = customerEventHeight)}
        >
          <div className="header" ref={headingHeight => (this.headingHeight = headingHeight)}>
            <h4>{title}</h4>
            <p className="datetime">
              {moment.utc(customerEvent.event_time).local().format(MOMENT.DATETIME_FORMAT)}
            </p>
          </div>
          {payload}
        </div>
        {height > TOGGLE_HEIGHT_TRESHOLD && (
          <div className={`show-more-less-btn ${expand ? "expanded" : ""}`}>
            <Button
              color="link"
              size="small"
              onClick={this.toggleShowMore()}
              className={`show ${expand ? "" : "collapse"}`}
            >
              {expand ? "Show Less" : "Show More"}
            </Button>
          </div>
        )}
      </Paper>
    )
  }
}

CustomerEvent.propTypes = {
  customerEvent: PropTypes.object.isRequired,
  event: PropTypes.instanceOf(Record).isRequired,
  dataSource: PropTypes.instanceOf(Record).isRequired,
}

export default CustomerEvent
