import React from "react"
import PropTypes from "prop-types"
import _noop from "lodash/noop"

import "./ToggleSwitch.scss"
import classNames from "classnames"

export const ToggleSwitchMultiple = ({
  width,
  name,
  buttons,
  checked,
  handleToggle,
  className,
}) => {
  const elWidth = `${100 / buttons.length}%`
  const checkedIndex = buttons.findIndex(button => button.value === checked)
  const switchLeftPosition = `${checkedIndex * (100 / buttons.length)}%`
  return (
    <div className={classNames("toggle-switch-multiple", className)} style={{ width: width }}>
      <div className="fieldset">
        <div className="inner">
          {buttons.map(button => (
            <React.Fragment key={button.value}>
              <input
                type="radio"
                name={name}
                value={button.value}
                id={`${button.value}-${name}`}
                checked={checked === button.value}
                onChange={() => handleToggle(button.value)}
              />
              <label htmlFor={`${button.value}-${name}`} style={{ width: elWidth }}>
                {button.label ? button.label : button.value}
              </label>
            </React.Fragment>
          ))}
          <span className="switch" style={{ width: elWidth, left: switchLeftPosition }} />
        </div>
      </div>
    </div>
  )
}

ToggleSwitchMultiple.propTypes = {
  width: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  buttons: PropTypes.array.isRequired,
  checked: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
}

const ToggleSwitch = ({
  name,
  leftValue,
  rightValue,
  checked,
  handleToggle,
  width,
  disabled,
  rightLabel,
  leftLabel,
  size = "medium",
  className = "",
}) => (
  <div
    className={`toggle-switch ${size} ${disabled ? "disabled" : ""} ${className}`}
    style={{ width: width }}
    data-testid={`toggle-switch-${name}`}
  >
    <p
      className="fieldset"
      data-testid="fieldset"
      onClick={disabled === true ? _noop : handleToggle}
    >
      <input
        type="radio"
        name={name}
        value={leftValue}
        id={`${leftValue}-${name}`}
        checked={checked === leftValue}
        readOnly
        disabled
        data-testid="left-input"
      />
      <label htmlFor={`${leftValue}-${name}`}>{leftLabel ? leftLabel : leftValue}</label>
      <input
        type="radio"
        name={name}
        value={rightValue}
        id={`${rightValue}-${name}`}
        checked={checked === rightValue}
        readOnly
        disabled
        data-testid="right-input"
      />
      <label htmlFor={`${rightValue}-${name}`}>{rightLabel ? rightLabel : rightValue}</label>
      <span className="switch" />
    </p>
  </div>
)

ToggleSwitch.propTypes = {
  width: PropTypes.string.isRequired,
  name: PropTypes.string,
  leftLabel: PropTypes.string,
  leftValue: PropTypes.string.isRequired,
  rightLabel: PropTypes.string,
  rightValue: PropTypes.string.isRequired,
  checked: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string,
}

export default ToggleSwitch
