import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _isNull from "lodash/isNull"
import _isArray from "lodash/isArray"
import Button from "components/UI/elements/Button/Button"
import IconButton, { COLOR } from "components/UI/elements/IconButton"

import "./ConditionOperationDropdown.scss"
import {
  OPERATION,
  OPERATION_CONTEXT_MAPPER,
  OPERATION_CONTEXT_TYPE,
  OPERATION_LABEL_MAPPER,
} from "resources/segment/segment/utilities/segmentOperationsConstants"

class ConditionOperationDropdown extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      // possible states: string, number, date, other
      context: OPERATION_CONTEXT_TYPE.OTHER,
    }
  }

  _determineContextFromCondition(condition) {
    return condition ? OPERATION_CONTEXT_MAPPER[condition] : this.props.defaultContext
  }

  _handleOutsideClick = evt => {
    if (!_isNull(this.dropdown)) {
      if (!this.dropdown.contains(evt.target)) {
        this.dropdownToggle()
      }
    }
  }

  dropdownToggle = () => {
    if (this.state.open) {
      // close
      this.setState({ open: false })
      document.removeEventListener("click", this._handleOutsideClick, false)
    } else {
      this.setState({
        open: true,
        context: this._determineContextFromCondition(this.props.value),
      })
      setTimeout(() => document.addEventListener("click", this._handleOutsideClick, false), 0)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this._handleOutsideClick, false)
  }

  switchContext = context => () => {
    this.setState({
      context,
    })
  }

  changeConditionOperation = operation => () => {
    const disabledOperations = _isArray(this.props.disabledOperations)
      ? this.props.disabledOperations
      : []
    if (!disabledOperations.includes(operation)) {
      this.props.handleValueChange(operation)
      this.dropdownToggle()
    }
  }

  renderOperation = (value, operation) => {
    const text = OPERATION_LABEL_MAPPER[operation]
    const disabledOperations = _isArray(this.props.disabledOperations)
      ? this.props.disabledOperations
      : []
    return (
      <span
        className={`${value === operation ? "active" : ""} ${
          disabledOperations.includes(operation) ? "disabled" : ""
        }`}
        onClick={this.changeConditionOperation(operation)}
      >
        {text}
      </span>
    )
  }

  _renderContextConditionOperations() {
    const { context } = this.state
    const { value } = this.props
    if (context === "string") {
      return (
        <div className="context-condition-operations">
          {this.renderOperation(value, OPERATION.STRING_EQUALS)}
          {this.renderOperation(value, OPERATION.STRING_NOT_EQUAL)}
          {this.renderOperation(value, OPERATION.CONTAINS)}
          {this.renderOperation(value, OPERATION.NOT_CONTAIN)}
          {this.renderOperation(value, OPERATION.STRING_IN)}
          {this.renderOperation(value, OPERATION.STRING_NOT_IN)}
          {this.renderOperation(value, OPERATION.CONTAINS_ANY_OF)}
          {this.renderOperation(value, OPERATION.NOT_CONTAIN_ANY_OF)}
        </div>
      )
    } else if (context === "number") {
      return (
        <div className="context-condition-operations">
          {this.renderOperation(value, OPERATION.NUMBER_EQUALS)}
          {this.renderOperation(value, OPERATION.NUMBER_NOT_EQUAL)}
          {this.renderOperation(value, OPERATION.NUMBER_LOWER_THAN)}
          {this.renderOperation(value, OPERATION.NUMBER_GREATER_THAN)}
          {this.renderOperation(value, OPERATION.NUMBER_BETWEEN)}
          {this.renderOperation(value, OPERATION.NUMBER_IN)}
          {this.renderOperation(value, OPERATION.NUMBER_NOT_IN)}
        </div>
      )
    } else if (context === "date") {
      return (
        <div className="context-condition-operations">
          {this.renderOperation(value, OPERATION.DATE_EQUALS)}
          {this.renderOperation(value, OPERATION.DATE_GREATER_THAN)}
          {this.renderOperation(value, OPERATION.DATE_LOWER_THAN)}
          {this.renderOperation(value, OPERATION.DATE_BETWEEN)}
          {this.renderOperation(value, OPERATION.DATE_MATCHES_CURRENT_DAY)}
          {this.renderOperation(value, OPERATION.DATE_MATCHES_CURRENT_MONTH)}
          {this.renderOperation(value, OPERATION.DATE_MATCHES_CURRENT_YEAR)}
        </div>
      )
    } else if (context === "other") {
      return (
        <div className="context-condition-operations">
          {this.renderOperation(value, OPERATION.IS_TRUE)}
          {this.renderOperation(value, OPERATION.IS_FALSE)}
          {this.renderOperation(value, OPERATION.IS_SET)}
          {this.renderOperation(value, OPERATION.IS_NOT_SET)}
        </div>
      )
    }
  }

  render() {
    const { className, value, isDisabled, errorMarkup, isVisible } = this.props
    const { open, context } = this.state
    const allowedContexts = _isArray(this.props.allowedContexts) ? this.props.allowedContexts : []
    return (
      <div className={`condition-operation-wrapper ${className ? className : ""}`}>
        <Button
          size="small"
          className={`condition-operation-dropdown-button ${errorMarkup ? "error-shadow" : ""} ${
            isVisible ? "is-visible" : ""
          }`}
          onClick={this.dropdownToggle}
          disabled={isDisabled}
        >
          <div className="text">
            {value ? OPERATION_LABEL_MAPPER[value] : <span className="placeholder">Select</span>}
          </div>
          <div className="caret-wrapper">
            <FontAwesomeIcon icon={["fas", open ? "caret-up" : "caret-down"]} />
          </div>
        </Button>
        {open && (
          <div className="dropdown" ref={node => (this.dropdown = node)}>
            <div className="context-menu">
              <IconButton
                color={`${context === OPERATION_CONTEXT_TYPE.STRING ? COLOR.PRIMARY : COLOR.GREY}`}
                onClick={this.switchContext("string")}
                disabled={!allowedContexts.includes(OPERATION_CONTEXT_TYPE.STRING)}
                iconName="quote-left"
                iconStyle="far"
              >
                <span className="label">string</span>
              </IconButton>
              <IconButton
                color={`${context === OPERATION_CONTEXT_TYPE.NUMBER ? COLOR.PRIMARY : COLOR.GREY}`}
                onClick={this.switchContext("number")}
                disabled={!allowedContexts.includes(OPERATION_CONTEXT_TYPE.NUMBER)}
                iconName="hashtag"
                iconStyle="far"
              >
                <span className="label">number</span>
              </IconButton>
              <IconButton
                color={`${context === OPERATION_CONTEXT_TYPE.DATE ? COLOR.PRIMARY : COLOR.GREY}`}
                onClick={this.switchContext("date")}
                disabled={!allowedContexts.includes(OPERATION_CONTEXT_TYPE.DATE)}
                iconName="calendar-alt"
                iconStyle="far"
              >
                <span className="label">date</span>
              </IconButton>
              <IconButton
                color={`${context === OPERATION_CONTEXT_TYPE.OTHER ? COLOR.PRIMARY : COLOR.GREY}`}
                onClick={this.switchContext("other")}
                disabled={!allowedContexts.includes(OPERATION_CONTEXT_TYPE.OTHER)}
                iconName="toggle-on"
                iconStyle="far"
              >
                <span className="label">other</span>
              </IconButton>
            </div>
            {this._renderContextConditionOperations()}
          </div>
        )}
      </div>
    )
  }
}

ConditionOperationDropdown.propTypes = {
  value: PropTypes.string,
  handleValueChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  allowedContexts: PropTypes.arrayOf(PropTypes.string),
  defaultContext: PropTypes.string,
  disabledOperations: PropTypes.array,
  errorMarkup: PropTypes.bool,
  isVisible: PropTypes.bool,
}

export default ConditionOperationDropdown
