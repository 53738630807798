import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import SmartSegmentSetupDetail from "./SmartSegmentDetail/SmartSegmentDetail"
import SmartSegmentsSetupList from "./SmartSegmentsList/SmartSegmentsList"

export default function SmartSegmentsSetup() {
  return (
    <Switch>
      <Route path={getRoutePath("setup.segments.smart")} exact component={SmartSegmentsSetupList} />
      <Route
        path={getRoutePath("setup.segments.smart.detail")}
        component={SmartSegmentSetupDetail}
      />
    </Switch>
  )
}
