import { Record, Map } from "immutable"

const Export = Record({
  id: 0,
  user_id: null,
  segment_id: null,
  segment_export_destination_id: null,
  logs: Map(),
  stats: Map(),
  settings: Map(),
  frontend_settings: null,
  name: "",
  status: "",
  created: "",
  file_download_token: "",
})

export default Export
