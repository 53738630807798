import _get from "lodash/get"

export const ITEMS_PER_PAGE = 100

export default class AllResourceItemsFetcher {
  /*
   * Default value for loading full structure of data
   */
  loadFullStructure = 0

  /*
   * Path to data in response
   */
  dataPath = null

  /*
   * Anonymous function to API endpoint
   */
  endpointCall = () => {}

  /*
   * Set load full structure value, possible values are 0/1
   */
  setLoadFullStructure(value) {
    this.loadFullStructure = value
    return this
  }

  /*
   * Set path to data in response
   */
  setDataPath(path) {
    this.dataPath = path
    return this
  }

  /*
   * Set endpoint call, which is an anonymous function to API endpoint
   */
  setEndpointCall(apiCall) {
    this.endpointCall = apiCall
    return this
  }

  async _fetchPage(offset) {
    const response = await this.endpointCall(offset, ITEMS_PER_PAGE, this.loadFullStructure)
    return response
  }

  async run() {
    let output = []

    let lastPage = false
    let offset = 0
    while (!lastPage) {
      try {
        const response = await this._fetchPage(offset)
        const data = _get(response, this.dataPath, [])
        output = [...output, ...data]
        lastPage = data.length < ITEMS_PER_PAGE
        offset += ITEMS_PER_PAGE
      } catch {
        lastPage = true
      }
    }

    return output
  }
}
