import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import { hasAccess } from "helpers/authenticatedUser.helper"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import Dashboard from "./Dashboard/Dashboard"
import Attributes from "./Attributes/Attributes"
import Events from "./Events/Events"
import Insights from "./Insights/Insights"
import SourcesDestinations from "./SourcesDestinations/SourcesDestinations"

function redirectToAllowedDataTab() {
  if (hasAccess.data.dashboard()) {
    return <Redirect to={getRoutePath("data.dashboard")} />
  }

  if (hasAccess.data.insights()) {
    return <Redirect to={getRoutePath("data.insights")} />
  }

  if (hasAccess.data.attributes()) {
    return <Redirect to={getRoutePath("data.attributes")} />
  }

  if (hasAccess.data.events()) {
    return <Redirect to={getRoutePath("data.events")} />
  }

  if (hasAccess.data.sourcesAndDestinations()) {
    return <Redirect to={getRoutePath("data.sources-destinations")} />
  }
}

export default function Data() {
  return (
    <Switch>
      <Route path={getRoutePath("data")} exact render={redirectToAllowedDataTab} />

      <AuthorizedRoute
        hasAccess={hasAccess.data.dashboard()}
        path={getRoutePath("data.dashboard")}
        component={Dashboard}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.data.insights()}
        path={getRoutePath("data.insights")}
        component={Insights}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.data.attributes()}
        path={getRoutePath("data.attributes")}
        component={Attributes}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.data.events()}
        path={getRoutePath("data.events")}
        component={Events}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.data.sourcesAndDestinations()}
        path={getRoutePath("data.sources-destinations")}
        component={SourcesDestinations}
      />
    </Switch>
  )
}
