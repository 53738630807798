import { FilterType } from "types/usersActivity"
import { DateString } from "types/util"

export const USERS_ACTIVITY_SET_START_DATE = "USERS_ACTIVITY_SET_START_DATE" as const
export const setStartDate = (date: DateString) => ({
  type: USERS_ACTIVITY_SET_START_DATE,
  payload: date,
})
type UsersActivitySetStartDateAction = ReturnType<typeof setStartDate>

export const USERS_ACTIVITY_SET_END_DATE = "USERS_ACTIVITY_SET_END_DATE" as const
export const setEndDate = (date: DateString) => ({
  type: USERS_ACTIVITY_SET_END_DATE,
  payload: date,
})
type UsersActivitySetEndDateAction = ReturnType<typeof setEndDate>

export const USERS_ACTIVITY_SET_FILTER_TYPE = "USERS_ACTIVITY_SET_FILTER_TYPE" as const
export const setFilterType = (filterType: FilterType) => ({
  type: USERS_ACTIVITY_SET_FILTER_TYPE,
  payload: filterType,
})
type UsersActivitySetFilterTypeAction = ReturnType<typeof setFilterType>

export const USERS_ACTIVITY_SET_FILTER_VALUE = "USERS_ACTIVITY_SET_FILTER_VALUE" as const
export const setFilterValue = (filterValue: (string | number)[]) => ({
  type: USERS_ACTIVITY_SET_FILTER_VALUE,
  payload: filterValue,
})
type UsersActivitySetFilterValueAction = ReturnType<typeof setFilterValue>

export type UsersActivityFiltersAction =
  | UsersActivitySetStartDateAction
  | UsersActivitySetEndDateAction
  | UsersActivitySetFilterTypeAction
  | UsersActivitySetFilterValueAction
