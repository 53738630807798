import requestFactory from "api/request"
import { Flag } from "types/util"
import { Segment } from "../segment/segmentTypes"
import { SegmentAggregationReturnType } from "./segmentAggregationTypes"

const aggregations = {
  list<T extends Flag = 0>(
    segment_id: Segment["id"],
    load_full_structure: T = 0 as T,
  ): Promise<{ segment_aggregations: SegmentAggregationReturnType<T>[] }> {
    return requestFactory(
      "get",
      `/segments/${segment_id}/aggregations`,
      {
        load_full_structure,
      },
      false,
      true,
      "",
      true,
    )
  },
}

export default aggregations
