import React, { Component } from "react"
import { connect } from "react-redux"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import SourceForm from "../components/SourceForm/SourceForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import { api } from "api"
import { showToast } from "actions/toast.action"
import PropTypes from "prop-types"
import _get from "lodash/get"
import { Iterable } from "immutable"

import "./SourceCreate.scss"

class SourceCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      creating: false,
    }
  }

  createSource = async data => {
    if (!this.state.creating) {
      this.setState({ creating: true })
      const { history, showToast } = this.props
      try {
        await api.dataSource.create(data)
        showToast("Source created.")
        history.push(getRoutePath("setup.sources"))
      } catch {
        this.setState({ creating: false })
      }
    }
  }

  render() {
    const { history, location } = this.props
    const { creating } = this.state

    let formInitValues = {}
    const source = _get(location, "state.source")
    if (Iterable.isIterable(source)) {
      formInitValues.id = source.id
      formInitValues.name = source.name
      formInitValues.type = source.type
      formInitValues.description = source.description
      formInitValues.icon = source.getIn(["frontend_settings", "icon"])
        ? source.getIn(["frontend_settings", "icon"])
        : source.type
      formInitValues.color = source.getIn(["frontend_settings", "color"])
        ? source.getIn(["frontend_settings", "color"])
        : "primary"
    }

    return (
      <section className="create-source wrapper">
        <PaperHeader size="small" titleText="Create source" className="create-source-header">
          <div className="header-buttons">
            <Button
              size="small"
              color="white"
              onClick={() => {
                history.push(getRoutePath("setup.sources"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton isLoading={creating} formName="SourceForm" />
          </div>
        </PaperHeader>
        <Paper hasHeader className="create-source-content">
          <SourceForm onSubmit={this.createSource} initialValues={formInitValues} />
        </Paper>
      </section>
    )
  }
}

SourceCreate.propTypes = {
  showToast: PropTypes.func.isRequired,
}

export default connect(null, { showToast })(SourceCreate)
