import React, { Component } from "react"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import EventForm, { VALUE_TYPE, VALUE_FORMAT } from "../components/EventForm/EventForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import { api } from "api"
import { connect } from "react-redux"
import { showToast } from "actions/toast.action"
import PropTypes from "prop-types"
import _get from "lodash/get"
import _isArray from "lodash/isArray"
import { Iterable } from "immutable"

import "./EventCreate.scss"
import { getDataSourcesArrayForSelect } from "resources/dataSource/dataSourceSelectors"

class EventCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      creating: false,
    }
  }

  createEvent = async data => {
    if (!this.state.creating) {
      this.setState({ creating: true })
      const { history, showToast } = this.props
      try {
        await api.event.create(data)
        showToast("Event created.")
        history.push(getRoutePath("setup.events"))
      } catch {
        this.setState({ creating: false })
      }
    }
  }

  render() {
    const { history, location, sourcesArray } = this.props
    const { creating } = this.state

    let formInitValues = {
      schema: {
        display: [{ name: "", path: "", type: "", format: "" }],
      },
      ttl: "",
    }
    const event = _get(location, "state.event")
    if (Iterable.isIterable(event)) {
      const selectedSource = sourcesArray.find(source => source.value === event.source_id)
      formInitValues.name = event.name
      formInitValues.version = event.version
      formInitValues.type = { label: event.type, value: event.type }
      formInitValues.source_id = selectedSource ? selectedSource : null
      formInitValues.schema = {
        title: event.schema.title,
        display: _isArray(_get(event, "schema.display"))
          ? event.schema.display.map(item => ({
              name: item.name,
              type: VALUE_TYPE.find(type => type.value === item.type),
              format: VALUE_FORMAT.find(format => format.value === item.format),
              path: item.path,
            }))
          : [],
      }
    }

    return (
      <section className="create-event wrapper">
        <PaperHeader size="small" titleText="Create event" className="create-event-header">
          <div className="header-buttons">
            <Button
              size="small"
              color="white"
              onClick={() => {
                history.push(getRoutePath("setup.events"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton isLoading={creating} formName="EventForm" />
          </div>
        </PaperHeader>
        <Paper hasHeader className="create-event-content">
          <EventForm onSubmit={this.createEvent} initialValues={formInitValues} />
        </Paper>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  sourcesArray: getDataSourcesArrayForSelect(state, true),
})

EventCreate.propTypes = {
  showToast: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { showToast })(EventCreate)
