import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"
import _noop from "lodash/noop"

import "./ToggleSwitch.scss"

const ToggleSwitchField = ({
  name,
  leftValue,
  rightValue,
  width,
  label,
  leftLabel,
  rightLabel,
  className,
  disabled,
  onChange,
  idSufix = "",
  size = "",
  onOffType = false,
}) => {
  const styles = {}
  if (width) {
    styles.width = width
  }
  return (
    <div
      className={`toggle-switch-wrapper ${className ? className : ""} ${
        onOffType ? "on-off-type" : ""
      }`}
      data-testid="toggle-switch-wrapper"
    >
      {label && (
        <label className="form-label" data-testid="whole-field-label">
          {label}
        </label>
      )}
      <div
        className={`toggle-switch toggle-switch-form-field ${disabled ? "disabled" : ""}`}
        style={styles}
        data-testid="toggle-switch"
      >
        <div className={`fieldset ${size}`} data-testid="fieldset">
          <Field
            type="radio"
            name={name}
            value={leftValue}
            id={`${leftValue}-${name}${idSufix}`}
            component="input"
            className="left-field"
            disabled={disabled}
            onChange={onChange ? onChange : _noop}
            data-testid="left-field"
          />
          <label htmlFor={`${leftValue}-${name}`} className={`${size}`} data-testid="left-label">
            {leftLabel ? leftLabel : leftValue}
          </label>
          <Field
            type="radio"
            name={name}
            value={rightValue}
            id={`${rightValue}-${name}${idSufix}`}
            component="input"
            className="right-field"
            disabled={disabled}
            onChange={onChange ? onChange : _noop}
            data-testid="right-field"
          />
          <label htmlFor={`${rightValue}-${name}`} className={`${size}`} data-testid="right-label">
            {rightLabel ? rightLabel : rightValue}
          </label>
          <span className={`switch ${size}`} data-testid="switch"></span>
          {onOffType && <div className="on-off-background" />}
          {onOffType && (
            <div className="labels">
              <span className="onLabel">on</span>
              <span className="offLabel">off</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ToggleSwitchField.propTypes = {
  name: PropTypes.string.isRequired,
  leftLabel: PropTypes.string,
  leftValue: PropTypes.string,
  rightLabel: PropTypes.string,
  rightValue: PropTypes.string,
  width: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  idSufix: PropTypes.string,
  onOffType: PropTypes.bool,
}

export default ToggleSwitchField
