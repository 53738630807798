import requestFactory from "api/request"
import { Flag, OrderDir } from "types/util"
import {
  Source,
  SourceCreatePayload,
  SourceListResponse,
  SourceModifyPayload,
} from "./dataSourceTypes"

const dataSource = {
  list(
    offset = 0,
    limit = 20,
    order_by: keyof Source = "id",
    order_dir: OrderDir = "ASC",
    show_hidden: Flag = 0,
  ): Promise<SourceListResponse> {
    return requestFactory("get", "/customers/sources", {
      limit,
      offset,
      order_by,
      order_dir,
      show_hidden,
    })
  },
  create(data: SourceCreatePayload): Promise<{ source: Source }> {
    return requestFactory("post", "/customers/sources", data)
  },
  modify(id: Source["id"], data: SourceModifyPayload): Promise<{ source: Source }> {
    return requestFactory("patch", `/customers/sources/${id}`, data)
  },
  delete(id: Source["id"]): Promise<void> {
    return requestFactory("delete", `/customers/sources/${id}`)
  },
}

export default dataSource
