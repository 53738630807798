import React, { PureComponent } from "react"
import PaperHeader from "components/UI/elements/PaperHeader"
import { getRoutePath } from "routes"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
  areExportDestinationsFetching,
  isExportDestinationsFulfilled,
  getExportDestinationsData,
} from "resources/exportDestination/exportDestinationSelectors"
import { fetchWorkspacesList } from "actions/workspace.action"
import {
  fetchExportDestinationsList,
  modifyExportDestination,
  deleteExportDestination,
} from "resources/exportDestination/exportDestinationActions"
import { Map } from "immutable"
import Paper from "components/UI/elements/Paper"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getIconSrc } from "helpers/image.helper"
import _toLower from "lodash/toLower"
import { getWorkspacesForSelect, areWorkspacesFulfilled } from "selectors/workspace.selector"
import { getGlobalSettingsFormValues } from "resources/globalSettings/globalSettingsSelectors"
import _isNil from "lodash/isNil"
import _get from "lodash/get"
import Select from "react-select"
import { DropdownIndicator } from "components/UI/elements/SelectField"
import { selectStyles } from "helpers/customSelectStyle.helper"
import { showToast } from "actions/toast.action"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
import { fetchGlobalSettingsList } from "resources/globalSettings/globalSettingsActions"
import { isGlobalSettingsFetching } from "resources/globalSettings/globalSettingsSelectors"
import DestinationAttributes from "pages/Data/SourcesDestinations/components/DestinationAttributes/DestinationAttributes"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"

import "./DestinationsList.scss"
import Tippy from "@tippyjs/react"

class DestinationsList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      deleteDestination: {
        modalOpen: false,
        destination: null,
        isLoading: false,
      },
    }
  }

  componentDidMount() {
    // refetch export destinations if necessary
    const {
      areDestinationsFetching,
      fetchExportDestinationsList,
      isGlobalSettingsFetching,
      fetchGlobalSettingsList,
    } = this.props
    if (!areDestinationsFetching) {
      fetchExportDestinationsList()
    }
    if (!isGlobalSettingsFetching) {
      fetchGlobalSettingsList()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.globalSettings !== prevProps.globalSettings &&
      !this.props.areWorkspacesFulfilled
    ) {
      const isMiApiSet = !_isNil(_get(this.props.globalSettings, "mi_api.value"))
      if (isMiApiSet) {
        this.props.fetchWorkspacesList()
      }
    }
  }

  goToDestinationDetail = destinationId => () => {
    this.props.history.push(getRoutePath("setup.destinations.detail", { id: destinationId }))
  }

  changeDestinationWorkspace = destinationId => workspace => {
    const { modifyExportDestination, showToast } = this.props
    modifyExportDestination(destinationId, {
      mi_workspace_id: workspace.value,
    }).then(() => {
      showToast("Workspace assigned.")
    })
  }

  renderWorkspaceSelect = destination => {
    const { workspaces, globalSettings, areWorkspacesFulfilled } = this.props
    const isMiApiSet = !_isNil(_get(globalSettings, "mi_api.value"))
    if (isMiApiSet && destination.id > 1000) {
      let selectedWorkspace = null
      if (destination.mi_workspace_id) {
        const foundWs = workspaces.find(ws => ws.value === destination.mi_workspace_id)
        selectedWorkspace = foundWs
          ? foundWs
          : { label: destination.mi_workspace_id, value: destination.mi_workspace_id }
      }
      return (
        <Select
          value={selectedWorkspace}
          onChange={this.changeDestinationWorkspace(destination.id)}
          options={workspaces}
          styles={selectStyles("tag")}
          simpleValue
          isSearchable={true}
          isLoading={!areWorkspacesFulfilled}
          className="select-input"
          components={{
            DropdownIndicator: DropdownIndicator,
          }}
          classNamePrefix="tag_select"
          noOptionsMessage={() => "Empty"}
        />
      )
    }
    return null
  }

  toggleDeleteModal =
    (destination = null) =>
    () => {
      if (!this.state.deleteDestination.isLoading) {
        this.setState(prevState => ({
          deleteDestination: {
            ...prevState.deleteDestination,
            modalOpen: !prevState.deleteDestination.modalOpen,
            destination: destination ? destination : prevState.deleteDestination.destination,
          },
        }))
      }
    }

  deleteDestination = async () => {
    const { deleteDestination } = this.state
    const { deleteExportDestination, showToast } = this.props
    this.setState(prevState => ({
      deleteDestination: {
        ...prevState.deleteDestination,
        isLoading: true,
      },
    }))
    try {
      await deleteExportDestination(deleteDestination.destination.id)
      showToast("Destination deleted.")
      this.setState(prevState => ({
        deleteDestination: {
          ...prevState.deleteDestination,
          isLoading: false,
          modalOpen: false,
        },
      }))
    } catch {
      this.setState(prevState => ({
        deleteDestination: {
          ...prevState.deleteDestination,
          isLoading: false,
        },
      }))
    }
  }

  render() {
    const { areDestinationsFulfilled, destinations } = this.props
    const { deleteDestination } = this.state

    return (
      <section className="wrapper setup-destinations">
        <PaperHeader size="small" titleText="Destinations" className="setup-destinations-header" />
        {areDestinationsFulfilled && destinations.size > 0 && (
          <Paper hasHeader noPaddingTop>
            <Table className="setup-destinations-table">
              <Thead stickyHeader>
                <Th className="icon" />
                <Th className="id">ID</Th>
                <Th className="name">Name</Th>
                <Th className="exported-attributes">Exported attributes</Th>
                <Th className="mandatory-attributes">Mandatory attributes</Th>
                <Th className="mi-workspace">
                  MI Workspace{" "}
                  <Tippy content="Setting available only for certain destinations if MI connection is defined.">
                    <span>
                      <FontAwesomeIcon icon={["fas", "info-circle"]} />
                    </span>
                  </Tippy>
                </Th>
                <Th className="actions" />
              </Thead>
              <Tbody>
                {destinations
                  .map(destination => {
                    return (
                      <Tr key={destination.id}>
                        <Td className="icon">
                          <div className="icon-wrapper">
                            <img
                              src={getIconSrc(
                                _toLower(destination.icon),
                                destination.getIn(["frontend_settings", "alt_icon"]),
                              )}
                              alt="icon"
                            />
                          </div>
                        </Td>
                        <Td className="id">{destination.id}</Td>
                        <Td className="name" textBold textBigger textBlack>
                          {destination.name}
                        </Td>
                        <Td>
                          <DestinationAttributes
                            destination={destination}
                            showAttributes="exported"
                            showHiddenAttributes
                          />
                        </Td>
                        <Td>
                          <DestinationAttributes
                            destination={destination}
                            showAttributes="mandatory"
                            showHiddenAttributes
                          />
                        </Td>
                        <Td className="mi-workspace">{this.renderWorkspaceSelect(destination)}</Td>
                        <Td textAlignRight className="actions">
                          <IconButton
                            color={COLOR.BLACK}
                            size={SIZE.TAG}
                            withBackground
                            onClick={this.goToDestinationDetail(destination.id)}
                            iconStyle="fas"
                            iconName="pencil-alt"
                            tooltip="Edit"
                          />
                          <IconButton
                            color={COLOR.RED}
                            size={SIZE.TAG}
                            withBackground
                            onClick={this.toggleDeleteModal(destination)}
                            className="left-margin"
                            iconName="trash-alt"
                            tooltip="Delete"
                          />
                        </Td>
                      </Tr>
                    )
                  })
                  .toArray()}
              </Tbody>
            </Table>
            <ConfirmModal
              open={deleteDestination.modalOpen}
              type={MODAL.TYPE.DELETE}
              handleClose={this.toggleDeleteModal()}
              handleConfirm={this.deleteDestination}
              title="Are you sure?"
              action="delete"
              what="destination"
              item={_get(deleteDestination, "destination.name", "")}
              isLoading={deleteDestination.isLoading}
            />
          </Paper>
        )}
      </section>
    )
  }
}

const mapStateToProps = state => ({
  areDestinationsFetching: areExportDestinationsFetching(state),
  areDestinationsFulfilled: isExportDestinationsFulfilled(state),
  destinations: getExportDestinationsData(state),
  workspaces: getWorkspacesForSelect(state),
  areWorkspacesFulfilled: areWorkspacesFulfilled(state),
  globalSettings: getGlobalSettingsFormValues(state),
  isGlobalSettingsFetching: isGlobalSettingsFetching(state),
})

DestinationsList.propTypes = {
  areDestinationsFetching: PropTypes.bool.isRequired,
  areDestinationsFulfilled: PropTypes.bool.isRequired,
  fetchExportDestinationsList: PropTypes.func.isRequired,
  destinations: PropTypes.instanceOf(Map).isRequired,
  fetchWorkspacesList: PropTypes.func.isRequired,
  workspaces: PropTypes.array.isRequired,
  areWorkspacesFulfilled: PropTypes.bool.isRequired,
  globalSettings: PropTypes.object,
  modifyExportDestination: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  deleteExportDestination: PropTypes.func.isRequired,
  fetchGlobalSettingsList: PropTypes.func.isRequired,
  isGlobalSettingsFetching: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, {
  fetchExportDestinationsList,
  fetchWorkspacesList,
  modifyExportDestination,
  showToast,
  deleteExportDestination,
  fetchGlobalSettingsList,
})(DestinationsList)
