import { ChartOptions, InteractionMode } from "chart.js"
import { Context } from "chartjs-plugin-datalabels"
import { sum } from "ramda"

export const optionsBase: ChartOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  responsive: true,
  layout: {
    padding: {
      top: 20,
      right: 3,
      bottom: 3,
      left: 3,
    },
  },
  tooltips: {
    // External tooltip added in the ActivityChart component
    enabled: false,
    mode: "index" as InteractionMode,
    intersect: false,
    position: "nearest",
    // Sort by value descending
    itemSort: (a, b) => parseInt(b.value!, 10) - parseInt(a.value!, 10),
    callbacks: {
      title: items => {
        const { label } = items[0]
        return label?.startsWith("W") ? `Week of ${label.slice(1)}` : label ?? ""
      },
      beforeBody: items => {
        const total = sum(items.map(({ value }) => parseInt(value!, 10)))
        return total > 0 ? `Total interactions: ${total}` : ""
      },
      // Set aside value for filtering in the tooltip body
      afterLabel: item => item.value!,
      afterBody: items => {
        const zeroItemsCount = items.filter(({ value }) => value === "0").length
        return zeroItemsCount === 0
          ? ""
          : zeroItemsCount === items.length
          ? "No interactions"
          : `+ ${zeroItemsCount} others with no interactions`
      },
    },
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          autoSkip: true,
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
          callback: (label: string) => (label.startsWith("W") ? label.slice(1) : label),
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          autoSkip: true,
          suggestedMin: 0,
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
          precision: 0,
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      color: (ctx: Context) => ctx.dataset.borderColor as string,
      // Formatter added in the ActivityChart component (dependent on the component's state)
      font: {
        size: 11,
        weight: "bold",
      },
      anchor: "end",
      align: "end",
      offset: -3,
    },
  },
}
