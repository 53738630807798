import requestFactory from "api/request"
import { OrderDir } from "types/util"
import {
  UserRole,
  UserRoleCreatePayload,
  UserRoleListDeletedParams,
  UserRoleListDeletedResponse,
  UserRoleListResponse,
  UserRoleModifyPayload,
} from "./userRoleTypes"

const userRole = {
  list(
    offset = 0,
    limit = 50,
    order_by: keyof UserRole = "name",
    order_dir: OrderDir = "ASC",
  ): Promise<UserRoleListResponse> {
    return requestFactory("get", "/users/user_roles", { offset, limit, order_by, order_dir })
  },
  create(data: UserRoleCreatePayload): Promise<UserRole> {
    return requestFactory("post", "/users/user_roles", data)
  },
  modify(roleId: UserRole["id"], data: UserRoleModifyPayload): Promise<UserRole> {
    return requestFactory("patch", `/users/user_roles/${roleId}`, data)
  },
  delete(roleId: UserRole["id"]): Promise<void> {
    return requestFactory("delete", `/users/user_roles/${roleId}`, {}, true)
  },
  listDeleted({
    offset = 0,
    limit = 20,
    searched_text = "",
  }: UserRoleListDeletedParams = {}): Promise<UserRoleListDeletedResponse> {
    return requestFactory("get", "/users/user_roles/trash", { offset, limit, searched_text })
  },
  restoreDeleted(id: UserRole["id"]): Promise<UserRole> {
    return requestFactory("patch", `/users/user_roles/trash/${id}`)
  },
}

export default userRole
