import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import DelayedTooltip from "./DelayedTooltip/DelayedTooltip"

import "./IconButton.scss"

export const COLOR = {
  PRIMARY: "primary",
  RED: "red",
  GREY: "grey",
  BLACK: "black",
  GREEN: "green",
}

export const SIZE = {
  TAG: "size-tag",
  MEDIUM: "size-medium",
}

const IconButton = ({
  children = null,
  customIcon = null,
  iconName,
  iconStyle = "fas",
  color = COLOR.PRIMARY,
  className = "",
  type = "button",
  withBackground = false,
  faded = false,
  size = SIZE.MEDIUM,
  tooltip,
  disabled = false,
  ...buttonAttrs
}) => {
  const content =
    type === "anchor" ? (
      <a
        {...buttonAttrs}
        className={`icon-button ${!tooltip && className ? className : ""} ${color} ${size} ${
          withBackground ? "with-background" : ""
        } ${faded ? "faded" : ""}`}
        disabled={disabled}
      >
        {customIcon ? customIcon : <FontAwesomeIcon icon={[iconStyle, iconName]} />}
        {children}
      </a>
    ) : (
      <button
        {...buttonAttrs}
        type={type}
        className={`icon-button ${!tooltip && className ? className : ""} ${color} ${size} ${
          withBackground ? "with-background" : ""
        } ${faded ? "faded" : ""}`}
        disabled={disabled}
      >
        {customIcon ? customIcon : <FontAwesomeIcon icon={[iconStyle, iconName]} />}
        {children}
      </button>
    )

  if (tooltip) {
    return (
      <DelayedTooltip
        content={tooltip}
        className={`${className ? className : ""} ${disabled ? "disabled" : ""}`}
      >
        {content}
      </DelayedTooltip>
    )
  } else {
    return content
  }
}

IconButton.propTypes = {
  customIcon: PropTypes.node,
  iconName: PropTypes.string,
  iconStyle: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  withBackground: PropTypes.bool,
  size: PropTypes.string,
  tooltip: PropTypes.string,
}

export default IconButton
