import React, { useCallback, useState } from "react"
import styles from "./EditableName.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"

type EditableNameProps = {
  initValue: string
  onChange: (value: string) => void | Promise<void>
  className?: string
  inputClassName?: string
}

export default function EditableName({
  initValue,
  onChange,
  className,
  inputClassName,
}: EditableNameProps) {
  const [isEditing, setIsEditing] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [value, setValue] = useState(initValue)

  const save = useCallback(
    async e => {
      e.preventDefault()
      const value = e.target[0].value
      if (value !== initValue) {
        setIsSaving(true)
        await onChange(value)
        setIsSaving(false)
      }
      setIsEditing(false)
    },
    [initValue, onChange],
  )

  const cancel = useCallback(() => {
    setIsEditing(false)
    setValue(initValue)
  }, [initValue])

  const escListener = useCallback(e => {
    if (e.key === "Escape") setIsEditing(false)
  }, [])

  return isEditing ? (
    <form className={styles.container} onSubmit={save}>
      <input
        className={styles.input}
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyUp={escListener}
        autoFocus
        onFocus={e => e.target.select()}
      />
      <button
        className={styles.saveButton}
        type="submit"
        disabled={isSaving || value === ""}
        data-testid="save-button"
      >
        {isSaving ? (
          <LoadingIndicator className={styles.spinner} />
        ) : (
          <FontAwesomeIcon icon={["fas", "check"]} />
        )}
      </button>
      <button
        className={styles.button}
        type="button"
        disabled={isSaving}
        onClick={cancel}
        data-testid="cancel-button"
      >
        <FontAwesomeIcon icon={["fas", "times"]} />
      </button>
    </form>
  ) : (
    <div className={styles.container}>
      <div className={className}>{initValue}</div>
      <button
        className={styles.button}
        type="button"
        onClick={() => setIsEditing(true)}
        data-testid="edit-button"
      >
        <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
      </button>
    </div>
  )
}
