import React, { useRef } from "react"
import PropTypes from "prop-types"
import Select, { components } from "react-select"
import CreatableSelect from "react-select/lib/Creatable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _isNil from "lodash/isNil"

import { selectStyles } from "helpers/customSelectStyle.helper"
import Avatar from "../Avatar"

import "./SelectField.scss"
import { uniq } from "ramda"
import IconButton, { COLOR } from "../IconButton"
import { copyStringToClipboard } from "helpers/string.helper"
import { useDispatch } from "react-redux"
import { showToast } from "actions/toast.action"
import { TOAST } from "sharedConstants"

export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon
        icon={["fas", "caret-down"]}
        className="caret-down caret-down-indicator"
        data-testid="select-field-dropdown-indicator"
      />
    </components.DropdownIndicator>
  )
}

export const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <FontAwesomeIcon icon={["far", "times"]} className="cross-icon" />
    </components.ClearIndicator>
  )
}

const SingleValue = props => {
  const { data } = props

  return (
    <components.SingleValue {...props}>
      <div className="select-single-value">
        {data.name && data.email && (
          <Avatar name={data.name} email={data.email} className="gravatar-image" />
        )}
        {data.hidden && <FontAwesomeIcon className="hidden-icon" icon={["far", "eye-slash"]} />}
        {data.label}
      </div>
    </components.SingleValue>
  )
}

const Input = props => {
  const { onPaste } = props.selectProps
  return <components.Input onPaste={onPaste} {...props} />
}

export const AsyncSelectCountOption = props => {
  const { data } = props

  return (
    <components.Option {...props}>
      <div className="async_select__option">
        {data.label}
        {!_isNil(data.count) && <span className="count">({data.count})</span>}
      </div>
    </components.Option>
  )
}

export const AsyncSelectHighLowIndicatedOption = props => {
  const { data } = props
  return (
    <components.Option {...props}>
      <div className="async_select__option">
        {data.label}
        {data.highest && <span className="count">(Most common)</span>}
      </div>
    </components.Option>
  )
}

const Option = props => {
  const { data } = props
  return (
    <components.Option {...props}>
      <div className="select-option" data-testid="select-field-option">
        {data.name && data.email && (
          <Avatar name={data.name} email={data.email} className="gravatar-image" />
        )}
        {data.hidden && <FontAwesomeIcon className="hidden-icon" icon={["far", "eye-slash"]} />}
        {data.label}
      </div>
    </components.Option>
  )
}

const SelectField = ({
  input,
  label,
  meta: { touched, error } = {},
  options,
  onChange = null,
  isSearchable = true,
  isClearable = false,
  isLoading = false,
  size = "medium",
  className = "",
  maxDropdownHeight = "300px",
  isCreatable = false,
  inputId,
  placeholder = "Select...",
  disabled = false,
  focusedBorderColor = "#FE7F66",
  noOptionsMessage,
  isMulti = false,
  allowCopy = false,
  isOptionSelected = undefined,
  hideErrorMessage = false,
  onInputChange,
}) => {
  const errorMessage = touched ? error : ""
  const SelectToRender = isCreatable ? CreatableSelect : Select
  const inputRef = useRef()
  const dispatch = useDispatch()
  return (
    <div
      className={`select-field ${className} ${errorMessage ? "error" : ""} ${
        disabled ? "disabled" : ""
      } ${isMulti && allowCopy ? "allow-copy" : ""}`}
      data-testid={`select-field-wrapper-${inputId}`}
    >
      {label !== undefined && <label data-testid="label">{label}</label>}
      <SelectToRender
        ref={inputRef}
        value={input.value}
        onChange={
          onChange
            ? event => {
                input.onChange(event)
                onChange(event)
              }
            : input.onChange
        }
        options={options}
        styles={selectStyles(size, "all", maxDropdownHeight, focusedBorderColor)}
        simpleValue
        isSearchable={isCreatable ? true : isSearchable}
        isClearable={isClearable}
        isLoading={isLoading}
        className="select-input"
        formatCreateLabel={value => `add "${value}"`}
        inputId={inputId}
        placeholder={placeholder}
        components={{
          SingleValue: SingleValue,
          Option: Option,
          DropdownIndicator: DropdownIndicator,
          ClearIndicator: ClearIndicator,
          Input: Input,
        }}
        noOptionsMessage={() => {
          if (noOptionsMessage) {
            return noOptionsMessage
          } else {
            return isCreatable ? "Create new by typing" : "Empty"
          }
        }}
        isDisabled={disabled}
        classNamePrefix="react-select-redux-field"
        isMulti={isMulti}
        isOptionSelected={isOptionSelected}
        onInputChange={value => onInputChange?.(value)}
        onPaste={
          isMulti
            ? evt => {
                evt.preventDefault()
                const clipboard = evt.clipboardData.getData("Text")
                if (!clipboard) return
                const options = clipboard.split(/,+/).map(value => ({
                  label: value.trim(),
                  value: value.trim(),
                }))
                const prevValues = inputRef.current.props.value ?? []
                inputRef.current.select.select.setValue(uniq(prevValues.concat(options)))
              }
            : undefined
        }
      />
      {isMulti && allowCopy && (
        <IconButton
          color={COLOR.GREY}
          onClick={evt => {
            evt.preventDefault()
            if (!inputRef.current?.props.value) {
              dispatch(showToast("Nothing to copy.", TOAST.TYPE.ERROR))
              return
            }
            const valuesToCopy = inputRef.current.props.value.map(v => v.value).join(",")
            copyStringToClipboard(valuesToCopy)
            dispatch(showToast("Values copied to a clipboard."))
          }}
          iconStyle="far"
          iconName="clone"
          className={`select-copy-icon ${label === undefined ? "no-label" : ""}`}
        />
      )}
      {errorMessage && !hideErrorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  )
}

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCreatable: PropTypes.bool,
  size: PropTypes.string,
  maxDropdownHeight: PropTypes.string,
  inputId: PropTypes.string,
  focusedBorderColor: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  hideErrorMessage: PropTypes.bool,
  onInputChange: PropTypes.func,
}

export default SelectField
