import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { Dispatch } from "redux"
import {
  SmartSegmentListResponse,
  FeaturedSegmentListResponse,
  SegmentListResponse,
  Segment,
  SegmentModifyPayload,
  SegmentCreatePayload,
  SegmentType,
} from "./segmentTypes"
import { Tag } from "resources/tag/tagTypes"
import { Flag } from "types/util"

export const SEGMENTS = {
  ACTION: {
    FETCH: "FETCH_SEGMENTS",
    CREATE: "CREATE_SEGMENT",
    RETRIEVE: "RETRIEVE_SEGMENT",
    MODIFY: "MODIFY_SEGMENT",
    DELETE: "DELETE_SEGMENT",
  },
  LOADING_LIMIT: 20,
}

export const SMART_SEGMENTS = {
  ACTION: {
    FETCH_ALL: "FETCH_ALL_SMART_SEGMENTS",
    CREATE: "CREATE_SMART_SEGMENT",
    RETRIEVE: "RETRIEVE_SMART_SEGMENT",
    MODIFY: "MODIFY_SMART_SEGMENT",
    DELETE: "DELETE_SMART_SEGMENT",
  },
  LOADING_LIMIT: 100,
} as const

export const FEATURED_SEGMENTS = {
  ACTION: {
    FETCH: "FETCH_FEATURED_SEGMENTS",
    CREATE: "CREATE_FEATURED_SEGMENT",
    RETRIEVE: "RETRIEVE_FEATURED_SEGMENT",
    MODIFY: "MODIFY_FEATURED_SEGMENT",
    DELETE: "DELETE_FEATURED_SEGMENT",
  },
  LOADING_LIMIT: 20,
} as const

export const fetchSegments =
  (
    offset: number,
    name_filter = "",
    order_by = "name" as SegmentListResponse<0>["selection_settings"]["order_by"],
    order_dir = "asc" as SegmentListResponse<0>["selection_settings"]["order_dir"],
    tag_ids = [] as Tag["id"][],
    type = "regular" as SegmentType,
  ) =>
  async (dispatch: Dispatch) => {
    const actionType = type === "featured" ? FEATURED_SEGMENTS.ACTION.FETCH : SEGMENTS.ACTION.FETCH
    return dispatch({
      type: actionType,
      payload: {
        promise: api.featuredSegment.list(
          offset,
          FEATURED_SEGMENTS.LOADING_LIMIT,
          name_filter,
          order_by!,
          order_dir!,
          tag_ids,
        ),
      },
    })
  }

export const fetchAllSmartSegments = () => async (dispatch: Dispatch) => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: SMART_SEGMENTS.ACTION.FETCH_ALL,
    payload: {
      promise: caller
        .setEndpointCall((offset: number, limit: number, loadFullStructure: Flag) =>
          api.smartSegment.list(offset, limit, "id", "ASC", loadFullStructure),
        )
        .setDataPath("prebuilt_segments")
        .setLoadFullStructure(0)
        .run(),
    },
  })
}

export const fetchSegmentsFulfilledType = `${SEGMENTS.ACTION.FETCH}_FULFILLED` as const
type FetchSegmentsAction = {
  type: typeof fetchSegmentsFulfilledType
  payload: SegmentListResponse<0>
}

export const fetchFeaturedSegmentsFulfilledType =
  `${FEATURED_SEGMENTS.ACTION.FETCH}_FULFILLED` as const
type FetchFeaturedSegmentsAction = {
  type: typeof fetchFeaturedSegmentsFulfilledType
  payload: FeaturedSegmentListResponse<0>
}

export const fetchAllSmartSegmentsPendingType =
  `${SMART_SEGMENTS.ACTION.FETCH_ALL}_PENDING` as const
type FetchAllSmartSegmentsPendingAction = {
  type: typeof fetchAllSmartSegmentsPendingType
}
export const fetchAllSmartSegmentsFulfilledType =
  `${SMART_SEGMENTS.ACTION.FETCH_ALL}_FULFILLED` as const
type FetchAllSmartSegmentsFulfilledAction = {
  type: typeof fetchAllSmartSegmentsFulfilledType
  payload: SmartSegmentListResponse<0>["prebuilt_segments"]
}

export const createSegment =
  (data: SegmentCreatePayload, type = "regular" as SegmentType) =>
  async (dispatch: Dispatch) => {
    const actionType =
      type === "featured"
        ? FEATURED_SEGMENTS.ACTION.CREATE
        : type === "smart"
        ? SMART_SEGMENTS.ACTION.CREATE
        : SEGMENTS.ACTION.CREATE
    return dispatch({
      type: actionType,
      payload: {
        promise: api.segment.create(data),
      },
    })
  }

export const createSegmentFulfilledType = `${SEGMENTS.ACTION.CREATE}_FULFILLED` as const
type CreateSegmentAction = {
  type: typeof createSegmentFulfilledType
  payload: {
    segment: Segment
  }
}

export const createSmartSegmentFulfilledType = `${SMART_SEGMENTS.ACTION.CREATE}_FULFILLED` as const
type CreateSmartSegmentAction = {
  type: typeof createSmartSegmentFulfilledType
  payload: {
    segment: Segment
  }
}

export const createFeaturedSegmentFulFilledType =
  `${FEATURED_SEGMENTS.ACTION.CREATE}_FULFILLED` as const
type CreateFeaturedSegmentAction = {
  type: typeof createFeaturedSegmentFulFilledType
  payload: {
    segment: Segment
  }
}

export const retrieveSegment =
  (id: Segment["id"], type = "regular" as SegmentType) =>
  async (dispatch: Dispatch) => {
    const actionType =
      type === "featured"
        ? FEATURED_SEGMENTS.ACTION.RETRIEVE
        : type === "smart"
        ? SMART_SEGMENTS.ACTION.RETRIEVE
        : SEGMENTS.ACTION.RETRIEVE
    return dispatch({
      type: actionType,
      payload: {
        promise: api.segment.retrieve(id),
      },
    })
  }

export const retrieveSegmentFulfilledType = `${SEGMENTS.ACTION.RETRIEVE}_FULFILLED` as const
type RetrieveSegmentAction = {
  type: typeof retrieveSegmentFulfilledType
  payload: {
    segment: Segment
  }
}

export const retrieveSmartSegmentFulfilledType =
  `${SMART_SEGMENTS.ACTION.RETRIEVE}_FULFILLED` as const
type RetrieveSmartSegmentAction = {
  type: typeof retrieveSmartSegmentFulfilledType
  payload: {
    segment: Segment
  }
}

export const retrieveFeaturedSegmentFulfilledType =
  `${FEATURED_SEGMENTS.ACTION.RETRIEVE}_FULFILLED` as const
type RetrieveFeaturedSegmentAction = {
  type: typeof retrieveFeaturedSegmentFulfilledType
  payload: {
    segment: Segment
  }
}

export const modifySegment =
  (id: Segment["id"], data: SegmentModifyPayload, type = "regular" as SegmentType) =>
  async (dispatch: Dispatch) => {
    const actionType =
      type === "featured"
        ? FEATURED_SEGMENTS.ACTION.MODIFY
        : type === "smart"
        ? SMART_SEGMENTS.ACTION.MODIFY
        : SEGMENTS.ACTION.MODIFY
    return dispatch({
      type: actionType,
      payload: {
        promise: api.segment.modify(id, data),
      },
    })
  }

export const modifySegmentFulfilledType = `${SEGMENTS.ACTION.MODIFY}_FULFILLED` as const
type ModifySegmentAction = {
  type: typeof modifySegmentFulfilledType
  payload: {
    segment: Segment
  }
}

export const modifySmartSegmentFulfilledType = `${SMART_SEGMENTS.ACTION.MODIFY}_FULFILLED` as const
type ModifySmartSegmentAction = {
  type: typeof modifySmartSegmentFulfilledType
  payload: {
    segment: Segment
  }
}

export const modifyFeaturedSegmentFulfilledType =
  `${FEATURED_SEGMENTS.ACTION.MODIFY}_FULFILLED` as const
type ModifyFeaturedSegmentAction = {
  type: typeof modifyFeaturedSegmentFulfilledType
  payload: {
    segment: Segment
  }
}

export const deleteSegment =
  (id: Segment["id"], type = "regular" as SegmentType) =>
  async (dispatch: Dispatch) => {
    const actionType =
      type === "featured"
        ? FEATURED_SEGMENTS.ACTION.DELETE
        : type === "smart"
        ? SMART_SEGMENTS.ACTION.DELETE
        : SEGMENTS.ACTION.DELETE
    return dispatch({
      type: actionType,
      payload: {
        promise: async () => {
          await api.segment.delete(id)
          return id
        },
      },
    })
  }

export const deleteSegmentFulfilledType = `${SEGMENTS.ACTION.DELETE}_FULFILLED` as const
type DeleteSegmentAction = {
  type: typeof deleteSegmentFulfilledType
  payload: Segment["id"]
}

export const deleteSmartSegmentFulfilledType = `${SMART_SEGMENTS.ACTION.DELETE}_FULFILLED` as const
type DeleteSmartSegmentAction = {
  type: typeof deleteSmartSegmentFulfilledType
  payload: Segment["id"]
}

export const deleteFeaturedSegmentFulfilledType =
  `${FEATURED_SEGMENTS.ACTION.DELETE}_FULFILLED` as const
type DeleteFeaturedSegmentAction = {
  type: typeof deleteFeaturedSegmentFulfilledType
  payload: Segment["id"]
}

export type SegmentAction =
  | FetchSegmentsAction
  | CreateSegmentAction
  | RetrieveSegmentAction
  | ModifySegmentAction
  | DeleteSegmentAction

export type SmartSegmentAction =
  | FetchAllSmartSegmentsPendingAction
  | FetchAllSmartSegmentsFulfilledAction
  | CreateSmartSegmentAction
  | RetrieveSmartSegmentAction
  | ModifySmartSegmentAction
  | DeleteSmartSegmentAction

export type FeaturedSegmentsAction =
  | FetchFeaturedSegmentsAction
  | CreateFeaturedSegmentAction
  | RetrieveFeaturedSegmentAction
  | ModifyFeaturedSegmentAction
  | DeleteFeaturedSegmentAction
