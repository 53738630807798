import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import DatePicker from "react-datepicker"
import _isNull from "lodash/isNull"
import _isString from "lodash/isString"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Button from "../../elements/Button/Button"

import { MOMENT } from "sharedConstants"

import "./CustomDatePicker.scss"
import { sanitizeHour, sanitizeMinute } from "./timeSanitizers"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import Tippy from "@tippyjs/react"
import { api } from "api"

class CustomDatePicker extends PureComponent {
  constructor(props) {
    super(props)
    const { value, dataType } = props
    let inputValue = ""
    if (value) {
      inputValue = moment.isMoment(value)
        ? value.format(
            dataType === "datetime"
              ? MOMENT.DATETIME_MONTH_WORD_FORMAT
              : MOMENT.DATE_MONTH_WORD_FORMAT,
          )
        : value
    }
    this.state = {
      type: moment.isMoment(value) || !value ? "calendar" : "input",
      naturalLanguageValue: inputValue,
      date: moment.isMoment(value) ? value : null,
      hours: moment.isMoment(value) ? sanitizeHour(value.local().hour().toString()) : "00",
      minutes: moment.isMoment(value) ? sanitizeMinute(value.local().minute().toString()) : "00",
      resolvedNLValue: null,
      isFetchingResolvedNLValue: false,
    }
  }

  toggleDropdownType = type => () => {
    this.setState({
      type,
    })
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this._handleOutsideClick, false)
    window.removeEventListener("keyup", this._handleKeyUp, false)
  }

  _handleOutsideClick = evt => {
    const DROPDOWN_MONTH = "react-datepicker__month-option"
    const YEAR_DROPDOWN = "react-datepicker__year-option"

    if (!_isNull(this.datepicker)) {
      if (!this.datepicker.contains(evt.target)) {
        if (!_isNull(evt.target) && _isString(evt.target.className)) {
          if (
            !evt.target.className.includes(DROPDOWN_MONTH) &&
            !evt.target.className.includes(YEAR_DROPDOWN)
          ) {
            this.props.toggle()
          }
        } else {
          this.props.toggle()
        }
      }
    }
  }

  onNaturalLanguageFieldChange = evt => {
    this.setState({
      naturalLanguageValue: evt.currentTarget.value,
    })
  }

  _handleKeyUp = evt => {
    const keys = {
      13: () => {
        evt.preventDefault()
        this.applyChanges()
      },
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  componentDidMount() {
    const { value } = this.props

    if (value && !moment.isMoment(value)) {
      this.fetchNLDateValue()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      // datepicker opened
      setTimeout(() => document.addEventListener("mousedown", this._handleOutsideClick, false), 0)
      window.addEventListener("keyup", this._handleKeyUp, false)
    } else if (!this.props.open && prevProps.open) {
      // datepicker closed
      if (
        this.state.type === "input" &&
        this.state.naturalLanguageValue &&
        this.props.value !== this.state.naturalLanguageValue
      ) {
        // this.props.onChange({ value: this.state.naturalLanguageValue })
      }
      document.removeEventListener("mousedown", this._handleOutsideClick, false)
      window.removeEventListener("keyup", this._handleKeyUp, false)
    }

    const { value, dataType } = this.props
    const prevValue = prevProps.value
    if (value !== prevValue) {
      this.setState({ resolvedNLValue: null })

      const same = moment.isMoment(value) && moment.isMoment(prevValue) && value.isSame(prevValue)

      if (!same) {
        if (value && !moment.isMoment(value)) {
          this.fetchNLDateValue()
        }

        const fieldValue = moment.isMoment(value)
          ? value
              .local()
              .format(
                dataType === "datetime"
                  ? MOMENT.DATETIME_MONTH_WORD_FORMAT
                  : MOMENT.DATE_MONTH_WORD_FORMAT,
              )
          : value
        this.setState({
          naturalLanguageValue: fieldValue,
          date: moment.isMoment(value) ? value : null,
          hours: moment.isMoment(value) ? sanitizeHour(value.local().hour().toString()) : "00",
          minutes: moment.isMoment(value)
            ? sanitizeMinute(value.local().minute().toString())
            : "00",
        })
      }
    }
  }

  applyChanges = () => {
    const { date, hours, minutes, type, naturalLanguageValue } = this.state
    const { dataType, onChange, toggle } = this.props

    if (type === "calendar") {
      if (dataType === "datetime") {
        onChange(moment(date).local().hour(hours).minute(minutes))
      } else {
        onChange(date.format(MOMENT.DB_DATE_FORMAT))
      }
    } else {
      onChange(naturalLanguageValue)
    }

    toggle()
  }

  fetchNLDateValue = async () => {
    const { value, dataType } = this.props
    this.setState({ isFetchingResolvedNLValue: true })
    const resolvedNLValue = (await api.tools.textToDatetime(value, dataType))[value]
    this.setState({ resolvedNLValue, isFetchingResolvedNLValue: false })
  }

  render() {
    const { value, isEditable, open, toggle, className = "", placeholder, dataType } = this.props
    const {
      type,
      naturalLanguageValue,
      date,
      hours,
      minutes,
      resolvedNLValue,
      isFetchingResolvedNLValue,
    } = this.state

    const formatString =
      dataType === "datetime" ? MOMENT.DATETIME_MONTH_WORD_FORMAT : MOMENT.DATE_MONTH_WORD_FORMAT

    let fieldValue = ""
    if (value) {
      fieldValue = moment.isMoment(value) ? value.local().format(formatString) : value
    }

    const showTimeTooltip = value && (!moment.isMoment(value) || dataType === "datetime")

    let tooltipContent = null

    if (showTimeTooltip) {
      if (moment.isMoment(value)) {
        tooltipContent = (
          <>
            <p>
              <strong>Local time:</strong> {value.local().format(formatString)}
            </p>
            <p>
              <strong>UTC time:</strong> {value.utc().format(formatString)}
            </p>
          </>
        )
      } else {
        if (isFetchingResolvedNLValue) {
          tooltipContent = "(fetching…)"
        }

        tooltipContent = resolvedNLValue && (
          <>
            <p>
              Value if resolved at this moment:{" "}
              {dataType === "date" && moment(resolvedNLValue).format(formatString)}
            </p>
            {dataType === "datetime" && (
              <>
                {" "}
                <p>
                  <strong>Local time:</strong>{" "}
                  {moment.utc(resolvedNLValue).local().format(formatString)}
                </p>
                <p>
                  <strong>UTC time:</strong> {moment(resolvedNLValue).format(formatString)}
                </p>
              </>
            )}
          </>
        )
      }
    }

    return (
      <div className={`datepicker-wrapper custom ${className}`}>
        <input
          type="text"
          value={fieldValue}
          placeholder={placeholder ?? "Pick a date"}
          className="condition-input"
          onClick={toggle}
          readOnly
          disabled={!isEditable}
        />
        {showTimeTooltip && (
          <Tippy content={tooltipContent}>
            <div className="time-tooltip-wrapper">
              <FontAwesomeIcon icon={["fas", "clock"]} />
            </div>
          </Tippy>
        )}
        {open && (
          <div
            className="react-datepicker-popper custom"
            data-placement="bottom-start"
            ref={node => (this.datepicker = node)}
          >
            <div className="custom-header">
              <Button
                color={type === "calendar" ? "primary" : "white"}
                size="small"
                onClick={this.toggleDropdownType("calendar")}
              >
                Calendar
              </Button>
              <Button
                color={type === "input" ? "primary" : "white"}
                size="small"
                onClick={this.toggleDropdownType("input")}
              >
                Natural language
              </Button>
            </div>
            {type === "calendar" && (
              <div className="calendar-wrapper">
                {dataType === "datetime" && (
                  <div className="time-input-wrapper">
                    <div className="time-input-label">time:</div>
                    <input
                      value={hours}
                      onChange={e => {
                        const newValue = sanitizeHour(e.target.value)
                        if (newValue) {
                          this.setState({ hours: newValue })
                        }
                      }}
                      className="time-input"
                      onFocus={e => e.target.select()}
                    />
                    <span>&nbsp;:&nbsp;</span>
                    <input
                      value={minutes}
                      onChange={e => {
                        const newValue = sanitizeMinute(e.target.value)
                        if (newValue) {
                          this.setState({ minutes: newValue })
                        }
                      }}
                      className="time-input"
                      onFocus={e => e.target.select()}
                    />
                    <InfoTooltip className="time-input-info">
                      Enter values in your local timezone.
                    </InfoTooltip>
                  </div>
                )}
                <DatePicker
                  dateFormat={MOMENT.DATE_MONTH_WORD_FORMAT}
                  selected={date?.toDate() ?? null}
                  onChange={value => this.setState({ date: moment(value) })}
                  className="condition-input"
                  calendarClassName="calendar-dropdown"
                  disabledKeyboardNavigation
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  maxDate={moment().endOf("year").add(10, "year").toDate()}
                  yearDropdownItemNumber={100}
                  disabled={!isEditable}
                  inline
                />
                <div className="calendar-buttons-wrapper">
                  <Button color="white" onClick={toggle}>
                    close
                  </Button>
                  <Button color="primary" onClick={this.applyChanges} disabled={!date}>
                    apply
                  </Button>
                </div>
              </div>
            )}
            {type === "input" && (
              <div className="natural-language">
                <div className="nat-lang-form">
                  <input
                    type="text"
                    value={naturalLanguageValue}
                    placeholder="Write date in natural language"
                    onChange={this.onNaturalLanguageFieldChange}
                    autoFocus
                    autoComplete="off"
                  />
                  <Button
                    onClick={this.applyChanges}
                    color="primary"
                    disabled={!naturalLanguageValue}
                    className="nat-lang-submit-button"
                    size="small"
                  >
                    <FontAwesomeIcon icon={["fas", "check"]} />
                  </Button>
                </div>
                <div className="help-box">
                  <p className="help-message">
                    For example: today, yesterday, friday, -5 days, 20 days
                  </p>
                  <p className="help-message">
                    See{" "}
                    <a
                      href="https://docs.meiro.io/books/meiro-business-explorer/page/segment-builder-date-datetime-attributes#bkmrk-4.-how-natural-langu"
                      target="_blank"
                      rel="noreferrer"
                    >
                      documentation
                    </a>{" "}
                    for details.
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

CustomDatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(moment), PropTypes.string]),
  open: PropTypes.bool,
  isEditable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  dataType: PropTypes.string,
}

export default CustomDatePicker
