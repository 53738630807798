import ComingSoonPage from "components/ComingSoonPage/ComingSoonPage"
import React from "react"
import mainImg from "./mobile-push-notifications.jpg"

export default function MobilePushNotifications() {
  return (
    <ComingSoonPage mainImgSrc={mainImg}>
      <h1>Push notifications for mobile apps</h1>
      <p>
        The new channel for activation allows you to combine real time web personalization{" "}
        <strong>with mobile app push messages</strong>.
      </p>
      <p>Use your audiences to trigger personalized content for your mobile app users.</p>
      <p>
        Notifications are shipping in a few weeks. To access the beta tests, please email{" "}
        <a href="mailto:product@meiro.io" target="_blank" rel="noreferrer">
          product@meiro.io
        </a>{" "}
        or get in touch with your account manager!
      </p>
    </ComingSoonPage>
  )
}
