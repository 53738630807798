import React from "react"
import PropTypes from "prop-types"
import { Line } from "react-chartjs-2"
import moment from "moment"
import _isFinite from "lodash/isFinite"

// constants, helpers
import { MOMENT } from "sharedConstants"
import { hexToRgba } from "helpers/chartjs.helper"
import { abbreviateNumber } from "helpers/number.helper"

const options = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  layout: {
    padding: {
      top: 10,
      right: 3,
      bottom: 3,
      left: 3,
    },
  },
  tooltips: {
    enabled: true,
    callbacks: {
      label: function (tooltipItems, data) {
        if (_isFinite(tooltipItems.yLabel)) {
          return `Count: ${abbreviateNumber(tooltipItems.yLabel)}`
        }
        return tooltipItems.yLabel
      },
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          maxTicksLimit: 3,
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
          callback: function (value) {
            return abbreviateNumber(value)
          },
        },
      },
    ],
  },
}

const CustomersOverTimeChart = ({ rawData, color, dataResolution, className }) => {
  const data = {
    labels: rawData.map(entry =>
      dataResolution === "month"
        ? moment(entry.date).format("MMM YYYY")
        : moment(entry.date).format(MOMENT.DATE_FORMAT),
    ),
    datasets: [
      {
        backgroundColor: hexToRgba(color, 0.5),
        borderColor: color,
        borderWidth: 2,
        fill: true,
        pointRadius: 3,
        pointBackgroundColor: color,
        data: rawData.map(entry => entry.count),
      },
    ],
  }

  return (
    <div className={`customer-events-per-date-chart ${className}`}>
      <Line data={data} options={options} />
    </div>
  )
}

CustomersOverTimeChart.propTypes = {
  rawData: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  dataResolution: PropTypes.string,
  className: PropTypes.string,
}

export default CustomersOverTimeChart
