import { Record } from "immutable"

const CustomerAttributeCount = Record({
  id: 0,
  name: "",
  attribute_id: "",
  count: 0,
})

export default CustomerAttributeCount
