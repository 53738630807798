import {
  createSmartSegmentFulfilledType,
  deleteSmartSegmentFulfilledType,
  fetchAllSmartSegmentsFulfilledType,
  fetchAllSmartSegmentsPendingType,
  modifySmartSegmentFulfilledType,
  retrieveSmartSegmentFulfilledType,
  SmartSegmentAction,
} from "./segmentActions"
import { Segment } from "./segmentTypes"

const initialState = {
  isFetching: true,
  isFulfilled: false,
  data: [] as Segment[],
}

type SmartSegmentsState = typeof initialState

export default function smartSegments(
  state: SmartSegmentsState = initialState,
  action: SmartSegmentAction,
) {
  switch (action.type) {
    case fetchAllSmartSegmentsPendingType: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case fetchAllSmartSegmentsFulfilledType: {
      return {
        ...state,
        isFetching: false,
        isFulfilled: true,
        data: action.payload,
      }
    }
    case retrieveSmartSegmentFulfilledType: {
      const isAlreadyInList = state.data.find(segment => segment.id === action.payload.segment.id)
      if (isAlreadyInList) {
        return {
          ...state,
          data: state.data.map(segment =>
            segment.id === action.payload.segment.id ? action.payload.segment : segment,
          ),
        }
      } else {
        return {
          ...state,
          data: [...state.data, action.payload.segment],
        }
      }
    }
    case modifySmartSegmentFulfilledType: {
      return {
        ...state,
        data: state.data.map(segment =>
          segment.id === action.payload.segment.id ? action.payload.segment : segment,
        ),
      }
    }
    case createSmartSegmentFulfilledType: {
      return {
        ...state,
        data: [...state.data, action.payload.segment],
      }
    }
    case deleteSmartSegmentFulfilledType: {
      return {
        ...state,
        data: state.data.filter(segment => segment.id !== action.payload),
      }
    }
    default:
      return state
  }
}
