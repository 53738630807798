import _isEmpty from "lodash/isEmpty"
import _trim from "lodash/trim"
import _get from "lodash/get"
import _isArray from "lodash/isArray"
import zxcvbn from "zxcvbn"
import _isNumber from "lodash/isNumber"

export const isEmailValid = email => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(_trim(email)) ? false : true
}

export const isValidPythonVariable = variable => {
  return /^[a-z]{1}[a-z0-9_]*$/i.test(variable)
}

const isUrlValid = url => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url,
  )
}

export const isJSONString = str => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const isEmptyField = value => value === undefined || value === null || _trim(value) === ""

// validation mesages
export const email = value => (value && !isEmailValid(value) ? "Invalid email address" : undefined)
export const required = value =>
  value || _isNumber(value) ? undefined : "Please fill in the field"
export const requiredArray = value => (value?.length > 0 ? undefined : "Please fill in the field")
export const minLength6 = value => (value.length < 6 ? "At least 6 characters required" : undefined)
export const minLength12 = value =>
  value.length < 12 ? "At least 12 characters required" : undefined
export const optionRequired = value => (!_isEmpty(value) ? undefined : "Please select one option")
export const passwordStrength = value => {
  const strResults = zxcvbn(value)
  if (strResults.score >= 3) {
    return undefined
  } else {
    if (_isArray(_get(strResults, "feedback.suggestions"))) {
      return strResults.feedback.suggestions[strResults.feedback.suggestions.length - 1]
    }
    return "Password is weak."
  }
}
export const validateDomElementId = value =>
  value && typeof value === "string" && /^[a-zA-Z0-9\-_]+$/i.test(value.trim())
    ? undefined
    : "Must contain only letters, numbers, '_' or '-'"

export const requiredFile = value => (value && value[0] ? undefined : "Please select a file")
export const maxFileSize = (size, value) => {
  if (value && value[0]) {
    const file = value[0]
    if (file.size > size) {
      return "Max size is 2MB"
    }
  }
  return undefined
}

export const validImageFile = value => {
  if (value && value[0]) {
    const image = value[0]
    if (image.size > 500000) {
      return "Max size is 500kB"
    } else if (
      ![
        "image/apng",
        "image/avif",
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/webp",
      ].includes(image.type)
    ) {
      return "Unsupported image type, allowed: APNG, AVIF, GIF, JPG, PNG, SVG, WEBP"
    }
  }
  return undefined
}
export const pythonVariable = value => {
  let valueToCheck = value
  if (typeof value === "object" && value.hasOwnProperty("value")) {
    valueToCheck = value.value
  }
  return valueToCheck && !isValidPythonVariable(valueToCheck)
    ? "Must start with the letter and contain letter/digit characters or '_'"
    : undefined
}
export const validateUrl = value => (value && !isUrlValid(value) ? "Not a valid URL." : undefined)
