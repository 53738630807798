import { Template } from "components/UI/components/TemplateGallery/TemplateGalleryItem/TemplateGalleryItem"
import imgSrc from "./shoppingIntention.jpg"

const shoppingIntention: Template = {
  id: "shoppingIntention",
  name: "Shopping intention",
  description:
    "Nudge customers to buy products they've been browsing but didn't add to the shopping cart.",
  imgSrc,
  width: 400,
  highlight: {
    startRow: 86,
    endRow: 98,
  },
  docLink:
    "https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-use-cases-library-html-for-shopping-intention",
  html: `<!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        body {
          margin: 0;
          font-family: sans-serif;
          color: #222222;
        }

        .banner {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          cursor: pointer;
          border-radius: 8px;
          padding: 15px;
          border: 1px solid #777777;
          background-color: white;
        }

        .text-container {
          align-self: stretch;
        }

        .text-container h1 {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
        }

        .text-container p {
          margin-top: 5px;
        }

        #product-thumbnail {
          width: 100px;
          margin: 10px;
          border-radius: 4px;
        }

        #product-name {
          font-size: 12px;
          font-weight: 500;
        }

        #product-price {
          font-size: 14px;
          font-weight: 600;
        }

        .cta-button {
          height: 40px;
          border-radius: 20px;
          cursor: pointer;
          border: none;
          margin-top: 10px;
          padding: 0 20px;
          background-color: #fe7f66;
          color: white;
          font-size: 14px;
          font-weight: 600;
          transition: all 0.15s ease;
        }

        .cta-button:hover {
          background-color: #eb6c52;
        }
      </style>
    </head>
    <body>
      <div class="banner" onclick="goToProduct()">
        <div class="text-container">
          <h1>Are you interested in this product?</h1>
          <p>Go take another look!</p>
        </div>
        <img id="product-thumbnail" src="" />
        <div id="product-name"></div>
        <div id="product-price"></div>
        <button class="cta-button">View product</button>
      </div>
      <script>
        var attributeId = "most_expensive_viewed_product"
        var getProductFromAttributeValue = function (attributeValue) {
          const product = JSON.parse(attributeValue[0])
          return {
            name: product[1],
            price: product[2],
            url: product[3],
            imageUrl: product[4],
          }
        }
        var displayPrice = function (price) {
          return price + " €"
        }

        var productUrl

        if (window.parent && window.parent.MeiroEvents) {
          var bannerID = window.parent.MeiroEvents.getWebBannerId()
          var responses = window.parent.MeiroEvents.getWebBannerHttpResponses(bannerID)
          var product = getProductFromAttributeValue(
            responses[0].data.returned_attributes[attributeId],
          )

          document.getElementById("product-name").textContent = product.name
          document.getElementById("product-thumbnail").setAttribute("src", product.imageUrl)
          document.getElementById("product-price").textContent = displayPrice(product.price)

          productUrl = product.url
        }

        function goToProduct() {
          if (window.parent && window.parent.MeiroEvents) {
            window.parent.MeiroEvents.goToWebBannerUrl(productUrl)
          }
        }
      </script>
    </body>
  </html>
  `,
}

export default shoppingIntention
