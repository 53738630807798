import React, { PureComponent } from "react"
import AutoSuggest from "react-autosuggest"
import PropTypes from "prop-types"

import "./Whisperer.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"

const getSuggestions = (suggestions, value) => {
  if (!value) return suggestions

  const inputValue = value.trim().toLowerCase()
  return suggestions.filter(suggestion => {
    const lSuggestion = suggestion.value.toLowerCase()
    return lSuggestion.includes(inputValue)
  })
}

const shouldRenderSuggestions = () => {
  return true
}

const getSuggestionValue = suggestion => suggestion.value

const renderSuggestion = suggestion => (
  <div className="whisperer-suggestion" id={suggestion.id}>
    <span className="suggestion-label">{suggestion.label}</span>
    {suggestion.highest && <span className="most-common">(Most&nbsp;common)</span>}
  </div>
)

const renderSuggestionsContainer = ({ containerProps, children }) => {
  return (
    <div {...containerProps}>
      <span className="suggestions-label">Suggestions:</span>
      <div className="scrollable">{children}</div>
    </div>
  )
}

const onSuggestionHighlighted = ({ suggestion }) => {
  if (suggestion) {
    const container = document.getElementsByClassName(
      "react-autosuggest__suggestions-container--open",
    )[0]
    if (container) {
      const scrollable = container.getElementsByClassName("react-autosuggest__suggestions-list")[0]
      if (scrollable) {
        const el = document.getElementById(suggestion.id)
        if (el) {
          if (Math.abs(scrollable.scrollTop - el.offsetTop) > 200) {
            scrollable.scrollTop = el.offsetTop - 192
          } else if (scrollable.scrollTop >= el.offsetTop) {
            scrollable.scrollTop = el.offsetTop - el.offsetHeight
          }
        }
      }
    }
  }
}

class Whisperer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      filteredSuggestions: [],
      allSuggestions: null,
    }

    this.inputRef = React.createRef()
  }

  componentDidMount() {
    const { fetchOptions } = this.props
    if (fetchOptions) {
      this.setState({ loading: true })
      fetchOptions()
        .then(allSuggestions => {
          this.setState({
            allSuggestions,
            filteredSuggestions: allSuggestions,
            loading: false,
          })
        })
        .catch(() => {
          this.setState({ loading: true })
        })
    }

    window.addEventListener("keyup", this.handleKeyUp, false)
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
  }

  handleKeyUp = evt => {
    const loseFocus = () => {
      if (this.inputRef.current && document.activeElement === this.inputRef.current) {
        this.inputRef.current.blur()
      }
    }

    const keys = {
      13: loseFocus,
      27: loseFocus,
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    const { options = [] } = this.props
    const { allSuggestions } = this.state

    this.setState({
      filteredSuggestions: getSuggestions(allSuggestions ? allSuggestions : options, value),
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      filteredSuggestions: [],
    })
  }

  render() {
    const {
      placeholder = "Type",
      value,
      onChange,
      className = "",
      disabled = false,
      size = "medium",
    } = this.props
    const { filteredSuggestions, loading } = this.state
    const inputProps = {
      placeholder,
      value: value ? value : "",
      onChange,
      ref: this.inputRef,
      disabled,
    }
    return (
      <div className={`whisperer ${className} ${size}`}>
        <AutoSuggest
          suggestions={filteredSuggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          inputProps={inputProps}
          focusInputOnSuggestionClick={false}
          shouldRenderSuggestions={shouldRenderSuggestions}
          onSuggestionHighlighted={onSuggestionHighlighted}
        />
        {!value && loading && <LoadingIndicator className="suggest-loading" />}
      </div>
    )
  }
}

Whisperer.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  fetchOptions: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
}

export default Whisperer
