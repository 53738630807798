import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import PropTypes from "prop-types"
import { List, Map, Iterable } from "immutable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _toString from "lodash/toString"
import _toLower from "lodash/toLower"
import _get from "lodash/get"
import _noop from "lodash/noop"
import moment from "moment"

// ui components
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import SearchForm from "components/UI/components/SearchForm"
import Tag from "components/UI/elements/Tag"
import Button from "components/UI/elements/Button/Button"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import TagForm from "./TagForm"
import ConfirmModal from "components/UI/components/ConfirmModal"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import Table, {
  Thead,
  Th,
  Tbody,
  Td,
  Tr,
  SortButton,
  RowMessage,
} from "components/UI/elements/Table"

// actions
import { setSortingOptions, setFilterAndSorting } from "actions/table.action"
import { fetchTagsList, createTag, modifyTag, deleteTag } from "resources/tag/tagActions"
import { showToast } from "actions/toast.action"

// selectors
import { areTagsAlreadyFetched, getTagsList } from "resources/tag/tagSelectors"
import { getUsersData } from "resources/user/userSelectors"

// constants, helpers
import { MOMENT, MODAL } from "sharedConstants"
import { getRoutePath } from "routes"
import { hasAccess } from "helpers/authenticatedUser.helper"

import "./Tags.scss"
import Tippy from "@tippyjs/react"

class Tags extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tagForm: {
        show: false,
        tag: null,
        loading: false,
      },
      deleteModal: {
        open: false,
        tag: null,
      },
    }
  }

  componentDidMount() {
    this.props.fetchTagsList().catch(_noop)
  }

  setSortTagsBy = column => () => {
    const { searchFormValues, setSortingOptions } = this.props
    const orderBy = _get(searchFormValues, "orderBy")

    if (orderBy === column) {
      const orderDir = _get(searchFormValues, "orderDir")
      setSortingOptions("DataTagsSearch", column, orderDir === "ASC" ? "DESC" : "ASC")
    } else {
      setSortingOptions("DataTagsSearch", column, "ASC")
    }
  }

  searchTags = tagsList => {
    const { searchFormValues } = this.props
    const searchTerm = _get(searchFormValues, "search", "")
    return tagsList.filter(tag => _toLower(tag.name).includes(_toLower(searchTerm)))
  }

  sortTags = (tagsList, orderBy, orderDir) => {
    return tagsList.sort((tagA, tagB) => {
      const valA = orderBy === "name" ? _toLower(tagA[orderBy]) : tagA[orderBy]
      const valB = orderBy === "name" ? _toLower(tagB[orderBy]) : tagB[orderBy]
      if (valA < valB) {
        return orderDir === "DESC" ? 1 : -1
      }
      if (valA > valB) {
        return orderDir === "DESC" ? -1 : 1
      }
      return 0
    })
  }

  listSegmentsByTag = tag => () => {
    const { setFilterAndSorting, history } = this.props
    setFilterAndSorting("SegmentFilter", "last_export", "DESC", [tag.id])
    history.push(getRoutePath("segments"))
  }

  toggleTagForm =
    (tag = null) =>
    () => {
      this.setState(prevState => ({
        tagForm: {
          show: !prevState.tagForm.show,
          tag: tag ? tag.toJS() : null,
          loading: false,
        },
      }))
    }

  submitTagForm = values => {
    const { tagForm } = this.state
    const { createTag, modifyTag, showToast } = this.props
    if (!tagForm.loading) {
      this.setState(prevState => ({
        tagForm: {
          ...prevState.tagForm,
          laoding: true,
        },
      }))
      if (tagForm.tag) {
        // modify
        modifyTag(tagForm.tag.id, values)
          .then(() => {
            showToast("Tag edited.")
            this.toggleTagForm()()
          })
          .catch(() => {
            this.setState(prevState => ({
              tagForm: {
                ...prevState.tagForm,
                laoding: false,
              },
            }))
          })
      } else {
        // create
        createTag(values)
          .then(() => {
            showToast("Tag created.")
            this.toggleTagForm()()
          })
          .catch(() => {
            this.setState(prevState => ({
              tagForm: {
                ...prevState.tagForm,
                laoding: true,
              },
            }))
          })
      }
    }
  }

  toggleDeleteModal = tag => () => {
    const tagObject = Iterable.isIterable(tag) ? tag.toJS() : tag
    this.setState(prevState => ({
      deleteModal: {
        open: !prevState.deleteModal.open,
        tag: tagObject ? tagObject : prevState.deleteModal.tag,
      },
    }))
  }

  deleteTag = () => {
    const { deleteTag, showToast } = this.props
    const { deleteModal, tagForm } = this.state

    deleteTag(deleteModal.tag.id)
      .then(() => {
        this.toggleDeleteModal()()
        if (tagForm.show) {
          this.toggleTagForm()()
        }
        showToast("Tag deleted.")
      })
      .catch(this.toggleDeleteModal())
  }

  render() {
    const { tagForm, deleteModal } = this.state
    const { areTagsFetched, tagsList, users, searchFormValues } = this.props

    const orderBy = _get(searchFormValues, "orderBy", "name")
    const orderDir = _get(searchFormValues, "orderDir", "ASC")
    const isAuthUserAdmin = hasAccess.segments.editTags()

    let resultTagsList = this.searchTags(tagsList)
    resultTagsList = this.sortTags(resultTagsList, orderBy, orderDir)

    return (
      <React.Fragment>
        <section className="data-tags wrapper">
          <PaperHeader className="data-tags-header" size="small">
            <h3 className="title">
              Tags
              <Tippy content="Tags help to organize segments. Filter by tag in the Segments tab.">
                <span className="info-tooltip-icon" data-tip data-for="tags-title">
                  <FontAwesomeIcon icon={["fas", "info-circle"]} />
                </span>
              </Tippy>
            </h3>
            {!tagForm.show && (
              <SearchForm
                placeholder="Search for tags"
                className="tags-search"
                initialValues={{ search: "" }}
                form="DataTagsSearch"
              />
            )}
            {!tagForm.show && (
              <Button
                size="small"
                color="primary"
                disabled={!isAuthUserAdmin}
                onClick={this.toggleTagForm()}
              >
                + Create tag
              </Button>
            )}
            {tagForm.show && (
              <div className="buttons">
                {tagForm.tag && (
                  <Button
                    size="small"
                    color="white-red"
                    onClick={this.toggleDeleteModal(tagForm.tag)}
                  >
                    <FontAwesomeIcon icon={["fas", "trash-alt"]} className="icon" /> Delete
                  </Button>
                )}

                <Button size="small" color="white" onClick={this.toggleTagForm()}>
                  Cancel
                </Button>
                <RemoteSubmitButton isLoading={tagForm.loading} formName="TagForm" />
              </div>
            )}
          </PaperHeader>
          {areTagsFetched && !tagForm.show && (
            <Paper hasHeader={true} noPaddingTop>
              {tagsList.size === 0 && (
                <RowMessage className="tags-info-message">
                  {isAuthUserAdmin && (
                    <React.Fragment>Click on the "Create Tag" to get started.</React.Fragment>
                  )}
                  {!isAuthUserAdmin && (
                    <React.Fragment>No tags created. Contact administrator.</React.Fragment>
                  )}
                </RowMessage>
              )}
              {tagsList.size > 0 && resultTagsList.size === 0 && (
                <RowMessage className="tags-info-message">No tags found.</RowMessage>
              )}
              {tagsList.size > 0 && resultTagsList.size > 0 && (
                <Table className="data-tags-table">
                  <Thead stickyHeader>
                    <Th>
                      <SortButton
                        column="name"
                        orderBy={orderBy}
                        orderDir={orderDir}
                        onClick={this.setSortTagsBy("name")}
                        label="Name"
                      />
                    </Th>
                    <Th className="segments-count">
                      <SortButton
                        column="segments_count"
                        orderBy={orderBy}
                        orderDir={orderDir}
                        onClick={this.setSortTagsBy("segments_count")}
                        label="In segments"
                      />
                    </Th>
                    <Th className="author">Author</Th>
                    <Th className="created" textAlignRight>
                      <SortButton
                        column="created"
                        orderBy={orderBy}
                        orderDir={orderDir}
                        onClick={this.setSortTagsBy("created")}
                        label="Created"
                      />
                    </Th>
                    <Th className="cta-header">&nbsp;</Th>
                  </Thead>
                  <Tbody>
                    {resultTagsList.size > 0 &&
                      resultTagsList.map(tag => (
                        <Tr key={tag.id}>
                          <Td className="tag-cell">
                            <Tag
                              color={tag.color ? tag.color : "primary"}
                              onClick={this.listSegmentsByTag(tag)}
                            >
                              {tag.name}
                            </Tag>
                          </Td>
                          <Td>{tag.segments_count}</Td>
                          <Td>{users.getIn([_toString(tag.user_id), "name"])}</Td>
                          <Td textAlignRight>
                            {moment.utc(tag.created).local().format(MOMENT.DATE_FORMAT)}
                          </Td>
                          <Td className="cta">
                            <span onClick={this.listSegmentsByTag(tag)}>Show segments</span>
                            <IconButton
                              color={COLOR.BLACK}
                              size={SIZE.TAG}
                              withBackground
                              className="edit-button"
                              onClick={this.toggleTagForm(tag)}
                              iconName="pencil-alt"
                              tooltip="Edit"
                            />
                            <IconButton
                              color={COLOR.RED}
                              size={SIZE.TAG}
                              withBackground
                              onClick={this.toggleDeleteModal(tag)}
                              disabled={!isAuthUserAdmin}
                              iconName="trash-alt"
                              tooltip="Delete"
                            />
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              )}
            </Paper>
          )}
          {tagForm.show && (
            <Paper hasHeader={true} className="tag-form-wrapper">
              <TagForm
                onSubmit={this.submitTagForm}
                initialValues={tagForm.tag ? tagForm.tag : {}}
                type={tagForm.tag ? "EDIT" : "CREATE"}
              />
            </Paper>
          )}
          <ConfirmModal
            open={deleteModal.open}
            type={MODAL.TYPE.DELETE}
            handleClose={this.toggleDeleteModal()}
            handleConfirm={this.deleteTag}
            title="Delete tag"
            action="delete"
            what="tag"
            item={_get(deleteModal, "tag.name", "")}
          />
        </section>
      </React.Fragment>
    )
  }
}

Tags.propTypes = {
  tagsList: PropTypes.instanceOf(List).isRequired,
  areTagsFetched: PropTypes.bool.isRequired,
  users: PropTypes.instanceOf(Map).isRequired,
  searchFormValues: PropTypes.object,
  setSortingOptions: PropTypes.func.isRequired,
  setFilterAndSorting: PropTypes.func.isRequired,
  createTag: PropTypes.func.isRequired,
  modifyTag: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  tagsList: getTagsList(state),
  areTagsFetched: areTagsAlreadyFetched(state),
  users: getUsersData(state),
  searchFormValues: getFormValues("DataTagsSearch")(state),
})

export default connect(mapStateToProps, {
  setSortingOptions,
  setFilterAndSorting,
  fetchTagsList,
  createTag,
  modifyTag,
  showToast,
  deleteTag,
})(Tags)
