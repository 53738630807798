import React, { useState } from "react"
import styles from "./SegmentSummary.module.scss"
import { AndOrCondition, ConditionTree } from "types/conditionTree"
import { isAndOrCondition } from "components/ConditionBuilder/utils"
import { getCompoundAttributeSubAttributes } from "resources/attribute/compoundAttributeUtils"
import { AttributeFull } from "resources/attribute/attributeTypes"
import classNames from "classnames"
import {
  isCompoundSegmentCondition,
  isDimensionBranch,
} from "resources/segment/segment/utilities/segmentConditionTypeChecks"
import {
  DimensionConditionObject,
  DimensionBranch,
  CompoundSegmentCondition,
  SimpleSegmentCondition,
  SegmentCondition,
} from "resources/segment/segment/segmentConditionsTypes"
import {
  OPERATION_LABEL_NL_MAPPER,
  OPERATION_HAS_VALUE,
} from "resources/segment/segment/utilities/segmentOperationsConstants"
import { useFetchAttributeById } from "resources/attribute/attributeQueries"

const joinNodes = (nodes: (JSX.Element | string)[], joiner: JSX.Element | string = "") =>
  nodes.reduce((acc, curr) => (
    <>
      {acc}
      {joiner}
      {curr}
    </>
  )) as JSX.Element

type DimensionLeafSummaryProps = {
  dimensionLeaf: DimensionConditionObject
  attribute: AttributeFull
}

function DimensionLeafSummary({
  dimensionLeaf: { condition },
  attribute,
}: DimensionLeafSummaryProps) {
  const dimensions = getCompoundAttributeSubAttributes(attribute.data_type)
  const subAttribute = dimensions.find(dim => dim.id === condition.sub_attribute_id)!

  return (
    <span>
      <strong>
        {attribute.name}: {subAttribute.name}
      </strong>{" "}
      {OPERATION_LABEL_NL_MAPPER[condition.operation!]}
      {condition.value !== undefined && (
        <>
          {" "}
          {Array.isArray(condition.value) ? (
            joinNodes(
              condition.value.map(val => <strong>{val}</strong>),
              <span> {OPERATION_HAS_VALUE[condition.operation!] === -1 ? "or" : "and"} </span>,
            )
          ) : (
            <strong>{condition.value}</strong>
          )}
        </>
      )}
    </span>
  )
}

type DimensionBranchSummaryProps = {
  dimensionBranch: DimensionBranch
  attribute: AttributeFull
}

function DimensionBranchSummary({ dimensionBranch, attribute }: DimensionBranchSummaryProps) {
  return (
    <>
      {joinNodes(
        dimensionBranch.operands.map(operand =>
          isDimensionBranch(operand) ? (
            <DimensionBranchSummary attribute={attribute} dimensionBranch={operand} />
          ) : (
            <DimensionLeafSummary attribute={attribute} dimensionLeaf={operand} />
          ),
        ),
        <span> {dimensionBranch.operation} </span>,
      )}
    </>
  )
}

type CompoundConditionSummaryProps = {
  condition: CompoundSegmentCondition
  onHover: (isHovering: boolean) => void
  attribute: AttributeFull
}

function CompoundConditionSummary({
  condition,
  onHover,
  attribute,
}: CompoundConditionSummaryProps) {
  return (
    <span
      className={styles.condition}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      {condition.negation && <span>it is not true that </span>}
      {isDimensionBranch(condition) ? (
        <DimensionBranchSummary dimensionBranch={condition} attribute={attribute} />
      ) : (
        <DimensionLeafSummary dimensionLeaf={condition} attribute={attribute} />
      )}
    </span>
  )
}

type SimpleConditionSummaryProps = {
  condition: SimpleSegmentCondition
  onHover: (isHovering: boolean) => void
  attribute: AttributeFull
}

function SimpleConditionSummary({
  condition: { condition, negation },
  onHover,
  attribute,
}: SimpleConditionSummaryProps) {
  return (
    <span
      className={styles.condition}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      {negation && <span>it is not true that </span>}
      <strong>{attribute.name}</strong> {OPERATION_LABEL_NL_MAPPER[condition.operation!]}
      {condition.value !== undefined && (
        <>
          {" "}
          {Array.isArray(condition.value) ? (
            joinNodes(
              condition.value.map(val => <strong>{val}</strong>),
              <span> {OPERATION_HAS_VALUE[condition.operation!] === -1 ? "or" : "and"} </span>,
            )
          ) : (
            <strong>{condition.value}</strong>
          )}
        </>
      )}
    </span>
  )
}

type ConditionSummaryProps = {
  condition: SegmentCondition
  onHover: (isHovering: boolean) => void
}

function ConditionSummary({ condition, onHover }: ConditionSummaryProps) {
  const { data: attribute } = useFetchAttributeById(condition.attribute_id)

  if (!attribute) {
    return null
  }

  return isCompoundSegmentCondition(condition) ? (
    <CompoundConditionSummary condition={condition} onHover={onHover} attribute={attribute} />
  ) : (
    <SimpleConditionSummary condition={condition} onHover={onHover} attribute={attribute} />
  )
}

type BranchSummaryProps = {
  conditionTree: AndOrCondition<SegmentCondition>
  onHover: (isHovering: boolean) => void
}

function BranchSummary({ conditionTree, onHover }: BranchSummaryProps) {
  return joinNodes(
    conditionTree.operands.map(condition =>
      isAndOrCondition(condition) ? (
        <BranchSummary conditionTree={condition} onHover={onHover} />
      ) : (
        <ConditionSummary condition={condition} onHover={onHover} />
      ),
    ),
    <span>, {conditionTree.operator.toUpperCase()} </span>,
  )
}

type SegmentSummaryProps = {
  totalCustomers: number | null
  segmentedCustomers: number | null
  hasUnsavedConditions?: boolean
  conditionTree: ConditionTree<SegmentCondition> | null
  isOpen: boolean
}

export default function SegmentSummary({
  totalCustomers,
  segmentedCustomers,
  hasUnsavedConditions,
  conditionTree,
  isOpen,
}: SegmentSummaryProps) {
  const [isHovering, setIsHovering] = useState(false)

  if (!isOpen) {
    return null
  }

  if (totalCustomers === null || segmentedCustomers === null) {
    return (
      <div className={styles.container}>
        <div className={styles.message}>Loading segmentation counts…</div>
      </div>
    )
  }

  if (hasUnsavedConditions) {
    return (
      <div className={styles.container}>
        <div className={styles.message}>Save segment conditions to see the summary.</div>
      </div>
    )
  }

  if (conditionTree === null) {
    return (
      <div className={styles.container}>
        <div className={styles.message}>There are no conditions yet.</div>
      </div>
    )
  }

  return (
    <div className={classNames(styles.container, { [styles.isHovering]: isHovering })}>
      <span>From your customer base of {totalCustomers.toLocaleString()} I have selected </span>
      <strong>{segmentedCustomers.toLocaleString()}</strong>
      <span> where </span>
      {isAndOrCondition(conditionTree) ? (
        <BranchSummary conditionTree={conditionTree} onHover={setIsHovering} />
      ) : (
        <ConditionSummary condition={conditionTree} onHover={setIsHovering} />
      )}
      <span>.</span>
    </div>
  )
}
