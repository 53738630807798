import React, { Component } from "react"
import { reduxForm, Form, Field, FormSection, FieldArray, isDirty } from "redux-form"
import TextField from "components/UI/elements/TextField"
import SelectField from "components/UI/elements/SelectField"
import { required, pythonVariable } from "helpers/validators.helper"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { getDataSourcesArrayForSelect } from "resources/dataSource/dataSourceSelectors"
import { getEventTypesForSelect } from "selectors/event.selector"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import Button from "components/UI/elements/Button/Button"
import ConfirmModal from "components/UI/components/ConfirmModal"
import _isEmpty from "lodash/isEmpty"

import "./EventForm.scss"

export const VALUE_TYPE = [
  { value: "number", label: "number" },
  { value: "datetime", label: "datetime" },
  { value: "string", label: "string" },
]

export const VALUE_FORMAT = [
  { value: "p", label: "text" },
  { value: "ahref", label: "link" },
  { value: "ul", label: "list" },
]

class EventForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventRetentionModal: {
        isOpen: false,
        formValues: null,
      },
    }
  }

  closeEventRetentionModal = () => {
    this.setState(prevState => ({
      eventRetentionModal: {
        ...prevState.eventRetentionModal,
        isOpen: false,
      },
    }))
  }

  confirmEventRetentionModal = () => {
    this.closeEventRetentionModal()
    this.submitForm(this.state.eventRetentionModal.formValues)
  }

  getEventTypes = () => {
    const { events } = this.props
    let result = []
    if (events.size > 0) {
      events.forEach(event => {
        if (result.find(type => type.value === event.type) === undefined) {
          result.push({ label: event.type, value: event.type })
        }
      })
    }
    return result
  }

  renderSchemas = ({ fields }) => {
    return (
      <div className="schema-right-content">
        {fields.map((schema, index) => (
          <div
            key={index}
            className={`event-form-schema-item-wrapper ${
              index === fields.length - 1 ? "last" : ""
            }`}
          >
            <div className="event-form-schema-box" key={index}>
              <div className="row">
                <Field
                  name={`${schema}.name`}
                  component={TextField}
                  placeholder="Name"
                  label="Name"
                  validate={required}
                  maxLength={60}
                  className="event-schema-name"
                />
                <Field
                  name={`${schema}.type`}
                  component={SelectField}
                  placeholder="Type"
                  label="Type"
                  className="event-schema-type"
                  options={VALUE_TYPE}
                />
                <Field
                  name={`${schema}.format`}
                  component={SelectField}
                  placeholder="Format"
                  label="Format"
                  className="event-schema-format"
                  options={VALUE_FORMAT}
                />
              </div>
              <div className="row">
                <Field
                  name={`${schema}.path`}
                  component={TextField}
                  placeholder=".data.path"
                  label="Path"
                  className="event-schema-path"
                  validate={required}
                />
              </div>
            </div>
            <IconButton
              size={SIZE.TAG}
              withBackground
              color={COLOR.RED}
              onClick={() => fields.remove(index)}
              iconName="trash-alt"
              tooltip="Delete"
            />
          </div>
        ))}
        <Button
          size="medium"
          color="primary"
          onClick={() => fields.push({})}
          className={fields.length === 0 ? "add-schema-button mt" : "add-schema-button"}
        >
          + Add schema
        </Button>
      </div>
    )
  }

  askForEventRetentionConfirmation = formValues => {
    if (this.props.isEventRetentionDirty && formValues.ttl !== "") {
      this.setState({
        eventRetentionModal: {
          isOpen: true,
          formValues,
        },
      })
      return
    }

    this.submitForm(formValues)
  }

  submitForm = formValues => {
    const { disabledFields = [] } = this.props

    const data = {
      name: formValues.name,
      ttl: formValues.ttl === "" ? 0 : parseInt(formValues.ttl) * 86400,
      schema: {
        title: formValues.schema.title,
        display:
          formValues.schema.display && !_isEmpty(formValues.schema.display)
            ? formValues.schema.display.map(schema => ({
                name: schema.name,
                type: schema.type?.value ?? "string",
                format: schema.format?.value ?? "p",
                path: schema.path,
              }))
            : [],
      },
    }

    if (!disabledFields.includes("source_id")) {
      data.source_id = formValues.source_id.value
    }
    if (!disabledFields.includes("type")) {
      data.type = formValues.type.value
    }
    if (!disabledFields.includes("version")) {
      data.version = formValues.version
    }

    this.props.onSubmit(data)
  }

  render() {
    const { handleSubmit, disabledFields = [], sourcesArray, eventTypes } = this.props
    const { eventRetentionModal } = this.state

    return (
      <section className="event-form">
        <Form onSubmit={handleSubmit(this.askForEventRetentionConfirmation)}>
          <div className="form-row white">
            <div className="left-part">
              <h2>General</h2>
            </div>
            <div className="right-part">
              <Field
                component={TextField}
                placeholder="Event name"
                name="name"
                label="Event name"
                validate={required}
                maxLength={60}
                className="event-name"
              />
              <Field
                component={SelectField}
                placeholder="Source"
                name="source_id"
                label="Source"
                validate={required}
                className="source-id"
                disabled={disabledFields.includes("source_id")}
                options={sourcesArray}
              />
              <Field
                component={SelectField}
                placeholder="Event type"
                name="type"
                label="Event type"
                validate={[required, pythonVariable]}
                className="event-type"
                disabled={disabledFields.includes("type")}
                options={eventTypes}
                isCreatable
              />
              <Field
                component={TextField}
                placeholder="1-0-0"
                name="version"
                label="Event version"
                validate={required}
                className="event-version"
                disabled={disabledFields.includes("version")}
              />
            </div>
          </div>
          <div className="form-row grey">
            <div className="left-part">
              <h2 className="event-retention-title">Event retention</h2>
            </div>
            <div className="right-part">
              <div className="event-retention-field-wrapper">
                <span>Delete events after</span>
                <Field
                  component={TextField}
                  name="ttl"
                  type="number"
                  min={1}
                  max={5000}
                  className="event-retention-field"
                  validate={value =>
                    value && parseInt(value) <= 0
                      ? "Must be > 0"
                      : value && parseInt(value) > 5000
                      ? "Must be <= 5000"
                      : undefined
                  }
                />
                <span>day(s)</span>
              </div>
            </div>
          </div>
          <div className="form-row last">
            <div className="left-part">
              <h2>Schema</h2>
              <p className="docs">
                To learn more:{" "}
                <a
                  href="https://docs.meiro.io/books/meiro-business-explorer/page/set-events"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  User documentation
                </a>
              </p>
            </div>
            <div className="right-part">
              <FormSection name="schema" className="schema">
                <Field
                  component={TextField}
                  placeholder="Title"
                  name="title"
                  label="Title"
                  className="event-schema-title"
                />
                <FieldArray name="display" component={this.renderSchemas} />
              </FormSection>
            </div>
          </div>
        </Form>
        <ConfirmModal
          open={eventRetentionModal.isOpen}
          handleClose={this.closeEventRetentionModal}
          handleConfirm={this.confirmEventRetentionModal}
          title="Setting up event retention"
          text={`You are about to set event retention to ${eventRetentionModal.formValues?.ttl} days. If you update existing events, this may influence already stored events.`}
          type="success"
        />
      </section>
    )
  }
}

EventForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  disabledFields: PropTypes.array,
  sourcesArray: PropTypes.array.isRequired,
  eventTypes: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  sourcesArray: getDataSourcesArrayForSelect(state, true),
  eventTypes: getEventTypesForSelect(state, true),
  isEventRetentionDirty: isDirty("EventForm")(state, ["ttl"]),
})

EventForm = connect(mapStateToProps)(EventForm)

EventForm = reduxForm({
  form: "EventForm",
  touchOnBlur: false,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(EventForm)

export default EventForm
