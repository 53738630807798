import {
  PopupWebBannerCreatePayload,
  PWBContentType,
  WBMinimizedHideCondition,
} from "resources/webBanner/popupWebBanner/popupWBTypes"
import { PopupWebBannersFormData } from "../popupWBComponentTypes"

export function formDataToCreatePayload(
  formData: PopupWebBannersFormData,
): PopupWebBannerCreatePayload {
  const {
    name,
    condition,
    frequency_cap,
    priority,
    position,
    image_local,
    image_name,
    image_remote,
    html,
    destination_url,
    width,
    minimized_width,
    close_button,
    minimized_close_button,
    disabled,
    minimized,
    minimized_image_local,
    minimized_image_name,
    minimized_image_remote,
    minimized_html,
    minimized_position,
    minimized_hide_values,
    minimized_hide_operator,
    display_minimized_first,
    margin_x,
    margin_y,
    minimized_margin_x,
    minimized_margin_y,
    animation,
    minimized_animation,
    display_on,
  } = formData

  const hide_condition: WBMinimizedHideCondition | undefined = minimized_hide_operator
    ? {
        operator: minimized_hide_operator.value,
        subject: "url",
        values: minimized_hide_values,
      }
    : undefined
  const fe_settings = {
    width,
    minimized_width,
    closeButton: close_button,
    minimized_close_button,
    margins: {
      x: margin_x,
      y: margin_y,
      minimized_x: minimized_margin_x,
      minimized_y: minimized_margin_y,
    },
    animation,
    minimized_animation,
    display_on,
  }
  const minimizedContentObj = minimized_image_local
    ? {
        data: minimized_image_local,
        image_name: minimized_image_name!,
        type: "local_image" as PWBContentType,
        position: minimized_position,
        display_minimized_first,
        hide_condition,
      }
    : minimized_image_remote
    ? {
        image_url: minimized_image_remote,
        type: "remote_image" as PWBContentType,
        position: minimized_position,
        display_minimized_first,
        hide_condition,
      }
    : minimized_html
    ? {
        html: minimized_html,
        type: "html" as PWBContentType,
        position: minimized_position,
        display_minimized_first,
        hide_condition,
      }
    : {}

  return {
    name,
    settings: {
      condition,
      frequency_cap,
      priority,
      position,
      content: image_local
        ? {
            destination_url: destination_url === "" ? null : destination_url,
            data: image_local,
            image_name: image_name!,
            type: "local_image",
          }
        : image_remote
        ? {
            destination_url: destination_url === "" ? null : destination_url,
            image_url: image_remote,
            type: "remote_image",
          }
        : { html: html!, type: "html" },
      minimized_content: {
        enabled: minimized,
        ...minimizedContentObj,
      },
      fe_settings,
    },
    disabled,
  }
}
