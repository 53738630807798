import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Form, Field, reduxForm, FormSection, getFormValues, FieldArray } from "redux-form"
import _get from "lodash/get"
import _set from "lodash/set"
import _trim from "lodash/trim"
import { Prompt } from "react-router-dom"
import moment from "moment"

import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import IconButton, { COLOR } from "components/UI/elements/IconButton"
import TextField from "components/UI/elements/TextField"
import SelectField from "components/UI/elements/SelectField"
import ConfirmModal from "components/UI/components/ConfirmModal"
import Duration from "components/UI/elements/Duration"
import AttributePicker from "components/UI/components/AttributePicker/AttributePicker"

// actions
import { showToast } from "actions/toast.action"

// constants, helpers
import { DATABASE_DRIVERS, BIGQUERY_LOCATIONS, MODAL, TOAST, MOMENT } from "sharedConstants"
import { isEmailValid } from "helpers/validators.helper"
import { api } from "api"
import PendingPromise from "helpers/pendingPromise.helper"

import "./Settings.scss"

const REQUIRED_ERROR_MESSAGE = "Please fill the field"

class SettingsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openDeleteModal: false,
      cacheDeleting: false,
      cacheRenewInProgress: false,
      cacheRefreshInitTime: "",
    }
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    this.checkCacheRenewInProgress()
  }

  checkCacheRenewInProgress = () => {
    const cacheStatusRequest = this.pendingPromises.create(api.cacheStatus())
    cacheStatusRequest.promise
      .then(response => {
        if (response.is_refresh_running) {
          if (!this.state.cacheRenewInProgress) {
            this.setState({
              cacheRenewInProgress: true,
              cacheRefreshInitTime: response.refresh_init_time,
            })
          }
          if (!this.refreshInterval) {
            this.refreshInterval = setInterval(this.checkCacheRenewInProgress, 5000)
          }
        } else {
          if (this.state.cacheRenewInProgress) {
            this.setState({
              cacheRenewInProgress: false,
            })
          }
          if (this.refreshInterval) {
            clearInterval(this.refreshInterval)
            this.refreshInterval = null
          }
        }
        this.pendingPromises.remove(cacheStatusRequest)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState({
            cacheRenewInProgress: false,
          })
        }
        if (this.refreshInterval) {
          clearInterval(this.refreshInterval)
          this.refreshInterval = null
        }
        this.pendingPromises.remove(cacheStatusRequest)
      })
  }

  componentWillUnmount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
      this.refreshInterval = null
    }
    this.pendingPromises.cancelAll()
  }

  onSubmit = async values => {
    this.setState({
      loading: true,
    })
    try {
      await this.props.onSubmit(values)
    } catch (err) {
      this.setState({ loading: false })
    }
    this.setState({ loading: false })
  }

  cancelFormEditing = () => {
    this.props.reset()
  }

  deleteCache = () => {
    const { handleCacheRenew, showToast } = this.props
    this.setState({
      cacheDeleting: true,
    })
    api.cache
      .delete()
      .then(() => {
        this.setState({
          cacheRenewInProgress: true,
          cacheRefreshInitTime: moment.utc().format(MOMENT.DB_DATETIME_FORMAT),
        })
        this.refreshInterval = setInterval(this.checkCacheRenewInProgress, 5000)

        handleCacheRenew()
        showToast("Cache is being refreshed.", TOAST.TYPE.SUCCESS)
        this.closeDeleteCacheModal()
      })
      .catch(this.closeDeleteCacheModal)
  }

  openDeleteCacheModal = () => {
    if (!this.state.cacheRenewInProgress) {
      this.setState({
        openDeleteModal: true,
      })
    }
  }

  closeDeleteCacheModal = () => {
    this.setState({
      openDeleteModal: false,
      cacheDeleting: false,
    })
  }

  selectAttribute = attributeId => {
    this.props.change("additional_search_result_attribute_id.value", attributeId ? attributeId : "")
  }

  renderNotificationEmails = ({ fields }) => {
    return (
      <React.Fragment>
        <div className="email-fields">
          {fields.map((value, index) => (
            <div className="notification-email-wrapper" key={value}>
              <Field
                name={value}
                component={TextField}
                label="Email"
                placeholder="Email to notify"
                type="text"
              />
              <IconButton
                className={`email-trash ${fields.length > 1 ? "" : "hidden"}`}
                color={COLOR.RED}
                onClick={() => {
                  if (fields.length > 1) {
                    fields.remove(index)
                  }
                }}
                iconName="trash-alt"
                tooltip="Delete"
              />
            </div>
          ))}
        </div>
        <Button
          color="primary"
          size="medium"
          onClick={() => fields.push()}
          className="add-email-button"
        >
          Add email
        </Button>
      </React.Fragment>
    )
  }

  render() {
    const { tagListForSelect, formValues, dirty } = this.props
    const { openDeleteModal, cacheDeleting, cacheRenewInProgress, cacheRefreshInitTime } =
      this.state
    const driver = _get(formValues, "cdp_db.value.driver.value")
    const additionalSearchResultAttributeId = _get(
      formValues,
      "additional_search_result_attribute_id.value",
    )

    return (
      <React.Fragment>
        <Form className="wrapper global-settings" onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <PaperHeader className="global-settings-header" size="small" titleText="Global settings">
            <div>
              <Button
                color="white"
                size="small"
                className="cancel-button"
                type="button"
                onClick={this.cancelFormEditing}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="small"
                type="submit"
                className={this.state.loading ? "loading" : ""}
              >
                <FontAwesomeIcon className="save-icon icon" icon={["fas", "save"]} /> Save
              </Button>
            </div>
          </PaperHeader>
          <Paper className="global-settings-content">
            <table className="global-settings-table">
              <tbody>
                <tr>
                  <td className="row-title">
                    <h3>Database connection</h3>
                    <p className="title-description">Contains prepared customer data.</p>
                  </td>
                  <td className="values">
                    <FormSection name="cdp_db">
                      <FormSection name="value" className="cdp-db-values">
                        {driver === "bigquery" && (
                          <React.Fragment>
                            <div className="form-row">
                              <Field
                                name="project"
                                component={TextField}
                                label="Project"
                                placeholder="Project"
                                className="floated"
                              />
                              <Field
                                name="driver"
                                component={SelectField}
                                options={DATABASE_DRIVERS}
                                label="Driver"
                                className="driver bigquery"
                                isSearchable={false}
                                size="medium"
                                inputId="database-driver"
                              />
                            </div>
                            <div className="form-row">
                              <Field
                                name="dataset"
                                component={TextField}
                                label="Dataset"
                                placeholder="Dataset"
                                className="floated"
                              />
                              <Field
                                name="exports_dataset"
                                component={TextField}
                                label="Exports&nbsp;dataset"
                                placeholder="Segment exports dataset"
                                className="floated margin-left exports-dataset"
                              />
                            </div>
                            <div className="form-row">
                              <Field
                                name="location"
                                component={SelectField}
                                options={BIGQUERY_LOCATIONS}
                                label="Location"
                                className="bq-location floated"
                                isSearchable={false}
                                size="medium"
                                inputId="bigquery-location"
                              />
                              <Field
                                name="demo_dataset"
                                component={TextField}
                                label="Demo&nbsp;dataset"
                                placeholder="Optional"
                                className="floated margin-left demo-dataset"
                              />
                            </div>
                            <div className="form-row">
                              <Field
                                name="#credentials"
                                component={TextField}
                                label="Credentials"
                                placeholder="Credentials settings object"
                                className="floated bigquery-credentials"
                                multiline={true}
                                fullWidth={true}
                                rows="5"
                              />
                            </div>
                          </React.Fragment>
                        )}
                        {(driver === "postgre" || driver === "redshift") && (
                          <React.Fragment>
                            <div className="form-row">
                              <Field
                                name="host"
                                component={TextField}
                                label="Host"
                                placeholder="Host URL"
                                className="floated"
                              />
                              <Field
                                name="port"
                                component={TextField}
                                label="Port"
                                placeholder="Port"
                                className="port"
                                type="number"
                              />
                              <Field
                                name="driver"
                                component={SelectField}
                                options={DATABASE_DRIVERS}
                                label="Driver"
                                className="driver"
                                isSearchable={false}
                                size="medium"
                                inputId="database-driver"
                              />
                            </div>
                            <div className="form-row">
                              <Field
                                name="db_name"
                                component={TextField}
                                label="DB&nbsp;name"
                                placeholder="Database name"
                                className="floated db-name"
                              />
                              <Field
                                name="exports_db_name"
                                component={TextField}
                                label="Exports&nbsp;Schema"
                                placeholder="Segment exports storage database"
                                className="floated margin-left exports-db-name"
                              />
                            </div>
                            <div className="form-row">
                              <Field
                                name="schema"
                                component={TextField}
                                label="Schema"
                                placeholder="DB schema"
                                className="floated db-name"
                              />
                              <Field
                                name="demo_db_name"
                                component={TextField}
                                label="Demo&nbsp;Schema"
                                placeholder="Optional"
                                className="floated marin-left demo-db-name"
                              />
                            </div>
                            <div className="form-row">
                              <Field
                                name="user"
                                component={TextField}
                                label="Username"
                                placeholder="Username"
                                className="floated"
                              />
                              <Field
                                name="#password"
                                component={TextField}
                                label="Password"
                                placeholder="Password"
                                className="floated margin-left"
                                type="password"
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </FormSection>
                    </FormSection>
                  </td>
                </tr>
                <tr>
                  <td className="row-title">
                    <h3>Customer Entities DB</h3>
                    <p className="title-description">Customer Entities database</p>
                  </td>
                  <td className="values">
                    <FormSection name="customer_entities_db">
                      <FormSection name="value">
                        <React.Fragment>
                          <div className="form-row">
                            <Field
                              name="host"
                              component={TextField}
                              label="Host"
                              placeholder="Host"
                              className="floated"
                            />
                            <Field
                              name="port"
                              component={TextField}
                              type="number"
                              label="Port"
                              placeholder="Port"
                              className="floated margin-left"
                            />
                          </div>
                          <div className="form-row">
                            <Field
                              name="user"
                              component={TextField}
                              label="Username"
                              placeholder="Username"
                              className="floated"
                            />
                            <Field
                              name="#password"
                              component={TextField}
                              label="Password"
                              placeholder="Password"
                              className="floated margin-left"
                              type="password"
                            />
                          </div>
                        </React.Fragment>
                      </FormSection>
                    </FormSection>
                  </td>
                </tr>
                <tr>
                  <td className="row-title">
                    <h3>MI API connection</h3>
                    <p className="title-description">
                      Used when configuring export destinations. If your Meiro Integrations instance
                      is accessible on <span>https://meiro.acme.com</span>, the correct URL would be
                      <span> https://meiro.acme.com/api</span>. It is best to use credentials for an
                      account created just for this purpose.
                    </p>
                  </td>
                  <td className="values">
                    <FormSection name="mi_api">
                      <FormSection name="value" className="meiro-api-values">
                        <React.Fragment>
                          <div className="form-row">
                            <Field
                              name="url"
                              component={TextField}
                              label="URL"
                              placeholder="URL"
                              className="floated meiro-api-url"
                            />
                          </div>
                          <div className="form-row">
                            <Field
                              name="email"
                              component={TextField}
                              label="Email"
                              placeholder="Email"
                              className="floated"
                            />
                            <Field
                              name="#password"
                              component={TextField}
                              label="Password"
                              placeholder="Password"
                              className="floated margin-left"
                              type="password"
                            />
                          </div>
                        </React.Fragment>
                      </FormSection>
                    </FormSection>
                  </td>
                </tr>
                <tr>
                  <td className="row-title">
                    <h3>ME API connection</h3>
                    <p className="title-description">
                      Used when configuring web banners. If your Meiro Events instance is accessible
                      on <span>https://meiro.acme.com</span>, the correct URL would be
                      <span> https://meiro.acme.com/api</span>. It is best to use credentials for an
                      account created just for this purpose.
                    </p>
                  </td>
                  <td className="values">
                    <FormSection name="me_api">
                      <FormSection name="value" className="meiro-api-values">
                        <React.Fragment>
                          <div className="form-row">
                            <Field
                              name="url"
                              component={TextField}
                              label="URL"
                              placeholder="URL"
                              className="floated meiro-api-url"
                            />
                          </div>
                          <div className="form-row">
                            <Field
                              name="email"
                              component={TextField}
                              label="Email"
                              placeholder="Email"
                              className="floated"
                            />
                            <Field
                              name="#password"
                              component={TextField}
                              label="Password"
                              placeholder="Password"
                              className="floated margin-left"
                              type="password"
                            />
                          </div>
                        </React.Fragment>
                      </FormSection>
                    </FormSection>
                  </td>
                </tr>
                <tr>
                  <td className="row-title">
                    <h3>Customers tab additional attribute</h3>
                    <p className="title-description">
                      To show additional attribute for customers search results.
                    </p>
                  </td>
                  <td className="values">
                    <div className="form-row additional-attribute">
                      <FormSection name="additional_search_result_attribute_id">
                        <div className="attribute-picker picker-field floated single">
                          <label>Attribute</label>
                          <AttributePicker
                            attributeId={additionalSearchResultAttributeId}
                            isEditable={true}
                            handleAttributeSelect={this.selectAttribute}
                            isClearable
                          />
                        </div>
                      </FormSection>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="row-title">
                    <h3>Customer Highlights label</h3>
                    <p className="title-description">
                      To identify the attributes for customer highlights.
                    </p>
                  </td>
                  <td className="values">
                    <div className="form-row contact-identifiers">
                      <FormSection name="contact_info_tag_id">
                        <Field
                          name="value"
                          component={SelectField}
                          options={tagListForSelect}
                          label="Label"
                          className="floated single"
                          isSearchable={true}
                          isClearable={true}
                          size="medium"
                          inputId="contact-info-tag-value"
                        />
                      </FormSection>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="row-title">
                    <h3>Channel Engagement label</h3>
                    <p className="title-description">
                      To identify the attributes for channel engagement.
                    </p>
                  </td>
                  <td className="values">
                    <div className="form-row channel-engagement-value">
                      <FormSection name="channel_engagement_tag_id">
                        <Field
                          name="value"
                          component={SelectField}
                          options={tagListForSelect}
                          label="Label"
                          className="floated single"
                          isSearchable={true}
                          isClearable={true}
                          size="medium"
                          inputId="channel-engagement-tag-value"
                        />
                      </FormSection>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="row-title">
                    <h3>Data cache expiration</h3>
                    <p className="title-description">
                      This schedule follows{" "}
                      <a href="https://crontab.guru/" target="_blank" rel="noopener noreferrer">
                        cron syntax
                      </a>{" "}
                      and sets the time (UTC) when the cache should be invalidated and generated.
                      Set it right after the process of ingesting new data is done.
                    </p>
                  </td>
                  <td className="values">
                    <div className="form-row cache-settings">
                      <label className="cache-settings-label">Settings</label>
                      <FormSection name="cdp_cache_expiration_period.value">
                        <Field
                          name="minute"
                          component={TextField}
                          label="Minute"
                          className="expiration-schedule-field"
                          type="string"
                        />
                        <Field
                          name="hour"
                          component={TextField}
                          label="Hour"
                          className="expiration-schedule-field"
                          type="string"
                        />
                        <Field
                          name="day"
                          component={TextField}
                          label="Day"
                          className="expiration-schedule-field"
                          type="string"
                        />
                        <Field
                          name="month"
                          component={TextField}
                          label="Month"
                          className="expiration-schedule-field"
                          type="string"
                        />
                        <Field
                          name="day_of_week"
                          component={TextField}
                          label="Day&nbsp;of&nbsp;week"
                          className="expiration-schedule-field"
                          type="string"
                        />
                      </FormSection>
                      <Button
                        color="primary"
                        size="medium"
                        className={`delete-cache ${
                          cacheRenewInProgress ? "loading refreshing" : ""
                        }`}
                        onClick={this.openDeleteCacheModal}
                      >
                        {!cacheRenewInProgress && (
                          <React.Fragment>
                            <FontAwesomeIcon
                              className="trash-icon icon"
                              icon={["far", "history"]}
                            />{" "}
                            refresh cache
                          </React.Fragment>
                        )}
                        {cacheRenewInProgress && (
                          <span className="small-text">
                            Running cache refresh (
                            <Duration datetime={cacheRefreshInitTime} />)
                          </span>
                        )}
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="row-title">
                    <h3>Maintenance notifications email(s)</h3>
                    <p className="title-description">
                      All important emails will be sent here, so make sure you read them!
                    </p>
                  </td>
                  <td className="values">
                    <div className="form-row maintenance-emails">
                      <FormSection name="maintenance_notifications_emails">
                        <FieldArray name="values" component={this.renderNotificationEmails} />
                      </FormSection>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Paper>
          <ConfirmModal
            open={openDeleteModal}
            type={MODAL.TYPE.DELETE}
            customButtonText="refresh"
            handleClose={this.closeDeleteCacheModal}
            handleConfirm={this.deleteCache}
            title="Refresh"
            text="Do you really want to refresh cache?"
            isLoading={cacheDeleting}
          />
        </Form>
        <Prompt when={dirty} message="Changes you made will not be saved." />
      </React.Fragment>
    )
  }
}

const validate = values => {
  let errors = {}
  const driver = _get(values, "cdp_db.value.driver.value")

  if (!_get(values, "cdp_db.value.driver.value", false)) {
    _set(errors, "cdp_db.value.driver", REQUIRED_ERROR_MESSAGE)
  } else {
    if (driver === "bigquery") {
      if (!_get(values, "cdp_db.value.project", false)) {
        _set(errors, "cdp_db.value.project", REQUIRED_ERROR_MESSAGE)
      }
      if (!_get(values, "cdp_db.value.dataset", false)) {
        _set(errors, "cdp_db.value.dataset", REQUIRED_ERROR_MESSAGE)
      }
      if (!_get(values, "cdp_db.value.exports_dataset", false)) {
        _set(errors, "cdp_db.value.exports_dataset", REQUIRED_ERROR_MESSAGE)
      }
      if (!_get(values, "cdp_db.value.#credentials", false)) {
        _set(errors, "cdp_db.value.#credentials", REQUIRED_ERROR_MESSAGE)
      }
      if (!_get(values, "cdp_db.value.location", false)) {
        _set(errors, "cdp_db.value.location", REQUIRED_ERROR_MESSAGE)
      }
    } else if (driver === "postgre" || driver === "redshift") {
      if (!_get(values, "cdp_db.value.db_name", false)) {
        _set(errors, "cdp_db.value.db_name", REQUIRED_ERROR_MESSAGE)
      }
      if (!_get(values, "cdp_db.value.host", false)) {
        _set(errors, "cdp_db.value.host", REQUIRED_ERROR_MESSAGE)
      }
      if (!_get(values, "cdp_db.value.port", false)) {
        _set(errors, "cdp_db.value.port", REQUIRED_ERROR_MESSAGE)
      }
      if (!_get(values, "cdp_db.value.exports_db_name", false)) {
        _set(errors, "cdp_db.value.exports_db_name", REQUIRED_ERROR_MESSAGE)
      }
      if (!_get(values, "cdp_db.value.user", false)) {
        _set(errors, "cdp_db.value.user", REQUIRED_ERROR_MESSAGE)
      }
      if (!_get(values, "cdp_db.value.#password", false)) {
        _set(errors, "cdp_db.value.#password", REQUIRED_ERROR_MESSAGE)
      }
      if (!_get(values, "cdp_db.value.schema", false)) {
        _set(errors, "cdp_db.value.schema", REQUIRED_ERROR_MESSAGE)
      }
    }
  }
  // customer entities db
  if (!_get(values, "customer_entities_db.value.host", false)) {
    _set(errors, "customer_entities_db.value.host", REQUIRED_ERROR_MESSAGE)
  }
  if (!_get(values, "customer_entities_db.value.port", false)) {
    _set(errors, "customer_entities_db.value.port", REQUIRED_ERROR_MESSAGE)
  }
  if (!_get(values, "customer_entities_db.value.user", false)) {
    _set(errors, "customer_entities_db.value.user", REQUIRED_ERROR_MESSAGE)
  }
  if (!_get(values, "customer_entities_db.value.password", false)) {
    _set(errors, "customer_entities_db.value.password", REQUIRED_ERROR_MESSAGE)
  }

  if (!_get(values, "cdp_cache_expiration_period.value.minute", false)) {
    _set(errors, "cdp_cache_expiration_period.value.minute", REQUIRED_ERROR_MESSAGE)
  }
  if (!_get(values, "cdp_cache_expiration_period.value.hour", false)) {
    _set(errors, "cdp_cache_expiration_period.value.hour", REQUIRED_ERROR_MESSAGE)
  }
  if (!_get(values, "cdp_cache_expiration_period.value.day", false)) {
    _set(errors, "cdp_cache_expiration_period.value.day", REQUIRED_ERROR_MESSAGE)
  }
  if (!_get(values, "cdp_cache_expiration_period.value.month", false)) {
    _set(errors, "cdp_cache_expiration_period.value.month", REQUIRED_ERROR_MESSAGE)
  }
  if (!_get(values, "cdp_cache_expiration_period.value.day_of_week", false)) {
    _set(errors, "cdp_cache_expiration_period.value.day_of_week", REQUIRED_ERROR_MESSAGE)
  }

  const notificationEmails = _get(values, "maintenance_notifications_emails.values", [""])
  notificationEmails.forEach((email, index) => {
    if (!email) {
      _set(errors, `maintenance_notifications_emails.values[${index}]`, REQUIRED_ERROR_MESSAGE)
    } else if (!isEmailValid(email)) {
      _set(errors, `maintenance_notifications_emails.values[${index}]`, "Email is not valid")
    } else if (notificationEmails.filter(fEmail => _trim(email) === _trim(fEmail)).length > 1) {
      _set(errors, `maintenance_notifications_emails.values[${index}]`, "Duplicate email")
    }
  })

  // mi api
  const miApiUrl = _get(values, "mi_api.value.url")
  const miApiEmail = _get(values, "mi_api.value.email")
  const miApiPassword = _get(values, "mi_api.value.#password")
  if (miApiUrl || miApiEmail || miApiPassword) {
    if (!miApiUrl) {
      _set(errors, "mi_api.value.url", REQUIRED_ERROR_MESSAGE)
    }
    if (!miApiEmail) {
      _set(errors, "mi_api.value.email", REQUIRED_ERROR_MESSAGE)
    }
    if (!miApiPassword) {
      _set(errors, "mi_api.value.#password", REQUIRED_ERROR_MESSAGE)
    }
  }

  // me api
  const meApiUrl = _get(values, "me_api.value.url")
  const meApiEmail = _get(values, "me_api.value.email")
  const meApiPassword = _get(values, "me_api.value.#password")
  if (meApiUrl || meApiEmail || meApiPassword) {
    if (!meApiUrl) {
      _set(errors, "me_api.value.url", REQUIRED_ERROR_MESSAGE)
    }
    if (!meApiEmail) {
      _set(errors, "me_api.value.email", REQUIRED_ERROR_MESSAGE)
    }
    if (!meApiPassword) {
      _set(errors, "me_api.value.#password", REQUIRED_ERROR_MESSAGE)
    }
  }

  return errors
}

SettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  reset: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  handleCacheRenew: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  formValues: getFormValues("GlobalSettingsForm")(state),
})

SettingsForm = connect(mapStateToProps, { showToast })(SettingsForm)

SettingsForm = reduxForm({
  form: "GlobalSettingsForm",
  enableReinitialize: true,
  touchOnBlur: false,
  validate,
})(SettingsForm)

export default SettingsForm
