import { STITCHING_ATTRIBUTE } from "sharedConstants"
import _omit from "lodash/omit"

const initialState = {
  isFulfilled: false,
  data: {},
}

export default (state = initialState, { type, payload }) => {
  const reducePayloadData = data => {
    return data.reduce((acc, item) => {
      if (acc[item.attribute_id]) {
        acc[item.attribute_id].configs.push({
          event_id: item.event_id,
          value: item.value_expression,
        })
      } else {
        acc[item.attribute_id] = {
          attribute_id: item.attribute_id,
          configs: [{ event_id: item.event_id, value: item.value_expression }],
        }
      }
      return acc
    }, {})
  }

  switch (type) {
    case `${STITCHING_ATTRIBUTE.ACTION.LIST}_FULFILLED`:
      return {
        ...state,
        isFulfilled: true,
        data: reducePayloadData(payload),
      }
    case `${STITCHING_ATTRIBUTE.ACTION.UPDATE}_FULFILLED`:
      return {
        ...state,
        data: {
          ...state.data,
          ...reducePayloadData(payload),
        },
      }
    case `${STITCHING_ATTRIBUTE.ACTION.DELETE}_FULFILLED`:
      return {
        ...state,
        data: {
          ..._omit(state.data, [payload]),
        },
      }
    default:
      return state
  }
}
