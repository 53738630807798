import React, { useState, useEffect } from "react"
import AceEditor from "react-ace"
import IconButton, { COLOR } from "components/UI/elements/IconButton"
import "ace-builds/src-noconflict/mode-pgsql"
import "ace-builds/src-noconflict/theme-github"

import Modal from "../../elements/Modal"
import Button from "../../elements/Button/Button"

import styles from "./ExpandableAceEditor.module.scss"
import classNames from "classnames"

type ExpandableAceEditorProps = {
  input: any
  expandModalTitle?: string
  label: string
  meta: any
  className?: string
  width?: string
  height?: string
}

const ExpandableAceEditor = ({
  input,
  expandModalTitle,
  label,
  meta: { touched, error },
  className,
  width = "100%",
  height = "80px",
}: ExpandableAceEditorProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [expandAceValue, setExpandAceValue] = useState<string>(input.value)

  useEffect(() => {
    setExpandAceValue(input.value)
  }, [open, input.value])

  const confirm = () => {
    input.onChange(expandAceValue)
    closeExpandModal()
  }

  const closeExpandModal = () => {
    setOpen(false)
  }

  const openExpandModal = () => {
    setOpen(true)
  }

  return (
    <div
      className={classNames(styles.aceEditorContainer, "ace-editor-wrapper", className, {
        error: touched && error,
      })}
    >
      {label && <p className="label-like">{label}</p>}
      <div className={styles.aceEditorWrapperInner}>
        <AceEditor
          value={input.value}
          onChange={input.onChange}
          mode="pgsql"
          theme="github"
          editorProps={{ $blockScrolling: true }}
          width={width}
          height={height}
          wrapEnabled={true}
          className="ace-editor"
        />
        <IconButton
          className={styles.expandButton}
          color={COLOR.GREY}
          onClick={openExpandModal}
          data-testid="expand-button"
          iconName="expand-alt"
          iconStyle="far"
          tooltip="Expand"
          withBackground={false}
        />
        <Modal
          open={open}
          handleClose={closeExpandModal}
          title={expandModalTitle ?? label ?? ""}
          className=""
          customCloseIconName="compress-alt"
          customCloseIconStyle="far"
          size="large"
        >
          <div className={styles.textareaExpandModal}>
            <AceEditor
              mode="pgsql"
              theme="github"
              name="name"
              onChange={setExpandAceValue}
              editorProps={{ $blockScrolling: true }}
              width="717px"
              height="400px"
              wrapEnabled={true}
              className="ace-editor"
              value={expandAceValue}
              focus
            />
            <div className={styles.modalButtons}>
              <Button
                color="transparent-grey"
                type="button"
                size="medium"
                onClick={closeExpandModal}
                className={styles.cancelButton}
              >
                Cancel
              </Button>
              <Button color="primary" type="button" size="medium" onClick={confirm}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
        {touched && error && <p className="error-message">{error}</p>}
      </div>
    </div>
  )
}

export default ExpandableAceEditor
