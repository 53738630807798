import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import EmbeddedWebBannersCreate from "./EmbeddedWebBannersCreate/EmbeddedWebBannersCreate"
import EmbeddedWebBannersDetail from "./EmbeddedWebBannersDetail/EmbeddedWebBannersDetail"
import EmbeddedWebBannersList from "./EmbeddedWebBannersList/EmbeddedWebBannersList"

export default function EmbeddedWebBanners() {
  return (
    <Switch>
      <Route
        path={getRoutePath("personalization.embedded-web-banners")}
        exact
        component={EmbeddedWebBannersList}
      />
      <Route
        path={getRoutePath("personalization.embedded-web-banners.create")}
        component={EmbeddedWebBannersCreate}
      />
      <Route
        path={getRoutePath("personalization.embedded-web-banners.detail")}
        component={EmbeddedWebBannersDetail}
      />
    </Switch>
  )
}
