import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import PopupWebBannersCreate from "./PopupWebBannersCreate/PopupWebBannersCreate"
import PopupWebBannersDetail from "./PopupWebBannersDetail/PopupWebBannersDetail"
import PopupWebBannersList from "./PopupWebBannersList/PopupWebBannersList"

export default function PopupWebBanners() {
  return (
    <Switch>
      <Route
        path={getRoutePath("personalization.popup-web-banners")}
        exact
        component={PopupWebBannersList}
      />
      <Route
        path={getRoutePath("personalization.popup-web-banners.create")}
        component={PopupWebBannersCreate}
      />
      <Route
        path={getRoutePath("personalization.popup-web-banners.detail")}
        component={PopupWebBannersDetail}
      />
    </Switch>
  )
}
