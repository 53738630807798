import _toString from "lodash/toString"
import { Map } from "immutable"

export const getSegmentCustomersState = state => state.segmentCustomers

export const getSegmentCustomers = (state, id) => {
  if (state.segmentCustomers.has(_toString(id))) {
    return state.segmentCustomers.get(_toString(id))
  }
  return Map({
    fetching: true,
  })
}
