import React, { PureComponent } from "react"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import { getFormValues } from "redux-form"
import { List } from "immutable"
import Paper from "components/UI/elements/Paper"
import Tag from "components/UI/elements/Tag"
import TagPicker from "components/UI/components/TagPicker"
import moment from "moment"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "resources/attribute/compoundAttributeUtils"
import { getIconSrc } from "helpers/image.helper"
import { getRoutePath } from "routes"
import AttributesFilterForm from "components/UI/components/AttributesFilterForm"
import AttributeId from "components/UI/components/AttributeId/AttributeId"
import Badge from "components/UI/elements/AttributeBadge"
import Table, {
  Thead,
  Th,
  Tbody,
  Td,
  Tr,
  RowMessage,
  SortButton,
} from "components/UI/elements/Table"

import "./AttributesList.scss"
import {
  useFetchActiveLabels,
  useFetchAllAttributes,
  useModifyAttribute,
} from "resources/attribute/attributeQueries"
import create from "zustand"
import { without } from "ramda"
import { useSelector } from "store"

class AttributesList extends PureComponent {
  renderSelectedLabels = () => {
    const {
      filters: { labelIds, removeLabelId },
      attributesLabels,
    } = this.props

    if (!labelIds || labelIds.langth === 0) {
      return null
    }

    return labelIds.map(labelId => {
      const label = attributesLabels.find(label => label.id === labelId)
      if (label) {
        return (
          <Tag key={label.id} clickable color="primary" onClick={() => removeLabelId(label.id)}>
            {label.name}
          </Tag>
        )
      }
      return null
    })
  }

  goToAttributeDetail = attributeId => () => {
    this.props.history.push(getRoutePath("setup.attributes.detail", { id: attributeId }))
  }

  renderAttributeRow = attribute => {
    const { filters, toggleHidden } = this.props

    let tags

    if (attribute.tags) {
      tags = attribute.tags.map(tag => {
        return (
          <Tag
            key={tag.id}
            color="primary"
            onClick={() => filters.addLabelId(tag.id)}
            className="attribute-label"
          >
            {tag.name}
          </Tag>
        )
      })
    }

    return (
      <Tr key={attribute.id} className={attribute.is_hidden ? "is-hidden" : ""}>
        <Td textBold textBlack textBigger className="attribute-name">
          <span>{attribute.name}</span>
          {moment().diff(attribute.created, "days") < 8 && <Badge text="New" />}
          {isAttributeCompound(attribute.data_type) ? (
            <ul className="sub-attrs">
              {getCompoundAttributeSubAttributes(attribute.data_type).map(subAttr => (
                <li key={subAttr.id}>{subAttr.name}</li>
              ))}
            </ul>
          ) : (
            <br />
          )}
          <AttributeId id={attribute.id} />
        </Td>
        <Td className="attribute-description">{attribute.description}</Td>
        <Td className="attribute-source">
          <div className="attribute-source-flex-wrapper">
            <div className="image-wrapper">
              <img
                src={getIconSrc(
                  {
                    primary: attribute.source.frontend_settings?.icon,
                    secondary: attribute.source.type?.toLowerCase(),
                  },
                  attribute.source.frontend_settings?.alt_icon,
                )}
                alt="icon"
              />
            </div>
            <div className="source">
              <div>{attribute.source.name}</div>
            </div>
          </div>
        </Td>
        <Td className="attribute-tags">{tags}</Td>
        <Td className="actions" textAlignRight>
          <IconButton
            color={COLOR.BLACK}
            withBackground
            size={SIZE.TAG}
            onClick={this.goToAttributeDetail(attribute.id)}
            iconName="pencil-alt"
            tooltip="Edit"
          />
          <IconButton
            color={COLOR.RED}
            size={SIZE.TAG}
            withBackground
            onClick={() => toggleHidden(attribute)}
            className="left-margin"
            disabled={attribute.source.is_hidden === 1}
            iconStyle="far"
            iconName={attribute.is_hidden ? "eye" : "eye-slash"}
            tooltip={
              attribute.source.is_hidden === 1
                ? "Hidden source"
                : attribute.is_hidden
                ? "Show"
                : "Hide"
            }
          />
        </Td>
      </Tr>
    )
  }

  render() {
    const { attributes, areAttributesFulfilled, attributesLabels, history, filters } = this.props

    return (
      <section className="wrapper setup-attributes">
        <PaperHeader size="small" titleText="Attributes" className="setup-attributes-header">
          <AttributesFilterForm
            destroyOnUnmount={false}
            formName="SetupSearchAttributeForm"
            showHiddenSources={true}
          />
          <Button
            size="small"
            color="primary"
            onClick={() => {
              history.push(getRoutePath("setup.attributes.create"))
            }}
          >
            + Create attribute
          </Button>
        </PaperHeader>
        {areAttributesFulfilled && attributes.length > 0 && (
          <Paper hasHeader={true}>
            <div className="tag-filter">
              <div className="label-tags">
                <span className="selected-tags">Filter by:</span>
                <span>{this.renderSelectedLabels()}</span>
              </div>
              <TagPicker
                selectedTagIds={List(filters.labelIds)}
                allTags={List(attributesLabels)}
                onTagSelect={filters.addLabelId}
                className="selected-labels-picker"
                type="label"
              />
            </div>
            {attributes.length > 0 && (
              <Table className="attributes-table">
                <Thead stickyHeader>
                  <Th className="name">
                    <SortButton
                      column="name"
                      orderBy={filters.orderBy}
                      orderDir={filters.orderDir}
                      onClick={() => filters.setSort("name")}
                      label="Name"
                    />
                  </Th>
                  <Th className="description-col">Description</Th>
                  <Th className="source-col">
                    <SortButton
                      column="source"
                      orderBy={filters.orderBy}
                      orderDir={filters.orderDir}
                      onClick={() => filters.setSort("source")}
                      label="Data Source"
                    />
                  </Th>
                  <Th className="labels-col">
                    <SortButton
                      column="labels"
                      orderBy={filters.orderBy}
                      orderDir={filters.orderDir}
                      onClick={() => filters.setSort("labels")}
                      label="Labels"
                    />
                  </Th>
                  <Th className="actions" />
                </Thead>
                <Tbody>{attributes.map(this.renderAttributeRow)}</Tbody>
              </Table>
            )}
            {attributes.length === 0 && (
              <RowMessage className="attributes-not-found">Nothing found.</RowMessage>
            )}
          </Paper>
        )}
        {areAttributesFulfilled && attributes.length === 0 && (
          <p className="info-message">Click on the "Create Attribute" to get started.</p>
        )}
      </section>
    )
  }
}

const useStore = create(set => ({
  orderBy: "name",
  orderDir: "ASC",
  // searchTerm: "",
  labelIds: [],
  // sourceId: null,
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  // setSearchTerm: searchTerm => set({ searchTerm }),
  addLabelId: labelId =>
    set(({ labelIds }) => ({
      labelIds: labelIds.includes(labelId) ? labelIds : labelIds.concat(labelId),
    })),
  removeLabelId: labelId => set(({ labelIds }) => ({ labelIds: without([labelId], labelIds) })),
  // setSourceId: sourceId => set({ sourceId }),
}))

export default props => {
  const filters = useStore()
  const { orderBy, orderDir, labelIds } = filters
  const formValues = useSelector(getFormValues("SetupSearchAttributeForm"))

  const { data: attributes, isSuccess } = useFetchAllAttributes(
    {
      includeHidden: true,
      orderBy,
      orderDir,
      searchTerm: formValues?.search,
      labelIds,
      sourceId: formValues?.select?.value,
    },
    { refetchOnMount: "always" },
  )

  const { data: attributesLabels } = useFetchActiveLabels()

  const modifyMutation = useModifyAttribute()
  const toggleHidden = attribute =>
    modifyMutation.mutate({ id: attribute.id, data: { is_hidden: +!attribute.is_hidden } })

  return (
    <AttributesList
      {...props}
      attributes={attributes}
      attributesLabels={attributesLabels}
      filters={filters}
      areAttributesFulfilled={isSuccess}
      toggleHidden={toggleHidden}
    />
  )
}
