import { format } from "date-fns"
import { toLocalDate } from "helpers/date.helper"
import { ActivityDataResolution } from "types/usersActivity"
import { DateString } from "types/util"

const SHORT_DATE_FMT = "d.M.yy"
const MONTH_YEAR_FMT = "MMM yy"

export const makeDataLabels = (rawLabels: DateString[], resolution: ActivityDataResolution) =>
  rawLabels.map(rawLabel => {
    const localDate = toLocalDate(rawLabel)

    if (resolution === "month") {
      return format(localDate, MONTH_YEAR_FMT)
    }

    if (resolution === "week") {
      // Mark week labels with 'W' to be able to distinguish them in the chart tooltips callback
      return `W${format(localDate, SHORT_DATE_FMT)}`
    }

    return format(localDate, SHORT_DATE_FMT)
  })
