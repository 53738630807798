import SegmentDetail from "pages/Segments/components/SegmentDetail/SegmentDetail"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"

export default function SmartSegmentSetupDetail() {
  return (
    <Switch>
      <Route
        path={getRoutePath("setup.segments.smart.detail")}
        exact
        render={() => <SegmentDetail view="settings" type="smart" isSmartSetup />}
      />
      <Route
        path={getRoutePath("setup.segments.smart.detail.builder")}
        render={() => <SegmentDetail view="builder" type="smart" isSmartSetup />}
      />
      <Route
        path={getRoutePath("setup.segments.smart.detail.exports")}
        exact
        render={() => <SegmentDetail view="exports" type="smart" isSmartSetup />}
      />
    </Switch>
  )
}
