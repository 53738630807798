import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./SingleInputForm.scss"
import DelayedTooltip from "components/UI/elements/IconButton/DelayedTooltip/DelayedTooltip"

class SingleInputForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue,
      editMode: false,
      inputWidth: "auto",
    }
    this.ghostRef = React.createRef()
    this.inputRef = React.createRef()
    window.addEventListener("keyup", this.handleKeyUp, false)
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
  }

  handleKeyUp = evt => {
    const keys = {
      27: () => {
        if (this.state.editMode) {
          this.setState({ value: this.props.defaultValue }, this.toggleEditMode)
        }
      },
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  toggleEditMode = () => {
    const { editMode } = this.state
    if (editMode) {
      this.setState(
        {
          inputWidth: this.ghostRef.current.clientWidth + 1,
        },
        () => {
          requestAnimationFrame(() => {
            setTimeout(() => {
              this.setState({
                editMode: false,
              })
            }, 150)
          })
        },
      )
    } else {
      this.setState(
        {
          editMode: true,
          inputWidth: this.ghostRef.current.clientWidth + 1,
        },
        () => {
          setTimeout(() => {
            this.inputRef.current.focus()
            requestAnimationFrame(() => {
              this.setState({
                inputWidth: "100%",
              })
            })
          }, 0)
        },
      )
    }
  }

  onInputNameChange = evt => {
    this.setState({
      value: evt.target.value,
    })
  }

  submitForm = async evt => {
    evt.preventDefault()
    const success = await this.props.onSubmit(this.state.value)
    if (success) {
      this.toggleEditMode()
    }
  }

  render() {
    const { label } = this.props
    const { value, editMode, inputWidth } = this.state

    return (
      <form className="single-input-form text-field" onSubmit={this.submitForm}>
        <label>{label}</label>
        <div className={`value-wrapper ${editMode ? "edit" : ""}`} style={{ width: inputWidth }}>
          <input
            type="text"
            name="value"
            value={value}
            className={editMode ? "active" : ""}
            readOnly={!editMode}
            onChange={this.onInputNameChange}
            ref={this.inputRef}
            autoComplete="off"
          />
          <div className="ghost" ref={this.ghostRef}>
            <span>{value}</span>
          </div>
          {editMode && (
            <DelayedTooltip content="Save" className="control-button-tooltip">
              <button type="submit" className={"edit-action-button edit"}>
                <FontAwesomeIcon icon={["fas", "check"]} />
              </button>
            </DelayedTooltip>
          )}
          {!editMode && (
            <DelayedTooltip content="Edit" className="control-button-tooltip">
              <button type="button" className="edit-action-button" onClick={this.toggleEditMode}>
                <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
              </button>
            </DelayedTooltip>
          )}
        </div>
      </form>
    )
  }
}

SingleInputForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
}

export default SingleInputForm
