import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { shortenText } from "helpers/string.helper"

import "./Tag.scss"
import Tippy from "@tippyjs/react"

const Tag = ({
  children,
  color,
  clickable = false,
  size = "small",
  className = "",
  maxCharacters = null,
  uniqueTooltipId = null,
  ...tagAttrs
}) => {
  let content = children
  let tooltip = false
  if (maxCharacters !== null && maxCharacters > 5) {
    // content must be of type text when using with combination with maxCharacters property
    if (content.length > maxCharacters) {
      content = shortenText(content, maxCharacters)
      tooltip = true
    }
  }
  return (
    <React.Fragment>
      <Tippy
        placement="bottom"
        content={children}
        className="tag-tooltip"
        disabled={!(tooltip && uniqueTooltipId !== null)}
      >
        <span
          {...tagAttrs}
          className={`${color} tag ${size} ${clickable ? "clickable" : ""} ${className}`}
          data-testid="tag"
        >
          <span data-testid="content">{content}</span>
          <FontAwesomeIcon icon={["far", "times"]} className="close" />
        </span>
      </Tippy>
    </React.Fragment>
  )
}

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  clickable: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  maxCharacters: PropTypes.number,
  uniqueTooltipId: PropTypes.string,
}

export default Tag
