import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { NavLink, Link } from "react-router-dom"
import { withRouter } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Route, Switch } from "react-router-dom"
import _get from "lodash/get"
import _size from "lodash/size"

import { logout, modifyAuthUser } from "actions/authenticatedUser.action"
import { getRoutePath } from "routes"
import { hasAccess } from "helpers/authenticatedUser.helper"
import DropdownMenu, {
  DropdownToggle,
  DropdownContent,
  DropdownMenuItem,
} from "../DropdownMenu/DropdownMenu"
import Avatar from "../../../components/UI/elements/Avatar"

// submenu components
import SegmentDetailSubmenu from "./SubmenuComponent/SegmentDetailSubmenu"
import SegmentExportLogSubmenu from "./SubmenuComponent/SegmentExportLogSubmenu"

import { version as meiroVersion } from "../../../../package.json"

import logo from "images/meiro-symbol-white-transparent.svg"
import clientDummyLogo from "images/client-dummy.png"
import "./HeaderContainer.scss"
import NotificationsDropdown from "../NotificationsDropdown/NotificationsDropdown"
import DelayedTooltip from "components/UI/elements/IconButton/DelayedTooltip/DelayedTooltip"
import Tippy from "@tippyjs/react"
import HelpDropdown from "../HelpDropdown/HelpDropdown"

const meiroClientLogoSrc = `${process.env.PUBLIC_URL}/img/client/logo.png`
let meiroLocation = ""
let meiroClient = ""
if (process.env.NODE_ENV === "production") {
  meiroLocation = "[[MEIRO_LOCATION]]"
  meiroClient = "[[MEIRO_CLIENT]]"
} else {
  meiroLocation = process.env.REACT_APP_MEIRO_LOCATION
  meiroClient = process.env.REACT_APP_CLIENT_NAME === '""' ? "" : process.env.REACT_APP_CLIENT_NAME
}

const LimitedAccessTooltipWrapper = ({ enabled = false, children }) => {
  if (enabled) {
    return (
      <Tippy
        placement="bottom"
        content="Your user role has limited access to this tab. To edit permissions contact the administrator."
        className="disabledMenuTooltip"
      >
        <div>{children}</div>
      </Tippy>
    )
  } else {
    return children
  }
}

const AdminSubmenu = () => {
  return (
    <nav className="secondary-nav">
      <NavLink
        to={getRoutePath("administration.users-activity")}
        onClick={evt => {
          if (!hasAccess.administration.usersActivity()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.administration.usersActivity() ? "is-active" : ""}
        disabled={!hasAccess.administration.usersActivity()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.administration.usersActivity()}>
          Users Activity
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("administration.users")}
        onClick={evt => {
          if (!hasAccess.administration.users()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.administration.users() ? "is-active" : ""}
        disabled={!hasAccess.administration.users()}
        data-testid="users-submenu-item-link"
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.administration.users()}>
          Users
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("administration.roles")}
        onClick={evt => {
          if (!hasAccess.administration.users()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.administration.users() ? "is-active" : ""}
        disabled={!hasAccess.administration.users()}
        data-testid="user-roles-submenu-item-link"
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.administration.users()}>
          Roles
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("administration.settings")}
        onClick={evt => {
          if (!hasAccess.administration.globalSettings()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.administration.globalSettings() ? "is-active" : ""}
        disabled={!hasAccess.administration.globalSettings()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.administration.globalSettings()}>
          Settings
        </LimitedAccessTooltipWrapper>
      </NavLink>
    </nav>
  )
}

const PersonalizationSubmenu = () => {
  return (
    <nav className="secondary-nav">
      <NavLink to={getRoutePath("personalization.popup-web-banners")} activeClassName="is-active">
        Pop up Web Banners
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("personalization.embedded-web-banners")}
        activeClassName="is-active"
        data-testid="ewb-submenu-item-link"
      >
        Embedded Web Banners
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("personalization.mobile-push-notifications")}
        activeClassName="is-active"
        className="flex-link"
      >
        Mobile Push Notifications
        <div className="header-badge">coming{"\n"}soon</div>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink to={getRoutePath("personalization.promo-codes")} activeClassName="is-active">
        Promo Codes
      </NavLink>
    </nav>
  )
}

const SetupSubmenu = ({ reportingEnabled }) => {
  return (
    <nav className="secondary-nav">
      <NavLink
        to={getRoutePath("setup.sources")}
        onClick={evt => {
          if (!hasAccess.setup.implementation()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.setup.implementation() ? "is-active" : ""}
        disabled={!hasAccess.setup.implementation()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.setup.implementation()}>
          Sources
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("setup.events")}
        onClick={evt => {
          if (!hasAccess.setup.implementation()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.setup.implementation() ? "is-active" : ""}
        disabled={!hasAccess.setup.implementation()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.setup.implementation()}>
          Events
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("setup.identity-stitching")}
        onClick={evt => {
          if (!hasAccess.setup.implementation()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.setup.implementation() ? "is-active" : ""}
        disabled={!hasAccess.setup.implementation()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.setup.implementation()}>
          Identity Stitching
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("setup.attributes")}
        onClick={evt => {
          if (!hasAccess.setup.implementation()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.setup.implementation() ? "is-active" : ""}
        disabled={!hasAccess.setup.implementation()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.setup.implementation()}>
          Attributes
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("setup.labels")}
        onClick={evt => {
          if (!hasAccess.setup.implementation()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.setup.implementation() ? "is-active" : ""}
        disabled={!hasAccess.setup.implementation()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.setup.implementation()}>
          Labels
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("setup.segments.smart")}
        onClick={evt => {
          if (!hasAccess.setup.smartSegments()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.setup.smartSegments() ? "is-active" : ""}
        disabled={!hasAccess.setup.smartSegments()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.setup.smartSegments()}>
          Smart Segments
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("setup.insights")}
        onClick={evt => {
          if (!hasAccess.setup.insights()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.setup.insights() ? "is-active" : ""}
        disabled={!hasAccess.setup.insights()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.setup.insights()}>
          Insights
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      {reportingEnabled && (
        <NavLink
          to={getRoutePath("setup.reports")}
          onClick={evt => {
            if (!hasAccess.setup.reports()) {
              evt.preventDefault()
            }
          }}
          activeClassName={hasAccess.setup.reports() ? "is-active" : ""}
          disabled={!hasAccess.setup.reports()}
        >
          <LimitedAccessTooltipWrapper enabled={!hasAccess.setup.reports()}>
            Reporting
          </LimitedAccessTooltipWrapper>
        </NavLink>
      )}
      <NavLink
        to={getRoutePath("setup.destinations")}
        onClick={evt => {
          if (!hasAccess.setup.implementation()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.setup.implementation() ? "is-active" : ""}
        disabled={!hasAccess.setup.implementation()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.setup.implementation()}>
          Destinations
        </LimitedAccessTooltipWrapper>
      </NavLink>
    </nav>
  )
}

const DataSubmenu = () => {
  return (
    <nav className="secondary-nav">
      <NavLink
        to={getRoutePath("data.dashboard")}
        onClick={evt => {
          if (!hasAccess.data.dashboard()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.data.dashboard() ? "is-active" : ""}
        disabled={!hasAccess.data.dashboard()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.data.dashboard()}>
          Diagnostic dashboard
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("data.insights")}
        onClick={evt => {
          if (!hasAccess.data.insights()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.data.insights() ? "is-active" : ""}
        disabled={!hasAccess.data.insights()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.data.insights()}>
          Insights
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("data.attributes")}
        onClick={evt => {
          if (!hasAccess.data.attributes()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.data.attributes() ? "is-active" : ""}
        disabled={!hasAccess.data.attributes()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.data.attributes()}>
          Attributes
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("data.events")}
        onClick={evt => {
          if (!hasAccess.data.events()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.data.events() ? "is-active" : ""}
        disabled={!hasAccess.data.events()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.data.events()}>
          Events
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("data.sources-destinations")}
        onClick={evt => {
          if (!hasAccess.data.sourcesAndDestinations()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.data.sourcesAndDestinations() ? "is-active" : ""}
        disabled={!hasAccess.data.sourcesAndDestinations()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.data.sourcesAndDestinations()}>
          Sources & Destinations
        </LimitedAccessTooltipWrapper>
      </NavLink>
    </nav>
  )
}

const SegmentsListSubmenu = () => {
  return (
    <nav className="secondary-nav">
      <NavLink exact to={getRoutePath("segments")} activeClassName="is-active">
        Custom segments
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink to={getRoutePath("segments.featured")} activeClassName="is-active">
        Featured segments
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink exact to={getRoutePath("segments.smart")} activeClassName="is-active">
        Smart segments
      </NavLink>
      <span className="middot">
        <span>&middot;</span>
      </span>
      <NavLink
        to={getRoutePath("segments.tags")}
        onClick={evt => {
          if (!hasAccess.segments.viewTags()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.segments.viewTags() ? "is-active" : ""}
        disabled={!hasAccess.segments.viewTags()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.segments.viewTags()}>
          Tags
        </LimitedAccessTooltipWrapper>
      </NavLink>
    </nav>
  )
}

const CustomersListSubmenu = () => {
  return (
    <nav className="secondary-nav">
      <NavLink
        to={getRoutePath("customers")}
        onClick={evt => {
          if (!hasAccess.customers.search()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.customers.search() ? "is-active" : ""}
        disabled={!hasAccess.customers.search()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.customers.search()}>
          Customers
        </LimitedAccessTooltipWrapper>
      </NavLink>
    </nav>
  )
}

const ReportingSubmenu = () => {
  return (
    <nav className="secondary-nav">
      <NavLink to={getRoutePath("reporting.reporting")} activeClassName="is-active">
        Reporting
      </NavLink>
    </nav>
  )
}

const CustomerDetailSubmenu = () => {
  return (
    <nav className="secondary-nav">
      <NavLink
        exact
        to={getRoutePath("customers")}
        onClick={evt => {
          if (!hasAccess.customers.search()) {
            evt.preventDefault()
          }
        }}
        activeClassName={hasAccess.customers.search() ? "is-active" : ""}
        disabled={!hasAccess.customers.search()}
      >
        <LimitedAccessTooltipWrapper enabled={!hasAccess.customers.search()}>
          Customers
        </LimitedAccessTooltipWrapper>
      </NavLink>
      <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
      <span className="link-like is-active">Customer Detail</span>
    </nav>
  )
}

const TrashSubmenu = () => {
  return (
    <nav className="secondary-nav">
      <NavLink exact to={getRoutePath("trash")} activeClassName="is-active">
        Trash
      </NavLink>
    </nav>
  )
}

const UserSettingsSubmenu = () => {
  return (
    <nav className="secondary-nav">
      <NavLink exact to={getRoutePath("user-settings")} activeClassName="is-active">
        User Settings
      </NavLink>
    </nav>
  )
}

const NotAuthorizedSubmenu = () => {
  return (
    <nav className="secondary-nav">
      <NavLink exact to={getRoutePath("not-authorized")} activeClassName="is-active">
        403 - Not authorized
      </NavLink>
    </nav>
  )
}

const NotFoundSubmenu = () => {
  return (
    <nav className="secondary-nav">
      <NavLink exact to={getRoutePath("not-found")} activeClassName="is-active">
        404 - Not found
      </NavLink>
    </nav>
  )
}

const HomePageSubmenu = () => {
  return (
    <nav className="secondary-nav">
      <NavLink exact to={getRoutePath("home")} activeClassName="is-active">
        Homepage
      </NavLink>
    </nav>
  )
}

class HeaderContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userMenu: false,
    }
  }

  toggleUserMenu = () => {
    this.setState(prevState => ({
      userMenu: !prevState.userMenu,
    }))
  }

  authenticatedUserLogout = () => {
    this.props.logout(true)
  }

  openLink = url => () => {
    window.open(url, "_blank")
  }

  goToLogs = async () => {
    this.openLink(`${window.location.origin}/logs`)()
  }

  render() {
    const { authenticatedUser, reportingEnabled } = this.props
    const { userMenu } = this.state

    const roleName = _get(authenticatedUser, "data.role.name")

    return (
      <div className={"header-container"}>
        <div className="primary">
          <div className="wrapper">
            <div className="logo-wrapper">
              <Link to={getRoutePath("home")} className="logo">
                <img src={logo} alt="logo" />
                <div className="client-logo">
                  <img
                    onError={evt => {
                      evt.target.src = clientDummyLogo
                      evt.target.className = "dummy"
                    }}
                    src={meiroClientLogoSrc}
                    alt="client logo"
                  />
                </div>
                <span className="product-name">
                  {_size(meiroClient) === 0 && (
                    <React.Fragment>
                      <span>Meiro</span>
                      <span>Business</span>
                      <span>Explorer</span>
                    </React.Fragment>
                  )}
                  {_size(meiroClient) > 0 && (
                    <React.Fragment>
                      <span>Meiro Business</span>
                      <span>Explorer For</span>
                      <span className="strong">{meiroClient}</span>
                    </React.Fragment>
                  )}
                </span>
              </Link>
            </div>
            <nav className="primary-nav">
              <NavLink
                to={getRoutePath("customers")}
                onClick={evt => {
                  if (!hasAccess.customers.search()) {
                    evt.preventDefault()
                  }
                }}
                activeClassName={hasAccess.customers.search() ? "is-active" : ""}
                disabled={!hasAccess.customers.search()}
                data-testid="customers-menu-item-link"
              >
                <LimitedAccessTooltipWrapper enabled={!hasAccess.customers.search()}>
                  <div className="menu-item">
                    <span className="inline-icon">
                      <FontAwesomeIcon icon="users" />
                    </span>
                    <span className="link-text">Customers</span>
                  </div>
                </LimitedAccessTooltipWrapper>
              </NavLink>
              <NavLink
                to={getRoutePath("segments")}
                activeClassName="is-active"
                data-testid="segments-menu-item-link"
              >
                <div className="menu-item">
                  <span className="inline-icon">
                    <span className="icon-wrapper">
                      <FontAwesomeIcon icon="filter" />
                    </span>
                  </span>
                  <span className="link-text">Segments</span>
                </div>
              </NavLink>
              <NavLink
                to={getRoutePath("data")}
                onClick={evt => {
                  if (!hasAccess.data.general()) {
                    evt.preventDefault()
                  }
                }}
                activeClassName={hasAccess.data.general() ? "is-active" : ""}
                disabled={!hasAccess.data.general()}
                data-testid="data-menu-item-link"
              >
                <LimitedAccessTooltipWrapper enabled={!hasAccess.data.general()}>
                  <div className="menu-item">
                    <span className="inline-icon">
                      <span className="icon-wrapper">
                        <FontAwesomeIcon icon="tachometer" />
                      </span>
                    </span>
                    <span className="link-text">Data</span>
                  </div>
                </LimitedAccessTooltipWrapper>
              </NavLink>
              {reportingEnabled && (
                <NavLink
                  to={getRoutePath("reporting")}
                  onClick={evt => {
                    if (!hasAccess.reports()) {
                      evt.preventDefault()
                    }
                  }}
                  activeClassName={hasAccess.reports() ? "is-active" : ""}
                  disabled={!hasAccess.reports()}
                  data-testid="reporting-menu-item-link"
                >
                  <LimitedAccessTooltipWrapper enabled={!hasAccess.reports()}>
                    <div className="menu-item">
                      <span className="inline-icon">
                        <span className="icon-wrapper">
                          <FontAwesomeIcon icon={["fas", "chart-bar"]} />
                        </span>
                      </span>
                      <span className="link-text">Reporting</span>
                    </div>
                  </LimitedAccessTooltipWrapper>
                </NavLink>
              )}
              <NavLink
                to={getRoutePath("personalization")}
                onClick={evt => {
                  if (!hasAccess.webBanners.view()) {
                    evt.preventDefault()
                  }
                }}
                activeClassName="is-active"
                className="personalization-menu-item"
                disabled={!hasAccess.webBanners.view()}
                data-testid="personalization-menu-item-link"
              >
                <LimitedAccessTooltipWrapper enabled={!hasAccess.webBanners.view()}>
                  <div className="menu-item">
                    <span className="inline-icon">
                      <span className="icon-wrapper">
                        <FontAwesomeIcon icon={["fas", "bullhorn"]} />
                      </span>
                    </span>
                    <span className="link-text">Personalization</span>
                  </div>
                </LimitedAccessTooltipWrapper>
              </NavLink>
              {hasAccess.setup.general() && (
                <NavLink
                  to={getRoutePath("setup")}
                  activeClassName="is-active"
                  data-testid="setup-menu-item-link"
                >
                  <div className="menu-item">
                    <span className="inline-icon">
                      <span className="icon-wrapper">
                        <FontAwesomeIcon icon={["fas", "cogs"]} />
                      </span>
                    </span>
                    <span className="link-text">Setup</span>
                  </div>
                </NavLink>
              )}
            </nav>
            <div className="user-menu-wrapper">
              <HelpDropdown />
              <NotificationsDropdown />
              {hasAccess.administration.general() && (
                <DelayedTooltip content="Administration">
                  <NavLink
                    to={getRoutePath("administration")}
                    className="administration-link"
                    activeClassName="is-active"
                    data-testid="administration-menu-item-link"
                  >
                    <FontAwesomeIcon className="icon" icon={["fas", "wrench"]} />
                  </NavLink>
                </DelayedTooltip>
              )}
              <DelayedTooltip content="Trash">
                <NavLink
                  to={getRoutePath("trash")}
                  activeClassName="is-active"
                  data-testid="trash-navigation-link"
                >
                  <FontAwesomeIcon className="icon" icon={["fas", "trash-alt"]} />
                </NavLink>
              </DelayedTooltip>
              <DropdownMenu open={userMenu} toggle={this.toggleUserMenu} align="right">
                <DropdownToggle className="user-menu-toggle-button">
                  <Avatar
                    name={authenticatedUser.data.name}
                    email={authenticatedUser.data.email}
                    className="gravatar-image"
                  />
                  {userMenu && (
                    <FontAwesomeIcon className="dropdown-caret" icon={["fas", "caret-up"]} />
                  )}
                  {!userMenu && (
                    <FontAwesomeIcon
                      data-testid="user-menu-toggle-button"
                      className="dropdown-caret"
                      icon={["fas", "caret-down"]}
                    />
                  )}
                </DropdownToggle>
                <DropdownContent className="user-menu">
                  <div className="logged-in-as">
                    <div className="logged-in-as-heading">Logged in as</div>
                    <div className="logged-in-as-content">
                      <div className="name">{authenticatedUser.data.name}</div>
                      <div className="email">{authenticatedUser.data.email}</div>
                    </div>
                  </div>
                  <DropdownMenuItem link={getRoutePath("user-settings")}>
                    <FontAwesomeIcon className="icon" icon={["fas", "user"]} /> User settings
                  </DropdownMenuItem>
                  {hasAccess.logs() && (
                    <DropdownMenuItem action={this.goToLogs}>
                      <FontAwesomeIcon className="icon" icon={["fas", "terminal"]} /> Logs
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuItem action={this.authenticatedUserLogout}>
                    <FontAwesomeIcon
                      data-testid="logout-button"
                      className="icon"
                      icon={["fas", "sign-out"]}
                    />{" "}
                    Logout
                  </DropdownMenuItem>
                  <div className="instance-info">
                    <div className="role-info">
                      <span className="role-label">
                        <FontAwesomeIcon icon={["fas", "user-cog"]} /> Role:
                      </span>
                      <span className="role-value">{roleName}</span>
                    </div>
                    <div className="location-info">
                      <span className="location-label">
                        <FontAwesomeIcon icon={["fas", "cloud"]} /> Location:
                      </span>
                      <span className="location-value">{meiroLocation}</span>
                    </div>
                    <div className="version-info">
                      <span className="version-label">
                        <FontAwesomeIcon icon={["fas", "code"]} /> Version:
                      </span>{" "}
                      <span className="version-value">{meiroVersion}</span>
                    </div>
                  </div>
                </DropdownContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
        <div className="secondary">
          <div className="wrapper">
            <Switch>
              <Route path={getRoutePath("administration")} component={AdminSubmenu} />
              <Route
                path={getRoutePath("setup")}
                render={() => <SetupSubmenu reportingEnabled={reportingEnabled} />}
              />
              <Route path={getRoutePath("personalization")} component={PersonalizationSubmenu} />
              <Route
                path={getRoutePath("segments.export.detail")}
                component={SegmentExportLogSubmenu}
              />
              <Route
                path={getRoutePath("segments.smart.export.detail")}
                component={SegmentExportLogSubmenu}
              />
              <Route exact path={getRoutePath("segments")} component={SegmentsListSubmenu} />
              <Route exact path={getRoutePath("segments.smart")} component={SegmentsListSubmenu} />
              <Route
                exact
                path={getRoutePath("segments.featured")}
                component={SegmentsListSubmenu}
              />
              <Route exact path={getRoutePath("segments.tags")} component={SegmentsListSubmenu} />
              <Route
                path={getRoutePath("segments.featured.detail")}
                component={SegmentDetailSubmenu}
              />
              <Route
                path={getRoutePath("segments.smart.detail")}
                component={SegmentDetailSubmenu}
              />
              <Route path={getRoutePath("segments.detail")} component={SegmentDetailSubmenu} />
              <Route path={getRoutePath("data")} component={DataSubmenu} />
              <Route path={getRoutePath("reporting")} component={ReportingSubmenu} />
              <Route exact path={getRoutePath("customers")} component={CustomersListSubmenu} />
              <Route path={getRoutePath("customers.detail")} component={CustomerDetailSubmenu} />
              <Route path={getRoutePath("trash")} component={TrashSubmenu} />
              <Route path={getRoutePath("user-settings")} component={UserSettingsSubmenu} />
              <Route path={getRoutePath("not-found")} component={NotFoundSubmenu} />
              <Route path={getRoutePath("not-authorized")} component={NotAuthorizedSubmenu} />
              <Route exact path={getRoutePath("home")} component={HomePageSubmenu} />
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}

HeaderContainer.propTypes = {
  authenticatedUser: PropTypes.object.isRequired,
  reportingEnabled: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  return {
    authenticatedUser: state.authenticatedUser,
  }
}

export default withRouter(connect(mapStateToProps, { logout, modifyAuthUser })(HeaderContainer))
