import { Map, List, fromJS } from "immutable"
import _map from "lodash/map"
import _drop from "lodash/drop"
import _toString from "lodash/toString"
import ExportModel from "./segmentExportModel"
import SelectionSettings from "models/selectionSettings.model"
import { SEGMENT } from "./segmentExportActions"

/**
 * The store holds data only from one segment at the time
 *
 * filled state example:
 * Map({
 *  segmentId: Map({
 *    data: List({export}),
 *    selectionSettings: SelectionSettings model,
 *    hasMoreItems: false
 *  })
 * })
 */

const initialState = Map()

export default (state = initialState, { type, meta, payload }) => {
  switch (type) {
    case SEGMENT.EXPORT.ACTION.LIST:
    case `${SEGMENT.EXPORT.ACTION.LIST}_FULFILLED`: {
      if (state.has(meta.segmentId)) {
        // already fetched something or load more
        if (payload.segment_exports.length === 0) {
          return state.setIn([meta.segmentId, "hasMoreItems"], false)
        } else {
          // prevent duplicities
          let shift = 0
          for (let i = 0; i < payload.segment_exports.length; i++) {
            const segmentExport = payload.segment_exports[i]
            if (
              state
                .getIn([meta.segmentId, "data"])
                .findIndex(val => val.id === segmentExport.id) === -1
            ) {
              // haven't found, break with no shifting
              break
            }
            shift++
          }
          if (shift === SEGMENT.EXPORT.ITEMS_PER_PAGE && payload.selection_settings.offset === 0) {
            // this is renew call, but export ids remains unchanged, change only fetched items in list
            return state.setIn(
              [meta.segmentId, "data"],
              List(
                _map(
                  payload.segment_exports,
                  segmentExport => new ExportModel(fromJS(segmentExport)),
                ),
              ).concat(state.getIn([meta.segmentId, "data"]).skip(shift)),
            )
          } else if (payload.selection_settings.offset === 0) {
            // shift is different, call is with offset = 0, init the data again
            return state
              .setIn(
                [meta.segmentId, "data"],
                List(
                  _map(
                    payload.segment_exports,
                    segmentExport => new ExportModel(fromJS(segmentExport)),
                  ),
                ),
              )
              .setIn(
                [meta.segmentId, "selectionSettings"],
                new SelectionSettings(payload.selection_settings),
              )
              .setIn(
                [meta.segmentId, "hasMoreItems"],
                SEGMENT.EXPORT.ITEMS_PER_PAGE === payload.segment_exports.length,
              )
          }
          let selectionSettings = payload.selection_settings
          selectionSettings.offset = selectionSettings.offset + shift
          return state
            .setIn(
              [meta.segmentId, "data"],
              state
                .getIn([meta.segmentId, "data"])
                .concat(
                  _map(
                    _drop(payload.segment_exports, shift),
                    segmentExport => new ExportModel(fromJS(segmentExport)),
                  ),
                ),
            )
            .setIn([meta.segmentId, "selectionSettings"], new SelectionSettings(selectionSettings))
            .setIn(
              [meta.segmentId, "hasMoreItems"],
              payload.segment_exports.length === SEGMENT.EXPORT.ITEMS_PER_PAGE,
            )
        }
      } else {
        // store only one segment exports at the time => create new Map()
        return Map({
          [meta.segmentId]: Map({
            data: List(
              _map(
                payload.segment_exports,
                segmentExport => new ExportModel(fromJS(segmentExport)),
              ),
            ),
            selectionSettings: new SelectionSettings(payload.selection_settings),
            hasMoreItems: SEGMENT.EXPORT.ITEMS_PER_PAGE === payload.segment_exports.length,
          }),
        })
      }
    }
    case `${SEGMENT.EXPORT.ACTION.RETRIEVE}_FULFILLED`: {
      if (List.isList(state.getIn([_toString(payload.segment_id), "data"]))) {
        // try to find export in list
        const index = state
          .getIn([_toString(payload.segment_id), "data"])
          .findIndex(item => item.id === payload.id)
        if (index === -1) {
          return state
            .getIn([_toString(payload.segment_id), "data"])
            .unshift(new ExportModel(fromJS(payload)))
        } else {
          return state.setIn(
            [_toString(payload.segment_id), "data", index],
            new ExportModel(fromJS(payload)),
          )
        }
      } else {
        // store only one segment exports at the time => create new Map()
        // segment exports bucket doesn't exist yet
        return Map({
          [payload.segment_id]: Map({
            data: List([new ExportModel(fromJS(payload))]),
            selectionSettings: null,
            hasMoreItems: false,
          }),
        })
      }
    }
    case `${SEGMENT.EXPORT.ACTION.RUN}_FULFILLED`: {
      if (List.isList(state.getIn([meta.segmentId, "data"]))) {
        return state
          .setIn(
            [meta.segmentId, "data"],
            state
              .getIn([meta.segmentId, "data"])
              .unshift(new ExportModel(fromJS(payload.segment_export))),
          )
          .setIn(
            [meta.segmentId, "selectionSettings", "offset"],
            state.getIn([meta.segmentId, "selectionSettings", "offset"]) + 1,
          )
      } else {
        return Map({
          [meta.segmentId]: Map({
            data: List([new ExportModel(fromJS(payload.segment_export))]),
            selectionSettings: null,
            hasMoreItems: false,
          }),
        })
      }
    }
    default:
      return state
  }
}
