import _get from "lodash/get"

import { api } from "api"

export const GLOBAL_SETTINGS = {
  ACTION: {
    LIST: "GLOBAL_SETTINGS_LIST",
    MODIFY: "GLOBAL_SETTINGS_MODIFY",
  },
}

export const fetchGlobalSettingsList = () => async dispatch =>
  dispatch({
    type: GLOBAL_SETTINGS.ACTION.LIST,
    payload: {
      promise: (async () => {
        const response = await api.globalSettings.list()
        return _get(response, "global_settings", [])
      })(),
    },
  })

export const modifyGlobalSettingsItem =
  (globalSettingsId, data, updateRedux = false) =>
  async dispatch =>
    dispatch({
      type: GLOBAL_SETTINGS.ACTION.MODIFY,
      meta: {
        updateRedux,
      },
      payload: {
        promise: (async () => {
          const response = await api.globalSettings.modify(globalSettingsId, data)
          return _get(response, "global_setting")
        })(),
      },
    })
