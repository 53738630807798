import { GLOBAL_SETTINGS } from "./globalSettingsActions"
import { Map, Record, fromJS } from "immutable"
import _mapKeys from "lodash/mapKeys"
import _map from "lodash/map"
import _toString from "lodash/toString"

const GlobalSettings = Record({
  id: 0,
  // can be scalar or object
  value: null,
  key: "",
})

// settingsId: { settingsObject }
const initialState = Map({
  data: Map(),
  isFulfilled: false,
  // isFetching variable is set to true because it's loaded at the
  // app start from AuthenticatedLayout.js
  isFetching: true,
})

export default (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case `${GLOBAL_SETTINGS.ACTION.LIST}_PENDING`: {
      return state.set("isFetching", true)
    }
    case `${GLOBAL_SETTINGS.ACTION.LIST}_FULFILLED`:
      return state
        .set("isFulfilled", true)
        .set(
          "data",
          Map(
            _mapKeys(
              _map(payload, settingsItem => new GlobalSettings(fromJS(settingsItem))),
              "id",
            ),
          ),
        )
        .set("isFetching", false)
    case `${GLOBAL_SETTINGS.ACTION.MODIFY}_FULFILLED`:
      // we're refetching all settings resources, because some settings resource may
      // change on BE according to another settings resource change ... update state only
      // when meta.updateRedux is true
      if (meta.updateRedux) {
        return state.setIn(["data", _toString(payload.id)], new GlobalSettings(fromJS(payload)))
      }
      return state
    default:
      return state
  }
}
