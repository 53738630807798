import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect"
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper"
import connectedAuthWrapper from "redux-auth-wrapper/connectedAuthWrapper"
import { getRoutePath } from "routes"
import * as authenticatedUser from "helpers/authenticatedUser.helper"

const locationHelper = locationHelperBuilder({})

const userIsAuthenticatedDefaults = {
  authenticatedSelector: state => authenticatedUser.isLoggedIn(),
  authenticatingSelector: state => authenticatedUser.isFetching(),
  wrapperDisplayName: "UserIsAuthenticated",
}

export const userIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults)

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  redirectPath: getRoutePath("login"),
})

const userIsNotAuthenticatedDefaults = {
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: state =>
    state.authenticatedUser.token === "" && state.authenticatedUser.isFetching !== true,
  wrapperDisplayName: "UserIsNotAuthenticated",
}

export const userIsNotAuthenticated = connectedAuthWrapper(userIsNotAuthenticatedDefaults)

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...userIsNotAuthenticatedDefaults,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || getRoutePath("home"),
  allowRedirectBack: false,
})
