import {
  UsersActivityFiltersAction,
  USERS_ACTIVITY_SET_END_DATE,
  USERS_ACTIVITY_SET_FILTER_TYPE,
  USERS_ACTIVITY_SET_FILTER_VALUE,
  USERS_ACTIVITY_SET_START_DATE,
} from "actions/usersActivityFilters.action"
import { getISODateDaysAgo, getISODateToday } from "helpers/dateFormat.helper"
import { UsersActivityFilters } from "types/usersActivity"

type UsersActivityFiltersState = UsersActivityFilters

const initialState: UsersActivityFiltersState = {
  since: getISODateDaysAgo(6),
  until: getISODateToday(),
  filterType: "all_users",
  filterValue: [],
}

export default function usersActivityFilters(
  state = initialState,
  action: UsersActivityFiltersAction,
): UsersActivityFiltersState {
  switch (action.type) {
    case USERS_ACTIVITY_SET_START_DATE: {
      return { ...state, since: action.payload }
    }
    case USERS_ACTIVITY_SET_END_DATE: {
      return { ...state, until: action.payload }
    }
    case USERS_ACTIVITY_SET_FILTER_TYPE: {
      return { ...state, filterType: action.payload }
    }
    case USERS_ACTIVITY_SET_FILTER_VALUE: {
      return { ...state, filterValue: action.payload }
    }
    default: {
      return state
    }
  }
}
