import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { getDataSourcesArrayForSelect } from "resources/dataSource/dataSourceSelectors"
import SearchFormWithSelect, {
  SearchFormWithSelectDestroyable,
} from "components/UI/components/SearchFormWithSelect"

class EventsFilterForm extends PureComponent {
  render() {
    const { sources, destroyOnUnmount = true, formName = "SearchEventForm" } = this.props
    if (destroyOnUnmount) {
      return (
        <SearchFormWithSelectDestroyable
          form={formName}
          inputPlaceholder="Search for events"
          selectOptions={sources}
          selectPlaceholder="All sources"
        />
      )
    } else {
      return (
        <SearchFormWithSelect
          form={formName}
          inputPlaceholder="Search for events"
          selectOptions={sources}
          selectPlaceholder="All sources"
        />
      )
    }
  }
}

EventsFilterForm.propTypes = {
  sources: PropTypes.array.isRequired,
  destroyOnUnmount: PropTypes.bool,
  formName: PropTypes.string,
  showHiddenSources: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => {
  const { showHiddenSources = false } = ownProps
  return {
    sources: getDataSourcesArrayForSelect(state, showHiddenSources),
  }
}

EventsFilterForm = connect(mapStateToProps)(EventsFilterForm)

export default EventsFilterForm
