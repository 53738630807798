import Modal from "components/UI/elements/Modal"
import React from "react"
import styles from "./CopyModal.module.scss"
import Button from "components/UI/elements/Button/Button"
import { SegmentType } from "resources/segment/segment/segmentTypes"
import { hasAccess } from "helpers/authenticatedUser.helper"
import classnames from "classnames"

type CopyModalProps = {
  open: boolean
  handleClose: () => void
  onCopyToFeaturedClick: () => void
  onCopyClick: () => void
  type: SegmentType
  copyingToFeatured: boolean
  copying: boolean
}

export default function CopyModal({
  open,
  handleClose,
  onCopyToFeaturedClick,
  copyingToFeatured,
  onCopyClick,
  copying,
  type,
}: CopyModalProps) {
  return (
    <Modal open={open} handleClose={handleClose} title="Copy segment" className={styles.modal}>
      <p>Do you really want to copy current segment?</p>
      <div className={styles.actionButtons}>
        <Button
          color="transparent-grey"
          className={styles.cancelButton}
          size="big"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <div className={styles.right}>
          <Button
            color="white"
            className={classnames(styles.secondaryActionButton, { loading: copyingToFeatured })}
            size="big"
            onClick={onCopyToFeaturedClick}
            disabled={
              !hasAccess.segments.featured.edit() || !["regular", "featured"].includes(type)
            }
          >
            Copy to featured segments
          </Button>
          <Button
            color="primary"
            className={classnames(styles.primaryActionButton, { loading: copying })}
            size="big"
            onClick={onCopyClick}
            disabled={!hasAccess.segments.create()}
          >
            Copy to custom segments
          </Button>
        </div>
      </div>
    </Modal>
  )
}
