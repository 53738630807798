import React, { ComponentProps } from "react"
import PropTypes from "prop-types"

import "./Button.scss"
import classNames from "classnames"

// TODO: fix color, should be enum
type ButtonProps = {
  color?: string
  fullWidth?: boolean
  size?: "tag" | "small" | "medium" | "big" | "large"
  isLoading?: boolean
} & ComponentProps<"button">

const Button = ({
  children,
  color,
  fullWidth,
  size,
  className,
  type = "button",
  isLoading,
  ...buttonAttrs
}: ButtonProps) => (
  <button
    {...buttonAttrs}
    type={type}
    className={classNames("button", className, color, size, {
      "full-width": fullWidth,
      shrinked: !fullWidth,
      loading: isLoading,
    })}
  >
    <span>{children}</span>
  </button>
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default Button
