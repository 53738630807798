import _toString from "lodash/toString"

export const SEGMENT = {
  CUSTOMER: {
    ACTION: {
      INIT: "INIT_SEGMENT_CUSTOMERS",
      STORE: "STORE_SEGMENT_CUSTOMERS",
      ERROR: "SEGMENT_CUSTOMERS_ERROR",
    },
  },
}

export const initSegmentCustomers =
  (segmentId, forceReload = false) =>
  dispatch =>
    dispatch({
      type: SEGMENT.CUSTOMER.ACTION.INIT,
      meta: {
        segmentId: _toString(segmentId),
        forceReload,
      },
    })

export const storeSegmentCustomers = (segmentId, data) => dispatch =>
  dispatch({
    type: SEGMENT.CUSTOMER.ACTION.STORE,
    meta: {
      segmentId: _toString(segmentId),
    },
    payload: {
      ...data,
    },
  })

export const dispatchSegmentCustomersError = segmentId => dispatch =>
  dispatch({
    type: SEGMENT.CUSTOMER.ACTION.ERROR,
    meta: { segmentId: _toString(segmentId) },
  })
