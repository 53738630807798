import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import _random from "lodash/random"
import _isFunction from "lodash/isFunction"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Button from "components/UI/elements/Button/Button"

import MeiroLabyrinthSrc from "images/meiro-labyrinth.svg"
import "./WholePageLoading.scss"

export const messages = [
  "From data maze to data a-maze-ing",
  "Churning out magic, won't be long!",
  "Finding the right answer to the right question.",
  "Generating new conclusions for you.",
]

class WholePageLoading extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      message: messages[_random(0, messages.length - 1)],
    }
  }

  render() {
    const { loadingError, errorCallback, customMessage, fullHeight, customErrorMessage, children } =
      this.props
    return (
      <div
        className={`whole-page-loading ${fullHeight ? "full-height" : ""}`}
        data-testid="whole-page-loading"
      >
        <div
          className={`loading-content ${loadingError ? "error" : ""}`}
          data-testid="loading-content"
        >
          <img src={MeiroLabyrinthSrc} alt="meiro labyrinth" />
          <h2 data-testid="title">
            {!loadingError && customMessage === null && this.state.message}
            {!loadingError && customMessage !== null && customMessage}
            {loadingError && customErrorMessage === null && "Error while loading"}
            {loadingError && customErrorMessage !== null && customErrorMessage}
          </h2>
          {_isFunction(errorCallback) && loadingError && (
            <Button
              color="primary"
              size="medium"
              onClick={errorCallback}
              data-testid="action-button"
            >
              <FontAwesomeIcon icon={["far", "sync-alt"]} /> Reload
            </Button>
          )}
          {children}
        </div>
      </div>
    )
  }
}

WholePageLoading.defaultProps = {
  loadingError: false,
  customMessage: null,
  customErrorMessage: null,
  fullHeight: false,
  children: null,
}

WholePageLoading.propTypes = {
  loadingError: PropTypes.bool,
  errorCallback: PropTypes.func,
  customMessage: PropTypes.string,
  fullHeight: PropTypes.bool,
  customErrorMessage: PropTypes.string,
  children: PropTypes.node,
}

export default WholePageLoading
