import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import _noop from "lodash/noop"
import _get from "lodash/get"
import _toLower from "lodash/toLower"
import _toString from "lodash/toString"
import moment from "moment"
import { Map } from "immutable"
import { getFormValues } from "redux-form"
import TimeAgo from "react-timeago"
import Select from "react-select"

// ui components
import Avatar from "components/UI/elements/Avatar"
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import ToggleButton from "components/UI/elements/ToggleButton"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import Button from "components/UI/elements/Button/Button"
import CreateUserModal from "./CreateUserModal/CreateUserModal"
import ConfirmModal from "components/UI/components/ConfirmModal"
import SearchForm from "components/UI/components/SearchForm"
import { DropdownIndicator } from "components/UI/elements/SelectField"
import Table, { Thead, Th, Tbody, Td, Tr, SortButton } from "components/UI/elements/Table"

// actions
import { fetchUsersList, modifyUser, deleteUser } from "resources/user/userActions"
import { showToast } from "actions/toast.action"
import { setSortingOptions } from "actions/table.action"

// selectors
import { getUsersData } from "resources/user/userSelectors"

// helpers, constants
import { TOAST, MODAL } from "sharedConstants"
import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { selectStyles } from "helpers/customSelectStyle.helper"
import { getRoutePath } from "routes"
import { copyStringToClipboard } from "helpers/string.helper"

import "./UsersList.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"

class UsersList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      createUserModalOpen: false,
      deleteModal: {
        open: false,
        item: null,
      },
      resendingInvitation: false,
      roles: null,
      copyingLink: false,
      isLoading: true,
    }
  }

  componentDidMount() {
    this.props.fetchUsersList().catch(_noop)
    this.fetchAllRoles()
  }

  fetchAllRoles = async () => {
    const caller = new AllResourceItemsFetcher()
    try {
      const data = await caller
        .setEndpointCall((offset, limit) => api.userRole.list(offset, limit))
        .setDataPath("roles")
        .run()
      this.setState({
        roles: data
          .filter(role => role.id !== 2)
          .map(role => ({ label: role.name, value: role.id, features: role.features })),
      })
    } catch {
    } finally {
      this.setState({ isLoading: false })
    }
  }

  changeUserRole = userId => evt => {
    const { modifyUser, showToast } = this.props
    modifyUser(userId, { role_id: evt.value })
      .then(response => {
        showToast(`User role edited.`, TOAST.TYPE.SUCCESS)
      })
      .catch(_noop)
  }

  toggleUsersDisabled = user => () => {
    const { modifyUser, showToast } = this.props
    modifyUser(user.id, {
      disabled: user.disabled ? 0 : 1,
    })
      .then(response => {
        showToast(`User ${response.value.disabled ? "disabled" : "enabled"}.`, TOAST.TYPE.SUCCESS)
      })
      .catch(_noop)
  }

  deleteUser = () => {
    const { deleteModal } = this.state
    const { deleteUser, showToast } = this.props
    deleteUser(deleteModal.item.id)
      .then(() => {
        showToast(`User deleted.`, TOAST.TYPE.SUCCESS)
      })
      .catch(_noop)
    this.closeDeleteUserModal()
  }

  openDeleteUserModal = user => () => {
    this.setState({
      deleteModal: {
        open: true,
        item: user,
      },
    })
  }

  closeDeleteUserModal = () => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal,
        open: false,
      },
    })
  }

  toggleCreateUserModal = () => {
    this.setState(prevState => ({
      createUserModalOpen: !prevState.createUserModalOpen,
    }))
  }

  resendInvitation = (name, email) => () => {
    const { showToast } = this.props
    const { resendingInvitation } = this.state
    if (!resendingInvitation) {
      this.setState({ resendingInvitation: true })
      api.user
        .resendInvitation(email)
        .then(() => {
          showToast(`User re-invited.`, TOAST.TYPE.SUCCESS)
        })
        .catch()
        .finally(() => {
          this.setState({ resendingInvitation: false })
        })
    }
  }

  sortUsersBy = column => () => {
    const { filterValues, setSortingOptions } = this.props
    const orderBy = _get(filterValues, "orderBy")
    const orderDir = _get(filterValues, "orderDir", "DESC")

    if (orderBy === column) {
      // switch orderDir
      setSortingOptions("UsersSearch", column, orderDir === "ASC" ? "DESC" : "ASC")
    } else {
      setSortingOptions("UsersSearch", column, "ASC")
    }
  }

  goToUser = user => () => {
    this.props.history.push(getRoutePath("administration.users.detail", { id: user.id }))
  }

  copyInvitationLink = userId => async () => {
    try {
      this.setState({
        copyingLink: true,
      })
      const response = await api.user.getInvitationLink(userId)
      const link = response.user_invitation_link
      copyStringToClipboard(link)
      this.setState({
        copyingLink: false,
      })
      this.props.showToast("Copied to clipboard.", TOAST.TYPE.SUCCESS)
    } catch (err) {
      this.setState({
        copyingLink: false,
      })
    }
  }

  render() {
    const { filterValues, users } = this.props
    const { createUserModalOpen, deleteModal, resendingInvitation, roles, copyingLink, isLoading } =
      this.state
    const searchTerm = _get(filterValues, "search", "")
    const orderBy = _get(filterValues, "orderBy", "name")
    const orderDir = _get(filterValues, "orderDir", "ASC")

    const usersFiltered = searchTerm
      ? users.filter(
          user =>
            user.id !== 2 &&
            (_toLower(user.name).includes(_toLower(searchTerm)) ||
              _toLower(user.email).includes(_toLower(searchTerm))),
        )
      : users.filter(user => user.id !== 2)

    const usersSorted = usersFiltered.toList().sortBy(
      user =>
        _toLower(orderBy === "role" ? _toString(user[orderBy].name) : _toString(user[orderBy])),
      (a, b) => {
        if (orderDir === "ASC") {
          return a.localeCompare(b)
        } else {
          return -a.localeCompare(b)
        }
      },
    )

    return (
      <section className="wrapper users">
        <PaperHeader className="users-list-header" size="small">
          <h3 className="title">Users</h3>
          <div className="search-and-buttons">
            <SearchForm
              placeholder="Search for users"
              className="users-search"
              initialValues={{ search: "", orderBy: "name", orderDir: "ASC" }}
              form="UsersSearch"
            />
            <div className="buttons-right">
              <Button
                onClick={() => this.toggleCreateUserModal("user")}
                color="primary"
                size="small"
              >
                + Create user
              </Button>
            </div>
          </div>
        </PaperHeader>
        <Paper hasHeader noPaddingTop>
          {isLoading && <LoadingIndicator className="loading-indicator" />}
          {!isLoading && (
            <Table className="admin-users">
              <Thead stickyHeader>
                <Th className="gravatar">&nbsp;</Th>
                <Th className="user-name">
                  <SortButton
                    column="name"
                    orderBy={orderBy}
                    orderDir={orderDir}
                    onClick={this.sortUsersBy("name")}
                    label="Name"
                  />
                </Th>
                <Th className="email-col">
                  <SortButton
                    column="email"
                    orderBy={orderBy}
                    orderDir={orderDir}
                    onClick={this.sortUsersBy("email")}
                    label="Email"
                  />
                </Th>
                <Th textAlignRight className="last-login">
                  <SortButton
                    column="last_login"
                    orderBy={orderBy}
                    orderDir={orderDir}
                    onClick={this.sortUsersBy("last_login")}
                    label="Last login"
                  />
                </Th>
                <Th textAlignRight className="role-column">
                  <SortButton
                    column="role"
                    orderBy={orderBy}
                    orderDir={orderDir}
                    onClick={this.sortUsersBy("role")}
                    label="Role"
                  />
                </Th>
                <Th textAlignRight className="toggle-column">
                  <SortButton
                    column="disabled"
                    orderBy={orderBy}
                    orderDir={orderDir}
                    onClick={this.sortUsersBy("disabled")}
                    label="Enabled"
                  />
                </Th>
                <Th className="action-column two-icon">&nbsp;</Th>
              </Thead>
              <Tbody>
                {usersSorted
                  .map(user => (
                    <Tr key={user.id} className={user.disabled ? "disabled-user" : ""}>
                      <Td className="gravatar user-table-cell">
                        <div className="clickable-avatar" onClick={this.goToUser(user)}>
                          <Avatar
                            name={user.name}
                            email={user.email}
                            disabledLook={user.disabled === 1}
                          />
                        </div>
                      </Td>
                      <Td className="user-table-cell" textBlack textBigger textBold>
                        <span
                          className="link-hover"
                          onClick={this.goToUser(user)}
                          data-testid="td-name"
                        >
                          {user.name}
                        </span>
                      </Td>
                      <Td className="email-col user-table-cell">{user.email}</Td>
                      <Td
                        textAlignRight
                        className={`last-login user-table-cell ${!user.last_login ? "never" : ""}`}
                      >
                        {user.last_login && (
                          <TimeAgo
                            date={moment.utc(user.last_login).local().format("YYYY-MM-DD HH:mm:ss")}
                          />
                        )}
                        {!user.last_login && (
                          <React.Fragment>
                            <div>Never,</div>
                            <span
                              className={`resend-inv ${resendingInvitation ? "sending" : ""}`}
                              onClick={this.resendInvitation(user.name, user.email)}
                            >
                              Resend invitation
                            </span>{" "}
                            <span>or</span>{" "}
                            <span
                              className={`copy-inv-link ${copyingLink ? "copying" : ""}`}
                              onClick={this.copyInvitationLink(user.id)}
                            >
                              Copy invitation link
                            </span>
                          </React.Fragment>
                        )}
                      </Td>
                      <Td className="role-column user-table-cell">
                        <Select
                          value={roles ? roles.find(role => role.value === user.role.id) : null}
                          onChange={this.changeUserRole(user.id)}
                          options={roles === null ? [] : roles}
                          styles={selectStyles("small")}
                          simpleValue
                          isSearchable={true}
                          isLoading={roles === null}
                          className="select-input"
                          components={{
                            DropdownIndicator: DropdownIndicator,
                          }}
                          classNamePrefix="role_select"
                          noOptionsMessage={() => "Empty"}
                        />
                      </Td>
                      <Td textAlignRight className="toggle-column user-table-cell">
                        <ToggleButton
                          value={!user.disabled}
                          handleToggle={this.toggleUsersDisabled(user)}
                        />
                      </Td>
                      <Td textAlignRight className="action-column two-icon user-table-cell">
                        <IconButton
                          color={COLOR.BLACK}
                          onClick={this.goToUser(user)}
                          size={SIZE.TAG}
                          withBackground
                          iconName="user-edit"
                          iconStyle="far"
                          tooltip="Edit"
                        />
                        <IconButton
                          color={COLOR.RED}
                          onClick={this.openDeleteUserModal(user)}
                          size={SIZE.TAG}
                          className="trash"
                          withBackground
                          iconName="trash-alt"
                          tooltip="Delete"
                        />
                      </Td>
                    </Tr>
                  ))
                  .toArray()}
              </Tbody>
            </Table>
          )}
        </Paper>
        <CreateUserModal
          open={createUserModalOpen}
          handleClose={this.toggleCreateUserModal}
          initialValues={{ send_invitation_emails: true }}
          roles={roles}
        />
        <ConfirmModal
          open={deleteModal.open}
          type={MODAL.TYPE.DELETE}
          handleClose={this.closeDeleteUserModal}
          handleConfirm={this.deleteUser}
          title="Delete user"
          action="delete"
          what="user"
          item={_get(deleteModal, "item.name", "")}
        />
      </section>
    )
  }
}

const mapStateToProps = state => {
  return {
    users: getUsersData(state),
    filterValues: getFormValues("UsersSearch")(state),
  }
}

UsersList.propTypes = {
  users: PropTypes.instanceOf(Map).isRequired,
  fetchUsersList: PropTypes.func.isRequired,
  modifyUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  setSortingOptions: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  fetchUsersList,
  modifyUser,
  deleteUser,
  showToast,
  setSortingOptions,
})(UsersList)
