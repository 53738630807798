import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import { hasAccess } from "helpers/authenticatedUser.helper"
import React from "react"
import { Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import CustomerDetail from "./CustomerDetail/CustomerDetail"
import CustomersList from "./CustomersList/CustomersList"

export default function Customers() {
  return (
    <Switch>
      <AuthorizedRoute
        hasAccess={hasAccess.customers.search()}
        path={getRoutePath("customers")}
        exact
        component={CustomersList}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.customers.detail()}
        path={getRoutePath("customers.detail")}
        component={CustomerDetail}
      />
    </Switch>
  )
}
