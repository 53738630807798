import SearchField from "components/UI/elements/SearchField"
import SelectField from "components/UI/elements/SelectField"
import useDebounce from "hooks/useDebounce"
import { useEffect, useState } from "react"
import { sort, prop, descend } from "ramda"
import styles from "./TrashFilterForm.module.scss"
import getTrashFilterOptions, {
  MAX_SELECTION_PRIORITY,
  TrashFilterOption,
} from "./getTrashFilterOptions"
import { TrashItemType } from "pages/Trash/trashTypes"
type TrashFilterFormProps = {
  onChange: (trashFilterOption: TrashFilterOption, searchText: string) => void
  itemsCounts: Record<TrashItemType, number> | null
}
export default function TrashFilterForm({ onChange, itemsCounts }: TrashFilterFormProps) {
  const [selectedOption, setSelectedOption] = useState<TrashFilterOption | null>(null)
  const [trashFilterOptions, setTrashFilterOptions] = useState<TrashFilterOption[]>([])
  const [searchText, setSearchText] = useState("")
  const debouncedSearchTerm = useDebounce(searchText)

  useEffect(() => {
    if (selectedOption) {
      onChange(selectedOption, debouncedSearchTerm)
    }
  }, [debouncedSearchTerm, onChange, selectedOption])

  useEffect(() => {
    if (itemsCounts) {
      const trashFilterOptions = getTrashFilterOptions(itemsCounts)
      setTrashFilterOptions(trashFilterOptions)
      if (selectedOption) {
        // workaround to update label (count) of selected trash item after restore action
        const newSelectedOption = trashFilterOptions.find(
          option => option.value === selectedOption.value,
        )
        if (newSelectedOption && newSelectedOption.label !== selectedOption.label) {
          setSelectedOption(newSelectedOption)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsCounts])

  useEffect(() => {
    if (itemsCounts && trashFilterOptions.length > 0 && !selectedOption) {
      const defaultOption =
        sort(descend(prop("selectionPriority")), trashFilterOptions).find(
          option => itemsCounts[option.value] !== 0,
        ) ?? trashFilterOptions.find(option => option.selectionPriority === MAX_SELECTION_PRIORITY)!
      setSearchText("")
      setSelectedOption(defaultOption)
    }
  }, [itemsCounts, selectedOption, trashFilterOptions])

  return (
    <div className={styles.trashFilterForm}>
      <SelectField
        input={{ value: selectedOption, onChange: setSelectedOption }}
        options={trashFilterOptions}
        className={styles.itemTypeSelect}
        isLoading={itemsCounts === null}
        placeholder=""
        inputId="trash-filter"
      />
      <SearchField
        input={{ value: searchText, onChange: setSearchText }}
        placeholder="Search for name"
        onClear={() => setSearchText("")}
        fullWidth={false}
        autoFocus
        size="medium"
        wrapperClassName={styles.searchFieldWrapper}
      />
    </div>
  )
}
