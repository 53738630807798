import { Map, List } from "immutable"
import _map from "lodash/map"

import SelectionSettings from "models/selectionSettings.model"
import CustomerEntity from "models/customerEntity.model"
import { SEGMENT } from "./segmentCustomersActions"

/**
 * The store holds data only from one segment at the time
 *
 * filled state example:
 * Map({
 *  segmentId: Map({
 *    fetching: true,
 *    data: List({export}),
 *    selectionSettings: SelectionSettings model,
 *    hasMoreItems: false
 *  })
 * })
 */

const initialState = Map()

export default (state = initialState, { type, meta, payload }) => {
  switch (type) {
    case SEGMENT.CUSTOMER.ACTION.INIT: {
      if (state.has(meta.segmentId)) {
        return state
          .setIn([meta.segmentId, "fetching"], true)
          .setIn(
            [meta.segmentId, "data"],
            meta.forceReload ? null : state.getIn([meta.segmentId, "data"]),
          )
      } else {
        // store only one segment customers at the time => create new Map()
        return Map({
          [meta.segmentId]: Map({
            fetching: true,
            error: false,
          }),
        })
      }
    }
    case SEGMENT.CUSTOMER.ACTION.ERROR: {
      return state.setIn(
        [meta.segmentId],
        Map({
          fetching: false,
          error: true,
        }),
      )
    }
    case SEGMENT.CUSTOMER.ACTION.STORE: {
      if (state.getIn([meta.segmentId, "data"], null) !== null) {
        if (payload.customer_entities === null) {
          // pivot table not ready yet
          return state
            .setIn([meta.segmentId, "hasMoreItems"], false)
            .setIn([meta.segmentId, "fetching"], false)
            .setIn([meta.segmentId, "data"], null)
            .setIn(
              [meta.segmentId, "selectionSettings"],
              new SelectionSettings(payload.selection_settings),
            )
        }

        // already fetched something
        if (payload.customer_entities.length === 0 && payload.selection_settings.offset !== 0) {
          return state
            .setIn([meta.segmentId, "hasMoreItems"], false)
            .setIn([meta.segmentId, "fetching"], false)
        } else {
          if (payload.selection_settings.offset === 0) {
            // test duplicities first, if everything remains same, do nothing
            let changed =
              payload.customer_entities?.length !== state.getIn([meta.segmentId, "data"])?.length
            if (state.getIn([meta.segmentId, "data"])) {
              for (let i = 0; i < payload.customer_entities.length; i++) {
                const customerEntity = payload.customer_entities[i]
                if (
                  state
                    .getIn([meta.segmentId, "data"])
                    .findIndex(val => val.id === customerEntity.id) !== i
                ) {
                  changed = true
                }
              }
            }
            if (changed) {
              return Map({
                [meta.segmentId]: Map({
                  data: List(
                    _map(
                      payload.customer_entities,
                      customerEntity => new CustomerEntity(customerEntity),
                    ),
                  ),
                  selectionSettings: new SelectionSettings(payload.selection_settings),
                  hasMoreItems:
                    SEGMENT.CUSTOMER.ITEMS_PER_PAGE === payload.customer_entities.length,
                  fetching: false,
                }),
              })
            } else {
              return state
            }
          } else {
            // concat
            return state
              .setIn(
                [meta.segmentId, "data"],
                state
                  .getIn([meta.segmentId, "data"])
                  .concat(
                    _map(
                      payload.customer_entities,
                      customerEntity => new CustomerEntity(customerEntity),
                    ),
                  ),
              )
              .setIn(
                [meta.segmentId, "selectionSettings"],
                new SelectionSettings(payload.selection_settings),
              )
              .setIn(
                [meta.segmentId, "hasMoreItems"],
                SEGMENT.CUSTOMER.ITEMS_PER_PAGE === payload.customer_entities.length,
              )
              .setIn([meta.segmentId, "fetching"], false)
          }
        }
      } else {
        return state.set(
          meta.segmentId,
          Map({
            fetching: false,
            data: payload.customer_entities
              ? List(
                  _map(
                    payload.customer_entities,
                    customerEntity => new CustomerEntity(customerEntity),
                  ),
                )
              : null,
            selectionSettings: new SelectionSettings(payload.selection_settings),
            hasMoreItems: payload.customer_entities
              ? SEGMENT.CUSTOMER.ITEMS_PER_PAGE === payload.customer_entities.length
              : false,
          }),
        )
      }
    }
    default:
      return state
  }
}
