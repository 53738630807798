import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import _trim from "lodash/trim"

// helpers
import { getGravatarImgUrl } from "helpers/gravatar.helper"

// default image placeholder, meiro symbol
import placeholder from "images/default-avatar-bg.svg"

// css
import "./Avatar.scss"

class Avatar extends PureComponent {
  renderInicials = () => {
    const nameSplit = _trim(this.props.name).split(" ")

    let inicials
    if (nameSplit.length > 1) {
      inicials = `${nameSplit[0][0]}${nameSplit[nameSplit.length - 1][0]}`
    } else {
      inicials = nameSplit[0][0]
    }

    return <span className="inicials">{inicials}</span>
  }

  render() {
    const { className, email, gravatarSize, disabledLook } = this.props

    return (
      <div
        className={`avatar-wrapper ${className ? className : ""} ${
          disabledLook ? "disabled-look" : ""
        }`}
      >
        {
          <React.Fragment>
            <img className="avatar-image" src={placeholder} alt="" />
            {this.renderInicials()}
          </React.Fragment>
        }
        <img
          className="avatar-image"
          src={getGravatarImgUrl(email, gravatarSize ? gravatarSize : 100)}
          alt=""
          data-testid="gravatar"
        />
        {disabledLook && <div className="disabled-overlay" />}
      </div>
    )
  }
}

Avatar.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  gravatarSize: PropTypes.number,
  disabledLook: PropTypes.bool,
}

export default Avatar
