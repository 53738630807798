import React from "react"
import PropTypes from "prop-types"

import "./LoadingIndicator.scss"

const LoadingIndicator = ({ className = "" }) => (
  <div className={`loading-indicator ${className}`} />
)

LoadingIndicator.propTypes = {
  className: PropTypes.string,
}

export default LoadingIndicator
