import notification from "./notification/notificationEndpoints"
import embeddedWebBanner from "./webBanner/embeddedWebBanner/embeddedWBEndpoints"
import popupWebBanner from "./webBanner/popupWebBanner/popupWBEndpoints"
import promoCodesList from "./promoCodesList/promoCodesListEndpoints"
import dataSource from "./dataSource/dataSourceEndpoints"
import tag from "./tag/tagEndpoints"
import report from "./report/reportEndpoints"
import exportDestination from "./exportDestination/exportDestinationEndpoints"
import { segment, featuredSegment, smartSegment } from "./segment/segment/segmentEndpoints"
import aggregations from "./segment/segmentAggregation/segmentAggregationEndpoints"
import exportEndpoints from "./segment/segmentExport/segmentExportEndpoints"
import note from "./segment/segmentNote/segmentNoteEndpoints"
import trash from "./trash/trashEndpoints"
import userRole from "./userRole/userRoleEndpoints"
import user from "./user/userEndpoints"
import attribute from "./attribute/attributeEndpoints"
import globalSettings from "./globalSettings/globalSettingsEndpoints"

const api = {
  attribute,
  notification,
  embeddedWebBanner,
  popupWebBanner,
  promoCodesList,
  dataSource,
  tag,
  report,
  exportDestination,
  segment: {
    ...segment,
    aggregations,
    export: exportEndpoints,
    note,
  },
  featuredSegment,
  smartSegment,
  trash,
  userRole,
  user,
  globalSettings,
}

export default api
