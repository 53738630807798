import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import UserDetail from "./UserDetail/UserDetail"
import UsersList from "./UsersList/UsersList"

export default function Users() {
  return (
    <Switch>
      <Route path={getRoutePath("administration.users")} exact component={UsersList} />
      <Route path={getRoutePath("administration.users.detail")} component={UserDetail} />
    </Switch>
  )
}
