import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import ReportCreate from "./ReportCreate/ReportCreate"
import ReportDetail from "./ReportDetail/ReportDetail"
import ReportsList from "./ReportsList/ReportsList"

export default function ReportsSetup() {
  return (
    <Switch>
      <Route path={getRoutePath("setup.reports")} exact component={ReportsList} />
      <Route path={getRoutePath("setup.reports.create")} component={ReportCreate} />
      <Route path={getRoutePath("setup.reports.detail")} component={ReportDetail} />
    </Switch>
  )
}
