import React, { Component } from "react"
import Paper from "components/UI/elements/Paper"
import { Record } from "immutable"
import PropTypes from "prop-types"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import SourceForm from "../components/SourceForm/SourceForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import { connect } from "react-redux"
import { isDataSourcesFulfilled, getDataSourceById } from "resources/dataSource/dataSourceSelectors"
import { modifyDataSource } from "resources/dataSource/dataSourceActions"
import { showToast } from "actions/toast.action"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fetchEventsList } from "actions/event.action"

import "./SourceDetail.scss"
import { refetchAttributes } from "resources/attribute/attributeQueries"
class SourceDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updating: false,
    }
  }

  updateSource = async data => {
    if (!this.state.updating) {
      this.setState({ updating: true })
      const { history, modifyDataSource, source, showToast } = this.props
      try {
        await modifyDataSource(source.id, data)
        showToast("Source edited.")
        history.push(getRoutePath("setup.sources"))
      } catch {
        this.setState({ updating: false })
      }
    }
  }

  toggleHiddenAttribute = async () => {
    const { source, modifyDataSource, showToast, fetchEventsList } = this.props
    await modifyDataSource(source.id, { is_hidden: source.is_hidden === 1 ? 0 : 1 })
    refetchAttributes()
    fetchEventsList(1)
    showToast(`Source ${source.is_hidden ? "visible" : "hidden"}.`)
  }

  cloneSource = () => {
    const { history, source } = this.props
    history.push({
      pathname: getRoutePath("setup.sources.create"),
      state: { source },
    })
  }

  render() {
    const { history, isDataSourcesFulfilled, source } = this.props
    const { updating } = this.state

    const formInitValues = {}
    if (source) {
      formInitValues.id = source.id
      formInitValues.name = source.name
      formInitValues.type = source.type
      formInitValues.description = source.description
      formInitValues.icon = source.getIn(["frontend_settings", "icon"])
        ? source.getIn(["frontend_settings", "icon"])
        : source.type
      formInitValues.color = source.getIn(["frontend_settings", "color"])
        ? source.getIn(["frontend_settings", "color"])
        : "primary"
    }

    return (
      <section className="detail-source wrapper">
        <PaperHeader size="small" titleText="Edit source" className="detail-source-header">
          <div className="header-buttons">
            {source && (
              <>
                <Button size="small" color="white-red" onClick={this.toggleHiddenAttribute}>
                  {source.is_hidden === 1 && (
                    <React.Fragment>
                      <FontAwesomeIcon icon={["far", "eye"]} className="icon" /> Show
                    </React.Fragment>
                  )}
                  {source.is_hidden === 0 && (
                    <React.Fragment>
                      <FontAwesomeIcon icon={["far", "eye-slash"]} className="icon" /> Hide
                    </React.Fragment>
                  )}
                </Button>
                <Button size="small" color="white" onClick={this.cloneSource}>
                  <FontAwesomeIcon icon={["fas", "clone"]} className="icon" /> Copy
                </Button>
              </>
            )}
            <Button
              size="small"
              color="white"
              onClick={() => {
                history.push(getRoutePath("setup.sources"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton isLoading={updating} formName="SourceForm" />
          </div>
        </PaperHeader>
        {isDataSourcesFulfilled && (
          <Paper hasHeader className="detail-source-content">
            <SourceForm
              onSubmit={this.updateSource}
              initialValues={formInitValues}
              disabledFields={["id"]}
            />
          </Paper>
        )}
      </section>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  source: getDataSourceById(state, ownProps.match.params.id),
  isDataSourcesFulfilled: isDataSourcesFulfilled(state),
})

SourceDetail.propTypes = {
  isDataSourcesFulfilled: PropTypes.bool.isRequired,
  source: PropTypes.instanceOf(Record),
  modifyDataSource: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  fetchEventsList: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  modifyDataSource,
  showToast,
  fetchEventsList,
})(SourceDetail)
