import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./Help.module.scss"

export default function Help() {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    setVisible(!visible)
  }

  return (
    <React.Fragment>
      <button className={styles.helpButton} onClick={toggleVisible}>
        <FontAwesomeIcon icon={visible ? ["far", "times"] : ["far", "question"]} />
      </button>
      {visible && (
        <div className={styles.helpBox}>
          <p>Cmd/Ctrl + scroll to zoom in/out</p>
          <p>Click on the edge to see connection details</p>
        </div>
      )}
    </React.Fragment>
  )
}
