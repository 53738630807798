import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import DestinationDetail from "./DestinationDetail/DestinationDetail"
import DestinationsList from "./DestinationsList/DestinationsList"

export default function DestinationsSetup() {
  return (
    <Switch>
      <Route path={getRoutePath("setup.destinations")} exact component={DestinationsList} />
      <Route path={getRoutePath("setup.destinations.detail")} component={DestinationDetail} />
    </Switch>
  )
}
