import Modal from "components/UI/elements/Modal"
import { useCallback, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { PromoCodesList } from "resources/promoCodesList/promoCodesListTypes"
import TextField from "components/UI/elements/TextField/HookFormTextField"
import styles from "./CreatePromoCodesListModal.module.scss"
import { maxFileSize, required, requiredFile } from "helpers/validators.helper"
import FileField from "components/UI/elements/FileField/FileField"
import Button from "components/UI/elements/Button/Button"
import classnames from "classnames"
import DropCsvFile from "../DropCsvFile/DropCsvFile"
import { useDispatch } from "react-redux"
import { showToast } from "actions/toast.action"
import { TOAST } from "sharedConstants"
import { MAX_UPLOADED_FILE_SIZE } from "pages/Personalization/PromoCodes/PromoCodesLists/PromoCodesLists"

export type CreatePromoCodesListFormValues = {
  name: PromoCodesList["name"]
  file: FileList
}

type CreatePromoCodesListModalProps = {
  open: boolean
  handleClose: () => void
  onSubmit: (data: CreatePromoCodesListFormValues) => void
}

export default function CreatePromoCodesListModal({
  open,
  handleClose,
  onSubmit,
}: CreatePromoCodesListModalProps) {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm<CreatePromoCodesListFormValues>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const closeModal = useCallback(() => {
    handleClose()
    reset()
  }, [handleClose, reset])

  const submitForm: SubmitHandler<CreatePromoCodesListFormValues> = useCallback(
    async ({ name, file }) => {
      if (!isSubmitting) {
        setIsSubmitting(true)
        try {
          await onSubmit({ name, file })
          setIsSubmitting(false)
          closeModal()
        } catch (err: any) {
          setIsSubmitting(false)
        }
      }
    },
    [isSubmitting, onSubmit, closeModal],
  )

  const onFileDrop = (files: FileList) => {
    if (files && files[0]) {
      const file = files[0]
      if (file.type !== "text/csv") {
        dispatch(showToast("Only CSV files are supported.", TOAST.TYPE.ERROR))
      } else {
        setValue("file", files)
      }
    }
  }

  return (
    <Modal open={open} handleClose={closeModal} title="Create promo codes list" size="large">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className={styles.nameFieldRow}>
          <TextField
            label="Name"
            placeholder="Promo codes list name"
            error={errors.name?.message}
            {...register("name", { validate: required })}
            fullWidth
          />
        </div>
        <div className={styles.fileFieldRow}>
          <DropCsvFile onFileDrop={onFileDrop} label="Add promo codes">
            <div>
              <FileField
                error={errors.file?.message}
                {...register("file", {
                  validate: value =>
                    requiredFile(value) || maxFileSize(MAX_UPLOADED_FILE_SIZE, value),
                })}
                accept=".csv"
                className={styles.fileField}
              />
              <p className={styles.fileFieldDescription}>
                CSV with 1 column with codes. Max size: 2MB
              </p>
            </div>
          </DropCsvFile>
        </div>
        <div className={styles.modalButtons}>
          <Button
            color="transparent-grey"
            type="button"
            size="medium"
            onClick={closeModal}
            className={styles.cancelButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            size="medium"
            className={classnames({ loading: isSubmitting })}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  )
}
