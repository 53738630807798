import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { Dispatch } from "redux"
import { Report, ReportCreatePayload, ReportModifyPayload } from "resources/report/reportTypes"

export const REPORTS = {
  ACTION: {
    FETCH_ALL: "REPORTS_FETCH_ALL",
    RETRIEVE: "REPORT_RETRIEVE",
    CREATE: "REPORT_CREATE",
    MODIFY: "REPORT_MODIFY",
    DELETE: "REPORT_DELETE",
    MOVE: "REPORT_MOVE",
    MOVE_ERROR: "REPORT_MOVE_ERROR",
  },
} as const

export const fetchAllReports = () => async (dispatch: Dispatch) => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: REPORTS.ACTION.FETCH_ALL,
    payload: {
      promise: caller
        .setEndpointCall((offset: number, limit: number) => api.report.list(offset, limit))
        .setDataPath("reports")
        .run(),
    },
  })
}

export const retrieveReport = (id: Report["id"]) => async (dispatch: Dispatch) => {
  return dispatch({
    type: REPORTS.ACTION.RETRIEVE,
    payload: {
      promise: api.report.retrieve(id),
    },
  })
}

export const createReport = (data: ReportCreatePayload) => async (dispatch: Dispatch) => {
  return dispatch({
    type: REPORTS.ACTION.CREATE,
    payload: {
      promise: api.report.create(data),
    },
  })
}

export const modifyReport =
  (id: Report["id"], data: ReportModifyPayload) => async (dispatch: Dispatch) => {
    return dispatch({
      type: REPORTS.ACTION.MODIFY,
      payload: {
        promise: api.report.modify(id, data),
      },
    })
  }

export const deleteReport = (id: Report["id"]) => async (dispatch: Dispatch) => {
  return dispatch({
    type: REPORTS.ACTION.DELETE,
    meta: {
      reportId: id,
    },
    payload: {
      promise: api.report.delete(id),
    },
  })
}

export const moveReport =
  (id: Report["id"], fromIndex: number, toIndex: number) => async (dispatch: Dispatch) => {
    dispatch({
      type: REPORTS.ACTION.MOVE,
      meta: {
        fromIndex: fromIndex,
        toIndex: toIndex,
      },
    })
    try {
      await api.report.move(id, { order_index: toIndex })
    } catch (err) {
      dispatch({
        type: REPORTS.ACTION.MOVE_ERROR,
        meta: {
          fromIndex: toIndex,
          toIndex: fromIndex,
        },
      })
    }
  }

export const fetchAllReportsFulfilledType = `${REPORTS.ACTION.FETCH_ALL}_FULFILLED` as const
type FetchAllReportsAction = {
  type: typeof fetchAllReportsFulfilledType
  payload: Report[]
}

export const retrieveReportFulfilledType = `${REPORTS.ACTION.RETRIEVE}_FULFILLED` as const
type RetrieveReportAction = {
  type: typeof retrieveReportFulfilledType
  payload: {
    report: Report
  }
}

export const createReportFulfilledType = `${REPORTS.ACTION.CREATE}_FULFILLED` as const
type CreateReportAction = {
  type: typeof createReportFulfilledType
  payload: {
    report: Report
  }
}

export const modifyReportFulfilledType = `${REPORTS.ACTION.MODIFY}_FULFILLED` as const
type ModifyReportAction = {
  type: typeof modifyReportFulfilledType
  payload: {
    report: Report
  }
}

export const deleteReportFulfilledType = `${REPORTS.ACTION.DELETE}_FULFILLED` as const
type DeleteReportAction = {
  type: typeof deleteReportFulfilledType
  meta: {
    reportId: Report["id"]
  }
  payload: { message: "OK" }
}

export const moveReportFulfilledType = REPORTS.ACTION.MOVE
export const moveReportErrorType = REPORTS.ACTION.MOVE_ERROR
type MoveReportAction = {
  type: typeof moveReportFulfilledType | typeof moveReportErrorType
  meta: {
    fromIndex: number
    toIndex: number
  }
}

export type ReportsAction =
  | FetchAllReportsAction
  | RetrieveReportAction
  | CreateReportAction
  | ModifyReportAction
  | DeleteReportAction
  | MoveReportAction
