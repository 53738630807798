import React, { useCallback } from "react"
import styles from "./DimensionTree.module.scss"
import { assocPath as set, pipe } from "ramda"
import { DimensionOption } from "./types"
import DimensionBranch from "./DimensionBranch/DimensionBranch"
import DimensionLeaf from "./DimensionLeaf/DimensionLeaf"
import Button from "components/UI/elements/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { countDimensionsUsed, getSubattributesUsed } from "./utils"
import { Attribute } from "resources/attribute/attributeTypes"
import { isDimensionBranch } from "resources/segment/segment/utilities/segmentConditionTypeChecks"
import {
  CompoundSegmentCondition,
  SegmentCondition,
  DimensionTreeError,
  DimensionConditionObject,
  LeafCompoundSegmentCondition,
  DimensionLeafError,
} from "resources/segment/segment/segmentConditionsTypes"

type DimensionTreeProps = {
  condition: CompoundSegmentCondition
  onChange: (condition: SegmentCondition) => void
  isEditable: boolean
  dimensions: DimensionOption[]
  attributeId: Attribute["id"] | null
  dimensionsError?: DimensionTreeError
}

export default function DimensionsTree({
  condition,
  onChange,
  isEditable,
  dimensions,
  attributeId,
  dimensionsError,
}: DimensionTreeProps) {
  const noOfDimensionsUsed = countDimensionsUsed(condition)
  const subattributeIdsUsed = getSubattributesUsed(condition)

  const addDimension = useCallback(() => {
    const newDimension: DimensionConditionObject = {
      condition: { sub_attribute_id: null, operation: null },
    }

    if (isDimensionBranch(condition)) {
      const { operands, operation } = condition

      onChange(
        pipe<SegmentCondition, SegmentCondition, SegmentCondition>(
          set(["operation"], "and"),
          set(["operands"], [{ operation, operands }, newDimension]),
        )(condition),
      )
    } else {
      const {
        attribute_id,
        negation,
        condition: currentOperationObject,
      } = condition as LeafCompoundSegmentCondition

      onChange({
        attribute_id,
        negation,
        operation: "and",
        operands: [{ condition: currentOperationObject }, newDimension],
      })
    }
  }, [condition, onChange])

  const removeLastDimension = useCallback(() => {
    const { attribute_id, negation } = condition

    onChange({ attribute_id, negation, condition: { operation: null } })
  }, [condition, onChange])

  return (
    <div className={styles.container}>
      {isDimensionBranch(condition) ? (
        <DimensionBranch
          path={[]}
          condition={condition}
          onChange={onChange}
          dimensions={dimensions}
          subattributeIdsUsed={subattributeIdsUsed}
          attributeId={attributeId}
          isEditable={isEditable}
          dimensionsError={dimensionsError}
        />
      ) : (
        <DimensionLeaf
          operationObject={condition.condition}
          onChange={newOperationObject =>
            onChange(set(["condition"], newOperationObject, condition))
          }
          isEditable={isEditable}
          dimensions={dimensions}
          subattributeIdsUsed={subattributeIdsUsed}
          attributeId={attributeId}
          removeSelf={removeLastDimension}
          error={dimensionsError as DimensionLeafError}
        />
      )}
      {isEditable && noOfDimensionsUsed < dimensions.length && (
        <Button onClick={addDimension} color="white" size="small" className={styles.addButton}>
          <FontAwesomeIcon icon={["far", "plus"]} /> add dimension
        </Button>
      )}
    </div>
  )
}
