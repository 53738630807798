import { isAndOrCondition } from "components/ConditionBuilder/utils"
import {
  isHTTPCondition,
  isSegmentCondition,
} from "components/UI/components/WebBannerCondition/utils"
import {
  IdentifierLocation,
  HTTPIsOkCondition,
  LeafCondition,
  WBConditionObject,
  SegmentCondition,
  HTTPCondition,
} from "resources/webBanner/webBannerConditionTypes"
import { BASE_URL } from "sharedConstants"

type SegmentConditionParams = Pick<
  SegmentCondition,
  "segment_id" | "identifier_id" | "identifier_location" | "identifier_location_key"
>

const WBS_URL = new URL("wbs", BASE_URL).toString()

const isWbsUrl = (url: URL) =>
  url.pathname === "/wbs" &&
  url.origin === BASE_URL.replace(/\/$/, "") &&
  !!url.searchParams.get("segment") &&
  !!url.searchParams.get("attribute") &&
  !!url.searchParams.get("value")

export const createWbsUrlTemplate = ({
  segment_id,
  identifier_id,
  identifier_location,
  identifier_location_key,
}: SegmentConditionParams) => {
  const wbsUrl = new URL(WBS_URL)
  wbsUrl.searchParams.append("segment", segment_id!.toString())
  wbsUrl.searchParams.append("attribute", identifier_id!)

  const stringWbsUrl = wbsUrl.toString()
  // cannot use URL search params because of encoding unsafe characters - "{", ":", "}"
  return stringWbsUrl.concat("&value=", `{{${identifier_location}:${identifier_location_key}}}`)
}

export const parseWbsUrlTemplate = (stringUrl: string): SegmentConditionParams | null => {
  try {
    const url = new URL(stringUrl)
    if (!isWbsUrl(url)) return null

    const segment_id = parseInt(url.searchParams.get("segment") ?? "")
    const identifier_id = url.searchParams.get("attribute") ?? ""
    // cannot use search params here due to the omitting of special characters
    const valueParam = stringUrl.match(/value={1}\{{2}(ls|cookie):{1}.+\}{2}/g)?.[0] ?? ""
    const [, ...restValueParam] = valueParam.split("=")
    const value = restValueParam.join("=").replaceAll("{", "").replaceAll("}", "")
    const [identifier_location, ...restLocationKey] = value.split(":") as [
      IdentifierLocation,
      Array<string>,
    ]

    return {
      identifier_location_key: restLocationKey.join(":"),
      segment_id,
      identifier_id,
      identifier_location,
    }
  } catch (_) {
    return null
  }
}

export const transformToHttpRequestCondition = (condition: LeafCondition): LeafCondition => {
  if (!isSegmentCondition(condition)) return condition

  const { operator, segment_id, identifier_id, identifier_location, identifier_location_key } =
    condition
  if (
    !operator ||
    !segment_id ||
    !identifier_id ||
    !identifier_location ||
    !identifier_location_key
  )
    return condition

  const urlTemplate = createWbsUrlTemplate({
    segment_id,
    identifier_id,
    identifier_location,
    identifier_location_key,
  })

  if (operator === "in_segment") {
    return {
      subject: "http_request",
      operator: "is_ok",
      url_template: urlTemplate,
    } as HTTPIsOkCondition
  } else if (operator === "not_in_segment") {
    return {
      subject: "http_request",
      operator: "equals",
      response_value_path: "status",
      value: "error",
      type: "string",
      url_template: urlTemplate,
    } as HTTPCondition
  }

  return condition
}

export const transformFromHttpRequestCondition = (condition: LeafCondition): LeafCondition => {
  if (!isHTTPCondition(condition)) return condition

  const { operator, url_template, response_value_path, type } = condition

  if (operator === "is_ok") {
    const parsedWbsUrl = parseWbsUrlTemplate(url_template!)
    if (!parsedWbsUrl) return condition

    const { segment_id, identifier_id, identifier_location, identifier_location_key } = parsedWbsUrl

    return {
      segment_id,
      identifier_id,
      identifier_location,
      identifier_location_key,
      subject: "segment",
      operator: "in_segment",
    } as SegmentCondition
  } else if (operator === "equals" && type === "string" && response_value_path === "status") {
    const parsedWbsUrl = parseWbsUrlTemplate(url_template!)
    if (!parsedWbsUrl) return condition

    const { segment_id, identifier_id, identifier_location, identifier_location_key } = parsedWbsUrl

    return {
      segment_id,
      identifier_id,
      identifier_location,
      identifier_location_key,
      subject: "segment",
      operator: "not_in_segment",
    } as SegmentCondition
  }

  return condition
}

export const replaceConditionInConditionTree = (
  tree: WBConditionObject,
  f: (condition: LeafCondition) => LeafCondition,
): WBConditionObject =>
  isAndOrCondition(tree)
    ? {
        operator: tree.operator,
        operands: tree.operands.map(operand => replaceConditionInConditionTree(operand, f)),
      }
    : tree.operator === "negation"
    ? { operator: tree.operator, operand: f(tree.operand) }
    : f(tree)

export const segmentToHttpRequestCondition = <
  T extends { settings?: { condition: WBConditionObject | null } },
>(
  webBanner: T,
) => {
  if (!webBanner.settings?.condition) return webBanner

  return {
    ...webBanner,
    settings: {
      ...webBanner.settings,
      condition: replaceConditionInConditionTree(
        webBanner.settings.condition,
        transformToHttpRequestCondition,
      ),
    },
  }
}

export const httpRequestToSegmentCondition = <
  T extends { settings?: { condition: WBConditionObject | null } },
>(
  webBanner: T,
) => {
  if (!webBanner.settings?.condition) return webBanner

  return {
    ...webBanner,
    settings: {
      ...webBanner.settings,
      condition: replaceConditionInConditionTree(
        webBanner.settings.condition,
        transformFromHttpRequestCondition,
      ),
    },
  }
}
