import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _isNil from "lodash/isNil"
import _isFunction from "lodash/isFunction"
import _size from "lodash/size"
import moment from "moment"
import SimpleBar from "simplebar-react"

import { getIconSrc } from "helpers/image.helper"
import {
  getCompoundAttributeSubAttribute,
  isAttributeCompound,
} from "resources/attribute/compoundAttributeUtils"
import { shortenText } from "helpers/string.helper"
import AttributeBadge from "../../elements/AttributeBadge"
import Button from "../../elements/Button/Button"

import "./AttributePicker.scss"
import Tippy from "@tippyjs/react"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import { filter, groupBy, isEmpty, mapObjIndexed, reject } from "ramda"

const DEFAULT_HEIGHT = 34

class AttributePicker extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      editMode: false,
      searchText: "",
      searchResults: {},
      selectedSource: null,
      selectedLabel: null,
      height: DEFAULT_HEIGHT,
      attributesMapBySourceName: {},
      attributesMapByLabel: {},
      filterBy: "source",
      needsTooltip: true,
    }

    this.inputRef = React.createRef()
    this.wrapperRef = React.createRef()
    this.scrollToRef = React.createRef()
    this.dropdown = React.createRef()
    this.ghost = React.createRef()

    window.addEventListener("keyup", this.handleKeyUp, false)
    setTimeout(() => document.addEventListener("click", this.handleOutsideClick, false), 0)
  }

  componentDidMount() {
    if (this.props.focusOnLoad) {
      this.toggleEditMode()
    }
    this.setFilledTextareaHeight()
    this.setAttributesMapBySourceName()
    this.setAttributesMapByLabel()
  }

  componentDidUpdate(prevProps) {
    if (this.props.attributesMap !== prevProps.attributesMap) {
      this.setAttributesMapBySourceName()
      this.setAttributesMapByLabel()
    }
    const needsTooltip = this.ghost.current.clientHeight > this.inputRef.current.clientHeight
    if (this.state.needsTooltip !== needsTooltip) {
      this.setState({ needsTooltip })
    }
  }

  setAttributesMapBySourceName = () => {
    const { attributesMap } = this.props

    const attributesMapBySourceName = groupBy(
      attribute => attribute.source.name,
      Object.values(attributesMap),
    )

    this.setState({ attributesMapBySourceName })
  }

  setAttributesMapByLabel = () => {
    const { attributesMap } = this.props

    const attributesMapByLabel = {}

    Object.values(attributesMap).forEach(attribute => {
      const labels = attribute.tags
      if (labels) {
        labels.forEach(({ name }) => {
          if (attributesMapByLabel[name]) {
            attributesMapByLabel[name].push(attribute)
          } else {
            attributesMapByLabel[name] = [attribute]
          }
        })
      }
    })

    this.setState({ attributesMapByLabel })
  }

  setFilledTextareaHeight = () => {
    if (!this.props.fixedSize && !_isNil(this.inputRef.current)) {
      const element = this.ghost

      // + borders
      if (this.state.height !== element.current.clientHeight + 2) {
        this.setState({
          height: element.current.clientHeight + 2,
        })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
    document.removeEventListener("click", this.handleOutsideClick, false)
  }

  clearPicker = () => {
    this.setState({
      editMode: false,
      searchText: "",
      searchResults: {},
      selectedSource: null,
      selectedLabel: null,
      filterBy: "source",
    })
    this.props.handleAttributeSelect(null)
    requestAnimationFrame(this.setFilledTextareaHeight)
  }

  handleKeyUp = evt => {
    const keys = {
      8: () => {
        if (this.state.editMode && !this.state.searchText) {
          this.backToFirstLevel()
          this.props.handleAttributeSelect(null)
        }
      },
      27: () => {
        if (this.state.editMode) {
          this.toggleEditMode()
        }
      },
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  handleOutsideClick = evt => {
    if (this.state.editMode && !_isNil(this.wrapperRef.current)) {
      if (!this.wrapperRef.current.contains(evt.target)) {
        this.toggleEditMode()
      }
    }
  }

  toggleEditMode = () => {
    const { editMode, selectedSource, selectedLabel } = this.state
    const { attributesMap, attributeId } = this.props
    const selectedAttribute = attributesMap[attributeId]
    let selectedSourceBySelectedAttribute = selectedSource
    if (selectedAttribute && !selectedSource && !selectedLabel) {
      selectedSourceBySelectedAttribute = selectedAttribute.source.name ?? null
    }

    this.setState(
      {
        editMode: !editMode,
        searchText: "",
        selectedSource: selectedSourceBySelectedAttribute,
      },
      () => {
        if (!editMode && !_isNil(this.dropdown.current) && !_isNil(this.scrollToRef.current)) {
          this.dropdown.current.scrollTop = this.scrollToRef.current.offsetTop - 60
        }
        if (!editMode && !_isNil(this.inputRef.current)) {
          this.inputRef.current.focus()
        }
        if (editMode && _isFunction(this.props.onClose)) {
          this.props.onClose()
        }
        if (editMode) {
          requestAnimationFrame(this.setFilledTextareaHeight)
        }
      },
    )

    if (editMode && !_isNil(this.inputRef.current)) {
      this.inputRef.current.blur()
    }
  }

  _filterAttributesByName = searchTerm => {
    const { compoundAttributesHidden } = this.props
    const { attributesMapBySourceName, attributesMapByLabel, filterBy } = this.state
    let attributesToFilter =
      filterBy === "source" ? attributesMapBySourceName : attributesMapByLabel
    if (compoundAttributesHidden) {
      attributesToFilter = mapObjIndexed(
        reject(attribute => isAttributeCompound(attribute.data_type)),
        attributesToFilter,
      )
    }

    this.setState(
      {
        selectedLabel: null,
        selectedSource: null,
        searchText: searchTerm,
        searchResults: mapObjIndexed(
          filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase())),
          attributesToFilter,
        ),
      },
      this.setFilledTextareaHeight,
    )
  }

  handleInputChange = evt => {
    this._filterAttributesByName(evt.target.value)
  }

  handleInputClick = evt => {
    const { editMode } = this.state
    if (!editMode) {
      this.toggleEditMode()
    }
  }

  handleAttributeClick = attributeId => () => {
    this.props.handleAttributeSelect(attributeId)
    this.toggleEditMode()
  }

  backToFirstLevel = evt => {
    if (evt) {
      evt.stopPropagation()
    }
    this.setState({
      selectedSource: null,
    })
  }

  goToSecondLevel = sourceName => () => {
    this.setState({
      selectedSource: sourceName,
    })
  }

  renderAttrName = attribute => {
    let examplesContent = <span>No examples</span>
    if (Array.isArray(attribute.examples) && attribute.examples.length > 0) {
      examplesContent = (
        <span>
          {attribute.examples
            .map(v => getUserFriendlyValueFormat(v, attribute.data_type))
            .join(", ")}
        </span>
      )
    } else if (attribute.examples && !isEmpty(attribute.examples)) {
      examplesContent = (
        <div className="compound-tooltip-content">
          {Object.entries(attribute.examples).map(([key, list]) => {
            const subAttribute = getCompoundAttributeSubAttribute(key, attribute.data_type)
            let values = "No examples"
            if (Array.isArray(list) && list.length > 0) {
              values = list
                .map(v => getUserFriendlyValueFormat(v, subAttribute?.data_type ?? "string"))
                .join(", ")
            }
            return (
              <span key={key}>
                {subAttribute ? subAttribute.name : key}: {values}
              </span>
            )
          })}
        </div>
      )
    }
    return (
      <>
        <Tippy
          className="attribute-examples-tooltip"
          placement="bottom-start"
          delay={[400, null]}
          content={examplesContent}
        >
          <span className="attrname" data-tip={attribute.name} data-for={attribute.id}>
            {attribute.is_hidden === 1 && (
              <FontAwesomeIcon icon={["far", "eye-slash"]} className="eye" />
            )}
            {attribute.name}
          </span>
        </Tippy>
        {moment().diff(attribute.created, "days") < 8 && (
          <AttributeBadge
            text="new"
            className={isAttributeCompound(attribute.data_type) ? "mr" : ""}
          />
        )}
        {isAttributeCompound(attribute.data_type) && <AttributeBadge text="compound" />}
      </>
    )
  }

  renderSearchResults = () => {
    const { searchResults } = this.state

    const noResults = !Object.values(searchResults).some(attributes => attributes.length > 0)

    if (noResults) {
      return <li className="no-results">No results found</li>
    }

    return Object.entries(searchResults).map(([categoryName, attributes]) => {
      if (attributes.length === 0) {
        return null
      }
      return (
        <li key={categoryName} className="source-name">
          {categoryName}
          <ul className="sublist">
            {attributes.map(attribute => (
              <li
                key={attribute.id}
                className="compound"
                onClick={this.handleAttributeClick(attribute.id)}
              >
                {this.renderAttrName(attribute)}
              </li>
            ))}
          </ul>
        </li>
      )
    })
  }

  selectFilterBy = filterBy => () => {
    this.setState(
      {
        filterBy,
      },
      () => this._filterAttributesByName(this.state.searchText),
    )
  }

  render() {
    const {
      isEditable,
      attributesMap,
      attributeId,
      showSource,
      showSourceLogo,
      fixedSize,
      compoundAttributesHidden,
      errorMarkup,
      isVisible,
      isClearable,
      renderSubmit = false,
      placeholder,
      inputTextLimit,
      className,
    } = this.props
    const {
      editMode,
      searchText,
      selectedSource,
      selectedLabel,
      height,
      attributesMapBySourceName,
      attributesMapByLabel,
      filterBy,
      needsTooltip,
    } = this.state

    let dropdownAlignment = "left"
    if (this.wrapperRef && this.wrapperRef.current) {
      const windowWidth = window.innerWidth
      const position = this.wrapperRef.current.getBoundingClientRect()
      if (position.x + 680 > windowWidth) {
        dropdownAlignment = "right"
      }
    }
    const selectedAttribute = attributesMap[attributeId]

    let attributeName = ""
    let attributeSourceLogo = null
    if (selectedAttribute) {
      if (showSource) {
        attributeName = `${selectedAttribute.source.name}: ${selectedAttribute.name}`
      } else {
        attributeName = selectedAttribute.name
      }
      if (showSourceLogo) {
        let color = selectedAttribute.source.frontend_settings?.color ?? "primary"
        attributeSourceLogo = (
          <div className={`source-bg ${color}`}>
            <img
              src={getIconSrc(
                {
                  primary: selectedAttribute.source.frontend_settings?.icon,
                  secondary: selectedAttribute.source.type.toLowerCase(),
                },
                selectedAttribute.source.frontend_settings?.alt_icon,
                true,
              )}
              alt="icon"
            />
          </div>
        )
      }
    }

    let inputValue = ""
    if (editMode) {
      inputValue = searchText
    } else {
      inputValue = attributeName
      if (inputTextLimit) {
        inputValue = shortenText(inputValue, inputTextLimit)
      }
    }

    let placeholderValue = placeholder ? placeholder : "Select attribute or type to search"
    if (editMode && attributeName) {
      placeholderValue = attributeName
    }

    let filteredAttributesMap =
      filterBy === "source" ? attributesMapBySourceName : attributesMapByLabel
    if (compoundAttributesHidden) {
      filteredAttributesMap = mapObjIndexed(
        reject(attribute => isAttributeCompound(attribute.data_type), filteredAttributesMap),
      )
    }

    const categoryNames = Object.keys(filteredAttributesMap)
    const showCategoryAttributes =
      Array.isArray(filteredAttributesMap[selectedSource]) ||
      Array.isArray(filteredAttributesMap[selectedLabel])

    let categoryAttributesSize = 0
    if (showCategoryAttributes) {
      if (selectedSource) {
        categoryAttributesSize = filteredAttributesMap[selectedSource]?.length
      } else if (selectedLabel) {
        categoryAttributesSize = filteredAttributesMap[selectedLabel]?.length
      }
    }
    return (
      <div
        className={`filter-attribute-select-wrapper ${
          attributeSourceLogo !== null ? "has-source" : "no-source"
        } ${errorMarkup ? "error" : ""} ${isVisible ? "is-visible" : ""} ${
          renderSubmit ? "submit-rendered" : ""
        } ${fixedSize ? fixedSize : ""} ${editMode ? "is-open" : ""} ${
          className ? className : ""
        } ${dropdownAlignment}`}
        ref={this.wrapperRef}
      >
        <React.Fragment>
          {attributeSourceLogo}
          <div
            className={`ghost-field ${fixedSize ? fixedSize : ""} ${
              isClearable ? "clearable" : ""
            }`}
            ref={this.ghost}
            aria-hidden="true"
          >
            {inputValue || attributeName}
          </div>
          {fixedSize && (
            <Tippy
              content={inputValue}
              disabled={!needsTooltip || !inputValue}
              placement="top"
              delay={200}
            >
              <input
                type="text"
                value={inputValue}
                onChange={this.handleInputChange}
                onClick={this.handleInputClick}
                onKeyUp={this.setFilledTextareaHeight}
                disabled={!isEditable}
                placeholder={placeholderValue}
                className={`filter-attribute-select-input ${fixedSize ? fixedSize : ""}`}
                ref={this.inputRef}
                autoComplete="off"
              />
            </Tippy>
          )}
          {!fixedSize && (
            <textarea
              value={inputValue}
              onChange={this.handleInputChange}
              onClick={this.handleInputClick}
              onKeyUp={this.setFilledTextareaHeight}
              disabled={!isEditable}
              placeholder={placeholderValue}
              className={`filter-attribute-select-input ${isClearable ? "clearable" : ""}`}
              style={{
                height: `${height}px`,
              }}
              ref={this.inputRef}
            />
          )}
          <button
            type="button"
            className={`filter-attribute-select-button ${!editMode ? "click-through" : ""} ${
              fixedSize ? fixedSize : ""
            }`}
            disabled={!isEditable}
            onClick={this.toggleEditMode}
          >
            {editMode && <FontAwesomeIcon icon={["fas", "caret-up"]} className="caret-up" />}
            {!editMode && <FontAwesomeIcon icon={["fas", "caret-down"]} className="caret-down" />}
          </button>
          {isClearable && isEditable && _size(inputValue) !== 0 && (
            <button
              type="button"
              className="filter-attribute-clear-button"
              onClick={this.clearPicker}
            >
              <FontAwesomeIcon icon={["far", "times"]} />
            </button>
          )}
          {renderSubmit && (
            <Button
              color="primary"
              className={`${fixedSize ? fixedSize : ""} search-button`}
              type="submit"
            >
              <FontAwesomeIcon icon="search" />
            </Button>
          )}
        </React.Fragment>
        {editMode && (
          <div
            className={`filter-attribute-select-dropdown ${
              !searchText && selectedSource ? "attribute-selecting" : ""
            }`}
            ref={this.dropdown}
          >
            <div className="menu-header">
              {selectedSource && (
                <span className="menu-header-title" onClick={this.backToFirstLevel}>
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} className="chevron-left" />{" "}
                  {selectedSource}
                </span>
              )}
              {!selectedSource && (
                <span className="menu-header-title filter-by">
                  Filter by:
                  <div className="filter-type-picker-wrapper">
                    <span
                      onClick={this.selectFilterBy("source")}
                      className={filterBy === "source" ? "active" : ""}
                    >
                      Source
                    </span>
                    <span
                      onClick={this.selectFilterBy("label")}
                      className={filterBy === "label" ? "active" : ""}
                    >
                      Label
                    </span>
                  </div>
                </span>
              )}
            </div>
            <SimpleBar className="scrollable">
              <ul
                className={`menu-first-lvl ${
                  !searchText && !selectedSource ? "displayed" : "hidden"
                }`}
              >
                {categoryNames.length > 0 ? (
                  categoryNames.sort().map(categoryName => (
                    <li
                      key={categoryName}
                      className="source-name"
                      onClick={this.goToSecondLevel(categoryName)}
                    >
                      {categoryName}{" "}
                      <FontAwesomeIcon icon={["fas", "chevron-right"]} className="chevron-right" />
                    </li>
                  ))
                ) : (
                  <li className="source-name">No {filterBy} exists.</li>
                )}
              </ul>
              <div
                className={`menu-second-lvl ${
                  !searchText && selectedSource ? "displayed" : "hidden"
                }`}
              >
                <div className="menu-second-lvl-content">
                  {showCategoryAttributes && (
                    <>
                      <ul className="first-col">
                        {filteredAttributesMap[selectedSource]
                          .slice(0, Math.ceil(categoryAttributesSize / 2))
                          .map(attribute => (
                            <li
                              key={attribute.id}
                              className={
                                attributeId === attribute.id ? "compound active" : "compound"
                              }
                              ref={attributeId === attribute.id ? this.scrollToRef : null}
                              onClick={this.handleAttributeClick(attribute.id)}
                            >
                              {this.renderAttrName(attribute)}
                            </li>
                          ))}
                      </ul>
                      <ul className="second-col">
                        {filteredAttributesMap[selectedSource]
                          .slice(Math.ceil(categoryAttributesSize / 2))
                          .map(attribute => (
                            <li
                              key={attribute.id}
                              className={
                                attributeId === attribute.id ? "compound active" : "compound"
                              }
                              ref={attributeId === attribute.id ? this.scrollToRef : null}
                              onClick={this.handleAttributeClick(attribute.id)}
                            >
                              {this.renderAttrName(attribute)}
                            </li>
                          ))}
                      </ul>
                    </>
                  )}
                </div>
              </div>
              <ul className={`search-results ${searchText ? "displayed" : "hidden"}`}>
                {this.renderSearchResults()}
              </ul>
            </SimpleBar>
          </div>
        )}
      </div>
    )
  }
}

AttributePicker.defaultProps = {
  compoundAttributesHidden: false,
}

AttributePicker.propTypes = {
  attributeId: PropTypes.string,
  attributesMap: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  handleAttributeSelect: PropTypes.func.isRequired,
  showSource: PropTypes.bool,
  showSourceLogo: PropTypes.bool,
  focusOnLoad: PropTypes.bool,
  onClose: PropTypes.func,
  fixedSize: PropTypes.string,
  compoundAttributesHidden: PropTypes.bool,
  errorMarkup: PropTypes.bool,
  isVisible: PropTypes.bool,
  isClearable: PropTypes.bool,
  renderSubmit: PropTypes.bool,
  placeholder: PropTypes.string,
  buttonMode: PropTypes.bool,
  buttonPlaceholder: PropTypes.string,
  className: PropTypes.string,
}

export default ({ includeHidden = false, excludeStitching = false, ...props }) => {
  const { data: attributesMap = {} } = useFetchAttributesMap({
    includeHidden,
    excludeStitching,
    includeId: props.attributeId,
  })

  return <AttributePicker {...props} attributesMap={attributesMap} />
}
