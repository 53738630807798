import styles from "./CloseButtonFields.module.scss"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import ToggleButton from "components/UI/elements/ToggleButton"
import { PWBCloseButton } from "resources/webBanner/popupWebBanner/popupWBTypes"
import ColorInput from "./ColorInput/ColorInput"

type CloseButtonFieldsProps = {
  name: string
  value: PWBCloseButton
  onChange: (...event: any[]) => void
  disabled: boolean
  disabledEnableCloseButton: boolean
}

export default function CloseButtonFields({
  name,
  value,
  onChange,
  disabled,
  disabledEnableCloseButton,
}: CloseButtonFieldsProps) {
  return (
    <>
      <h4>Close button</h4>
      <div className={styles.box}>
        <div className={styles.closeButtonToggleWrapper}>
          <label>
            Enable close button{" "}
            <InfoTooltip placement="top">
              When disabled you'll have to call the method
              window.parent.MeiroEvents.closeWebBanner() on your own for closing the banner.
            </InfoTooltip>
          </label>
          <ToggleButton
            value={!value.disabled}
            handleToggle={() => {
              onChange({ ...value, disabled: !value.disabled })
            }}
            disabled={disabled || disabledEnableCloseButton}
          />
        </div>
        <div className={styles.colors}>
          <ColorInput
            name={`${name}_icon_color`}
            label="Icon color"
            value={value.css?.color}
            onChange={(color: string) => {
              onChange({
                ...value,
                css: {
                  ...value.css,
                  color,
                },
              })
            }}
            disabled={disabled}
          />
          <ColorInput
            name={`${name}_background_color`}
            label="Background color"
            value={value.css?.background_color}
            onChange={(background_color: string) => {
              onChange({
                ...value,
                css: {
                  ...value.css,
                  background_color,
                },
              })
            }}
            disabled={disabled}
          />
          <ColorInput
            name={`${name}_border_color`}
            label="Border color"
            value={value.css?.border_color}
            onChange={(border_color: string) => {
              onChange({
                ...value,
                css: {
                  ...value.css,
                  border_color,
                },
              })
            }}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  )
}
