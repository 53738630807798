import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import SegmentDetail from "pages/Segments/components/SegmentDetail/SegmentDetail"
import { hasAccess } from "helpers/authenticatedUser.helper"
import SegmentExportLog from "pages/Segments/components/SegmentExportLog/SegmentExportLog"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"

export default function FeaturedSegmentDetail() {
  return (
    <Switch>
      <Route
        path={getRoutePath("segments.featured.detail")}
        exact
        render={() => <SegmentDetail view="builder" type="featured" />}
      />
      <Route
        path={getRoutePath("segments.featured.detail.insights")}
        render={() => <SegmentDetail view="insights" type="featured" />}
      />
      <Route
        path={getRoutePath("segments.featured.detail.exports")}
        exact
        render={() => <SegmentDetail view="exports" type="featured" />}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.segments.export()}
        path={getRoutePath("segments.featured.export.detail")}
        component={SegmentExportLog}
      />
    </Switch>
  )
}
