import React, { Component } from "react"
import {
  reduxForm,
  Form,
  Field,
  FieldArray,
  getFormSyncErrors,
  formValueSelector,
  getFormValues,
} from "redux-form"
import SelectField from "components/UI/elements/SelectField"
import { required } from "helpers/validators.helper"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import Button from "components/UI/elements/Button/Button"
import _get from "lodash/get"
import _isNil from "lodash/isNil"
import _set from "lodash/set"
import AttributePicker from "components/UI/components/AttributePicker/AttributePicker"
import { getEventsIsFulfilled, getEventsArrayForSelect } from "selectors/event.selector"
import ExpandableAceEditor from "components/UI/components/ExpandableAceEditor/ExpandableAceEditor"

import "./IdentityStitchingForm.scss"

class IdentityStitchingForm extends Component {
  renderEvents = ({
    fields,
    eventsArray,
    areEventsFulfilled,
    submitFailed,
    submitErrors,
    formValues,
  }) => {
    return (
      <div className="events-right-content">
        {fields.map((event, index) => {
          return (
            <div
              key={index}
              className={`event-item-wrapper ${index === fields.length - 1 ? "last" : ""}`}
            >
              <div className="box">
                <Field
                  name={`${event}.event_id`}
                  component={SelectField}
                  placeholder="Event"
                  label="Event"
                  validate={required}
                  options={eventsArray}
                  isLoading={!areEventsFulfilled}
                  className="event-id"
                />
                <Field
                  name={`${event}.value`}
                  component={ExpandableAceEditor}
                  label="Value"
                  expandModalTitle={_get(formValues, `${event}.event_id.label`)}
                  className={`ace-editor-wrapper-with-margin ${
                    submitFailed && !_isNil(_get(submitErrors, `${event}.value`)) ? "error" : ""
                  }`}
                  width="320px"
                />
              </div>
              {fields.length > 1 && (
                <IconButton
                  withBackground
                  size={SIZE.TAG}
                  color={COLOR.RED}
                  onClick={() => fields.remove(index)}
                  iconName="trash-alt"
                  tooltip="Delete"
                />
              )}
            </div>
          )
        })}
        <Button
          size="medium"
          color="primary"
          onClick={() => fields.push({})}
          className="add-rule-button"
        >
          + Add rule
        </Button>
      </div>
    )
  }

  submitForm = values => {
    const data = {
      attribute_id: values.attribute_id,
      stitching_attribute_configs: values.configs.map(config => ({
        event_id: config.event_id.value,
        value_expression: config.value,
      })),
    }
    this.props.onSubmit(data)
  }

  selectAttribute = newAttributeId => {
    this.props.change("attribute_id", newAttributeId)
  }

  render() {
    const {
      handleSubmit,
      submitFailed,
      submitErrors,
      attributeId,
      eventsArray,
      areEventsFulfilled,
      initialValues,
      formValues,
    } = this.props

    return (
      <section className="identity-stitching-form">
        <Form onSubmit={handleSubmit(this.submitForm)}>
          <div className="form-row white">
            <div className="left-part">
              <h2>General</h2>
            </div>
            <div className="right-part">
              <div
                className={`attribute-id text-field ${
                  submitFailed && !_isNil(submitErrors.attribute_id) ? "error" : ""
                }`}
              >
                <label>Attribute *</label>
                <AttributePicker
                  attributeId={attributeId}
                  handleAttributeSelect={this.selectAttribute}
                  showSource={false}
                  fixedSize="medium-size"
                  isEditable={true}
                  includeHidden
                  excludeStitching
                />
                {submitFailed && !_isNil(submitErrors.attribute_id) && (
                  <p className="error-message">{submitErrors.attribute_id}</p>
                )}
              </div>
              <FieldArray
                name="configs"
                component={this.renderEvents}
                rerenderOnEveryChange={true}
                props={{
                  eventsArray,
                  areEventsFulfilled,
                  submitFailed,
                  submitErrors,
                  initialValues,
                  formValues,
                }}
              />
            </div>
          </div>
        </Form>
      </section>
    )
  }
}

IdentityStitchingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  eventsArray: PropTypes.array.isRequired,
  areEventsFulfilled: PropTypes.bool.isRequired,
}

const selector = formValueSelector("IdentityStitchingForm")
const mapStateToProps = state => ({
  eventsArray: getEventsArrayForSelect(state),
  submitErrors: getFormSyncErrors("IdentityStitchingForm")(state),
  attributeId: selector(state, "attribute_id"),
  areEventsFulfilled: getEventsIsFulfilled(state),
  formValues: getFormValues("IdentityStitchingForm")(state),
})

IdentityStitchingForm = connect(mapStateToProps)(IdentityStitchingForm)

const validate = values => {
  let errors = {}
  if (!_get(values, "attribute_id")) {
    _set(errors, "attribute_id", "Please select the attribute")
  }
  if (values.configs) {
    values.configs.forEach((config, index) => {
      if (!config.value) {
        _set(errors, `configs[${index}].value`, "Please fill in the field")
      }
    })
  }
  return errors
}

IdentityStitchingForm = reduxForm({
  form: "IdentityStitchingForm",
  touchOnBlur: false,
  enableReinitialize: true,
  validate,
})(IdentityStitchingForm)

export default IdentityStitchingForm
