import { getImgSrcFromHtml } from "resources/webBanner/popupWebBanner/getImgSrcFromHtml"
import { PopupWebBanner } from "resources/webBanner/popupWebBanner/popupWBTypes"
import { PopupWebBannersFormValues } from "../popupWBComponentTypes"
import {
  FREQUENCY_CAP_TYPE_OPTIONS,
  PRIORITY_OPTIONS,
  POSITION_OPTIONS,
  HIDE_OPERATOR_OPTIONS,
  ANIMATION_OPTIONS,
  DISPLAY_CONDITION_OPTIONS,
} from "./dropdownOptions"

export function popupWBToFormValues(
  webBanner?: PopupWebBanner,
): PopupWebBannersFormValues | undefined {
  if (!webBanner) {
    return
  }

  return {
    name: webBanner.name,
    condition: webBanner.settings.condition,
    frequency_cap_window_max_count:
      webBanner.settings.frequency_cap.per_user.window.max_display_count,
    frequency_cap_window_type: FREQUENCY_CAP_TYPE_OPTIONS.find(
      type => type.value === webBanner.settings.frequency_cap.per_user.window.type,
    )!,
    frequency_cap_window_size:
      webBanner.settings.frequency_cap.per_user.window.type === "session"
        ? null
        : webBanner.settings.frequency_cap.per_user.window.size,
    frequency_cap_user_max_count:
      webBanner.settings.frequency_cap.per_user.max_display_count ?? null,
    frequency_cap_banner_max_count: webBanner.settings.frequency_cap.max_display_count ?? null,
    priority: PRIORITY_OPTIONS.find(prio => prio.value === webBanner.settings.priority)!,
    position: POSITION_OPTIONS.find(pos => pos.value === webBanner.settings.position)!,
    destination_url: webBanner.settings.content.destination_url,
    content_type: webBanner.settings.content.type,
    html: webBanner.settings.content.type === "html" ? webBanner?.settings.content.html : "",
    image_url: getImgSrcFromHtml(webBanner),
    width: webBanner.settings.fe_settings?.width,
    minimized_width: webBanner.settings.fe_settings?.minimized_width,
    close_button: {
      ...webBanner.settings.fe_settings?.closeButton,
      disabled: webBanner.settings.fe_settings?.closeButton?.disabled ?? false,
      css: webBanner.settings.fe_settings?.closeButton?.css ?? {
        color: "#222222",
        background_color: "#ffffff",
        border_color: "#cccccc",
      },
    },
    minimized_close_button: {
      ...webBanner.settings.fe_settings?.minimized_close_button,
      disabled: webBanner.settings.fe_settings?.minimized_close_button?.disabled ?? false,
      css: webBanner.settings.fe_settings?.minimized_close_button?.css ?? {
        color: "#222222",
        background_color: "#ffffff",
        border_color: "#cccccc",
      },
    },
    disabled: webBanner.disabled,
    minimized: webBanner.settings.minimized_content.enabled ?? false,
    minimized_html:
      webBanner.settings.minimized_content?.type === "html"
        ? webBanner.settings.minimized_content.html
        : "",
    minimized_image_url: getImgSrcFromHtml(webBanner, "minimized"),
    minimized_content_type: webBanner.settings.minimized_content.type,
    minimized_position: POSITION_OPTIONS.find(
      pos => pos.value === webBanner.settings.minimized_content.position,
    ),
    display_minimized_first: webBanner.settings.minimized_content.display_minimized_first,
    minimized_hide_operator: HIDE_OPERATOR_OPTIONS.find(
      prio => prio.value === webBanner.settings.minimized_content.hide_condition?.operator,
    ),
    minimized_hide_values: webBanner.settings.minimized_content.hide_condition?.values ?? [],
    margin_x: webBanner.settings.fe_settings?.margins?.x ?? 20,
    margin_y: webBanner.settings.fe_settings?.margins?.y ?? 20,
    minimized_margin_x: webBanner.settings.fe_settings?.margins?.minimized_x ?? 20,
    minimized_margin_y: webBanner.settings.fe_settings?.margins?.minimized_y ?? 20,
    animation: ANIMATION_OPTIONS.find(
      anim => anim.value === webBanner.settings.fe_settings?.animation,
    ),
    minimized_animation: ANIMATION_OPTIONS.find(
      anim => anim.value === webBanner.settings.fe_settings?.minimized_animation,
    ),
    display_on: {
      action: DISPLAY_CONDITION_OPTIONS.find(
        condition =>
          condition.value === (webBanner.settings.fe_settings?.display_on?.action ?? "page_load"),
      )!,
      value: webBanner.settings.fe_settings?.display_on?.value ?? null,
    },
  }
}
