import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Table, {
  RowMessage,
  TbodySortable,
  Td,
  Th,
  Thead,
  TrSortable,
} from "components/UI/elements/Table"
import ToggleButton from "components/UI/elements/ToggleButton"
import React, { useCallback, useState, useEffect } from "react"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"

import styles from "./ReportsList.module.scss"
import { useHistory } from "react-router"
import { getRoutePath } from "routes"
import { useDispatch } from "react-redux"
import useTypedSelector from "hooks/useTypedSelector"
import {
  fetchAllReports,
  modifyReport,
  deleteReport,
  moveReport,
} from "resources/report/reportActions"
import { Report } from "resources/report/reportTypes"
import { showToast } from "actions/toast.action"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { DragEndEvent } from "@dnd-kit/core"

export default function () {
  const history = useHistory()
  const dispatch = useDispatch()
  const { initialFetching, reports } = useTypedSelector(state => state.reports)
  const [deleteModal, setDeleteModal] = useState<{ open: boolean; report: any | null }>({
    open: false,
    report: null,
  })

  useEffect(() => {
    dispatch(fetchAllReports())
  }, [dispatch])

  const toggleReportDisabled = useCallback(
    (report: Report) => async () => {
      await dispatch(modifyReport(report.id, { disabled: !report.disabled }))
      dispatch(showToast(`Report has been ${report.disabled ? "enabled" : "disabled"}.`))
    },
    [dispatch],
  )

  const closeDeleteReportModal = useCallback(() => {
    setDeleteModal({
      ...deleteModal,
      open: false,
    })
  }, [deleteModal])

  const confirmDeleteReport = useCallback(async () => {
    await dispatch(deleteReport(deleteModal.report!.id))
    closeDeleteReportModal()
    dispatch(showToast("Report has been deleted."))
  }, [dispatch, deleteModal.report, closeDeleteReportModal])

  const onDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (event.over?.data.current) {
        const targetIndex = event.over.data.current.sortable.index
        const sourceIndex = event.active.data.current?.sortable.index
        if (!isNaN(sourceIndex) && targetIndex !== sourceIndex) {
          const reportId = event.active.id as Report["id"]
          await dispatch(moveReport(reportId, sourceIndex, targetIndex))
          dispatch(showToast("Report order changed."))
        }
      }
    },
    [dispatch],
  )

  return (
    <div className={styles.wrapper}>
      <PaperHeader size="small" titleText="Reporting" className={styles.header}>
        <div>
          <Button
            color="white"
            size="small"
            className={styles.headerButtonMargin}
            onClick={() => {
              window.open(`${window.location.origin}/reports`, "_blank")
            }}
          >
            Metabase
          </Button>
          <Button
            color="primary"
            size="small"
            onClick={() => {
              history.push(getRoutePath("setup.reports.create"))
            }}
          >
            + Create
          </Button>
        </div>
      </PaperHeader>
      <Paper hasHeader>
        {initialFetching ? (
          <LoadingIndicator />
        ) : reports.length === 0 ? (
          <RowMessage>No reports found.</RowMessage>
        ) : (
          <Table>
            <Thead>
              <Th>#</Th>
              <Th>Name</Th>
              <Th className={styles.tableDashboardIdCol}>Dashboard ID</Th>
              <Th textAlignRight className={styles.tableEnabledCol}>
                Show in Reporting tab
              </Th>
              <Th className={styles.tableActionsCol}>&nbsp;</Th>
            </Thead>
            <TbodySortable items={reports} onDragEnd={onDragEnd}>
              {reports.map(report => (
                <TrSortable key={report.id} id={report.id}>
                  <Td textBlack textBold textBigger>
                    {report.name}
                  </Td>
                  <Td className={styles.tableDashboardIdCol}>{report.dashboard_id}</Td>
                  <Td textAlignRight className={styles.tableEnabledCol}>
                    <ToggleButton
                      value={!report.disabled}
                      handleToggle={toggleReportDisabled(report)}
                    />
                  </Td>
                  <Td textAlignRight className={styles.tableActionsCol}>
                    {/* @ts-ignore */}
                    <IconButton
                      color={COLOR.BLACK}
                      size={SIZE.TAG}
                      onClick={() => {
                        history.push(
                          getRoutePath("setup.reports.detail", {
                            id: report.id,
                          }),
                        )
                      }}
                      withBackground
                      iconName="pencil-alt"
                      tooltip="Edit"
                    />
                    {/* @ts-ignore */}
                    <IconButton
                      color={COLOR.RED}
                      size={SIZE.TAG}
                      onClick={() => {
                        setDeleteModal({
                          open: true,
                          report,
                        })
                      }}
                      withBackground
                      iconName="trash-alt"
                      tooltip="Delete"
                      className={styles.actionButtonMargin}
                    />
                  </Td>
                </TrSortable>
              ))}
            </TbodySortable>
          </Table>
        )}
      </Paper>
      <ConfirmModal
        title="Are you sure?"
        open={deleteModal.open}
        type="delete"
        text={`Do you really want to delete report: ${deleteModal.report?.name}`}
        handleClose={closeDeleteReportModal}
        handleConfirm={confirmDeleteReport}
      />
    </div>
  )
}
