import { showToast } from "actions/toast.action"
import { deleteReport, modifyReport, retrieveReport } from "resources/report/reportActions"
import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import useTypedSelector from "hooks/useTypedSelector"
import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router"
import { getRoutePath } from "routes"
import { Report } from "resources/report/reportTypes"
import ReportForm, { ReportFormValues } from "../components/ReportForm/ReportForm"

export default function () {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { id } = useParams<{ id: Report["id"] }>()
  const report = useTypedSelector(state => state.reports.reports.find(report => report.id === id))

  useEffect(() => {
    if (!report && !isDeleting) {
      dispatch(retrieveReport(id))
    }
  }, [dispatch, id, report, isDeleting])

  const onModifyReport = useCallback(
    async ({ name, dashboard_id, disabled }: ReportFormValues) => {
      await dispatch(
        modifyReport(id, {
          name,
          dashboard_id,
          disabled,
        }),
      )
      history.push(getRoutePath("setup.reports"))
      dispatch(showToast("Report modified."))
    },
    [dispatch, history, id],
  )

  const confirmDeleteReport = useCallback(async () => {
    setIsDeleting(true)
    try {
      await dispatch(deleteReport(id))
      history.push(getRoutePath("setup.reports"))
      dispatch(showToast("Report deleted."))
    } catch (err) {
      setIsDeleting(false)
    }
  }, [dispatch, history, id])

  const initialValues = report
    ? { name: report.name, dashboard_id: report.dashboard_id, disabled: report.disabled }
    : undefined

  if (!report) return <LoadingIndicator />
  return (
    <>
      <ReportForm
        initialValues={initialValues}
        onSubmit={onModifyReport}
        onDelete={() => {
          setDeleteModalOpen(true)
        }}
      />
      <ConfirmModal
        title="Are you sure?"
        open={deleteModalOpen}
        type="delete"
        text={`Do you really want to delete report?`}
        handleClose={() => {
          setDeleteModalOpen(false)
        }}
        handleConfirm={confirmDeleteReport}
      />
    </>
  )
}
