import React from "react"
import styles from "./OperatorToggle.module.scss"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AndOrOperator } from "types/conditionTree"
import DelayedTooltip from "components/UI/elements/IconButton/DelayedTooltip/DelayedTooltip"

type OperatorToggleProps = {
  onIndent: () => void
  onUnindent: () => void
  onToggle: () => void
  operator: AndOrOperator
  canIndent?: boolean
  canUnindent?: boolean
  disabled?: boolean
  name?: string
}

export default function OperatorToggle({
  onIndent: indent,
  onUnindent: unindent,
  onToggle: toggleAndOr,
  operator,
  canIndent = true,
  canUnindent = true,
  disabled = false,
  name,
}: OperatorToggleProps) {
  const unindentButton = (
    <button
      type="button"
      className={styles.indentButton}
      onClick={unindent}
      disabled={!canUnindent}
    >
      <FontAwesomeIcon icon={["fas", "chevron-left"]} />
    </button>
  )

  const indentButton = (
    <button type="button" className={styles.unindentButton} onClick={indent} disabled={!canIndent}>
      <FontAwesomeIcon icon={["fas", "chevron-right"]} />
    </button>
  )

  // For some reason, the tooltip often fails to register the mouseleave event if the button is
  // disabled and leaves the tooltip hanging. For now we don't render the tooltip if the button is
  // disabled.

  // TODO: Fix tooltip component, then remove the ternaries below and always render the tooltip

  return (
    <div className={styles.container}>
      {!disabled &&
        (canUnindent ? (
          <DelayedTooltip content="Remove parentheses">{unindentButton}</DelayedTooltip>
        ) : (
          unindentButton
        ))}
      <div className={styles.toggleWrapper}>
        <ToggleSwitch
          leftValue={"and"}
          rightValue={"or"}
          checked={operator}
          handleToggle={toggleAndOr}
          disabled={disabled}
          name={name}
          width="70px"
          size="tiny"
        />
      </div>
      {!disabled &&
        (canIndent ? (
          <DelayedTooltip content="Add parentheses">{indentButton}</DelayedTooltip>
        ) : (
          indentButton
        ))}
    </div>
  )
}
