import React, { PureComponent } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { fromJS, List, Map, Record } from "immutable"
import _toString from "lodash/toString"
import _mapKeys from "lodash/mapKeys"
import _map from "lodash/map"
import _isNumber from "lodash/isNumber"
import _filter from "lodash/filter"
import _includes from "lodash/includes"
import _isEmpty from "lodash/isEmpty"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"

// selectors
import { getExportDestinationsData } from "resources/exportDestination/exportDestinationSelectors"
import { getSegmentExports } from "resources/segment/segmentExport/segmentExportSelectors"

// models
import ExportModel from "resources/segment/segmentExport/segmentExportModel"

// actions
import { modifySegment as modifySegmentDeprecated } from "resources/segment/segment/segmentActions.deprecated"
import { modifySegment } from "resources/segment/segment/segmentActions"
import {
  fetchSegmentExportsList,
  runSegmentExport,
  retrieveSegmentExport,
  SEGMENT,
} from "resources/segment/segmentExport/segmentExportActions"
import { showToast } from "actions/toast.action"

// ui elements
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import IconButton, { SIZE } from "components/UI/elements/IconButton"
import SegmentationNumbers from "components/UI/components/SegmentationNumbers"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"

import Scheduler from "./Scheduler"
import ParametersController from "./ParametersController"
import PromotionBar from "./PromotionBar/PromotionBar"

// constants, helpers
import { MOMENT, TOAST } from "sharedConstants"
import { api } from "api"
import { getIconSrc } from "helpers/image.helper"
import Username from "helpers/Username.helper"
import PendingPromise from "helpers/pendingPromise.helper"

// routes
import { getRoutePath } from "routes"
import { hasAccess } from "helpers/authenticatedUser.helper"

import "./SegmentExports.scss"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import Tippy from "@tippyjs/react"
import DelayedTooltip from "components/UI/elements/IconButton/DelayedTooltip/DelayedTooltip"
import { initSegmentNumbers } from "resources/segment/segmentNumbers/segmentNumbersActions"
import { SocketContext } from "context/socket"
import { BASE_API_URL } from "api/request"

const REFRESH_INTERVAL = 5000

class SegmentExports extends PureComponent {
  static contextType = SocketContext

  refreshInterval = null

  constructor(props) {
    super(props)
    this.state = {
      buttonLoading: Map(),
      running: Map(),
      loadMoreButtonLoading: false,
    }
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    if (hasAccess.segments.export()) {
      this.fetchExportsInitialRows()
      this.refreshInterval = setInterval(
        () => this.fetchExportsInitialRows(false),
        REFRESH_INTERVAL,
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval)
    this.refreshInterval = null
    this.pendingPromises.cancelAll()
  }

  fetchExportsInitialRows = (promise = true) => {
    const { fetchSegmentExportsList, segment, isSegmentDeleting } = this.props

    if (isSegmentDeleting) {
      return
    }

    const segmentExportsListRequest = this.pendingPromises.create(
      fetchSegmentExportsList(segment.id, 0, SEGMENT.EXPORT.ITEMS_PER_PAGE, promise),
    )
    segmentExportsListRequest.promise
      .then(response => {
        const segmentExports = response.value
          ? response.value.segment_exports
          : response.payload.segment_exports
        const running = Map(
          _mapKeys(
            _map(
              _filter(segmentExports, segmentExport =>
                _includes(["waiting", "running"], segmentExport.status),
              ),
              segmentExport => new ExportModel(segmentExport),
            ),
            "segment_export_destination_id",
          ),
        )

        this.setState({
          running,
        })
        this.pendingPromises.remove(segmentExportsListRequest)
      })
      .catch(() => {
        this.pendingPromises.remove(segmentExportsListRequest)
      })
  }

  _cancelExportApiCall = async exportId => {
    const { segment } = this.props
    return await api.segment.export.cancel(segment.id, exportId, 0)
  }

  cancelExportByDestinationId = destinationId => () => {
    const exportJob = this.state.running.get(_toString(destinationId))
    this._cancelExportApiCall(exportJob.id)
      .then(() => {
        this.props.showToast("Export will be canceled.", TOAST.TYPE.SUCCESS)
      })
      .catch(() => {
        this.fetchExportsInitialRows()
      })
  }

  runSegmentExport = destinationId => async () => {
    const { segment, runSegmentExport, exportDestinations, initSegmentNumbers } = this.props

    this.setState(prevState => ({
      buttonLoading: prevState.buttonLoading.set(destinationId, true),
    }))
    await runSegmentExport(segment.id, {
      name: exportDestinations.getIn([destinationId.toString(), "name"]) + " export",
      segment_export_destination_id: destinationId,
    })
      .then(response => {
        const { segment_export } = response.value
        this.setState(prevState => ({
          buttonLoading: prevState.buttonLoading.set(destinationId, false),
          running: prevState.running.set(
            _toString(segment_export.segment_export_destination_id),
            new ExportModel(segment_export),
          ),
        }))

        // Refresh numbers
        initSegmentNumbers(segment.id, true)
        this.context.emit("segment_counts", { segment_id: segment.id, refresh_cache: true })

        this.props.showToast("Export initiated.", TOAST.TYPE.SUCCESS)
      })
      .catch(() => {
        this.fetchExportsInitialRows()
        this.setState(prevState => ({
          buttonLoading: prevState.buttonLoading.set(destinationId, false),
        }))
      })
  }

  loadMoreSegmentExports = () => {
    this.setState({
      loadMoreButtonLoading: true,
    })
    const { segment, segmentExports, fetchSegmentExportsList } = this.props
    fetchSegmentExportsList(
      segment.id,
      segmentExports.getIn(["selectionSettings", "offset"]) + SEGMENT.EXPORT.ITEMS_PER_PAGE,
      SEGMENT.EXPORT.ITEMS_PER_PAGE,
    )
      .then(() => {
        this.setState({
          loadMoreButtonLoading: false,
        })
      })
      .catch(() => {
        this.setState({
          loadMoreButtonLoading: false,
        })
      })
  }

  modifySegmentScheduling = (exportDestinationId, schedules, confirmed = false) => {
    const { segment, modifySegmentDeprecated, modifySegment, isFeaturedSegment, isSmartSegment } =
      this.props
    let scheduling = segment.getIn(["settings", "scheduling"])
    if (scheduling) {
      if (_isEmpty(schedules)) {
        // erase scheduling
        let resultScheduling = scheduling.map(setSchedule => {
          const destinations = setSchedule.get("destination_ids")
          if (destinations.includes(exportDestinationId)) {
            if (destinations.size === 1) {
              // erase schedule
              return null
            } else {
              // erase destination only
              return setSchedule.set(
                "destination_ids",
                setSchedule.get("destination_ids").filterNot(id => id === exportDestinationId),
              )
            }
          }
          return setSchedule
        })
        resultScheduling = resultScheduling.filterNot(val => val === null)
        const settings =
          resultScheduling.size === 0
            ? segment.settings.delete("scheduling")
            : segment.settings.set("scheduling", resultScheduling)
        if (isFeaturedSegment || isSmartSegment) {
          return modifySegment(
            segment.id,
            { settings: settings.toJS() },
            isFeaturedSegment ? "featured" : "smart",
          )
        } else {
          return modifySegmentDeprecated(segment.id, { settings: settings.toJS() })
        }
      } else {
        // 3 options, schedule settings is shared / schedule settings is single / schedule settings is new
        let resultScheduling = scheduling.map(setSchedule => {
          if (setSchedule.get("destination_ids").includes(exportDestinationId)) {
            // omit and create new entry
            if (setSchedule.get("destination_ids").size === 1) {
              return null
            } else {
              return setSchedule.set(
                "destination_ids",
                setSchedule.get("destination_ids").filterNot(id => id === exportDestinationId),
              )
            }
          }
          return setSchedule
        })
        resultScheduling = resultScheduling.filterNot(val => val === null)
        const immutableSchedules = fromJS(schedules)
        let added = false
        resultScheduling = resultScheduling.map(setSchedule => {
          if (setSchedule.get("schedules").equals(immutableSchedules)) {
            // schedules equals, push to array of dest ids
            added = true
            return setSchedule.set(
              "destination_ids",
              setSchedule.get("destination_ids").push(exportDestinationId),
            )
          }
          return setSchedule
        })
        if (!added) {
          resultScheduling = resultScheduling.push(
            Map({
              destination_ids: List([exportDestinationId]),
              schedules: immutableSchedules,
            }),
          )
        }
        const settings = segment.settings.set("scheduling", resultScheduling)
        if (isFeaturedSegment || isSmartSegment) {
          return modifySegment(
            segment.id,
            { settings: settings.toJS() },
            isFeaturedSegment ? "featured" : "smart",
          )
        } else {
          return modifySegmentDeprecated(segment.id, { settings: settings.toJS() })
        }
      }
    } else {
      // initial scheduling set
      if (!_isEmpty(schedules)) {
        let settings
        if (Map.isMap(segment.settings)) {
          settings = segment.settings.set("scheduling", [
            { destination_ids: [exportDestinationId], schedules },
          ])
        } else {
          settings = Map({
            scheduling: [{ destination_ids: [exportDestinationId], schedules }],
          })
        }
        if (isFeaturedSegment || isSmartSegment) {
          return modifySegment(
            segment.id,
            { settings: settings.toJS() },
            isFeaturedSegment ? "featured" : "smart",
          )
        } else {
          return modifySegmentDeprecated(segment.id, { settings: settings.toJS() })
        }
      }
    }
  }

  modifySegmentDestinationParameters = dstObj => {
    const { segment, modifySegmentDeprecated, modifySegment, isFeaturedSegment, isSmartSegment } =
      this.props
    let dataToSend = {
      settings: {
        destination_parameters: dstObj,
      },
    }
    if (Map.isMap(segment.settings)) {
      const settings = segment.settings.toJS()
      if (Map.isMap(segment.getIn(["settings", "destination_parameters"]))) {
        // merge with destination_parameters
        dataToSend = {
          settings: {
            ...settings,
            destination_parameters: {
              ...settings.destination_parameters,
              ...dstObj,
            },
          },
        }
      } else {
        // merge destination_parameters with existing settings
        dataToSend = {
          settings: {
            ...settings,
            destination_parameters: dstObj,
          },
        }
      }
    }
    if (isFeaturedSegment || isSmartSegment) {
      return modifySegment(segment.id, dataToSend, isFeaturedSegment ? "featured" : "smart")
    } else {
      return modifySegmentDeprecated(segment.id, dataToSend)
    }
  }

  render() {
    const {
      exportDestinations,
      isEditable,
      segment,
      segmentsNumbers,
      segmentExports,
      isSmartSegment,
      isFeaturedSegment,
    } = this.props
    const { running, buttonLoading, loadMoreButtonLoading } = this.state

    const hasExportLogs =
      List.isList(segmentExports.get("data")) && segmentExports.get("data").size > 0

    return (
      <div className="segment-export">
        {exportDestinations && exportDestinations.size > 0 && (
          <div>
            <PaperHeader size="small" titleText="export destinations" />
            <Paper className="segment-export-settings" hasHeader={true}>
              <table className="table segment-export-table">
                <thead>
                  <tr>
                    <th>Destination</th>
                    <th>
                      Exportable{" "}
                      <InfoTooltip placement="right" interactive>
                        Segmented customers available for each export destinations.{" "}
                        <a
                          href="https://docs.meiro.io/books/meiro-business-explorer/page/export-out-of-available-for-the-destination--what-does-it-mean"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Learn more
                        </a>
                        .
                      </InfoTooltip>
                    </th>
                    <th>Schedule</th>
                    <th>Parameters</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {exportDestinations.toList().map(destination => {
                    const destinationStatus = running.getIn([_toString(destination.id), "status"])
                    let segmentNumberDestinationIndex = -1
                    if (List.isList(segmentsNumbers.get("export_destinations_results_counts"))) {
                      segmentNumberDestinationIndex = segmentsNumbers
                        .get("export_destinations_results_counts")
                        .findIndex(dst => dst.get("export_destination_id") === destination.id)
                    }

                    let schedules = null
                    const scheduling = segment.getIn(["settings", "scheduling"])
                    if (List.isList(scheduling)) {
                      scheduling.forEach(setSchedule => {
                        if (setSchedule.get("destination_ids").includes(+destination.id)) {
                          schedules = setSchedule.get("schedules").toJS()
                        }
                      })
                    }

                    let countOfRequiredParameters = 0
                    let countOfFilledParameters = 0
                    const destinationParameters = segment.getIn(
                      ["settings", "destination_parameters", _toString(destination.id)],
                      Map(),
                    )
                    const miWorkspaceVariables = destination.getIn(
                      ["settings", "mi_workspace_variables"],
                      List(),
                    )
                    miWorkspaceVariables.forEach(param => {
                      if (param.get("required")) {
                        countOfRequiredParameters += 1
                        if (destinationParameters.get(param.get("name"))) {
                          countOfFilledParameters += 1
                        }
                      }
                    })

                    return (
                      <tr key={destination.id} className="segment-export-settings-row">
                        <td className="destination">
                          <div className="destination-chip-wrapper">
                            <div
                              className={`destination-chip ${destination.getIn([
                                "frontend_settings",
                                "color",
                              ])}`}
                            >
                              <img
                                src={getIconSrc(
                                  destination.icon,
                                  destination.getIn(["frontend_settings", "alt_icon"]),
                                  true,
                                )}
                                alt="icon"
                                className="destination-icon"
                              />
                            </div>
                            <div className="destination-info">{destination.name}</div>
                          </div>
                        </td>
                        <td className="segmentation-numbers-col">
                          <SegmentationNumbers
                            totalNumber={
                              segmentNumberDestinationIndex === -1
                                ? undefined
                                : segmentsNumbers.getIn([
                                    "export_destinations_results_counts",
                                    segmentNumberDestinationIndex,
                                    "total_count",
                                  ])
                            }
                            segmentedNumber={
                              segmentNumberDestinationIndex === -1
                                ? undefined
                                : segmentsNumbers.getIn([
                                    "export_destinations_results_counts",
                                    segmentNumberDestinationIndex,
                                    "count",
                                  ])
                            }
                            isLoading={
                              !_isNumber(
                                segmentNumberDestinationIndex === -1
                                  ? undefined
                                  : segmentsNumbers.getIn([
                                      "export_destinations_results_counts",
                                      segmentNumberDestinationIndex,
                                      "total_count",
                                    ]),
                              ) ||
                              !_isNumber(
                                segmentNumberDestinationIndex === -1
                                  ? undefined
                                  : segmentsNumbers.getIn([
                                      "export_destinations_results_counts",
                                      segmentNumberDestinationIndex,
                                      "count",
                                    ]),
                              )
                            }
                            uniqueDataTipId={`tooltip-destination-${destination.id}`}
                            showLabel={false}
                            error={segmentsNumbers.get("error")}
                          />
                        </td>
                        <td>
                          {destination.id === 2 ? (
                            "—"
                          ) : (
                            <Scheduler
                              exportDestinationId={destination.id}
                              schedules={schedules}
                              onSaveSchedule={this.modifySegmentScheduling}
                              showToast={this.props.showToast}
                              isEditable={isEditable}
                            />
                          )}
                        </td>
                        <td>
                          {
                            <ParametersController
                              exportDestinationId={destination.id}
                              initialValues={destinationParameters.toJS()}
                              onSaveParameters={this.modifySegmentDestinationParameters}
                              fieldsConfig={miWorkspaceVariables}
                              countOfRequiredParameters={countOfRequiredParameters}
                              countOfFilledParameters={countOfFilledParameters}
                              showToast={this.props.showToast}
                              form={`DestinationParametersForm[${destination.id}]`}
                              isEditable={isEditable}
                            />
                          }
                        </td>
                        <td>
                          <div className="export-actions">
                            <Tippy
                              content={
                                "You need to fill all the required parameters to be able to run export."
                              }
                              disabled={countOfFilledParameters >= countOfRequiredParameters}
                            >
                              <div>
                                <Button
                                  color={destinationStatus ? destinationStatus : "primary"}
                                  size="small"
                                  disabled={countOfFilledParameters < countOfRequiredParameters}
                                  onClick={
                                    destinationStatus
                                      ? this.cancelExportByDestinationId(destination.id)
                                      : this.runSegmentExport(destination.id)
                                  }
                                  className={`export-button ${
                                    buttonLoading.get(destination.id) ? "loading" : ""
                                  }`}
                                >
                                  {destinationStatus && (
                                    <>
                                      <FontAwesomeIcon
                                        icon={["far", "hourglass"]}
                                        spin={destinationStatus === "waiting"}
                                        className="icon"
                                      />
                                      Cancel
                                    </>
                                  )}
                                  {!destinationStatus && "Export"}
                                </Button>
                              </div>
                            </Tippy>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Paper>
            <PromotionBar />

            <PaperHeader
              className="segment-export-history-header"
              titleText="exports history"
              size="small"
            />
            <Paper className="segment-export-history" hasHeader={true}>
              {!hasExportLogs && (
                <div className="no-exports-message">There are no exports yet.</div>
              )}
              {hasExportLogs && (
                <>
                  <Table className="segment-export-history-table">
                    <Thead>
                      <Th className="history-th">Export history</Th>
                      <Th className="history-th">User</Th>
                      <Th className="history-th">Destination</Th>
                      <Th className="history-th">Exported</Th>
                      <Th textAlignRight className="history-th">
                        Status
                      </Th>
                      <Th textAlignCenter className="one-icon history-th">
                        Log
                      </Th>
                      <Th textAlignCenter className="one-icon history-th">
                        CSV
                      </Th>
                    </Thead>
                    <Tbody>
                      {segmentExports.get("data").map(segmentExport => (
                        <Tr key={segmentExport.id} className="history-row">
                          <Td className="history-cell" textBlack textBigger textBold>
                            {segmentExport.name}
                          </Td>
                          <Td className="history-cell">
                            <Username userId={segmentExport.user_id} />
                          </Td>
                          <Td className="history-cell">
                            {exportDestinations.getIn([
                              _toString(segmentExport.segment_export_destination_id),
                              "name",
                            ])}
                          </Td>
                          <Td className="history-cell">
                            {moment
                              .utc(segmentExport.created)
                              .local()
                              .format(MOMENT.DATETIME_FORMAT)}
                          </Td>
                          <Td
                            textAlignRight
                            className={`status history-cell ${segmentExport.status}`}
                          >
                            {segmentExport.status}
                          </Td>
                          <Td textAlignCenter className="history-cell">
                            <DelayedTooltip content="View log">
                              <Link
                                to={{
                                  pathname: getRoutePath(
                                    isSmartSegment
                                      ? "segments.smart.export.detail"
                                      : isFeaturedSegment
                                      ? "segments.featured.export.detail"
                                      : "segments.export.detail",
                                    {
                                      id: segment.id,
                                      eid: segmentExport.id,
                                    },
                                  ),
                                  state: { previous: true },
                                }}
                                className="log-icon icon-button with-background primary size-tag"
                              >
                                <FontAwesomeIcon icon={["far", "file-alt"]} />
                              </Link>
                            </DelayedTooltip>
                          </Td>
                          <Td textAlignCenter className="history-cell">
                            {segmentExport.segment_export_destination_id === 2 &&
                              segmentExport.status === "finished" && (
                                // download csv file, id = 2 is guaranteed by instalator
                                <IconButton
                                  size={SIZE.TAG}
                                  withBackground
                                  className="download-icon"
                                  href={`${BASE_API_URL}/segments/${segment.id}/exports/${segmentExport.id}/download?file_download_token=${segmentExport.file_download_token}`}
                                  download
                                  target="_blank"
                                  type="anchor"
                                  iconStyle="far"
                                  iconName="download"
                                  tooltip="Download export"
                                />
                              )}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>

                  {segmentExports.get("hasMoreItems") && (
                    <div className="load-more-button-wrapper">
                      <Button
                        size="small"
                        color="white"
                        className={loadMoreButtonLoading ? "loading" : ""}
                        onClick={this.loadMoreSegmentExports}
                      >
                        Show more
                      </Button>
                    </div>
                  )}
                </>
              )}
              {this.state.isLoading && <LoadingIndicator />}
            </Paper>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  exportDestinations: getExportDestinationsData(state),
  segmentExports: getSegmentExports(state, ownProps.segment.id),
})

SegmentExports.propTypes = {
  segment: PropTypes.instanceOf(Record).isRequired,
  exportDestinations: PropTypes.instanceOf(Map),
  segmentsNumbers: PropTypes.instanceOf(Map).isRequired,
  isEditable: PropTypes.bool.isRequired,
  segmentExports: PropTypes.instanceOf(Map).isRequired,
  runSegmentExport: PropTypes.func.isRequired,
  retrieveSegmentExport: PropTypes.func.isRequired,
  modifySegmentDeprecated: PropTypes.func.isRequired,
  isSegmentDeleting: PropTypes.bool.isRequired,
  isSmartSegment: PropTypes.bool,
}

export default connect(mapStateToProps, {
  showToast,
  fetchSegmentExportsList,
  runSegmentExport,
  retrieveSegmentExport,
  modifySegmentDeprecated,
  modifySegment,
  initSegmentNumbers,
})(SegmentExports)
