import { CUSTOMER } from "sharedConstants"
import { api } from "api"

export const fulltextCustomerSearch =
  (searchText, offset, limit, loadFullStructure = 0) =>
  async dispatch =>
    dispatch({
      type: CUSTOMER.ACTION.SEARCH_VALUE,
      payload: {
        promise: (async () => {
          return await api.customer.search.fulltext(searchText, offset, limit, loadFullStructure)
        })(),
      },
    })

export const listFavoriteCustomers =
  (offset, limit, loadFullStructure = 0) =>
  async dispatch =>
    dispatch({
      type: CUSTOMER.ACTION.LIST_FAVORITE_CUSTOMERS,
      payload: api.customer.list.favorite(offset, limit, loadFullStructure),
    })

export const fulltextCustomerAttributeSearch =
  (attributeId, searchValue = "", offset, limit, loadFullStructure = 0) =>
  async dispatch =>
    dispatch({
      type: CUSTOMER.ACTION.SEARCH_VALUE_IN_ATTRIBUTE,
      payload: {
        promise: (async () => {
          const response = await api.customer.attribute.search(
            attributeId,
            searchValue,
            offset,
            limit,
            loadFullStructure,
          )
          return {
            search_result: response.search_result,
            selection_settings: response.selection_settings,
            selectedAttributeId: attributeId,
            customers_total_count: response.customers_total_count,
            additional_attribute: response.additional_attribute,
          }
        })(),
      },
    })

export const resetCustomerSearchResults = () => dispatch =>
  dispatch({
    type: CUSTOMER.ACTION.SEARCH_RESULTS_RESET,
  })
