import { TrashItemType } from "pages/Trash/trashTypes"
import getReadableItemType from "pages/Trash/utilities/getReadableItemType"
import { sortBy, prop } from "ramda"

export type TrashFilterOption = {
  label: string
  value: TrashItemType
  selectionPriority: number
}

export const MAX_SELECTION_PRIORITY = 3

const getTrashFilterOptionPriority = (itemType: TrashItemType) => {
  switch (itemType) {
    case "segments":
      return MAX_SELECTION_PRIORITY
    case "users":
      return MAX_SELECTION_PRIORITY - 1
    default:
      return MAX_SELECTION_PRIORITY - 2
  }
}

const getTrashFilterOptionLabel = (itemType: TrashItemType, count: number): string => {
  return `${getReadableItemType(itemType, { plural: true })} (${count})`
}

const getTrashFilterOptions = (itemsCounts: Record<TrashItemType, number>): TrashFilterOption[] =>
  sortBy(
    prop("label"),
    (Object.keys(itemsCounts) as TrashItemType[]).map(trashItemType => ({
      label: getTrashFilterOptionLabel(trashItemType, itemsCounts[trashItemType]),
      value: trashItemType,
      selectionPriority: getTrashFilterOptionPriority(trashItemType),
    })),
  )

export default getTrashFilterOptions
