import React, { useState, useRef, useEffect } from "react"
import "./DelayedTooltip.scss"
import { CSSTransition } from "react-transition-group"

export default ({ children, content, className = null }) => {
  const [active, setActive] = useState(false)
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const showTip = () => {
    if (isMounted.current) {
      setActive(true)
    }
  }

  const hideTip = () => {
    if (isMounted.current) {
      setActive(false)
    }
  }

  return (
    <div
      className={`tooltip-wrapper ${className ? className : ""}`}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      <CSSTransition in={active} timeout={750} classNames="delayedFade" unmountOnExit>
        <div className="tooltip-tip">{content}</div>
      </CSSTransition>
    </div>
  )
}
