export const getCustomerSourceIdentification = (
  attributesMapBySourceId,
  customerAttributes,
  sources,
) => {
  const identifiedInSources = sources.map(source => {
    const list = attributesMapBySourceId[source.id]
    if (Array.isArray(list)) {
      return list.some(src => customerAttributes.has(src.id))
    }
    return false
  })
  return identifiedInSources
}
