import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Field } from "redux-form"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _get from "lodash/get"
import _toInteger from "lodash/toInteger"
import styles from "./Login.module.scss"
import { showToast } from "actions/toast.action"
import AuthFormLayout from "components/AuthFormLayout/AuthFormLayout"
import TextField from "components/UI/elements/TextField"
import Button from "components/UI/elements/Button/Button"
import { required, email } from "helpers/validators.helper"
import { login } from "actions/authenticatedUser.action"
import { getRoutePath } from "routes"
import PendingPromise from "helpers/pendingPromise.helper"
import { api } from "api"
import ReCAPTCHA from "react-google-recaptcha"
import classnames from "classnames"

import okta from "images/white-okta-logo.png"

let credentialsLoginEnabled = 0,
  oktaLoginEnabled = 0,
  recaptchaSiteKey = null
if (process.env.NODE_ENV === "production") {
  credentialsLoginEnabled = "[[CREDENTIALS_LOGIN_ENABLED]]"
  oktaLoginEnabled = "[[OKTA_LOGIN_ENABLED]]"
  recaptchaSiteKey = "[[RECAPTCHA_SITE_KEY]]"
} else {
  credentialsLoginEnabled = process.env.REACT_APP_CREDENTIALS_LOGIN_ENABLED
  oktaLoginEnabled = process.env.REACT_APP_OKTA_LOGIN_ENABLED
  recaptchaSiteKey =
    process.env.REACT_APP_RECAPTCHA_SITE_KEY === '""'
      ? ""
      : process.env.REACT_APP_RECAPTCHA_SITE_KEY
}

class Login extends Component {
  constructor(props) {
    super(props)
    let showGeneralTermsCheckbox = false
    let meiroGeneralTermsAccepted
    try {
      meiroGeneralTermsAccepted = window.localStorage.getItem("cdpGeneralTermsAccepted")
    } catch (err) {}
    if (meiroGeneralTermsAccepted !== "true") {
      showGeneralTermsCheckbox = true
    }
    this.state = {
      showGeneralTermsCheckbox,
      generalTermsCheckboxValue: false,
      auth: {
        options: null,
        loading: true,
      },
      recaptchaToken: "",
      showRecaptcha: false,
    }
    this.pendingPromises = new PendingPromise()
  }

  componentDidMount() {
    const authOptionsRequest = this.pendingPromises.create(api.authOptions())
    authOptionsRequest.promise
      .then(response => {
        this.setState({
          auth: {
            options: response,
            loading: false,
          },
        })
        this.pendingPromises.remove(authOptionsRequest)
      })
      .catch(error => {
        if (!_get(error, "isCanceled")) {
          this.setState(prevState => ({
            auth: {
              ...prevState.auth,
              loading: false,
            },
          }))
        }
        this.pendingPromises.remove(authOptionsRequest)
      })

    document.body.classList.add("toastify-on-top")
  }

  componentWillUnmount() {
    this.pendingPromises.cancelAll()
    document.body.classList.remove("toastify-on-top")
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.auth.loading && prevState.auth.loading && this.state.oktaLoading) {
      this.redirectToOktaAuthLink()
    }
  }

  onSubmit = values => {
    if (!this.state.loading) {
      const { recaptchaToken } = this.state
      if (recaptchaToken) {
        values.recaptcha_token = recaptchaToken
      }
      this.setState({ loading: true })
      this.props
        .login(values)
        .then(() => {
          try {
            if (window.localStorage.getItem("cdpGeneralTermsAccepted") !== "true") {
              window.localStorage.setItem("cdpGeneralTermsAccepted", "true")
            }
          } catch (err) {}
        })
        .catch(error => {
          if (error.response?.data?.show_recaptcha) {
            this.setState({
              showRecaptcha: true,
              loading: false,
            })
          } else {
            this.setState({ loading: false })
          }
        })
    }
  }

  redirectToOktaAuthLink = () => {
    const { auth } = this.state
    const oktaLink = _get(auth, "options.okta_auth_link")
    window.open(oktaLink, "_self")
  }

  loginWithOkta = () => {
    try {
      const urlParams = new URLSearchParams(this.props.location.search)
      const redirect = urlParams.get("redirect")
      window.localStorage.setItem("redirectAfterOktaLoginTo", redirect)
    } catch (err) {}
    if (this.state.auth.loading) {
      this.setState({
        oktaLoading: true,
      })
    } else {
      this.redirectToOktaAuthLink()
    }
  }

  toggleGeneralTermsCheckboxValue = () => {
    this.setState(prevState => ({
      generalTermsCheckboxValue: !prevState.generalTermsCheckboxValue,
    }))
  }

  renderTermsGuidelinesCheckbox = () => {
    const { showGeneralTermsCheckbox, generalTermsCheckboxValue } = this.state
    if (showGeneralTermsCheckbox) {
      return (
        <div className={classnames("form-row", "checkbox-field", styles.generalTerms)}>
          <label className={styles.checkboxLabel}>
            I consent with{" "}
            <a href="https://meiro.io/general-terms/" target="_blank" rel="noopener noreferrer">
              Meiro General Terms
            </a>{" "}
            and{" "}
            <a
              href="https://meiro.io/user-security-guidelines/"
              target="_blank"
              rel="noopener noreferrer"
            >
              User Security Guidelines
            </a>
            <input
              type="checkbox"
              checked={generalTermsCheckboxValue}
              onChange={this.toggleGeneralTermsCheckboxValue}
              className="checkbox"
            />
            <span className="checkmark" data-testid="agreement-checkbox">
              <FontAwesomeIcon className="icon-check" icon={["fas", "check"]} />
            </span>
          </label>
        </div>
      )
    }
  }

  setRecaptchaToken = recaptchaToken => {
    this.setState({ recaptchaToken })
  }

  render() {
    const { handleSubmit } = this.props
    const { showGeneralTermsCheckbox, generalTermsCheckboxValue, showRecaptcha, recaptchaToken } =
      this.state

    const showLoginForm = _toInteger(credentialsLoginEnabled) === 1
    const showOktaButton = _toInteger(oktaLoginEnabled) === 1

    return (
      <AuthFormLayout>
        {showLoginForm && (
          <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)} className={styles.form}>
            <h2>Login</h2>
            <div className="form-row">
              <Field
                name="email"
                component={TextField}
                label="Email"
                validate={[required, email]}
                fullWidth={true}
                placeholder="Email"
                autoFocus
              />
            </div>
            <div className="form-row">
              <Field
                name="password"
                component={TextField}
                label="Password"
                placeholder="Password"
                validate={required}
                fullWidth={true}
                type="password"
              />
            </div>
            {showRecaptcha && recaptchaSiteKey && (
              <div className={classnames("form-row", styles.recaptcha)}>
                <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={this.setRecaptchaToken} />
              </div>
            )}

            <div
              className={classnames(styles.actions, {
                [styles.withCheckbox]: showGeneralTermsCheckbox,
              })}
            >
              <Button
                data-testid="login-button"
                type="submit"
                color="primary"
                size="big"
                fullWidth={true}
                className={classnames({ loading: this.state.loading })}
                disabled={
                  (showGeneralTermsCheckbox && !generalTermsCheckboxValue) ||
                  (showRecaptcha && !recaptchaToken)
                }
              >
                Login
              </Button>
              {true && (
                <Button
                  type="button"
                  color="blue"
                  size="big"
                  onClick={this.loginWithOkta}
                  className={classnames(styles.oktaLogin, styles.secondButton, {
                    loading: this.state.oktaLoading,
                    [styles.hideLogo]: this.state.oktaLoading,
                  })}
                  disabled={showGeneralTermsCheckbox && !generalTermsCheckboxValue}
                >
                  Login with <img src={okta} alt="" />
                </Button>
              )}
              {this.renderTermsGuidelinesCheckbox()}
              <Link
                className={styles.underformLink}
                data-testid="forgot-password-link"
                to={getRoutePath("password.reset")}
              >
                Forgot password?
              </Link>
            </div>
          </form>
        )}
        {!showLoginForm && showOktaButton && (
          <div className={styles.oktaWrapper}>
            <Button
              type="button"
              color="blue"
              size="big"
              onClick={this.loginWithOkta}
              className={classnames(styles.oktaLogin, {
                loading: this.state.oktaLoading,
                [styles.hideLogo]: this.state.oktaLoading,
              })}
              disabled={showGeneralTermsCheckbox && !generalTermsCheckboxValue}
            >
              Login with <img src={okta} alt="" />
            </Button>
            {this.renderTermsGuidelinesCheckbox()}
          </div>
        )}
      </AuthFormLayout>
    )
  }
}

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
}

Login = reduxForm({
  form: "LoginForm",
  touchOnBlur: false,
})(connect(null, { login, showToast })(Login))

export default Login
