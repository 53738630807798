import { Map, List, fromJS } from "immutable"
import { SEGMENT } from "./segmentNumbersActions"
import _isNumber from "lodash/isNumber"
import _set from "lodash/set"
import _isEmpty from "lodash/isEmpty"
import _toString from "lodash/toString"
import _has from "lodash/has"

/**
 * The store holds data only from one segment at the time
 *
 * filled state example:
 * Map({
 *  segmentId: Map({
 *    data_load_start_time: datetime,
 *    data_load_end_time: datetime,
 *    conditions_numbers: List(900, 346),
 *    conditions_numbers_struct: array
 *    conditions_results_count: 900,
 *    customers_total_count: 34167,
 *    export_destinations_results_counts: List(
 *      Map({
 *        count: 900,
 *        export_destination_id: number
 *      })
 *    ),
 *  })
 * })
 */
const initialState = Map()

const transformConditionsNumbersToList = conditionsNumbers => {
  if (_isNumber(conditionsNumbers)) {
    return List([conditionsNumbers])
  } else if (List.isList(conditionsNumbers)) {
    return conditionsNumbers.flatten(0)
  } else {
    return List()
  }
}

export default (state = initialState, { type, meta, payload }) => {
  switch (type) {
    case SEGMENT.NUMBER.ACTION.ERROR: {
      return state.setIn([meta.segmentId, "error"], true)
    }
    case SEGMENT.NUMBER.ACTION.INIT: {
      if (!state.has(meta.segmentId) || payload.forceInit) {
        return Map({
          [meta.segmentId]: Map({
            error: false,
            conditions_numbers: List([]),
            conditions_numbers_struct: [],
            conditions_results_count: null,
            customers_total_count: null,
            export_destinations_results_counts: List([]),
          }),
        })
      }
      return state
    }
    case SEGMENT.NUMBER.ACTION.SET: {
      // ignore messages from different segment
      if (!state.has(_toString(payload.segment_id))) return state
      switch (payload.count_type) {
        case "condition_results_count": {
          const conditions_numbers_struct = state.getIn([
            meta.segmentId,
            "conditions_numbers_struct",
          ])
            ? [...state.getIn([meta.segmentId, "conditions_numbers_struct"])]
            : []
          const path = _isEmpty(payload.condition_position)
            ? "[0]"
            : payload.condition_position.map(v => `[${v}]`).join("")
          _set(conditions_numbers_struct, path, payload.count)
          return state
            .setIn(
              [meta.segmentId, "conditions_numbers"],
              transformConditionsNumbersToList(List(fromJS(conditions_numbers_struct))),
            )
            .setIn([meta.segmentId, "conditions_numbers_struct"], conditions_numbers_struct)
        }
        case "customers_total_count":
          return state.setIn([meta.segmentId, "customers_total_count"], payload.count)
        case "segment_results_count":
          return state.setIn([meta.segmentId, "conditions_results_count"], payload.count)
        case "export_destination_results_count": {
          const index = state
            .getIn([meta.segmentId, "export_destinations_results_counts"])
            .findIndex(dst => dst.get("export_destination_id") === payload.destination_id)
          if (index !== -1) {
            return state
              .setIn(
                [meta.segmentId, "export_destinations_results_counts", index, "count"],
                payload.count,
              )
              .setIn(
                [meta.segmentId, "export_destinations_results_counts", index, "total_count"],
                payload.total_count,
              )
          } else {
            return state.setIn(
              [meta.segmentId, "export_destinations_results_counts"],
              state.getIn([meta.segmentId, "export_destinations_results_counts"]).push(
                Map({
                  count: payload.count,
                  export_destination_id: payload.destination_id,
                  total_count: payload.total_count,
                }),
              ),
            )
          }
        }
        default:
          if (_has(payload, "data_load_end_time") && _has(payload, "data_load_start_time")) {
            return state
              .setIn([meta.segmentId, "data_load_start_time"], payload.data_load_start_time)
              .setIn([meta.segmentId, "data_load_end_time"], payload.data_load_end_time)
          }
          return state
      }
    }

    case SEGMENT.NUMBER.ACTION.CHANGE_CONDITIONS_NUMBER_POSITION: {
      const segmentNumbers = state.get(meta.segmentId)
      const temp = segmentNumbers.getIn(["conditions_numbers", payload.fromIndex])
      let swappedSegmentsConditionsNumbers = segmentNumbers.set(
        "conditions_numbers",
        segmentNumbers.get("conditions_numbers").splice(payload.fromIndex, 1),
      )
      swappedSegmentsConditionsNumbers = swappedSegmentsConditionsNumbers.set(
        "conditions_numbers",
        swappedSegmentsConditionsNumbers.get("conditions_numbers").splice(payload.toIndex, 0, temp),
      )
      return state.set(meta.segmentId, swappedSegmentsConditionsNumbers)
    }

    case SEGMENT.NUMBER.ACTION.RESET_GLOBAL: {
      return state.setIn([meta.segmentId, "conditions_results_count"], -1).setIn(
        [meta.segmentId, "export_destinations_results_counts"],
        state
          .getIn([meta.segmentId, "export_destinations_results_counts"])
          .map(dst => dst.set("count", -1)),
      )
    }

    case SEGMENT.NUMBER.ACTION.RESET_PARTICULAR: {
      if (state.hasIn([meta.segmentId, "conditions_numbers", payload.index])) {
        return state.setIn([meta.segmentId, "conditions_numbers", payload.index], -1)
      }
      return state
    }

    case SEGMENT.NUMBER.ACTION.DELETE_PARTICULAR: {
      if (state.hasIn([meta.segmentId, "conditions_numbers", payload.index])) {
        return state.deleteIn([meta.segmentId, "conditions_numbers", payload.index])
      }
      return state
    }

    case SEGMENT.NUMBER.ACTION.ADD_LAST: {
      if (state.hasIn([meta.segmentId, "conditions_numbers"])) {
        return state.setIn(
          [meta.segmentId, "conditions_numbers"],
          state.getIn([meta.segmentId, "conditions_numbers"]).push(-1),
        )
      }
      return state
    }

    default:
      return state
  }
}
