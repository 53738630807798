import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import React, { useState } from "react"
import { useHistory, useParams } from "react-router"
import { getRoutePath } from "routes"

import {
  useDeletePopupWB,
  useFetchPopupWBById,
  useModifyPopupWB,
} from "resources/webBanner/popupWebBanner/popupWBQueries"
import { formDataToModifyPayload } from "../utilities/formDataToModifyPayload"
import { popupWBToFormValues } from "../utilities/popupWBToFormValues"
import { PopupWebBanner } from "resources/webBanner/popupWebBanner/popupWBTypes"
import { PopupWebBannersFormData } from "../popupWBComponentTypes"
import PopupWebBannersForm from "../components/PopupWebBannersForm/PopupWebBannersForm"

export default function PopupWebBannersDetail() {
  const history = useHistory()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { id } = useParams<{ id: PopupWebBanner["id"] }>()

  const { data: webBanner, isLoading, isError } = useFetchPopupWBById(id)
  const deleteMutation = useDeletePopupWB()
  const modifyMutation = useModifyPopupWB()

  const modifyWebBanner = (formData: PopupWebBannersFormData) =>
    modifyMutation.mutateAsync(
      { id, data: formDataToModifyPayload(formData, webBanner!) },
      {
        onSuccess: () => {
          history.push(getRoutePath("personalization.popup-web-banners"))
        },
      },
    )

  const deleteBanner = () =>
    deleteMutation.mutate(
      { id },
      {
        onSuccess: () => {
          history.push(getRoutePath("personalization.popup-web-banners"))
        },
      },
    )

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (isError) {
    return null
  }

  return (
    <>
      <PopupWebBannersForm
        key={id}
        initialValues={popupWBToFormValues(webBanner)}
        onSubmit={modifyWebBanner}
        onDelete={() => {
          setDeleteModalOpen(true)
        }}
      />
      <ConfirmModal
        title="Are you sure?"
        open={deleteModalOpen}
        type="delete"
        text={`Do you really want to delete web banner?`}
        handleClose={() => {
          setDeleteModalOpen(false)
        }}
        handleConfirm={deleteBanner}
        isLoading={deleteMutation.isLoading}
      />
    </>
  )
}
