import React from "react"
import { useHistory } from "react-router"
import { getRoutePath } from "routes"

import { useCreatePopupWB } from "resources/webBanner/popupWebBanner/popupWBQueries"
import { formDataToCreatePayload } from "../utilities/formDataToCreatePayload"
import { PopupWebBannersFormData } from "../popupWBComponentTypes"
import PopupWebBannersForm from "../components/PopupWebBannersForm/PopupWebBannersForm"

export default function PopupWebBannersCreate() {
  const history = useHistory()

  const createMutation = useCreatePopupWB()

  const createBanner = (formData: PopupWebBannersFormData) =>
    createMutation.mutateAsync(
      { data: formDataToCreatePayload(formData) },
      {
        onSuccess: () => {
          history.push(getRoutePath("personalization.popup-web-banners"))
        },
      },
    )

  return <PopupWebBannersForm onSubmit={createBanner} />
}
