import { isEmpty as _isEmpty } from "lodash"
import {
  PopupWebBanner,
  PopupWebBannerModifyPayload,
  PopupWebBannerRequestPayload,
  WBMinimizedHideCondition,
} from "resources/webBanner/popupWebBanner/popupWBTypes"
import { PopupWebBannersFormData } from "../popupWBComponentTypes"

export function formDataToModifyPayload(
  formData: PopupWebBannersFormData,
  webBanner: PopupWebBanner,
): PopupWebBannerModifyPayload {
  const {
    name,
    condition,
    frequency_cap,
    priority,
    position,
    image_local,
    image_name,
    image_remote,
    html,
    destination_url,
    width,
    minimized_width,
    close_button,
    minimized_close_button,
    disabled,
    minimized,
    minimized_image_local,
    minimized_image_name,
    minimized_image_remote,
    minimized_html,
    minimized_position,
    minimized_hide_values,
    minimized_hide_operator,
    display_minimized_first,
    margin_x,
    margin_y,
    minimized_margin_x,
    minimized_margin_y,
    animation,
    minimized_animation,
    display_on,
  } = formData

  const fe_settings = {
    width,
    minimized_width,
    closeButton: close_button,
    minimized_close_button,
    margins: {
      x: margin_x,
      y: margin_y,
      minimized_x: minimized_margin_x,
      minimized_y: minimized_margin_y,
    },
    animation,
    minimized_animation,
    display_on,
  }
  // destination_url needs to be present in image contents, otherwise it will be nullified :(
  const content: PopupWebBannerRequestPayload["settings"]["content"] = image_local
    ? {
        data: image_local,
        image_name: image_name!,
        type: "local_image",
        destination_url: destination_url === "" ? null : destination_url,
      }
    : image_remote
    ? {
        image_url: image_remote,
        type: "remote_image",
        destination_url: destination_url === "" ? null : destination_url,
      }
    : destination_url !== null
    ? {
        destination_url: destination_url === "" ? null : destination_url,
        type: webBanner?.settings.content.type,
      }
    : html
    ? { html, type: "html" }
    : {}
  const data: PopupWebBannerModifyPayload = {
    name,
    settings: {
      condition,
      frequency_cap,
      priority,
      position,
      fe_settings,
    },
    disabled,
  }
  if (content && !_isEmpty(content)) {
    // needs to be added optionally only when something changes, otherwise it removes
    // image from the storage ...
    data.settings!.content = content
  }
  const minimizedHideCondition: WBMinimizedHideCondition | null = minimized_hide_operator
    ? {
        operator: minimized_hide_operator.value,
        subject: "url",
        values: minimized_hide_values,
      }
    : null
  data.settings!.minimized_content = minimized_image_local
    ? {
        enabled: minimized,
        data: minimized_image_local,
        image_name: minimized_image_name!,
        type: "local_image",
        position: minimized_position,
        display_minimized_first,
        hide_condition: minimizedHideCondition,
      }
    : minimized_image_remote
    ? {
        enabled: minimized,
        image_url: minimized_image_remote,
        type: "remote_image",
        position: minimized_position,
        display_minimized_first,
        hide_condition: minimizedHideCondition,
      }
    : minimized_html
    ? {
        html: minimized_html,
        enabled: minimized,
        type: "html",
        position: minimized_position,
        display_minimized_first,
        hide_condition: minimizedHideCondition,
      }
    : {
        enabled: minimized,
        type: webBanner?.settings.minimized_content.type ?? "html",
        position: minimized_position,
        display_minimized_first,
        hide_condition: minimizedHideCondition,
      }

  return data
}
