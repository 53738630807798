import React from "react"
import io from "socket.io-client"

import { DEFAULT_BASE_URL } from "sharedConstants"

const wsHostUrl = () => {
  if (process.env.NODE_ENV === "production") {
    const { protocol, host } = window.location
    return `${protocol === "http:" ? "ws" : "wss"}://${host}`
  }

  const baseUrl = new URL(process.env.REACT_APP_BASE_URL ?? DEFAULT_BASE_URL)
  const wsProtocol = baseUrl.protocol === "http:" ? "ws" : "wss"
  const port = Number.isInteger(baseUrl.port) ? baseUrl.port : "3000"
  return `${wsProtocol}://${baseUrl.hostname}:${port}`
}

export const socket = io(wsHostUrl(), {
  path: "/wsapi/socket.io",
  autoConnect: false,
  transports: ["websocket"],
})

export const SocketContext = React.createContext()
