import { WORKSPACE } from "sharedConstants"
import _isArray from "lodash/isArray"

const initialState = {
  data: [],
  isFulfilled: false,
  isFetching: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${WORKSPACE.ACTION.LIST}_PENDING`: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case `${WORKSPACE.ACTION.LIST}_FULFILLED`:
      return {
        data: _isArray(payload) ? payload : [],
        isFulfilled: true,
        isFetching: false,
      }
    default:
      return state
  }
}
