import { DATA_SOURCES } from "./dataSourceActions"
import { Map, OrderedMap, Record, fromJS } from "immutable"
import _map from "lodash/map"
import _mapKeys from "lodash/mapKeys"
import _toString from "lodash/toString"

const DataSource = Record({
  id: 0,
  name: "",
  description: "",
  created: "",
  frontend_settings: null,
  type: "",
  is_hidden: 0,
})

const initialState = Map({
  data: OrderedMap(),
  isFulfilled: false,
  // isFetching is set to true because sources are loaded at the app start
  // from AuthenticatedLayout.js
  isFetching: true,
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${DATA_SOURCES.ACTION.LIST}_PENDING`: {
      return state.set("isFetching", true)
    }
    case `${DATA_SOURCES.ACTION.LIST}_FULFILLED`:
      return state
        .set(
          "data",
          OrderedMap(
            _mapKeys(
              _map(payload, dataSource => new DataSource(fromJS(dataSource))),
              "id",
            ),
          ),
        )
        .set("isFulfilled", true)
        .set("isFetching", false)
    case `${DATA_SOURCES.ACTION.MODIFY}_FULFILLED`: {
      return state.setIn(["data", payload.id], new DataSource(fromJS(payload)))
    }
    case `${DATA_SOURCES.ACTION.DELETE}_FULFILLED`:
      return state.set("data", state.get("data").delete(_toString(payload)))
    default:
      return state
  }
}
