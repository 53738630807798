import requestFactory from "api/request"
import { httpRequestToSegmentCondition, segmentToHttpRequestCondition } from "../utils"
import {
  EmbeddedWebBanner,
  EmbeddedWebBannerCreatePayload,
  EmbeddedWebBannerListResponse,
  EmbeddedWebBannerModifyPayload,
  EmbeddedWebBannerListDeletedParams,
  EmbeddedWebBannerListDeletedResponse,
} from "./embeddedWBTypes"

const embeddedWebBanner = {
  async list(offset = 0, limit = 50): Promise<EmbeddedWebBannerListResponse> {
    const result = await requestFactory("get", "/embedded_web_banners", { offset, limit })
    return {
      ...result,
      web_banners: result.web_banners.map(httpRequestToSegmentCondition),
    }
  },
  async retrieve(id: EmbeddedWebBanner["id"]): Promise<{ web_banner: EmbeddedWebBanner }> {
    const result = await requestFactory("get", `/embedded_web_banners/${id}`)
    return { web_banner: httpRequestToSegmentCondition(result.web_banner) }
  },
  create(data: EmbeddedWebBannerCreatePayload): Promise<{ web_banner: EmbeddedWebBanner }> {
    return requestFactory("post", "/embedded_web_banners", segmentToHttpRequestCondition(data))
  },
  async copy(id: EmbeddedWebBanner["id"]): Promise<{ web_banner: EmbeddedWebBanner }> {
    const result = await requestFactory("post", `/embedded_web_banners/${id}`)
    return { web_banner: httpRequestToSegmentCondition(result.web_banner) }
  },
  async modify(
    id: EmbeddedWebBanner["id"],
    data: EmbeddedWebBannerModifyPayload,
  ): Promise<{ web_banner: EmbeddedWebBanner }> {
    const result = await requestFactory(
      "patch",
      `/embedded_web_banners/${id}`,
      segmentToHttpRequestCondition(data),
    )
    return { web_banner: httpRequestToSegmentCondition(result.web_banner) }
  },
  delete(id: EmbeddedWebBanner["id"]): Promise<void> {
    return requestFactory("delete", `/embedded_web_banners/${id}`)
  },
  listDeleted({
    offset = 0,
    limit = 20,
    searched_text = "",
  }: EmbeddedWebBannerListDeletedParams = {}): Promise<EmbeddedWebBannerListDeletedResponse> {
    return requestFactory("get", "/embedded_web_banners/trash", { offset, limit, searched_text })
  },
  restoreDeleted(id: EmbeddedWebBanner["id"]): Promise<{ embedded_web_banner: EmbeddedWebBanner }> {
    return requestFactory("patch", `/embedded_web_banners/trash/${id}`)
  },
}

export default embeddedWebBanner
