import {
  ReportsAction,
  fetchAllReportsFulfilledType,
  retrieveReportFulfilledType,
  createReportFulfilledType,
  modifyReportFulfilledType,
  deleteReportFulfilledType,
  moveReportFulfilledType,
  moveReportErrorType,
} from "resources/report/reportActions"
import { Report } from "resources/report/reportTypes"
import { move } from "ramda"

const initialState = {
  initialFetching: true,
  reports: [] as Report[],
}

type ReportsState = typeof initialState

export default function reports(state: ReportsState = initialState, action: ReportsAction) {
  switch (action.type) {
    case fetchAllReportsFulfilledType: {
      return {
        ...state,
        initialFetching: false,
        reports: action.payload,
      }
    }
    case createReportFulfilledType: {
      return {
        ...state,
        reports: [...state.reports, action.payload.report],
      }
    }
    case retrieveReportFulfilledType: {
      const index = state.reports.findIndex(report => report.id === action.payload.report.id)
      if (index === -1) {
        return {
          ...state,
          reports: [...state.reports, action.payload.report],
        }
      } else {
        return {
          ...state,
          reports: [
            ...state.reports.slice(0, index),
            action.payload.report,
            ...state.reports.slice(index + 1),
          ],
        }
      }
    }
    case modifyReportFulfilledType: {
      return {
        ...state,
        reports: state.reports.map(report => {
          if (report.id === action.payload.report.id) {
            return action.payload.report
          }
          return report
        }),
      }
    }
    case deleteReportFulfilledType: {
      return {
        ...state,
        reports: state.reports.filter(report => report.id !== action.meta.reportId),
      }
    }
    case moveReportFulfilledType:
    case moveReportErrorType: {
      const { fromIndex, toIndex } = action.meta
      return {
        ...state,
        reports: move(fromIndex, toIndex, state.reports),
      }
    }
    default:
      return state
  }
}
