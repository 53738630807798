import React from "react"
import PropTypes from "prop-types"
import { Bar } from "react-chartjs-2"
import { Map } from "immutable"
import ChartDataLabels from "chartjs-plugin-datalabels"

// constants, helpers
import { abbreviateNumber } from "helpers/number.helper"
import { COLOR } from "sharedConstants"

const options = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  hover: {
    mode: null,
  },
  layout: {
    padding: {
      top: 20,
      right: 10,
      bottom: 3,
      left: 3,
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          autoSkip: false,
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          suggestedMin: 0,
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
          callback: function (value) {
            return abbreviateNumber(value)
          },
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      color: function (context) {
        return context.dataset.borderColor
      },
      formatter: function (value) {
        if (value) {
          return abbreviateNumber(value)
        }
      },
      font: {
        size: 12,
        weight: "bold",
      },
      anchor: "end",
      align: "end",
      offset: -3,
    },
  },
}

const CustomersPerSourceChart = ({ rawData, sourceMapById, className }) => {
  let data = {}
  data = {
    labels: rawData.map(entry =>
      sourceMapById.getIn([entry.source_id, "name"], "Undefined source"),
    ),
    datasets: [
      {
        backgroundColor: rawData.map(entry => {
          let color = COLOR[sourceMapById.getIn([entry.source_id, "frontend_settings", "color"])]
          if (!color) {
            color = COLOR["primary"]
          }
          return color
        }),
        borderColor: rawData.map(entry => {
          let color = COLOR[sourceMapById.getIn([entry.source_id, "frontend_settings", "color"])]
          if (!color) {
            color = COLOR["primary"]
          }
          return color
        }),
        data: rawData.map(entry => entry.count),
        minBarLength: 2,
      },
    ],
  }

  return (
    <div className={`customers-counts-chart ${className}`}>
      <Bar data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  )
}

CustomersPerSourceChart.propTypes = {
  rawData: PropTypes.array.isRequired,
  sourceMapById: PropTypes.instanceOf(Map),
  className: PropTypes.string,
}

export default CustomersPerSourceChart
