import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import { useCallback, useEffect, useState } from "react"
import TrashDataTable from "./components/TrashDataTable/TrashDataTable"
import TrashFilterForm from "./components/TrashFilterForm/TrashFilterForm"
import styles from "./Trash.module.scss"
import {
  fetchTrashItems as fetchTrashItemsAPI,
  fetchTrashItemsCounts,
  restoreTrashItem,
} from "./trashRequests"
import { TrashItem, TrashItemType, FetchTrashItemsResponse } from "./trashTypes"
import { showToast } from "actions/toast.action"
import { useDispatch } from "react-redux"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
import getReadableItemType from "./utilities/getReadableItemType"
import { TrashFilterOption } from "./components/TrashFilterForm/getTrashFilterOptions"

type TrashItemsState = {
  data: TrashItem[]
  itemType: TrashItemType
  selectionSettings: FetchTrashItemsResponse["selection_settings"]
}

type TrashItemsCountsState =
  | {
      isLoading: true
    }
  | {
      isLoading: false
      data: Record<TrashItemType, number>
    }
type RestorationModalState = {
  isOpen: boolean
  trashItem?: TrashItem
}

export default function Trash() {
  const dispatch = useDispatch()
  const [trashItemsCounts, setTrashItemsCounts] = useState<TrashItemsCountsState>({
    isLoading: true,
  })
  const [trashItems, setTrashItems] = useState<TrashItemsState | null>(null)
  const [restorationModal, setRestorationModal] = useState<RestorationModalState>({
    isOpen: false,
  })
  const [isRestoringTrashItem, setIsRestoringTrashItem] = useState(false)

  const initTrashItemsCounts = useCallback(async () => {
    const res = await fetchTrashItemsCounts()
    setTrashItemsCounts({
      isLoading: false,
      data: res,
    })
  }, [])

  useEffect(() => {
    initTrashItemsCounts()
  }, [initTrashItemsCounts])

  const fetchTrashItems = useCallback(
    async (offset: number, item_type: TrashItemType, searched_text: string) => {
      try {
        if (offset === 0) {
          setTrashItems(null)
        }
        return await fetchTrashItemsAPI({ item_type, offset, searched_text })
      } catch (err) {
        dispatch(showToast(`Unknown entity type: ${item_type}`))
      }
    },
    [dispatch],
  )

  const filterTrash = useCallback(
    async (trashFilterOption: TrashFilterOption, searchText: string) => {
      const itemType = trashFilterOption.value
      const res = await fetchTrashItems(0, itemType, searchText)
      if (res) {
        setTrashItems({
          selectionSettings: res.selection_settings,
          data: res.data,
          itemType,
        })
      }
    },
    [fetchTrashItems],
  )

  const loadMoreTrashItems = async () => {
    if (trashItems) {
      const {
        itemType,
        selectionSettings: { offset, limit, searched_text },
      } = trashItems
      const res = await fetchTrashItems(offset + limit, itemType, searched_text)
      if (res && trashItems?.itemType === itemType) {
        setTrashItems(prevState => ({
          data: [...(prevState?.data ?? []), ...res.data],
          selectionSettings: res.selection_settings,
          itemType,
        }))
      }
    }
  }

  const openRestorationModal = (trashItem: TrashItem) => {
    setRestorationModal({
      isOpen: true,
      trashItem,
    })
  }

  const closeRestorationModal = () => {
    setRestorationModal(prevState => ({ ...prevState, isOpen: false }))
  }

  const confirmRestorationModal = async () => {
    if (!restorationModal.trashItem || isRestoringTrashItem) {
      return
    }

    setIsRestoringTrashItem(true)
    const { id, type } = restorationModal.trashItem
    try {
      await restoreTrashItem(id, type)
      closeRestorationModal()
      // because react-select cannot update selected item's label (decrease count) from updated
      // options, I have to call `setValue` there to exact same option, which works,
      // but onChange triggers form submit, hence I don't have to fetch trash items here directly
      initTrashItemsCounts()
      const readableItemType = getReadableItemType(type)
      dispatch(
        showToast(
          `${readableItemType.charAt(0).toUpperCase() + readableItemType.slice(1)} restored.`,
        ),
      )
      setIsRestoringTrashItem(false)
    } catch (err) {
      dispatch(showToast(`Unknown entity type: ${type}`))
      setIsRestoringTrashItem(false)
    }
  }

  return (
    <div className={styles.trash}>
      <PaperHeader size="small" titleText="Trash">
        <TrashFilterForm
          itemsCounts={!trashItemsCounts.isLoading ? trashItemsCounts.data : null}
          onChange={filterTrash}
        />
      </PaperHeader>
      <Paper hasHeader>
        {trashItemsCounts.isLoading || !trashItems ? (
          <LoadingIndicator />
        ) : (
          <TrashDataTable
            data={trashItems.data}
            hasMoreDataToLoad={
              trashItems.data.length ===
              trashItems.selectionSettings.offset + trashItems.selectionSettings.limit
            }
            itemType={trashItems.itemType}
            onRestoreClick={openRestorationModal}
            onLoadMore={loadMoreTrashItems}
          />
        )}
      </Paper>
      <ConfirmModal
        open={restorationModal.isOpen}
        type={MODAL.TYPE.SUCCESS}
        handleClose={closeRestorationModal}
        handleConfirm={confirmRestorationModal}
        title="Restore item"
        action="restore"
        what={
          restorationModal.trashItem?.type
            ? getReadableItemType(restorationModal.trashItem.type, { capitalize: false })
            : ""
        }
        item={restorationModal.trashItem?.name}
        isLoading={isRestoringTrashItem}
      />
    </div>
  )
}
