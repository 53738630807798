import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { List } from "immutable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _isEmpty from "lodash/isEmpty"
import parser from "cron-parser"
import moment from "moment"

import Button from "components/UI/elements/Button/Button"

import { TOAST } from "sharedConstants"
import {
  schedulesApiToForm,
  schedulesFormToApi,
  SCHEDULE_TYPE,
} from "resources/segment/segment/utilities/segmentSchedulesUtils"

import "./Scheduler.scss"
import SchedulerForm from "./SchedulerForm/SchedulerForm"

class Scheduler extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      nextSchedule: "",
    }
  }

  componentDidMount() {
    this.evaluateNextSchedule()
    this.intervalId = setInterval(this.evaluateNextSchedule, 60000)
  }

  evaluateNextSchedule = () => {
    const { schedules } = this.props
    if (Array.isArray(schedules)) {
      const schedulesCronStrings = schedules.map(
        schedule =>
          `${schedule.minute} ${schedule.hour} ${schedule.day} ${schedule.month} ${schedule.day_of_week}`,
      )
      let nextSchedulesDates = List()
      try {
        const intervals = List(
          schedulesCronStrings.map(cronString => parser.parseExpression(cronString, { utc: true })),
        )
        nextSchedulesDates = intervals.map(interval => {
          let dates = List()
          for (let i = 0; i < 3; i++) {
            dates = dates.set(i, moment.utc(interval.next().toISOString()))
          }
          return dates
        })
      } catch {}

      this.setState({
        nextSchedule: nextSchedulesDates
          .flatten()
          .sort()
          .slice(0, 1)
          .first()
          .local()
          .format("ddd H:mm"),
      })
    } else {
      this.setState({
        nextSchedule: "",
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
    this.refreshInterval = null
  }

  toggleSchedule = () => {
    if (this.state.open) {
      // closing the form, evaluate next schedules
      this.evaluateNextSchedule()
    }
    this.setState(prevState => ({
      open: !prevState.open,
      loadingSave: false,
      loadingDelete: false,
    }))
  }

  renderButtonContent = () => {
    const { schedules } = this.props
    const { nextSchedule } = this.state

    return (
      <React.Fragment>
        <span className={`icon ${nextSchedule ? "has-schedule" : ""}`}>
          <FontAwesomeIcon icon={["far", "clock"]} className="clock-icon" />
          {nextSchedule && Array.isArray(schedules) && schedules.length > 0 && (
            <span className="days-count">{schedules.length}</span>
          )}
        </span>
        {nextSchedule && (
          <span className="text">
            Next: <strong>{nextSchedule}</strong>
          </span>
        )}
        {!nextSchedule && <span className="text">Schedule</span>}
      </React.Fragment>
    )
  }

  saveSchedule = values => {
    const { exportDestinationId, onSaveSchedule, showToast } = this.props

    const schedules = schedulesFormToApi(values)
    this.setState({
      loadingSave: _isEmpty(schedules) ? false : true,
      loadingDelete: _isEmpty(schedules) ? true : false,
    })
    const saveRequest = onSaveSchedule(exportDestinationId, schedules)
    if (saveRequest && typeof saveRequest.then === "function") {
      saveRequest
        .then(() => {
          if (_isEmpty(values.schedules)) {
            showToast("Schedule deleted.", TOAST.TYPE.SUCCESS)
          } else {
            showToast("Schedule set.", TOAST.TYPE.SUCCESS)
          }
          this.toggleSchedule()
        })
        .catch(() => {
          this.setState({
            loadingSave: false,
            loadingDelete: false,
          })
        })
    } else {
      this.setState({
        loadingSave: false,
        loadingDelete: false,
      })
    }
  }

  render() {
    const { open, loadingSave, loadingDelete, nextSchedule } = this.state
    const { isEditable, schedules, exportDestinationId } = this.props

    const formInitialValues = {
      visibleSchedule: 0,
      schedules:
        Array.isArray(schedules) && schedules.length > 0
          ? schedulesApiToForm(schedules)
          : [
              {
                days: {},
                type: SCHEDULE_TYPE.ONCE,
                hour: "",
                minute: "",
              },
            ],
    }
    return (
      <div className="scheduler-wrapper">
        <Button
          size="small"
          color="white"
          className={`scheduler-button ${nextSchedule || open ? "schedule-exists" : ""}`}
          onClick={this.toggleSchedule}
        >
          {this.renderButtonContent()}
        </Button>
        <SchedulerForm
          form={`SchedulerForm${exportDestinationId}`}
          open={open}
          handleClose={this.toggleSchedule}
          isEditable={isEditable}
          initialValues={formInitialValues}
          onSubmit={this.saveSchedule}
          isLoading={{
            save: loadingSave,
            delete: loadingDelete,
          }}
        />
      </div>
    )
  }
}

Scheduler.propTypes = {
  schedules: PropTypes.array,
  exportDestinationId: PropTypes.number.isRequired,
  onSaveSchedule: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
}

export default Scheduler
