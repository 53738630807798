import { createSelector } from "reselect"
import { fromJS, Map } from "immutable"
import Segment from "./segmentModel"
import _toInteger from "lodash/toInteger"

export const getSegments = (state, smart = false) => {
  return smart ? state.smartSegments.data : state.segments.get("data")
}
export const getSelectionSettings = state => state.segments.get("selectionSettings")
export const getHasMoreItems = state => state.segments.get("hasMoreItems")
export const getIsFulfilled = (state, smart = false) => {
  return smart ? state.smartSegments.isFulfilled : state.segments.get("isFulfilled")
}
export const getSmartSegmentsIsFetching = state => state.smartSegments.isFetching
const getSegment = (state, id, type = "regular") => {
  switch (type) {
    case "featured": {
      const segment = state.featuredSegments.featuredSegments.find(sg => sg.id === _toInteger(id))
      if (segment) {
        return new Segment(fromJS(segment))
      }
      return segment
    }
    case "smart":
      const segment = state.smartSegments.data.find(sg => sg.id === _toInteger(id))
      if (segment) {
        return new Segment(fromJS(segment))
      }
      return segment
    default:
      return state.segments.get("data").find(sg => sg.id === _toInteger(id))
  }
}

// segments list result
export const getSegmentsList = createSelector(
  [getSegments, getSelectionSettings, getHasMoreItems, getIsFulfilled],
  (segments, selectionSettings, hasMoreItems, isFulfilled) => {
    if (isFulfilled === false) {
      return Map(
        fromJS({
          data: [],
          selectionSettings: null,
          isFulfilled,
        }),
      )
    } else if (segments.size === 0) {
      return Map(
        fromJS({
          data: [],
          selectionSettings,
          isFulfilled,
        }),
      )
    }
    return Map(
      fromJS({
        data: segments.toList(),
        selectionSettings,
        hasMoreItems,
        isFulfilled,
      }),
    )
  },
)

// one segment
export const getSegmentById = createSelector([getSegment, getIsFulfilled], segment => segment)
