import React, { useState } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Waypoint from "react-waypoint"
import SimpleBar from "simplebar-react"
import { identity, sort, uniqBy } from "ramda"

import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import { SegmentOption, SegmentType } from "../types"

import styles from "./SegmentPickerBar.module.scss"
import { ascend } from "utilities/comparators"

type Props = {
  fetchNextPage: (category: SegmentType) => void
  hasNextPage: (category: SegmentType) => boolean
  onSelect: (newValue: SegmentOption) => void
  options: Array<SegmentOption>
  selectedOption: SegmentOption | null
  loading?: boolean
  searchMode?: boolean
}

const SegmentPickerBar = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      fetchNextPage,
      hasNextPage,
      onSelect,
      options,
      selectedOption,
      loading = false,
      searchMode = false,
    },
    ref,
  ) => {
    const [selectedCategory, setSelectedCategory] = useState<SegmentType | undefined>(
      selectedOption?.type,
    )

    const categories = sort(
      ascend(identity),
      uniqBy(({ type }) => type, options).map(({ type }) => type),
    )
    const selectedCategoryOptions = selectedCategory
      ? options.filter(({ type }) => type === selectedCategory)
      : []

    return (
      <div ref={ref} className={styles.wrapper} data-testid="segment-picker-bar">
        <div className={styles.header}>
          {!searchMode && selectedCategory && (
            <span className={styles.clickableTitle} onClick={() => setSelectedCategory(undefined)}>
              <FontAwesomeIcon
                icon={["fas", "chevron-left"]}
                className={classNames(styles.icon, styles.left)}
              />
              {selectedCategory}
            </span>
          )}
          {(searchMode || !selectedCategory) && <span className={styles.title}>Segment</span>}
        </div>
        <SimpleBar className={styles.bar}>
          {loading && <LoadingIndicator />}
          {!loading && options.length === 0 && (
            <ul className={styles.list}>
              <li>No results found</li>
            </ul>
          )}
          {!loading && searchMode && (
            <ul className={styles.list}>
              {categories.map(category => (
                <li key={category} className={styles.categoryListItem}>
                  {category}
                  <ul key={category} className={styles.list}>
                    {options
                      .filter(({ type }) => category === type)
                      .map(option => (
                        <li
                          key={option.value}
                          className={classNames(styles.clickableListItem, styles.subItem)}
                          onClick={() => onSelect(option)}
                        >
                          {option.label}
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
            </ul>
          )}
          {!loading && !searchMode && selectedCategory && (
            <>
              <ul className={styles.list}>
                {selectedCategoryOptions.map(option => (
                  <li
                    key={option.value}
                    className={classNames(styles.clickableListItem, {
                      [styles.active]: option.value === selectedOption?.value,
                    })}
                    onClick={() => onSelect(option)}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
              <Waypoint
                onEnter={() => {
                  if (hasNextPage(selectedCategory)) fetchNextPage(selectedCategory)
                }}
              />
            </>
          )}
          {!loading && !searchMode && !selectedCategory && (
            <ul className={styles.list}>
              {categories.map(type => (
                <li
                  className={classNames(styles.clickableListItem, styles.category)}
                  key={type}
                  onClick={() => setSelectedCategory(type)}
                >
                  {type}
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} className={styles.icon} />
                </li>
              ))}
            </ul>
          )}
        </SimpleBar>
      </div>
    )
  },
)

export default SegmentPickerBar
