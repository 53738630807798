import React, { Component } from "react"
import { reduxForm, Form, Field } from "redux-form"
import TextField from "components/UI/elements/TextField"
import IconsRadioGroup from "components/UI/components/IconsRadioGroup"
import ColorRadioGroup from "components/UI/components/ColorRadioGroup"
import { required, pythonVariable } from "helpers/validators.helper"
import { OPTION_GROUP_ICONS, OPTION_GROUP_COLORS } from "sharedConstants"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import "./SourceForm.scss"
import InfoTooltip from "components/UI/elements/InfoTooltip"
import { getDataSourcesData } from "resources/dataSource/dataSourceSelectors"

class SourceForm extends Component {
  submitForm = values => {
    const { disabledFields = [] } = this.props
    const data = {
      name: values.name,
      type: values.type,
      description: values.description,
      frontend_settings: {
        icon: values.icon,
        color: values.color,
      },
    }
    if (!disabledFields.includes("id")) {
      data.id = values.id
    }

    this.props.onSubmit(data)
  }

  render() {
    const { handleSubmit, disabledFields = [], sources } = this.props
    const alreadyUsedColors = []
    sources.forEach(source => {
      if (source.getIn(["frontend_settings", "color"])) {
        alreadyUsedColors.push(source.getIn(["frontend_settings", "color"]))
      }
    })
    return (
      <section className="source-form">
        <Form onSubmit={handleSubmit(this.submitForm)}>
          <div className="form-row white">
            <div className="left-part">
              <h2>General</h2>
            </div>
            <div className="right-part">
              <Field
                component={TextField}
                placeholder="Source ID"
                name="id"
                label="Source ID"
                validate={[required, pythonVariable]}
                maxLength={30}
                className="source-id"
                disabled={disabledFields.includes("id")}
              />
              <Field
                component={TextField}
                placeholder="Name"
                name="name"
                label="Name"
                validate={required}
                maxLength={30}
                className="source-name"
                disabled={disabledFields.includes("name")}
              />
              <Field
                component={TextField}
                placeholder="Source type"
                name="type"
                label="Source type"
                validate={[required, pythonVariable]}
                maxLength={30}
                className="source-type"
                disabled={disabledFields.includes("type")}
              />
            </div>
          </div>
          <div className="form-row grey">
            <div className="left-part">
              <h2>Description</h2>
            </div>
            <div className="right-part">
              <Field
                component={TextField}
                placeholder="Description (optional)"
                name="description"
                label="Description (optional)"
                className="source-description"
                disabled={disabledFields.includes("description")}
                multiline
                rows="8"
              />
            </div>
          </div>
          <div className="form-row last">
            <div className="left-part">
              <h2>Icon</h2>
            </div>
            <div className="right-part">
              <div>
                <Field
                  name="icon"
                  icons={OPTION_GROUP_ICONS}
                  component={IconsRadioGroup}
                  validate={required}
                />
                <div className="label-n-tooltip">
                  <label>
                    Define source color{" "}
                    <InfoTooltip>
                      Color will be visible in: Diagnostic Dashboard, insights default settings,
                      customer profile, source logos.
                    </InfoTooltip>
                  </label>
                </div>
                <Field
                  name="color"
                  colors={OPTION_GROUP_COLORS}
                  alreadyUsedColors={alreadyUsedColors}
                  component={ColorRadioGroup}
                  validate={required}
                />
              </div>
            </div>
          </div>
        </Form>
      </section>
    )
  }
}

SourceForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  disabledFields: PropTypes.array,
}

const mapStateToProps = state => ({
  sources: getDataSourcesData(state, true),
})

SourceForm = connect(mapStateToProps)(SourceForm)

SourceForm = reduxForm({
  form: "SourceForm",
  touchOnBlur: false,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(SourceForm)

export default SourceForm
