import React from "react"
import PropTypes from "prop-types"

import "./ToggleButton.scss"

type ToggleButtonProps = {
  value: number | boolean
  handleToggle: () => void
  size?: "xs" | "sm" | "md" | "lg"
  disabled?: boolean
}
const ToggleButton = ({
  value,
  handleToggle,
  size = "md",
  disabled = false,
}: ToggleButtonProps) => (
  <button
    className={`toggle-button ${value ? "on" : "off"} ${size}`}
    onClick={handleToggle}
    data-testid="button"
    disabled={disabled}
    type="button"
  >
    <div className="labels">
      <span className="onLabel">on</span>
      <span className="offLabel">off</span>
    </div>
    <span className="switch"></span>
  </button>
)

ToggleButton.propTypes = {
  value: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
}

export default ToggleButton
