import React from "react"
import { connect } from "react-redux"
import { submit } from "redux-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/UI/elements/Button/Button"

const RemoteSubmitButton = connect()(({ dispatch, isLoading, formName }) => (
  <Button
    className={isLoading ? "loading" : ""}
    size="small"
    color="primary"
    onClick={() => dispatch(submit(formName))}
  >
    <FontAwesomeIcon className="save-icon icon" icon={["fas", "save"]} /> Save
  </Button>
))

export default RemoteSubmitButton
