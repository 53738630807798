import { api } from "api"
import { CUSTOMER_EVENT } from "sharedConstants"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchCustomerEventCountsList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: CUSTOMER_EVENT.COUNTS.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api.customer.event.count.list("", offset, limit, "name", "ASC", loadFullStructure),
          )
          .setLoadFullStructure(0)
          .setDataPath("event_counts")
          .run()
        return data
      })(),
    },
  })
}
