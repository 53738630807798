import requestFactory from "api/request"
import { Flag } from "types/util"
import {
  GlobalSetting,
  GlobalSettingModifyPayload,
  GlobalSettingReturnType,
} from "./globalSettingsTypes"

const globalSettings = {
  list<T extends Flag = 0>(
    load_full_structure: T = 0 as T,
  ): Promise<{ global_settings: GlobalSettingReturnType<T>[] }> {
    return requestFactory("get", "/global_settings", {
      load_full_structure,
    })
  },
  modify(
    globalSettingsId: GlobalSetting["id"],
    data: GlobalSettingModifyPayload,
  ): Promise<{ global_setting: GlobalSetting }> {
    return requestFactory("patch", `/global_settings/${globalSettingsId}`, data)
  },
}

export default globalSettings
