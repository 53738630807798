import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { showToast } from "actions/toast.action"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { equals, path, prop, sort } from "ramda"
import { useDispatch } from "react-redux"
import api from "resources/endpoints"
import { OrderDir } from "types/util"
import { ascend, descend } from "utilities/comparators"
import {
  PopupWebBanner,
  PopupWebBannerCreatePayload,
  PopupWebBannerModifyPayload,
  PopupWebBannerSettingsModifyPayload,
  PopupWebBannerSort,
} from "./popupWBTypes"

export function useFetchAllPopupWB(opts: {
  orderBy: PopupWebBannerSort
  orderDir: OrderDir
  searchTerm: string
}) {
  const queryClient = useQueryClient()
  return useQuery(
    ["popupWebBanner", "all"],
    () =>
      new AllResourceItemsFetcher()
        .setEndpointCall((offset: number, limit: number) => api.popupWebBanner.list(offset, limit))
        .setDataPath("web_banners")
        .run() as Promise<PopupWebBanner[]>,
    {
      onSuccess: webBanners => {
        webBanners.forEach(web_banner =>
          queryClient.setQueryData(["popupWebBanner", web_banner.id], { web_banner }),
        )
      },
      select(webBanners) {
        const filteredBanners = opts.searchTerm
          ? webBanners.filter(({ name }) =>
              name.toLowerCase().includes(opts.searchTerm.toLowerCase()),
            )
          : webBanners

        const getSortingProperty =
          opts.orderBy === "priority" ? path(["settings", "priority"]) : prop(opts.orderBy)
        const comparator = opts.orderDir === "ASC" ? ascend : descend

        return sort(comparator(getSortingProperty), filteredBanners)
      },
    },
  )
}

export function useFetchPopupWBById(id: PopupWebBanner["id"]) {
  return useQuery(["popupWebBanner", id], () => api.popupWebBanner.retrieve(id), {
    select: ({ web_banner }) => web_banner,
  })
}

export function useCreatePopupWB() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ data }: { data: PopupWebBannerCreatePayload }) => api.popupWebBanner.create(data),
    {
      onSuccess: ({ web_banner }) => {
        queryClient.setQueryData<PopupWebBanner[]>(["popupWebBanner", "all"], data => {
          if (!data) {
            return
          }

          data.push(web_banner)
          return data
        })
        queryClient.setQueryData(["popupWebBanner", web_banner.id], { web_banner })
        dispatch(showToast("Web banner created."))
      },
    },
  )
}

export function useCopyPopupWB() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(({ id }: { id: PopupWebBanner["id"] }) => api.popupWebBanner.copy(id), {
    onSuccess: ({ web_banner }) => {
      queryClient.setQueryData<PopupWebBanner[]>(["popupWebBanner", "all"], data => {
        if (!data) {
          return
        }

        data.push(web_banner)
        return data
      })
      queryClient.setQueryData(["popupWebBanner", web_banner.id], { web_banner })
      dispatch(showToast("Web banner copied."))
    },
  })
}

export function useModifyPopupWB() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ id, data }: { id: PopupWebBanner["id"]; data: PopupWebBannerModifyPayload }) =>
      api.popupWebBanner.modify(id, data),
    {
      onSuccess: ({ web_banner }, { data }) => {
        queryClient.setQueryData<PopupWebBanner[]>(["popupWebBanner", "all"], data => {
          if (!data) {
            return
          }

          const index = data.findIndex(({ id }) => id === web_banner.id)
          if (index === undefined) {
            data.push(web_banner)
          } else {
            data[index] = web_banner
          }
          return data
        })
        queryClient.setQueryData(["popupWebBanner", web_banner.id], { web_banner })
        dispatch(
          showToast(
            equals(data, { disabled: true })
              ? "Web banner disabled."
              : equals(data, { disabled: false })
              ? "Web banner enabled."
              : "Web banner modified.",
          ),
        )
      },
    },
  )
}

export function useDeletePopupWB() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(({ id }: { id: PopupWebBanner["id"] }) => api.popupWebBanner.delete(id), {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData<PopupWebBanner[]>(["popupWebBanner", "all"], data => {
        return data?.filter(el => el.id !== id)
      })
      dispatch(showToast("Web banner deleted."))
    },
  })
}

export function useFetchPopupWBSettings() {
  return useQuery(["popupWebBannerSettings"], () => api.popupWebBanner.settings.retrieve())
}

export function useModifyPopupWBSettings() {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ data }: { data: PopupWebBannerSettingsModifyPayload }) =>
      api.popupWebBanner.settings.modify(data),
    {
      onSuccess: data => {
        queryClient.setQueryData(["popupWebBannerSettings"], data)
        dispatch(showToast("Global settings modified."))
      },
    },
  )
}
