import { createSelector } from "reselect"
import _toLower from "lodash/toLower"

export const getExportDestinationsData = state => state.exportDestinations.get("data")

export const isExportDestinationsFulfilled = state => state.exportDestinations.get("isFulfilled")
export const areExportDestinationsFetching = state => state.exportDestinations.get("isFetching")

export const getExportDestinationById = (state, id) => state.exportDestinations.getIn(["data", id])

export const getExportDestinationsListSortedByName = createSelector(
  getExportDestinationsData,
  exportDestinations => exportDestinations.sortBy(val => _toLower(val.name)),
)
