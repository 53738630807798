import React, { Component } from "react"
import { connect } from "react-redux"
import { reduxForm, Field } from "redux-form"
import PropTypes from "prop-types"
import classNames from "classnames"

import AuthFormLayout from "components/AuthFormLayout/AuthFormLayout"
import Button from "components/UI/elements/Button/Button"
import PasswordField from "components/UI/components/PasswordField"
import TextField from "components/UI/elements/TextField"
import { PASSWORD_SET, TOAST } from "sharedConstants"
import { logout } from "actions/authenticatedUser.action"
import { showToast } from "actions/toast.action"
import { getRoutePath } from "routes"
import { api } from "api"

import styles from "./PasswordSet.module.scss"

class PasswordSet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      heading: "Set new password",
      passwordFieldLabel: "New password",
      submitButtonText: "Set password",
    }
  }

  componentDidMount() {
    const { search } = this.props.location

    if (search) {
      const params = new URLSearchParams(search)
      const email = params.get("email")
      const resetToken = params.get("reset_token")
      const referrer = params.get("referrer")
      if (referrer === PASSWORD_SET.REFERRER.INVITATION) {
        this.setState({
          heading: "You've got invited",
          passwordFieldLabel: "Create password",
          submitButtonText: "Create account",
          referrer,
          email,
          resetToken,
        })
      } else {
        this.setState({
          referrer,
          email,
          resetToken,
        })
      }
    }

    document.body.classList.add("toastify-on-top")
  }

  componentWillUnmount() {
    document.body.classList.remove("toastify-on-top")
  }

  onSubmit = async values => {
    this.setState({ loading: true })
    const { email, resetToken, referrer } = this.state
    const { history, showToast, logout } = this.props
    const data = {
      email,
      password_reset_token: resetToken,
      password: values.password,
    }

    await api.user
      .setPassword(data)
      .then(() => {
        logout()
        showToast(
          referrer === PASSWORD_SET.REFERRER.INVITATION
            ? "Password created, feel free to login!"
            : "Password changed.",
          TOAST.TYPE.SUCCESS,
        )
        history.push(getRoutePath("login"))
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    const { passwordFieldLabel, submitButtonText, heading, email, referrer, loading } = this.state
    const { handleSubmit } = this.props
    const passwordFormName = "forgetPassword"

    return (
      <AuthFormLayout>
        <div className={styles.body}>
          <h2 className={styles.title}>{heading}</h2>
          {referrer === PASSWORD_SET.REFERRER.INVITATION && (
            <p className={styles.invitedPerson}>
              on <strong>{email}</strong> email
            </p>
          )}
          <form
            id={passwordFormName}
            autoComplete="off"
            onSubmit={handleSubmit(this.onSubmit)}
            className={classNames("form-row", styles.form)}
          >
            <PasswordField passwordFieldLabel={passwordFieldLabel} autoFocus />
            <Field
              name="repeat_password"
              component={TextField}
              fullWidth={true}
              placeholder="Repeat password"
              type="password"
              className={styles.repeatPassword}
            />
          </form>
          <Button
            type="submit"
            color="primary"
            size="big"
            form={passwordFormName}
            className={classNames(styles.submitButton, { loading: loading })}
          >
            {submitButtonText}
          </Button>
        </div>
      </AuthFormLayout>
    )
  }
}

const validate = values => {
  const errors = {}
  if (values.password && values.password !== values.repeat_password) {
    errors.repeat_password = "Password doesn't match"
  }
  return errors
}

PasswordSet.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
}

PasswordSet = reduxForm({
  form: "PasswordSetForm",
  touchOnBlur: false,
  validate,
})(connect(null, { logout, showToast })(PasswordSet))

export default PasswordSet
