import { USER } from "resources/user/userActions"
import _get from "lodash/get"
import { UserFull } from "resources/user/userTypes"

type AuthenticatedUserState = {
  token: string
  data: UserFull | null
  isFetching: boolean | null
  firstLogin: boolean
  loginTimestamp: number | null
  ui: {
    segmentsListType: string
    customerDetailLayout: string
    insightsLayout: string
  }
  latestSearch: {
    customers: {
      searchText?: string
      attributeId?: string
    }
  }
}

const initialState: AuthenticatedUserState = {
  token: "",
  data: null,
  isFetching: null,
  firstLogin: true,
  loginTimestamp: null,
  ui: {
    segmentsListType: "thick",
    customerDetailLayout: "two-cols",
    insightsLayout: "four-cols",
  },
  latestSearch: {
    customers: {},
  },
}

export default (
  state = initialState,
  { type, payload }: any /* TODO: type this */,
): AuthenticatedUserState => {
  switch (type) {
    case USER.ACTION.LOGGING_IN:
      return {
        ...state,
        isFetching: true,
      }
    case `${USER.ACTION.LOGGED_IN}_FULFILLED`: {
      return {
        ...state,
        isFetching: false,
        token: _get(payload, "token", ""),
        loginTimestamp: Date.now(),
      }
    }
    case USER.ACTION.RETRIEVE_LOGGED_IN_USER: {
      const { ...data } = payload
      return {
        ...state,
        data,
        isFetching: false,
      }
    }
    case `${USER.ACTION.REFRESH_TOKEN}_FULFILLED`:
      return {
        ...state,
        loginTimestamp: Date.now(),
        token: _get(payload, "token", ""),
      }
    case USER.ACTION.LOGGED_OUT:
      return {
        ...initialState,
        ui: state.ui,
        latestSearch: state.latestSearch,
        firstLogin: false,
        isFetching: false,
      }
    case `${USER.ACTION.MODIFY_AUTH_USER}_FULFILLED`:
    case USER.ACTION.MODIFY_AUTH_USER:
      return {
        ...state,
        isFetching: false,
        data: {
          ...payload,
        },
      }
    case `${USER.ACTION.MODIFY}_FULFILLED`:
      if (payload.id === state.data?.id) {
        return {
          ...state,
          isFetching: false,
          data: {
            ...payload,
          },
        }
      }
      return state
    case USER.ACTION.SET_UI_ATTRIBUTE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          [payload.attribute]: payload.value,
        },
      }
    }
    case USER.ACTION.SET_LATEST_SEARCH: {
      return {
        ...state,
        latestSearch: {
          ...state.latestSearch,
          [payload.section]: payload.values,
        },
      }
    }
    default:
      return state
  }
}
