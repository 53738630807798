import React, { useCallback } from "react"
import styles from "./NewReleaseModal.module.scss"
import Modal from "components/UI/elements/Modal"
import useTypedSelector from "hooks/useTypedSelector"
import { modifyAuthUser } from "actions/authenticatedUser.action"
import Button from "components/UI/elements/Button/Button"
import { useDispatch } from "react-redux"

const CURRENT_NEW_FEATURE = "supported_destinations"

export default function NewReleaseModal() {
  const { id, frontend_settings } = useTypedSelector(state => state.authenticatedUser.data)!
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    dispatch(
      modifyAuthUser(id, {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          lastReleaseModalShown: CURRENT_NEW_FEATURE,
        },
      }),
    )
  }, [dispatch, frontend_settings, id])

  const lastReleaseModalShown = frontend_settings?.lastReleaseModalShown

  return (
    <Modal
      open={lastReleaseModalShown !== CURRENT_NEW_FEATURE}
      title="Check out the updates:"
      handleClose={handleClose}
    >
      <div className={styles.container}>
        <h3>🎉 Time and author of last modification for Web Banners and Segments</h3>
        <p>
          For larger teams, the overview screens for both Web Banners and Segments now display
          <strong> timestamp and author of last modification</strong>.{" "}
          <a
            href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-segments"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </p>

        <h3>🎉 Supported Destinations</h3>
        <p>
          A new bar in Segment &gt; Export screen helps you <strong>discover destinations</strong>{" "}
          already supported by Meiro where you can target your segmented audiences.
        </p>

        <h3>🎉 Access to attribute_id</h3>
        <p>
          Useful for developers, the attribute identifiers are now displayed in both Data &gt;
          Attributes and Setup &gt; Attributes screens. Both screens now also allow searching by
          attribute identifiers.
        </p>

        <div className={styles.buttonWrapper}>
          <Button color="primary" size="big" onClick={handleClose}>
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  )
}
