import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  fetchNotifications,
  markNotificationsAsRead,
  removeUnreadBadge,
} from "resources/notification/notificationActions"
import classNames from "classnames"
import { format, isToday, isYesterday } from "date-fns"
import useTypedSelector from "hooks/useTypedSelector"
import { groupBy } from "lodash"
import React, { useCallback, useMemo } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import { DATEFNS } from "sharedConstants"
import NotificationCard from "components/UI/components/Notification/Notification"
import styles from "./NotificationsDropdown.module.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import DelayedTooltip from "components/UI/elements/IconButton/DelayedTooltip/DelayedTooltip"
import useClickOutHandler from "hooks/useClickOutHandler"

export default function NotificationsDropdown() {
  const { notifications, hasNewUnread, hasNextPage, oldestId } = useTypedSelector(
    state => state.notifications,
  )
  const dispatch = useDispatch()

  const fetchMore = useCallback(() => {
    if (hasNextPage) dispatch(fetchNotifications({ untilId: oldestId }))
  }, [dispatch, hasNextPage, oldestId])

  const openCallback = useCallback(() => {
    if (hasNewUnread) {
      dispatch(removeUnreadBadge())
    }
  }, [dispatch, hasNewUnread])

  const closeCallback = useCallback(() => {
    if (notifications.some(n => !n.read)) {
      dispatch(markNotificationsAsRead())
    }
  }, [dispatch, notifications])

  const {
    isOpen: isDropdownOpen,
    toggle: toggleDropdown,
    close: closeDropdown,
    ref: dropdownRef,
  } = useClickOutHandler({ openCallback, closeCallback })

  const notifsGroupedByDate = useMemo(
    () =>
      Object.entries(groupBy(notifications, n => n.timestamp.slice(0, 10))).sort(
        ([date1], [date2]) => new Date(date2).getTime() - new Date(date1).getTime(),
      ),
    [notifications],
  )

  return (
    <div className={styles.container}>
      <DelayedTooltip content="Notifications">
        <button
          className={classNames(styles.button, { [styles.isOpen]: isDropdownOpen })}
          onClick={toggleDropdown}
        >
          <FontAwesomeIcon className={styles.icon} icon={["fas", "bell"]} />
        </button>
      </DelayedTooltip>
      {hasNewUnread && <div className={styles.unreadBadge}></div>}
      {isDropdownOpen && (
        <div className={styles.dropdown} ref={dropdownRef}>
          <div className={styles.dropdownHeader}>
            <div className={styles.title}>notifications</div>
            <Link
              to={getRoutePath("home")}
              onClick={closeDropdown}
              className={styles.dashboardLink}
            >
              view on homepage <FontAwesomeIcon icon={["fas", "arrow-right"]} />
            </Link>
          </div>
          <div className={styles.dropdownBody} id="dropdownBody">
            {notifsGroupedByDate.length === 0 ? (
              <div className={styles.emptyMessage}>There are no notifications.</div>
            ) : (
              <InfiniteScroll
                dataLength={notifsGroupedByDate.length}
                next={fetchMore}
                hasMore={hasNextPage}
                scrollThreshold="100px"
                loader={
                  <div className={styles.loadingMore}>
                    <LoadingIndicator />
                  </div>
                }
                scrollableTarget="dropdownBody"
              >
                {notifsGroupedByDate.map(([date, notifs]) => {
                  const dateObject = new Date(date)
                  let formattedDate = format(dateObject, DATEFNS.DATE_FORMAT)
                  if (isToday(dateObject)) formattedDate = "TODAY"
                  if (isYesterday(dateObject)) formattedDate = "YESTERDAY"

                  return (
                    <div key={date} className={styles.dateGroup}>
                      <div className={styles.dateGroupHeader}>{formattedDate}</div>
                      {notifs.map(notification => (
                        <NotificationCard
                          notification={notification}
                          key={notification.id}
                          isDropdown
                          onLinkClick={closeDropdown}
                        />
                      ))}
                    </div>
                  )
                })}
              </InfiniteScroll>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
