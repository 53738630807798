import { Dispatch } from "redux"
import { TOAST } from "sharedConstants"

export const showToast =
  (
    message: string,
    type: typeof TOAST.TYPE[keyof typeof TOAST.TYPE] = TOAST.TYPE.SUCCESS,
    route = "",
    hidePrevious = false,
  ) =>
  (dispatch: Dispatch) =>
    dispatch({
      type: TOAST.ACTION.SHOW,
      payload: { message, type, route, hidePrevious },
    })
