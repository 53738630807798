import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import PaperHeader from "components/UI/elements/PaperHeader"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import _toLower from "lodash/toLower"
import {
  isDataSourcesFulfilled,
  getDataSourcesData,
  areDataSourcesFetching,
} from "resources/dataSource/dataSourceSelectors"
import {
  getGlobalSettingsFormValues,
  isGlobalSettingsFetching,
  isGlobalSettingsFulfilled,
} from "resources/globalSettings/globalSettingsSelectors"
import {
  fetchGlobalSettingsList,
  modifyGlobalSettingsItem,
} from "resources/globalSettings/globalSettingsActions"
import { showToast } from "actions/toast.action"
import { fetchDataSourcesList, modifyDataSource } from "resources/dataSource/dataSourceActions"
import { OrderedMap } from "immutable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _get from "lodash/get"
import { getIconSrc } from "helpers/image.helper"
import moment from "moment"
import ToggleButton from "components/UI/elements/ToggleButton"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import { MOMENT, TOAST } from "sharedConstants"
import { getRoutePath } from "routes"
import { fetchEventsList } from "actions/event.action"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"

import "./SourcesList.scss"
import Tippy from "@tippyjs/react"
import { refetchAttributes } from "resources/attribute/attributeQueries"

class Sources extends Component {
  componentDidMount() {
    // refetch global settings and sources if necessary
    const {
      isGlobalSettingsFetching,
      fetchGlobalSettingsList,
      areDataSourcesFetching,
      fetchDataSourcesList,
    } = this.props
    if (!isGlobalSettingsFetching) {
      fetchGlobalSettingsList()
    }
    if (!areDataSourcesFetching) {
      fetchDataSourcesList(1)
    }
  }

  toggleSourcesDashboardHidden = sourceId => () => {
    const { modifyGlobalSettingsItem, globalSettings, fetchGlobalSettingsList, showToast } =
      this.props
    const settingsId = _get(globalSettings, "hidden_data_sources_from_diagnostic_dashboard.id")
    if (settingsId) {
      const hiddenDashboardSourceIds = _get(
        globalSettings,
        "hidden_data_sources_from_diagnostic_dashboard.value",
        [],
      )
      let value
      let enabled = false
      if (hiddenDashboardSourceIds.includes(sourceId)) {
        // remove sourceId from array
        value = hiddenDashboardSourceIds.filter(arrSourceId => arrSourceId !== sourceId)
        enabled = true
      } else {
        // add sourceId to array, because it was no there
        value = [...hiddenDashboardSourceIds, sourceId]
      }
      modifyGlobalSettingsItem(settingsId, { value }).then(() => {
        fetchGlobalSettingsList().then(() => {
          showToast(
            `The data source will be ${
              enabled ? "shown" : "hidden"
            } from the Diagnostic Dashboard after the next data cache refresh. If you want to see the change now, refresh cache manually.`,
            TOAST.TYPE.INFO,
            getRoutePath("administration.settings"),
            true,
          )
        })
      })
    }
  }

  goToSourceDetail = sourceId => () => {
    this.props.history.push(getRoutePath("setup.sources.detail", { id: sourceId }))
  }

  toggleHiddenAttribute = source => async () => {
    const { modifyDataSource, showToast, fetchEventsList } = this.props
    const changedIsHidden = source.is_hidden === 1 ? 0 : 1
    await modifyDataSource(source.id, { is_hidden: changedIsHidden })
    fetchEventsList(1)
    refetchAttributes()
    showToast(`Source ${changedIsHidden === 1 ? "hidden" : "visible"}.`)
  }

  render() {
    const { sources, sourcesFulfilled, globalSettings, isGlobalSettingsFulfilled, history } =
      this.props
    const hiddenDashboardSourceIds = _get(
      globalSettings,
      "hidden_data_sources_from_diagnostic_dashboard.value",
      [],
    )

    return (
      <section className="wrapper setup-sources">
        <PaperHeader size="small" titleText="Sources" className="setup-sources-header">
          <Button
            size="small"
            color="primary"
            onClick={() => {
              history.push(getRoutePath("setup.sources.create"))
            }}
          >
            + Create source
          </Button>
        </PaperHeader>
        {sourcesFulfilled && sources.size > 0 && (
          <Paper hasHeader noPaddingTop>
            <Table className="setup-sources-table">
              <Thead stickyHeader>
                <Th />
                <Th className="name">Name</Th>
                <Th className="description">Description</Th>
                <Th className="diagnostic-dashboard-viz">
                  Show in Diagnostic Dashboard{" "}
                  <Tippy
                    content="The change will be visible after data cache expiration in the Diagnostic
                    Dashboard charts: NO. OF UNIQUE CUSTOMERS IDENTIFIED ACROSS X SOURCES, NO. OF
                    CUSTOMERS IDENTIFIED PER SOURCE and CONNECTED SOURCES. To quicken the update,
                    manually refresh the data cache in the Administration tab / Settings tab."
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={["fas", "info-circle"]}
                        data-tip
                        data-for="show-in-diagnostic-dashboard-tooltip"
                      />
                    </span>
                  </Tippy>
                </Th>
                <Th className="date-added">Date added</Th>
                <Th className="actions" />
              </Thead>
              <Tbody>
                {sources
                  .map(source => {
                    const showInDashboard = !hiddenDashboardSourceIds.includes(source.id)
                    return (
                      <Tr key={source.id} className={source.is_hidden ? "is-hidden" : ""}>
                        <Td className="icon">
                          <div className="icon-wrapper">
                            <img
                              src={getIconSrc(
                                {
                                  primary: source.getIn(["frontend_settings", "icon"]),
                                  secondary: _toLower(source.type),
                                },
                                source.getIn(["frontend_settings", "alt_icon"]),
                              )}
                              alt="icon"
                            />
                          </div>
                        </Td>
                        <Td
                          className="name"
                          withNewBadge={moment().diff(source.created, "days") < 8}
                          textBigger
                          textBold
                          textBlack
                        >
                          {source.name}
                        </Td>
                        <Td className="description">
                          {source.description ? `${source.description}` : "—"}
                        </Td>
                        <Td className="show-in-dd">
                          {isGlobalSettingsFulfilled && (
                            <ToggleButton
                              value={showInDashboard}
                              handleToggle={this.toggleSourcesDashboardHidden(source.id)}
                              disabled={source.is_hidden === 1}
                            />
                          )}
                        </Td>
                        <Td className="created">
                          {moment.utc(source.created).local().format(MOMENT.DATE_FORMAT)}
                        </Td>
                        <Td textAlignRight className="actions">
                          <IconButton
                            color={COLOR.BLACK}
                            size={SIZE.TAG}
                            onClick={this.goToSourceDetail(source.id)}
                            withBackground
                            iconName="pencil-alt"
                            tooltip="Edit"
                          />
                          <IconButton
                            color={COLOR.RED}
                            size={SIZE.TAG}
                            onClick={this.toggleHiddenAttribute(source)}
                            className="left-margin"
                            withBackground
                            iconName={source.is_hidden ? "eye" : "eye-slash"}
                            iconStyle="far"
                            tooltip={source.is_hidden ? "Show" : "Hide"}
                          />
                        </Td>
                      </Tr>
                    )
                  })
                  .toArray()}
              </Tbody>
            </Table>
          </Paper>
        )}
        {sourcesFulfilled && sources.size === 0 && (
          <p className="info-message">Click on the "Create Source" to get started.</p>
        )}
      </section>
    )
  }
}

Sources.propTypes = {
  sources: PropTypes.instanceOf(OrderedMap).isRequired,
  sourcesFulfilled: PropTypes.bool.isRequired,
  areDataSourcesFetching: PropTypes.bool.isRequired,
  fetchDataSourcesList: PropTypes.func.isRequired,
  fetchGlobalSettingsList: PropTypes.func.isRequired,
  modifyGlobalSettingsItem: PropTypes.func.isRequired,
  isGlobalSettingsFetching: PropTypes.bool.isRequired,
  isGlobalSettingsFulfilled: PropTypes.bool.isRequired,
  globalSettings: PropTypes.object,
  showToast: PropTypes.func.isRequired,
  modifyDataSource: PropTypes.func.isRequired,
  fetchEventsList: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    sourcesFulfilled: isDataSourcesFulfilled(state),
    sources: getDataSourcesData(state, true),
    areDataSourcesFetching: areDataSourcesFetching(state),
    isGlobalSettingsFetching: isGlobalSettingsFetching(state),
    isGlobalSettingsFulfilled: isGlobalSettingsFulfilled(state),
    globalSettings: getGlobalSettingsFormValues(state),
  }
}

export default connect(mapStateToProps, {
  fetchGlobalSettingsList,
  modifyGlobalSettingsItem,
  fetchDataSourcesList,
  showToast,
  modifyDataSource,
  fetchEventsList,
})(Sources)
