import IconButton, { SIZE } from "components/UI/elements/IconButton"
import Table, { RowMessage, Tbody, Th, Thead, Tr, Td } from "components/UI/elements/Table"
import { format } from "date-fns"
import { DATEFNS } from "sharedConstants"
import { TrashItem, TrashItemType } from "pages/Trash/trashTypes"
import ItemTypeChip from "./components/TrashItemTypeChip/TrashItemTypeChip"
import styles from "./TrashDataTable.module.scss"
import getReadableItemType from "pages/Trash/utilities/getReadableItemType"
import useTypedSelector from "hooks/useTypedSelector"
import { getAllUsersMap } from "resources/user/userSelectors"
import { User } from "resources/user/userTypes"
import InfiniteLoader from "./components/InfiniteLoader/InfiniteLoader"

type TrashDataTableProps = {
  data: TrashItem[]
  hasMoreDataToLoad: boolean
  itemType: TrashItemType
  onRestoreClick: (trashItem: TrashItem) => void
  onLoadMore: () => Promise<void>
}

export default function TrashDataTable({
  data,
  hasMoreDataToLoad,
  itemType,
  onRestoreClick,
  onLoadMore,
}: TrashDataTableProps) {
  const allUsers: Record<User["id"], User> = useTypedSelector(state => getAllUsersMap(state)).toJS()

  return data.length === 0 ? (
    <RowMessage>
      No {getReadableItemType(itemType, { capitalize: false, plural: true })} found.
    </RowMessage>
  ) : (
    <>
      <Table>
        <Thead>
          <Th>Name</Th>
          <Th className={styles.typeColumn}>Type</Th>
          <Th textAlignRight className={styles.deletedByColumn}>
            Deleted at
          </Th>
          <Th className={styles.actionsColumn}>&nbsp;</Th>
        </Thead>
        <Tbody>
          {data.map(item => (
            <Tr key={item.id}>
              <Td textBlack textBigger textBold data-testid="td-name">
                {item.name}
              </Td>
              <Td data-testid="td-type">
                <ItemTypeChip type={item.type} />
              </Td>
              <Td textAlignRight>
                <span className={styles.deletedAt}>
                  {format(new Date(item.deleted_at), DATEFNS.DATETIME_FORMAT)}
                </span>
                {item.deleted_by && (
                  <span className={styles.deletedBy}>by {allUsers[item.deleted_by]?.name}</span>
                )}
              </Td>
              <Td textAlignRight>
                {/*
              // @ts-ignore */}
                <IconButton
                  color="green"
                  size={SIZE.TAG}
                  withBackground
                  iconName="redo"
                  iconStyle="far"
                  onClick={() => onRestoreClick(item)}
                  data-testid="restore-trash-item"
                  tooltip="Restore"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <InfiniteLoader hasMoreDataToLoad={hasMoreDataToLoad} onLoadMoreData={onLoadMore} />
    </>
  )
}
