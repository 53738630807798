import { ITEMS_PER_PAGE } from "sharedConstants"
import { Map, fromJS, List } from "immutable"
import _map from "lodash/map"
import { SegmentSelectionSettings } from "models/selectionSettings.model"
import Segment from "./segmentModel"
import { SEGMENTS } from "./segmentActions"
import { SEGMENT } from "./segmentActions.deprecated"

const initialState = Map({
  data: List(),
  selectionSettings: Map(),
  hasMoreItems: false,
  isFulfilled: false,
})

export default function customSegments(state = initialState, { type, payload, meta }) {
  switch (type) {
    case `${SEGMENT.ACTION.LIST}_PENDING`: {
      if (meta.clearListBefore) {
        return initialState
      }
      return state
    }
    case `${SEGMENT.ACTION.LIST}_FULFILLED`: {
      if (payload.selection_settings.offset === 0) {
        return state
          .set("data", List(_map(payload.segments, segment => new Segment(fromJS(segment)))))
          .set("hasMoreItems", payload.segments.length === ITEMS_PER_PAGE)
          .set("isFulfilled", true)
          .set("selectionSettings", new SegmentSelectionSettings(payload.selection_settings))
      } else {
        return state
          .set(
            "data",
            state
              .get("data")
              .concat(_map(payload.segments, segment => new Segment(fromJS(segment)))),
          )
          .set("hasMoreItems", payload.segments.length === ITEMS_PER_PAGE)
          .set("isFulfilled", true)
          .set("selectionSettings", new SegmentSelectionSettings(payload.selection_settings))
      }
    }

    case `${SEGMENTS.ACTION.RETRIEVE}_FULFILLED`: // TODO:
    case `${SEGMENT.ACTION.RETRIEVE}_FULFILLED`: {
      if (!state.get("isFulfilled")) {
        return state.set("data", List([new Segment(fromJS(payload.segment))]))
      } else {
        const isAlreadyInList = state.get("data").find(sg => sg.id === payload.segment.id)
        if (isAlreadyInList) {
          return state.set(
            "data",
            state
              .get("data")
              .map(sg =>
                sg.id === payload.segment.id ? new Segment(fromJS(payload.segment)) : sg,
              ),
          )
        } else {
          return state.set("data", state.get("data").push(new Segment(fromJS(payload.segment))))
        }
      }
    }

    case `${SEGMENTS.ACTION.MODIFY}_FULFILLED`: // TODO:
    case `${SEGMENT.ACTION.MODIFY}_FULFILLED`:
      return state.set(
        "data",
        state
          .get("data")
          .map(sg => (sg.id === payload.segment.id ? new Segment(fromJS(payload.segment)) : sg)),
      )

    case `${SEGMENT.ACTION.DELETE}_FULFILLED`:
      return state.set(
        "data",
        state.get("data").filterNot(sg => sg.id === payload),
      )

    // create action calls dispatches LIST action because we haven't got fetched all the data ->
    // it's not possible to insert newly create item somewhere if there exists some filter or sorting rule
    case `${SEGMENT.ACTION.CREATE}_FULFILLED`:
    default:
      return state
  }
}
