import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { api } from "api"

export const DATA_SOURCES = {
  ACTION: {
    LIST: "DATA_SOURCES_LIST",
    CREATE: "CREATE_DATA_SOURCE",
    MODIFY: "MODIFY_DATA_SOURCE",
    DELETE: "DELETE_DATA_SOURCE",
  },
}

export const fetchDataSourcesList =
  (showHidden = 0) =>
  async dispatch => {
    const caller = new AllResourceItemsFetcher()

    return dispatch({
      type: DATA_SOURCES.ACTION.LIST,
      payload: {
        promise: (async () => {
          return await caller
            .setEndpointCall((offset, limit) =>
              api.dataSource.list(offset, limit, "name", "ASC", showHidden),
            )
            .setDataPath("sources")
            .run()
        })(),
      },
    })
  }

export const modifyDataSource = (id, data) => async dispatch =>
  dispatch({
    type: DATA_SOURCES.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api.dataSource.modify(id, data)
        return response.source
      })(),
    },
  })
