import requestFactory from "api/request"
import { httpRequestToSegmentCondition, segmentToHttpRequestCondition } from "../utils"
import {
  PopupWebBanner,
  PopupWebBannerCreatePayload,
  PopupWebBannerListDeletedParams,
  PopupWebBannerListDeletedResponse,
  PopupWebBannerListResponse,
  PopupWebBannerModifyPayload,
  PopupWebBannerSettingsModifyPayload,
  PopupWebBannerSettingsResponse,
} from "./popupWBTypes"

const popupWebBanner = {
  async list(offset = 0, limit = 50): Promise<PopupWebBannerListResponse> {
    const result = await requestFactory("get", "/web_banners", { offset, limit })
    return {
      ...result,
      web_banners: result.web_banners.map(httpRequestToSegmentCondition),
    }
  },
  async retrieve(id: PopupWebBanner["id"]): Promise<{ web_banner: PopupWebBanner }> {
    const result = await requestFactory("get", `/web_banners/${id}`)
    return { web_banner: httpRequestToSegmentCondition(result.web_banner) }
  },
  create(data: PopupWebBannerCreatePayload): Promise<{ web_banner: PopupWebBanner }> {
    return requestFactory("post", "/web_banners", segmentToHttpRequestCondition(data))
  },
  async copy(id: PopupWebBanner["id"]): Promise<{ web_banner: PopupWebBanner }> {
    const result = await requestFactory("post", `/web_banners/${id}`)
    return { web_banner: httpRequestToSegmentCondition(result.web_banner) }
  },
  async modify(
    id: PopupWebBanner["id"],
    data: PopupWebBannerModifyPayload,
  ): Promise<{ web_banner: PopupWebBanner }> {
    const result = await requestFactory(
      "patch",
      `/web_banners/${id}`,
      segmentToHttpRequestCondition(data),
    )
    return { web_banner: httpRequestToSegmentCondition(result.web_banner) }
  },
  delete(id: PopupWebBanner["id"]): Promise<void> {
    return requestFactory("delete", `/web_banners/${id}`)
  },
  listDeleted({
    offset = 0,
    limit = 20,
    searched_text = "",
  }: PopupWebBannerListDeletedParams = {}): Promise<PopupWebBannerListDeletedResponse> {
    return requestFactory("get", "/web_banners/trash", { offset, limit, searched_text })
  },
  restoreDeleted(id: PopupWebBanner["id"]): Promise<{ popup_web_banner: PopupWebBanner }> {
    return requestFactory("patch", `/web_banners/trash/${id}`)
  },

  settings: {
    retrieve(): Promise<PopupWebBannerSettingsResponse> {
      return requestFactory("get", "/web_banners/settings")
    },

    modify(data: PopupWebBannerSettingsModifyPayload): Promise<PopupWebBannerSettingsResponse> {
      return requestFactory("put", "/web_banners/settings", data)
    },
  },
}

export default popupWebBanner
