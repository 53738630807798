import {
  fetchNotificationsFulfilledType,
  fetchNotificationsPendingType,
  fetchNotificationsRejectedType,
  markNotificationAsReadFulfilledType,
  markNotificationAsReadPendingType,
  markNotificationAsReadRejectedType,
  NOTIFICATIONS,
  NotificationsAction,
} from "./notificationActions"
import { Notification } from "./notificationTypes"

// TODO: error state?
const initState = {
  isFetching: false,
  isMarkingAsRead: false,
  hasNewUnread: false,
  notifications: [] as Notification[],
  oldestId: null as string | null,
  hasNextPage: false,
}

type NotificationsState = typeof initState

export default function notifications(
  state: NotificationsState = initState,
  action: NotificationsAction,
): NotificationsState {
  switch (action.type) {
    case fetchNotificationsPendingType: {
      return { ...state, isFetching: true }
    }
    case fetchNotificationsFulfilledType: {
      const { has_next_page, oldest_id, notifications, shouldAppend } = action.payload

      // remove notifs that might have came through WS while the HTTP request was in flight
      const dedupedNotifications = shouldAppend
        ? notifications
        : notifications.filter(
            ({ id }) => !state.notifications.find(notification => notification.id === id),
          )

      return {
        ...state,
        isFetching: false,
        hasNewUnread: state.hasNewUnread || notifications.filter(({ read }) => !read).length > 0,
        notifications: shouldAppend
          ? state.notifications.concat(dedupedNotifications)
          : dedupedNotifications,
        oldestId: oldest_id,
        hasNextPage: has_next_page,
      }
    }
    case fetchNotificationsRejectedType: {
      return { ...state, isFetching: false }
    }
    case NOTIFICATIONS.ACTION.ADD_NEW: {
      return {
        ...state,
        hasNewUnread: true,
        notifications: [action.payload].concat(state.notifications),
        oldestId: state.oldestId ?? action.payload.id,
      }
    }
    case markNotificationAsReadPendingType: {
      return { ...state, isMarkingAsRead: true }
    }
    case markNotificationAsReadFulfilledType: {
      return {
        ...state,
        isMarkingAsRead: false,
        hasNewUnread: false,
        notifications: state.notifications.map(notif =>
          notif.read ? notif : { ...notif, read: true },
        ),
      }
    }
    case markNotificationAsReadRejectedType: {
      return { ...state, isMarkingAsRead: false }
    }
    case NOTIFICATIONS.ACTION.REMOVE_UNREAD_BADGE: {
      return {
        ...state,
        hasNewUnread: false,
      }
    }
    default:
      return state
  }
}
