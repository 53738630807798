import DragAndDrop from "components/UI/components/DragAndDrop/DragAndDrop"
import { ComponentProps } from "react"
import styles from "./DropCsvFile.module.scss"
import folderImgSrc from "./folder.png"

type DropCsvFileProps = {
  onFileDrop: (files: FileList) => void
  label: string
} & ComponentProps<"div">

export default function DropCsvFile({ onFileDrop, children, label }: DropCsvFileProps) {
  return (
    <div className={styles.dropCsvFile}>
      <span className={styles.label}>{label}</span>
      <DragAndDrop onFileDrop={onFileDrop}>
        <div className={styles.droppableContent}>
          <img src={folderImgSrc} alt="" className={styles.folderImg} />
          {children}
        </div>
      </DragAndDrop>
    </div>
  )
}
