import moment from "moment"
import { MOMENT } from "sharedConstants"
import _toLower from "lodash/toLower"
import _get from "lodash/get"
import _map from "lodash/map"
import _isString from "lodash/isString"
import { api } from "api"

moment.suppressDeprecationWarnings = true

export const getUserFriendlyValueFormat = (value, dataType, timeAgo = false) => {
  if (dataType === "float" && !isNaN(value)) {
    return Number(Math.round(value * 100) / 100).toLocaleString()
  } else if (dataType === "int" && !isNaN(value)) {
    return Number(Math.round(value)).toLocaleString()
  } else if (dataType === "datetime" && moment(value).isValid()) {
    return timeAgo
      ? `${moment
          .utc(_isString(value) ? value.toUpperCase() : value)
          .local()
          .fromNow()} (${moment
          .utc(_isString(value) ? value.toUpperCase() : value)
          .local()
          .format(MOMENT.DATETIME_FORMAT)})`
      : moment
          .utc(_isString(value) ? value.toUpperCase() : value)
          .local()
          .format(MOMENT.DATETIME_FORMAT)
  } else if (dataType === "date" && moment(value, "YYYY-MM-DD").isValid()) {
    return timeAgo
      ? `${moment.utc(value).local().fromNow()} (${moment
          .utc(value)
          .local()
          .format(MOMENT.DATE_FORMAT)})`
      : moment.utc(value).local().format(MOMENT.DATE_FORMAT)
  } else if (dataType === "bool") {
    if (value === "1") {
      return "Yes"
    } else if (value === "0") {
      return "No"
    }
  } else if (value === "* Protected Information *") {
    return "*\u00A0Protected\u00A0information\u00A0*"
  }
  return value
}

export const getBackendValueFromUserFriendly = (value, dataType) => {
  if (dataType === "bool") {
    if (_toLower(value) === "yes") {
      return "1"
    } else if (_toLower(value) === "no") {
      return "0"
    }
  }
  return value
}

export const fetchCustomerAttributeValues = async attributeId => {
  const offset = 0,
    limit = 100
  try {
    const data = await api.customer.attribute.value.list(
      attributeId,
      "",
      offset,
      limit,
      "count",
      "DESC",
    )
    return _map(_get(data, "attribute_values_counts", []), (item, index) => {
      const additionalProps = {}
      if (index === 0 && data.attribute_values_counts.length > 2) {
        additionalProps.highest = true
      } else if (
        index === data.attribute_values_counts.length - 1 &&
        data.attribute_values_counts.length > 2
      ) {
        additionalProps.lowest = true
      }
      return {
        id: `${item.attribute_id}.${item.value}`,
        label: item.value,
        value: item.value,
        ...additionalProps,
      }
    })
  } catch (err) {
    if (_get(err, "response.data.error_type") === "pii_protected_attribute") {
      return [
        {
          label: "* Protected Information *",
          value: "",
          disabled: true,
          id: "protected_information",
        },
      ]
    }
    return []
  }
}
