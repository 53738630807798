import { Record, List } from "immutable"

const Segment = Record({
  id: 0,
  name: "",
  description: "",
  disabled: 0,
  deleted: 0,
  settings: null,
  user_id: 0,
  tag_ids: List(),
  created: "",
  enabled_export_destinations: List(),
  author_id: 0,
  origin_created: "",
  last_export: "",
  frontend_settings: null,
  prebuilt: 0,
  featured: 0,
})

export default Segment
