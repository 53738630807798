import React, { Component } from "react"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import EventForm, { VALUE_TYPE, VALUE_FORMAT } from "../components/EventForm/EventForm"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import { getEventById, getEventsIsFulfilled } from "selectors/event.selector"
import {
  getDataSourcesArrayForSelect,
  getDataSourcesData,
} from "resources/dataSource/dataSourceSelectors"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Record, Map } from "immutable"
import _isArray from "lodash/isArray"
import _get from "lodash/get"
import { modifyEvent } from "actions/event.action"
import { showToast } from "actions/toast.action"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./EventDetail.scss"
import Tippy from "@tippyjs/react"

class EventDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modifying: false,
    }
  }

  modifyEvent = async data => {
    if (!this.state.modifying) {
      this.setState({ modifying: true })
      const { history, modifyEvent, event, showToast } = this.props
      try {
        await modifyEvent(event.id, data)
        showToast("Event edited.")
        history.push(getRoutePath("setup.events"))
      } catch {
        this.setState({ modifying: false })
      }
    }
  }

  toggleHiddenAttribute = async () => {
    const { event, modifyEvent, showToast } = this.props
    await modifyEvent(event.id, { is_hidden: event.is_hidden === 1 ? 0 : 1 })
    showToast(`Event ${event.is_hidden ? "visible" : "hidden"}.`)
  }

  cloneEvent = () => {
    const { history, event } = this.props
    history.push({
      pathname: getRoutePath("setup.events.create"),
      state: { event },
    })
  }

  render() {
    const { history, event, areEventsFulfilled, sourcesArray, dataSourcesMap } = this.props
    const { modifying } = this.state

    const formInitValues = {}
    let sourceIsHidden = true
    if (event) {
      const selectedSource = sourcesArray.find(source => source.value === event.source_id)
      formInitValues.name = event.name
      formInitValues.version = event.version
      formInitValues.type = { label: event.type, value: event.type }
      formInitValues.source_id = selectedSource ? selectedSource : null
      formInitValues.ttl = event.ttl === 0 ? "" : (event.ttl / 86400).toString()
      formInitValues.schema = {
        title: event.schema.title,
        display: _isArray(_get(event, "schema.display"))
          ? event.schema.display.map(item => ({
              name: item.name,
              type: VALUE_TYPE.find(type => type.value === item.type),
              format: VALUE_FORMAT.find(format => format.value === item.format),
              path: item.path,
            }))
          : [],
      }

      sourceIsHidden = dataSourcesMap.getIn([event.source_id, "is_hidden"], 1) === 1
    }

    return (
      <section className="detail-event wrapper">
        <PaperHeader size="small" titleText="Edit event" className="detail-event-header">
          <div className="header-buttons">
            {event && (
              <React.Fragment>
                <Tippy content="Source is hidden." disabled={!sourceIsHidden}>
                  <span>
                    <Button
                      size="small"
                      color="white-red"
                      onClick={this.toggleHiddenAttribute}
                      disabled={sourceIsHidden}
                    >
                      {event.is_hidden === 1 && (
                        <React.Fragment>
                          <FontAwesomeIcon icon={["far", "eye"]} className="icon" /> Show
                        </React.Fragment>
                      )}
                      {event.is_hidden === 0 && (
                        <React.Fragment>
                          <FontAwesomeIcon icon={["far", "eye-slash"]} className="icon" /> Hide
                        </React.Fragment>
                      )}
                    </Button>
                  </span>
                </Tippy>

                <Button size="small" color="white" onClick={this.cloneEvent}>
                  <FontAwesomeIcon icon={["fas", "clone"]} className="icon" /> Copy
                </Button>
              </React.Fragment>
            )}
            <Button
              size="small"
              color="white"
              onClick={() => {
                history.push(getRoutePath("setup.events"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton isLoading={modifying} formName="EventForm" />
          </div>
        </PaperHeader>
        {areEventsFulfilled && (
          <Paper hasHeader className="detail-event-content">
            <EventForm
              onSubmit={this.modifyEvent}
              disabledFields={["source_id", "type", "version"]}
              initialValues={formInitValues}
            />
          </Paper>
        )}
      </section>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  event: getEventById(state, ownProps.match.params.id),
  areEventsFulfilled: getEventsIsFulfilled(state),
  sourcesArray: getDataSourcesArrayForSelect(state, true),
  dataSourcesMap: getDataSourcesData(state, true),
})

EventDetail.propTypes = {
  event: PropTypes.instanceOf(Record),
  areEventsFulfilled: PropTypes.bool.isRequired,
  sourcesArray: PropTypes.array.isRequired,
  modifyEvent: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  dataSourcesMap: PropTypes.instanceOf(Map).isRequired,
}

export default connect(mapStateToProps, { modifyEvent, showToast })(EventDetail)
