import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Form, Field, reduxForm, formValueSelector } from "redux-form"
import { Map, List } from "immutable"
import _toLower from "lodash/toLower"
import _size from "lodash/size"

// selectors
import { isDataSourcesFulfilled } from "resources/dataSource/dataSourceSelectors"
import { getEventsSourceIds } from "selectors/event.selector"

// ui components
import Paper from "components/UI/elements/Paper"
import IconButton, { COLOR } from "components/UI/elements/IconButton"
import SearchField from "components/UI/elements/SearchField"
import InfoTooltip from "components/UI/elements/InfoTooltip"

// helpers
import { getIconSrc } from "helpers/image.helper"
import { getCustomerSourceIdentification } from "pages/Customers/CustomerDetail/getCustomerSourceIdentification"
import { hexToRgba } from "helpers/chartjs.helper"

import { ReactComponent as OneColIcon } from "images/one-col.svg"
import { ReactComponent as TwoColsIcon } from "images/two-col.svg"

import "./CustomerDetailFilterForm.scss"

const SourceCheckboxField = ({
  source,
  input,
  label,
  className,
  sourceExpanded,
  onChange = () => {},
  disabled = false,
  ...fieldAttrs
}) => {
  const color = source.getIn(["frontend_settings", "color"], "primary")
  return (
    <div className={`source-checkbox-field single-source ${className ? className : ""}`}>
      <label className={`${disabled ? "disabled" : ""} ${!input.value ? "unchecked" : ""}`}>
        {source.name}
        <input
          {...input}
          checked={input.value}
          type="checkbox"
          className="checkbox"
          onChange={evt => {
            input.onChange(evt)
            onChange(evt)
          }}
          disabled={disabled}
          {...fieldAttrs}
        />
        <div className={`source-icon ${color}`}>
          <img
            src={getIconSrc(
              {
                primary: source.getIn(["frontend_settings", "icon"]),
                secondary: _toLower(source.type),
              },
              source.getIn(["frontend_settings", "alt_icon"]),
              true,
            )}
            alt="icon"
          />
        </div>
      </label>
    </div>
  )
}

class CustomerDetailFilterForm extends PureComponent {
  handleSelectAllCheckboxes = () => {
    const { resetSection, eventsFetching } = this.props

    if (!eventsFetching) {
      resetSection("CustomerDetailFilterForm", "sources")
      resetSection("CustomerDetailFilterForm", "eventTypes")
    }
  }

  handleDeselectAllCheckboxes = () => {
    const { initialValues } = this.props
    let sources = {}
    Object.keys(initialValues.sources).forEach(key => (sources[key] = false))

    let eventTypes = {}
    Object.keys(initialValues.eventTypes).forEach(keyLvl1 => {
      eventTypes[keyLvl1] = {}
      Object.keys(initialValues.eventTypes[keyLvl1]).forEach(keyLvl2 => {
        eventTypes[keyLvl1][keyLvl2] = false
      })
    })

    this.props.change("sources", sources)
    this.props.change("eventTypes", eventTypes)
  }

  handleSourceCheckboxChange = evt => {
    const { resetSection, change, eventTypesBySourceId } = this.props

    const changedSourceId = evt.currentTarget.name.split(".")[1]
    if (evt.currentTarget.checked) {
      resetSection("CustomerDetailFilterForm", `eventTypes.${changedSourceId}`)
    } else {
      const sourceEventTypes = eventTypesBySourceId.get(changedSourceId)
      if (sourceEventTypes) {
        sourceEventTypes.forEach(eventType => {
          change(`eventTypes.${changedSourceId}.${eventType.id}`, false)
        })
      }
    }
  }

  handleEventTypeCheckboxChange = evt => {
    const { change } = this.props
    if (evt.currentTarget.checked) {
      const changedSourceId = evt.currentTarget.name.split(".")[1]
      change(`sources.${changedSourceId}`, true)
    }
  }

  toggleIdentifier = attributeId => () => {
    const { graphIdentifiers, change } = this.props
    const val = !graphIdentifiers[attributeId]
    change(`graphIdentifiers.${attributeId}`, val)
  }

  render() {
    const {
      attributesMapBySourceId,
      customerAttributes,
      dataSources,
      isDataSourcesFulfilled,
      screenType,
      eventsFetching,
      currentLayout,
      setLayout,
      timelineAvailableSourceIds,
      stitchingAttributes,
      graphIdentifiers,
    } = this.props

    if (!isDataSourcesFulfilled) {
      return null
    }
    const identifiedInSources = getCustomerSourceIdentification(
      attributesMapBySourceId,
      customerAttributes,
      dataSources,
    )
    const sourcesToRender = dataSources.filter(source => {
      let result = identifiedInSources.get(source.id)
      if (screenType === "timeline") {
        if (!timelineAvailableSourceIds.includes(source.id)) {
          result = false
        }
      }
      return result
    })

    return (
      <Form
        className="attribute-filter-form"
        onSubmit={evt => {
          evt.preventDefault()
        }}
        autoComplete="off"
      >
        <Paper
          hasHeader={true}
          className={`attribute-options ${screenType} ${eventsFetching ? "fetching" : ""}`}
        >
          <h3>
            {screenType === "identity" ? (
              <span>
                Identity graph{" "}
                <InfoTooltip placement="right" className="identity-graph-tooltip" interactive>
                  Identity Graph shows how your customer entity has been stitched from various
                  identifiers across data sources.{" "}
                  <a
                    href="https://docs.meiro.io/books/meiro-knowledgebase/page/identity-stitching-and-how-customer-identity-appears"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Learn more here
                  </a>
                  .
                </InfoTooltip>
              </span>
            ) : (
              "Sources"
            )}
          </h3>
          {screenType !== "identity" && (
            <React.Fragment>
              <div className="sources-layout-wrapper">
                <div className="sources">
                  {sourcesToRender
                    .map(source => {
                      return (
                        <Field
                          key={source.id}
                          name={`sources.${source.id}`}
                          source={source}
                          component={SourceCheckboxField}
                          className="bold-label"
                          onChange={this.handleSourceCheckboxChange}
                          disabled={eventsFetching}
                        />
                      )
                    })
                    .toList()}
                </div>
                <div className={`layout-picker ${screenType !== "attributes" ? "hidden" : ""}`}>
                  <IconButton
                    color={COLOR.GREY}
                    className={`layout-button ${currentLayout === "two-cols" ? "active" : ""}`}
                    onClick={setLayout("two-cols")}
                    customIcon={<TwoColsIcon />}
                    tooltip="Two columns"
                  />
                  <IconButton
                    color={COLOR.GREY}
                    className={`layout-button ${currentLayout === "one-col" ? "active" : ""}`}
                    onClick={setLayout("one-col")}
                    customIcon={<OneColIcon />}
                    tooltip="Single column"
                  />
                </div>
              </div>
            </React.Fragment>
          )}
          {screenType === "identity" && (
            <Field
              name="searchIdentifier"
              component={SearchField}
              placeholder="Search for identifier value"
              className="large search-box"
              type="text"
            />
          )}
        </Paper>
        {screenType === "identity" && _size(graphIdentifiers) > 0 && (
          <Paper className="filter-graph-identifiers">
            <div className="label">Filter identifier:</div>
            <div className="buttons">
              {stitchingAttributes.map(attribute => {
                if (graphIdentifiers[attribute.id] !== undefined) {
                  return (
                    <button
                      key={attribute.id}
                      className={`identifier-button ${
                        graphIdentifiers[attribute.id] ? "" : "not-active"
                      }`}
                      style={{
                        "--color": hexToRgba(attribute.identity_graph_color, 1),
                        "--background-color": hexToRgba(attribute.identity_graph_color, 0.5),
                      }}
                      onClick={this.toggleIdentifier(attribute.id)}
                      type="button"
                    >
                      {attribute.name}
                    </button>
                  )
                }
                return null
              })}
            </div>
          </Paper>
        )}
      </Form>
    )
  }
}

CustomerDetailFilterForm.propTypes = {
  dataSources: PropTypes.instanceOf(Map).isRequired,
  eventTypesBySourceId: PropTypes.instanceOf(Map).isRequired,
  attributesMapBySourceId: PropTypes.object.isRequired,
  customerAttributes: PropTypes.instanceOf(Map).isRequired,
  isDataSourcesFulfilled: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  screenType: PropTypes.string.isRequired,
  formChanged: PropTypes.func.isRequired,
  eventsFetching: PropTypes.bool.isRequired,
  timelineAvailableSourceIds: PropTypes.instanceOf(List).isRequired,
  stitchingAttributes: PropTypes.instanceOf(Array).isRequired,
}

const selector = formValueSelector("CustomerDetailFilterForm")
const mapStateToProps = state => ({
  isDataSourcesFulfilled: isDataSourcesFulfilled(state),
  timelineAvailableSourceIds: getEventsSourceIds(state),
  graphIdentifiers: selector(state, "graphIdentifiers"),
})

CustomerDetailFilterForm = connect(mapStateToProps)(CustomerDetailFilterForm)

export default reduxForm({
  form: "CustomerDetailFilterForm",
  touchOnBlur: false,
  enableReinitialize: true,
  onChange: (values, dispatch, props, previousValues) => {
    props.formChanged()
  },
})(CustomerDetailFilterForm)
