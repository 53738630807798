import { createSelector } from "reselect"
import { Map } from "immutable"
import _toString from "lodash/toString"
import { ascend, filter, identity, map, pipe, prop, sort, uniq } from "ramda"
import { SYSTEM_USER_ID } from "sharedConstants"

const getActiveUsers = state => state.users.get("active")
const getDeletedUsers = state => state.users.get("removed")

export const areUsersFulfilled = state =>
  state.users.get("active") !== null && state.users.get("removed") !== null

export const getUsersData = createSelector([getActiveUsers], users => {
  return users ? users : Map()
})

export const getAllUsersMap = createSelector(
  [getActiveUsers, getDeletedUsers],
  (active, removed) => {
    if (active === null && removed === null) {
      return Map()
    } else if (active === null) {
      return removed
    } else if (removed === null) {
      return active
    } else {
      return active.merge(removed)
    }
  },
)

export const getActiveUserById = (state, id) => {
  const users = getActiveUsers(state)
  if (users) {
    return users.get(_toString(id))
  }
  return false
}

export const getUsersForSelect = createSelector(
  [getAllUsersMap],
  pipe(
    usersMap => usersMap.toIndexedSeq().toJS(),
    filter(({ id }) => id !== SYSTEM_USER_ID),
    map(user => ({ value: user.id, label: user.email })),
    sort(ascend(prop("label"))),
  ),
)

export const getEmailDomainsForSelect = createSelector(
  [getAllUsersMap],
  pipe(
    usersMap => usersMap.toIndexedSeq().toJS(),
    filter(({ id }) => id !== SYSTEM_USER_ID),
    map(({ email }) => `@${email.split("@")[1]}`),
    uniq,
    sort(ascend(identity)),
    map(domain => ({ value: domain, label: domain })),
  ),
)
