import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSTransition } from "react-transition-group"

import Button from "components/UI/elements/Button/Button"

import "./AppUpdateModal.scss"
import meiroLogoSrc from "images/meiro-symbol-white-transparent.svg"

class AppUpdateModal extends PureComponent {
  componentDidMount() {
    if (this.props.open) {
      this.removeFocusFromInputs()
      window.addEventListener("keyup", this.handleKeyUp, false)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.removeFocusFromInputs()
      window.addEventListener("keyup", this.handleKeyUp, false)
    } else if (!this.props.open && prevProps.open) {
      window.removeEventListener("keyup", this.handleKeyUp, false)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false)
  }

  removeFocusFromInputs = () => {
    const el = document.querySelector(":focus")
    if (el) el.blur()
  }

  handleKeyUp = evt => {
    const keys = {
      27: () => {
        evt.preventDefault()
        this.closeModal()
      },
    }
    if (keys[evt.keyCode]) {
      keys[evt.keyCode]()
    }
  }

  closeModal = () => {
    window.removeEventListener("keyup", this.handleKeyUp, false)
    this.props.onClose()
  }

  render() {
    const { open } = this.props

    return (
      <CSSTransition in={open} timeout={200} classNames="fade" unmountOnExit>
        <div className="update-modal-wrapper">
          <div className="update-modal">
            <header>
              <img src={meiroLogoSrc} alt="" />
              <h3>Yuppi! The new version of Meiro is available</h3>
            </header>
            <section>
              <div className="text">
                <p>Update to the latest version of Meiro Business Explorer.</p>
                <p>
                  If you want to <strong>finish unsaved work</strong>, click on “No, ask me later”,
                  save changes and proceed with an update later.
                </p>
              </div>
              <div className="actions">
                <Button size="small" className="later" onClick={this.closeModal}>
                  No, ask me later
                </Button>
                <Button size="small" className="update" onClick={() => window.location.reload()}>
                  <FontAwesomeIcon icon={["fas", "arrow-circle-up"]} className="update-icon" /> Yes,
                  update
                </Button>
              </div>
            </section>
          </div>
          <div className="update-modal-overlay" />
        </div>
      </CSSTransition>
    )
  }
}

AppUpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AppUpdateModal
