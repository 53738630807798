import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import _get from "lodash/get"
import _noop from "lodash/noop"
import _toInteger from "lodash/toInteger"
import _isEmpty from "lodash/isEmpty"
import _find from "lodash/find"
import _pick from "lodash/pick"
import _isString from "lodash/isString"

// actions
import {
  fetchGlobalSettingsList,
  modifyGlobalSettingsItem,
} from "resources/globalSettings/globalSettingsActions"
import { fetchDataSourcesList } from "resources/dataSource/dataSourceActions"
import { fetchEventsList } from "actions/event.action"

import { showToast } from "actions/toast.action"

// selectors
import {
  getGlobalSettingsFormValues,
  isGlobalSettingsFulfilled,
} from "resources/globalSettings/globalSettingsSelectors"

// ui components
import SettingsForm from "./SettingsForm"

// helpers, constants
import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { TOAST, DATABASE_DRIVERS, BIGQUERY_LOCATIONS } from "sharedConstants"
import { hasAccess } from "helpers/authenticatedUser.helper"

import "./Settings.scss"
import { refetchAttributes } from "resources/attribute/attributeQueries"

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tagListForSelect: [],
    }
  }

  async retrieveTags() {
    const caller = new AllResourceItemsFetcher()
    try {
      const data = await caller
        .setEndpointCall((offset, limit) => api.label.list(offset, limit, "name", "ASC"))
        .setDataPath("tags")
        .run()
      const tagListForSelect = data.map(tag => ({ value: tag.id, label: tag.name }))
      this.setState({ tagListForSelect })
    } catch (err) {}
  }

  componentDidMount() {
    this.retrieveTags()
    this.props.fetchGlobalSettingsList()
  }

  onSubmit = async values => {
    const { modifyGlobalSettingsItem, showToast, fetchGlobalSettingsList } = this.props
    const driver = _get(values, "cdp_db.value.driver.value")
    let dbValue = {}
    if (driver === "bigquery") {
      const location = _get(values, "cdp_db.value.location.value")
      dbValue = {
        ..._pick(values.cdp_db.value, [
          "project",
          "dataset",
          "exports_dataset",
          "demo_dataset",
          "#credentials",
        ]),
        location,
        driver,
      }
    } else if (driver === "redshift" || driver === "postgre") {
      dbValue = {
        ..._pick(values.cdp_db.value, [
          "host",
          "port",
          "db_name",
          "exports_db_name",
          "schema",
          "demo_db_name",
          "user",
          "#password",
        ]),
        driver,
      }
    }

    const hasMiApiValues = _get(values, "mi_api.value.url", false)
    const hasMeApiValues = _get(values, "me_api.value.url", false)

    await Promise.all([
      modifyGlobalSettingsItem(values.cdp_cache_expiration_period.id, {
        value: values.cdp_cache_expiration_period.value,
      }),
      modifyGlobalSettingsItem(values.cdp_db.id, { value: dbValue }),
      modifyGlobalSettingsItem(values.customer_entities_db.id, {
        value: {
          ..._get(values, "customer_entities_db.value", ""),
          port: _toInteger(_get(values, "customer_entities_db.value.port", 0)),
        },
      }),
      modifyGlobalSettingsItem(values.additional_search_result_attribute_id.id, {
        value: _get(values, "additional_search_result_attribute_id.value", ""),
      }),
      modifyGlobalSettingsItem(values.channel_engagement_tag_id.id, {
        value: _get(values, "channel_engagement_tag_id.value.value", ""),
      }),
      modifyGlobalSettingsItem(values.contact_info_tag_id.id, {
        value: _get(values, "contact_info_tag_id.value.value", ""),
      }),
      modifyGlobalSettingsItem(values.maintenance_notifications_emails.id, {
        value: values.maintenance_notifications_emails.values,
      }),
      modifyGlobalSettingsItem(values.mi_api.id, {
        value: hasMiApiValues ? values.mi_api.value : null,
      }),
      modifyGlobalSettingsItem(values.me_api.id, {
        value: hasMeApiValues ? values.me_api.value : null,
      }),
    ]).then(response => {
      fetchGlobalSettingsList().catch(_noop)

      this.renewCache()

      showToast("Global settings edited.", TOAST.TYPE.SUCCESS)
    })
  }

  renewCache = () => {
    const { fetchDataSourcesList, fetchEventsList } = this.props
    this.retrieveTags()
    if (
      hasAccess.segments.insights() ||
      hasAccess.data.dashboard() ||
      hasAccess.data.insights() ||
      hasAccess.data.events() ||
      hasAccess.data.sourcesAndDestinations() ||
      hasAccess.setup.implementation ||
      hasAccess.customers.detail()
    ) {
      fetchDataSourcesList(1).catch(_noop)
    }
    refetchAttributes()
    if (hasAccess.data.dashboard() || hasAccess.data.events() || hasAccess.customers.detail()) {
      fetchEventsList(1).catch(_noop)
    }
  }

  render() {
    const { initialValues, isGlobalSettingsFulfilled } = this.props
    const { tagListForSelect } = this.state

    if (!isGlobalSettingsFulfilled) {
      return null
    }

    const selectedContactInformationTagId = _get(initialValues, "contact_info_tag_id.value", null)
    const selectedContactInfoTag = tagListForSelect.find(
      tag => tag.value === selectedContactInformationTagId,
    )

    const selectedChannelEngagementTagId = _get(
      initialValues,
      "channel_engagement_tag_id.value",
      null,
    )
    const selectedChannelEngagementTag = tagListForSelect.find(
      tag => tag.value === selectedChannelEngagementTagId,
    )

    const driver = _get(initialValues, "cdp_db.value.driver")
    let transformedInitialValues = {
      ...initialValues,
      cdp_db: {
        ...initialValues.cdp_db,
        value: !_isEmpty(_get(initialValues, "cdp_db.value"))
          ? {
              ...initialValues.cdp_db.value,
              driver: driver
                ? _find(DATABASE_DRIVERS, ["value", driver])
                : { label: "PostgreSQL", value: "postgre" },
            }
          : {},
      },
      additional_search_result_attribute_id: {
        id: _get(initialValues, "additional_search_result_attribute_id.id", null),
        value: _get(initialValues, "additional_search_result_attribute_id.value", null),
      },
      contact_info_tag_id: {
        id: _get(initialValues, "contact_info_tag_id.id", null),
        value: selectedContactInfoTag,
      },
      channel_engagement_tag_id: {
        id: _get(initialValues, "channel_engagement_tag_id.id", null),
        value: selectedChannelEngagementTag,
      },
      maintenance_notifications_emails: {
        id: _get(initialValues, "maintenance_notifications_emails.id"),
        values: _isString(_get(initialValues, "maintenance_notifications_emails.value"))
          ? [_get(initialValues, "maintenance_notifications_emails.value")]
          : _get(initialValues, "maintenance_notifications_emails.value"),
      },
    }

    if (driver === "bigquery") {
      const location = _get(initialValues, "cdp_db.value.location")
      transformedInitialValues = {
        ...transformedInitialValues,
        cdp_db: {
          ...transformedInitialValues.cdp_db,
          value: {
            ...transformedInitialValues.cdp_db.value,
            location: location ? _find(BIGQUERY_LOCATIONS, ["value", location]) : null,
          },
        },
      }
    }

    return (
      <SettingsForm
        tagListForSelect={tagListForSelect}
        onSubmit={this.onSubmit}
        initialValues={transformedInitialValues}
        handleCacheRenew={this.renewCache}
      />
    )
  }
}

Settings.propTypes = {
  fetchGlobalSettingsList: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isGlobalSettingsFulfilled: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  return {
    initialValues: getGlobalSettingsFormValues(state),
    isGlobalSettingsFulfilled: isGlobalSettingsFulfilled(state),
  }
}

export default connect(mapStateToProps, {
  fetchGlobalSettingsList,
  modifyGlobalSettingsItem,
  showToast,
  fetchDataSourcesList,
  fetchEventsList,
})(Settings)
