import { Record } from "immutable"

const SelectionSettings = Record({
  limit: 0,
  offset: 0,
  order_by: "",
  order_dir: "",
  name_filter: null,
  search_text: null,
  search_value: null,
  tag_ids: [],
  source_ids: [],
  time_resolution: null,
  start_date: "",
  end_date: "",
})

export const SegmentSelectionSettings = Record({
  limit: 0,
  name_filter: "",
  offset: 0,
  order_by: "",
  order_dir: "",
  show_my: true,
  show_shared_with_me: true,
  show_foreign: true,
  tag_ids: [],
})

export default SelectionSettings
