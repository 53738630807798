import { ACL } from "sharedConstants"
import { Map, fromJS } from "immutable"
import _map from "lodash/map"
import Acl from "models/acl.model"
import _toString from "lodash/toString"

/*
 * { segmentId: [acls] }
 */
const initialState = Map(
  fromJS({
    data: {},
  }),
)

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${ACL.ACTION.LIST}_FULFILLED`:
      return state.set(
        "data",
        state.get("data").merge(
          fromJS({
            [payload.segmentId]: _map(payload.data, acl => new Acl(acl)),
          }),
        ),
      )
    case `${ACL.ACTION.CREATE}_FULFILLED`:
      return state.setIn(
        ["data", _toString(payload.segment_id)],
        state.getIn(["data", _toString(payload.segment_id)]).insert(0, new Acl(payload)),
      )
    case `${ACL.ACTION.MODIFY}_FULFILLED`:
      return state.setIn(
        ["data", _toString(payload.segment_id)],
        state
          .getIn(["data", _toString(payload.segment_id)])
          .map(acl =>
            acl.user_id === payload.user_id && acl.segment_id === payload.segment_id
              ? new Acl(payload)
              : acl,
          ),
      )
    case `${ACL.ACTION.DELETE}_FULFILLED`:
      return state.setIn(
        ["data", _toString(payload.segmentId)],
        state
          .getIn(["data", _toString(payload.segmentId)])
          .filterNot(acl => acl.user_id === payload.userId && acl.segment_id === payload.segmentId),
      )
    default:
      return state
  }
}
