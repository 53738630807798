import _toString from "lodash/toString"
import { Map, List } from "immutable"
import SelectionSettings from "models/selectionSettings.model"
import _toInteger from "lodash/toInteger"

export const getSegmentExports = (state, id) => {
  if (state.segmentExports.has(_toString(id))) {
    return state.segmentExports.get(_toString(id))
  }
  return Map({
    data: List(),
    selectionSettings: SelectionSettings(),
    hasMoreItems: false,
  })
}

export const getSegmentExportData = (state, segmentId, exportId) => {
  if (List.isList(state.segmentExports.getIn([_toString(segmentId), "data"]))) {
    const index = state.segmentExports
      .getIn([_toString(segmentId), "data"])
      .findIndex(item => item.id === _toInteger(exportId))
    if (index !== -1) {
      return state.segmentExports.getIn([_toString(segmentId), "data", index])
    }
  }
  return null
}
