import { createSelector } from "reselect"

export const areWorkspacesFulfilled = state => state.workspaces.isFulfilled

export const areWorkspacesFetching = state => state.workspaces.isFetching

const getWorkspaces = state => state.workspaces.data

export const getWorkspacesForSelect = createSelector([getWorkspaces], workspaces => {
  return workspaces.map(ws => ({ value: ws.id, label: ws.name }))
})
