import React from "react"
import moment from "moment"

import { BUILD_DATE } from "sharedConstants"
import logo from "images/meiro-symbol-white-transparent.svg"
import { version as meiroVersion } from "../../../package.json"

import "./AuthFormLayout.scss"

const AuthFormLayout: React.FC = ({ children }) => (
  <section className="user-form-screens">
    <div className="form-wrapper">
      <img src={logo} className="logo" alt="meiro cdp logo" />
      {children}
    </div>
    <header className="caption">
      <div className="content-wrapper">
        <h1>Meiro Business Explorer</h1>
        <h2>
          All of your customer <span>data</span>: connected and <span>activated</span>.
        </h2>
        <div className="updates">
          <a
            href="https://status.meiro.io/"
            target="_blank"
            rel="noopener noreferrer"
            className="release-notes-link"
          >
            Check new release notes
          </a>
          Latest update, {meiroVersion} – <span>{moment.utc(BUILD_DATE).local().fromNow()}</span>
        </div>
      </div>
    </header>
  </section>
)

export default AuthFormLayout
