import React, { useEffect, useState, useCallback } from "react"
import classnames from "classnames"
import styles from "./FeaturedSegmentsList.module.scss"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { hasAccess, usersPermission } from "helpers/authenticatedUser.helper"
import Paper from "components/UI/elements/Paper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import Table, {
  RowMessage,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  SortButton,
} from "components/UI/elements/Table"
import { useDispatch } from "react-redux"
import useTypedSelector from "hooks/useTypedSelector"
import {
  fetchSegments,
  deleteSegment,
  createSegment as createSegmentAction,
} from "resources/segment/segment/segmentActions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PERMISSION, MOMENT, MODAL, TOAST, DATEFNS } from "sharedConstants"
import { getRoutePath } from "routes"
import { getScheduleText } from "resources/segment/segment/utilities/segmentSchedulesUtils"
import {
  SegmentScheduleCron,
  SegmentSchedule,
  Segment,
  FeaturedSegmentListResponse,
} from "resources/segment/segment/segmentTypes"
import { getIconSrc } from "helpers/image.helper"
import _toLower from "lodash/toLower"
import Tippy from "@tippyjs/react"
import TagBadge from "components/UI/elements/Tag"
import Username from "helpers/Username.helper"
import moment from "moment"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import { showToast } from "actions/toast.action"
import ConfirmModal from "components/UI/components/ConfirmModal"
import SearchForm from "components/UI/components/SearchForm"
import TagPicker from "components/UI/components/TagPicker"
import { Tag } from "resources/tag/tagTypes"
import Waypoint from "react-waypoint"
import { fetchUsersAclList } from "actions/acl.action"
import { useHistory } from "react-router"
import { format } from "date-fns"

export default function () {
  const dispatch = useDispatch()
  const history = useHistory()
  const authenticatedUser = useTypedSelector(state => state.authenticatedUser)
  const { initialListFetching, featuredSegments, selectionSettings } = useTypedSelector(
    state => state.featuredSegments,
  )
  const exportDestinationsState = useTypedSelector(state => state.exportDestinations)
  const tags = useTypedSelector(state => state.tags.get("data"))
  const [isCreatingSegment, setIsCreatingSegment] = useState(false)
  const [deleteModal, setDeleteModal] = useState<{
    open: boolean
    segment: Segment | null
    deleting: boolean
  }>({ open: false, segment: null, deleting: false })
  const [searching, setSearching] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)

  useEffect(() => {
    dispatch(
      fetchSegments(
        0,
        selectionSettings.name_filter,
        selectionSettings.order_by,
        selectionSettings.order_dir,
        selectionSettings.tag_ids ?? [],
        "featured",
      ),
    )
    // eslint-disable-next-line
  }, [dispatch])

  const loadMore = useCallback(async () => {
    setLoadingMore(true)
    try {
      await dispatch(
        fetchSegments(
          selectionSettings.offset! + selectionSettings.limit!,
          selectionSettings.name_filter,
          selectionSettings.order_by,
          selectionSettings.order_dir,
          selectionSettings.tag_ids ?? [],
          "featured",
        ),
      )
      setLoadingMore(false)
    } catch {
      setLoadingMore(false)
    }
  }, [dispatch, selectionSettings])

  const renderWaypoint = () => {
    const hasMoreSegments =
      featuredSegments.length === selectionSettings.offset! + selectionSettings.limit!
    if (!loadingMore && hasMoreSegments) {
      return <Waypoint onEnter={loadMore} bottomOffset={-300} />
    } else if (loadingMore && hasMoreSegments) {
      return <LoadingIndicator />
    }
  }

  const createSegment = useCallback(async () => {
    setIsCreatingSegment(true)

    try {
      const {
        // @ts-ignore
        value: { segment },
      } = await dispatch(createSegmentAction({ name: "Untitled segment", featured: 1 }, "featured"))
      await dispatch(fetchUsersAclList(authenticatedUser.data!.id))

      history.push(getRoutePath("segments.featured.detail", { id: segment.id }))
      dispatch(showToast(`Featured segment created.`, TOAST.TYPE.SUCCESS))
    } catch (error) {
      setIsCreatingSegment(false)
    }
  }, [authenticatedUser.data, dispatch, history])

  const openDeleteSegmentModal = (segment: Segment) => (evt: MouseEvent) => {
    evt.preventDefault()
    setDeleteModal({
      open: true,
      segment,
      deleting: false,
    })
  }

  const closeDeleteSegmentModal = useCallback(() => {
    setDeleteModal({
      ...deleteModal,
      open: false,
      deleting: false,
    })
  }, [deleteModal])

  const onDeleteSegmentConfirm = useCallback(async () => {
    const { segment, deleting } = deleteModal
    if (!deleting) {
      setDeleteModal({
        ...deleteModal,
        deleting: true,
      })
      try {
        await dispatch(deleteSegment(segment!.id, "featured"))
        dispatch(showToast("Featured segment deleted."))
        setDeleteModal({
          ...deleteModal,
          open: false,
          deleting: false,
        })
      } catch (err) {
        setDeleteModal({
          ...deleteModal,
          deleting: false,
        })
      }
    }
  }, [deleteModal, dispatch])

  const onSearchSubmit = useCallback(
    async (searchTerm: string) => {
      try {
        setSearching(true)
        await dispatch(
          fetchSegments(
            0,
            searchTerm,
            selectionSettings.order_by,
            selectionSettings.order_dir,
            selectionSettings.tag_ids ?? [],
            "featured",
          ),
        )
      } finally {
        setSearching(false)
      }
    },
    [dispatch, selectionSettings],
  )

  const sortSegmentsBy = useCallback(
    (columnName: FeaturedSegmentListResponse<0>["selection_settings"]["order_by"]) => async () => {
      try {
        setSearching(true)
        const order_dir =
          columnName === selectionSettings.order_by
            ? selectionSettings.order_dir?.toUpperCase() === "ASC"
              ? "DESC"
              : "ASC"
            : "ASC"
        await dispatch(
          fetchSegments(
            0,
            selectionSettings.name_filter,
            columnName,
            order_dir,
            selectionSettings.tag_ids ?? [],
            "featured",
          ),
        )
      } catch {
      } finally {
        setSearching(false)
      }
    },
    [selectionSettings, dispatch],
  )

  const selectTag = useCallback(
    async (tagId: number) => {
      try {
        setSearching(true)
        const tagIds = selectionSettings.tag_ids ?? []
        if (!tagIds.includes(tagId)) {
          tagIds.push(tagId)
        }
        await dispatch(
          fetchSegments(
            0,
            selectionSettings.name_filter,
            selectionSettings.order_by,
            selectionSettings.order_dir,
            tagIds,
            "featured",
          ),
        )
      } finally {
        setSearching(false)
      }
    },
    [selectionSettings, dispatch],
  )

  const deselectTag = useCallback(
    (tagId: number) => async () => {
      try {
        setSearching(true)
        const tagIds = selectionSettings.tag_ids ?? []
        await dispatch(
          fetchSegments(
            0,
            selectionSettings.name_filter,
            selectionSettings.order_by,
            selectionSettings.order_dir,
            tagIds.filter(selectedTagId => selectedTagId !== tagId),
            "featured",
          ),
        )
      } finally {
        setSearching(false)
      }
    },
    [selectionSettings, dispatch],
  )

  const renderSchedulingElement = useCallback(
    scheduling => {
      if (!exportDestinationsState.get("isFulfilled")) {
        return null
      }

      let tooltipContent: JSX.Element[] = []
      const isScheduled = Array.isArray(scheduling)
      const schedules: Record<string, string[]> = {}
      if (isScheduled) {
        scheduling.forEach((entry: SegmentSchedule) => {
          if (Array.isArray(entry.destination_ids)) {
            const schedulesTexts = entry.schedules.map((scheduleCron: SegmentScheduleCron) =>
              getScheduleText(scheduleCron),
            )
            entry.destination_ids.forEach((destinationId: number) => {
              schedules[`${destinationId}`] = schedulesTexts
            })
          }
        })
        exportDestinationsState
          .get("data")
          .sortBy((destination: any) => _toLower(destination.name))
          .forEach((destination: any) => {
            if (Array.isArray(schedules[`${destination.id}`])) {
              tooltipContent.push(
                <div
                  className={`destination-schedule ${destination.getIn([
                    "frontend_settings",
                    "color",
                  ])}`}
                  key={destination.id}
                >
                  <img
                    src={getIconSrc(
                      destination.icon,
                      destination.getIn(["frontend_settings", "alt_icon"]),
                      true,
                    )}
                    alt="destination icon"
                  />
                  <p>
                    {destination.name} – {schedules[`${destination.id}`].join("; ")}
                  </p>
                </div>,
              )
            }
          })
      }
      return (
        <React.Fragment>
          <Tippy
            className="scheduling-tooltip"
            content={<>{isScheduled ? tooltipContent : "Not scheduled"}</>}
          >
            <span className={classnames(styles.schedulingIcon, { [styles.active]: isScheduled })}>
              <FontAwesomeIcon icon={["far", "clock"]} />
            </span>
          </Tippy>
        </React.Fragment>
      )
    },
    [exportDestinationsState],
  )

  return (
    <section className={classnames("wrapper", styles.featuredSegments)}>
      <PaperHeader className={styles.header} size="small" titleText="Featured segments">
        <SearchForm
          placeholder="Search for name or tag"
          className={styles.searchBar}
          onSubmit={onSearchSubmit}
          initialValues={{ search: "" }}
          form="FeaturedSegmentSearch"
        />
        <Button
          color="primary"
          size="small"
          onClick={createSegment}
          disabled={!hasAccess.segments.featured.edit()}
          isLoading={isCreatingSegment}
        >
          + Create featured segment
        </Button>
      </PaperHeader>
      <Paper hasHeader>
        <div className={styles.tagsRow}>
          <span className={styles.label}>Filter by:</span>
          <span className={styles.tagsAndPicker}>
            {selectionSettings.tag_ids?.map(tagId => {
              let color = tags.getIn([tagId.toString(), "color"])
              if (!color) {
                color = "primary"
              }
              return (
                <TagBadge
                  key={tagId}
                  clickable={true}
                  color={color}
                  onClick={deselectTag(tagId)}
                  className={styles.tag}
                >
                  {tags.getIn([tagId.toString(), "name"], "Deleted tag")}
                </TagBadge>
              )
            })}
            <TagPicker
              selectedTagIds={selectionSettings.tag_ids ?? []}
              allTags={tags.toList().sortBy((tag: Tag) => tag.name.toLowerCase())}
              onTagSelect={selectTag}
            />
          </span>
        </div>
        {initialListFetching || searching ? (
          <LoadingIndicator />
        ) : featuredSegments.length === 0 ? (
          <RowMessage>No featured segments found.</RowMessage>
        ) : (
          <div>
            <Table>
              <Thead stickyHeader>
                <Th>
                  <SortButton
                    column="name"
                    orderBy={selectionSettings.order_by}
                    orderDir={selectionSettings.order_dir}
                    onClick={sortSegmentsBy("name")}
                    label="Name"
                  />
                </Th>
                <Th>
                  <FontAwesomeIcon icon={["far", "tag"]} /> Tags
                </Th>
                <Th>
                  <SortButton
                    column="author_name"
                    orderBy={selectionSettings.order_by}
                    orderDir={selectionSettings.order_dir}
                    onClick={sortSegmentsBy("author_name")}
                    label="Author"
                  />
                </Th>
                <Th>
                  <SortButton
                    column="last_export"
                    orderBy={selectionSettings.order_by}
                    orderDir={selectionSettings.order_dir}
                    onClick={sortSegmentsBy("last_export")}
                    label={<span>Last&nbsp;export</span>}
                  />
                </Th>
                <Th textAlignRight>
                  <SortButton
                    column="created"
                    orderBy={selectionSettings.order_by}
                    orderDir={selectionSettings.order_dir}
                    onClick={sortSegmentsBy("created")}
                    label={<span>Modified at</span>}
                  />
                </Th>
                <Th>&nbsp;</Th>
              </Thead>
              <Tbody>
                {featuredSegments.map((featuredSegment: Segment) => {
                  const isForbidden = ![PERMISSION.WRITE, PERMISSION.READ].includes(
                    usersPermission(featuredSegment.id, true),
                  )
                  return (
                    <React.Fragment key={featuredSegment.id}>
                      <Tr
                        href={getRoutePath("segments.featured.detail", { id: featuredSegment.id })}
                        disabled={isForbidden}
                        onClick={e => {
                          if (isForbidden) {
                            e!.preventDefault()
                          }
                        }}
                      >
                        <Td className={styles.segmentName} textBigger textBlack textBold>
                          <div className={styles.segmentNameContentWrapper}>
                            {!isForbidden &&
                              renderSchedulingElement(featuredSegment.settings?.scheduling)}
                            <Tippy
                              content="You have no permission to access."
                              disabled={!isForbidden}
                            >
                              <span>{featuredSegment.name}</span>
                            </Tippy>
                          </div>
                        </Td>
                        <Td className={styles.tags}>
                          {featuredSegment.tag_ids.map(tagId => {
                            const tag = tags.get(`${tagId}`)
                            if (tag) {
                              return (
                                <TagBadge
                                  key={tag.id}
                                  color={tag.color ? tag.color : "primary"}
                                  onClick={(evt: MouseEvent) => {
                                    evt.preventDefault()
                                    selectTag(tag.id)
                                  }}
                                  className={styles.tag}
                                >
                                  {tag.name}
                                </TagBadge>
                              )
                            }
                            return null
                          })}
                        </Td>
                        <Td className={styles.author}>
                          {<Username userId={featuredSegment.author_id} />}
                        </Td>
                        <Td className={classnames(styles.date, styles.exportDate)}>
                          {featuredSegment.last_export !== null
                            ? moment
                                .utc(featuredSegment.last_export)
                                .local()
                                .format(MOMENT.DATETIME_FORMAT)
                            : "—"}
                        </Td>
                        <Td className={styles.date} textAlignRight>
                          <span className={styles.modifiedAt}>
                            {format(
                              new Date(`${featuredSegment.created.replace(/\s/g, "T")}Z`),
                              DATEFNS.DATETIME_FORMAT,
                            )}
                          </span>
                          {featuredSegment.user_id && (
                            <span className={styles.modifiedBy}>
                              by <Username userId={featuredSegment.user_id} />
                            </span>
                          )}
                        </Td>
                        <Td className={styles.actionColumn} textAlignRight>
                          <IconButton
                            color={COLOR.RED}
                            size={SIZE.TAG}
                            withBackground
                            onClick={openDeleteSegmentModal(featuredSegment)}
                            disabled={
                              usersPermission(featuredSegment.id, true) !== PERMISSION.WRITE
                            }
                            iconName="trash-alt"
                            tooltip="Delete"
                          />
                        </Td>
                      </Tr>
                    </React.Fragment>
                  )
                })}
              </Tbody>
            </Table>
            {renderWaypoint()}
          </div>
        )}
      </Paper>
      <ConfirmModal
        open={deleteModal.open}
        type={MODAL.TYPE.DELETE}
        handleClose={closeDeleteSegmentModal}
        handleConfirm={onDeleteSegmentConfirm}
        title="Delete featured segment"
        action="delete"
        what="featured segment"
        isLoading={deleteModal.deleting}
        item={deleteModal.segment?.name ?? ""}
      />
    </section>
  )
}
