import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import _noop from "lodash/noop"

// actions
import { refreshToken } from "actions/authenticatedUser.action"
import { fetchUsersAclList } from "actions/acl.action"
import { fetchExportDestinationsList } from "resources/exportDestination/exportDestinationActions"
import { fetchDataSourcesList } from "resources/dataSource/dataSourceActions"
import { verifyUser } from "actions/authenticatedUser.action"
import { fetchEventsList } from "actions/event.action"
import { fetchUsersList, fetchTrashedUserList } from "resources/user/userActions"
import { fetchTagsList } from "resources/tag/tagActions"
import { fetchGlobalSettingsList } from "resources/globalSettings/globalSettingsActions"
import { addNotification, fetchNotifications } from "resources/notification/notificationActions"

import { hasAccess } from "helpers/authenticatedUser.helper"

// ui components
import HeaderContainer from "app/components/HeaderContainer/HeaderContainer"
import FooterContainer from "app/components/FooterContainer/FooterContainer"

// auth router
import AuthenticatedRoutesSwitch from "../AuthenticatedRoutesSwitch/AuthenticatedRoutesSwitch"

import "./AuthenticatedLayout.scss"
import { socket, SocketContext } from "context/socket"
import { api } from "api"
import { version as meiroVersion } from "../../../../package.json"
import AppUpdateModal from "../AppUpdateModal/AppUpdateModal"
import NewReleaseModal from "../NewReleaseModal/NewReleaseModal"

// 5 hours
const REFRESH_TOKEN_INTERVAL = 18000000

class AuthenticatedLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reportingEnabled: null,
      updateModalOpen: false,
    }
  }

  userIsAbleToFetchDestinations = () => {
    return (
      hasAccess.data.sourcesAndDestinations() ||
      hasAccess.setup.implementation() ||
      hasAccess.segments.export()
    )
  }

  userIsAbleToFetchSources = () => {
    return (
      hasAccess.segments.insights() ||
      hasAccess.data.dashboard() ||
      hasAccess.data.insights() ||
      hasAccess.data.events() ||
      hasAccess.data.sourcesAndDestinations() ||
      hasAccess.setup.implementation() ||
      hasAccess.customers.detail()
    )
  }

  userIsAbleToFetchEvents = () => {
    return hasAccess.data.dashboard() || hasAccess.data.events() || hasAccess.customers.detail()
  }

  userIsAbleToFetchTrash = () => {
    return hasAccess.administration.users()
  }

  fetchAppData = () => {
    const {
      authenticatedUser,
      fetchUsersAclList,
      fetchExportDestinationsList,
      fetchDataSourcesList,
      fetchEventsList,
      fetchUsersList,
      fetchTagsList,
      fetchGlobalSettingsList,
      fetchTrashedUserList,
    } = this.props

    fetchUsersAclList(authenticatedUser.data.id).catch(_noop)

    fetchUsersList().catch(_noop)
    fetchTrashedUserList().catch(_noop)
    fetchGlobalSettingsList().catch(_noop)

    if (this.userIsAbleToFetchDestinations()) {
      fetchExportDestinationsList().catch(_noop)
    }

    if (this.userIsAbleToFetchSources()) {
      fetchDataSourcesList(1).catch(_noop)
    }

    if (this.userIsAbleToFetchEvents()) {
      fetchEventsList(1).catch(_noop)
    }

    fetchTagsList().catch(_noop)
  }

  refreshTokenAndData = () => {
    this.props.refreshToken().then(this.fetchAppData).catch(_noop)
  }

  showUpdateModalIfOutdated = () => {
    api.systemInfo().then(response => {
      if (meiroVersion !== response.system_info.version) {
        this.setState({
          updateModalOpen: true,
        })
      }
    })
  }

  componentDidMount() {
    const { authenticatedUser, verifyUser, refreshToken, fetchNotifications, addNotification } =
      this.props

    verifyUser(authenticatedUser.token)
      .then(() => {
        api.systemInfo().then(response => {
          this.setState({
            reportingEnabled: response.system_info.reporting_enabled,
          })
        })

        let triedToRefreshTokenForWss = false
        socket.on("connect", () => {
          triedToRefreshTokenForWss = false
        })
        socket.on("connect_error", err => {
          if (err.message === "Connection rejected by server") {
            if (!triedToRefreshTokenForWss)
              refreshToken().then(() => {
                triedToRefreshTokenForWss = true
                socket.close()
                socket.open()
              })
          }
        })
        socket.open()

        socket.on("notification", addNotification)
        fetchNotifications({ limit: 20 })

        const timestamp = Date.now()
        const diff = timestamp - authenticatedUser.loginTimestamp
        if (diff < REFRESH_TOKEN_INTERVAL) {
          this.fetchAppData()
        } else {
          this.refreshTokenAndData()
        }

        // check token every hour, check new version
        this.checkInterval = setInterval(() => {
          this.refreshTokenAndData()
          this.showUpdateModalIfOutdated()
        }, 3600000)
      })
      .catch(_noop)

    window.addEventListener("focus", this.onWindowFocus)
  }

  componentWillUnmount() {
    clearInterval(this.checkInterval)
    this.checkInterval = null
    if (socket && socket.connected) {
      socket.close()
    }
    window.removeEventListener("focus", this.onWindowFocus)
  }

  onWindowFocus = () => {
    const { verifyUser, authenticatedUser } = this.props
    verifyUser(authenticatedUser.token, false)
  }

  closeUpdateModal = () => {
    this.setState({
      updateModalOpen: false,
    })
  }

  render() {
    const { updateModalOpen, reportingEnabled } = this.state
    const { authenticatedUser } = this.props

    return (
      <div className="authenticated-layout">
        {authenticatedUser.isFetching === false &&
          authenticatedUser.data !== null &&
          reportingEnabled !== null && (
            <SocketContext.Provider value={socket}>
              <div className="content-wrap">
                <HeaderContainer reportingEnabled={reportingEnabled} />
                <AuthenticatedRoutesSwitch />
              </div>
              <FooterContainer />
              <AppUpdateModal open={updateModalOpen} onClose={this.closeUpdateModal} />
              {!updateModalOpen && <NewReleaseModal />}
            </SocketContext.Provider>
          )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authenticatedUser: state.authenticatedUser,
  }
}

AuthenticatedLayout.propTypes = {
  authenticatedUser: PropTypes.object,
  fetchTagsList: PropTypes.func.isRequired,
  fetchTrashedUserList: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {
  fetchUsersAclList,
  fetchExportDestinationsList,
  verifyUser,
  fetchDataSourcesList,
  fetchEventsList,
  fetchUsersList,
  fetchTagsList,
  fetchGlobalSettingsList,
  fetchTrashedUserList,
  refreshToken,
  fetchNotifications,
  addNotification,
})(AuthenticatedLayout)
