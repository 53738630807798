import Button from "components/UI/elements/Button/Button"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import React, { useEffect, useRef, useState } from "react"
import styles from "./Reporting.module.scss"
import classnames from "classnames"
import { useDispatch } from "react-redux"
import useTypedSelector from "hooks/useTypedSelector"
import { fetchAllReports } from "resources/report/reportActions"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import { RowMessage } from "components/UI/elements/Table"
import { useMutationObserver } from "hooks/useMutationObserver"

// define iframe height if developing on localhost because iframe is cross origin and
// you cannot set height dynamically based on content
const DEFAULT_IFRAME_HEIGHT = process.env.NODE_ENV !== "production" ? 500 : 0

export default function Reporting() {
  const [activeIndex, setActiveIndex] = useState(0)
  const dispatch = useDispatch()
  const { initialFetching, reports } = useTypedSelector(state => state.reports)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeHeight, setIframeHeight] = useState(DEFAULT_IFRAME_HEIGHT)
  const enabledReports = reports.filter(report => !report.disabled)

  useEffect(() => {
    dispatch(fetchAllReports())
  }, [dispatch])

  const updateIframeHeight = () => {
    if (DEFAULT_IFRAME_HEIGHT === 0) {
      if (iframeRef.current) {
        const embedFrames =
          iframeRef.current.contentWindow?.document.getElementsByClassName("EmbedFrame")
        if (embedFrames && embedFrames.length > 0) {
          const embedFrame = embedFrames[0]
          setIframeHeight(embedFrame.scrollHeight + 2)
        }
      }
    }
  }

  useMutationObserver({
    target:
      DEFAULT_IFRAME_HEIGHT === 0 ? iframeRef.current?.contentWindow?.document.body : undefined,
    options: {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    },
    callback: updateIframeHeight,
  })

  return (
    <div className={styles.wrapper}>
      <PaperHeader size="small" className={styles.reportingHeader}>
        {enabledReports.map((report, index) => (
          <Button
            key={report.id}
            color="white"
            size="small"
            className={classnames(styles.sectionButton, { [styles.active]: activeIndex === index })}
            onClick={() => {
              if (activeIndex !== index) {
                const previous = enabledReports[activeIndex]
                const next = enabledReports[index]
                setActiveIndex(index)
                if (previous.link !== next.link) {
                  setIframeHeight(DEFAULT_IFRAME_HEIGHT)
                }
              }
            }}
          >
            {report.name}
          </Button>
        ))}
      </PaperHeader>
      <Paper hasHeader className={styles.content}>
        {initialFetching ? (
          <LoadingIndicator />
        ) : enabledReports.length === 0 ? (
          <RowMessage>No reports found.</RowMessage>
        ) : (
          <div className={styles.iframeWrapper}>
            <LoadingIndicator className={styles.iframeLoading} />
            <iframe
              key={activeIndex}
              className={styles.embed}
              title="Report"
              src={enabledReports[activeIndex].link}
              ref={iframeRef}
              height={iframeHeight}
              onLoad={updateIframeHeight}
            ></iframe>
          </div>
        )}
      </Paper>
    </div>
  )
}
