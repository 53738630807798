import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { List } from "immutable"
import _get from "lodash/get"
import _isNil from "lodash/isNil"
import { CSSTransition } from "react-transition-group"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"

import "./CompoundAttributeValuesTable.scss"

class CompoundAttributeValuesTable extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showFade: false,
    }
    this.scrollableContentRef = React.createRef()
  }

  /* istanbul ignore next */
  componentDidMount() {
    const scrollableElement = this.scrollableContentRef.current
    if (scrollableElement.scrollWidth > scrollableElement.clientWidth) {
      this.setState({
        showFade: true,
      })
    }
    scrollableElement.addEventListener("scroll", this.handleOnScroll, false)
  }

  componentWillUnmount() {
    const scrollableElement = this.scrollableContentRef.current
    scrollableElement.removeEventListener("scroll", this.handleOnScroll, false)
  }

  /* istanbul ignore next */
  handleOnScroll = () => {
    const scrollableElement = this.scrollableContentRef.current
    const { showFade } = this.state
    if (
      scrollableElement.scrollLeft + scrollableElement.clientWidth >
      scrollableElement.scrollWidth - 10
    ) {
      if (showFade) {
        this.setState({
          showFade: false,
        })
      }
    } else {
      if (!showFade) {
        this.setState({
          showFade: true,
        })
      }
    }
  }

  isFieldEmpty = field => {
    if (_isNil(field) || field === "") {
      return true
    }
    return false
  }

  render() {
    const { subAttributes, values, page, lastPage, changePage, loading } = this.props
    const { showFade } = this.state
    return (
      <div className="compound-attr-table">
        <div className="compound-attr-table-header">
          {subAttributes.map(subAttr => (
            <div key={subAttr.id} className="sub-attr-name">
              {subAttr.name}
            </div>
          ))}
        </div>
        <div className="compound-attr-table-body">
          <div className="scrollable-area" ref={this.scrollableContentRef}>
            {values.map((fields, fieldsIndex) => (
              <div key={fieldsIndex} className="compound-attr-table-col">
                {fields.map((field, fieldIndex) => (
                  <div key={fieldIndex} className="compound-attr-table-value">
                    {this.isFieldEmpty(field) && <span data-testid="empty-value">&nbsp;</span>}
                    {!this.isFieldEmpty(field) &&
                      getUserFriendlyValueFormat(
                        field,
                        _get(subAttributes.get(fieldIndex), "data_type", "string"),
                      )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <CSSTransition in={showFade} timeout={200} classNames="fade" unmountOnExit>
            <div className="fade-overlay" />
          </CSSTransition>
        </div>
        {!_isNil(page) && (
          <div className="compound-attr-table-pagination">
            <button
              className="nav-button-left"
              disabled={page === 1}
              onClick={changePage(page - 1)}
            >
              <FontAwesomeIcon icon={["fas", "chevron-left"]} />
            </button>
            <span className={`page-number ${loading ? "loading" : ""}`}>{page}</span>
            <button
              className="nav-button-right"
              disabled={page === lastPage}
              onClick={changePage(page + 1)}
            >
              <FontAwesomeIcon icon={["fas", "chevron-right"]} />
            </button>
          </div>
        )}
      </div>
    )
  }
}

CompoundAttributeValuesTable.propTypes = {
  subAttributes: PropTypes.instanceOf(List).isRequired,
  values: PropTypes.instanceOf(List).isRequired,
  page: PropTypes.number,
  lastPage: PropTypes.number,
  changePage: PropTypes.func,
  loading: PropTypes.bool,
}

export default CompoundAttributeValuesTable
