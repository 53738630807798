import { AndOrCondition, ConditionPath, ConditionTree } from "../../types/conditionTree"
import {
  assocPath as set,
  equals,
  insert,
  last,
  move,
  remove,
  path as get,
  pipe,
  insertAll,
} from "ramda"
import { isAndOrCondition } from "./utils"

export function addCondition<T>(
  newCondition: T,
  conditionTree: ConditionTree<T> | null,
): ConditionTree<T> {
  if (!conditionTree) {
    return newCondition
  }

  if (isAndOrCondition(conditionTree)) {
    return { ...conditionTree, operands: [...conditionTree.operands, newCondition] }
  }

  return { operator: "and", operands: [conditionTree, newCondition] }
}

export function removeCondition<T>(
  path: ConditionPath,
  conditionTree: ConditionTree<T>,
): ConditionTree<T> | null {
  if (path.length === 0) {
    // The whole tree is only one leaf condition
    return null
  }

  type OperandsArray = ConditionTree<T>[]

  const index = last(path) as number
  const operandsPath = path.slice(0, -1)
  const operands = get<OperandsArray>(operandsPath, conditionTree)!
  const newOperands = remove(index, 1, operands)
  const newConditionTree = set(operandsPath, newOperands, conditionTree)

  // If 1 operand remains, merge the remaining operand up into the parent
  return operands.length === 2 ? mergeUpLoneConditions(newConditionTree) : newConditionTree
}

export function moveCondition<T>(
  fromPath: ConditionPath,
  toPath: ConditionPath,
  conditionTree: ConditionTree<T>,
): ConditionTree<T> {
  if (equals(fromPath, toPath)) {
    return conditionTree
  }

  type OperandsArray = ConditionTree<T>[]

  const movedCondition = get<T>(fromPath, conditionTree)!
  const sourceArrayPath = fromPath.slice(0, -1) // [..., "operands"]
  const sourceIndex = last(fromPath) as number
  const targetArrayPath = toPath.slice(0, -1) // [..., "operands"]
  const targetIndex = last(toPath) as number
  const targetArray = get<OperandsArray>(targetArrayPath, conditionTree)!

  if (equals(sourceArrayPath, targetArrayPath)) {
    // Moving within the same array
    const updatedArray = move(sourceIndex, targetIndex, targetArray)
    return set(targetArrayPath, updatedArray, conditionTree)
  }

  // We first insert the condition to target, then remove from source. If inserting the condition in
  // target has shifted the path of the source, we have to adjust the source path

  // That is if:
  if (
    // 1. The source array is nested under the target array
    equals(sourceArrayPath.slice(0, targetArrayPath.length), targetArrayPath) &&
    // 2. The insertion point has lower index in the target array than the branch containing the source
    targetIndex <= sourceArrayPath[targetArrayPath.length]
  ) {
    // The insertion has bumped the branch containing the source down. We have to adjust the correct
    // element in the path.
    ;(sourceArrayPath[targetArrayPath.length] as number) += 1
  }

  const updatedTargetArray = insert(targetIndex, movedCondition, targetArray)
  const conditionTreeAfterInsert = set(targetArrayPath, updatedTargetArray, conditionTree)
  // We have to grab a fresh reference to the source array because it might have changed after the insertion
  const sourceArrayAfterInsert = get<OperandsArray>(sourceArrayPath, conditionTreeAfterInsert)!
  const updatedSourceArray = remove(sourceIndex, 1, sourceArrayAfterInsert)
  const updatedConditionTree = set(sourceArrayPath, updatedSourceArray, conditionTreeAfterInsert)

  return updatedConditionTree
}

export function mergeUpLoneConditions<T>(conditionTree: ConditionTree<T>): ConditionTree<T> {
  // If after moving a condition there is an and/or condition left anywhere in the tree which has
  // only one operand, the operand must be merged up and replace the and/or condition in its parent
  if (isAndOrCondition(conditionTree)) {
    if (conditionTree.operands.length === 1) {
      // The remaining operand replaces the and/or condition
      return mergeUpLoneConditions(conditionTree.operands[0])
    }
    return { ...conditionTree, operands: conditionTree.operands.map(mergeUpLoneConditions) }
  }
  return conditionTree
}

export function toggleOperator<T>(
  path: ConditionPath,
  conditionTree: ConditionTree<T>,
): ConditionTree<T> {
  const index = last(path) as number
  const parentPath = path.slice(0, -2)
  const { operands, operator } = get<AndOrCondition<T>>(parentPath, conditionTree)!
  const newOperator = operator === "and" ? "or" : "and"

  if (operands.length === 2) {
    return set([...parentPath, "operator"], newOperator, conditionTree)
  }

  // If the size of the operands array is more than 2, we have to indent the two surrounding
  // conditions around the new operator
  const newAndOrCondition: AndOrCondition<T> = {
    operator: newOperator,
    operands: [operands[index], operands[index + 1]],
  }
  const newOperands = pipe(
    remove(index, 2),
    insert<ConditionTree<T>>(index, newAndOrCondition),
  )(operands)

  return set([...parentPath, "operands"], newOperands, conditionTree)
}

export function canIndent<T>(path: ConditionPath, conditionTree: ConditionTree<T>): boolean {
  // Let's limit the depth of the tree
  if (path.length >= 8) {
    return false
  }

  type OperandsArray = ConditionTree<T>[]

  const operandsPath = path.slice(0, -1)
  const operands = get<OperandsArray>(operandsPath, conditionTree)!

  return operands.length > 2
}

export function indent<T>(path: ConditionPath, conditionTree: ConditionTree<T>): ConditionTree<T> {
  if (!canIndent(path, conditionTree)) {
    return conditionTree
  }

  const index = last(path) as number
  const operandsPath = path.slice(0, -1)
  const parentPath = path.slice(0, -2)
  const { operands, operator } = get<AndOrCondition<T>>(parentPath, conditionTree)!

  const firstOperand = operands[index]
  const isFirstOperandAndOr = isAndOrCondition(firstOperand)
  const doesFirstOperatorMatchParent =
    isAndOrCondition(firstOperand) && firstOperand.operator === operator

  const secondOperand = operands[index + 1]
  const isSecondOperandAndOr = isAndOrCondition(secondOperand)
  const doesSecondOperatorMatchParent =
    isAndOrCondition(secondOperand) && secondOperand.operator === operator

  if (
    // Neither is an array
    (!isFirstOperandAndOr && !isSecondOperandAndOr) ||
    // First one is an array with a different operator than the parent: can't merge
    (isFirstOperandAndOr && !isSecondOperandAndOr && !doesFirstOperatorMatchParent) ||
    // Second one is an array with a different operator than the parent: can't merge
    (!isFirstOperandAndOr && isSecondOperandAndOr && !doesSecondOperatorMatchParent) ||
    // Both are arrays with a different operator than the parent: can't merge
    (isFirstOperandAndOr &&
      isSecondOperandAndOr &&
      !doesFirstOperatorMatchParent &&
      !doesSecondOperatorMatchParent)
  ) {
    // Simply indent the 2 elements surrounding the toggle
    const newAndOrCondition: AndOrCondition<T> = {
      operator,
      operands: [operands[index], operands[index + 1]],
    }
    const newOperands = pipe(
      remove(index, 2),
      insert<ConditionTree<T>>(index, newAndOrCondition),
    )(operands)

    return set(operandsPath, newOperands, conditionTree)
  }

  if (
    // Both are arrays with the same operator as the parent
    isFirstOperandAndOr &&
    isSecondOperandAndOr &&
    doesFirstOperatorMatchParent &&
    doesSecondOperatorMatchParent
  ) {
    // Merge the two arrays
    const arrayAbove = operands[index] as AndOrCondition<T>
    const arrayBelow = operands[index + 1] as AndOrCondition<T>
    const mergedOperands = [...arrayAbove.operands, ...arrayBelow.operands]

    const newOperands = pipe(
      remove(index, 2),
      insert<ConditionTree<T>>(index, { operator, operands: mergedOperands }),
    )(operands)

    return set(operandsPath, newOperands, conditionTree)
  }

  // One of them must be an array with the same operator as the parent (A) and the other one
  // must be something else (B); we will merge B into A
  if (isFirstOperandAndOr && doesFirstOperatorMatchParent) {
    // It's the first one
    const mergedOperands = [...(firstOperand as AndOrCondition<T>).operands, secondOperand]

    const newOperands = pipe(
      set([index, "operands"], mergedOperands),
      remove<ConditionTree<T>>(index + 1, 1),
    )(operands)

    return set(operandsPath, newOperands, conditionTree)
  }

  // It's the second one
  const mergedOperands = [firstOperand, ...(secondOperand as AndOrCondition<T>).operands]

  const newOperands = pipe(
    set([index + 1, "operands"], mergedOperands),
    remove<ConditionTree<T>>(index, 1),
  )(operands)

  return set(operandsPath, newOperands, conditionTree)
}

export function canUnindent(path: ConditionPath): boolean {
  return path.length >= 4
}

export function unindent<T>(
  path: ConditionPath,
  conditionTree: ConditionTree<T>,
): ConditionTree<T> {
  if (!canUnindent(path)) {
    return conditionTree
  }

  type OperandsArray = ConditionTree<T>[]

  const toggleIndex = last(path) as number
  const selfPath = path.slice(0, -2)
  const parentOperandsPath = path.slice(0, -3)
  const selfIndex = last(selfPath) as number

  const { operands, operator } = get<AndOrCondition<T>>(selfPath, conditionTree)!
  const parentOperands = get<OperandsArray>(parentOperandsPath, conditionTree)!

  // Split the array into two arrays, above and below the toggle, and replace the array in the
  // parent with them; if one or both of those is a leaf condition, use it directly instead of
  // creating a new array for it
  const newFirstOperand =
    toggleIndex === 0 ? operands[0] : { operator, operands: operands.slice(0, toggleIndex + 1) }

  const newSecondOperand =
    toggleIndex === operands.length - 2
      ? operands[operands.length - 1]
      : { operator, operands: operands.slice(toggleIndex + 1) }

  const newParentOperands = pipe(
    remove(selfIndex, 1),
    insertAll<ConditionTree<T>>(selfIndex, [newFirstOperand, newSecondOperand]),
  )(parentOperands)

  return set(parentOperandsPath, newParentOperands, conditionTree)
}
