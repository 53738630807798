import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const USER = {
  ACTION: {
    LOGGING_IN: "USER_LOGGING_IN",
    LOGGED_IN: "USER_LOGGED_IN",
    LOGGED_OUT: "USER_LOGGED_OUT",
    RETRIEVE_LOGGED_IN_USER: "RETRIEVE_LOGGED_IN_USER",
    MODIFY_AUTH_USER: "MODIFY_AUTH_USER",
    LIST: "USER_LIST",
    CREATE: "USER_CREATE",
    DELETE: "USER_DELETE",
    RESTORE: "USER_RESTORE",
    MODIFY: "USER_MODIFY",
    REFRESH_TOKEN: "REFRESH_USER_TOKEN",
    SET_UI_ATTRIBUTE: "SET_UI_ATTRIBUTE",
    SET_LATEST_SEARCH: "SET_LATEST_SEARCH",
  },
  TRASH: {
    ACTION: {
      LIST: "LIST_TRASHED_USERS",
    },
  },
}

export const fetchUsersList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: USER.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit) => api.user.list(offset, limit))
          .setDataPath("users")
          .run()
        return data
      })(),
    },
  })
}

export const fetchTrashedUserList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: USER.TRASH.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit) => api.user.listDeleted({ offset, limit }))
          .setDataPath("trashed_users")
          .run()
        return data
      })(),
    },
  })
}

export const createUser = (userData, segmentsAcl) => async dispatch => {
  dispatch({
    type: USER.ACTION.CREATE,
    payload: {
      promise: (async () => {
        const user = await api.user.create(userData)
        if (Object.keys(segmentsAcl).length) {
          await api.acl.user.create(user.id, segmentsAcl)
        }
        return user
      })(),
    },
  })
}

export const inviteUser = (userData, segmentsAcl) => async dispatch => {
  dispatch({
    type: USER.ACTION.CREATE,
    payload: {
      promise: (async () => {
        const user = await api.user.sendInvitation(userData)
        if (Object.keys(segmentsAcl).length) {
          await api.acl.user.create(user.id, segmentsAcl)
        }
        return user
      })(),
    },
  })
}

export const modifyUser = (id, data) => async dispatch =>
  dispatch({
    type: USER.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        return await api.user.modify(id, data)
      })(),
    },
  })

export const deleteUser = id => async dispatch =>
  dispatch({
    type: USER.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api.user.delete(id)
        return id
      })(),
    },
  })

export const restoreUser = user => dispatch =>
  dispatch({
    type: USER.ACTION.RESTORE,
    payload: user,
  })
