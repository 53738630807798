import requestFactory from "api/request"
import { OrderDir } from "types/util"
import {
  PromoCodesList,
  PromoCodesListRequestPayload,
  PromoCodesListResponse,
} from "./promoCodesListTypes"

const promoCodesList = {
  list(
    offset = 0,
    limit = 50,
    order_by: keyof PromoCodesList = "name",
    order_dir: OrderDir = "ASC",
    name_search = "",
  ): Promise<PromoCodesListResponse> {
    return requestFactory("get", "/promo_codes_lists", {
      offset,
      limit,
      order_by,
      order_dir,
      name_search,
    })
  },
  retrieve(id: PromoCodesList["id"]): Promise<{ promo_codes_list: PromoCodesList }> {
    return requestFactory("get", `/promo_codes_lists/${id}`)
  },
  create(data: PromoCodesListRequestPayload): Promise<{ promo_codes_list: PromoCodesList }> {
    return requestFactory("post", "/promo_codes_lists", data)
  },
  modify(
    id: PromoCodesList["id"],
    data: PromoCodesListRequestPayload,
  ): Promise<{ promo_codes_list: PromoCodesList }> {
    return requestFactory("patch", `/promo_codes_lists/${id}`, data)
  },
  delete(id: PromoCodesList["id"]): Promise<void> {
    return requestFactory("delete", `/promo_codes_lists/${id}`)
  },
  uploadFile(
    id: PromoCodesList["id"],
    formData: FormData,
  ): Promise<{ promo_codes_list: PromoCodesList }> {
    return requestFactory(
      "post",
      `/promo_codes_lists/${id}`,
      formData,
      false,
      true,
      "",
      false,
      false,
      { "Content-Type": "multipart/form-data" },
    )
  },
}

export default promoCodesList
