import { Record } from "immutable"

const Note = Record({
  id: 0,
  segment_id: null,
  name: "",
  content: "",
  // frontend attribute only
  excerpt: "",
  modified: "",
  user_id: null,
})

export default Note
