import _toString from "lodash/toString"
import _get from "lodash/get"
import _startsWith from "lodash/startsWith"

import store from "store"
import { PERMISSION } from "sharedConstants"

const { getState } = store

export const token = () => getState().authenticatedUser.token

export const isFetching = () => getState().authenticatedUser.isFetching

const getUserFeatures = () => {
  return _get(getState().authenticatedUser, "data.role.features", [])
}

const includesAll = (arr, conditionsArray) => conditionsArray.every(item => arr.includes(item))

export const hasAccess = {
  administration: {
    general() {
      return getUserFeatures().some(item => ["settings/users", "settings/global"].includes(item))
    },
    users() {
      return getUserFeatures().includes("settings/users")
    },
    globalSettings() {
      return getUserFeatures().includes("settings/global")
    },
    usersActivity() {
      return getUserFeatures().includes("data/user_activity")
    },
  },
  customers: {
    search() {
      return getUserFeatures().includes("customers/search")
    },
    detail() {
      return getUserFeatures().includes("customers/detail")
    },
    all() {
      return includesAll(getUserFeatures(), ["customers/search", "customers/detail"])
    },
  },
  segments: {
    create() {
      return getUserFeatures().includes("segments/create")
    },
    export() {
      let access = false
      const features = getUserFeatures()
      if (features.includes("segments/export")) {
        access = true
      } else {
        features.forEach(feature => {
          if (_startsWith(feature, "segments/export/")) {
            access = true
          }
        })
      }
      return access
    },
    insights() {
      return getUserFeatures().includes("segments/insights")
    },
    listForeign() {
      return getUserFeatures().includes("foreign_segments/list")
    },
    editForeign() {
      return getUserFeatures().includes("foreign_segments/edit")
    },
    viewForeign() {
      return getUserFeatures().includes("foreign_segments/view")
    },
    all() {
      return includesAll(getUserFeatures(), [
        "segments/create",
        "segments/export",
        "segments/insights",
      ])
    },
    viewTags() {
      return getUserFeatures().includes("data/tags")
    },
    editTags() {
      return getUserFeatures().includes("settings/tags")
    },
    featured: {
      general() {
        return getUserFeatures().some(feature =>
          ["featured_segments/list", "featured_segments/edit", "featured_segments/view"].includes(
            feature,
          ),
        )
      },
      list() {
        return getUserFeatures().includes("featured_segments/list")
      },
      view() {
        return getUserFeatures().includes("featured_segments/view")
      },
      edit() {
        return getUserFeatures().includes("featured_segments/edit")
      },
    },
  },
  data: {
    general() {
      return getUserFeatures().some(item =>
        [
          "data/dashboard",
          "data/insights",
          "data/attributes",
          "data/events",
          "data/sources_and_destinations",
        ].includes(item),
      )
    },
    dashboard() {
      return getUserFeatures().includes("data/dashboard")
    },
    insights() {
      return getUserFeatures().includes("data/insights")
    },
    attributes() {
      return getUserFeatures().includes("data/attributes")
    },
    events() {
      return getUserFeatures().includes("data/events")
    },
    sourcesAndDestinations() {
      return getUserFeatures().includes("data/sources_and_destinations")
    },
    licenceUsage() {
      return getUserFeatures().includes("data/licence_usage")
    },
    all() {
      return includesAll(getUserFeatures(), [
        "data/dashboard",
        "data/insights",
        "data/attributes",
        "data/events",
        "data/sources_and_destinations",
        "data/licence_usage",
      ])
    },
  },
  setup: {
    general() {
      return getUserFeatures().some(item =>
        ["settings/insights", "settings/cdp_entities", "prebuilt_segments/edit"].includes(item),
      )
    },
    insights() {
      return getUserFeatures().includes("settings/insights")
    },
    implementation() {
      return getUserFeatures().includes("settings/cdp_entities")
    },
    smartSegments() {
      return getUserFeatures().includes("prebuilt_segments/edit")
    },
    reports() {
      return getUserFeatures().includes("reports/edit")
    },
  },
  logs() {
    return getUserFeatures().includes("redirects/logs")
  },
  webBanners: {
    view() {
      return getUserFeatures().includes("web_banners/view")
    },
    edit() {
      return getUserFeatures().includes("web_banners/edit")
    },
  },
  reports() {
    return getUserFeatures().includes("reports/view")
  },
}

export const getAllowedExportDestinationsIds = () => {
  const features = getUserFeatures()
  if (features.includes("segments/export")) {
    // allowed all
    return []
  }
  const destinationIds = []
  features.forEach(feature => {
    if (_startsWith(feature, "segments/export/")) {
      const parts = feature.split("/")
      destinationIds.push(parts[parts.length - 1])
    }
  })
  return destinationIds
}

export const isLoggedIn = () => token() !== ""

// returns false, if user has no permission, otherwise returns permission
export const usersPermission = (segmentId, featuredSegments = false) => {
  const loggedUser = getState().authenticatedUser.data
  if (!loggedUser) {
    return false
  }
  const segmentsPermission = getState().usersAcls.get(_toString(segmentId))
  if (featuredSegments) {
    if (hasAccess.segments.featured.edit()) {
      return PERMISSION.WRITE
    } else if (segmentsPermission) {
      return segmentsPermission.permission
    } else if (hasAccess.segments.featured.view()) {
      return PERMISSION.READ
    }
  } else {
    if (hasAccess.segments.editForeign()) {
      return PERMISSION.WRITE
    } else if (segmentsPermission) {
      return segmentsPermission.permission
    } else if (hasAccess.segments.viewForeign()) {
      return PERMISSION.READ
    }
  }

  return false
}
