import { USER } from "./userActions"
import { Map, fromJS, Record } from "immutable"
import _map from "lodash/map"
import _mapKeys from "lodash/mapKeys"
import _toString from "lodash/toString"

/*
  null if not fetched yet, otherwise
  Map({
    "userId": userObject
  })
*/

const UserModel = Record({
  id: 0,
  name: "",
  email: "",
  role: "",
  disabled: 0,
  deleted: 0,
  password_reset_token: null,
  created: "",
  last_login: "",
  okta_enabled: 0,
  automated: false,
})

const initialState = Map({
  active: null,
  removed: null,
})

export default function userUpdate(state = initialState, { type, payload }) {
  switch (type) {
    case `${USER.ACTION.LIST}_FULFILLED`:
      return state.set(
        "active",
        Map(
          _mapKeys(
            _map(payload, user => new UserModel(user)),
            "id",
          ),
        ),
      )

    case `${USER.TRASH.ACTION.LIST}_FULFILLED`:
      return state.set(
        "removed",
        Map(
          _mapKeys(
            _map(payload, user => new UserModel(user)),
            "id",
          ),
        ),
      )

    case `${USER.ACTION.RETRIEVE}_FULFILLED`:
    case `${USER.ACTION.CREATE}_FULFILLED`:
    case `${USER.ACTION.MODIFY}_FULFILLED`:
      return state.set(
        "active",
        state.get("active").merge(fromJS({ [payload.id]: new UserModel(payload) })),
      )
    case USER.ACTION.RESTORE: {
      return state
        .set("active", state.get("active").merge(fromJS({ [payload.id]: new UserModel(payload) })))
        .deleteIn(["removed", _toString(payload.id)])
    }
    case `${USER.ACTION.DELETE}_FULFILLED`: {
      // there's id in payload
      const user = state.getIn(["active", _toString(payload)]).set("deleted", 1)
      if (Map.isMap(state.get("removed"))) {
        return state
          .deleteIn(["active", _toString(payload)])
          .set("removed", state.get("removed").merge(Map({ [user.id]: user })))
      } else {
        return state
          .deleteIn(["active", _toString(payload)])
          .set("removed", Map({ [user.id]: user }))
      }
    }
    default:
      return state
  }
}
