import _get from "lodash/get"
import { api } from "api"
import { LABEL } from "sharedConstants"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchLabelsList = () => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: LABEL.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, load_full_structure) =>
            api.label.list(offset, limit, "name", "ASC", load_full_structure),
          )
          .setLoadFullStructure(0)
          .setDataPath("tags")
          .run()
        return data
      })(),
    },
  })
}

export const createLabel = data => async dispatch =>
  dispatch({
    type: LABEL.ACTION.CREATE,
    payload: {
      promise: (async () => {
        const response = await api.label.create(data)
        return _get(response, "tag", {})
      })(),
    },
  })

export const modifyLabel = (labelId, data) => async dispatch =>
  dispatch({
    type: LABEL.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api.label.modify(labelId, data)
        return _get(response, "tag", {})
      })(),
    },
  })

export const deleteLabel = labelId => async dispatch =>
  dispatch({
    type: LABEL.ACTION.DELETE,
    payload: {
      promise: (async () => {
        await api.label.delete(labelId)
        return labelId
      })(),
    },
  })
