import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { getSegmentIconSrc } from "resources/segment/segment/utilities/smartSegmentUtils"

import "./SmartSegmentTile.scss"

const SmartSegmentTile = ({ name, icon, color, link, className, description = "" }) => {
  const tileContent = (
    <React.Fragment>
      <img src={getSegmentIconSrc(icon)} alt="" />
      <h4>{name}</h4>
      {description.length !== 0 && <p className="short-desc">{description}</p>}
    </React.Fragment>
  )

  if (link) {
    return (
      <Link
        className={`smart-segment-tile link ${className ? className : ""} ${color ? color : ""}`}
        to={link}
      >
        {tileContent}
      </Link>
    )
  } else {
    return (
      <div className={`smart-segment-tile ${className ? className : ""} ${color ? color : ""}`}>
        {tileContent}
      </div>
    )
  }
}

SmartSegmentTile.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  link: PropTypes.string,
  color: PropTypes.string,
}

export default SmartSegmentTile
