import { CUSTOMER_ITERATOR } from "sharedConstants"
import _toString from "lodash/toString"

export const setCustomersIterator =
  (index, segmentId = null) =>
  dispatch =>
    dispatch({
      type: CUSTOMER_ITERATOR.ACTION.SET,
      meta: {
        segmentId: _toString(segmentId),
      },
      payload: {
        index,
        segmentId,
      },
    })

export const resetCustomersIterator = () => dispatch =>
  dispatch({
    type: CUSTOMER_ITERATOR.ACTION.RESET,
  })

export const setCustomersIteratorIndex = index => dispatch =>
  dispatch({
    type: CUSTOMER_ITERATOR.ACTION.SET_INDEX,
    payload: {
      index,
    },
  })
