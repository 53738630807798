import React, { Component, useState } from "react"
import { connect } from "react-redux"
import { getFormValues } from "redux-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { List } from "immutable"
import moment from "moment"

// ui components
import AttributeId from "components/UI/components/AttributeId/AttributeId"
import Badge from "components/UI/elements/AttributeBadge"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Tag from "components/UI/elements/Tag"
import TagPicker from "components/UI/components/TagPicker"
import CompoundAttributeValuesTable from "components/UI/elements/CompoundAttributeValuesTable"
import AttributesFilterForm from "components/UI/components/AttributesFilterForm"
import Table, {
  Thead,
  Th,
  Tbody,
  Td,
  Tr,
  SortButton,
  RowMessage,
} from "components/UI/elements/Table"
import InfoTooltip from "components/UI/elements/InfoTooltip"

// actions
import { fetchCustomerAttributesCountsList } from "actions/customerAttribute.action"

import { getCustomerAttributesCounts } from "selectors/customerAttributeCount.selector"

// helpers
import { getIconSrc } from "helpers/image.helper"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "resources/attribute/compoundAttributeUtils"

import "./Attributes.scss"
import Tippy from "@tippyjs/react"
import { useFetchActiveLabels, useFetchAllAttributes } from "resources/attribute/attributeQueries"
import { without } from "ramda"
import { useSelector } from "store"

class Attributes extends Component {
  componentDidMount() {
    this.props.fetchCustomerAttributesCountsList()
  }

  renderAttributesRow = attribute => {
    const { customerAttributesCountsList, filters } = this.props
    let tags = null,
      attribute_count = null

    if (attribute.tags) {
      tags = attribute.tags.map(tag => {
        if (tag) {
          return (
            <Tag
              key={tag.id}
              color="primary"
              onClick={() => filters.addLabelId(tag.id)}
              className="attribute-label"
            >
              {tag.name}
            </Tag>
          )
        } else {
          return null
        }
      })
    }

    const customerAttributeCount = customerAttributesCountsList.find(
      ca => ca.attribute_id === attribute.id,
    )
    if (customerAttributeCount) {
      attribute_count = customerAttributeCount.count
    }

    let attributeExamples = null
    if (
      isAttributeCompound(attribute.data_type) &&
      attribute.examples &&
      !Array.isArray(attribute.examples)
    ) {
      const attrExamples = attribute.examples
      const subAttributes = List(getCompoundAttributeSubAttributes(attribute.data_type))
      let maxSize = 0
      const examples = subAttributes.map(subAttribute => {
        if (Array.isArray(attrExamples[subAttribute.id])) {
          if (maxSize < attrExamples[subAttribute.id].length) {
            maxSize = attrExamples[subAttribute.id].length
          }
          return attrExamples[subAttribute.id]
        }
        return []
      })
      if (maxSize > 3) {
        maxSize = 3
      }
      const values = []
      for (let i = 0; i < maxSize; i++) {
        values.push([])
        examples.forEach(examplesList => {
          values[i].push(examplesList[i])
        })
      }
      if (values.some(arr => arr.length > 0)) {
        attributeExamples = (
          <CompoundAttributeValuesTable subAttributes={subAttributes} values={List(values)} />
        )
      }
    } else {
      attributeExamples = attribute.examples?.slice(0, 3).map((value, key) => (
        <Tag
          key={key}
          color="white"
          size="small"
          clickable={false}
          className="attribute-example-value"
          maxCharacters={30}
          uniqueTooltipId={`tooltip-${attribute.id}_${key}`}
        >
          {getUserFriendlyValueFormat(value, attribute.data_type)}
        </Tag>
      ))
    }

    return (
      <Tr key={attribute.id}>
        <Td className="attribute-name" textBigger textBlack textBold>
          <span>{attribute.name}</span>
          {moment().diff(attribute.created, "days") < 8 && <Badge text="New" />}
          {isAttributeCompound(attribute.data_type) ? (
            <ul className="sub-attrs">
              {getCompoundAttributeSubAttributes(attribute.data_type).map(subAttr => (
                <li key={subAttr.id}>{subAttr.name}</li>
              ))}
            </ul>
          ) : (
            <br />
          )}
          <AttributeId id={attribute.id} />
        </Td>
        <Td className="attribute-description">{attribute.description}</Td>
        <Td className="attribute-source">
          <div className="attribute-source-flex-wrapper">
            <div>
              <img
                src={getIconSrc(
                  {
                    primary: attribute.source.frontend_settings?.icon,
                    secondary: attribute.source.type?.toLowerCase(),
                  },
                  attribute.source.frontend_settings?.alt_icon,
                )}
                alt="icon"
              />
            </div>
            <div className="source-n-count">
              <div>{attribute.source.name}</div>
              <div className="count">
                {attribute_count !== null ? `${attribute_count} filled` : ""}
              </div>
            </div>
          </div>
        </Td>
        <Td className="attribute-tags">{tags}</Td>
        <Td className="attribute-examples">
          <div className="examples-wrapper">{attributeExamples}</div>
        </Td>
      </Tr>
    )
  }

  render() {
    const { attributesTagsList, areAttributesFulfilled, filters, attributesList } = this.props

    return (
      <React.Fragment>
        <div className="attributes-container wrapper">
          <PaperHeader size="small">
            <h3 className="title">
              Attributes
              <Tippy
                interactive
                content={
                  <>
                    <p>
                      Attributes are <strong>characteristics of a customer</strong> that help to
                      build a more holistic picture of a customer (e.g. information about personal
                      data, revenue spent, opened campaigns etc).
                    </p>
                    <p>
                      Attributes are extracted from events.{" "}
                      <strong>When a customer action happens</strong> (e.g. customer visits a web
                      page, makes a payment transaction, etc.){" "}
                      <strong>
                        it is recorded as an event. Meiro is able to extract data from the events
                        and based on it create attributes
                      </strong>{" "}
                      like "Date of the last visit to the web" or "Total revenue from all
                      transactions".
                    </p>
                    <p>
                      To learn more about Attributes tab, please refer to{" "}
                      <a
                        href="https://docs.meiro.io/books/meiro-business-explorer/page/tab-data"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        this article
                      </a>
                      .
                    </p>
                  </>
                }
              >
                <span className="info-tooltip-icon">
                  <FontAwesomeIcon icon={["fas", "info-circle"]} />
                </span>
              </Tippy>
            </h3>
            <AttributesFilterForm />
          </PaperHeader>
          <Paper hasHeader>
            <div className="tag-filter">
              <div className="label-tags">
                <span className="selected-tags">Filter by:</span>
                <span>
                  {filters.labelIds.map(tagId => {
                    const tag = attributesTagsList.find(val => val.id === tagId)
                    if (tag) {
                      return (
                        <Tag
                          key={tagId}
                          clickable={true}
                          color="primary"
                          onClick={() => filters.removeLabelId(tagId)}
                        >
                          {tag.name}
                        </Tag>
                      )
                    }
                    return null
                  })}
                </span>
              </div>
              <TagPicker
                selectedTagIds={List(filters.labelIds)}
                allTags={List(attributesTagsList)}
                onTagSelect={filters.addLabelId}
                className="selected-tags-picker"
                type="label"
              />
            </div>
            {attributesList?.length > 0 && (
              <Table className="attributes-table">
                <Thead stickyHeader>
                  <Th className="name">
                    <SortButton
                      column="name"
                      orderBy={filters.orderBy}
                      orderDir={filters.orderDir}
                      onClick={() => filters.setSort("name")}
                      label="Name"
                    />
                  </Th>
                  <Th className="description-col">Description</Th>
                  <Th className="source-col">
                    <SortButton
                      column="source"
                      orderBy={filters.orderBy}
                      orderDir={filters.orderDir}
                      onClick={() => filters.setSort("source")}
                      label="Data Source"
                    />
                  </Th>
                  <Th className="labels-col">
                    <SortButton
                      column="labels"
                      orderBy={filters.orderBy}
                      orderDir={filters.orderDir}
                      onClick={() => filters.setSort("labels")}
                      label="Labels"
                    />
                    <InfoTooltip className="labels-info-tooltip">
                      Labels help to manage attributes. Administrators can assign attributes with a
                      certain label to a particular feature.
                    </InfoTooltip>
                  </Th>
                  <Th className="examples-col">Examples</Th>
                </Thead>
                <Tbody>{attributesList.map(this.renderAttributesRow)}</Tbody>
              </Table>
            )}
            {attributesList?.length === 0 && areAttributesFulfilled && (
              <RowMessage className="no-attributes-found">Nothing found.</RowMessage>
            )}
          </Paper>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    customerAttributesCountsList: getCustomerAttributesCounts(state),
  }
}

Attributes = connect(mapStateToProps, { fetchCustomerAttributesCountsList })(Attributes)

export default props => {
  const formValues = useSelector(getFormValues("SearchAttributeForm"))

  const [{ orderBy, orderDir }, setSortState] = useState({ orderBy: "name", orderDir: "ASC" })
  const [labelIds, setLabelIds] = useState([])

  const setSort = orderBy =>
    setSortState(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    }))
  const addLabelId = labelId =>
    setLabelIds(labelIds => (labelIds.includes(labelId) ? labelIds : labelIds.concat(labelId)))
  const removeLabelId = labelId => setLabelIds(without([labelId]))

  const { data: attributesList, isSuccess } = useFetchAllAttributes(
    {
      orderBy,
      orderDir,
      searchTerm: formValues?.search,
      labelIds,
      sourceId: formValues?.select?.value,
    },
    { refetchOnMount: "always" },
  )

  const { data: attributesTagsList } = useFetchActiveLabels()

  return (
    <Attributes
      {...props}
      attributesList={attributesList}
      attributesTagsList={attributesTagsList}
      filters={{ orderBy, orderDir, labelIds, setSort, addLabelId, removeLabelId }}
      areAttributesFulfilled={isSuccess}
    />
  )
}
