export const getCustomerSearchesData = state => state.customerSearches.get("data")
export const getCustomerSearchesSelectionSettings = state =>
  state.customerSearches.get("selectionSettings")
export const hasCustomerSearchesMoreResults = state => state.customerSearches.get("hasMoreItems")
export const getCustomerSearchesSelectedAttributeId = state =>
  state.customerSearches.get("selectedAttributeId")
export const getCustomerSearchesResultsCount = state => state.customerSearches.get("results")
export const getCustomerSearchesAdditionalAttribute = state =>
  state.customerSearches.get("additionalAttribute")
export const getCustomerSearchesFavoritesFlag = state =>
  state.customerSearches.get("isShowingFavorites")
export const getCustomerSearchesLoadingFlag = state => state.customerSearches.get("isLoading")
