import { Template } from "components/UI/components/TemplateGallery/TemplateGalleryItem/TemplateGalleryItem"
import imgSrc from "./abandonedBasket.jpg"

const abandonedBasket: Template = {
  id: "abandonedBasket",
  name: "Abandoned basket",
  description:
    "Remind the customer of their purchase intention and guide them to finish the checkout process.",
  imgSrc,
  width: 400,
  highlight: {
    startRow: 80,
    endRow: 87,
  },
  docLink:
    "https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-use-cases-library-html-for-abandoned-basket",
  html: `<!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        body {
          margin: 0;
          font-family: sans-serif;
          color: #222222;
        }

        .banner {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          cursor: pointer;
          border-radius: 8px;
          padding: 15px;
          border: 1px solid #777777;
          background-color: white;
        }

        .text-container {
          align-self: stretch;
        }

        .text-container h1 {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
        }

        .text-container p {
          margin-top: 5px;
        }

        #product-thumbnail {
          width: 100px;
          margin: 10px;
          border-radius: 4px;
        }

        #product-name {
          font-size: 12px;
          font-weight: 500;
        }

        .cta-button {
          height: 40px;
          border-radius: 20px;
          cursor: pointer;
          border: none;
          margin-top: 10px;
          padding: 0 20px;
          background-color: #fe7f66;
          color: white;
          font-size: 14px;
          font-weight: 600;
          transition: all 0.15s ease;
        }

        .cta-button:hover {
          background-color: #eb6c52;
        }
      </style>
    </head>
    <body>
      <div class="banner" onclick="goToCheckout()">
        <div class="text-container">
          <h1>Your items are waiting</h1>
          <p>Don’t miss out, complete checkout now!</p>
        </div>
        <img id="product-thumbnail" src="" />
        <div id="product-name"></div>
        <button class="cta-button">Go to checkout</button>
      </div>
      <script>
        var checkoutURL = "https://www.example.com"
        var getProductFromResponse = function (response) {
          const product = response.data.products[0]
          return {
            imageUrl: product.imageUrl,
            name: product.name,
          }
        }

        function goToCheckout() {
          if (window.parent && window.parent.MeiroEvents) {
            window.parent.MeiroEvents.goToWebBannerUrl(checkoutURL)
          }
        }

        if (window.parent && window.parent.MeiroEvents) {
          var bannerID = window.parent.MeiroEvents.getWebBannerId()
          var responses = window.parent.MeiroEvents.getWebBannerHttpResponses(bannerID)
          var product = getProductFromResponse(responses[0])

          document.getElementById("product-name").textContent = product.name
          document.getElementById("product-thumbnail").setAttribute("src", product.imageUrl)
        }
      </script>
    </body>
  </html>
  `,
}

export default abandonedBasket
