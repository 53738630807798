import { sum } from "lodash"
import { SubcategoryData } from "types/usersActivity"

// Add an element to the front of the array of subcategories which sums up the data across the
// subcategories
export const addCategoryTotal = (subcategoryData: SubcategoryData[]): SubcategoryData[] =>
  // Don't add total if there is only one subcategory
  subcategoryData.length <= 1
    ? subcategoryData
    : [
        {
          // Extract the category name from the first subcategory and add '/total'
          id: subcategoryData[0].id.split("/")[0] + "/total",

          total_interactions: sum(
            subcategoryData.map(({ total_interactions }) => total_interactions),
          ),

          // The first subcategory will have the same set of datasets as every other; use it to build the
          // array of datasets for the total "subcategory". Each dataset is one user/role/domain.
          datasets: subcategoryData[0].datasets.map(({ id, data }) => ({
            // The user/role/domain (`null` if it's data for all users)
            id,
            // Each element in `data` is one day/week/month for the given user/role/domain
            data: data.map((_, index) =>
              // Sum across subcategories
              sum(
                // From each subcategory, find the user/role/domain's dataset, and grab the datapoint for
                // the given day/week/month's index
                subcategoryData.map(
                  ({ datasets }) => datasets.find(dataset => dataset.id === id)?.data[index] ?? 0,
                ),
              ),
            ),
          })),
        },
        ...subcategoryData,
      ]
