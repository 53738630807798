import React, { PureComponent } from "react"
import Modal from "components/UI/elements/Modal"
import PropTypes from "prop-types"
import {
  reduxForm,
  Form,
  FieldArray,
  Field,
  formValueSelector,
  getFormSyncErrors,
} from "redux-form"
import "./SchedulerForm.scss"
import { connect } from "react-redux"
import Button from "components/UI/elements/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  DAY_MAPPINGS,
  SCHEDULE_TYPE,
  REPEAT_OPTIONS,
} from "resources/segment/segment/utilities/segmentSchedulesUtils"
import ToggleSwitchField from "components/UI/elements/ToggleSwitch/ToggleSwitchField"
import TextField from "components/UI/elements/TextField"
import SelectField from "components/UI/elements/SelectField"
import _isEmpty from "lodash/isEmpty"
import _toInteger from "lodash/toInteger"
import _map from "lodash/map"
import deepEqual from "deep-equal"
import moment from "moment"
import Tippy from "@tippyjs/react"

const MAX_ALLOWED_SCHEDULES = 8

const DayCheckbox = ({ input, id, label, meta: { touched, error } }) => {
  return (
    <div className={touched && error ? "error" : ""}>
      <input
        {...input}
        checked={input.value}
        type="checkbox"
        id={id}
        className={touched && error ? "error" : ""}
      />
      <label htmlFor={id}>{label}</label>
      {touched && error && <p className="error-message">{error}</p>}
    </div>
  )
}

class SchedulerForm extends PureComponent {
  selectSchedule = index => () => {
    this.props.change("visibleSchedule", index)
  }

  renderSchedules = ({ fields, visibleSchedule, schedules, submitErrors }) => {
    const { anyTouched, invalid, isLoading, reset, onSubmit, initialValues } = this.props
    const { schedules: initialSchedules } = initialValues
    let wasFormChanged = false
    if (initialSchedules && schedules && initialSchedules.length !== schedules.length) {
      wasFormChanged = true
    } else {
      initialSchedules.forEach((schedule, index) => {
        if (!deepEqual(schedule, schedules[index])) wasFormChanged = true
      })
    }

    const removeField = index => evt => {
      evt.stopPropagation()
      const fieldsCount = fields.length
      if (fieldsCount > 1) {
        fields.remove(index)
        if (visibleSchedule === fieldsCount - 1) {
          this.selectSchedule(visibleSchedule - 1)()
        }
      }
    }

    const addField = () => {
      const fieldsCount = fields.length
      if (fieldsCount < MAX_ALLOWED_SCHEDULES) {
        fields.push({
          days: {},
          type: SCHEDULE_TYPE.ONCE,
          hour: "",
          minute: "",
        })
        this.selectSchedule(fieldsCount)()
      }
    }

    const getDaysTextList = days => {
      return _map(days, (val, key) => {
        if (val) return DAY_MAPPINGS[key]
        return null
      })
        .filter(v => v !== null)
        .join(", ")
    }

    const tabs = fields.map((_, index) => {
      let error = false
      if (anyTouched && invalid) {
        if (!_isEmpty(submitErrors.schedules[index])) {
          error = true
        }
      }
      return (
        <Button
          key={`schedule-${index}`}
          color={`white ${index === visibleSchedule ? "turned-on turned-on-primary" : ""}`}
          size="medium"
          onClick={this.selectSchedule(index)}
          className={error ? "error-schedule" : ""}
        >
          Schedule {index + 1}{" "}
          <span className="remove-schedule-button" onClick={removeField(index)}>
            <FontAwesomeIcon icon={["far", "times"]} />
          </span>
        </Button>
      )
    })

    const scheduleType = schedules[visibleSchedule].type
    let naturalLanguageMessage =
      scheduleType === "once"
        ? "Select at least one day for exports at chosen time"
        : "Select at least one day for exports at chosen intervals of time"
    const currentSchedule = schedules[visibleSchedule]
    if (Object.values(currentSchedule.days).some(day => day)) {
      if (currentSchedule.type === SCHEDULE_TYPE.ONCE) {
        const days = getDaysTextList(currentSchedule.days)
        naturalLanguageMessage = (
          <>
            Schedule is set for every <span>{days}</span> at{" "}
            <span>
              {currentSchedule.hour ? currentSchedule.hour : "0"}:
              {currentSchedule.minute ? currentSchedule.minute : "00"}
            </span>{" "}
            UTC
          </>
        )
      } else {
        if (!currentSchedule.every) {
          naturalLanguageMessage = "Select repeat interval"
        } else {
          const days = getDaysTextList(currentSchedule.days)
          const fromTo =
            currentSchedule.from && currentSchedule.to ? (
              <>
                from <span>{currentSchedule.from}</span> to{" "}
                <span>{currentSchedule.to} hour UTC</span>
              </>
            ) : null
          naturalLanguageMessage = (
            <>
              Schedule is set on <span>{days}</span> and repeats every{" "}
              <span>{currentSchedule.every.langText}</span> {fromTo}
            </>
          )
        }
      }
    }

    let serverTooltipValue = "not set",
      localTooltipValue = "not set"
    if (currentSchedule.type === "once") {
      const time = moment.utc({
        hour: currentSchedule.hour ? currentSchedule.hour : 0,
        minute: currentSchedule.minute ? currentSchedule.minute : 0,
      })
      serverTooltipValue = time.format("H:mm")
      localTooltipValue = time.local().format("H:mm")
    } else {
      if (currentSchedule.from && currentSchedule.to) {
        const fromTime = moment.utc({
          hour: currentSchedule.from,
          minute: 0,
        })
        const toTime = moment.utc({
          hour: currentSchedule.to,
          minute: 0,
        })
        serverTooltipValue = `From ${fromTime.format("H")} to ${toTime.format("H")}`
        localTooltipValue = `From ${fromTime.local().format("H")} to ${toTime.local().format("H")}`
      }
    }

    return (
      <>
        <div className="tabs-n-buttons">
          <div className="tabs">
            {tabs}
            {fields.length < MAX_ALLOWED_SCHEDULES && (
              <Button
                key="schedule-add"
                color="white"
                size="medium"
                className="add-schedule-button"
                onClick={addField}
              >
                <FontAwesomeIcon icon={["far", "plus"]} />
              </Button>
            )}
          </div>
          <div className="buttons">
            {wasFormChanged && (
              <Button
                size="medium"
                color="transparent-grey"
                className="undo-button"
                onClick={reset}
              >
                Undo changes
              </Button>
            )}

            <Button
              size="medium"
              color="white-red"
              onClick={() => {
                onSubmit({ schedules: [] })
              }}
              className={isLoading.delete ? "loading" : ""}
            >
              Delete all
            </Button>
            <Button
              size="medium"
              color="primary"
              type="submit"
              className={isLoading.save ? "loading" : ""}
            >
              Save
            </Button>
          </div>
        </div>
        {fields.map((field, index) => (
          <div key={index}>
            <div className={`form-inputs ${index === visibleSchedule ? "visible" : "hidden"}`}>
              <div className="days">
                <span className="tiny-label">Select day(s) of the week:</span>
                {Array.from({ length: 7 }, (_, i) => i + 1).map(val => (
                  <div key={val} className="day-option">
                    <Field
                      name={`${field}.days.${val.toString()}`}
                      id={`${field}.days.${val.toString()}`}
                      component={DayCheckbox}
                      label={DAY_MAPPINGS[val.toString()]}
                    />
                  </div>
                ))}
              </div>
              <div className="type-toggle">
                <ToggleSwitchField
                  name={`${field}.type`}
                  leftValue={SCHEDULE_TYPE.REPEAT}
                  leftLabel="Interval"
                  rightValue={SCHEDULE_TYPE.ONCE}
                  rightLabel="Fixed time"
                  width="190px"
                  className="type-toggle-field"
                />
              </div>
              {scheduleType === SCHEDULE_TYPE.ONCE && (
                <div className="once-fields">
                  <span className="field-lbl">Time</span>
                  <Field
                    name={`${field}.hour`}
                    component={TextField}
                    type="text"
                    placeholder="0"
                    size="small"
                    maxLength={2}
                    className="once-hour"
                    autoComplete="off"
                  />
                  <span className="double-dot">:</span>
                  <Field
                    name={`${field}.minute`}
                    component={TextField}
                    type="text"
                    placeholder="00"
                    size="small"
                    maxLength={2}
                    className="once-minute"
                    autoComplete="off"
                  />
                </div>
              )}
              {scheduleType === SCHEDULE_TYPE.REPEAT && (
                <div className="repeat-fields">
                  <span className="tiny-label">Select interval:</span>
                  <span className="tiny-label time-label">Fill time (24hr, UTC, optional):</span>
                  <span className="field-lbl">Every</span>
                  <Field
                    name={`${field}.every`}
                    component={SelectField}
                    options={REPEAT_OPTIONS}
                    className="repeat-select"
                    isSearchable={false}
                    size="small"
                    inputId={`repeat-select-${index}`}
                    maxDropdownHeight="200px"
                  />
                  <span className="field-lbl from">from</span>
                  <Field
                    name={`${field}.from`}
                    component={TextField}
                    size="small"
                    type="text"
                    className="repeat-hrs from"
                    autoComplete="off"
                  />
                  <span className="field-lbl to">to</span>
                  <Field
                    name={`${field}.to`}
                    component={TextField}
                    size="small"
                    type="text"
                    className="repeat-hrs to"
                    autoComplete="off"
                  />
                </div>
              )}
              <div className="utc-info">
                <Tippy
                  content={
                    <>
                      <p>
                        <span>Server time (UTC):</span>
                        <span className="value">{serverTooltipValue}</span>
                      </p>
                      <p>
                        <span>Local time:</span>
                        <span className="value">{localTooltipValue}</span>
                      </p>
                    </>
                  }
                >
                  <span className="tooltip-icon">
                    <FontAwesomeIcon icon={["fas", "clock"]} />
                  </span>
                </Tippy>
              </div>
            </div>
            <div
              className={`scheduler-natural-language-message ${
                index === visibleSchedule ? "visible" : "hidden"
              }`}
            >
              {naturalLanguageMessage}
            </div>
          </div>
        ))}
      </>
    )
  }

  render() {
    const {
      open,
      handleClose,
      visibleSchedule,
      schedules,
      handleSubmit,
      form,
      submitErrors,
      onSubmit,
    } = this.props
    return (
      <Modal open={open} title="Scheduler" className="scheduler-form" handleClose={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)} name={form}>
          <FieldArray
            name="schedules"
            component={this.renderSchedules}
            rerenderOnEveryChange={true}
            props={{ visibleSchedule, schedules, submitErrors }}
          />
        </Form>
      </Modal>
    )
  }
}

const validate = values => {
  const errors = {}
  if (Array.isArray(values.schedules)) {
    errors.schedules = values.schedules.map(schedule => {
      const scheduleErrors = {}
      const daysChosen = Object.values(schedule.days).some(day => day)
      if (!daysChosen) {
        scheduleErrors.days = {
          1: "Select at least one day",
        }
      }
      if (schedule.type === SCHEDULE_TYPE.ONCE) {
        const hour = _toInteger(schedule.hour)
        const minute = _toInteger(schedule.minute)
        if (
          schedule.hour !== "" &&
          ((schedule.hour !== "0" && hour === 0) || hour < 0 || hour > 23)
        ) {
          scheduleErrors.hour = "Invalid time"
          scheduleErrors.minute = "_"
        }

        if (
          schedule.minute !== "" &&
          ((!["0", "00"].includes(schedule.minute) && minute === 0) || minute < 0 || minute > 59)
        ) {
          scheduleErrors.hour = "Invalid time"
          scheduleErrors.minute = "_"
        }
      } else {
        if (!schedule.every) {
          scheduleErrors.every = "Select interval"
        }
        if ((schedule.from && !schedule.to) || (!schedule.from && schedule.to)) {
          // placeholder instead of error message on in "to" field
          scheduleErrors.from = "Fill both fields"
          scheduleErrors.to = "_"
        } else if (schedule.from && schedule.to) {
          const from = _toInteger(schedule.from)
          const to = _toInteger(schedule.to)
          if ((from === 0 && schedule.from !== "0") || (to === 0 && schedule.to !== "0")) {
            scheduleErrors.from = "Invalid values (0-23)"
            scheduleErrors.to = "_"
          }
          if (!scheduleErrors.from && !scheduleErrors.to) {
            if (from < 0 || from > 23 || to < 0 || to > 23) {
              scheduleErrors.from = "Invalid values (0-23)"
              scheduleErrors.to = "_"
            } else if (from > to) {
              scheduleErrors.from = "'from' can't be greater"
              scheduleErrors.to = "_"
            }
          }
        }
      }
      return scheduleErrors
    })
  }
  return errors
}

SchedulerForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  visibleSchedule: PropTypes.number.isRequired,
  schedules: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  isLoading: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector(ownProps.form)
  return {
    visibleSchedule: selector(state, "visibleSchedule"),
    schedules: selector(state, "schedules"),
    submitErrors: getFormSyncErrors(ownProps.form)(state),
  }
}

SchedulerForm = connect(mapStateToProps)(SchedulerForm)

SchedulerForm = reduxForm({
  touchOnBlur: false,
  enableReinitialize: true,
  destroyOnUnmount: true,
  validate,
})(SchedulerForm)

export default SchedulerForm
