import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _isNil from "lodash/isNil"
import _toInteger from "lodash/toInteger"
import { Map } from "immutable"
import Select from "react-select"

// ui components
import NameForm from "components/UI/components/NameForm"
import SmartSegmentTile from "components/UI/elements/SmartSegmentTile"
import { DropdownIndicator } from "components/UI/elements/SelectField"

// actions
import { showToast } from "actions/toast.action"
import { modifySegment } from "resources/segment/segment/segmentActions"

// helpers, constants
import { selectStyles } from "helpers/customSelectStyle.helper"
import {
  getSmartSegmentsOptions,
  smartSegmentGroups,
  getSegmentIconSrc,
} from "resources/segment/segment/utilities/smartSegmentUtils"

import "./SmartSegmentSettings.scss"
import { getSegmentById } from "resources/segment/segment/segmentSelectors"

class SmartSegmentSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      positionInputValue: props.segment
        ? props.segment.getIn(["frontend_settings", "smart", "position"])
        : null,
    }
  }

  _getOriginFeSettings = () => {
    const { segment } = this.props
    return Map.isMap(segment.frontend_settings) ? segment.frontend_settings : Map()
  }

  changeSmartSegmentGroup = group => {
    const originFeSettings = this._getOriginFeSettings()
    const changedFeSettings = originFeSettings
      .setIn(["smart", "icon"], null)
      .setIn(["smart", "group_id"], group.value)
      .toJS()
    this.props.handleSmartSegmentFeSettingsChange(changedFeSettings)
  }

  changeSmartSegmentDescription = description => {
    const originFeSettings = this._getOriginFeSettings()
    const changedFeSettings = originFeSettings.setIn(["smart", "description"], description).toJS()
    this.props.handleSmartSegmentFeSettingsChange(changedFeSettings)
  }

  changeSmartSegmentIcon = icon => () => {
    const originFeSettings = this._getOriginFeSettings()
    if (originFeSettings.getIn(["smart", "icon"]) !== icon) {
      const changedFeSettings = originFeSettings.setIn(["smart", "icon"], icon).toJS()
      this.props.handleSmartSegmentFeSettingsChange(changedFeSettings)
    }
  }

  changeSmartSegmentPosition = () => {
    const { positionInputValue } = this.state
    const originFeSettings = this._getOriginFeSettings()
    if (originFeSettings.getIn(["smart", "position"]) !== _toInteger(positionInputValue)) {
      const changedFeSettings = originFeSettings
        .setIn(["smart", "position"], _toInteger(positionInputValue))
        .toJS()
      this.props.handleSmartSegmentFeSettingsChange(changedFeSettings)
    }
  }

  _getSmartSegmentGroup = () => {
    const { segment } = this.props
    return smartSegmentGroups.get(segment.getIn(["frontend_settings", "smart", "group_id"]))
  }

  renderPicturesPicker = () => {
    const group = this._getSmartSegmentGroup()
    const selectedPicture = this.props.segment.getIn(["frontend_settings", "smart", "icon"])
    if (group) {
      return group.icons.map(icon => (
        <div
          onClick={this.changeSmartSegmentIcon(icon)}
          className={`icon-wrapper ${selectedPicture === icon ? "selected" : ""}`}
          key={icon}
        >
          <img src={getSegmentIconSrc(icon)} alt="" />
          {selectedPicture === icon && (
            <span className="checkmark">
              <FontAwesomeIcon icon={["far", "check"]} />
            </span>
          )}
        </div>
      ))
    }
  }

  getSmartSegmentColor = () => {
    const group = this._getSmartSegmentGroup()
    if (group) {
      return group.color
    }
    return ""
  }

  getSmartSegmentGroupName = () => {
    const group = this._getSmartSegmentGroup()
    if (group) {
      return group.name
    }
    return "Segment group"
  }

  onPositionChange = evt => {
    this.setState({
      positionInputValue: evt.target.value,
    })
  }

  render() {
    const { segment, smartSegmentDescriptionEditMode, smartSegmentDescriptionToggleFunc } =
      this.props
    const { positionInputValue } = this.state

    return (
      <div className="smart-segment-attributes">
        <div className="smart-segment-form">
          <div className="form-row flex">
            <div className="segment-group-field">
              <label>Segment group</label>
              <Select
                value={getSmartSegmentsOptions().find(
                  option =>
                    option.value === segment.getIn(["frontend_settings", "smart", "group_id"]),
                )}
                onChange={this.changeSmartSegmentGroup}
                options={getSmartSegmentsOptions()}
                styles={selectStyles("big", "all", "200px")}
                simpleValue
                isSearchable={true}
                className="select-input"
                components={{
                  DropdownIndicator: DropdownIndicator,
                }}
                inputId="group-select"
              />
            </div>
            <div className="position-field">
              <label>Position in group</label>
              <div className="text-field">
                <input
                  type="number"
                  value={positionInputValue}
                  onChange={this.onPositionChange}
                  onBlur={this.changeSmartSegmentPosition}
                  autoComplete="off"
                  className="position-input"
                />
              </div>
            </div>
          </div>
          <div className="form-row flex">
            <NameForm
              initialValues={{
                name: segment.getIn(["frontend_settings", "smart", "description"]),
              }}
              handleNameChange={this.changeSmartSegmentDescription}
              size="big"
              form="smart-segment-description-form"
              label="Short description"
              editMode={smartSegmentDescriptionEditMode}
              toggleEditMode={smartSegmentDescriptionToggleFunc}
              isEditable
              placeholder="Description"
              allowEmptyValue
            />
          </div>
          {!_isNil(segment.getIn(["frontend_settings", "smart", "group_id"])) && (
            <div className="form-row no-margin-bottom">
              <label>Choose picture</label>
              <div className="pictures">{this.renderPicturesPicker()}</div>
            </div>
          )}
        </div>
        <div className="smart-segment-preview">
          <div className="label">Preview</div>
          <div className={`segment-group ${this.getSmartSegmentColor()}`}>
            {this.getSmartSegmentGroupName()}
          </div>
          <SmartSegmentTile
            name={segment.name}
            description={segment.getIn(["frontend_settings", "smart", "description"])}
            className="preview-tile"
            color={this.getSmartSegmentColor()}
            icon={segment.getIn(["frontend_settings", "smart", "icon"])}
          />
        </div>
      </div>
    )
  }
}

SmartSegmentSettings.propTypes = {
  segment: PropTypes.object.isRequired,
  smartSegmentDescriptionEditMode: PropTypes.bool.isRequired,
  smartSegmentDescriptionToggleFunc: PropTypes.func.isRequired,
  handleSmartSegmentFeSettingsChange: PropTypes.func.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
  segment: getSegmentById(state, ownProps.id, "smart"),
})

export default connect(mapStateToProps, {
  showToast,
  modifySegment,
})(SmartSegmentSettings)
