import React, { useCallback, useEffect, useRef, useState } from "react"
import styles from "./BannerPreview.module.scss"
import { WebBannerType } from "../../../popupWBComponentTypes"
import classnames from "classnames"
import { useMutationObserver } from "hooks/useMutationObserver"
import {
  PWBPosition,
  PWBAnimation,
  PWBCloseButton,
} from "resources/webBanner/popupWebBanner/popupWBTypes"
import { getMarginsStyles, getAnimationStyles, AnimationStyles } from "./utils"
import Paper from "components/UI/elements/Paper"
import Button from "components/UI/elements/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ToggleSwitch from "components/UI/elements/ToggleSwitch"

export type WebBannersPreviewProps = {
  type: WebBannerType
  minimizedType: WebBannerType
  html: string | null
  minimizedHtml?: string | null
  imageUrl?: string
  minimizedImageUrl?: string
  base64Image: string | null
  base64MinimizedImage: string | null
  width?: number | null
  minimizedWidth?: number | null
  position?: PWBPosition
  minimizedPosition?: PWBPosition
  margins: {
    x?: number
    y?: number
    minimizedX?: number
    minimizedY?: number
  }
  minimizedBannerEnabled: boolean
  animation?: PWBAnimation
  minimizedAnimation?: PWBAnimation
  closeButtonStyles: PWBCloseButton
  minimizedCloseButtonStyles: PWBCloseButton
}

export default function BannerPreview({
  type,
  minimizedType,
  html,
  imageUrl,
  base64Image,
  width,
  minimizedWidth,
  minimizedHtml,
  minimizedImageUrl,
  base64MinimizedImage,
  position,
  minimizedPosition,
  margins,
  minimizedBannerEnabled,
  animation,
  minimizedAnimation,
  closeButtonStyles,
  minimizedCloseButtonStyles,
}: WebBannersPreviewProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const bannerRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const [previewType, setPreviewType] = useState<"large" | "minimized">("large")
  const [animationCss, setAnimationCss] = useState<AnimationStyles>({})
  const renderedCloseButtonStyles =
    previewType === "large" ? closeButtonStyles : minimizedCloseButtonStyles
  const renderedPosition = previewType === "large" ? position : minimizedPosition
  const animationEnabled =
    (previewType === "large" && animation && animation !== "none") ||
    (minimizedBannerEnabled &&
      previewType === "minimized" &&
      minimizedAnimation &&
      minimizedAnimation !== "none")

  const updateIframeHeight = () => {
    if (iframeRef.current) {
      const height =
        iframeRef.current.contentWindow?.document.documentElement?.getBoundingClientRect().height
      setHeight(height ?? 0)
    }
  }

  useEffect(() => {
    updateIframeHeight()
  }, [width, minimizedWidth])

  useMutationObserver({
    target: iframeRef.current?.contentWindow?.document.body,
    options: {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    },
    callback: updateIframeHeight,
  })

  const getMargins = useCallback(() => {
    return getMarginsStyles(previewType, margins, position)
  }, [position, margins, previewType])

  const playAnimation = useCallback(() => {
    const { width, height } = bannerRef.current!.getBoundingClientRect()
    const bannerAnimation = previewType === "large" ? animation : minimizedAnimation
    const bannerPosition = previewType === "large" ? position : minimizedPosition
    const bannerMargins =
      previewType === "large"
        ? { x: margins.x, y: margins.y }
        : { x: margins.minimizedX, y: margins.minimizedY }
    setAnimationCss(
      getAnimationStyles("initial", bannerAnimation, bannerPosition, width, height, bannerMargins),
    )
    setTimeout(() => {
      setAnimationCss(
        getAnimationStyles("final", bannerAnimation, bannerPosition, width, height, bannerMargins),
      )
    }, 300)
  }, [previewType, animation, minimizedAnimation, minimizedPosition, position, margins])

  const togglePreviewType = useCallback(() => {
    setPreviewType(previewType === "large" ? "minimized" : "large")
  }, [previewType])

  let previewWidth = previewType === "large" ? width : minimizedWidth
  if (previewWidth && previewWidth > 1120) {
    previewWidth = 1120
  }
  const isLargeImage = previewType === "large" && type === "image" && (imageUrl || base64Image)
  const isMinimizedImage =
    previewType === "minimized" &&
    minimizedType === "image" &&
    (minimizedImageUrl || base64MinimizedImage)
  const isLargeHtml = previewType === "large" && type === "html" && html
  const isMinimizedHtml = previewType === "minimized" && minimizedType === "html" && minimizedHtml

  let preview = <div className={styles.noContent}>Fill the form fields to see the preview</div>

  if (isLargeImage) {
    preview = (
      <img
        style={{ width: width ? `${previewWidth}px` : "auto" }}
        src={imageUrl || base64Image!}
        alt=""
      />
    )
  }

  if (isMinimizedImage) {
    preview = (
      <img
        style={{ width: minimizedWidth ? `${previewWidth}px` : "auto" }}
        src={minimizedImageUrl || base64MinimizedImage!}
        alt=""
      />
    )
  }

  if (isLargeHtml || isMinimizedHtml) {
    if ((isLargeHtml && !width) || (isMinimizedHtml && !minimizedWidth)) {
      preview = <div className={styles.noContent}>Fill the width to see the preview</div>
    } else {
      preview = (
        <iframe
          ref={iframeRef}
          width={previewWidth!}
          height={height}
          title="preview"
          className={styles.bannerInnerHtml}
          srcDoc={previewType === "large" ? html! : minimizedHtml!}
          onLoad={updateIframeHeight}
        />
      )
    }
  }

  return (
    <Paper className={styles.paper}>
      <div className={styles.paperInnerHeader}>
        <h3>Preview</h3>
        <div className={styles.bannerPreviewControls}>
          {animationEnabled && (
            <Button
              color="primary"
              size="small"
              className={styles.playAnimationButton}
              onClick={playAnimation}
            >
              <FontAwesomeIcon icon={["fas", "play"]} />
              Play animation
            </Button>
          )}
          {minimizedBannerEnabled && (
            <ToggleSwitch
              leftValue={"large"}
              rightValue={"minimized"}
              checked={previewType}
              handleToggle={togglePreviewType}
              width="150px"
              className={styles.toggleBannerTypeButton}
            />
          )}
        </div>
      </div>
      <div
        className={classnames(
          styles.preview,
          styles[renderedPosition ? renderedPosition : "middle"],
        )}
      >
        <div
          className={classnames(styles.bannerCard, {
            [styles.imageBanner]: type === "image",
            [styles.htmlBanner]: type === "html",
          })}
          style={{ ...getMargins(), ...animationCss }}
          ref={bannerRef}
        >
          <>
            {preview}
            {!renderedCloseButtonStyles.disabled && (
              <button
                className={classnames(styles.closeButton, {
                  [styles.closeButtonMinimized]: previewType === "minimized",
                })}
                type="button"
                style={{
                  backgroundColor: renderedCloseButtonStyles.css?.background_color,
                  borderColor: renderedCloseButtonStyles.css?.border_color,
                }}
              >
                <svg width="12" height="12" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1 L9 9 M1 9 L9 1"
                    strokeLinecap="round"
                    strokeWidth="1.8"
                    stroke={renderedCloseButtonStyles.css?.color}
                  />
                </svg>
              </button>
            )}
          </>
        </div>
      </div>
    </Paper>
  )
}
