import React, { useCallback, useState } from "react"
import styles from "./EditableValue.module.scss"
import { useForm } from "react-hook-form"
import TextField from "components/UI/elements/TextField/HookFormTextField"
import useToggle from "hooks/useToggle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"

type EditableValueProps = {
  initValue: string
  onChange: (value: string) => void | Promise<void>
  validate?: (value: string) => string | undefined
  className?: string
  inputClassName?: string
}

export default function EditableValue({
  initValue,
  onChange,
  validate,
  className,
  inputClassName,
}: EditableValueProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" })
  const [isEditing, toggleIsEditing] = useToggle(false)
  const [isSaving, setIsSaving] = useState(false)

  const save = useCallback(
    async ({ value }) => {
      if (value !== initValue) {
        setIsSaving(true)
        await onChange(value)
        setIsSaving(false)
      }
      toggleIsEditing()
    },
    [initValue, onChange, toggleIsEditing],
  )

  const escListener = useCallback(
    e => {
      if (e.key === "Escape") toggleIsEditing()
    },
    [toggleIsEditing],
  )

  return isEditing ? (
    <form className={styles.container} onSubmit={handleSubmit(save)}>
      <TextField
        defaultValue={initValue}
        error={errors.value?.message}
        className={inputClassName}
        onKeyUp={escListener}
        autoFocus
        data-testid="editable-value-input"
        {...register("value", { validate })}
      />
      <button
        className={styles.saveButton}
        type="submit"
        disabled={isSaving}
        data-testid="save-button"
      >
        {isSaving ? <LoadingIndicator /> : <FontAwesomeIcon icon={["fas", "check"]} />}
      </button>
      <button
        className={styles.button}
        type="button"
        disabled={isSaving}
        onClick={toggleIsEditing}
        data-testid="cancel-button"
      >
        <FontAwesomeIcon icon={["fas", "times"]} />
      </button>
    </form>
  ) : (
    <div className={styles.container}>
      <div className={className}>{initValue}</div>
      <button
        className={styles.button}
        type="button"
        onClick={toggleIsEditing}
        data-testid="edit-button"
      >
        <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
      </button>
    </div>
  )
}
