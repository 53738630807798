import {
  TrashItemType,
  FetchTrashItemsParams,
  FetchTrashItemsResponse,
  TrashItem,
} from "./trashTypes"
import { api } from "api"
import {
  embeddedWebBannerToTrashItemTransform,
  popupWebBannerToTrashItemTransform,
  segmentExportDestinationToTrashItemTransform,
  segmentToTrashItemTransform,
  userRoleToTrashItemTransform,
  userToTrashItemTransform,
} from "./utilities/entityToTrashItemTransform"
import { restoreUser } from "resources/user/userActions"
import store from "store"
import { restoreExportDestination } from "resources/exportDestination/exportDestinationActions"

export const fetchTrashItemsCounts = async () => {
  return await api.trash.itemTypesCounts()
}

export const fetchTrashItems = async ({
  offset,
  searched_text,
  item_type,
}: FetchTrashItemsParams): Promise<FetchTrashItemsResponse> => {
  switch (item_type) {
    case "segments": {
      const res = await api.segment.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_segments.map(segmentToTrashItemTransform),
      }
    }
    case "embedded_web_banners": {
      const res = await api.embeddedWebBanner.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_embedded_web_banners.map(embeddedWebBannerToTrashItemTransform),
      }
    }
    case "popup_web_banners": {
      const res = await api.popupWebBanner.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_popup_web_banners.map(popupWebBannerToTrashItemTransform),
      }
    }
    case "segment_export_destinations": {
      const res = await api.exportDestination.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_segment_export_destinations.map(
          segmentExportDestinationToTrashItemTransform,
        ),
      }
    }
    case "user_roles": {
      const res = await api.userRole.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_user_roles.map(userRoleToTrashItemTransform),
      }
    }
    case "users": {
      const res = await api.user.listDeleted({ offset, searched_text })
      return {
        selection_settings: res.selection_settings,
        data: res.trashed_users.map(userToTrashItemTransform),
      }
    }
    default:
      throw new Error("Unknown trash item type")
  }
}

export const restoreTrashItem = async (
  id: string | number,
  itemType: TrashItemType,
): Promise<{ restoredTrashItem: TrashItem }> => {
  switch (itemType) {
    case "segments": {
      return {
        restoredTrashItem: segmentToTrashItemTransform(
          (await api.segment.restoreDeleted(id as number)).segment,
        ),
      }
    }
    case "embedded_web_banners": {
      return {
        restoredTrashItem: embeddedWebBannerToTrashItemTransform(
          (await api.embeddedWebBanner.restoreDeleted(id as string)).embedded_web_banner,
        ),
      }
    }
    case "popup_web_banners": {
      return {
        restoredTrashItem: popupWebBannerToTrashItemTransform(
          (await api.popupWebBanner.restoreDeleted(id as string)).popup_web_banner,
        ),
      }
    }
    case "segment_export_destinations": {
      const exportDestination = (await api.exportDestination.restoreDeleted(id as number))
        .segment_export_destination
      store.dispatch(restoreExportDestination(exportDestination))
      return {
        restoredTrashItem: segmentExportDestinationToTrashItemTransform(exportDestination),
      }
    }
    case "user_roles": {
      return {
        restoredTrashItem: userRoleToTrashItemTransform(
          await api.userRole.restoreDeleted(id as number),
        ),
      }
    }
    case "users": {
      const restoredUser = await api.user.restoreDeleted(id as number)
      store.dispatch(restoreUser(restoredUser))
      return {
        restoredTrashItem: userToTrashItemTransform(restoredUser),
      }
    }
    default:
      throw new Error("Unknown trash item type")
  }
}
