import React from "react"
import _template from "lodash/template"
import _map from "lodash/map"
import _has from "lodash/has"
import _isArray from "lodash/isArray"
import _isEmpty from "lodash/isEmpty"
import JSPath from "jspath"
import { getUserFriendlyValueFormat } from "./attributeValue.helper"

const getValue = (customerEvent, type, path, isArray = false) => {
  let values = []
  try {
    values = JSPath.apply(path, customerEvent.payload)
  } catch {
    if (isArray) {
      return []
    } else {
      return "—"
    }
  }

  if (isArray) {
    switch (type) {
      case "number":
        return _map(values, value => getUserFriendlyValueFormat(value, "int"))
      case "datetime":
        return _map(values, value => getUserFriendlyValueFormat(value, "datetime"))
      case "string":
      default:
        return values
    }
  } else {
    if (values.length === 0) {
      return "—"
    } else {
      switch (type) {
        case "number":
          return getUserFriendlyValueFormat(values[0], "int")
        case "datetime":
          return getUserFriendlyValueFormat(values[0], "datetime")
        case "string":
        default:
          return values[0]
      }
    }
  }
}

export const getCustomerEventTitle = (customerEvent, schema, defaultName) => {
  let title = defaultName
  if (_has(schema, "title") && schema.title) {
    try {
      const compiled = _template(schema.title)
      title = compiled(customerEvent.payload)
    } catch {
      return title
    }
  }
  return title
}

export const getCustomerEventPayload = (
  customerEvent,
  schema,
  expandable,
  expand,
  headingHeight,
) => {
  let payload = null
  if (_has(schema, "display") && !_isEmpty(schema.display)) {
    payload = (
      <div className="payload">
        <div className="payload-content">
          {schema.display.map((item, index) => {
            switch (item.format) {
              case "ahref":
                return (
                  <p key={index} className="entry">
                    {`${item.name}: `}
                    <a
                      href={getValue(customerEvent, item.type, item.path)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      link
                    </a>
                  </p>
                )
              case "ul":
                const value = getValue(customerEvent, item.type, item.path, true)
                return (
                  <div key={index} className="entry">
                    <h5>
                      {`${item.name}:`}
                      {_isArray(value) && value.length === 0 ? " —" : ""}
                    </h5>
                    <ul key={index}>
                      {_map(value, (value, idx) => (
                        <li key={idx}>{value}</li>
                      ))}
                    </ul>
                  </div>
                )
              default: {
                return (
                  <p key={index} className="entry">
                    {`${item.name}: ${getValue(customerEvent, item.type, item.path)}`}
                  </p>
                )
              }
            }
          })}
        </div>
        <div
          className={`payload-overlay ${expandable && expand === true ? "" : "collapse"} ${
            expandable && headingHeight < 45 ? "big" : "small"
          }`}
        />
      </div>
    )
  }
  return payload
}
