import React, { ComponentProps, useCallback, useEffect, useRef, useState } from "react"
import classnames from "classnames"
import styles from "./DragAndDrop.module.scss"

type DragAndDropProps = {
  maxFilesCount?: number
  onFileDrop: (files: FileList) => void
} & ComponentProps<"div">

export default function DragAndDrop({
  children,
  className,
  onFileDrop,
  maxFilesCount = 1,
}: DragAndDropProps) {
  const dropRef = useRef<HTMLDivElement>(null)
  const [dragging, setDragging] = useState(false)

  const handleDragEnter = useCallback((evt: DragEvent) => {
    evt.preventDefault()
    evt.stopPropagation()
    if (evt.dataTransfer?.items && evt.dataTransfer?.items.length) {
      setDragging(true)
    }
  }, [])

  const handleDragLeave = useCallback((evt: DragEvent) => {
    evt.preventDefault()
    evt.stopPropagation()
    setDragging(false)
  }, [])

  const handleDragOver = (evt: DragEvent) => {
    evt.preventDefault()
    evt.stopPropagation()
  }

  const handleDrop = useCallback(
    (evt: DragEvent) => {
      evt.preventDefault()
      evt.stopPropagation()
      if (evt.dataTransfer?.files && evt.dataTransfer?.files.length === maxFilesCount) {
        onFileDrop(evt.dataTransfer.files)
        evt.dataTransfer.clearData()
      }
      setDragging(false)
    },
    [maxFilesCount, onFileDrop],
  )

  useEffect(() => {
    const div = dropRef.current
    if (div) {
      div.addEventListener("dragenter", handleDragEnter)
      div.addEventListener("dragleave", handleDragLeave)
      div.addEventListener("dragover", handleDragOver)
      div.addEventListener("drop", handleDrop)

      return () => {
        div.removeEventListener("dragenter", handleDragEnter)
        div.removeEventListener("dragleave", handleDragLeave)
        div.removeEventListener("dragover", handleDragOver)
        div.removeEventListener("drop", handleDrop)
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div
      ref={dropRef}
      className={classnames(styles.dragAndDrop, { [styles.dragging]: dragging }, className)}
    >
      {children}
    </div>
  )
}
