import React, { Component } from "react"
import { Field } from "redux-form"
import TextField from "../../elements/TextField"
import zxcvbn from "zxcvbn"

import {
  required,
  minLength12,
  passwordStrength as pwdStrengthValidate,
} from "helpers/validators.helper"

import WeakIcon from "./weakIcon.svg"
import MildIcon from "./mildIcon.svg"
import StrongIcon from "./strongIcon.svg"

import "./PasswordField.scss"

class PasswordField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      passwordStrength: 0,
    }
  }

  /*
   * Evaluates password strength.
   *
   * @param string  password  password to be evaluated
   * @return int    strength  0 - no password, 1 - weak, 2 - Good, 3 - Strong, 4 - Very strong
   */
  _evaluatePasswordStrength = password => {
    const strength = zxcvbn(password).score
    if (password.length < 12 && strength > 2) {
      return 2
    }
    return strength
  }

  setPasswordStrength = password => {
    const strength = this._evaluatePasswordStrength(password)
    this.setState({
      passwordStrength: strength,
    })
  }

  onChangePassword = evt => {
    const password = evt.currentTarget.value

    this.setPasswordStrength(password)
  }

  renderPasswordStrengthIndicator = () => {
    const { passwordFieldLabel } = this.props
    const { passwordStrength } = this.state
    let iconSrc = WeakIcon
    let text = "weak"
    if (passwordStrength === 3) {
      iconSrc = MildIcon
      text = "mild"
    } else if (passwordStrength === 4) {
      iconSrc = StrongIcon
      text = "strong"
    }
    return (
      <div className={`strength-icon-wrapper ${passwordFieldLabel ? "has-label" : ""}`}>
        <div className={`strength-icon ${text}`}>
          <img src={iconSrc} alt="" />
        </div>
        <span className={`label ${text}`}>{text}</span>
      </div>
    )
  }

  render() {
    const { passwordFieldLabel, autoFocus, placeholder = "" } = this.props

    return (
      <div className="password-field-wrapper">
        <Field
          name="password"
          component={TextField}
          label={passwordFieldLabel}
          validate={[required, minLength12, pwdStrengthValidate]}
          fullWidth={true}
          placeholder={placeholder ? placeholder : "Password"}
          type="password"
          onChange={this.onChangePassword}
          autoFocus={autoFocus ? true : false}
        />
        {this.renderPasswordStrengthIndicator()}
      </div>
    )
  }
}

export default PasswordField
