import { createSelector } from "reselect"
import { List } from "immutable"

import { getCustomerSearchesData } from "./customerSearch.selector"

const getIterator = state => state.customersIterator
const getSegmentCustomers = state => state.segmentCustomers

export const getCustomersIterator = createSelector(
  [getIterator, getCustomerSearchesData, getSegmentCustomers],
  (iterator, customerSearches, segmentCustomers) => {
    if (iterator.get("index") !== null) {
      if (iterator.get("segmentId")) {
        const customerData = segmentCustomers.getIn([iterator.get("segmentId"), "data"])
        let customerIds = List()
        if (List.isList(customerIds)) {
          customerIds = customerData.map(customer => customer.id)
        }
        return iterator.set("customerIds", customerIds)
      } else {
        let customerIds = List()
        if (List.isList(customerSearches)) {
          customerIds = customerSearches.map(customer => customer.customer_entity.id)
        }
        return iterator.set("customerIds", customerIds)
      }
    }
    return iterator.set("customerIds", List())
  },
)
