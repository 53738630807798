import React, { Component } from "react"
import { connect } from "react-redux"
import _get from "lodash/get"

import { modifyAuthUser } from "actions/authenticatedUser.action"

import UserSettings from "components/UserSettings"

class UserSettingsPage extends Component {
  render() {
    const { authenticatedUser, modifyAuthUser } = this.props
    const user = _get(authenticatedUser, "data")

    if (!user) {
      return null
    }

    return (
      <UserSettings user={user} modifyUser={modifyAuthUser} editablePassword={!user.okta_enabled} />
    )
  }
}

const mapStateToProps = state => {
  return {
    authenticatedUser: state.authenticatedUser,
  }
}

UserSettingsPage = connect(mapStateToProps, { modifyAuthUser })(UserSettingsPage)

export default UserSettingsPage
