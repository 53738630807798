/*eslint no-loop-func: "off"*/

import React from "react"
import { Map, List } from "immutable"
import _find from "lodash/find"
import _min from "lodash/min"
import _isNumber from "lodash/isNumber"
import _repeat from "lodash/repeat"
import _toString from "lodash/toString"

import { charactersMap } from "helpers/charactersMap.helper"
import {
  getCompoundAttributeSubAttributes,
  isAttributeCompound,
} from "resources/attribute/compoundAttributeUtils"

import { LOGICAL_OPERATOR } from "sharedConstants"

export const getSegmentsConditionsDescription = (filters, operations, toggleHoverFunc = null) => {
  let level = 0
  let openedParentheses = 0
  const titleParts = []
  filters.forEach((filter, index) => {
    const currentLevel = operations.getIn([index, "level"])
    if (index) {
      titleParts.push(
        <span key={titleParts.length}>
          {operations.getIn([index - 1, "value"]) === LOGICAL_OPERATOR.AND ? (
            <span className="operator"> and </span>
          ) : (
            <span className="operator"> or </span>
          )}
        </span>,
      )
    }
    const negation = filter.get("negation", false)
    if (_isNumber(currentLevel) && currentLevel !== level) {
      if (currentLevel > level) {
        openedParentheses += currentLevel - level
        titleParts.push(
          <span key={titleParts.length} className="parentheses">
            {_repeat("(", currentLevel - level)}
          </span>,
        )
        titleParts.push(
          <span
            key={titleParts.length}
            className={`condition-${index}`}
            onMouseEnter={toggleHoverFunc ? toggleHoverFunc(index) : null}
            onMouseLeave={toggleHoverFunc ? toggleHoverFunc(index) : null}
          >
            <span className="not">{negation ? "not" : ""}</span>
            {charactersMap[_toString(index)]}
          </span>,
        )
      } else {
        openedParentheses -= level - currentLevel
        titleParts.push(
          <span
            key={titleParts.length}
            className={`condition-${index}`}
            onMouseEnter={toggleHoverFunc ? toggleHoverFunc(index) : null}
            onMouseLeave={toggleHoverFunc ? toggleHoverFunc(index) : null}
          >
            <span className="not">{negation ? "not" : ""}</span>
            {charactersMap[_toString(index)]}
          </span>,
        )
        titleParts.push(
          <span key={titleParts.length} className="parentheses">
            {_repeat(")", level - currentLevel)}
          </span>,
        )
      }
      level = currentLevel
    } else {
      if (_isNumber(currentLevel)) {
        titleParts.push(
          <span
            key={titleParts.length}
            className={`condition-${index}`}
            onMouseEnter={toggleHoverFunc ? toggleHoverFunc(index) : null}
            onMouseLeave={toggleHoverFunc ? toggleHoverFunc(index) : null}
          >
            <span className="not">{negation ? "not" : ""}</span>
            {charactersMap[_toString(index)]}
          </span>,
        )
        level = currentLevel
      } else {
        titleParts.push(
          <span
            key={titleParts.length}
            className={`condition-${index}`}
            onMouseEnter={toggleHoverFunc ? toggleHoverFunc(index) : null}
            onMouseLeave={toggleHoverFunc ? toggleHoverFunc(index) : null}
          >
            <span className="not">{negation ? "not" : ""}</span>
            {charactersMap[_toString(index)]}
          </span>,
        )
        titleParts.push(
          <span key={titleParts.length} className="parentheses">
            {_repeat(")", openedParentheses)}
          </span>,
        )
      }
    }
  })
  return titleParts
}

/*
 * Parses segment settings.conditions_operation object obtained from API and returns
 * object suitable for modify internal CB state.
 *
 * @param Immutable.Map   conditionsOperation
 */
export const parseConditionsOperationObject = (
  conditionsOperation,
  attributesMapById,
  operationsBetweenConditions = List(),
) => {
  const pushListMutable = value => list => {
    return list.push(value)
  }

  const defineSubFilters = (item, activeOperation, subFilters, subAttributes) => {
    const subAttributeId = item.getIn(["condition", "sub_attribute_id"])
    const existingSubFilterIndex = subFilters.findIndex(
      subFilter => subFilter.getIn(["subattribute", "id"]) === subAttributeId,
    )
    if (existingSubFilterIndex === -1) {
      subFilters = subFilters.push(
        Map({
          subattribute: Map(_find(subAttributes, subattr => subattr.id === subAttributeId)),
          conditions: List([item.get("condition").delete("sub_attribute_id")]),
          operations: List(),
        }),
      )
    } else {
      subFilters = subFilters
        .setIn(
          [existingSubFilterIndex, "conditions"],
          subFilters
            .getIn([existingSubFilterIndex, "conditions"])
            .push(item.get("condition").delete("sub_attribute_id")),
        )
        .setIn(
          [existingSubFilterIndex, "operations"],
          subFilters.getIn([existingSubFilterIndex, "operations"]).push(activeOperation),
        )
    }
    return subFilters
  }

  const constructFilter = (item, id) => {
    const attribute = attributesMapById[item.get("attribute_id")]
    const negation = item.get("negation", false)
    if (attribute && isAttributeCompound(attribute.data_type)) {
      if (item.has("condition") && !item.hasIn(["condition", "sub_attribute_id"])) {
        // compound attribute with is_set/is_not_set single operation and no subFilters
        return Map({
          id,
          attribute_id: item.get("attribute_id"),
          negation,
          operations: List(),
          conditions: List([Map({ operation: item.getIn(["condition", "operation"]) })]),
          subFilters: List(),
        })
      }

      const subAttributes = getCompoundAttributeSubAttributes(attribute.data_type)
      // compound attribute
      if (item.hasIn(["condition", "sub_attribute_id"])) {
        // single subattribute condition
        return Map({
          id,
          attribute_id: item.get("attribute_id"),
          negation,
          operations: List(),
          conditions: List(),
          subFilters: List([
            Map({
              subattribute: Map(
                _find(
                  subAttributes,
                  subAttr => subAttr.id === item.getIn(["condition", "sub_attribute_id"]),
                ),
              ),
              conditions: List([item.get("condition").delete("sub_attribute_id")]),
              operations: List(),
            }),
          ]),
        })
      } else {
        // multiple subattributes conditions, object has "operands"
        let subFilters = List()
        let subFiltersOperations = List()
        let traversalTree = List([
          Map({
            operands: item.get("operands"),
            operation: item.get("operation"),
          }),
        ])
        let visitedItemsIndexes = List([-1])
        let latestAttribute = null
        while (traversalTree.size) {
          const currentItem = traversalTree.last().get("operands")
          if (currentItem.has("operands")) {
            traversalTree = traversalTree.push(
              Map({
                operands: currentItem.get("operands"),
                operation: currentItem.get("operation"),
              }),
            )
            visitedItemsIndexes = visitedItemsIndexes.push(-1)
          } else if (List.isList(currentItem)) {
            const index = traversalTree.size - 1
            const visitedItemIndex = visitedItemsIndexes.get(index)
            const visitedItem = currentItem.get(visitedItemIndex + 1)
            if (visitedItem) {
              if (visitedItem.has("operands")) {
                traversalTree = traversalTree.push(
                  Map({
                    operands: visitedItem.get("operands"),
                    operation: visitedItem.get("operation"),
                  }),
                )
                visitedItemsIndexes = visitedItemsIndexes.push(-1)
              } else {
                subFilters = defineSubFilters(
                  visitedItem,
                  traversalTree.last().get("operation"),
                  subFilters,
                  subAttributes,
                )
                if (latestAttribute) {
                  if (visitedItem.getIn(["condition", "sub_attribute_id"]) !== latestAttribute.id) {
                    const index = _min([latestAttribute.deepth, traversalTree.size])
                    if (visitedItemIndex !== -1) {
                      subFiltersOperations = subFiltersOperations.push(
                        traversalTree.getIn([index - 1, "operation"]),
                      )
                    } else {
                      subFiltersOperations = subFiltersOperations.push(
                        traversalTree.getIn([index - 2, "operation"]),
                      )
                    }
                    latestAttribute = {
                      id: visitedItem.getIn(["condition", "sub_attribute_id"]),
                      deepth: traversalTree.size,
                    }
                  }
                } else {
                  latestAttribute = {
                    id: visitedItem.getIn(["condition", "sub_attribute_id"]),
                    deepth: traversalTree.size,
                  }
                }
                visitedItemsIndexes = visitedItemsIndexes.set(
                  index,
                  visitedItemsIndexes.get(index) + 1,
                )
              }
            } else {
              traversalTree = traversalTree.pop()
              visitedItemsIndexes = visitedItemsIndexes.pop()
              if (visitedItemsIndexes.size) {
                visitedItemsIndexes = visitedItemsIndexes.set(
                  index - 1,
                  visitedItemsIndexes.get(index - 1) + 1,
                )
              }
            }
          }
        }
        return Map({
          id,
          attribute_id: item.get("attribute_id"),
          negation,
          operations: subFiltersOperations,
          conditions: List(),
          subFilters,
        })
      }
    }

    // regular attribute
    if (item.has("condition")) {
      // single row condition
      const conditions = List([item.get("condition")])
      return Map({
        id,
        attribute_id: item.get("attribute_id"),
        negation: item.get("negation", false),
        operations: List(),
        conditions,
      })
    } else {
      // multiple
      let listOfInners = List([item])
      let parent = item
      while (parent.hasIn(["operands", 0, "operation"])) {
        listOfInners = listOfInners.withMutations(pushListMutable(parent.getIn(["operands", 0])))
        parent = parent.getIn(["operands", 0])
      }
      let operations = List()
      let conditions = List()
      let filter = Map({
        id,
        attribute_id: item.get("attribute_id"),
        negation: item.get("negation", false),
        operations,
        conditions,
      })
      for (let i = listOfInners.size - 1; i >= 0; i--) {
        for (let j = 0; j < listOfInners.getIn([i, "operands"]).size; j++) {
          const entry = listOfInners.getIn([i, "operands", j])
          if (Map.isMap(entry) && entry.has("condition")) {
            conditions = conditions.withMutations(pushListMutable(entry.get("condition")))
            if (j > 0) {
              operations = operations.withMutations(
                pushListMutable(listOfInners.getIn([i, "operation"])),
              )
            }
          }
        }
      }
      return filter.set("operations", operations).set("conditions", conditions)
    }
  }

  if (!conditionsOperation || conditionsOperation.size === 0) {
    return {
      filters: List(),
      operations: List(),
    }
  } else {
    if (!conditionsOperation.has("attribute_id")) {
      // multiple conditions
      let filters = List()
      let operations = List()
      let operationsStack = List([
        Map({
          maxUsages: conditionsOperation.get("operands").size - 1,
          used: 0,
          value: conditionsOperation.get("operation"),
        }),
      ])
      let traversalTree = List([conditionsOperation])
      let visitedItemsIndexes = List([-1])
      let i = 1
      while (traversalTree.size) {
        const currentItem = traversalTree.last().get("operands")
        if (List.isList(currentItem)) {
          const index = traversalTree.size - 1
          const visitedItemIndex = visitedItemsIndexes.get(index)
          const visitedItem = currentItem.get(visitedItemIndex + 1)
          if (visitedItem) {
            if (visitedItem.has("operands") && !visitedItem.has("attribute_id")) {
              traversalTree = traversalTree.push(visitedItem)
              operationsStack = operationsStack.push(
                Map({
                  maxUsages: visitedItem.get("operands").size - 1,
                  used: 0,
                  value: visitedItem.get("operation"),
                }),
              )
              visitedItemsIndexes = visitedItemsIndexes.push(-1)
            } else {
              filters = filters.push(constructFilter(visitedItem, i))
              while (
                operationsStack.size &&
                operationsStack.last().get("maxUsages") === operationsStack.last().get("used")
              ) {
                operationsStack = operationsStack.pop()
              }
              if (operationsStack.size) {
                operations = operations.push(
                  Map({
                    level: 0,
                    value: operationsStack.last().get("value"),
                  }),
                )
                operationsStack = operationsStack.setIn(
                  [operationsStack.size - 1, "used"],
                  operationsStack.getIn([operationsStack.size - 1, "used"]) + 1,
                )
              }
              i += 1
              visitedItemsIndexes = visitedItemsIndexes.set(
                index,
                visitedItemsIndexes.get(index) + 1,
              )
            }
          } else {
            traversalTree = traversalTree.pop()
            visitedItemsIndexes = visitedItemsIndexes.pop()
            if (visitedItemsIndexes.size) {
              visitedItemsIndexes = visitedItemsIndexes.set(
                index - 1,
                visitedItemsIndexes.get(index - 1) + 1,
              )
            }
          }
        } else if (currentItem.has("operands") && !currentItem.has("attribute_id")) {
          traversalTree = traversalTree.push(currentItem)
          visitedItemsIndexes = visitedItemsIndexes.push(-1)
          operationsStack = operationsStack.push(
            Map({
              maxUsages: currentItem.get("operands").size - 1,
              used: 0,
              value: currentItem.get("operation"),
            }),
          )
        }
      }
      if (operations.size === operationsBetweenConditions.size) {
        operations = operations.map((op, idx) =>
          op.set("level", operationsBetweenConditions.getIn([idx, "level"])),
        )
      }

      return {
        filters,
        operations,
      }
    } else {
      // single filter item
      const filter = constructFilter(conditionsOperation, 1)

      return {
        filters: List([filter]),
        operations: List(),
      }
    }
  }
}
