import requestFactory from "api/request"
import { Flag, OrderDir } from "types/util"
import { Tag, TagListResponse, TagPayload, TagReturnType } from "./tagTypes"

const tag = {
  list<T extends Flag = 0>(
    offset = 0,
    limit = 20,
    order_by: keyof Tag = "id",
    order_dir: OrderDir = "ASC",
    load_full_structure: T = 0 as T,
    name_filter = "",
  ): Promise<TagListResponse<T>> {
    return requestFactory("get", "/tags", {
      offset,
      limit,
      order_by,
      order_dir,
      load_full_structure,
      name_filter,
    })
  },
  create(data: TagPayload): Promise<{ tag: Tag }> {
    return requestFactory("post", "/tags", data)
  },
  retrieve<T extends Flag = 0>(
    tag_id: Tag["id"],
    load_full_structure: T = 0 as T,
  ): Promise<{ tag: TagReturnType<T> }> {
    return requestFactory("get", `/tags/${tag_id}`, { load_full_structure })
  },
  modify<T extends Flag = 0>(
    tag_id: Tag["id"],
    data: TagPayload,
    load_full_structure: T = 0 as T,
  ): Promise<{ tag: TagReturnType<T> }> {
    return requestFactory(
      "patch",
      `/tags/${tag_id}?load_full_structure=${load_full_structure}`,
      data,
    )
  },
  delete(tag_id: Tag["id"], load_full_structure: Flag = 0): Promise<void> {
    return requestFactory("delete", `/tags/${tag_id}?load_full_structure=${load_full_structure}`)
  },
}

export default tag
