import { createSelector } from "reselect"
import { Map } from "immutable"

const getGlobalSettings = state => state.globalSettings.get("data")

export const isGlobalSettingsFulfilled = state => state.globalSettings.get("isFulfilled")

export const getGlobalSettingsValueByKey = (state, key) => {
  const settingsRecord = state.globalSettings.get("data").find(settings => settings.key === key)
  if (settingsRecord) {
    return settingsRecord.value
  }
  return null
}

export const isGlobalSettingsFetching = state => state.globalSettings.get("isFetching")

export const getGlobalSettingsByKey = (state, key) => {
  const settingsRecord = state.globalSettings.get("data").find(settings => settings.key === key)
  if (settingsRecord) {
    return settingsRecord
  }
  return Map()
}

export const getGlobalSettingsMap = createSelector([getGlobalSettings], settings => settings)

export const getGlobalSettingsFormValues = createSelector([getGlobalSettings], settings => {
  let initialValues = Map()
  settings.forEach(settingItem => {
    initialValues = initialValues.withMutations(map =>
      map.set(settingItem.key, Map({ id: settingItem.id, value: settingItem.value })),
    )
  })
  return initialValues.toJS()
})
