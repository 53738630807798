import requestFactory from "api/request"
import { Flag, OrderDir } from "types/util"
import { Segment } from "resources/segment/segment/segmentTypes"
import {
  SegmentNote,
  SegmentNoteCreatePayload,
  SegmentNoteListResponse,
  SegmentNoteModifyPayload,
  SegmentNoteReturnType,
} from "./segmentNoteTypes"

const note = {
  list<T extends Flag = 0>(
    segmentId: Segment["id"],
    offset = 0,
    limit = 50,
    order_by: keyof SegmentNoteReturnType<T> = "modified",
    order_dir: OrderDir = "DESC",
    load_full_structure: T = 0 as T,
  ): Promise<SegmentNoteListResponse<T>> {
    return requestFactory("get", `/segments/${segmentId}/notes`, {
      offset,
      limit,
      order_by,
      order_dir,
      load_full_structure,
    })
  },
  create<T extends Flag = 0>(
    segmentId: Segment["id"],
    data: SegmentNoteCreatePayload,
    load_full_structure: T = 0 as T,
  ): Promise<{ segment_not: SegmentNoteReturnType<T> }> {
    return requestFactory(
      "post",
      `/segments/${segmentId}/notes?load_full_structure=${load_full_structure}`,
      data,
    )
  },
  retrieve<T extends Flag = 0>(
    segmentId: Segment["id"],
    noteId: SegmentNote["id"],
    load_full_structure: T = 0 as T,
  ): Promise<{ segment_not: SegmentNoteReturnType<T> }> {
    return requestFactory("get", `/segments/${segmentId}/notes/${noteId}`, {
      load_full_structure,
    })
  },
  modify<T extends Flag = 0>(
    segmentId: Segment["id"],
    noteId: SegmentNote["id"],
    data: SegmentNoteModifyPayload,
    load_full_structure: T = 0 as T,
  ): Promise<{ segment_not: SegmentNoteReturnType<T> }> {
    return requestFactory(
      "patch",
      `/segments/${segmentId}/notes/${noteId}?load_full_structure=${load_full_structure}`,
      data,
    )
  },
  delete(segmentId: Segment["id"], noteId: SegmentNote["id"]): Promise<void> {
    return requestFactory("delete", `/segments/${segmentId}/notes/${noteId}`)
  },
}

export default note
