import Tippy from "@tippyjs/react"
import { initSegmentNumbers } from "resources/segment/segmentNumbers/segmentNumbersActions"
import IconButton from "components/UI/elements/IconButton"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import { SocketContext } from "context/socket"
import { abbreviateNumber } from "helpers/number.helper"
import { timeAgoFormatter } from "helpers/timeAgo.helper"
import moment from "moment"
import React, { useCallback, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import ReactTimeago from "react-timeago"
import { getSegmentsNumbers } from "resources/segment/segmentNumbers/segmentNumbersSelectors"
import styles from "./SegmentNumbers.module.scss"

function percentageToArcCoordinates(percentage: number) {
  const angle = percentage * (Math.PI / 100)
  const dX = (-Math.cos(angle) + 1) / 2
  const dY = Math.sin(angle)
  return { dX, dY }
}

type SegmentNumbersProps = {
  hasUnsavedConditions: boolean
}

export default function SegmentNumbers({ hasUnsavedConditions }: SegmentNumbersProps) {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const socket = useContext(SocketContext)

  const {
    customers_total_count,
    conditions_results_count,
    data_load_start_time,
    data_load_end_time,
  } = useSelector(state => getSegmentsNumbers(state, id)).toJS()

  const segmentedPercentage =
    conditions_results_count === null || customers_total_count === null
      ? null
      : Math.round(100 * (conditions_results_count / customers_total_count))

  const percentageDisplayString =
    segmentedPercentage !== null &&
    `${
      segmentedPercentage === 0 && conditions_results_count !== 0
        ? "<1"
        : segmentedPercentage === 100 && conditions_results_count < customers_total_count
        ? ">99"
        : segmentedPercentage
    } %`

  // SVG for percentage arcs
  const { dX = 0, dY = 0 } =
    (segmentedPercentage && percentageToArcCoordinates(segmentedPercentage)) || {}

  const VIEWBOX_MARGIN = 15
  const ARC_HEIGHT = 140
  const ARC_WIDTH = 300

  const X1 = VIEWBOX_MARGIN
  const Y1 = VIEWBOX_MARGIN + ARC_HEIGHT
  const rX = ARC_WIDTH / 2
  const rY = ARC_HEIGHT
  const X2grey = VIEWBOX_MARGIN + ARC_WIDTH
  const Y2grey = VIEWBOX_MARGIN + ARC_HEIGHT
  const X2primary = VIEWBOX_MARGIN + ARC_WIDTH * dX
  const Y2primary = VIEWBOX_MARGIN + ARC_HEIGHT * (1 - dY)

  const greyArcPath = `M${X1} ${Y1} A${rX} ${rY} 0 0 1 ${X2grey} ${Y2grey}`
  const primaryArcPath = `M${X1} ${Y1} A${rX} ${rY} 0 0 1 ${X2primary} ${Y2primary}`
  const viewBox = `0 0 ${2 * VIEWBOX_MARGIN + ARC_WIDTH} ${2 * VIEWBOX_MARGIN + ARC_HEIGHT}`

  const refreshSegmentNumbers = useCallback(() => {
    dispatch(initSegmentNumbers(id, true))
    socket.emit("segment_counts", { segment_id: id, refresh_cache: true })
  }, [dispatch, id, socket])

  return (
    <div className={styles.container}>
      <IconButton
        iconName="sync-alt"
        iconStyle="far"
        color="primary"
        tooltip="Refresh segment numbers"
        withBackground
        className={styles.refreshIcon}
        disabled={data_load_end_time === null || hasUnsavedConditions}
        onClick={refreshSegmentNumbers}
      />
      <div className={styles.segmentedNumberWrapper}>
        <div className={styles.segmentedNumberInlineWrapper}>
          <span className={styles.segmentedNumber}>
            {conditions_results_count === null && (
              <LoadingIndicator className={styles.inlineLoadingIndicator} />
            )}

            {conditions_results_count !== null && hasUnsavedConditions && (
              <Tippy content="Save segment conditions to see segmentation numbers.">
                <span>N/A</span>
              </Tippy>
            )}

            {conditions_results_count !== null && !hasUnsavedConditions && (
              <span>{abbreviateNumber(conditions_results_count)}</span>
            )}
          </span>
          <span>out of </span>
          {customers_total_count === null && (
            <LoadingIndicator className={styles.inlineLoadingIndicator} />
          )}

          {customers_total_count !== null && hasUnsavedConditions && (
            <Tippy content="Save segment conditions to see segmentation numbers.">
              <span>N/A</span>
            </Tippy>
          )}

          {customers_total_count !== null && !hasUnsavedConditions && (
            <span>{abbreviateNumber(customers_total_count)}</span>
          )}
        </div>
        <div className={styles.dataRefreshed}>
          {data_load_end_time && (
            <>
              Data refreshed:&nbsp;
              <ReactTimeago
                key="finished"
                date={moment.utc(data_load_end_time).local().format("YYYY-MM-DD HH:mm:ss")}
                formatter={timeAgoFormatter({ seconds: "moment" })}
              />
            </>
          )}
          {data_load_end_time === null && data_load_start_time && (
            <>
              Data refresh started:&nbsp;
              <ReactTimeago
                key="inProgress"
                date={moment.utc(data_load_start_time).local().format("YYYY-MM-DD HH:mm:ss")}
                formatter={timeAgoFormatter({ seconds: "moment" })}
              />
            </>
          )}
        </div>
      </div>
      <div className={styles.percentageWrapper}>
        <div className={styles.percentage}>
          <div className={styles.percentageArcWrapper}>
            <svg viewBox={viewBox}>
              <path
                d={greyArcPath}
                strokeLinecap="round"
                strokeWidth="30"
                fill="none"
                stroke="#dddddd"
              />
              {!hasUnsavedConditions && segmentedPercentage && (
                <path
                  d={primaryArcPath}
                  strokeLinecap="round"
                  strokeWidth="30"
                  fill="none"
                  stroke="#fe7f66"
                />
              )}
            </svg>
          </div>

          {segmentedPercentage === null && (
            <LoadingIndicator className={styles.inlineLoadingIndicator} />
          )}

          {percentageDisplayString && hasUnsavedConditions && (
            <Tippy content="Save segment conditions to see segmentation numbers.">
              <span>N/A %</span>
            </Tippy>
          )}

          {percentageDisplayString && !hasUnsavedConditions && (
            <span>{percentageDisplayString}</span>
          )}
        </div>
        <div className={styles.percentageSubtitle}>of all customers</div>
      </div>
    </div>
  )
}
