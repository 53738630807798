import React, { PureComponent } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Record } from "immutable"
import { NavLink, withRouter } from "react-router-dom"

// helpers
import { getRoutePath } from "routes"

// selectors
import { getSegmentById } from "resources/segment/segment/segmentSelectors"
import SegmentNameLink from "./SegmentNameLink/SegmentNameLink"

class SegmentDetailSubmenu extends PureComponent {
  render() {
    const { segment, match } = this.props
    const isRegularSegment = match.path === "/segments/:id"
    const isSmartSegment = match.path === "/segments/smart/:id"
    const isFeaturedSegment = match.path === "/segments/featured/:id"

    if (!segment) {
      return null
    }

    return (
      <nav className="secondary-nav">
        {isFeaturedSegment && (
          <NavLink to={getRoutePath("segments.featured")}>Featured segments</NavLink>
        )}
        {isSmartSegment && <NavLink to={getRoutePath("segments.smart")}>Smart segments</NavLink>}
        {isRegularSegment && <NavLink to={getRoutePath("segments")}>Custom segments</NavLink>}
        <FontAwesomeIcon className="chevron-icon" icon={["far", "chevron-right"]} />
        <SegmentNameLink
          name={segment.name}
          routeTo={getRoutePath(
            isRegularSegment
              ? "segments.detail"
              : isSmartSegment
              ? "segments.smart.detail"
              : "segments.featured.detail",
            {
              id: segment.id,
            },
          )}
          activeClassName="is-active"
        />
      </nav>
    )
  }
}

SegmentDetailSubmenu.propTypes = {
  segment: PropTypes.instanceOf(Record),
}

const mapStateToProps = (state, ownProps) => {
  const { id: segmentId } = ownProps.match.params
  const segmentType =
    ownProps.match.path === "/segments/smart/:id"
      ? "smart"
      : ownProps.match.path === "/segments/featured/:id"
      ? "featured"
      : "regular"
  return {
    segment: getSegmentById(state, segmentId, segmentType),
  }
}

export default withRouter(connect(mapStateToProps, null)(SegmentDetailSubmenu))
