import { getSymbolStyle, getSymbolTree } from "components/ConditionBuilder/treeSymbols"
import { AndOrCondition, ConditionSymbol, ConditionTree } from "types/conditionTree"
import { isAndOrCondition } from "components/ConditionBuilder/utils"
import React, { Fragment } from "react"
import styles from "./ConditionTreeOverview.module.scss"

type LeafRendererProps = {
  symbolObject: ConditionSymbol
}

function LeafRenderer({ symbolObject: { text, color, negation } }: LeafRendererProps) {
  return (
    <div className={styles.symbol} style={getSymbolStyle(color)}>
      {negation && <div className={styles.negation}>NOT</div>}
      {text}
    </div>
  )
}

type BranchRendererProps = {
  branch: AndOrCondition<ConditionSymbol>
  isTopLevel?: boolean
}

function BranchRenderer({
  branch: { operator, operands },
  isTopLevel = false,
}: BranchRendererProps) {
  return (
    <>
      {!isTopLevel && <div className={styles.parens}>(</div>}
      {operands.map((operand, index) => (
        <Fragment key={index}>
          {isAndOrCondition(operand) ? (
            <BranchRenderer branch={operand} />
          ) : (
            <LeafRenderer symbolObject={operand} />
          )}
          {index < operands.length - 1 && <div className={styles.operator}>{operator}</div>}{" "}
        </Fragment>
      ))}
      {!isTopLevel && <div className={styles.parens}>)</div>}
    </>
  )
}

type ConditionTreeOverviewProps<T> = {
  conditionTree: ConditionTree<T> | null
}

export default function ConditionTreeOverview<T>({ conditionTree }: ConditionTreeOverviewProps<T>) {
  if (!conditionTree) {
    return null
  }

  const symbolTree = getSymbolTree(conditionTree)

  return (
    <div className={styles.container}>
      {isAndOrCondition(symbolTree) ? (
        <BranchRenderer branch={symbolTree} isTopLevel />
      ) : (
        <LeafRenderer symbolObject={symbolTree} />
      )}
    </div>
  )
}
