import { CUSTOMER_ATTRIBUTE } from "sharedConstants"
import { List, Map } from "immutable"
import CustomerAttributeCount from "models/customerAttributeCount.model"

const initialState = Map({
  isFulfilled: false,
  data: List(),
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${CUSTOMER_ATTRIBUTE.COUNTS.ACTION.LIST}_FULFILLED`:
      return state.set("isFulfilled", true).set(
        "data",
        List(
          payload.map(
            attr =>
              new CustomerAttributeCount({
                id: attr.id,
                name: attr.name,
                attribute_id: attr.attribute_id,
                count: attr.count,
              }),
          ),
        ),
      )
    default:
      return state
  }
}
