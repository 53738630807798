import React, { Component } from "react"
import { connect } from "react-redux"
import _isNil from "lodash/isNil"

import { modifyUser, deleteUser } from "resources/user/userActions"
import { getActiveUserById } from "resources/user/userSelectors"
import { getRoutePath } from "routes"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import { api } from "api"

import UserSettings from "components/UserSettings"

class UserDetailPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      roles: null,
    }
  }

  componentDidMount() {
    if (_isNil(this.props.user)) {
      this.props.history.push(getRoutePath("not-found"))
    } else {
      this.fetchAllRoles()
    }
  }

  fetchAllRoles = async () => {
    const caller = new AllResourceItemsFetcher()
    try {
      const data = await caller
        .setEndpointCall((offset, limit) => api.userRole.list(offset, limit))
        .setDataPath("roles")
        .run()
      this.setState({
        roles: data
          .filter(role => role.id !== 2)
          .map(role => ({ label: role.name, value: role.id, features: role.features })),
      })
    } catch {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user === false && _isNil(this.props.user)) {
      this.props.history.push(getRoutePath("not-found"))
    }
  }

  render() {
    const { user, modifyUser, deleteUser } = this.props
    const { roles } = this.state

    if (!user) {
      return null
    }

    return (
      <UserSettings
        user={user}
        modifyUser={modifyUser}
        editablePassword={!user.okta_enabled}
        backButton
        deleteUser={deleteUser}
        deletableUser
        roles={roles}
        editableRole
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: getActiveUserById(state, ownProps.match.params.id),
  }
}

UserDetailPage = connect(mapStateToProps, {
  modifyUser,
  deleteUser,
})(UserDetailPage)

export default UserDetailPage
