import { createSelector } from "reselect"
import { Map, List } from "immutable"
import { getDataSourceById } from "resources/dataSource/dataSourceSelectors"
import { identity, map, pipe, prop, sort, uniq } from "ramda"
import { ascend } from "utilities/comparators"

export const getEventsIsFulfilled = state => state.events.get("isFulfilled")
export const getEventsIsFetching = state => state.events.get("isFetching")
export const getEventsData = (state, showHidden = false) => {
  if (showHidden) {
    return state.events.get("data")
  } else {
    return state.events.get("data").filterNot(event => event.is_hidden)
  }
}

export const getEventsArrayForSelect = state => {
  return sort(
    ascend(prop("label")),
    state.events
      .get("data")
      .map(event => ({
        label: `${getDataSourceById(state, event.source_id)?.name}: ${event.name}`,
        value: event.id,
        hidden: event.is_hidden === 1,
        source_id: event.source_id,
      }))
      .toArray(),
  )
}

export const getEventTypesForSelect = createSelector(
  getEventsData,
  pipe(
    list => list.toJS(),
    map(prop("type")),
    uniq,
    sort(ascend(identity)),
    map(type => ({ label: type, value: type })),
  ),
)

export const getEventsMappedById = createSelector(getEventsData, events => {
  return Map().withMutations(map => {
    events.forEach(event => {
      map.set(event.id, event)
    })
  })
})

export const getEventById = (state, id) =>
  state.events.get("data").size > 0 ? state.events.get("data").find(event => event.id === id) : null

export const getEventsMappedBySourceAndType = createSelector(getEventsData, events => {
  return Map().withMutations(map => {
    events.forEach(event => {
      map.set(`${event.source_id}-${event.type}`, event)
    })
  })
})

export const getEventsMappedBySourceId = createSelector(getEventsData, events => {
  return Map().withMutations(map => {
    events.forEach(event => {
      if (map.has(event.source_id)) {
        map.set(event.source_id, map.get(event.source_id).push(event))
      } else {
        map.set(event.source_id, List([event]))
      }
    })
  })
})

export const getEventsFilterFormInitialValues = createSelector(getEventsData, events => {
  return Map()
    .withMutations(map => {
      events.forEach(event => {
        map.setIn([event.source_id, event.id], true)
      })
    })
    .toJS()
})

export const getEventsSourceIds = createSelector(getEventsData, events => {
  const sources = []
  events.forEach(event => {
    if (!sources.includes(event.source_id)) {
      sources.push(event.source_id)
    }
  })
  return List(sources)
})
