import { CUSTOMER_EVENT } from "sharedConstants"
import { List, Map } from "immutable"
import CustomerEventCount from "models/customerEventCount.model"

const initialState = Map({
  isFulfilled: false,
  data: List(),
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${CUSTOMER_EVENT.COUNTS.ACTION.LIST}_FULFILLED`:
      return state.set("isFulfilled", true).set(
        "data",
        List(
          payload.map(
            evt =>
              new CustomerEventCount({
                id: evt.id,
                name: evt.name,
                event_id: evt.event_id,
                count: evt.count,
              }),
          ),
        ),
      )
    default:
      return state
  }
}
