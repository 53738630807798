import _trim from "lodash/trim"
const SI_SYMBOL = [
  "",
  "k",
  "M",
  "B",
  "T",
  "Qa",
  "Qi",
  "Sx",
  "Sp",
  "Oc",
  "No",
  "Dc",
  "Ud",
  "Dd",
  "Td",
]
const ALT_SI_SYMBOL = [
  "",
  "thousand",
  "million",
  "billion",
  "trillion",
  "quadrillion",
  "quintillion",
  "sextillion",
  "septillion",
  "octillion",
  "nonillion",
  "decillion",
  "undecillion",
  "duodecillion",
  "tredecillion",
]

export const abbreviateNumber = (number, alt = false) => {
  if (typeof number !== "number") return number
  const tier = (Math.log10(number) / 3) | 0
  if (tier === 0) return Math.round(number * 100) / 100
  const suffix = alt ? ALT_SI_SYMBOL[tier] : SI_SYMBOL[tier]
  const scale = Math.pow(10, tier * 3)
  const scaled = number / scale
  return _trim(`${Number(scaled.toFixed(1)).toLocaleString()}${alt ? " " + suffix : suffix}`)
}

export const formatSearchNumber = number => {
  return String(number).replace(/(.)(?=(\d{3})+$)/g, "$1 ")
}
