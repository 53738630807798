import styles from "./EmbeddedWebBannersList.module.scss"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import { useHistory } from "react-router-dom"
import { hasAccess } from "helpers/authenticatedUser.helper"
import React, { useState } from "react"
import Paper from "components/UI/elements/Paper"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"
import Table, {
  RowMessage,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  SortButton,
} from "components/UI/elements/Table"
import ToggleButton from "components/UI/elements/ToggleButton"
import {
  EmbeddedWebBanner,
  EmbeddedWebBannerSort,
} from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { format } from "date-fns"
import { DATEFNS } from "sharedConstants"
import { assoc } from "ramda"
import { OrderDir } from "types/util"
import { shortenText } from "helpers/string.helper"
import Tippy from "@tippyjs/react"
import create from "zustand"
import {
  useFetchAllEmbeddedWB,
  useDeleteEmbeddedWB,
  useModifyEmbeddedWB,
} from "resources/webBanner/embeddedWebBanner/embeddedWBQueries"
import SearchField from "components/UI/elements/SearchField"
import { User } from "resources/user/userTypes"
import useTypedSelector from "hooks/useTypedSelector"
import { getAllUsersMap } from "resources/user/userSelectors"

type EmbeddedWBSelectionState = {
  orderBy: EmbeddedWebBannerSort
  orderDir: OrderDir
  searchTerm: string
  setSort: (orderBy: EmbeddedWebBannerSort) => void
  setSearchTerm: (searchTerm: string) => void
}

const useStore = create<EmbeddedWBSelectionState>(set => ({
  orderBy: "name",
  orderDir: "ASC",
  searchTerm: "",
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
}))

export default function EmbeddedWebBannersList() {
  const history = useHistory()
  const allUsers: Record<User["id"], User> = useTypedSelector(state => getAllUsersMap(state)).toJS()
  const { orderBy, orderDir, searchTerm, setSort, setSearchTerm } = useStore()

  const { data: webBanners, isLoading } = useFetchAllEmbeddedWB({ searchTerm, orderBy, orderDir })
  const deleteMutation = useDeleteEmbeddedWB()
  const modifyMutation = useModifyEmbeddedWB()

  const [deleteModal, setDeleteModal] = useState({
    open: false,
    banner: null as EmbeddedWebBanner | null,
  })

  const toggleBannerDisabled = (webBanner: EmbeddedWebBanner) =>
    modifyMutation.mutate({ id: webBanner.id, data: { disabled: !webBanner.disabled } })

  const closeDeleteBannerModal = () => setDeleteModal(assoc("open", false))

  const deleteBanner = () =>
    deleteMutation.mutate(
      { id: deleteModal.banner!.id },
      {
        onSuccess: () => {
          closeDeleteBannerModal()
        },
      },
    )

  return (
    <div className={styles.wrapper}>
      <PaperHeader titleText="Embedded Web Banners" size="small" className={styles.header}>
        <Button
          color="primary"
          type="button"
          size="small"
          onClick={() => {
            history.push(getRoutePath("personalization.embedded-web-banners.create"))
          }}
          disabled={!hasAccess.webBanners.edit()}
          className={styles.buttonRight}
        >
          + Create Embedded Web Banner
        </Button>
      </PaperHeader>
      <Paper className={styles.bannersBox}>
        <div className={styles.formHeader}>
          <h3>Embedded Web Banners</h3>
          <div className={styles.searchField}>
            <SearchField
              input={{
                value: searchTerm,
                onChange: setSearchTerm,
              }}
              placeholder="Search for name / element ID"
              onClear={() => setSearchTerm("")}
              data-testid="ewb-search-field"
            />
          </div>
        </div>
        {isLoading ? (
          <LoadingIndicator />
        ) : webBanners?.length === 0 ? (
          <RowMessage>No banners found.</RowMessage>
        ) : (
          <Table>
            <Thead>
              <Th>
                <SortButton
                  column="name"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("name")}
                  label="Name"
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="element_id"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("element_id")}
                  label="Element ID"
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="priority"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("priority")}
                  label="Priority"
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="disabled"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("disabled")}
                  label="Active"
                />
              </Th>
              <Th textAlignRight>
                <SortButton
                  column="created"
                  orderBy={orderBy}
                  orderDir={orderDir}
                  onClick={() => setSort("created")}
                  label="Modified at"
                />
              </Th>
              <Th>&nbsp;</Th>
            </Thead>
            <Tbody>
              {webBanners?.map(webBanner => (
                <React.Fragment key={webBanner.id}>
                  <Tr>
                    <Td
                      textBigger
                      textBlack
                      textBold
                      data-testid="td-ewb-name"
                      className={styles.nameCell}
                    >
                      {webBanner.name}
                    </Td>
                    <Td textAlignRight>
                      {webBanner.element_id.length > 30 ? (
                        <Tippy
                          content={webBanner.element_id}
                          placement="bottom"
                          className={styles.elementIdTooltip}
                        >
                          {<span>{shortenText(webBanner.element_id, 30)}</span>}
                        </Tippy>
                      ) : (
                        webBanner.element_id
                      )}
                    </Td>
                    <Td textAlignRight className={styles.priorityColumn}>
                      {webBanner.settings.priority}
                    </Td>
                    <Td textAlignRight className={styles.toggleColumn}>
                      <ToggleButton
                        value={!webBanner.disabled}
                        handleToggle={() => toggleBannerDisabled(webBanner)}
                        disabled={!hasAccess.webBanners.edit()}
                      />
                    </Td>
                    <Td textAlignRight className={styles.modifiedAtColumn}>
                      <span className={styles.modifiedAt}>
                        {format(new Date(`${webBanner.created}Z`), DATEFNS.DATETIME_FORMAT)}
                      </span>
                      {webBanner.user_id && (
                        <span className={styles.modifiedBy}>
                          by {allUsers[webBanner.user_id]?.name}
                        </span>
                      )}
                    </Td>
                    <Td textAlignRight className={styles.actionsColumn}>
                      {/* @ts-ignore */}
                      <IconButton
                        color={COLOR.BLACK}
                        size={SIZE.TAG}
                        onClick={() => {
                          history.push(
                            getRoutePath("personalization.embedded-web-banners.detail", {
                              id: webBanner.id,
                            }),
                          )
                        }}
                        withBackground
                        iconName="pencil-alt"
                        tooltip="Edit"
                      />
                      {/* @ts-ignore */}
                      <IconButton
                        color={COLOR.RED}
                        size={SIZE.TAG}
                        onClick={() => {
                          setDeleteModal({
                            open: true,
                            banner: webBanner,
                          })
                        }}
                        withBackground
                        iconName="trash-alt"
                        tooltip="Delete"
                        className={styles.actionButtonMargin}
                        disabled={!hasAccess.webBanners.edit()}
                        data-testid="cta-delete-ewb"
                      />
                    </Td>
                  </Tr>
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        )}
      </Paper>
      <ConfirmModal
        title="Are you sure?"
        open={deleteModal.open}
        type="delete"
        action="delete"
        what="embedded web banner"
        item={deleteModal.banner?.name}
        handleClose={closeDeleteBannerModal}
        handleConfirm={deleteBanner}
        isLoading={deleteMutation.isLoading}
      />
    </div>
  )
}
