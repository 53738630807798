import { api } from "api"
import { EVENT } from "sharedConstants"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

export const fetchEventsList = (showHidden = 0) => async dispatch => {
  const caller = new AllResourceItemsFetcher()

  return dispatch({
    type: EVENT.ACTION.LIST,
    payload: {
      promise: (async () => {
        const data = await caller
          .setEndpointCall((offset, limit, loadFullStructure) =>
            api.event.list(offset, limit, "name", "ASC", loadFullStructure, showHidden),
          )
          .setLoadFullStructure(0)
          .setDataPath("events")
          .run()

        return data
      })(),
    },
  })
}

export const modifyEvent = (id, data) => async dispatch =>
  dispatch({
    type: EVENT.ACTION.MODIFY,
    payload: {
      promise: (async () => {
        const response = await api.event.modify(id, data)
        return response.event
      })(),
    },
  })
