import { useInfiniteQuery } from "@tanstack/react-query"
import { api } from "api"
import {
  FeaturedSegmentListResponse,
  SegmentListResponse,
  SmartSegmentListResponse,
} from "./segmentTypes"

const LIMIT = 50

export const useFetchRegularSegments = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<SegmentListResponse<0>, string>(
    ["fetchRegularSegments", searchTerm],
    ({ pageParam }) =>
      api.segment.list(pageParam, LIMIT, "name", "ASC", searchTerm, [], 0, 1, 1, 1),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.segments) : [] }
}

export const useFetchFeaturedSegments = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<FeaturedSegmentListResponse<0>, string>(
    ["fetchFeaturedSegments", searchTerm],
    ({ pageParam }) => api.featuredSegment.list(pageParam, LIMIT, searchTerm, "name", "ASC", [], 0),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.featured_segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.featured_segments) : [] }
}

export const useFetchSmartSegments = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<SmartSegmentListResponse<0>, string>(
    ["fetchSmartSegments"],
    ({ pageParam }) => api.smartSegment.list(pageParam, LIMIT, "name", "ASC", 0),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.prebuilt_segments.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      // BE doesn't offer possibility to filter by name
      select: !searchTerm
        ? undefined
        : ({ pages, ...restPages }) => ({
            ...restPages,
            pages: pages.map(({ prebuilt_segments, ...restSegment }) => ({
              ...restSegment,
              prebuilt_segments: prebuilt_segments.filter(({ name }) =>
                name.toLowerCase().includes(searchTerm.toLowerCase()),
              ),
            })),
          }),
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.prebuilt_segments) : [] }
}
