import {
  deleteFeaturedSegmentFulfilledType,
  FeaturedSegmentsAction,
  FEATURED_SEGMENTS,
  fetchFeaturedSegmentsFulfilledType,
  modifyFeaturedSegmentFulfilledType,
  retrieveFeaturedSegmentFulfilledType,
} from "./segmentActions"
import { FeaturedSegmentListResponse } from "./segmentTypes"

const initialState = {
  initialListFetching: true,
  featuredSegments: [] as FeaturedSegmentListResponse<0>["featured_segments"],
  selectionSettings: {} as FeaturedSegmentListResponse<0>["selection_settings"],
  hasMorePages: true,
}

type FeaturedSegmentsState = typeof initialState

export default function featuredSegments(
  state: FeaturedSegmentsState = initialState,
  action: FeaturedSegmentsAction,
) {
  switch (action.type) {
    case fetchFeaturedSegmentsFulfilledType: {
      if (action.payload.selection_settings.offset === 0) {
        return {
          ...state,
          initialListFetching: false,
          featuredSegments: action.payload.featured_segments,
          selectionSettings: action.payload.selection_settings,
          hasMorePages: action.payload.featured_segments.length === FEATURED_SEGMENTS.LOADING_LIMIT,
        }
      } else {
        return {
          ...state,
          featuredSegments: [...state.featuredSegments, ...action.payload.featured_segments],
          selectionSettings: action.payload.selection_settings,
          hasMorePages: action.payload.featured_segments.length === FEATURED_SEGMENTS.LOADING_LIMIT,
        }
      }
    }
    case retrieveFeaturedSegmentFulfilledType: {
      if (state.initialListFetching) {
        return {
          ...state,
          featuredSegments: [action.payload.segment],
        }
      } else {
        const isAlreadyInList = state.featuredSegments.find(
          featuredSegment => featuredSegment.id === action.payload.segment.id,
        )
        if (isAlreadyInList) {
          return {
            ...state,
            featuredSegments: state.featuredSegments.map(featuredSegment =>
              featuredSegment.id === action.payload.segment.id
                ? action.payload.segment
                : featuredSegment,
            ),
          }
        } else {
          return {
            ...state,
            featuredSegments: [...state.featuredSegments, action.payload.segment],
          }
        }
      }
    }
    case modifyFeaturedSegmentFulfilledType: {
      return {
        ...state,
        featuredSegments: state.featuredSegments.map(featuredSegment =>
          featuredSegment.id === action.payload.segment.id
            ? action.payload.segment
            : featuredSegment,
        ),
      }
    }
    case deleteFeaturedSegmentFulfilledType: {
      return {
        ...state,
        featuredSegments: state.featuredSegments.filter(
          featuredSegment => featuredSegment.id !== action.payload,
        ),
      }
    }
    default:
      return state
  }
}
