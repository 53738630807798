import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import _round from "lodash/round"
import { getPercentageText } from "helpers/insight.helper"
import { abbreviateNumber } from "helpers/number.helper"

import "./HorizontalChart.scss"
import Tippy from "@tippyjs/react"

class HorizontalChart extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      labelClass: null,
      maxLabelLength: 0,
    }
    this.flexDivRef = React.createRef()
  }

  componentDidMount() {
    const { size } = this.props
    const flexDiv = this.flexDivRef.current
    const height = flexDiv.clientHeight
    if (height > 20) {
      this.setState({
        labelClass: "absolute",
        maxLabelLength: size === "small" ? 16 : 52,
      })
    } else {
      this.setState({
        labelClass: "above",
        maxLabelLength: size === "small" ? 35 : 66,
      })
    }
  }

  _getBarWidth = percentage => {
    if (percentage < 1 && percentage !== 0) {
      return 1
    } else if (percentage > 99 && percentage !== 100) {
      return 99
    }
    return _round(percentage, 2)
  }

  _shortenTextInMiddleWithTooltip = text => {
    const { maxLabelLength } = this.state
    if (text.length <= maxLabelLength) {
      return text
    }
    const shortening = (maxLabelLength - 5) / 2
    const start = text.substr(0, Math.floor(shortening))
    const end = text.substr(-Math.ceil(shortening))
    return (
      <React.Fragment>
        {start}
        <Tippy content={text} placement="bottom">
          <span className="shortening">[...]</span>
        </Tippy>

        {end}
      </React.Fragment>
    )
  }

  render() {
    const {
      outOf,
      attributePercentage,
      label,
      percentage,
      value,
      size,
      color = "primary",
      className,
    } = this.props
    const { labelClass } = this.state
    const valuePercentage = (value / outOf) * 100

    return (
      <div className={`insight-horizontal-chart ${size} ${color} ${className ? className : ""}`}>
        {label && (
          <div className={`label ${labelClass ? labelClass : ""}`}>
            {this._shortenTextInMiddleWithTooltip(label)}
          </div>
        )}
        <div className="flex" ref={this.flexDivRef}>
          <div className="value-info" ref={this.valueInfoRef}>
            {getPercentageText(valuePercentage, 2)} ({abbreviateNumber(value)} out of{" "}
            {abbreviateNumber(outOf)})
          </div>
          <div className="bar" style={{ width: `${this._getBarWidth(percentage)}%` }} />
        </div>
        <div className="attribute-line">
          <div
            className="percentage-line"
            style={{ width: `${this._getBarWidth(attributePercentage)}%` }}
          ></div>
        </div>
      </div>
    )
  }
}

HorizontalChart.defaultProps = {
  size: "small",
}

HorizontalChart.propTypes = {
  outOf: PropTypes.number.isRequired,
  label: PropTypes.string,
  percentage: PropTypes.number.isRequired,
  attributePercentage: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  size: PropTypes.string,
  tooltipId: PropTypes.number,
}

export default HorizontalChart
