import React from "react"
import PropTypes from "prop-types"
import { Line } from "react-chartjs-2"
import moment from "moment"
import { Map } from "immutable"
import _isFinite from "lodash/isFinite"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"

// constants, helpers
import { MOMENT, COLOR } from "sharedConstants"
import { hexToRgba } from "helpers/chartjs.helper"
import { abbreviateNumber } from "helpers/number.helper"

const options = {
  maintainAspectRatio: false,
  legend: false,
  layout: {
    padding: {
      top: 10,
      right: 3,
      bottom: 3,
      left: 3,
    },
  },
  tooltips: {
    enabled: true,
    mode: "index",
    callbacks: {
      label: function (tooltipItems, data) {
        if (_isFinite(tooltipItems.yLabel)) {
          return `${_get(
            data,
            `datasets[${tooltipItems.datasetIndex}].label`,
            "Count",
          )}: ${abbreviateNumber(tooltipItems.yLabel)}`
        }
        return tooltipItems.yLabel
      },
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          maxTicksLimit: 3,
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
          callback: function (value) {
            return abbreviateNumber(value)
          },
        },
      },
    ],
  },
}

const EventsOverTimeChart = ({ rawData, sources, dataResolution, className, startDate }) => {
  let chartStart = moment().format(
    dataResolution === "hour" ? MOMENT.DB_DATETIME_FORMAT : MOMENT.DB_DATE_FORMAT,
  )
  let chartEnd = moment(startDate).format(
    dataResolution === "hour" ? MOMENT.DB_DATETIME_FORMAT : MOMENT.DB_DATE_FORMAT,
  )
  rawData.forEach(sourceData => {
    if (!_isEmpty(sourceData.events_per_date)) {
      const firstEvent = sourceData.events_per_date[0]
      const lastEvent = sourceData.events_per_date[sourceData.events_per_date.length - 1]
      if (firstEvent.date < chartStart) {
        chartStart = firstEvent.date
      }
      if (lastEvent.date > chartEnd) {
        chartEnd = lastEvent.date
      }
    }
  })

  const labels = []
  const normalizedData = rawData.map((sourceData, index) => {
    const eventsPerDate = []
    let dateIt = chartStart
    let arrayIterator = 0
    while (dateIt <= chartEnd) {
      if (index === 0) {
        // fill labels
        switch (dataResolution) {
          case "day":
          case "week":
            labels.push(moment(dateIt).format(MOMENT.DATE_FORMAT))
            break
          case "month":
            labels.push(moment(dateIt).format("MMM YYYY"))
            break
          case "year":
            labels.push(moment(dateIt).format("YYYY"))
            break
          default:
            // hour
            labels.push(moment(dateIt).format(MOMENT.DATETIME_FORMAT))
        }
      }

      if (!_isEmpty(sourceData.events_per_date)) {
        const entry = sourceData.events_per_date[arrayIterator]
        if (entry.date === dateIt) {
          eventsPerDate.push(entry)
          arrayIterator =
            arrayIterator < sourceData.events_per_date.length - 1
              ? arrayIterator + 1
              : arrayIterator
        } else {
          eventsPerDate.push({
            date: dateIt,
            count: 0,
          })
        }
      }
      switch (dataResolution) {
        case "day":
          dateIt = moment(dateIt).add(1, "day").format(MOMENT.DB_DATE_FORMAT)
          break
        case "week":
          dateIt = moment(dateIt).add(7, "day").format(MOMENT.DB_DATE_FORMAT)
          break
        case "month":
          dateIt = moment(dateIt).add(1, "month").format(MOMENT.DB_DATE_FORMAT)
          break
        case "year":
          dateIt = moment(dateIt).add(1, "year").format(MOMENT.DB_DATE_FORMAT)
          break
        default:
          // hour
          dateIt = moment(dateIt).add(1, "hour").format(MOMENT.DB_DATETIME_FORMAT)
      }
    }
    return {
      source_id: sourceData.source_id,
      events_per_date: eventsPerDate,
    }
  })

  const data = {
    labels,
    datasets: normalizedData.map(dataset => {
      const source = sources.get(dataset.source_id)
      let color = COLOR["primary"]
      if (source) {
        color = COLOR[source.getIn(["frontend_settings", "color"])]
      }

      return {
        label: source ? source.name : "Undefined source",
        backgroundColor: hexToRgba(color, 0.5),
        borderColor: color,
        borderWidth: 1,
        fill: true,
        pointRadius: 2,
        pointBackgroundColor: color,
        data: dataset.events_per_date.map(obj => obj.count),
      }
    }),
  }

  return (
    <div className={`customer-events-per-date-chart ${className}`}>
      <Line data={data} options={options} />
    </div>
  )
}

EventsOverTimeChart.propTypes = {
  rawData: PropTypes.array.isRequired,
  dataResolution: PropTypes.string,
  className: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  sources: PropTypes.instanceOf(Map).isRequired,
}

export default EventsOverTimeChart
