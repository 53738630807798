import React from "react"
import DonutChart from "react-minimal-pie-chart"
import _round from "lodash/round"
import PropTypes from "prop-types"
import { COLOR } from "sharedConstants"
import { hexToRgba } from "helpers/chartjs.helper"

import "./AttributePercentage.scss"

const AttributePercentageChart = ({ percentage, color }) => {
  const _getPercentageText = value => {
    if (value < 1 && value !== 0) {
      return "<1 %"
    } else if (value > 99 && value !== 100) {
      return ">99%"
    } else {
      return `${_round(value)}%`
    }
  }

  let chartPercentage = percentage
  if (chartPercentage > 99 && chartPercentage !== 100) {
    chartPercentage = 99
  } else if (chartPercentage < 1 && chartPercentage !== 0) {
    chartPercentage = 1
  }
  const percentageData = [
    { title: "Known", value: _round(chartPercentage), color: hexToRgba(COLOR[color], 0.6) },
  ]
  if (percentage !== 100) {
    percentageData.push({
      title: "Unknown",
      value: 100 - _round(chartPercentage),
      color: "#999999",
    })
  }

  let labelContent = <div />
  if (percentage === 100) {
    labelContent = <span className={`single-label known ${color}`}>Known 100%</span>
  } else if (percentage === 0) {
    labelContent = <span className={`single-label unknown grey`}>Unknown 100%</span>
  } else {
    labelContent = (
      <React.Fragment>
        <span className={`double-label known ${color}`}>
          Known {_getPercentageText(percentage)}
        </span>
        <span className="double-label unknown grey">
          Unknown {_getPercentageText(100 - percentage)}
        </span>
      </React.Fragment>
    )
  }

  return (
    <div
      className={`progress-bar ${color}`}
      data-content={percentage < 1 && percentage > 0 ? "<1%" : `${_round(percentage, 0)}%`}
    >
      <DonutChart
        animate={true}
        animationDuration={800}
        animationEasing="ease-out"
        data={percentageData}
        label={false}
        lengthAngle={360}
        lineWidth={15}
        radius={50}
        rounded
        startAngle={-90}
        viewBoxSize={[140, 140]}
        paddingAngle={
          percentage === 0 || (percentage === 100 && percentageData.length === 1) ? 0 : 15
        }
      />
      <div className="labels">{labelContent}</div>
    </div>
  )
}

AttributePercentageChart.propTypes = {
  percentage: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

export default AttributePercentageChart
