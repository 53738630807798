import { createSelector } from "reselect"

export const getLabelsMap = state => state.labels.data

export const getLabelsArray = createSelector([getLabelsMap], labels =>
  Object.keys(labels)
    .map(k => labels[k])
    .sort((a, b) => a.name.localeCompare(b.name)),
)

export const areLabelsFetching = state => state.labels.isFetching

export const areLabelsFulfilled = state => state.labels.isFulfilled

export const getLabelsArrayForSelect = createSelector([getLabelsArray], labels =>
  labels.map(label => ({ value: label.id, label: label.name })),
)
