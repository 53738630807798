import { SEGMENT_ANALYTICS_FUNCTIONS } from "sharedConstants"
import _round from "lodash/round"
import { List } from "immutable"
import store from "../store"
import { modifyAuthUser } from "actions/authenticatedUser.action"
import { showToast } from "actions/toast.action"

export const getFunctionName = (functionValue, valueType) => {
  if (valueType && ["date", "datetime"].includes(valueType)) {
    if (functionValue === SEGMENT_ANALYTICS_FUNCTIONS.EARLIEST.value) {
      return SEGMENT_ANALYTICS_FUNCTIONS.EARLIEST.label
    } else if (functionValue === SEGMENT_ANALYTICS_FUNCTIONS.LATEST.value) {
      return SEGMENT_ANALYTICS_FUNCTIONS.LATEST.label
    }
  }
  return SEGMENT_ANALYTICS_FUNCTIONS[functionValue.toUpperCase()].label
}

export const getPercentageText = (percentage, round = 0) => {
  let low, high
  switch (round) {
    case 1: {
      low = 1
      high = 99.9
      break
    }
    case 2: {
      low = 1
      high = 99.99
      break
    }
    default: {
      low = 1
      high = 99
    }
  }
  if (percentage < low && percentage !== 0) {
    return `<${low}%`
  } else if (percentage > high && percentage !== 100) {
    return `>${high}%`
  } else {
    return `${_round(percentage, round)}%`
  }
}

export const reorderStickyInsights = (insights, stickyInsightsIds) => {
  if (stickyInsightsIds.length !== 0 && List.isList(insights)) {
    const stickyOnes = []
    stickyInsightsIds.forEach(id => {
      const insight = insights.find(i => i.id === id)
      if (insight) {
        stickyOnes.push(insight)
      }
    })
    const filteredOut = insights.filterNot(i => stickyInsightsIds.includes(i.id))
    return List(stickyOnes).concat(filteredOut)
  } else {
    return insights
  }
}

export const getStickyInsightsIds = frontendSettings => {
  if (!frontendSettings || !frontendSettings.stickyInsightsIds) {
    return []
  } else {
    return frontendSettings.stickyInsightsIds
  }
}

export const toggleInsightsSticky = insight => () => {
  const authUser = store.getState().authenticatedUser.data
  let stickyInsightsIds = getStickyInsightsIds(authUser.frontend_settings)
  let method
  if (stickyInsightsIds.includes(insight.id)) {
    // unsticky
    method = "unsticky"
    stickyInsightsIds = stickyInsightsIds.filter(id => id !== insight.id)
  } else {
    // sticky
    method = "sticky"
    stickyInsightsIds = [...stickyInsightsIds, insight.id]
  }

  store
    .dispatch(
      modifyAuthUser(
        authUser.id,
        authUser.frontend_settings
          ? { frontend_settings: { ...authUser.frontend_settings, stickyInsightsIds } }
          : { frontend_settings: { stickyInsightsIds } },
      ),
    )
    .then(() => {
      store.dispatch(
        showToast(method === "sticky" ? "Added to favorites." : "Removed from favorites."),
      )
    })
}
