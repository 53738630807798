import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import ReportingPage from "./Reporting/Reporting"

export default function Reporting() {
  return (
    <Switch>
      <Route
        path={getRoutePath("reporting")}
        exact
        render={() => <Redirect to={getRoutePath("reporting.reporting")} />}
      />
      <Route path={getRoutePath("reporting.reporting")} component={ReportingPage} />
    </Switch>
  )
}
