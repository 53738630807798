import React from "react"

// ui components
import Paper from "components/UI/elements/Paper"

import notFoundImageUrl from "./404.png"
import "./NotFound.scss"

const NotFoundPage = () => (
  <Paper className="wrapper not-found">
    <img src={notFoundImageUrl} alt="Not Found" className="left-side" />
    <div className="right-side">
      <h1>Ooops... something went wrong.</h1>
      <h2>Seems like the page you're looking for doesn't exist.</h2>
    </div>
  </Paper>
)

export default NotFoundPage
