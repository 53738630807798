import React, { Component } from "react"
import { reduxForm, Form, Field, formValueSelector, FieldArray } from "redux-form"
import PropTypes from "prop-types"
import TextField from "components/UI/elements/TextField"
import { required, pythonVariable } from "helpers/validators.helper"
import SelectField from "components/UI/elements/SelectField"
import { connect } from "react-redux"
import { getDataSourcesArrayForSelect } from "resources/dataSource/dataSourceSelectors"
import ToggleIconSwitchField from "components/UI/elements/ToggleSwitch/ToggleIconSwitchField"
import _isNil from "lodash/isNil"
import _toInteger from "lodash/toInteger"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import Button from "components/UI/elements/Button/Button"
import { fetchLabelsList } from "actions/label.action"
import { areLabelsFulfilled, getLabelsArray } from "selectors/label.selector"
import _noop from "lodash/noop"
import Tag from "components/UI/elements/Tag"
import TagPicker from "components/UI/components/TagPicker"
import { List } from "immutable"

import "./AttributeForm.scss"
import {
  getEventsData,
  getEventsIsFulfilled,
  getEventTypesForSelect,
} from "selectors/event.selector"
import ExpandableAceEditor from "components/UI/components/ExpandableAceEditor/ExpandableAceEditor"
import AceEditor from "react-ace"
import classNames from "classnames"
import { fetchEventsList } from "actions/event.action"
import { equals, filter, map, pipe, prop, sort, uniqBy } from "ramda"
import ToggleButton from "components/UI/elements/ToggleButton"
import { ascend } from "utilities/comparators"

export const ATTRIBUTE_DATA_TYPES = [
  { value: "bool", label: "boolean" },
  { value: "compound", label: "compound" },
  { value: "date", label: "date" },
  { value: "datetime", label: "datetime" },
  { value: "float", label: "float" },
  { value: "int", label: "integer" },
  { value: "string", label: "string" },
]

export const COMPOUND_ATTRIBUTE_DATA_TYPES = ATTRIBUTE_DATA_TYPES.filter(
  type => type.value !== "compound",
)

export const DEFINITION_CALC_TYPE_OPTIONS = [
  { value: "avg", label: "Average" },
  { value: "first", label: "First" },
  { value: "last", label: "Last" },
  { value: "most_frequent", label: "Most frequent" },
  { value: "least_frequent", label: "Least frequent" },
  { value: "min", label: "Minimum" },
  { value: "max", label: "Maximum" },
  { value: "list", label: "List" },
  { value: "num_of_unique", label: "No. of unique" },
  { value: "sum", label: "Sum" },
  { value: "value", label: "Value" },
]

class AttributeForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDefinitionShown: props.initialValues.isDefinitionShown,
    }
  }

  componentDidMount() {
    const { areLabelsFulfilled, fetchLabelsList, areEventsFulfilled, fetchEventsList } = this.props
    if (!areLabelsFulfilled) {
      fetchLabelsList().catch(_noop)
    }
    if (!areEventsFulfilled) {
      fetchEventsList().catch(_noop)
    }
  }

  submitForm = values => {
    const { disabledFields = [], onSubmit } = this.props
    const data = {
      name: values.name,
      description: values.description,
      tag_ids: values.tag_ids ? values.tag_ids : [],
    }

    if (!disabledFields.includes("id")) {
      data.id = values.id
    }
    if (!disabledFields.includes("source_id")) {
      data.source_id = values.source_id.value
    }
    if (!disabledFields.includes("is_unique")) {
      data.is_unique = _toInteger(values.is_unique)
    }

    if (!disabledFields.includes("data_type")) {
      if (values.data_type.value !== "compound") {
        data.data_type = values.data_type.value
      } else {
        // transform to compound
        const dimensions = values.compound_dimensions.map(dimension => {
          return [dimension.id, dimension.name, dimension.data_type.value]
        })
        data.data_type = `compound(${JSON.stringify(dimensions)})`
      }
    }

    if (this.state.isDefinitionShown) {
      const { id, type, query, sources, types, versions, ...rest } = values.definition

      data.definition = { id: values.id }

      if (values.definitionType === "predefined") {
        data.definition.sources = sources?.map(({ value }) => value) ?? []
        data.definition.types = types?.map(({ value }) => value) ?? []
        data.definition.versions = versions?.map(({ value }) => value) ?? []
        data.definition.type = values.definition.type.value
        data.definition.outer_value = values.definition.outer_value ?? "value"
        data.definition.filter = values.definition.filter ?? ""
        data.definition.outer_filter = values.definition.outer_filter ?? ""
        data.definition = { ...data.definition, ...rest }
      } else {
        data.definition.type = "custom"
        data.definition.query = values.definition.query
      }
    } else {
      data.definition = null
    }

    onSubmit(data)
  }

  renderDimensions = ({ fields }) => {
    const { disabledFields = [] } = this.props
    return (
      <div className="dimensions-content">
        {fields.map((dimension, index) => (
          <div
            key={index}
            className={`dimension-item ${index === fields.length - 1 ? "last" : ""}`}
          >
            <Field
              name={`${dimension}.id`}
              component={TextField}
              placeholder="Dimension ID"
              label="Dimension ID"
              validate={[required, pythonVariable]}
              className="dimension-id"
              disabled={disabledFields.includes("data_type")}
              maxLength={80}
            />
            <Field
              name={`${dimension}.name`}
              component={TextField}
              placeholder="Name"
              label="Name"
              className="dimension-name"
              validate={required}
              disabled={disabledFields.includes("data_type")}
              maxLength={60}
            />
            <Field
              component={SelectField}
              placeholder="Data type"
              name={`${dimension}.data_type`}
              label="Data type"
              validate={required}
              className="dimension-data-type"
              options={COMPOUND_ATTRIBUTE_DATA_TYPES}
              disabled={disabledFields.includes("data_type")}
            />
            {fields.length > 1 && !disabledFields.includes("data_type") && (
              <IconButton
                size={SIZE.TAG}
                color={COLOR.RED}
                withBackground
                onClick={() => fields.remove(index)}
                iconName="trash-alt"
                tooltip="Delete"
              />
            )}
          </div>
        ))}
        <Button
          size="medium"
          color="primary"
          onClick={() => fields.push({})}
          className="add-dimension-button"
          disabled={disabledFields.includes("data_type")}
        >
          + Add dimension
        </Button>
      </div>
    )
  }

  selectLabel = labelId => {
    const { tagIds = [], change } = this.props
    change("tag_ids", [...tagIds, labelId])
  }

  deselectLabel = labelId => () => {
    const { tagIds, change } = this.props
    change(
      "tag_ids",
      tagIds.filter(tagId => tagId !== labelId),
    )
  }

  renderSQLQueryEditor = ({ input: { value, onChange }, meta: { touched, error } }) => (
    <div
      className={classNames("ace-editor-wrapper", {
        error: touched && error,
      })}
    >
      <p className="label-like">SQL query</p>
      <AceEditor
        value={value}
        onChange={onChange}
        mode="pgsql"
        theme="github"
        editorProps={{ $blockScrolling: true }}
        width="100%"
        height="400px"
        wrapEnabled={true}
        className="ace-editor"
      />
      {touched && error && <p className="error-message">{error}</p>}
    </div>
  )

  // Select only the event versions such that there are events with the selected sources and of the
  // selected types which have these versions
  getAvailableEventVersions = (events, selectedSources, selectedEventTypes) =>
    pipe(
      filter(
        ({ source_id }) =>
          selectedSources.length === 0 || selectedSources.find(({ value }) => source_id === value),
      ),
      filter(
        ({ type }) =>
          selectedEventTypes.length === 0 || selectedEventTypes.find(({ value }) => type === value),
      ),
      map(({ version }) => ({ label: version, value: version })),
      uniqBy(prop("value")),
      sort(ascend(prop("value"))),
    )(events)

  // Keep selected versions in sync with available versions
  updateVersionsSelection = (selectedSources, selectedEventTypes) => {
    const { change, selectedVersions, events } = this.props
    const availableEventVersions = this.getAvailableEventVersions(
      events,
      selectedSources,
      selectedEventTypes,
    )

    if (selectedVersions) {
      change(
        "definition.versions",
        selectedVersions.filter(opt => availableEventVersions.find(equals(opt))),
      )
    }
  }

  updateVersionsAfterSourceChange = selectedSources =>
    this.updateVersionsSelection(selectedSources, this.props.selectedEventTypes)

  updateVersionsAfterEventTypeChange = selectedEventTypes =>
    this.updateVersionsSelection(this.props.selectedSources, selectedEventTypes)

  toggleDefinitionSection = () =>
    this.setState(({ isDefinitionShown }) => ({
      isDefinitionShown: !isDefinitionShown,
    }))

  render() {
    const {
      handleSubmit,
      disabledFields = [],
      sourcesArray,
      eventTypesArray,
      dataType,
      tagIds,
      labels,
      areLabelsFulfilled,
      definitionType,
      events,
      selectedSources,
      selectedEventTypes,
    } = this.props

    return (
      <section className="attribute-form">
        <Form onSubmit={handleSubmit(this.submitForm)}>
          <div className="form-row white">
            <div className="left-part">
              <h2>General</h2>
            </div>
            <div className="right-part">
              <Field
                component={TextField}
                placeholder="Attribute ID"
                name="id"
                label="Attribute ID"
                validate={[required, pythonVariable]}
                maxLength={80}
                className="attribute-id"
                disabled={disabledFields.includes("id")}
              />
              <Field
                component={TextField}
                placeholder="Name"
                name="name"
                label="Name"
                validate={required}
                maxLength={80}
                className="attribute-name"
              />
              <Field
                component={SelectField}
                placeholder="Source"
                name="source_id"
                label="Source"
                validate={required}
                className="source-id"
                disabled={disabledFields.includes("source_id")}
                options={sourcesArray}
              />
            </div>
          </div>
          <div className="form-row grey">
            <div className="left-part">
              <h2>Description</h2>
            </div>
            <div className="right-part">
              <Field
                component={TextField}
                placeholder="Description (optional)"
                name="description"
                label="Description (optional)"
                className="attribute-description"
                multiline
                rows="8"
              />
              <div className="labels">
                <p className="label-like">Labels (optional)</p>
                {areLabelsFulfilled && (
                  <div className="list">
                    {Array.isArray(tagIds) &&
                      tagIds.map(tagId => {
                        const label = labels.find(item => item.id === tagId)
                        const labelName = label ? label.name : tagId
                        return (
                          <Tag
                            key={tagId}
                            color="primary"
                            clickable
                            onClick={this.deselectLabel(label ? label.id : tagId)}
                          >
                            {labelName}
                          </Tag>
                        )
                      })}
                    <TagPicker
                      className="segment-detail-header-tag-picker"
                      selectedTagIds={tagIds ? tagIds : []}
                      allTags={List(labels)}
                      onTagSelect={this.selectLabel}
                      dropdownAlign="right"
                      type="label"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-row white">
            <div className="left-part wider">
              <div className="title-with-toggle">
                <h2>Data settings</h2>
                <ToggleIconSwitchField
                  name="is_unique"
                  leftValue="1"
                  rightValue="0"
                  width="82px"
                  leftLabel="Single"
                  rightLabel="Multiple"
                  leftIcon={["fas", "dot-circle"]}
                  rightIcon={["fas", "ball-pile"]}
                  disabled={disabledFields.includes("is_unique")}
                />
              </div>
              <p className="docs">
                To learn more:{" "}
                <a
                  href="https://docs.meiro.io/books/meiro-business-explorer/page/set-attributes"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  User documentation
                </a>
              </p>
            </div>
            <div className="right-part wrap">
              <Field
                component={SelectField}
                placeholder="Data type"
                name="data_type"
                label="Data type"
                validate={required}
                className="attribute-data-type"
                disabled={disabledFields.includes("data_type")}
                options={ATTRIBUTE_DATA_TYPES}
              />
              {!_isNil(dataType) && dataType.value === "compound" && (
                <div className="compound-fields">
                  <FieldArray name="compound_dimensions" component={this.renderDimensions} />
                </div>
              )}
            </div>
          </div>
          <div className="form-row grey">
            <div className="left-part wider">
              <div className="definition-title">
                <h2>Definition</h2>
                <ToggleButton
                  value={this.state.isDefinitionShown}
                  handleToggle={this.toggleDefinitionSection}
                />
              </div>
              <p className="docs">
                To learn more:{" "}
                <a
                  href="https://docs.meiro.io/books/meiro-business-explorer/page/set-attributes"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  User documentation
                </a>
              </p>
            </div>
            {this.state.isDefinitionShown && (
              <div className="attribute-def-wrapper">
                {/* TODO: Create a ToggleIconSwitch component which is not a form field -> remove definitionType form field */}
                <ToggleIconSwitchField
                  name="definitionType"
                  leftValue="predefined"
                  rightValue="custom"
                  width="82px"
                  leftLabel="Predefined"
                  rightLabel="Custom"
                  leftIcon={["far", "list"]}
                  rightIcon={["fas", "pencil-alt"]}
                  className="definition-type-switch"
                />

                {definitionType === "custom" && (
                  <Field
                    component={this.renderSQLQueryEditor}
                    name="definition.query"
                    validate={required}
                  />
                )}

                {definitionType === "predefined" && (
                  <>
                    <div className="attribute-def-row">
                      <Field
                        component={SelectField}
                        placeholder="Select"
                        name="definition.sources"
                        label="Event source (optional)"
                        className="attribute-def-sources"
                        options={sourcesArray}
                        fullWidth
                        isMulti
                        onChange={this.updateVersionsAfterSourceChange}
                      />
                    </div>
                    <div className="attribute-def-row">
                      <Field
                        component={SelectField}
                        placeholder="Select"
                        name="definition.types"
                        label="Event type (optional)"
                        className="attribute-def-event-types"
                        options={eventTypesArray}
                        fullWidth
                        isMulti
                        onChange={this.updateVersionsAfterEventTypeChange}
                      />
                    </div>
                    <div className="attribute-def-row">
                      <Field
                        component={SelectField}
                        placeholder="Type"
                        name="definition.versions"
                        label="Event version (optional)"
                        className="attribute-def-versions"
                        options={this.getAvailableEventVersions(
                          events,
                          selectedSources,
                          selectedEventTypes,
                        )}
                        fullWidth
                        isMulti
                      />
                      <Field
                        component={SelectField}
                        placeholder="Select"
                        name="definition.type"
                        label="Calculation type"
                        className="attribute-def-calc-type"
                        options={DEFINITION_CALC_TYPE_OPTIONS}
                        fullWidth
                        validate={required}
                      />
                      <Field
                        component={TextField}
                        placeholder="Type"
                        name="definition.weight"
                        label="Weight"
                        className="attribute-def-weight"
                        fullWidth
                        validate={required}
                      />
                    </div>
                    <div className="attribute-def-row">
                      <Field
                        component={ExpandableAceEditor}
                        name="definition.value"
                        label="Value"
                        className="code-editor-field"
                        validate={required}
                      />
                      <Field
                        component={ExpandableAceEditor}
                        name="definition.outer_value"
                        label="Outer value (optional)"
                        className="code-editor-field"
                      />
                    </div>
                    <div className="attribute-def-row">
                      <Field
                        component={ExpandableAceEditor}
                        name="definition.filter"
                        label="Filter (optional)"
                        className="code-editor-field"
                      />
                      <Field
                        component={ExpandableAceEditor}
                        name="definition.outer_filter"
                        label="Outer filter (optional)"
                        className="code-editor-field"
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </Form>
      </section>
    )
  }
}

AttributeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  disabledFields: PropTypes.array,
  sourcesArray: PropTypes.array.isRequired,
  dataType: PropTypes.object,
  tagIds: PropTypes.array,
  fetchLabelsList: PropTypes.func.isRequired,
  areLabelsFulfilled: PropTypes.bool.isRequired,
  labels: PropTypes.array.isRequired,
}

const selector = formValueSelector("AttributeForm")
const mapStateToProps = state => ({
  sourcesArray: getDataSourcesArrayForSelect(state, true),
  eventTypesArray: getEventTypesForSelect(state, true),
  dataType: selector(state, "data_type"),
  tagIds: selector(state, "tag_ids"),
  areLabelsFulfilled: areLabelsFulfilled(state),
  labels: getLabelsArray(state),
  definitionType: selector(state, "definitionType"),
  selectedVersions: selector(state, "definition.versions"),
  selectedSources: selector(state, "definition.sources") ?? [],
  selectedEventTypes: selector(state, "definition.types") ?? [],
  events: getEventsData(state)?.toJS() ?? [],
  areEventsFulfilled: getEventsIsFulfilled(state),
})

AttributeForm = reduxForm({
  form: "AttributeForm",
  touchOnBlur: false,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(AttributeForm)

export default connect(mapStateToProps, { fetchLabelsList, fetchEventsList })(AttributeForm)
