import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import { hasAccess } from "helpers/authenticatedUser.helper"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import AttributesSetup from "./Attributes/AttributesSetupRoutes"
import DestinationsSetup from "./Destinations/DestinationsSetupRoutes"
import EventsSetup from "./Events/EventsSetupRoutes"
import IdentityStitchingSetup from "./IdentityStitching/IdentityStitchingSetupRoutes"
import InsightsSetup from "./Insights/Insights"
import LabelsSetup from "./Labels/Labels"
import ReportsSetup from "./Reports/ReportsSetupRoutes"
import SmartSegmentsSetup from "./SmartSegments/SmartSegmentsSetupRoutes"
import SourcesSetup from "./Sources/SourcesSetupRoutes"

function redirectToAllowedSetupTab() {
  if (hasAccess.setup.implementation()) {
    return <Redirect to={getRoutePath("setup.sources")} />
  }

  if (hasAccess.setup.smartSegments()) {
    return <Redirect to={getRoutePath("setup.segments.smart")} />
  }

  if (hasAccess.setup.insights()) {
    return <Redirect to={getRoutePath("setup.insights")} />
  }
}

export default function Setup() {
  return (
    <Switch>
      <Route path={getRoutePath("setup")} exact render={redirectToAllowedSetupTab} />

      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation()}
        path={getRoutePath("setup.sources")}
        component={SourcesSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation()}
        path={getRoutePath("setup.events")}
        component={EventsSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation()}
        path={getRoutePath("setup.identity-stitching")}
        component={IdentityStitchingSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation()}
        path={getRoutePath("setup.attributes")}
        component={AttributesSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation()}
        path={getRoutePath("setup.labels")}
        component={LabelsSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.smartSegments()}
        path={getRoutePath("setup.segments.smart")}
        component={SmartSegmentsSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.insights()}
        path={getRoutePath("setup.insights")}
        component={InsightsSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.reports()}
        path={getRoutePath("setup.reports")}
        component={ReportsSetup}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.setup.implementation()}
        path={getRoutePath("setup.destinations")}
        component={DestinationsSetup}
      />
    </Switch>
  )
}
