import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { submit } from "redux-form"
import { Record, Map } from "immutable"
import _noop from "lodash/noop"
import _take from "lodash/take"
import _takeRight from "lodash/takeRight"
import _startsWith from "lodash/startsWith"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Select from "react-select"

import { showToast } from "actions/toast.action"
import { TOAST, MODAL } from "sharedConstants"
import {
  customersFeatures,
  segmentsFeatures,
  setupFeatures,
  dataFeatures,
  administrationFeatures,
  getFeatureTitle,
} from "helpers/roles.helper"
import { goBackInHistory } from "helpers/backButton.helper"

import { getExportDestinationsData } from "resources/exportDestination/exportDestinationSelectors"

import Avatar from "components/UI/elements/Avatar"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import PasswordChangeForm from "./PasswordChangeForm"
import SingleInputForm from "./SingleInputForm"
import IconButton, { COLOR } from "components/UI/elements/IconButton"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { selectStyles } from "helpers/customSelectStyle.helper"
import { DropdownIndicator } from "components/UI/elements/SelectField"

import "./UserSettings.scss"
import { getRoutePath } from "routes"
import ToggleButton from "components/UI/elements/ToggleButton"
import InfoTooltip from "components/UI/elements/InfoTooltip"

class UserSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      passwordEditMode: false,
      deleteModalOpen: false,
    }
  }

  deleteUser = () => {
    const {
      deleteUser,
      showToast,
      history,
      match: {
        params: { id },
      },
    } = this.props
    deleteUser(id)
      .then(() => {
        showToast(`User deleted.`, TOAST.TYPE.SUCCESS)
        history.push(getRoutePath("administration.users"))
      })
      .catch(_noop)
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModalOpen: !prevState.deleteModalOpen,
    }))
  }

  togglePasswordEditMode = () => {
    this.setState(prevState => ({
      passwordEditMode: !prevState.passwordEditMode,
    }))
  }

  savePassword = values => {
    const { modifyUser, showToast, user } = this.props

    modifyUser(user.id, { password: values.password })
      .then(() => {
        showToast(`Password changed.`, TOAST.TYPE.SUCCESS)
        this.setState({ passwordEditMode: false })
      })
      .catch(_noop)
  }

  saveName = async value => {
    const { modifyUser, showToast, user } = this.props
    try {
      await modifyUser(user.id, { name: value })
      showToast("Name changed.", TOAST.TYPE.SUCCESS)
      return true
    } catch (err) {
      return false
    }
  }

  saveEmail = async value => {
    const { modifyUser, showToast, user } = this.props
    try {
      await modifyUser(user.id, { email: value })
      showToast("Email changed.", TOAST.TYPE.SUCCESS)
      return true
    } catch (err) {
      return false
    }
  }

  toggleEmailNotifications = async () => {
    const { modifyUser, showToast, user } = this.props
    const isEnabled = user.email_notifications_enabled
    try {
      await modifyUser(user.id, { email_notifications_enabled: !isEnabled })
      showToast(`Email notifications ${isEnabled ? "disabled" : "enabled"}.`, TOAST.TYPE.SUCCESS)
    } catch {}
  }

  toggleAutomated = async () => {
    const { modifyUser, showToast, user } = this.props
    const isAutomated = user.automated
    try {
      await modifyUser(user.id, { automated: !user.automated })
      showToast(`Automated status turned ${isAutomated ? "off" : "on"}.`, TOAST.TYPE.SUCCESS)
    } catch {}
  }

  renderRoleFeaturesSection = () => {
    const { user, exportDestinations } = this.props
    const features = user.role.features

    const allowedFeatures = [],
      notAllowedFeatures = []
    const evalFeatures = fMap => {
      fMap.forEach((_, key) => {
        if (features.includes(key)) {
          allowedFeatures.push(getFeatureTitle(key))
        } else {
          notAllowedFeatures.push(getFeatureTitle(key))
        }
      })
    }
    const evalSubFeatures = () => {
      features.forEach(key => {
        if (_startsWith(key, "segments/export/")) {
          allowedFeatures.push(getFeatureTitle(key, exportDestinations))
        }
      })
    }
    evalFeatures(customersFeatures)
    evalFeatures(segmentsFeatures)
    evalSubFeatures()
    evalFeatures(dataFeatures)
    evalFeatures(administrationFeatures)
    evalFeatures(setupFeatures)

    return (
      <div className="role-features-section">
        {allowedFeatures.length > 0 && (
          <div className="row allowed">
            <h4>Permissions:</h4>
            <div className="permissions">
              <div className="col">
                {_take(allowedFeatures, Math.ceil(allowedFeatures.length / 2)).map(val => (
                  <p key={val}>
                    <FontAwesomeIcon className="icon" icon={["fas", "check"]} /> {val}
                  </p>
                ))}
              </div>
              <div className="col">
                {_takeRight(allowedFeatures, Math.floor(allowedFeatures.length / 2)).map(val => (
                  <p key={val}>
                    <FontAwesomeIcon className="icon" icon={["fas", "check"]} /> {val}
                  </p>
                ))}
              </div>
            </div>
          </div>
        )}
        {allowedFeatures.length > 0 && notAllowedFeatures.length > 0 && (
          <div className="delimiter" />
        )}
        {notAllowedFeatures.length > 0 && (
          <div className="row">
            <h4>Without access:</h4>
            <div className="permissions">
              <div className="col">
                {_take(notAllowedFeatures, Math.ceil(notAllowedFeatures.length / 2)).map(val => (
                  <p key={val}>
                    <FontAwesomeIcon className="icon" icon={["fas", "times"]} /> {val}
                  </p>
                ))}
              </div>
              <div className="col">
                {_takeRight(notAllowedFeatures, Math.floor(notAllowedFeatures.length / 2)).map(
                  val => (
                    <p key={val}>
                      <FontAwesomeIcon className="icon" icon={["fas", "times"]} /> {val}
                    </p>
                  ),
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  changeUserRole = obj => {
    const { modifyUser, showToast, user } = this.props

    modifyUser(user.id, { role_id: obj.value })
      .then(() => {
        showToast(`Role changed.`, TOAST.TYPE.SUCCESS)
      })
      .catch(_noop)
  }

  render() {
    const {
      user,
      editablePassword,
      deletableUser,
      dispatch,
      editableRole,
      backButton,
      history,
      roles,
      isMe,
    } = this.props
    const { passwordEditMode, deleteModalOpen } = this.state

    const actionButtons = (
      <div className="action-buttons">
        {deletableUser && (
          <Button color="white-red" size="small" onClick={this.toggleDeleteModal}>
            <FontAwesomeIcon className="icon" icon={["fas", "trash-alt"]} /> Delete user
          </Button>
        )}
        {editablePassword && (
          <React.Fragment>
            {passwordEditMode && (
              <React.Fragment>
                <Button color="white" size="small" onClick={this.togglePasswordEditMode}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => dispatch(submit("UserPasswordChange"))}
                >
                  <FontAwesomeIcon className="icon" icon={["fas", "check"]} /> Confirm password
                </Button>
              </React.Fragment>
            )}
            {!passwordEditMode && (
              <Button color="primary" size="small" onClick={this.togglePasswordEditMode}>
                <FontAwesomeIcon className="icon" icon={["fas", "lock-alt"]} /> Change password
              </Button>
            )}
          </React.Fragment>
        )}
      </div>
    )

    return (
      <section className="wrapper user-settings">
        {backButton && (
          <div className="above-paper-left">
            <IconButton
              color={COLOR.GREY}
              onClick={goBackInHistory(history, getRoutePath("administration.users"))}
              className="back-button"
              iconName="chevron-left"
            />
          </div>
        )}
        <PaperHeader className="user-settings-header" size="small" titleText="user settings">
          <div className="header-content">
            {isMe && (
              <>
                <div className="email-notif-toggle-label">Receive notifications by email</div>
                <ToggleButton
                  value={user.email_notifications_enabled}
                  handleToggle={this.toggleEmailNotifications}
                  size="sm"
                />
              </>
            )}
            {actionButtons}
          </div>
        </PaperHeader>
        <Paper hasHeader={true} className="user-settings-content">
          <div className="avatar">
            <Avatar
              className="gravatar-image"
              name={user.name}
              email={user.email}
              gravatarSize={200}
            />
          </div>
          {!passwordEditMode && (
            <div className="user-info">
              <div className="box">
                <SingleInputForm onSubmit={this.saveName} label="Name" defaultValue={user.name} />
              </div>
              <div className="box">
                <SingleInputForm
                  onSubmit={this.saveEmail}
                  label="Email"
                  defaultValue={user.email}
                />
              </div>
              <div className="box">
                <label className="role-label">Role</label>
                {!editableRole && <span className="role-name">{user.role.name}</span>}
                {editableRole && (
                  <Select
                    value={roles ? roles.find(role => role.value === user.role.id) : null}
                    onChange={this.changeUserRole}
                    options={roles === null ? [] : roles}
                    styles={selectStyles()}
                    simpleValue
                    isSearchable={true}
                    isLoading={roles === null}
                    className="select-input"
                    components={{
                      DropdownIndicator: DropdownIndicator,
                    }}
                    classNamePrefix="role_select"
                    noOptionsMessage={() => "Empty"}
                  />
                )}
              </div>
              <div className="box">
                <label className="role-label">
                  System user{" "}
                  <InfoTooltip>
                    System users are not included in the Users Activity tab reports.
                  </InfoTooltip>
                </label>
                <div className="automated-toggle-wrapper">
                  <ToggleButton
                    value={user.automated}
                    handleToggle={this.toggleAutomated}
                    size="sm"
                  />
                </div>
              </div>
            </div>
          )}
          {passwordEditMode && <PasswordChangeForm onSubmit={this.savePassword} />}
        </Paper>
        {this.renderRoleFeaturesSection()}
        <ConfirmModal
          open={deleteModalOpen}
          type={MODAL.TYPE.DELETE}
          handleClose={this.toggleDeleteModal}
          handleConfirm={this.deleteUser}
          title="Delete user"
          action="delete"
          what="user"
          item={user.name}
        />
      </section>
    )
  }
}

UserSettings.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Record)]).isRequired,
  modifyUser: PropTypes.func.isRequired,
  editablePassword: PropTypes.bool,
  editableRole: PropTypes.bool,
  deletableUser: PropTypes.bool,
  deleteUser: PropTypes.func,
  showToast: PropTypes.func.isRequired,
  exportDestinations: PropTypes.instanceOf(Map).isRequired,
  backButton: PropTypes.bool,
  roles: PropTypes.array,
}

const mapDispatchToProps = dispatch => {
  return {
    showToast: (message, type) => dispatch(showToast(message, type)),
    dispatch,
  }
}

const mapStateToProps = (state, ownProps) => ({
  exportDestinations: getExportDestinationsData(state),
  isMe: state.authenticatedUser.data.id === ownProps.user.id,
})

UserSettings = connect(mapStateToProps, mapDispatchToProps)(UserSettings)

export default withRouter(UserSettings)
