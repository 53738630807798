import React, { PureComponent } from "react"
import PaperHeader from "components/UI/elements/PaperHeader"
import { getRoutePath } from "routes"
import Button from "components/UI/elements/Button/Button"
import RemoteSubmitButton from "components/UI/elements/RemoteSubmitButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { connect } from "react-redux"
import _isNil from "lodash/isNil"
import _get from "lodash/get"
import {
  isExportDestinationsFulfilled,
  getExportDestinationById,
} from "resources/exportDestination/exportDestinationSelectors"
import PropTypes from "prop-types"
import Paper from "components/UI/elements/Paper"
import DestinationForm from "../components/DestinationForm/DestinationForm"
import {
  areWorkspacesFetching,
  areWorkspacesFulfilled,
  getWorkspacesForSelect,
} from "selectors/workspace.selector"
import { fetchWorkspacesList } from "actions/workspace.action"
import {
  modifyExportDestination,
  deleteExportDestination,
} from "resources/exportDestination/exportDestinationActions"
import { showToast } from "actions/toast.action"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
import { getGlobalSettingsFormValues } from "resources/globalSettings/globalSettingsSelectors"

import "./DestinationDetail.scss"

class DestinationDetail extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modifying: false,
      deleteModal: {
        open: false,
        isLoading: false,
      },
    }
  }

  componentDidMount() {
    const { areWorkspacesFulfilled, areWorkspacesFetching, fetchWorkspacesList, globalSettings } =
      this.props
    const isMiApiSet = !_isNil(_get(globalSettings, "mi_api.value"))
    if (isMiApiSet && !areWorkspacesFulfilled && !areWorkspacesFetching) {
      fetchWorkspacesList()
    }
  }

  componentDidUpdate(prevProps) {
    const { globalSettings, areWorkspacesFulfilled, fetchWorkspacesList } = this.props
    if (globalSettings !== prevProps.globalSettings && !areWorkspacesFulfilled) {
      const isMiApiSet = !_isNil(_get(globalSettings, "mi_api.value"))
      if (isMiApiSet) {
        fetchWorkspacesList()
      }
    }
  }

  modifyDestination = async data => {
    if (!this.state.modifying) {
      this.setState({ modifying: true })
      const { history, modifyExportDestination, destination, showToast } = this.props
      try {
        await modifyExportDestination(destination.id, data)
        showToast("Destination edited.")
        history.push(getRoutePath("setup.destinations"))
      } catch {
        this.setState({ modifying: false })
      }
    }
  }

  toggleDeleteModal = () => {
    if (!this.state.deleteModal.isLoading) {
      this.setState(prevState => ({
        deleteModal: {
          ...prevState.deleteModal,
          open: !prevState.deleteModal.open,
        },
      }))
    }
  }

  deleteDestination = async () => {
    const { deleteExportDestination, showToast, destination, history } = this.props
    this.setState(prevState => ({
      deleteModal: {
        ...prevState.deleteModal,
        isLoading: true,
      },
    }))
    try {
      await deleteExportDestination(destination.id)
      showToast("Destination deleted.")
      history.push(getRoutePath("setup.destinations"))
    } catch {
      this.setState(prevState => ({
        deleteModal: {
          ...prevState.deleteModal,
          isLoading: false,
        },
      }))
    }
  }

  render() {
    const { history, areDestinationsFulfilled, destination, workspaces } = this.props
    const { modifying, deleteModal } = this.state
    const formInitValues = {}
    if (destination) {
      formInitValues.id = destination.id
      formInitValues.name = destination.name
      formInitValues.description = destination.description
      formInitValues.attribute_ids = destination.attribute_ids
        ? destination.attribute_ids.toJS()
        : null
      formInitValues.mandatory_attribute_ids = destination.mandatory_attribute_ids
        ? destination.mandatory_attribute_ids.toJS()
        : null
      formInitValues.icon = destination.icon ? destination.icon.replace(/\.[^/.]+$/, "") : null
      formInitValues.settings = {
        mandatory_attributes_operator: destination.getIn(
          ["settings", "mandatory_attributes_operator"],
          "or",
        ),
      }

      if (destination.mi_workspace_id) {
        formInitValues.mi_workspace = workspaces.find(
          item => item.value === destination.mi_workspace_id,
        )
        if (!formInitValues.mi_workspace) {
          formInitValues.mi_workspace = {
            value: destination.mi_workspace_id,
            label: `${destination.mi_workspace_id}`,
          }
        }
      }

      const parameters = destination.getIn(["settings", "mi_workspace_variables"])
      if (parameters) {
        formInitValues.settings.mi_workspace_variables = parameters.toJS().map(param => ({
          ...param,
          input_mode: {
            user_input: param.input_mode.includes("user_input"),
            value_from_list: param.input_mode.includes("value_from_list"),
          },
        }))
      }
    }

    return (
      <section className="detail-destination wrapper">
        <PaperHeader
          size="small"
          titleText="Edit destination"
          className="detail-destination-header"
        >
          <div className="header-buttons">
            <Button size="small" color="white-red" onClick={this.toggleDeleteModal}>
              <FontAwesomeIcon icon={["fas", "trash-alt"]} className="icon" /> Delete
            </Button>
            <Button
              size="small"
              color="white"
              onClick={() => {
                history.push(getRoutePath("setup.destinations"))
              }}
            >
              Cancel
            </Button>
            <RemoteSubmitButton isLoading={modifying} formName="DestinationForm" />
          </div>
        </PaperHeader>
        {areDestinationsFulfilled && (
          <Paper hasHeader className="detail-destination-content">
            <DestinationForm
              onSubmit={this.modifyDestination}
              initialValues={formInitValues}
              disabledFields={["id"]}
            />
          </Paper>
        )}
        <ConfirmModal
          open={deleteModal.open}
          type={MODAL.TYPE.DELETE}
          handleClose={this.toggleDeleteModal}
          handleConfirm={this.deleteDestination}
          title="Are you sure?"
          action="delete"
          what="destination"
          item={destination ? destination.name : ""}
          isLoading={deleteModal.isLoading}
        />
      </section>
    )
  }
}

DestinationDetail.propTypes = {
  areDestinationsFulfilled: PropTypes.bool.isRequired,
  fetchWorkspacesList: PropTypes.func.isRequired,
  workspaces: PropTypes.array.isRequired,
  areWorkspacesFulfilled: PropTypes.bool.isRequired,
  areWorkspacesFetching: PropTypes.bool.isRequired,
  modifyExportDestination: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  deleteExportDestination: PropTypes.func.isRequired,
  globalSettings: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => ({
  areDestinationsFulfilled: isExportDestinationsFulfilled(state),
  destination: getExportDestinationById(state, ownProps.match.params.id),
  areWorkspacesFetching: areWorkspacesFetching(state),
  areWorkspacesFulfilled: areWorkspacesFulfilled(state),
  workspaces: getWorkspacesForSelect(state),
  globalSettings: getGlobalSettingsFormValues(state),
})

export default connect(mapStateToProps, {
  fetchWorkspacesList,
  modifyExportDestination,
  showToast,
  deleteExportDestination,
})(DestinationDetail)
