import { AxiosError } from "axios"
import { Dispatch } from "redux"

export const PROMISE_TYPE_DELIMITER = "_" as const

export enum ActionType {
  Pending = "PENDING",
  Fulfilled = "FULFILLED",
  Rejected = "REJECTED",
}

type Action<T extends string, P, M> = {
  type: T
  payload: P
  meta?: M
}

type PromisePendingAction<T extends string, M> = {
  type: `${T}${typeof PROMISE_TYPE_DELIMITER}${ActionType.Pending}`
  meta: M
}
type PromiseFulfilledAction<T extends string, P, M> = {
  type: `${T}${typeof PROMISE_TYPE_DELIMITER}${ActionType.Fulfilled}`
  payload: P
  meta: M
}
type PromiseRejectedAction<T extends string, M> = {
  type: `${T}${typeof PROMISE_TYPE_DELIMITER}${ActionType.Rejected}`
  payload: AxiosError
  meta: M
}

export type PromiseAction<A> = A extends (
  ...args: any
) =>
  | Action<infer T, infer P, infer M>
  | ((dispatch: Dispatch, getState: any) => Action<infer T, infer P, infer M>)
  ?
      | PromisePendingAction<T, M>
      | PromiseFulfilledAction<T, Awaited<P>, M>
      | PromiseRejectedAction<T, M>
  : never
