import React from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { shortenText } from "helpers/string.helper"

import "./SegmentNameLink.scss"
import Tippy from "@tippyjs/react"

const MAX_NAME_LENGTH = 40
const DATA_FOR = "segment-nav-tooltip"

const SegmentNameLink = ({ name, routeTo, ...props }) => {
  const hasTooltip = name.length >= MAX_NAME_LENGTH
  const tooltipProps = hasTooltip ? { "data-tip": name, "data-for": DATA_FOR } : {}

  return (
    <Tippy content={name} disabled={!hasTooltip} placement="bottom">
      <NavLink to={routeTo} {...tooltipProps} {...props}>
        <FontAwesomeIcon className="segment-icon" icon="filter" />
        {shortenText(name, MAX_NAME_LENGTH)}
      </NavLink>
    </Tippy>
  )
}

SegmentNameLink.propTypes = {
  name: PropTypes.string.isRequired,
  routeTo: PropTypes.string.isRequired,
}

export default SegmentNameLink
