import React from "react"
import PropTypes from "prop-types"
import { Line } from "react-chartjs-2"
import _forEachRight from "lodash/forEachRight"

// ui components
import Paper from "components/UI/elements/Paper"

import "./EventGroupsCountsChart.scss"

const lineChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        display: true,
        ticks: {
          suggestedMin: 0,
          callback: function (value) {
            if (value % 1 === 0) {
              return value
            }
          },
          fontStyle: "bold",
          fontSize: 11,
        },
        gridLines: {
          color: "#efefef",
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 3,
          maxRotation: 0,
          fontStyle: "bold",
          fontSize: 11,
        },
      },
    ],
  },
}

const EventGroupsCountsChart = ({ data }) => {
  let content = null
  if (data.length < 2) {
    content = (
      <p>
        Customer Events chart will be shown when the customer has more interactions within a
        specified timeframe.
      </p>
    )
  } else {
    let reversedLabels = []
    let reversedData = []
    _forEachRight(data, entry => {
      reversedLabels.push(entry.date)
      reversedData.push(entry.events_count)
    })

    const chartData = {
      labels: reversedLabels,
      datasets: [
        {
          label: "Count",
          data: reversedData,
          fill: true,
          borderColor: "#5FC4BE",
          backgroundColor: "rgba(95, 196, 190, 0.5)",
          borderJoinStyle: "miter",
          borderWidth: 2,
          lineTension: 0,
          pointRadius: 2,
          pointHoverRadius: 3,
          pointBackgroundColor: "#5FC4BE",
          pointBorderColor: "#5FC4BE",
        },
      ],
    }
    content = <Line data={chartData} options={lineChartOptions} />
  }

  return (
    <Paper className="event-groups-counts-chart-wrapper" hasHeader={false}>
      <h3>Customer Events</h3>
      <div className="chart-wrapper">{content}</div>
    </Paper>
  )
}

EventGroupsCountsChart.propTypes = {
  data: PropTypes.array.isRequired,
}

export default EventGroupsCountsChart
