import { EVENT } from "sharedConstants"
import { List, Map } from "immutable"
import EventModel from "models/event.model"

const initialState = Map({
  isFulfilled: false,
  isFetching: true,
  data: List(),
})

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case `${EVENT.ACTION.LIST}_PENDING`:
      return state.set("isFetching", true)
    case `${EVENT.ACTION.LIST}_FULFILLED`:
      return state
        .set("isFulfilled", true)
        .set("isFetching", false)
        .set("data", List(payload.map(event => new EventModel(event))))
    case `${EVENT.ACTION.MODIFY}_FULFILLED`:
      return state.set(
        "data",
        state.get("data").map(event => {
          if (event.id === payload.id) {
            return new EventModel(payload)
          }
          return event
        }),
      )
    default:
      return state
  }
}
