import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import { hasAccess } from "helpers/authenticatedUser.helper"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import CustomSegments from "./CustomSegments/CustomSegmentsRoutes"
import FeaturedSegments from "./FeaturedSegments/FeaturedSegmentsRoutes"
import SmartSegments from "./SmartSegments/SmartSegmentsRoutes"
import Tags from "./Tags/Tags"

export default function Segments() {
  return (
    <Switch>
      <AuthorizedRoute
        hasAccess={hasAccess.segments.viewTags()}
        path={getRoutePath("segments.tags")}
        component={Tags}
      />
      <Route path={getRoutePath("segments.featured")} component={FeaturedSegments} />
      <Route path={getRoutePath("segments.smart")} component={SmartSegments} />
      <Route path={getRoutePath("segments")} component={CustomSegments} />
    </Switch>
  )
}
