import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { List, Map, OrderedMap } from "immutable"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import _forEach from "lodash/forEach"
import _noop from "lodash/noop"
import _startsWith from "lodash/startsWith"
import { omit, toLower } from "ramda"

import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import Button from "components/UI/elements/Button/Button"
import IconButton, { COLOR, SIZE } from "components/UI/elements/IconButton"
import Tag from "components/UI/elements/Tag"
import ConfirmModal from "components/UI/components/ConfirmModal"
import RoleForm from "./RoleForm"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"

import { showToast } from "actions/toast.action"

import { getExportDestinationsListSortedByName } from "resources/exportDestination/exportDestinationSelectors"

import { MODAL, TOAST } from "sharedConstants"
import { api } from "api"
import { getFeatureTitle, getFeaturesInitialFormValues } from "helpers/roles.helper"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"

import "./Roles.scss"
import LoadingIndicator from "components/UI/elements/LoadingIndicator"

class Roles extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      roles: null,
      mode: "table",
      editingRole: {},
      confirmModal: Map({
        open: false,
        item: null,
        loading: false,
        handleConfirm: _noop,
        action: "",
        what: "",
        type: MODAL.TYPE.DELETE,
        text: "",
      }),
      isLoading: true,
    }
  }

  componentDidMount() {
    this.fetchAllRoles()
  }

  fetchAllRoles = async () => {
    const caller = new AllResourceItemsFetcher()
    try {
      const data = await caller
        .setEndpointCall((offset, limit) => api.userRole.list(offset, limit))
        .setDataPath("roles")
        .run()
      this.setState({
        roles: List(data.filter(role => role.id !== 2)),
      })
    } catch {
    } finally {
      this.setState({ isLoading: false })
    }
  }

  toggleMode =
    (role = {}) =>
    () => {
      this.setState(prevState => ({
        mode: prevState.mode === "table" ? "form" : "table",
        editingRole: role,
      }))
    }

  toggleConfirmModalOrSaveRole = async data => {
    const { editingRole } = this.state
    if (!_isEmpty(editingRole)) {
      const loggedInUsersRole = _get(this.props, "authenticatedUser.data.role.id")
      if (editingRole.id === loggedInUsersRole) {
        this.setState(prevState => ({
          confirmModal: prevState.confirmModal
            .set("open", true)
            .set("item", null)
            .set("loading", false)
            .set("handleConfirm", async () => {
              await this.saveRole(data)
              this.closeConfirmModal()
            })
            .set("action", "")
            .set("what", "")
            .set("type", MODAL.TYPE.SUCCESS)
            .set("text", "Warning. You are about to edit your own user role."),
        }))
      } else {
        this.saveRole(data)
      }
    } else {
      this.saveRole(data)
    }
  }

  saveRole = async data => {
    const { showToast } = this.props
    const { editingRole, saving } = this.state
    if (!saving) {
      const dataToSend = {
        name: data.name,
        invisible_attributes_tag_ids: data.attribute_visibility_label_ids,
        features: [],
      }
      _forEach(omit(["name", "attribute_visibility_label_ids"], data), (value, key) => {
        if (value === "on") {
          if (!(_startsWith(key, "segments/export/") && data["segments/export"] === "on")) {
            dataToSend.features.push(key)
          }
        }
      })
      this.setState({ saving: true })
      if (_isEmpty(editingRole)) {
        // create
        try {
          const response = await api.userRole.create(dataToSend)
          showToast("Role created.", TOAST.TYPE.SUCCESS)
          this.setState(prevState => ({
            mode: "table",
            saving: false,
            roles: prevState.roles.push(response).sortBy(role => toLower(role.name)),
          }))
        } catch (err) {
          this.setState({
            saving: false,
          })
        }
      } else {
        // edit
        try {
          const response = await api.userRole.modify(editingRole.id, dataToSend)
          showToast("Role edited.", TOAST.TYPE.SUCCESS)
          this.setState(prevState => ({
            mode: "table",
            saving: false,
            roles: prevState.roles
              .map(role => (role.id !== response.id ? role : response))
              .sortBy(role => toLower(role.name)),
          }))
        } catch (err) {
          this.setState({
            saving: false,
          })
        }
      }
    }
  }

  openRoleDeleteModal = role => () => {
    this.setState(prevState => ({
      confirmModal: prevState.confirmModal
        .set("open", true)
        .set("item", role)
        .set("loading", false)
        .set("handleConfirm", this.deleteRole)
        .set("action", "delete")
        .set("what", "role")
        .set("item", role)
        .set("type", MODAL.TYPE.DELETE)
        .set("text", ""),
    }))
  }

  closeConfirmModal = () => {
    this.setState(prevState => ({
      confirmModal: prevState.confirmModal.set("open", false).set("loading", false),
    }))
  }

  deleteRole = async () => {
    const { confirmModal } = this.state
    try {
      this.setState(prevState => ({
        confirmModal: prevState.confirmModal.set("loading", true),
      }))
      await api.userRole.delete(confirmModal.get("item").id)
      this.props.showToast("Role deleted.", TOAST.TYPE.SUCCESS)
      this.setState(prevState => ({
        mode: "table",
        confirmModal: prevState.confirmModal.set("open", false).set("loading", false),
        roles: prevState.roles.filter(item => item.id !== confirmModal.get("item").id),
      }))
    } catch (err) {
      if (_get(err, "response.status") === 404) {
        // roles was already deleted
        this.fetchAllRoles()
        this.setState(prevState => ({
          mode: "table",
          confirmModal: prevState.confirmModal.set("open", false).set("loading", false),
        }))
      } else {
        this.setState(prevState => ({
          confirmModal: prevState.confirmModal.set("loading", false),
        }))
      }
    }
  }

  render() {
    const { roles, confirmModal, mode, editingRole, saving, isLoading } = this.state
    const { exportDestinations } = this.props

    const initialDestinationsPermissions = {}
    exportDestinations.forEach(destination => {
      initialDestinationsPermissions[`segments/export/${destination.id}`] = "off"
    })
    const formInitialValues = {
      name: "",
      ...getFeaturesInitialFormValues(),
      ...initialDestinationsPermissions,
    }
    if (editingRole && Array.isArray(editingRole.features)) {
      formInitialValues.name = editingRole.name
      formInitialValues.attribute_visibility_label_ids =
        editingRole.invisible_attributes_tag_ids ?? []
      if (Array.isArray(editingRole.features)) {
        editingRole.features.forEach(featureName => {
          formInitialValues[featureName] = "on"
        })
        if (formInitialValues["segments/export"] === "on") {
          exportDestinations.forEach(destination => {
            formInitialValues[`segments/export/${destination.id}`] = "on"
          })
        }
      }
    }

    return (
      <section className="wrapper admin-roles">
        {mode === "table" && (
          <React.Fragment>
            <PaperHeader className="admin-roles-header" titleText="List of roles" size="small">
              <Button type="button" color="primary" size="small" onClick={this.toggleMode()}>
                + Create new role
              </Button>
            </PaperHeader>
            <Paper hasHeader className="admin-roles-content">
              {isLoading && <LoadingIndicator className="loading-indicator" />}
              {!isLoading && List.isList(roles) && (
                <>
                  {roles.size === 0 && <p>No role exists yet.</p>}
                  {roles.size > 0 && (
                    <Table className="admin-roles">
                      <Thead stickyHeader>
                        <Th className="role-name">Name</Th>
                        <Th className="features">List of enabled permissions</Th>
                        <Th className="acton-column two-icon">&nbsp;</Th>
                      </Thead>
                      <Tbody>
                        {roles.map(role => (
                          <Tr key={role.id}>
                            <Td textBigger textBold textBlack data-testid="td-name">
                              {role.name}
                            </Td>
                            <Td>
                              {role.features.map(featureName => {
                                const title = getFeatureTitle(featureName, exportDestinations)
                                if (title) {
                                  return (
                                    <Tag
                                      key={featureName}
                                      className="feature"
                                      color="site-background"
                                    >
                                      {title}
                                    </Tag>
                                  )
                                }
                                return null
                              })}
                            </Td>
                            <Td textAlignRight className="action-column two-icon">
                              <IconButton
                                iconName="pencil-alt"
                                color={COLOR.BLACK}
                                className="edit-button"
                                onClick={this.toggleMode(role)}
                                size={SIZE.TAG}
                                withBackground
                                tooltip="Edit"
                              />
                              <IconButton
                                iconName="trash-alt"
                                color={COLOR.RED}
                                onClick={this.openRoleDeleteModal(role)}
                                className="trash-button"
                                size={SIZE.TAG}
                                withBackground
                                tooltip="Delete"
                              />
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  )}
                </>
              )}
            </Paper>
          </React.Fragment>
        )}
        {mode === "form" && (
          <RoleForm
            closeForm={this.toggleMode()}
            onSubmit={this.toggleConfirmModalOrSaveRole}
            isSaving={saving}
            exportDestinations={exportDestinations}
            initialValues={formInitialValues}
          />
        )}
        <ConfirmModal
          open={confirmModal.get("open")}
          handleClose={this.closeConfirmModal}
          handleConfirm={confirmModal.get("handleConfirm")}
          title="Are you sure?"
          action={confirmModal.get("action")}
          what={confirmModal.get("what")}
          item={confirmModal.get("item") ? confirmModal.get("item").name : ""}
          isLoading={confirmModal.get("loading")}
          type={confirmModal.get("type")}
          text={confirmModal.get("text")}
        />
      </section>
    )
  }
}

Roles.propTypes = {
  authenticatedUser: PropTypes.object.isRequired,
  exportDestinations: PropTypes.instanceOf(OrderedMap).isRequired,
}

const mapStateToProps = state => ({
  authenticatedUser: state.authenticatedUser,
  exportDestinations: getExportDestinationsListSortedByName(state),
})

Roles = connect(mapStateToProps, { showToast })(Roles)

export default Roles
