import { api } from "api"
import { USER } from "resources/user/userActions"
import { history } from "app/App"
import { getRoutePath } from "routes"
import { without } from "lodash"

export const login = data => async dispatch =>
  dispatch({
    type: USER.ACTION.LOGGED_IN,
    payload: {
      promise: (async () => {
        return await api.user.login(data)
      })(),
    },
  })

export const oktaLogin = data => async dispatch =>
  dispatch({
    type: USER.ACTION.LOGGED_IN,
    payload: {
      promise: (async () => {
        return await api.user.oktaLogin(data)
      })(),
    },
  })

export const refreshToken = () => async dispatch =>
  dispatch({
    type: USER.ACTION.REFRESH_TOKEN,
    payload: {
      promise: (async () => {
        return await api.user.refreshToken()
      })(),
    },
  })

export const modifyAuthUser = (id, data) => async dispatch =>
  dispatch({
    type: USER.ACTION.MODIFY_AUTH_USER,
    payload: {
      promise: api.user.modify(id, data),
    },
  })

export const verifyUser =
  (token = "", logging = true) =>
  async dispatch => {
    if (logging) {
      dispatch({
        type: USER.ACTION.LOGGING_IN,
      })
    }

    const { me } = await _userMe(token)
    if (me) {
      dispatch({
        type: USER.ACTION.RETRIEVE_LOGGED_IN_USER,
        payload: {
          ...me,
        },
      })
    }

    return me
  }

export const logout =
  (redirect = false) =>
  dispatch => {
    if (redirect) {
      api.user.logout()
      history.push(getRoutePath("logout"))
    }
    dispatch({
      type: USER.ACTION.LOGGED_OUT,
    })
  }

export const setUiAttribute = (attribute, value) => dispatch =>
  dispatch({
    type: USER.ACTION.SET_UI_ATTRIBUTE,
    payload: {
      attribute,
      value,
    },
  })

export const setLatestSearch = (section, values) => dispatch =>
  dispatch({
    type: USER.ACTION.SET_LATEST_SEARCH,
    payload: {
      section,
      values,
    },
  })

export const me =
  (token = "") =>
  async () => {
    return await _userMe(token)
  }

const _userMe = (token = "") => api.user.me(token)

export const toggleFavoriteCustomer = id => async (dispatch, getState) => {
  const { cdp_settings, id: authUserId } = getState().authenticatedUser.data
  const favoriteCustomers = cdp_settings?.favourite_customers ?? []
  const toggledCustomer = favoriteCustomers.find(
    ({ customer_entity_id }) => customer_entity_id === id,
  )
  const newFavoriteCustomers = toggledCustomer
    ? without(favoriteCustomers, toggledCustomer)
    : favoriteCustomers.concat({ customer_entity_id: id })

  return dispatch({
    type: USER.ACTION.MODIFY_AUTH_USER,
    payload: {
      promise: api.user.modify(authUserId, {
        cdp_settings: { favourite_customers: newFavoriteCustomers },
      }),
    },
  })
}
