import React from "react"
import classNames from "classnames"
import styles from "./HelpDropdown.module.scss"
import DelayedTooltip from "components/UI/elements/IconButton/DelayedTooltip/DelayedTooltip"
import useClickOutHandler from "hooks/useClickOutHandler"
import { links } from "./links"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getRoutePath } from "routes"

function getSection() {
  const { pathname } = window.location

  if (pathname.startsWith(getRoutePath("customers"))) {
    return { name: "Customers", links: links.customers }
  }
  if (pathname.startsWith(getRoutePath("segments"))) {
    return { name: "Segments", links: links.segments }
  }
  if (pathname.startsWith(getRoutePath("data"))) {
    return { name: "Data", links: links.data }
  }
  if (pathname.startsWith(getRoutePath("reporting"))) {
    return { name: "Reporting", links: links.reporting }
  }
  if (pathname.startsWith(getRoutePath("personalization"))) {
    return { name: "Personalization", links: links.personalization }
  }
  if (pathname.startsWith(getRoutePath("setup"))) {
    return { name: "Setup", links: links.setup }
  }
  if (pathname.startsWith(getRoutePath("administration"))) {
    return { name: "Administration", links: links.administration }
  }
  if (pathname.startsWith(getRoutePath("trash"))) {
    return { name: "Trash", links: links.trash }
  }
  if (pathname.startsWith(getRoutePath("user-settings"))) {
    return { name: "User settings", links: links.userSettings }
  }
  if (pathname === getRoutePath("home")) {
    return { name: "Homepage", links: links.homepage }
  }
  return null
}

export default function HelpDropdown() {
  const {
    isOpen: isDropdownOpen,
    toggle: toggleDropdown,
    close: closeDropdown,
    ref: dropdownRef,
  } = useClickOutHandler()

  const section = getSection()

  return (
    section && (
      <div className={styles.container}>
        <DelayedTooltip content="Help">
          <button
            className={classNames(styles.button, { [styles.isOpen]: isDropdownOpen })}
            onClick={toggleDropdown}
          >
            ?
          </button>
        </DelayedTooltip>
        {isDropdownOpen && (
          <div className={styles.dropdown} ref={dropdownRef}>
            <div className={styles.topSection}>
              <div className={styles.heading}>
                Useful documentation links for <strong>{section.name}</strong>:
              </div>
              {section.links.map(({ title, url }) => (
                <a
                  onClick={closeDropdown}
                  key={url}
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.docLink}
                >
                  {title}
                  <FontAwesomeIcon icon={["far", "external-link"]} className={styles.openIcon} />
                </a>
              ))}
            </div>
            <div className={styles.bottomSection}>
              <a
                onClick={closeDropdown}
                href={"https://docs.meiro.io/books/meiro-business-explorer"}
                target="_blank"
                rel="noreferrer"
                className={styles.docLink}
              >
                <FontAwesomeIcon icon={["fas", "life-ring"]} className={styles.icon} />
                User documentation
                <FontAwesomeIcon icon={["far", "external-link"]} className={styles.openIcon} />
              </a>
              <a
                onClick={closeDropdown}
                href={"http://status.meiro.io/"}
                target="_blank"
                rel="noreferrer"
                className={styles.docLink}
              >
                <FontAwesomeIcon icon={["fas", "sparkles"]} className={styles.icon} />
                What's new
                <FontAwesomeIcon icon={["far", "external-link"]} className={styles.openIcon} />
              </a>
              <a
                onClick={closeDropdown}
                href={"https://meiro.io/general-terms/"}
                target="_blank"
                rel="noreferrer"
                className={styles.docLink}
              >
                <FontAwesomeIcon icon={["fas", "file-contract"]} className={styles.icon} />
                Terms & Conditions
                <FontAwesomeIcon icon={["far", "external-link"]} className={styles.openIcon} />
              </a>
            </div>
          </div>
        )}
      </div>
    )
  )
}
