import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import EmbeddedWebBanners from "./EmbeddedWebBanners/EmbeddedWebBannersRoutes"
import MobilePushNotifications from "./MobilePushNotifications/MobilePushNotifications"
import PopupWebBanners from "./PopupWebBanners/PopupWebBannersRoutes"
import PromoCodesLists from "./PromoCodes/PromoCodesLists/PromoCodesLists"

function redirectToAllowedPersonalizationTab() {
  return <Redirect to={getRoutePath("personalization.popup-web-banners")} />
}

export default function Personalization() {
  return (
    <Switch>
      <Route
        path={getRoutePath("personalization")}
        exact
        render={redirectToAllowedPersonalizationTab}
      />

      <Route path={getRoutePath("personalization.popup-web-banners")} component={PopupWebBanners} />
      <Route
        path={getRoutePath("personalization.embedded-web-banners")}
        component={EmbeddedWebBanners}
      />
      <Route
        path={getRoutePath("personalization.mobile-push-notifications")}
        component={MobilePushNotifications}
      />
      <Route path={getRoutePath("personalization.promo-codes")} component={PromoCodesLists} />
    </Switch>
  )
}
