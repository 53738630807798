import { Record, List, Map } from "immutable"

const Attribute = Record({
  id: 0,
  source: Map(),
  name: "",
  description: "",
  tags: List(),
  data_type: "",
  order_index: 1000,
  examples: List(),
  created: "",
  is_hidden: 0,
  is_unique: 1,
  is_used_in_stitching: false,
  identity_graph_color: "#fe7f66",
  definition: {},
})

export default Attribute
