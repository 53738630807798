import { TrashItemType } from "../trashTypes"

const trashItemLabels: Record<TrashItemType, string> = {
  embedded_web_banners: "Embedded web banner",
  popup_web_banners: "Pop up web banner",
  segments: "Segment",
  segment_export_destinations: "Export destination",
  users: "User",
  user_roles: "User role",
}

export default function getReadableItemType(
  type: TrashItemType,
  { capitalize = true, plural = false } = {},
) {
  let result = trashItemLabels[type]
  if (!capitalize) result = result.toLowerCase()
  if (plural) result = `${result}s`
  return result
}
