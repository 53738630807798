import React from "react"
import styles from "./TemplateGalleryItem.module.scss"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

type TemplateGalleryItemProps = {
  template: Template
  onClick: () => void
  isSelected: boolean
}

export type HighlightState = {
  startRow: number
  endRow: number
}

export type Template = {
  id: string
  html: string
  width: number
  highlight: HighlightState | null
  imgSrc: string | null
  name: string
  description: string
  docLink: string
}

const TemplateGalleryItem = ({ template, onClick, isSelected }: TemplateGalleryItemProps) => {
  return (
    <div
      className={classNames(styles.galleryItem, isSelected && styles.isSelected)}
      onClick={onClick}
      data-testid="html-templates-gallery-item"
    >
      {isSelected && <FontAwesomeIcon className={styles.checkIcon} icon={["fas", "check"]} />}
      <div className={styles.previewImageWrapper}>
        {template.imgSrc && <img src={template.imgSrc} alt="" />}
      </div>
      <div className={styles.previewText}>
        <h4 className={styles.name}>{template.name}</h4>
        {template.description && <p className={styles.description}>{template.description}</p>}
      </div>
    </div>
  )
}

export default TemplateGalleryItem
